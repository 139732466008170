import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
    GetRequiredSurveysResponse,
    GetRequiredSurveyStateResponse,
    RequiredSurveysDataDetails,
    SurveyLessonsOptions,
    SurveyOptionsDto,
    SurveyOptionsResponse,
} from './types';
import { MAX_SURVEY_CALLS } from '../../constants';
import { surveysApi } from './api';
import { getNextTenMinutes } from './helpers';
import { RootStateRTK } from '../../rtk/store';
import { createSelector } from 'reselect';
import { CCOptions, GLOptions, ILOptions } from './surveyOptionsConstants';

export interface ISurveysState
    extends GetRequiredSurveysResponse,
        SurveyLessonsOptions {
    pauseCalls: boolean;
    callsCount: number;
    canCallAfter: Date | null;
    isFullScreen: boolean;
}

export const initialState: ISurveysState = {
    ConversationClass: {
        required: false,
        details: null,
    },
    GroupLesson: {
        required: false,
        details: null,
    },
    IndividualLesson: {
        required: false,
        details: null,
    },
    LearnerPortal: {
        required: false,
    },
    pauseCalls: false,
    callsCount: 0,
    canCallAfter: null,
    IndividualLessonOptions: null,
    ConversationClassOptions: null,
    GroupLessonOptions: null,
    PerformanceOptions: null,
    isFullScreen: false,
};

export const surveysSlice = createSlice({
    name: 'surveys',
    initialState,
    reducers: {
        setPauseCalls: (state, action: PayloadAction<boolean>) => {
            state.pauseCalls = action.payload;
        },
        setCallsCount: (state, action: PayloadAction<number>) => {
            state.callsCount = action.payload;
        },
        setCanCallAfter: (state) => {
            state.canCallAfter = getNextTenMinutes();
        },
        // Because BE caches results for 10 minutes we define skip as false for surveys
        setCloseIL: (state, action: PayloadAction<boolean>) => {
            state.IndividualLesson = {
                ...state.IndividualLesson,
                required: action.payload,
            };
        },
        setCloseCC: (state, action: PayloadAction<boolean>) => {
            state.ConversationClass = {
                ...state.ConversationClass,
                required: action.payload,
            };
        },
        setCloseGL: (state, action: PayloadAction<boolean>) => {
            state.GroupLesson = {
                ...state.GroupLesson,
                required: action.payload,
            };
        },
        setClosePerformance: (state, action: PayloadAction<boolean>) => {
            state.LearnerPortal = {
                required: action.payload,
            };
        },
        setFullScreen: (state, action: PayloadAction<boolean>) => {
            state.isFullScreen = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder.addMatcher(
            surveysApi.endpoints.getRequiredSurveys.matchPending,
            (state) => {
                if (state.callsCount < MAX_SURVEY_CALLS && !state.pauseCalls) {
                    const increased = state.callsCount + 1;
                    state.pauseCalls = increased < MAX_SURVEY_CALLS;
                    state.callsCount = increased;
                    state.canCallAfter = null;
                } else {
                    state.pauseCalls = true;
                    state.callsCount = 0;
                    state.canCallAfter = getNextTenMinutes();
                }
            }
        );
        builder.addMatcher(
            surveysApi.endpoints.getRequiredSurveys.matchFulfilled,
            (state, action) => {
                const { response } = action.meta.baseQueryMeta as any;
                if (response.status === 200) {
                    const { survey } =
                        action.payload as GetRequiredSurveyStateResponse;
                    state.pauseCalls = true;
                    state.callsCount = 0;
                    state.canCallAfter = getNextTenMinutes();
                    state.ConversationClass = survey.ConversationClass;
                    state.GroupLesson = survey.GroupLesson;
                    state.IndividualLesson = survey.IndividualLesson;
                    state.LearnerPortal = survey.LearnerPortal;
                } else {
                    state.pauseCalls = false;
                }
            }
        );
        builder.addMatcher(
            surveysApi.endpoints.getSurveysOptions.matchRejected,
            (state) => {
                state.IndividualLessonOptions = ILOptions;
                state.ConversationClassOptions = CCOptions;
                state.GroupLessonOptions = GLOptions;
            }
        );
        builder.addMatcher(
            surveysApi.endpoints.getSurveysOptions.matchFulfilled,
            (state, action) => {
                if (
                    action.meta.arg.originalArgs.businessType ===
                    'ConversationClass'
                ) {
                    state.ConversationClassOptions = action.payload;
                }
                if (
                    action.meta.arg.originalArgs.businessType ===
                    'IndividualLesson'
                ) {
                    state.IndividualLessonOptions = action.payload;
                }
                if (
                    action.meta.arg.originalArgs.businessType === 'GroupLesson'
                ) {
                    state.GroupLessonOptions = action.payload;
                }
            }
        );
        builder.addMatcher(
            surveysApi.endpoints.skipSurvey.matchPending,
            (state, action) => {
                if (
                    action.meta.arg.originalArgs.businessType ===
                    'ConversationClass'
                ) {
                    state.ConversationClass = {
                        ...state.ConversationClass,
                        required: false,
                    };
                }
                if (
                    action.meta.arg.originalArgs.businessType ===
                    'IndividualLesson'
                ) {
                    state.IndividualLesson = {
                        ...state.IndividualLesson,
                        required: false,
                    };
                }
                if (
                    action.meta.arg.originalArgs.businessType === 'GroupLesson'
                ) {
                    state.GroupLesson = {
                        ...state.GroupLesson,
                        required: false,
                    };
                }
                if (
                    action.meta.arg.originalArgs.businessType ===
                    'LearnerPortal'
                ) {
                    state.LearnerPortal = {
                        ...state.LearnerPortal,
                        required: false,
                    };
                }
            }
        );
        builder.addMatcher(
            surveysApi.endpoints.getPageOptions.matchPending,
            (state) => {
                state.PerformanceOptions = null;
            }
        );
        builder.addMatcher(
            surveysApi.endpoints.getPageOptions.matchFulfilled,
            (state, action) => {
                state.PerformanceOptions = action.payload;
            }
        );
    },
});

const { reducer, actions } = surveysSlice;
export default reducer;

export const {
    setPauseCalls,
    setCallsCount,
    setCanCallAfter,
    setCloseIL,
    setCloseCC,
    setCloseGL,
    setClosePerformance,
    setFullScreen,
} = actions;

const surveySelector = (state: RootStateRTK) => state.surveys;

export const surveysFullScreenSelector = createSelector(
    surveySelector,
    (surveyState) => surveyState.isFullScreen
);
export const ConversationClassSurveyDataSelector = createSelector(
    surveySelector,
    (surveyState) => surveyState.ConversationClass
);
export const GroupLessonSurveyDataSelector = createSelector(
    surveySelector,
    (surveyState) => surveyState.GroupLesson
);
export const IndividualLessonDataSurveySelector = createSelector(
    surveySelector,
    (surveyState) => surveyState.IndividualLesson
);
export const LearnerPortalSurveyDataSelector = createSelector(
    surveySelector,
    (surveyState) => surveyState.LearnerPortal
);
// Select required
export const IsLearnerPortalSurveySelector = createSelector(
    LearnerPortalSurveyDataSelector,
    (surveyState) => surveyState.required as boolean
);
export const IsConversationClassSurveySelector = createSelector(
    ConversationClassSurveyDataSelector,
    (surveyState) => surveyState.required as boolean
);
export const IsGroupLessonSurveySelector = createSelector(
    GroupLessonSurveyDataSelector,
    (surveyState) => surveyState.required as boolean
);
export const IsIndividualLessonSurveySelector = createSelector(
    IndividualLessonDataSurveySelector,
    (surveyState) => surveyState.required as boolean
);
// Select details for save payload
export const ConversationClassSurveyDetailsSelector = createSelector(
    ConversationClassSurveyDataSelector,
    (surveyState) => surveyState.details as RequiredSurveysDataDetails | null
);
export const GroupLessonSurveyDetailsSelector = createSelector(
    GroupLessonSurveyDataSelector,
    (surveyState) => surveyState.details as RequiredSurveysDataDetails | null
);
export const IndividualLessonDetailsSurveySelector = createSelector(
    IndividualLessonDataSurveySelector,
    (surveyState) => surveyState.details as RequiredSurveysDataDetails | null
);
// Additional for polling
export const IsSurveysCallsPausedSelector = createSelector(
    surveySelector,
    (surveyState) => surveyState.pauseCalls
);
export const SurveysCallsCountSelector = createSelector(
    surveySelector,
    (surveyState) => surveyState.callsCount
);
export const CallSurveysAfterDateSelector = createSelector(
    surveySelector,
    (surveyState) => surveyState.canCallAfter
);
// Select options
export const IndividualLessonOptionsSelector = createSelector(
    surveySelector,
    (surveyState) =>
        surveyState.IndividualLessonOptions as SurveyOptionsResponse | null
);
export const ConversationClassOptionsSelector = createSelector(
    surveySelector,
    (surveyState) =>
        surveyState.ConversationClassOptions as SurveyOptionsResponse | null
);
export const GroupLessonOptionsSelector = createSelector(
    surveySelector,
    (surveyState) =>
        surveyState.GroupLessonOptions as SurveyOptionsResponse | null
);
export const PerformanceOptionsSelector = createSelector(
    surveySelector,
    (surveyState) => surveyState.PerformanceOptions as SurveyOptionsDto[] | null
);
// Setup a queue
export const ShowPerformanceModalSelector = createSelector(
    [IsLearnerPortalSurveySelector, PerformanceOptionsSelector],
    (isPerformance, performanceOptions) =>
        Boolean(isPerformance) &&
        performanceOptions !== null &&
        performanceOptions.length > 0
);
export const ShowILModalSelector = createSelector(
    [
        ShowPerformanceModalSelector,
        IsIndividualLessonSurveySelector,
        IndividualLessonOptionsSelector,
        IndividualLessonDetailsSurveySelector,
    ],
    (isPerformance, isIL, ILOptions, isDetails) =>
        Boolean(!isPerformance && isIL && ILOptions && isDetails)
);
export const ShowCCModalSelector = createSelector(
    [
        ShowILModalSelector,
        IsConversationClassSurveySelector,
        ConversationClassOptionsSelector,
        ConversationClassSurveyDetailsSelector,
    ],
    (isIL, isCC, CCOptions, isDetails) =>
        Boolean(!isIL && isCC && CCOptions && isDetails)
);
export const ShowGLModalSelector = createSelector(
    [
        ShowCCModalSelector,
        IsGroupLessonSurveySelector,
        GroupLessonOptionsSelector,
        GroupLessonSurveyDetailsSelector,
    ],
    (isCC, isGL, GLOptions, isDetails) =>
        Boolean(!isCC && isGL && GLOptions && isDetails)
);
