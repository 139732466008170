import { RootEpic } from '../root-epic';
import { of } from 'rxjs';
import {
    catchError,
    concatMap,
    filter,
    switchMap,
    withLatestFrom,
} from 'rxjs/operators';
import { isActionOf } from 'typesafe-actions';
import { Action } from '../../store/root-action';
import { getStatusLoadingActions, modifyResourceStatus } from './helpers';
import { ResourceContent } from '../../store/resources/resource-content';

export const loadArticlesList: RootEpic = (
    action$,
    state$,
    { legacyResourceContentApi }
) =>
    action$.pipe(
        filter(isActionOf(Action.articles.loadArticles.request)),
        withLatestFrom(state$),
        switchMap(([x, state]) => {
            return legacyResourceContentApi
                .loadArticlesList(
                    state.resources.articles.descriptions.count(),
                    state.learnerProfile.currentLanguage?.code as string,
                    x?.payload?.levels,
                    x?.payload?.categories
                )
                .pipe(
                    concatMap((loadedResources) => {
                        const inProgressResources =
                            state.resources.inProgress.list;
                        const completedResources =
                            state.resources.completed.list;
                        const actualizedResources = loadedResources.map(
                            modifyResourceStatus<ResourceContent>(
                                inProgressResources,
                                completedResources
                            )
                        );
                        return of(
                            ...getStatusLoadingActions(
                                inProgressResources,
                                completedResources
                            ),
                            Action.articles.loadArticles.success(
                                actualizedResources as ResourceContent[]
                            )
                        );
                    }),
                    catchError((e) =>
                        of(Action.articles.loadArticles.failure(e))
                    )
                );
        })
    );

export const loadFilteredArticlesList: RootEpic = (
    action$,
    state$,
    { legacyResourceContentApi }
) =>
    action$.pipe(
        filter(isActionOf(Action.articles.loadFilteredArticles.request)),
        withLatestFrom(state$),
        switchMap(([x, state]) => {
            return legacyResourceContentApi
                .loadArticlesList(
                    state.resources.articles.filtered.count(),
                    state.learnerProfile.currentLanguage?.code as string,
                    x?.payload?.levels,
                    x?.payload?.categories
                )
                .pipe(
                    concatMap((loadedResources) => {
                        const inProgressResources =
                            state.resources.inProgress.list;
                        const completedResources =
                            state.resources.completed.list;
                        const actualizedResources = loadedResources.map(
                            modifyResourceStatus<ResourceContent>(
                                inProgressResources,
                                completedResources
                            )
                        );
                        return of(
                            ...getStatusLoadingActions(
                                inProgressResources,
                                completedResources
                            ),
                            Action.articles.loadFilteredArticles.success(
                                actualizedResources as ResourceContent[]
                            )
                        );
                    }),
                    catchError((e) =>
                        of(Action.articles.loadFilteredArticles.failure(e))
                    )
                );
        })
    );

export const articlesEpics = [loadArticlesList, loadFilteredArticlesList];
