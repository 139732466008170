import {
    LessonReport,
    LessonReportLearner,
    LessonReportListItem,
    LessonReportProps,
    LessonReportTeacher,
} from '../store/lesson-reports/data/lesson-report-models';

export class LessonReportsService {
    private readonly LIST_ITEM_KEY = 'lessonReportListItem';
    private readonly LESSON_REPORT_KEY = 'lessonReport';
    private readonly cellSymbolsTreatAsEmpty = [
        '&nbsp;',
        ' ',
        '<strong>',
        '</strong>',
    ];

    public tryGetLessonReportFromLocalStorage(): {
        listItem: LessonReportListItem | null;
        lessonReport: LessonReport | null;
    } {
        try {
            const rawListItem = localStorage.getItem(this.LIST_ITEM_KEY);
            const rawLessonReport = localStorage.getItem(
                this.LESSON_REPORT_KEY
            );

            return {
                listItem: new LessonReportListItem(JSON.parse(rawListItem!)),
                lessonReport: new LessonReport(JSON.parse(rawLessonReport!)),
            };
        } catch (e) {
            console.error("Can't load lesson report to print", e);
            return { listItem: null, lessonReport: null };
        }
    }

    public saveLessonReportToLocalStorage(
        listItem: LessonReportListItem,
        lessonReport: LessonReport
    ): void {
        localStorage.setItem(this.LIST_ITEM_KEY, JSON.stringify(listItem));
        localStorage.setItem(
            this.LESSON_REPORT_KEY,
            JSON.stringify(lessonReport)
        );
    }

    public buildLessonReport(
        dto: LessonReportProps,
        teacher: LessonReportTeacher | null | undefined,
        learner: LessonReportLearner | null | undefined
    ): LessonReport {
        dto.modifiedVocabularyContent = this.modifyVocabularyContent(
            dto.words!
        );
        dto.modifiedCorrectionsContent = this.modifyCorrectionsContent(
            dto.corrections
        );
        dto.modifiedCorrectionsContentMobile =
            this.modifyCorrectionsContentMobile(dto.corrections);
        dto.greetingsHtml = this.buildGreetingsHtml(
            dto.greetings,
            teacher,
            learner,
            this.buildTagsHtml(dto.tagNames),
            dto.tagNames.length === 0
        );
        return new LessonReport(dto);
    }

    public modifyVocabularyContent(vocabulary: string): string {
        if (!vocabulary) return '';
        try {
            let res = vocabulary
                .replace(/<a/g, '<li')
                .replace(/<\/a>/g, '</li>');

            res = '<ul>' + res + '</ul>';
            return res;
        } catch (e) {
            console.error('content modification failed', vocabulary);
            return vocabulary;
        }
    }

    public modifyCorrectionsContent(
        corrections: string | null | undefined
    ): string {
        if (!corrections) return '';
        try {
            const openIndex = corrections.indexOf('<table>');
            const closeIndex = corrections.indexOf('</table>');
            let table = corrections.substr(
                openIndex,
                closeIndex - openIndex + 8
            );
            let divElement = document.createElement('div');
            divElement.innerHTML = table;

            let rows = divElement.querySelectorAll('tr');

            let newTable = document.createElement('table');
            newTable.createTBody();
            let tbody = newTable.firstChild;

            for (let i = 0; i < rows.length; i++) {
                let hasContent = false;
                let cells = rows[i].querySelectorAll('td');
                for (let j = 0; j < cells.length; j++) {
                    if (!this.isEmptyCell(cells[j])) {
                        hasContent = true;
                        break;
                    }
                }
                if (hasContent) {
                    tbody!.appendChild(rows[i]);
                }
            }

            return newTable.outerHTML;
        } catch (e) {
            console.error('content modification failed', corrections, e);
            return corrections;
        }
    }

    public modifyCorrectionsContentMobile(
        corrections: string | null | undefined
    ): string[] {
        if (!corrections) return [];
        try {
            const openIndex = corrections.indexOf('<table>');
            const closeIndex = corrections.indexOf('</table>');
            let table = corrections.substr(
                openIndex,
                closeIndex - openIndex + 8
            );
            let divElement = document.createElement('div');
            divElement.innerHTML = table;

            let rows = divElement.querySelectorAll('tr');

            let newTable = document.createElement('table');
            newTable.createTBody();
            let rowsToRender = [];

            //Exclude first row - table header
            for (let i = 1; i < rows.length; i++) {
                let hasContent = false;
                let cells = rows[i].querySelectorAll('td');
                for (let j = 0; j < cells.length; j++) {
                    if (!this.isEmptyCell(cells[j])) {
                        hasContent = true;
                        break;
                    }
                }
                if (hasContent) {
                    rowsToRender.push(rows[i]);
                }
            }

            let headerCells = rows[0].querySelectorAll('td');

            let blocks: string[] = [];

            const createBlockFromRow = (row: ParentNode): HTMLElement => {
                let rowBlock = document.createElement('div');
                const rowCells = row.querySelectorAll('td');

                for (let i = 0; i < headerCells.length; i++) {
                    let headerCellBlock = document.createElement('div');
                    headerCellBlock.className =
                        'lesson-report-corrections_header-block';
                    let dataCellBlock = document.createElement('div');

                    dataCellBlock.innerHTML = rowCells[i]
                        ? rowCells[i].innerHTML
                        : '';
                    headerCellBlock.innerHTML = headerCells[i].innerHTML;

                    if (i === 1)
                        dataCellBlock.className =
                            'lesson-report-corrections_said-block';
                    if (i === 2)
                        dataCellBlock.className =
                            'lesson-report-corrections_comments-block';
                    if (!this.isEmptyCell(dataCellBlock)) {
                        rowBlock.appendChild(headerCellBlock);
                        rowBlock.appendChild(dataCellBlock);
                    }
                }
                return rowBlock;
            };

            rowsToRender.forEach((r) => {
                blocks.push(createBlockFromRow(r).innerHTML);
            });

            return blocks;
        } catch (e) {
            console.error('content modification failed', corrections, e);
            return [corrections];
        }
    }

    private buildGreetingsHtml(
        greetings: string,
        teacher: LessonReportTeacher | null | undefined,
        learner: LessonReportLearner | null | undefined,
        tagsHtml: string,
        deleteHeader?: boolean
    ): string {
        const firstName = teacher?.firstName || '';
        const lastName = teacher?.lastName || '';
        const fullTrainerName = `${firstName} ${lastName}`.trim();

        let res = greetings!
            .replace('%Trainer%', fullTrainerName)
            .replace('%Learner%', learner?.name || '')
            .replace('%Tags%', tagsHtml || '');

        //if (deleteHeader) res = res.replace('Today we worked on:', '');

        return res;
    }

    private buildTagsHtml(tagNames: Array<string>) {
        return (
            '<ul>' +
            tagNames.map((n) => '<li>' + n + '</li>').join('') +
            '</ul>'
        );
    }

    private isEmptyCell(cell: InnerHTML): boolean {
        let content = cell.innerHTML;
        this.cellSymbolsTreatAsEmpty.forEach(
            (s) => (content = content.replace(s, ''))
        );
        return content.trim().length === 0;
    }
}
