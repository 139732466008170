import { createAsyncAction, createAction } from 'typesafe-actions';
import { List } from 'immutable';
import { TranslateResourceData } from './translate-record';

export const translateAction = createAsyncAction(
    '@@resource/translate/request',
    '@@resource/translate/success',
    '@@resource/translate/failure'
)<
    {
        text: Array<string>;
    },
    TranslateResourceData,
    Error
>();

export const clearTranslateData = createAction('@@resource/translate-reset')();

export const translateOriginalText = createAction(
    '@@resource/translate-original-text'
)<List<string>>();

export const toggleTranslateSwitchAction = createAction(
    '@@resource/translate-switch'
)<boolean>();

export const translateActions = {
    translateAction,
    toggleTranslateSwitchAction,
    translateOriginalText,
    clearTranslateData,
};
