import { RootEpic } from './root-epic';
import { catchError, filter, map, switchMap } from 'rxjs/operators';
import { isActionOf } from 'typesafe-actions';
import { Action } from '../store/root-action';
import { of } from 'rxjs';

const loadActivityCategoriesEpic: RootEpic = (
    action$,
    _,
    { activityFiltersApi }
) =>
    action$.pipe(
        filter(
            isActionOf(Action.activityFilters.loadActivityCategories.request)
        ),
        switchMap((x) => {
            return activityFiltersApi
                .getActivityCategoriesList(x.payload.languageUUID)
                .pipe(
                    map((x) =>
                        Action.activityFilters.loadActivityCategories.success(x)
                    ),
                    catchError((e) =>
                        of(
                            Action.activityFilters.loadActivityCategories.failure(
                                e
                            )
                        )
                    )
                );
        })
    );

export const activityFiltersEpics = [loadActivityCategoriesEpic];
