import { ApiHeaders } from './api-headers';
import { Axios } from 'axios-observable';
import { catchError, map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { apiv1 } from './api-urls';
import { isCordova, saveAndOpenFileCordova } from '../utils/cordova';
import { downloadFile } from '../utils/download-file';

export class LearnerCertificateApi {
    constructor(private readonly apiHeaders: ApiHeaders) {}

    getCertificate({
        overAllLevelUuid,
        type,
    }: {
        overAllLevelUuid: string;
        type: string;
    }): Observable<string> {
        return this.getDownloadFromService(
            apiv1() + `certificate-service/${type}/${overAllLevelUuid}`
        );
    }

    getProficiencyOverview({
        testUUID,
    }: {
        testUUID: string;
    }): Observable<string> {
        return this.getDownloadFromService(
            apiv1() +
                `certificate-service/generate/proficiency-overview/${testUUID}`
        );
    }

    private getDownloadFromService(url: string) {
        return Axios.get<string>(url, {
            responseType: 'arraybuffer',
            headers: this.apiHeaders.getHeaders({
                'Content-Type': 'application/json',
                Accept: 'application/pdf',
            }),
        }).pipe(
            map((response) => {
                const headers = response.headers['content-disposition'];
                const responseData = response.data;
                return this.downloadFile({ headers, responseData });
            }),
            catchError((error) => {
                throw error?.message ? error.message : error;
            })
        );
    }

    private downloadFile({
        headers,
        responseData,
    }: {
        headers: string;
        responseData: string;
    }) {
        const { fileName, extension } =
            this.extractFileNameAndExtension(headers);
        const fileContent = new Blob([responseData]);

        const decodedFilename = decodeURIComponent(fileName);

        if (isCordova) {
            saveAndOpenFileCordova(decodedFilename, extension, fileContent);
        } else {
            downloadFile(decodedFilename, extension, fileContent);
        }

        return responseData;
    }

    private extractFileNameAndExtension(response: string) {
        //Tweaked logic to accomodate scenario where a name has a . in it.
		//For example, Ma. Lourdes (short for Maria)
		//OR integration user specified by client (firstname.secondname.lastname)
		//extension -> the final element after splitting by period is bound to be the file extension
		//filename -> everything else after splitting by period
		
		let parsedFullFileName = response.split(';')[1].split('filename=')[1];		
		let parsedByPreiod = parsedFullFileName.split(".");
		let extension = parsedByPreiod[parsedByPreiod.length-1];		
		let fileName = parsedFullFileName.split("."+extension)[0];

        return { fileName, extension };
    }
}
