import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import moment, { Moment } from 'moment';
import { useHistory } from 'react-router-dom';
import { ProficiencyCertificatePage } from '@arcadia-projects/portal2-ui-library';
import * as _ from 'lodash';
import { useBem } from '../../hooks/useBem';
import { useAppDispatch } from '../root/app-dispatch';
import Action from '../../store/root-action';
import {
    currentLanguageIdSelector,
    languageListSelector,
} from '../../store/learner-profile/learner-profile-selectors';
import {
    getCurrentUILanguage,
    userLearnerUUID,
    isRtlViewSelector,
} from '../../store/user/user-selectors';
import { localization } from '../../localization';

import { gradingCEFRSelector } from '../../store/grading/grading-selectors';
import {
    proficiencyHistorySelector,
    proficiencyListSelector,
} from '../../store/proficiency/proficiency-selectors';
import {
    DownloadDropDownDTO,
    ProficienciesDetailDTO,
    ProficienciesDTO,
} from '../../store/proficiency/proficiency-dto';
import { FilterOption } from '../filters/filters';
import language_list from '../../localization/study-language-list.json';
import { LocalizationLanguage } from '../../store/user/user-record';
import './proficiency-certificate.css';

type TopicListType =
    | 'chinese'
    | 'dutch'
    | 'english'
    | 'french'
    | 'german'
    | 'italian'
    | 'portuguese'
    | 'russian'
    | 'spanish'
    | 'arabic'
    | 'japanese'
    | 'korean'
    | 'polish';

export enum LevelEnum {
    Highest = 'Highest',
    Average = 'Average',
}

export enum UseCaseEnum {
    Portal = 'PORTAL',
    Assessment = 'ASSESSMENT',
}

export enum HandlerTypeEnum {
    NoRecord = 'No Record',
    EmptyList = 'Empty List',
}

const getLatestDateAndHighestProficiency = (props: {
    proficiencyList: any; //ProficienciesDetailDTO |
    isPreload?: boolean;
}) => {
    const { proficiencyList = [], isPreload = true } = props;
    const highestValue = proficiencyList.reduce(
        (previous: any, current: any) => {
            return isPreload
                ? current?.scores?.PROFICIENCY > previous?.scores?.PROFICIENCY
                    ? current
                    : previous
                : current?.details?.scores?.PROFICIENCY >
                  previous?.details?.scores?.PROFICIENCY
                ? current
                : previous;
        }
    );

    const filterAllHighestValue = proficiencyList.filter((o: any) => {
        return isPreload
            ? o?.scores?.PROFICIENCY === highestValue?.scores?.PROFICIENCY
            : o?.details?.scores?.PROFICIENCY ===
                  highestValue?.details?.scores?.PROFICIENCY;
    });

    const getLatestDateWithHighestValue = Math.max(
        ...filterAllHighestValue.map((o: any) =>
            isPreload
                ? moment(o?.calculationDate).valueOf()
                : moment(o?.details?.calculationDate).valueOf()
        )
    );

    return getLatestDateWithHighestValue;
};

export const ProficiencyCertificate = () => {
    const dispatch = useAppDispatch();
    const block = useBem('profile-certificate');
    const gradingCEFR = useSelector(gradingCEFRSelector);
    const topicUUID = useSelector(currentLanguageIdSelector);
    const leanerUUID = useSelector(userLearnerUUID);
    const proficiencyHistory = useSelector(proficiencyHistorySelector);
    const proficiencyList = useSelector(proficiencyListSelector);
    const isRtlView = useSelector(isRtlViewSelector);
    const [proficiencyValue, setProficiencyValue] = useState<any>(null);
    const history = useHistory();

    useEffect(() => {
        if (leanerUUID && topicUUID) {
            dispatch(
                Action.proficiency.loadHistory.request({
                    learnerUUID: leanerUUID,
                    topicUUID: topicUUID,
                })
            );
        }
    }, [dispatch, leanerUUID, topicUUID]);

    const formatString = 'YYYY-MM-DD';

    const limitPeriod = useMemo(() => 12, []);

    const firstDefaultDate = useMemo(
        () => moment().subtract(limitPeriod, 'months'),
        [limitPeriod]
    );

    const lastDefaultDate = useMemo(() => moment(), []);

    const [dateRange, setDateRange] = useState<{
        from: Moment;
        to: Moment;
    }>({ from: firstDefaultDate, to: lastDefaultDate });

    // dropdown
    const languageList = useSelector(languageListSelector);
    const currentUILanguage = useSelector(getCurrentUILanguage);

    const mapTypeToName: Record<TopicListType, string> = useMemo(() => {
        return {
            chinese: localization.analytics_empty_list_titles_chinese,
            dutch: localization.analytics_empty_list_titles_dutch,
            english: localization.analytics_empty_list_titles_english,
            french: localization.analytics_empty_list_titles_french,
            german: localization.analytics_empty_list_titles_german,
            italian: localization.analytics_empty_list_titles_italian,
            portuguese: localization.analytics_empty_list_titles_portuguese,
            russian: localization.analytics_empty_list_titles_russian,
            spanish: localization.analytics_empty_list_titles_spanish,
            arabic: localization.analytics_empty_list_titles_arabic,
            japanese: localization.analytics_empty_list_titles_japanese,
            korean: localization.analytics_empty_list_titles_korean,
            polish: localization.analytics_empty_list_titles_polish,
        };
    }, []);

    const language = languageList
        .valueSeq()
        .toArray()
        .sort((a, b) => {
            if (a.name > b.name) {
                return 1;
            }
            if (a.name < b.name) {
                return -1;
            }
            return 0;
        })
        .map((item) => ({
            name: item.name,
            value: item.languageUUID,
            code: item.code,
        }));

    const languageListt = language_list.map((l) => {
        let t = new Map<string, string>();
        // @ts-ignore
        Object.keys(l.translation).forEach((k) => t.set(k, l.translation[k]));

        return {
            id: l.id,
            name: l.name,
            endonym: l.endonym,
            translation: t,
        } as LocalizationLanguage;
    });

    const localizedLanguages = language.map((l: any) => {
        const localizedName =
            languageListt
                .find((ll) => ll.id === l.code || ll.id.includes(l.code))
                ?.translation.get(currentUILanguage.id) || l.name;
        return { id: l.value, value: l.name, text: localizedName };
    });

    // localization
    const defaultLanguageOption = useMemo(() => {
        const findCUrrentTopic: any = localizedLanguages.find(
            (y: any) => y.id === topicUUID
        );
        return [findCUrrentTopic];
    }, [localizedLanguages, topicUUID]);

    const [languageSelected, setSelectedLanguage] = useState(
        defaultLanguageOption
    );

    const [previousLanguageSelected, setPreviousSelectedLanguage] = useState(
        defaultLanguageOption
    );

    const handleApplyDropdown = useCallback(() => {
        if (languageSelected.length > 0 && leanerUUID) {
            dispatch(
                Action.proficiency.loadHistory.request({
                    learnerUUID: leanerUUID,
                    topicUUID: languageSelected[0].id,
                })
            );
            setProficiencyValue(null);
            setPreviousSelectedLanguage(languageSelected);
        }
    }, [languageSelected, leanerUUID, dispatch]);

    const handleCancelDropdown = useCallback(() => {
        setSelectedLanguage(previousLanguageSelected);
    }, [setSelectedLanguage, previousLanguageSelected]);

    // Dropdown download
    const handleClickButton = useCallback(
        (e: DownloadDropDownDTO) => {
            const { cardDetails, itemDetail } = e;
            if (
                itemDetail.name ===
                localization.analytics_download_dropdown_summary_title
            ) {
                dispatch(
                    Action.proficiency.loadCertificate.request({
                        overAllLevelUuid: cardDetails?.overallLevelUuid,
                        type: 'generateFirst',
                    })
                );
            } else {
                // localization.analytics_download_dropdown_full_version_title
                dispatch(
                    Action.proficiency.loadCertificate.request({
                        overAllLevelUuid: cardDetails?.overallLevelUuid,
                        type: 'generate',
                    })
                );
            }
        },
        [dispatch]
    );

    const handleClickLink = useCallback(() => {
        history.push(`/adaptive-test`);
    }, [history]);

    let defaultIndicatorValue = useMemo(() => {
        return {
            toolTipProps: {
                item: {
                    description: localization.analytics_tooltip_desciption,
                },
                name: localization.profile_tooltip_name_LearnMore,
            },
        };
    }, []);

    let defaultValue = useMemo(() => {
        return {
            graphIndicatorProps: {
                DataDTO: null,
                ComponentDTO: {
                    Button: {
                        name: localization.analytics_download_button_title,
                        handleClickMenu: handleClickButton,
                        MenuList: [
                            {
                                name: localization.analytics_download_dropdown_summary_title,
                            },
                            {
                                name: localization.analytics_download_dropdown_full_version_title,
                            },
                        ],
                    },
                    Indicator: {
                        highest: {
                            name: localization.analytics_highest_indicator_text,
                        },
                    },
                },
            },
            handlerProps: {
                description:
                    localization.analytics_message_no_record_description,
                subDescription: '',
                type: HandlerTypeEnum.NoRecord,
            },
            ...defaultIndicatorValue,
        };
    }, [handleClickButton, defaultIndicatorValue]);

    const handleSelectDropdown = useCallback(
        (value: FilterOption) => {
            setSelectedLanguage(() => [value]);
        },
        [setSelectedLanguage]
    );

    const handleChangeDateRange = useCallback(
        (from?: Date, to?: Date) => {
            setProficiencyValue(null);
            setDateRange({ from: moment(from), to: moment(to) });
        },
        [setDateRange, setProficiencyValue]
    );

    /*
    Is this a half work or what? Why type is not used?

    No typization code with typescript looks great! Should be fixed

    Using type throws error `has no properties in common with type 'ProficiencyProps'
    let defaultHeader: ProficiencyProps = useMemo(() => {
     */
    let defaultHeader = useMemo(() => {
        return {
            title: localization.analytics_component_title,
            ComponentDTO: {
                DetailDropdown: {
                    selectedItems: languageSelected,
                    previousSelectedItems: previousLanguageSelected,
                    items: localizedLanguages,
                    applied:
                        localizedLanguages.length !==
                        previousLanguageSelected.length,
                    onSelect: handleSelectDropdown,
                    onApply: handleApplyDropdown,
                    onCancel: handleCancelDropdown,
                    applyText: localization.insights_action_apply,
                    cancelText: localization.insights_location_cancel,
                    rtlDirection: isRtlView,
                },
                DetailDatePicker: {
                    firstDateSelected: dateRange?.from?.toDate(),
                    lastDateSelected: dateRange?.to?.toDate(),
                    firstMaxDate: moment().subtract(24, 'month').toDate(),
                    lastMaxDate: moment().toDate(),
                    onApply: handleChangeDateRange,
                    applyText: localization.insights_action_apply,
                    cancelText: localization.insights_location_cancel,
                },
            },
        };
    }, [
        languageSelected,
        previousLanguageSelected,
        localizedLanguages,
        handleSelectDropdown,
        handleApplyDropdown,
        handleCancelDropdown,
        isRtlView,
        dateRange?.from,
        dateRange?.to,
        handleChangeDateRange,
    ]);

    console.log('languageSelected', languageSelected);
    useEffect(() => {
        if (!proficiencyValue) {
            if (proficiencyHistory) {
                const getAllUseCasePortal = proficiencyHistory.filter(
                    (x: ProficienciesDetailDTO) => {
                        return x?.useCase === UseCaseEnum.Portal;
                    }
                );
                const historyLength = getAllUseCasePortal.length;
                if (historyLength > 0) {
                    const filteredDateItems = getAllUseCasePortal.filter(
                        (y: ProficienciesDetailDTO) => {
                            const calculationDate = y?.calculationDate;
                            const startDate = dateRange.from;
                            const endDate = dateRange.to;
                            if (startDate && endDate) {
                                return (
                                    moment(
                                        moment(calculationDate).format(
                                            formatString
                                        )
                                    ).isSameOrAfter(
                                        startDate.format(formatString)
                                    ) &&
                                    moment(
                                        moment(calculationDate).format(
                                            formatString
                                        )
                                    ).isSameOrBefore(
                                        endDate.format(formatString)
                                    )
                                );
                            } else {
                                return false;
                            }
                        }
                    );

                    if (filteredDateItems.length > 0) {
                        const maxObject = getLatestDateAndHighestProficiency({
                            proficiencyList: filteredDateItems,
                        });
                        const highestValue = filteredDateItems.find(
                            (x: ProficienciesDetailDTO) => {
                                const itemDateTimestamp = moment(
                                    x?.calculationDate
                                ).valueOf();
                                return itemDateTimestamp === maxObject;
                            }
                        );

                        const sortItem: ProficienciesDetailDTO[] =
                            filteredDateItems.sort((a: any, b: any) => {
                                if (a.calculationDate > b.calculationDate)
                                    return 1;
                                if (a.calculationDate < b.calculationDate)
                                    return -1;
                                return 0;
                            });

                        let itemList = [
                            sortItem[0],
                            sortItem[filteredDateItems.length - 1],
                        ] as ProficienciesDetailDTO[];

                        if (highestValue) {
                            itemList = [
                                sortItem[0],
                                highestValue,
                                sortItem[filteredDateItems.length - 1],
                            ] as ProficienciesDetailDTO[];
                        }

                        // Creates an array of objects with unique "calculationDate" property values.
                        const uniqueObjArray = _.uniqBy(
                            itemList,
                            'calculationDate'
                        );
                        dispatch(
                            Action.proficiency.loadList.request(uniqueObjArray)
                        );
                    } else {
                        dispatch(Action.proficiency.setLoadList([]));
                    }
                } else {
                    let NoRecord: any = {
                        ...defaultValue,
                        handlerProps: {
                            description:
                                localization.analytics_message_no_record_description,
                            subDescription:
                                mapTypeToName[
                                    (
                                        languageSelected[0]?.value || 'english'
                                    ).toLowerCase() as TopicListType
                                ],
                            type: HandlerTypeEnum.NoRecord,
                        },
                    };
                    if (languageSelected[0]?.id === topicUUID) {
                        NoRecord = {
                            ...NoRecord,
                            handlerProps: {
                                ...NoRecord.handlerProps,
                                handleClick: handleClickLink,
                            },
                        };
                    }

                    setProficiencyValue(NoRecord);
                }
            }
        }
    }, [
        dispatch,
        proficiencyHistory,
        proficiencyValue,
        setProficiencyValue,
        defaultValue,
        dateRange,
        languageSelected,
        mapTypeToName,
        handleClickLink,
        topicUUID,
    ]);

    useEffect(() => {
        if (proficiencyList) {
            const listLength = proficiencyList.length;
            if (listLength > 0) {
                let hasHighest = false;
                const getLatestDateWithHighestValue =
                    getLatestDateAndHighestProficiency({
                        proficiencyList,
                        isPreload: false,
                    });
                const proficiencies = proficiencyList.map(
                    (x: ProficienciesDTO, index: number) => {
                        const { convertedValue } = x;
                        const itemDateTimestamp = moment(
                            x?.details?.calculationDate
                        ).valueOf();

                        const overAllText = convertedValue?.proficiency
                            ? `CEFR ${convertedValue?.proficiency?.name} | goFLUENT ${convertedValue?.goProficiency?.name}`
                            : localization.analytics_description_not_applicable_title;
                        const speakingLevelText = convertedValue?.speaking
                            ? `CEFR ${convertedValue?.speaking?.name} | goFLUENT ${convertedValue?.goSpeaking?.name}`
                            : localization.analytics_description_not_applicable_title;
                        const proficiencyTestText =
                            convertedValue?.proficiencyTest
                                ? `CEFR ${convertedValue?.proficiencyTest?.name} | goFLUENT ${convertedValue?.goProficiencyTest?.name}`
                                : localization.analytics_description_take_proficiency_test;

                        const clickObjectProficiencyTest =
                            convertedValue?.proficiencyTest
                                ? {}
                                : languageSelected[0]?.id === topicUUID
                                ? {
                                      handleClick: handleClickLink,
                                  }
                                : {};

                        let patternObject = {
                            ...x,
                            description: [
                                {
                                    name: localization.analytics_description_overall_title,
                                    value: overAllText,
                                    highlight: true,
                                },
                                {
                                    name: localization.analytics_description_speaking_title,
                                    value: speakingLevelText,
                                    highlight: false,
                                },
                                {
                                    name: localization.analytics_description_proficiency_test_title,
                                    value: proficiencyTestText,
                                    highlight: false,
                                    ...clickObjectProficiencyTest,
                                },
                            ],
                        };

                        if (listLength === 1) {
                            patternObject = {
                                ...patternObject,
                                typeOrdinal: index,
                                level: LevelEnum.Average,
                            };
                        } else {
                            if (
                                getLatestDateWithHighestValue ===
                                itemDateTimestamp
                            ) {
                                hasHighest = true;
                                patternObject = {
                                    ...patternObject,
                                    typeOrdinal: 1,
                                    level: LevelEnum.Highest,
                                };
                            } else {
                                patternObject = {
                                    ...patternObject,
                                    typeOrdinal: hasHighest ? index++ : index,
                                    level: LevelEnum.Average,
                                };
                            }
                        }

                        return patternObject;
                    }
                );

                const CertificateObjects: any = {
                    gradingLevels: gradingCEFR,
                    proficiencies: proficiencies,
                };

                const ObjectData = {
                    ...defaultValue,
                    graphIndicatorProps: {
                        ...defaultValue.graphIndicatorProps,
                        DataDTO: CertificateObjects,
                    },
                };

                setProficiencyValue(ObjectData);
            } else {
                const EmptyList = {
                    ...defaultValue,
                    handlerProps: {
                        description:
                            localization.analytics_message_empty_list_description,
                        subDescription: '',
                        type: HandlerTypeEnum.EmptyList,
                    },
                };
                setProficiencyValue(EmptyList);
            }
        }
    }, [
        dispatch,
        proficiencyList,
        gradingCEFR,
        setProficiencyValue,
        defaultValue,
        handleClickLink,
        languageSelected,
        topicUUID,
    ]);

    return (
        <div
            className={block.elem('wrapper', {
                noRecord: !!!proficiencyValue?.graphIndicatorProps?.DataDTO,
            })}
        >
            <ProficiencyCertificatePage
                headerDetailsProps={defaultHeader}
                levelIndicatorsProps={
                    proficiencyValue ? proficiencyValue : defaultIndicatorValue
                }
            />
        </div>
    );
};
