import { combineReducers } from 'redux';
import { createReducer } from 'typesafe-actions';
import { Map } from 'immutable';
import { CreditRecord, MediaRecord } from './scheduler-data';
import { Action } from '../root-action';

const creditReducer = createReducer(Map<string, CreditRecord>())
    .handleAction(
        [
            Action.scheduler.initializeScheduler.success,
            Action.lessonsList.initializeLessonsList.success,
        ],
        (state, { payload }) => {
            return payload.credits;
        }
    )
    .handleAction(Action.user.logout.success, (state) => state.clear());

const currentCreditReducer = createReducer<CreditRecord | null>(null)
    .handleAction(
        [
            Action.scheduler.setCurrentCredit,
            Action.lessonsList.setCurrentCredit,
        ],
        (state, { payload }) => payload
    )
    .handleAction(Action.user.logout.success, () => null);

const currentDurationReducer = createReducer<string | null>(null)
    .handleAction(
        [
            Action.scheduler.setCurrentDuration,
            Action.lessonsList.setCurrentDuration,
        ],
        (state, { payload }) => payload
    )
    .handleAction(Action.user.logout.success, () => null);

const currentMediaReducer = createReducer<MediaRecord | null>(null)
    .handleAction(
        [Action.scheduler.setCurrentMedia, Action.lessonsList.setCurrentMedia],
        (state, { payload }) => payload
    )
    .handleAction(Action.user.logout.success, () => null);

export const lessonsSettingsReducer = () =>
    combineReducers({
        credit: creditReducer,
        currentCredit: currentCreditReducer,
        currentDuration: currentDurationReducer,
        currentMedia: currentMediaReducer,
    });
