import websocket from './classroom/websocket/websocket-actions';
import classroom from './classroom/classroom-actions';
import list from './list/classroom-list-actions';
import { createAction } from 'typesafe-actions';

export const clearClassroom = createAction('@@classroom/clear-all')();

const vcrActions = {
    list: list,
    classroom: classroom,
    websocket: websocket,
    clearClassroom,
};

export default vcrActions;
