import { List } from 'immutable';
import { RootEpic } from './root-epic';
import { Action } from '../store/root-action';
import { isActionOf } from 'typesafe-actions';
import { of, Observable } from 'rxjs';
import {
    filter,
    switchMap,
    map,
    catchError,
    withLatestFrom,
    exhaustMap,
    ignoreElements,
    concatMap,
    takeUntil,
    mergeMap,
} from 'rxjs/operators';
import {
    Availability,
    Teacher,
    CreditRecord,
    Foresen,
    MediaRecord,
    SchedulerLanguageProps,
    SchedulerLimitation,
} from '../store/scheduler/scheduler-data';

import {
    getMaxDateForBrowsing,
    getHoursBookedMonth,
    getHoursBookedWeek,
} from '../store/scheduler/scheduler-selectors';

import moment from 'moment';
import { RetrieveAvailsOptions } from '../services/scheduler-api';
import { RootAction } from 'typesafe-actions';
import { localization } from '../localization';
import { ContractTypes } from '../store/contracts/contract-data';

const loggedOut = (action$: Observable<RootAction>) =>
    action$.pipe(filter(isActionOf(Action.user.logout.request)));

const onOpenEpic: RootEpic = (action$, state$, _) => {
    return action$.pipe(filter(isActionOf(Action.scheduler.opened))).pipe(
        exhaustMap(() => {
            let learnerID = String(
                state$.value.user.identity?.learnerExternalId
            );
            return of(
                Action.scheduler.initializeScheduler.request({
                    learnerID: learnerID,
                }),
                Action.scheduler.loadInitWeekLessons.request({
                    startDate: moment().startOf('day'),
                    learnerId: learnerID,
                }),
                // Useless call to emulate previous version of portal

                Action.scheduler.initLanguage.request({
                    learnerId: learnerID,
                })
            );
        })
    );
};

const onSettingsOpenEpic: RootEpic = (action$, state$, _) => {
    return action$.pipe(filter(isActionOf(Action.scheduler.settingsOpen))).pipe(
        switchMap(() => {
            let learnerID = String(
                state$.value.user.identity?.learnerExternalId
            );
            return of(
                Action.scheduler.initializeScheduler.request({
                    learnerID: learnerID,
                })
            );
        })
    );
};

const onBookingEpic: RootEpic = (action$, state$, { schedulerApi }) => {
    return action$
        .pipe(
            filter(
                isActionOf([
                    Action.scheduler.bookLesson.success,
                    Action.scheduler.confirmedCancelLesson.success,
                ])
            )
        )
        .pipe(
            withLatestFrom(state$),
            map(([r, state]) => {
                let learner: any = state.scheduler.learner;
                let learnerId: any = learner
                    ? learner.LearnerID
                    : state.user.identity?.learnerExternalId;

                return Action.scheduler.initializeScheduler.request({
                    learnerID: learnerId,
                });
            })
        );
};

const bookingMessageSuccess: RootEpic = (action$, state$, _) => {
    return action$.pipe(
        filter(isActionOf(Action.scheduler.bookLesson.success)),
        map(() =>
            Action.contentSavedActions.setNotification({
                show: true,
                error: false,
                maximum: false,
                text: localization.scheduler_message_LessonSuccessfullyBooked,
                key: 15,
            })
        )
    );
};

const bookingMessageFailure: RootEpic = (action$, state$, _) => {
    return action$.pipe(
        filter(isActionOf(Action.scheduler.bookLesson.failure)),
        map(({ payload }) => {
            let msg = (() => localization.scheduler_message_CantBookLesson!)();
            if (payload.message === 'max_hours_reached') {
                //Todo: find some format string solution
                if (
                    state$.value.scheduler.limitation
                        .getMaxHours()
                        .toString() === '1'
                ) {
                    msg = (() =>
                        localization.scheduler_message_MaxHourReached!)();
                } else {
                    if (
                        state$.value.scheduler.limitation
                            .getMaxHours()
                            .toString() === '0'
                    ) {
                        msg = (() =>
                            localization.scheduler_message_MaxHourReachedZero!)();
                    } else {
                        msg = (() =>
                            localization.scheduler_message_MaxHoursReached!)().replace(
                            '{0}',
                            state$.value.scheduler.limitation
                                .getMaxHours()
                                .toString()
                        );
                    }
                }
            }

            if (payload.message === 'max_hours_reached_month') {
                //Todo: find some format string solution
                if (
                    state$.value.scheduler.limitation
                        .getMaxHours()
                        .toString() === '1'
                ) {
                    msg = (() =>
                        localization.scheduler_message_MaxHourReachedMonth!)();
                } else {
                    if (
                        state$.value.scheduler.limitation
                            .getMaxHours()
                            .toString() === '0'
                    ) {
                        msg = (() =>
                            localization.scheduler_message_MaxHoursReachedMonthZero!)();
                    } else {
                        msg = (() =>
                            localization.scheduler_message_MaxHoursReachedMonth!)().replace(
                            '{0}',
                            state$.value.scheduler.limitation
                                .getMaxHours()
                                .toString()
                        );
                    }
                }
            }

            return Action.contentSavedActions.setNotification({
                show: true,
                error: true,
                maximum: false,
                text: msg,
                key: 16,
            });
        })
    );
};

const cancelMessageSuccess: RootEpic = (action$, state$, _) => {
    return action$.pipe(
        filter(isActionOf(Action.scheduler.confirmedCancelLesson.success)),
        map(() =>
            Action.contentSavedActions.setNotification({
                show: true,
                error: false,
                maximum: false,
                text: localization.scheduler_message_LessonSuccessfullyCanceled,
                key: 17,
            })
        )
    );
};

const setSchedulerLimitationEpic: RootEpic = (action$, state$, _) =>
    action$.pipe(
        filter(isActionOf(Action.contracts.loadUserContracts.success)),
        map(() => {
            const schedulerLimitationRule: any =
                state$.value.contracts.userContracts.find(
                    (userContract) =>
                        userContract.type === ContractTypes.Scheduler
                )?.limitationRule;

            return Action.scheduler.setSchedulerLimitation(
                new SchedulerLimitation({
                    displayAvailabilities:
                        schedulerLimitationRule?.displayLimitWindow,
                    displayAvailabilityValue:
                        schedulerLimitationRule?.displayLimit,
                    maxLessonRange: schedulerLimitationRule?.hourLimitWindow,
                    maxLessonHours: schedulerLimitationRule?.hourLimit,
                })
            );
        })
    );

const cancelMessageFailure: RootEpic = (action$, state$, _) => {
    return action$.pipe(
        filter(isActionOf(Action.scheduler.confirmedCancelLesson.failure)),
        map(({ payload }) => {
            let payloadWithResponse: any = payload;
            let errorLabel: string =
                payloadWithResponse?.response?.Label || 'generic_error';
            return errorLabel;
        }),
        map((errorLabel) => {
            let message = (() =>
                localization.scheduler_message_CantCancelLesson)();
            if (errorLabel === 'pastlesson') {
                message = (() =>
                    localization.scheduler_message_CantCancelPastLesson)();
            }
            return Action.contentSavedActions.setNotification({
                show: true,
                error: true,
                maximum: false,
                text: message!,
                key: 18,
            });
        })
    );
};

const loadInitWeekLessonsEpic: RootEpic = (
    action$,
    state$,
    { schedulerApi }
) => {
    return action$
        .pipe(filter(isActionOf(Action.scheduler.loadInitWeekLessons.request)))
        .pipe(
            switchMap((action) => {
                return schedulerApi
                    .initWeekLessons({
                        learnerID: action.payload.learnerId,
                        startTime: action.payload.startDate.format(
                            'YYYY-MM-DDT00:00:00'
                        ),
                    })
                    .pipe(
                        map((response) =>
                            Action.scheduler.loadInitWeekLessons.success({
                                lessons: response.lessons,
                            })
                        ),
                        catchError((e) =>
                            of(Action.scheduler.loadInitWeekLessons.failure(e))
                        )
                    );
            })
        );
};

const initializeSchedulerEpic: RootEpic = (
    action$,
    state$,
    { schedulerApi }
) => {
    return action$
        .pipe(filter(isActionOf(Action.scheduler.initializeScheduler.request)))
        .pipe(
            switchMap((r) => {
                return schedulerApi
                    .getInitialize({
                        learnerId: String(r.payload.learnerID),
                    })
                    .pipe(
                        map((response) => {
                            response.sat = !!localStorage.getItem('SAT_NEW');
                            response.isAvailabilitiesPreLoaded =
                                state$.value.scheduler.isAvailabilitiesPreLoaded;

                            const newResponase = {
                                ...response,
                                currentCredit: state$.value.lessonsSettings
                                    .currentCredit as any,
                            };
                            return Action.scheduler.initializeScheduler.success(
                                newResponase
                            );
                        }),
                        catchError((e) =>
                            of(Action.scheduler.initializeScheduler.failure(e))
                        )
                    );
            })
        );
};

const changeSchedulerSelectedTeachersEpic: RootEpic = (action$, state$, _) =>
    action$.pipe(
        filter(isActionOf(Action.scheduler.changeSelectedTeachers)),
        map((x) => {
            if (x.payload.selectAll) {
                localStorage.setItem('SAT_NEW', x.payload.selectAll.toString());
            } else {
                localStorage.removeItem('SAT_NEW');
            }
        }),
        ignoreElements()
    );

const changeSchedulerDateEpic: RootEpic = (action$, state$, _) => {
    return action$
        .pipe(filter(isActionOf(Action.scheduler.selectSchedulerDate)))
        .pipe(
            withLatestFrom(state$),
            filter(([action, state]) => !state.user.isLoggingOut),
            concatMap(([action, state]) => {
                const date = action.payload;
                const learnerID = String(
                    state$.value.user.identity?.learnerExternalId
                );

                const isLimitedDate =
                    getMaxDateForBrowsing(state).isBefore(date);

                if (isLimitedDate) {
                    return of(
                        Action.scheduler.setAvailabilityError(
                            'limited_availability_view'
                        ),
                        Action.contentSavedActions.setNotification({
                            show: true,
                            error: true,
                            maximum: false,
                            text: localization.scheduler_message_LimitedAvailabilityView,
                            key: 19,
                        })
                    );
                }

                return of(
                    Action.scheduler.initializeScheduler.request({
                        learnerID: learnerID,
                        selectedDate: date,
                    }),
                    Action.scheduler.loadInitWeekLessons.request({
                        startDate: date,
                        learnerId: learnerID,
                    })
                );
            })
        );
};

const changeSchedulerSelectedTeachersProps: RootEpic = (action$, state$, _) => {
    return action$
        .pipe(filter(isActionOf(Action.scheduler.changeSelectedTeachers)))
        .pipe(
            switchMap((r) => {
                let learnerID = String(
                    state$.value.user.identity?.learnerExternalId
                );

                const isLimitedDate = getMaxDateForBrowsing(
                    state$.value
                ).isBefore(state$.value.scheduler.selectedDate);

                if (isLimitedDate) {
                    return of(
                        Action.scheduler.setAvailabilityError(
                            'limited_availability_view'
                        ),
                        Action.contentSavedActions.setNotification({
                            show: true,
                            error: true,
                            maximum: false,
                            text: localization.scheduler_message_LimitedAvailabilityView,
                            key: 20,
                        })
                    );
                }

                return of(
                    Action.scheduler.initializeScheduler.request({
                        learnerID: learnerID,
                        selectedDate: state$.value.scheduler.selectedDate,
                    }),
                    Action.scheduler.loadInitWeekLessons.request({
                        startDate: state$.value.scheduler.selectedDate,
                        learnerId: learnerID,
                    })
                );
            })
        );
};

const initLessonsEpic: RootEpic = (action$, state$, { schedulerApi }) => {
    return action$
        .pipe(filter(isActionOf(Action.scheduler.initLessons.request)))
        .pipe(
            switchMap((r) => {
                return schedulerApi
                    .getInitLessons({
                        learnerID: r.payload.learnerId,
                        startTime: moment(r.payload.startTime)
                            .clone()
                            .startOf('month')
                            .format('YYYY-MM-DDT00:00:00'),
                    })
                    .pipe(
                        map((response) => {
                            return Action.scheduler.initLessons.success(
                                response
                            );
                        }),
                        catchError((e) => {
                            return of(Action.scheduler.initLessons.failure(e));
                        })
                    );
            })
        );
};

const cancelLessonEpic: RootEpic = (action$, state$, { schedulerApi }) => {
    return action$
        .pipe(filter(isActionOf(Action.scheduler.cancelLesson.request)))
        .pipe(
            switchMap((r) => {
                const learner = state$.value.user?.identity?.learnerExternalId;
                return schedulerApi
                    .cancelLesson({
                        lessonID: String(r.payload.lessonId),
                        learnerID: String(learner),
                    })
                    .pipe(
                        withLatestFrom(state$),
                        map(([response, state]) => {
                            return Action.scheduler.cancelLesson.success({
                                cancellation: state.scheduler.cancellation
                                    .set(
                                        'credit',
                                        new CreditRecord({
                                            name: response.Credit.Name,
                                            creditId: response.Credit.CreditID,
                                            expiration:
                                                response.Credit.Expiration,
                                            creditLeft:
                                                response.Credit.CreditLeft,
                                            duration: response.Credit.Duration,
                                        })
                                    )
                                    .set('label', response.Label),
                            });
                        }),
                        catchError((e) =>
                            of(Action.scheduler.cancelLesson.failure(e))
                        ),
                        takeUntil(loggedOut(action$))
                    );
            })
        );
};

const trulyCancelLessonEpic: RootEpic = (action$, state$, { schedulerApi }) => {
    return action$
        .pipe(
            filter(isActionOf(Action.scheduler.confirmedCancelLesson.request))
        )
        .pipe(
            switchMap((r) => {
                const learner: any = state$.value.scheduler.learner;
                let learnerId: any = learner
                    ? learner.LearnerID
                    : state$.value.user.identity?.learnerExternalId;

                return schedulerApi
                    .cancelLesson({
                        lessonID: String(r.payload.lessonId),
                        learnerID: learnerId,
                        confirmCancel: 'true',
                    })
                    .pipe(
                        map((response) => {
                            return Action.scheduler.confirmedCancelLesson.success(
                                response
                            );
                        }),
                        catchError((e) => {
                            return of(
                                Action.scheduler.confirmedCancelLesson.failure(
                                    e
                                )
                            );
                        }),
                        takeUntil(loggedOut(action$))
                    );
            })
        );
};

const deselectOnBookingOrCancelEpic: RootEpic = (action$, state$, helpers) => {
    return action$.pipe(
        filter(
            isActionOf([
                Action.scheduler.confirmedCancelLesson.success,
                Action.scheduler.confirmedCancelLesson.failure,
                Action.scheduler.bookLesson.failure,
                Action.scheduler.bookLesson.success,
            ])
        ),
        map(() => Action.scheduler.setSelectedAvailability(null))
    );
};

const uiEpic: RootEpic = (action$, state$, { schedulerApi }) => {
    return action$
        .pipe(filter(isActionOf(Action.scheduler.initializeSchedulerEnded)))
        .pipe(
            switchMap((r) => {
                let schedulerState = state$.value.scheduler;
                const currentCredit =
                    state$.value.lessonsSettings.currentCredit;
                let learner: any = schedulerState.learner;
                let learnerId = String(
                    state$.value.user.identity?.learnerExternalId
                );
                let startDate = schedulerState.selectedDate;

                const originLocation = state$.value.scheduler.originLocation;

                if (!currentCredit) {
                    return of(
                        Action.scheduler.loadAvailabilities.failure(
                            new Error(localization.scheduler_error_Deadline)
                        )
                    );
                } else {
                    if (originLocation === 'dashboard') {
                        return of();
                    } else {
                        return learner
                            ? of(
                                  Action.scheduler.loadAvailabilities.request({
                                      learnerID: learnerId,
                                      selectAllAvailable:
                                          schedulerState
                                              .selectedTeachersProperties
                                              .selectAll,
                                      selectedTeachers:
                                          schedulerState
                                              .selectedTeachersProperties
                                              .selectedTeachers,
                                      startDate: startDate,
                                  }),
                                  Action.scheduler.initLessons.request({
                                      learnerId: learnerId,
                                      startTime: startDate.format(
                                          'YYYY-MM-DDT00:00:00'
                                      ),
                                  })
                              )
                            : of(
                                  Action.scheduler.initLessons.request({
                                      learnerId: learnerId,
                                      startTime: startDate.format(
                                          'YYYY-MM-DDT00:00:00'
                                      ),
                                  })
                              );
                    }
                }
            })
        );
};

const bookLessonEpic: RootEpic = (action$, state$, { schedulerApi }) => {
    return action$
        .pipe(filter(isActionOf(Action.scheduler.bookLesson.request)))
        .pipe(
            switchMap((x) => {
                let learner: any = state$.value.scheduler.learner;
                let availabilities = state$.value.scheduler.availabilities;
                let lesson = x.payload.availability
                    ? x.payload.availability
                    : availabilities.find(
                          (a) => a.lessonId === x.payload.lessonId
                      );
                let mediaId = x.payload.media?.mediaID
                    ? x.payload.media?.mediaID
                    : state$.value.lessonsSettings.currentMedia?.mediaID;
                let teacherId = x.payload.teacherId;
                let maxHours = state$.value.scheduler.limitation.getMaxHours();
                let maxHoursRange =
                    state$.value.scheduler.limitation.getMaxHoursRange();
                let currentHours =
                    maxHoursRange !== 'None'
                        ? maxHoursRange === 'Week'
                            ? getHoursBookedWeek(state$.value)
                            : getHoursBookedMonth(state$.value)
                        : Number.POSITIVE_INFINITY;

                if (lesson && currentHours + lesson.duration / 60 > maxHours) {
                    if (maxHoursRange === 'Week') {
                        return of(
                            Action.scheduler.bookLesson.failure(
                                new Error('max_hours_reached')
                            )
                        );
                    } else {
                        return of(
                            Action.scheduler.bookLesson.failure(
                                new Error('max_hours_reached_month')
                            )
                        );
                    }
                }
                return schedulerApi
                    .validateLesson({
                        learnerID: String(learner.LearnerID),
                        creditID: String(lesson?.creditId),
                        duration: lesson?.duration || 0,
                        teacherID: String(teacherId),
                        mediaID: String(mediaId),
                        startTime:
                            lesson?.startDateTime.format(
                                'YYYY-MM-DDTHH:mm:00'
                            ) || '',
                        endTime:
                            lesson?.endDateTime?.format(
                                'YYYY-MM-DDTHH:mm:ss'
                            ) || '',
                    })
                    .pipe(
                        map((response) =>
                            Action.scheduler.bookLesson.success(response)
                        ),
                        catchError((err) =>
                            of(Action.scheduler.bookLesson.failure(err))
                        ),
                        takeUntil(loggedOut(action$))
                    );
            })
        );
};

const loadInitWeekLessonsWhenCancelledEpic: RootEpic = (
    action$,
    state$,
    { schedulerApi }
) => {
    return action$
        .pipe(
            filter(isActionOf(Action.scheduler.confirmedCancelLesson.success))
        )
        .pipe(
            switchMap((action) => {
                const learnerID = String(
                    state$.value.user.identity?.learnerExternalId
                );

                return schedulerApi
                    .initWeekLessons({
                        learnerID: learnerID,
                        startTime: moment(
                            state$.value.scheduler.selectedAvailability
                                ?.startDateTime
                        )
                            .clone()
                            .format('YYYY-MM-DDT00:00:00'),
                    })
                    .pipe(
                        map((response) =>
                            Action.scheduler.loadInitWeekLessons.success({
                                lessons: response.lessons,
                            })
                        ),
                        catchError((e) =>
                            of(Action.scheduler.loadInitWeekLessons.failure(e))
                        )
                    );
            })
        );
};

const loadAvailabilitesEpic: RootEpic = (action$, state$, { schedulerApi }) => {
    return action$
        .pipe(filter(isActionOf(Action.scheduler.loadAvailabilities.request)))
        .pipe(
            mergeMap((r) => {
                let teachers = r.payload.selectedTeachers;
                let sat = state$.value.scheduler.selectedTeachersProperties
                    .selectAll
                    ? 'true'
                    : 'false';
                let startDate = r.payload.startDate;
                let endDate = startDate.clone().add(1, 'days').startOf('day');

                var params = {
                    learnerID: r.payload.learnerID.toString(),
                    mediaID: String(
                        state$.value.lessonsSettings.currentMedia?.mediaID
                    ),
                    duration: String(
                        state$.value.lessonsSettings.currentDuration
                    ),
                    creditID: String(
                        state$.value.lessonsSettings.currentCredit?.creditId
                    ),
                    selectedWeek: {
                        start: startDate.format('YYYY-MM-DDT00:00:00'),
                    },
                    isBilingual: 'false',
                    selectedShift: '0',
                    SAT: sat,
                    teacherID: teachers
                        .map((t) => String(t.teacherId))
                        .toArray(),
                } as RetrieveAvailsOptions;

                return schedulerApi.retrieveAvails(params).pipe(
                    map(
                        (response: {
                            availabilities: List<Availability>;
                            teachers: List<Teacher>;
                        }) => {
                            const t = response.teachers;
                            let a = response.availabilities // fix API responce with extra avails from next days
                                .filter((a) =>
                                    a.startDateTime.isBefore(endDate)
                                );

                            if (a.size === 0) {
                                const emptyAvailability = new Availability({
                                    startDateTime: startDate.clone(),
                                    endDateTime: startDate.clone(),
                                    eventType: 'empty',
                                });
                                a = a.push(emptyAvailability);
                            }

                            return Action.scheduler.loadAvailabilities.success({
                                availabilities: a,
                                teachers: t,
                                selectAllAvailable: params.SAT === 'true',
                            });
                        }
                    ),
                    catchError((err) => {
                        if (err?.message === 'creditexpired') {
                            return of(
                                Action.scheduler.loadAvailabilities.failure(
                                    err
                                ),
                                Action.contentSavedActions.setNotification({
                                    show: true,
                                    error: true,
                                    maximum: false,
                                    text: localization.scheduler_error_Deadline,
                                    key: 21,
                                })
                            );
                        }
                        return of(
                            Action.scheduler.loadAvailabilities.failure(err)
                        );
                    }),
                    takeUntil(loggedOut(action$))
                );
            })
        );
};

const loadMoreAvailabilitesEpic: RootEpic = (
    action$,
    state$,
    { schedulerApi }
) => {
    return action$
        .pipe(
            filter(isActionOf(Action.scheduler.loadMoreAvailabilities.request))
        )
        .pipe(
            mergeMap((r) => {
                let teachers = r.payload.selectedTeachers;
                let sat = state$.value.scheduler.selectedTeachersProperties
                    .selectAll
                    ? 'true'
                    : 'false';
                let startDate = r.payload.startDate;

                var params = {
                    learnerID: r.payload.learnerID.toString(),
                    mediaID: String(
                        state$.value.lessonsSettings.currentMedia?.mediaID
                    ),
                    duration: String(
                        state$.value.lessonsSettings.currentDuration
                    ),
                    creditID: String(
                        state$.value.lessonsSettings.currentCredit?.creditId
                    ),
                    selectedWeek: {
                        start: startDate.format('YYYY-MM-DDT00:00:00'),
                    },
                    isBilingual: 'false',
                    selectedShift: '0',
                    SAT: sat,
                    teacherID: teachers
                        .map((t) => String(t.teacherId))
                        .toArray(),
                } as RetrieveAvailsOptions;

                return schedulerApi.retrieveAvails(params).pipe(
                    map(
                        (response: {
                            availabilities: List<Availability>;
                            teachers: List<Teacher>;
                        }) => {
                            const t = response.teachers;
                            let a = response.availabilities;

                            if (a.size === 0) {
                                const emptyAvailability = new Availability({
                                    startDateTime: startDate.clone(),
                                    endDateTime: startDate.clone(),
                                    eventType: 'empty',
                                });
                                a = a.push(emptyAvailability);
                            }

                            return Action.scheduler.loadMoreAvailabilities.success(
                                {
                                    availabilities: a,
                                    teachers: t,
                                }
                            );
                        }
                    ),
                    catchError((err) =>
                        of(Action.scheduler.loadMoreAvailabilities.failure(err))
                    )
                );
            })
        );
};

const loadForesenEpic: RootEpic = (action$, state$, { schedulerApi }) => {
    return action$
        .pipe(filter(isActionOf(Action.scheduler.loadForesen.request)))
        .pipe(
            switchMap((r) => {
                const learner = state$.value.user.identity?.learnerExternalId;
                return schedulerApi.getForeseen(learner as any).pipe(
                    map((response) => {
                        return Action.scheduler.loadForesen.success(
                            new Foresen(response)
                        );
                    }),
                    catchError((e) =>
                        of(Action.scheduler.loadForesen.failure(e))
                    ),
                    takeUntil(loggedOut(action$))
                );
            })
        );
};

const loadForseenVirtualClass: RootEpic = (action$, state$, { vcrApi }) => {
    return action$
        .pipe(filter(isActionOf(Action.scheduler.loadForeseenVCR.request)))
        .pipe(
            switchMap((r) => {
                const learnerUUID = state$.value.user.identity?.learnerUUID;
                const lessonExternalID = r.payload;

                const param = {
                    learnerUUID,
                    lessonExternalID,
                };

                return vcrApi.getForeseenClass(param as any).pipe(
                    map((response) => {
                        const newData = response.virtualClasses.map(
                            (data: any) => {
                                const languageList =
                                    state$.value.learnerProfile?.languageList
                                        .valueSeq()
                                        .toArray();

                                const topicLanguage = languageList.find(
                                    (y: any) =>
                                        y.languageUUID ===
                                        data.virtualClassDto.topicId
                                );

                                const classDetail = {
                                    ...data.virtualClassDto,
                                    topicCode: topicLanguage?.code,
                                    topicName: topicLanguage?.name,
                                };

                                return {
                                    ...data,
                                    virtualClassDto: classDetail,
                                };
                            }
                        );

                        const newParam = {
                            ...response,
                            virtualClasses: newData,
                        };

                        return Action.scheduler.loadForeseenVCR.success(
                            newParam
                        );
                    }),
                    catchError((e) =>
                        of(Action.scheduler.loadForeseenVCR.failure(e))
                    ),
                    takeUntil(loggedOut(action$))
                );
            })
        );
};

const loadGroupForesenEpic: RootEpic = (action$, state$, { schedulerApi }) => {
    return action$
        .pipe(filter(isActionOf(Action.scheduler.loadGroupForesen.request)))
        .pipe(
            switchMap((r) => {
                const learnerUUID = state$.value.user?.identity
                    ?.learnerUUID as string;
                return schedulerApi.getGroupForeseen(learnerUUID as any).pipe(
                    map((response) => {
                        const isEmpty = Object.keys(response).length === 0;
                        let responseObject: any = null;
                        if (!isEmpty) {
                            responseObject = new Foresen({
                                ...response,
                                MediaType: 'Group',
                            });
                        }

                        return Action.scheduler.loadGroupForesen.success(
                            responseObject
                        );
                    }),
                    catchError((e) =>
                        of(Action.scheduler.loadForesen.failure(e))
                    ),
                    takeUntil(loggedOut(action$))
                );
            })
        );
};

const loadInitializeForeseen: RootEpic = (
    action$,
    state$,
    { schedulerApi }
) => {
    return action$
        .pipe(filter(isActionOf(Action.scheduler.loadForeseenInit)))
        .pipe(
            switchMap((r) => {
                const learnerID = String(
                    state$.value.user.identity?.learnerExternalId
                );
                return of(
                    Action.scheduler.initializeScheduler.request({
                        learnerID: learnerID,
                        selectedDate: moment.utc().startOf('day'),
                    }),
                    Action.scheduler.loadForesen.request(r.payload),
                    Action.scheduler.loadGroupForesen.request(r.payload)
                );
            })
        );
};

const InitializeLessonSettingsEpic: RootEpic = (action$, state$) =>
    action$.pipe(
        filter(isActionOf(Action.scheduler.initializeScheduler.success)),
        switchMap(({ payload }) => {
            const stateMedia = state$.value.lessonsSettings.currentMedia;
            const stateCredit = state$.value.lessonsSettings.currentCredit;
            const stateDuration = state$.value.lessonsSettings.currentDuration;

            let currentMedia = stateMedia;
            let currentCredit: CreditRecord | null = payload.credits.first()
                ? payload.credits.first()
                : null;
            let currentDuration = currentCredit
                ? String(currentCredit?.duration[0])
                : null;

            if (!stateMedia) {
                const authorizedMedia = payload.learner.LearnerAuthorizedMedia;
                const defaultMedia = payload.learner.LearnerDefaultMedia;

                const defaultMediaInList = authorizedMedia.find((item: any) => {
                    return item.name === defaultMedia.MediaType;
                }) as MediaRecord;

                if (defaultMediaInList) {
                    currentMedia = payload.medias.find(
                        (item) => item.mediaID === defaultMedia.MediaID
                    ) as MediaRecord;
                    if (!currentMedia) {
                        currentMedia = new MediaRecord({
                            userValue: defaultMedia.UserValue,
                            mediaType: defaultMedia.MediaType,
                            mediaID: defaultMedia.MediaId,
                            default: defaultMedia.Default,
                        });
                    }
                } else {
                    let dataTest: any = [];
                    payload.medias.forEach((x) => dataTest.push(x));

                    const uniqueObjArray = [
                        ...new Map(
                            dataTest.map((item: any) => [
                                item['mediaType'],
                                item,
                            ])
                        ).values(),
                    ];
                    const teams = uniqueObjArray.find((x: any) => {
                        return x.mediaType === 'Teams';
                    }) as MediaRecord;

                    const sequence: any = [];
                    authorizedMedia.forEach((y: any, i: any) => {
                        if (teams) {
                            if (y.name === 'Teams') {
                                sequence.push({ ...y, index: 0 });
                            } else {
                                const teamsSequence = sequence.find(
                                    (x: any) => {
                                        return x.name === 'Teams';
                                    }
                                ) as MediaRecord;
                                sequence.push({
                                    ...y,
                                    index: teamsSequence ? i : i + 1,
                                });
                            }
                        } else {
                            sequence.push({ ...y, index: i });
                        }
                    });

                    const listSortSequence = sequence.sort(
                        (a: any, b: any) => a.index - b.index
                    );
                    listSortSequence.every((t: any) => {
                        const mediaItem = uniqueObjArray.find((x: any) => {
                            return x.mediaType === t.name;
                        }) as MediaRecord;

                        if (mediaItem) {
                            currentMedia = new MediaRecord(mediaItem);
                            return false;
                        }
                        return true;
                    });

                    if (!currentMedia) {
                        currentMedia = new MediaRecord({
                            userValue: defaultMedia.UserValue,
                            mediaType: defaultMedia.MediaType,
                            mediaID: defaultMedia.MediaId,
                            default: defaultMedia.Default,
                        });
                    }
                }
            }

            if (stateCredit) {
                const creditId = stateCredit.creditId;
                const findCredit = payload.credits.find(
                    (item) => item.creditId === creditId
                ) as CreditRecord;
                currentCredit = findCredit ? findCredit : currentCredit;
                currentDuration = stateDuration as string;
            }

            return of(
                Action.scheduler.setCurrentMedia(currentMedia as MediaRecord),
                Action.scheduler.setCurrentCredit(currentCredit),
                Action.scheduler.setCurrentDuration(currentDuration),
                Action.scheduler.initializeSchedulerEnded()
            );
        })
    );

const initLanguageEpic: RootEpic = (action$, state$, { schedulerApi }) => {
    return action$
        .pipe(filter(isActionOf(Action.scheduler.initLanguage.request)))
        .pipe(
            switchMap((r) => {
                return schedulerApi.getLanguage(r.payload.learnerId).pipe(
                    map((response) => {
                        return Action.scheduler.initLanguage.success(
                            new SchedulerLanguageProps({
                                learnerName: response.Learner.LearnerName,
                                translations: response.Translations,
                            })
                        );
                    }),
                    catchError((e) =>
                        of(Action.scheduler.initLanguage.failure(e))
                    )
                );
            })
        );
};

const createMediaEpic: RootEpic = (action$, state$, { schedulerApi }) => {
    return action$
        .pipe(filter(isActionOf(Action.scheduler.addNewMedia.request)))
        .pipe(
            switchMap((action) => {
                return schedulerApi.createMedia(action.payload).pipe(
                    mergeMap((response) => {
                        const newCurrentMedia = {
                            default: true,
                            mediaType: action.payload.mediaType,
                            mediaID: 0,
                            userValue: action.payload.userValue,
                        };

                        return of(
                            Action.scheduler.addNewMedia.success({
                                medias: response.medias,
                                learner: response.learner,
                            }),
                            Action.scheduler.setCurrentMedia(
                                newCurrentMedia as any
                            )
                        );
                    }),
                    catchError((e) =>
                        of(Action.scheduler.addNewMedia.failure(e))
                    )
                );
            })
        );
};

const createMediaMessageSuccess: RootEpic = (action$, state$, _) => {
    return action$.pipe(
        filter(isActionOf(Action.scheduler.addNewMedia.success)),
        map(() =>
            Action.contentSavedActions.setNotification({
                show: true,
                error: false,
                maximum: false,
                text: localization.profile_message_NewMediaSuccessfullyCreated,
                key: 22,
            })
        )
    );
};

//TODO: create general message failure (error-handling)
const createMediaMessageFailure: RootEpic = (action$, state$, _) => {
    return action$.pipe(
        filter(isActionOf(Action.scheduler.addNewMedia.failure)),
        map(({ payload }) => {
            let msg = (() => localization.profile_message_CantAddMedia!)();
            if (payload.message === 'max_hours_reached') {
                //Todo: find some format string solution
                msg = (() =>
                    localization.scheduler_message_MaxHoursReached!)().replace(
                    '{0}',
                    state$.value.scheduler.limitation.getMaxHours().toString()
                );
            }

            return Action.contentSavedActions.setNotification({
                show: true,
                error: true,
                maximum: false,
                text: msg,
                key: 23,
            });
        })
    );
};

const loadForeseenLessonsForNextMonth: RootEpic = (action$, state$, _) => {
    return action$
        .pipe(filter(isActionOf(Action.scheduler.selectMonthDate)))
        .pipe(
            switchMap((action) => {
                let learnerID = String(
                    state$.value.user.identity?.learnerExternalId
                );

                const isLimitedDate = getMaxDateForBrowsing(
                    state$.value
                ).isBefore(action.payload);

                if (isLimitedDate) {
                    return of(
                        Action.scheduler.setAvailabilityError(
                            'limited_availability_view'
                        )
                    );
                }

                return of(
                    Action.scheduler.initLessons.request({
                        startTime: action.payload.format('YYYY-MM-DDT00:00:00'),
                        learnerId: learnerID,
                    })
                );
            })
        );
};

export const schedulerEpics = [
    initializeSchedulerEpic,
    loadAvailabilitesEpic,
    loadMoreAvailabilitesEpic,
    uiEpic,
    onOpenEpic,
    bookLessonEpic,
    onBookingEpic,
    cancelLessonEpic,
    trulyCancelLessonEpic,
    initLessonsEpic,
    loadInitWeekLessonsEpic,
    bookingMessageSuccess,
    bookingMessageFailure,
    cancelMessageSuccess,
    cancelMessageFailure,
    loadForesenEpic,
    loadForseenVirtualClass,
    loadGroupForesenEpic,
    loadInitializeForeseen,
    deselectOnBookingOrCancelEpic,
    onSettingsOpenEpic,
    InitializeLessonSettingsEpic,
    changeSchedulerDateEpic,
    changeSchedulerSelectedTeachersProps,
    changeSchedulerSelectedTeachersEpic,
    setSchedulerLimitationEpic,
    initLanguageEpic,
    createMediaEpic,
    createMediaMessageSuccess,
    createMediaMessageFailure,
    loadForeseenLessonsForNextMonth,
    loadInitWeekLessonsWhenCancelledEpic,
];
