import { createReducer } from 'typesafe-actions';
import { loadHomeworkList, loadMoreList, nextKey } from './homework-action';
import { Homework } from './homework';
import { combineReducers } from 'redux';
import { clearAllResources } from '../resources/resources-actions';
import { loadInProgressActivities } from '../resources/in-progress/in-progress-actions';
import { modifyResourceStatus } from '../../epics/resources/helpers';
import { loadCompletedActivities } from '../resources/completed/completed-actions';

export const homeworkListReducer = createReducer<Homework[] | null>(null)
    .handleAction(loadHomeworkList.success, (state, action) => {
        return state ? state.concat(action.payload) : action.payload;
    })
    .handleAction(clearAllResources, () => null)
    .handleAction(loadInProgressActivities.success, (state, action) => {
        const statuses = action.payload;
        return (state || []).map(
            modifyResourceStatus<Homework>(statuses, null)
        );
    })
    .handleAction(loadCompletedActivities.success, (state, action) => {
        const statuses = action.payload;
        return (state || []).map(
            modifyResourceStatus<Homework>(null, statuses)
        );
    });

export const isLoadingHomework = createReducer(false)
    .handleAction(loadHomeworkList.request, () => true)
    .handleAction(
        [loadHomeworkList.success, loadHomeworkList.failure],
        () => false
    );

export const homeworkListAmount = createReducer(20)
    .handleAction(loadMoreList, (state, action) => action.payload)
    .handleAction(clearAllResources, () => 20);

export const reachedEnd = createReducer(false).handleAction(
    loadHomeworkList.success,
    (state, action) => {
        return action.payload.length === 0;
    }
);

export const homeWorkNextKey = createReducer(0)
    .handleAction(nextKey, (state, action) => action.payload)
    .handleAction(clearAllResources, () => 0);

export const homeworkReducer = () =>
    combineReducers({
        list: homeworkListReducer,
        amount: homeworkListAmount,
        isLoading: isLoadingHomework,
        reachedEnd: reachedEnd,
        nextKey: homeWorkNextKey,
    });
