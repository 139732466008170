import { InProgressResource } from '../../store/resources/in-progress/in-progress-record';
import { CompletedResource } from '../../store/resources/completed/completed-record';
import { Action } from '../../store/root-action';
import { RootAction, RootState } from 'typesafe-actions';
import {
    CourseCompletionStatusEnum,
    CourseStatusDetails,
    TrainingPath,
} from '../../store/resources/training-path/training-path-record';

export const modifyResourceStatus =
    <T>(
        inProgress: InProgressResource[] | null,
        completed: CompletedResource[] | null
    ) =>
    (item: any): T => {
        const itemId = item.get('legacy')?.articleId;
        const itemStatus = item.get('status');

        const completedItem =
            completed &&
            completed.find(
                ({ legacy: { articleId, id } }) =>
                    articleId === itemId || id === itemId
            );

        if (completedItem) {
            const { quizStatus, quizScore, quizScoreMax } =
                completedItem.quiz || {};

            const score =
                quizScoreMax === 0
                    ? 0
                    : Math.floor((quizScore / quizScoreMax) * 100);

            if (quizStatus === 'p') {
                return item.set('status', 'passed').set('score', score) as T;
            } else {
                return item.set('status', 'complete').set('score', score) as T;
            }
        }

        const inProgressItem =
            inProgress &&
            inProgress.find(
                ({ legacy: { articleId, id } }) =>
                    articleId === itemId || id === itemId
            );

        if (
            inProgressItem &&
            itemStatus !== 'complete' &&
            itemStatus !== 'passed'
        ) {
            // for UI set inProgress if was not previously completed
            return item
                .set('status', 'inProgress')
                .set(
                    'score',
                    Math.floor(inProgressItem.contentProgress * 100)
                ) as T;
        }
        // To check if status checking was applied to all activities uncomment next row
        // return item.set('status', 'inProgress');
        return item as T;
    };

export const getStatusLoadingActions = (
    inProgress: InProgressResource[] | null,
    completed: CompletedResource[] | null,
    coursesStatuses?: CourseStatusDetails[] | null
): RootAction[] => {
    const actionList = new Array<RootAction>();

    if (inProgress === null)
        actionList.push(Action.resources.loadInProgressActivities.request());

    if (completed === null)
        actionList.push(Action.resources.loadCompletedActivities.request());

    if (coursesStatuses === null)
        actionList.push(Action.trainingPath.loadCoursesStatuses.request());

    return actionList;
};

export const modifyCourseStatus =
    (statuses: CourseStatusDetails[] | null) =>
    (item: any): TrainingPath => {
        const itemId = item.get('articleId');

        const statusReport =
            statuses &&
            statuses.find(
                ({ legacy: { articleId } }) =>
                    articleId.toString() === itemId.toString()
            );

        if (statusReport) {
            const status = statusReport.progressStatus;

            if (status) {
                return item
                    .set(
                        'status',
                        statusReport.curriculumCompletionStatus ===
                            CourseCompletionStatusEnum.Passed
                            ? CourseCompletionStatusEnum.Passed
                            : status
                    )
                    .set('score', +statusReport.curriculumScoreAvg)
                    .set('contentCompleted', +statusReport.completedContents)
                    .set('contentTotal', +statusReport.totalContents)
                    .set(
                        'percentsToComplete',
                        +statusReport.percentsToComplete
                    );
            }
        }

        // To check if status modification was applied to all needed courses uncomment next row
        // return item.set('status', 'inProgress') as TrainingPath;
        return item as TrainingPath;
    };

export const getActionWithStatus = (
    loadedCourses: TrainingPath[],
    state: RootState
): {
    statusLoadingActions: RootAction[];
    actualizedCourses: TrainingPath[];
} => {
    const inProgressResources = state.resources.inProgress.list;
    const completedResources = state.resources.completed.list;
    const coursesStatuses = state.trainingPath.coursesStatuses;

    const actualizedCourses = loadedCourses.map(
        modifyCourseStatus(coursesStatuses)
    ) as TrainingPath[];

    return {
        statusLoadingActions: getStatusLoadingActions(
            inProgressResources,
            completedResources,
            coursesStatuses
        ),
        actualizedCourses: actualizedCourses,
    };
};
