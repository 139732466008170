import { createAsyncAction } from 'typesafe-actions';

export const loadChannelList = createAsyncAction(
    '@@channel/load-list/request',
    '@@channel/load-list/success',
    '@@channel/load-list/failure'
)<{ topicUUID: string; userUUID: string }, any, Error>();

export const channelActions = {
    loadChannelList,
};
