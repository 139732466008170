import { Observable } from 'rxjs';
import { ApiHeaders } from '../services/api-headers';

import { map } from 'rxjs/operators';
import { ajax } from 'rxjs/ajax';
import { apiv1 } from '../services/api-urls';

export interface LocalizationFile {
    languageCode: string;
    version: number;
    dictionary: LocalizationString[];
}

export interface OOOMessage {
    heading: string;
    content: string;
}

export interface OOOKeyTranslation {
    key: keyof OOOMessage;
    text: string;
}

export interface LocalizationString {
    key: string;
    text: string;
}

export interface PreferredLangauges {
    displayLanguage: string;
    preferedCommunicationLanguage: string;
    userId: string;
    offlineModeEnabled: boolean;
}

export interface ILocalizationApi {
    loadLanguageLocalization(code: string): Observable<LocalizationFile>;

    getUserLanguageCode(userId: string): Observable<string>;

    getUserPreferredLanguageAndCode(
        userId: string
    ): Observable<PreferredLangauges>;

    setUserLanguageCode(user: {
        userId: string;
        displayLanguage: string;
        preferedCommunicationLanguage: string;
    }): Observable<void>;

    loadOOOMessage(code: string): Observable<OOOMessage>;
}

export class LocalizationApi implements ILocalizationApi {
    constructor(private readonly apiHeaders: ApiHeaders) {
        this.apiHeaders = apiHeaders;
    }

    getUserLanguageCode(userId: string): Observable<string> {
        return ajax
            .getJSON<any>(
                apiv1() + 'learner-preferences/user-profile/' + userId,
                this.apiHeaders.getHeaders({}, true)
            )
            .pipe(
                map((response) => {
                    return response && response.displayLanguage;
                })
            );
    }

    getUserPreferredLanguageAndCode(
        userId: string
    ): Observable<PreferredLangauges> {
        return ajax
            .getJSON<any>(
                apiv1() + 'learner-preferences/user-profile/' + userId,
                this.apiHeaders.getHeaders({}, true)
            )
            .pipe(
                map((response) => {
                    return {
                        displayLanguage: response.displayLanguage,
                        preferedCommunicationLanguage:
                            response.preferedCommunicationLanguage,
                        userId: response.userId,
                        offlineModeEnabled: response.offlineModeEnabled,
                    };
                })
            );
    }

    setUserLanguageCode(user: {
        userId: string;
        displayLanguage: string;
        preferedCommunicationLanguage: string;
    }): Observable<any> {
        return ajax
            .post(
                apiv1() + 'learner-preferences/user-profile/',
                user,
                this.apiHeaders.getHeaders(
                    {
                        'Content-Type': 'application/json',
                    },
                    true
                )
            )
            .pipe();
    }

    loadLanguageLocalization(code: string): Observable<LocalizationFile> {
        return ajax
            .getJSON<LocalizationString[]>(
                apiv1() + 'localization/translation/portal/1.0.2/' + code,
                this.apiHeaders.getHeaders({}, true)
            )
            .pipe(
                map((response) => {
                    return {
                        version: 0.1,
                        dictionary: response,
                        languageCode: code,
                    };
                })
            );
    }

    loadOOOMessage(code: string): Observable<OOOMessage> {
        return ajax
            .getJSON<OOOKeyTranslation[]>(
                //TODO: change to correct api
                apiv1() + 'localization/translation/ooo-message/1.0.2/' + code,
                this.apiHeaders.getHeaders({}, true)
            )
            .pipe(
                map((response) => {
                    return {
                        heading: response.find(({ key }) => key === 'heading')!
                            .text,
                        content: response.find(({ key }) => key === 'content')!
                            .text,
                    };
                })
            );
    }
}
