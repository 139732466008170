import { storage, IStorage } from './types';

export class CacheStorage implements IStorage {
    protected storage: storage<any, any> = new Map();

    public setItem(key: any, value: any) {
        this.storage.set(key, value);
    }

    public getItem(key: any) {
        return this.storage.get(key);
    }

    public hasItem(key: any) {
        return this.storage.has(key);
    }

    public clear() {
        this.storage.clear();
    }
}

export class PromiseStorage extends CacheStorage {
    public setItem(key: any, value: any) {
        super.setItem(key, value);

        value.catch(() => this.storage.delete(key));
    }
}

export class GlobalStorage {
    storage: storage<any, IStorage> = new Map();

    getStorage(key: any, defaultStorage: IStorage): IStorage {
        if (this.storage.has(key)) {
            return this.storage.get(key)!;
        }

        this.storage.set(key, defaultStorage);

        return defaultStorage;
    }
}
