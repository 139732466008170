import { ApiHeaders } from '../api-headers';
import { Axios } from 'axios-observable';
import { map } from 'rxjs/operators';
import {
    OnboardingDisplayStrategyProps,
    StateProgressProps,
} from '../../store/learner-onboarding/learner-onboarding-records';
import { Observable } from 'rxjs';
import { apiv1 } from '../api-urls';

export class LearnerOnboardingApi {
    constructor(private readonly apiHeaders: ApiHeaders) {}

    loadLearnerOnboardingDisplayStrategy(
        learnerUUID: string
    ): Observable<OnboardingDisplayStrategyProps | null> {
        return Axios.get<OnboardingDisplayStrategyProps | null>(
            apiv1() + `display-strategy/handle/onboarding/user/${learnerUUID}`,
            { headers: this.apiHeaders.getHeaders() }
        ).pipe(map((x) => x.data));
    }

    loadCurrentLearnerOnboardingDisplay(
        learnerUUID: string
    ): Observable<StateProgressProps | null> {
        return Axios.get<StateProgressProps | null>(
            apiv1() + `onboarding/state/${learnerUUID}`,
            { headers: this.apiHeaders.getHeaders({}, true) }
        ).pipe(
            map((response) => {
                const noData = {
                    learnerUUID: learnerUUID,
                    organizationId: 0,
                    handle: 'onboarding-empty' as string,
                    onboardingStateDetails: [],
                } as StateProgressProps;

                return response.data ? response.data : noData;
            })
        );
    }

    saveLearnerStateProgress(
        displayStrategy: OnboardingDisplayStrategyProps | null,
        displayProgressState: StateProgressProps | null,
        nextProgressDetail: number,
        learnerUUID: string
    ): Observable<StateProgressProps> {
        const displayRuleDetails = displayStrategy?.displayRule.find(
            (item: any) => {
                let progressIndex = 0;
                if (displayProgressState) {
                    const nextProgress =
                        displayProgressState?.onboardingStateDetails;
                    progressIndex = nextProgress.length;
                }

                if (item.order === progressIndex) {
                    return item;
                }

                return undefined;
            }
        );
        const newValue = {
            component: displayRuleDetails && displayRuleDetails.component,
            subComponent: displayRuleDetails && displayRuleDetails.subComponent,
            state: 'Done',
        };

        let tempOnboardingStateDetails;
        if (displayProgressState) {
            const onboardingStateDetails =
                displayProgressState?.onboardingStateDetails;
            tempOnboardingStateDetails = [...onboardingStateDetails, newValue];
        } else {
            tempOnboardingStateDetails = [newValue];
        }

        let tempDisplayProgressState = {
            handle: 'onboarding',
            learnerId: learnerUUID,
            onboardingStateDetails: tempOnboardingStateDetails,
            organizationId: 0,
        };

        return Axios.post(
            apiv1() + `onboarding/state/`,
            tempDisplayProgressState,
            {
                headers: this.apiHeaders.getHeaders({
                    'Content-Type': 'application/json',
                }),
            }
        ).pipe(map((x) => x.data));
    }
}
