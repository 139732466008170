import { ResourceContentType } from '../../store/resources/resource-content-type';

export type ResourceTypeDto =
    | 'how-to'
    | 'workshop'
    | 'grammar'
    | 'grammar-rule'
    | 'vocabulary'
    | 'article'
    | 'video'
    | 'standalone-quiz'
    | string;

export const parseDtoToResourceType: (
    dto: ResourceTypeDto
) => ResourceContentType = (dto: ResourceTypeDto) => {
    switch (dto) {
        case 'article':
            return ResourceContentType.Article;
        case 'video':
            return ResourceContentType.Video;
        case 'grammar':
        case 'grammar-rule':
            return ResourceContentType.Grammar;
        case 'how-to':
            return ResourceContentType.Howto;
        case 'vocabulary':
            return ResourceContentType.Vocabulary;
        case 'workshop':
            return ResourceContentType.Workshop;
        case 'standalone-quiz':
            return ResourceContentType.Quiz;
        default:
            return ResourceContentType.Unknown;
    }
};
