import React, { FC } from 'react';
import './privacy-popup.css';

interface PrivacyPopUpProps {
    handleToggle: () => void;
    isActive: boolean;
}

export const PrivacyPopup: FC<PrivacyPopUpProps> = ({
    handleToggle,
    isActive,
}) => {
    const handleClick = () => {
        handleToggle();
    };
    return (
        <>
            {isActive && (
                <div className="privacy-popup-container">
                    <div className="privacy-popup__background" />
                    <div className="privacy-popup__card">
                        <p className="privacy-popup__text">
                            不同意隐私政策和设备权限，应用将无正常运行，请在隐私政策页面点击同意按钮继续使用应用。
                        </p>

                        <button
                            className="privacy-popup__button"
                            onClick={handleClick}
                            tabIndex={0}
                            onTouchStart={() => {}}
                        >
                            好的
                        </button>
                    </div>
                </div>
            )}
        </>
    );
};
