import { List, Record } from 'immutable';
import { SlideContent } from './section';
import { ResourceContentType } from '../resource-content-type';

const titleSlideTemplate = 'title-slide';

interface TitleSlideProps extends SlideContent {
    template: typeof titleSlideTemplate;
    objectives: List<string>;
    levels: List<string> | null;
    studyTime: string;
    bgImage: string | null;
    resourceType: ResourceContentType;
}

const titleSlidePropsDefaults: TitleSlideProps = {
    template: titleSlideTemplate,
    objectives: List(),
    levels: null,
    studyTime: '',
    bgImage: null,
    resourceType: ResourceContentType.Unknown,
};

export class TitleSlideContent extends Record(titleSlidePropsDefaults) {}

export function isTitleSlide(slide: SlideContent): slide is TitleSlideContent {
    return slide.template === titleSlideTemplate;
}
