import { RootEpic } from '../root-epic';
import {
    catchError,
    concatMap,
    filter,
    switchMap,
    withLatestFrom,
} from 'rxjs/operators';
import { isActionOf } from 'typesafe-actions';
import { Action } from '../../store/root-action';
import { of } from 'rxjs';
import { ResourceContent } from '../../store/resources/resource-content';
import { getStatusLoadingActions, modifyResourceStatus } from './helpers';

const searchEpic: RootEpic = (action$, state$, { searchApi }) =>
    action$.pipe(
        filter(isActionOf(Action.resources.searchResource.request)),
        withLatestFrom(state$),
        switchMap(([x, state]) =>
            searchApi
                .search(
                    state$.value.resources.search.searchResults.count(),
                    [x.payload.keyword],
                    state$.value.learnerProfile.currentLanguage?.code as string,
                    x.payload.moduleType
                )
                .pipe(
                    concatMap((loadedResources) => {
                        if (
                            loadedResources[0]?.title === 'resource_not_found'
                        ) {
                            return of(
                                Action.resources.searchResource.failure(
                                    new Error(loadedResources[0]?.title)
                                )
                            );
                        }
                        const inProgressResources =
                            state.resources.inProgress.list;
                        const completedResources =
                            state.resources.completed.list;
                        const actualizedResources = loadedResources.map(
                            modifyResourceStatus<ResourceContent>(
                                inProgressResources,
                                completedResources
                            )
                        );
                        return of(
                            ...getStatusLoadingActions(
                                inProgressResources,
                                completedResources
                            ),
                            Action.resources.searchResource.success(
                                actualizedResources
                            )
                        );
                    }),
                    catchError((y) =>
                        of(Action.resources.searchResource.failure(y))
                    )
                )
        )
    );

export const searchEpics = [searchEpic];
