import { ResourceContentProps } from './resources-content-record';
import { createAsyncAction, createAction } from 'typesafe-actions';

export const loadSelectedResourceContent = createAsyncAction(
    '@@resources/load-selected-content/request',
    '@@resources/load-selected-content/success',
    '@@resources/load-selected-content/failure'
)<{ contentUUID: string }, ResourceContentProps, Error>();

export const setDisplayResourceContent = createAction(
    '@@resources/set-display-resource-content'
)<ResourceContentProps>();

export const resourceContentActions = {
    loadSelectedResourceContent,
    setDisplayResourceContent,
};

export const startPollingActivityStatuses = createAsyncAction(
    '@@resources/poll-all-statuses/request',
    '@@resources/poll-all-statuses/success',
    '@@resources/poll-all-statuses/failure'
)<undefined, undefined, Error>();

export const stopPollingActivityStatuses = createAsyncAction(
    '@@resources/stop-polling-all-statuses/request',
    '@@resources/stop-polling-statuses/success',
    '@@resources/stop-polling-statuses/failure'
)<undefined, undefined, Error>();
