import { List, Record } from 'immutable';
import Moment from 'moment';
import { ResourceDescription } from '../resources';
import { HowtoContent } from './howto-content';
import { ResourceContentType } from '../resource-content-type';
import { ContentMetadataProps } from '../resources-content-record';

//search distingueshes between vocabulary/howto/grammar,
// but since they are the same we can leave it as is.
// Anyway, DTO string might change.

interface HowtoDescriptionProps extends ResourceDescription {
    content: HowtoContent | null;
    contentMetadata: ContentMetadataProps | null;
}

const defaultHowtoDescriptionProps: HowtoDescriptionProps = {
    resourceId: '',
    groupId: 0,
    categories: List(),
    date: Moment.invalid(),
    index: 0,
    tags: List(),
    thumbnail: null,
    title: '',
    type: ResourceContentType.Unknown,
    content: null,
    contentMetadata: null,
    quizArticleId: null,
    contentUUId: '',
    quizUUID: '',
    template: 'old',
    topicUUID: '',
};

export class HowtoDescription
    extends Record(defaultHowtoDescriptionProps)
    implements HowtoDescriptionProps {}

export function validateNRT(
    resource: ResourceDescription
): resource is HowtoDescription {
    return [
        ResourceContentType.Howto,
        ResourceContentType.Vocabulary,
        ResourceContentType.Grammar,
    ].includes(resource.type);
}

export function isHowto(
    resource: ResourceDescription
): resource is HowtoDescription {
    return [
        ResourceContentType.Howto,
        ResourceContentType.Workshop,
        ResourceContentType.Vocabulary,
        ResourceContentType.Grammar,
        ResourceContentType.Video,
        ResourceContentType.Article,
    ].includes(resource.type);
}
