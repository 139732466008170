import { ApiHeaders } from './api-headers';
import { Axios } from 'axios-observable';
import { map } from 'rxjs/operators';
import { from, lastValueFrom, of } from 'rxjs';
import { ajax } from 'rxjs/ajax';
import {
    LanguageProps,
    LearnerProfileProps,
    JobFunctionProps,
    IndustryProps,
} from '../store/learner-profile/learner-profile-records';
import {
    ProficienciesDetailDTO,
    ProficienciesDTO,
    ProficiencyConvertedValueDTO,
} from '../store/proficiency/proficiency-dto';
import { Observable } from 'rxjs';
import { apiv1, apiv100, gradingFind } from './api-urls';

import { GradingDto } from '../store/grading/grading-dto';

export interface TopicDTO {
    attributes: {
        LanguageCode: string;
        VCR: string;
    };
    id: string;
    name: string;
    type: string;
}

export class LearnerProfileApi {
    constructor(private readonly apiHeaders: ApiHeaders) {}

    loadLearnerProfilePath(
        learnerUUID: string | unknown
    ): Observable<LearnerProfileProps | null> {
        return Axios.get<LearnerProfileProps | null>(
            apiv1() + `learning-profile/${learnerUUID}`,
            { headers: this.apiHeaders.getHeaders() }
        ).pipe(map((x) => x.data));
    }

    saveLanguageToProfile(
        learnerUUID: string,
        languageId: string
    ): Observable<LearnerProfileProps> {
        return this.saveProfile({ learnerUUID, languageId });
    }

    saveIndustryToProfile(
        learnerUUID: string,
        industryUUID: string[]
    ): Observable<LearnerProfileProps> {
        return this.saveProfile({ learnerUUID, industryUUID });
    }

    saveJobFunctionToProfile(
        learnerUUID: string,
        jobFunctionUUID: string[]
    ): Observable<LearnerProfileProps> {
        return this.saveProfile({ learnerUUID, jobFunctionUUID });
    }

    updateIndustryLearnerProfile(
        LearnerProfile: LearnerProfileProps,
        industryUUID: string[]
    ): Observable<LearnerProfileProps> {
        return this.updateProfileIndustry({
            LearnerProfile,
            industryUUID,
        });
    }

    updateJobFunctionToProfile(
        LearnerProfile: LearnerProfileProps,
        jobFunctionUUID: string[]
    ): Observable<LearnerProfileProps> {
        return this.updateProfileIndustry({
            LearnerProfile,
            jobFunctionUUID,
        });
    }

    updateLanguageLearnerProfile(
        LearnerProfile: LearnerProfileProps,
        currentLanguage: LanguageProps,
        topicUUID: string
    ): Observable<LearnerProfileProps> {
        return this.updateProfileIndustry({
            LearnerProfile,
            topicUUID,
            currentLanguage,
        });
    }

    loadLanguageList(): Observable<LanguageProps[]> {
        return Axios.get<TopicDTO[]>(apiv100() + `topic/`, {
            headers: this.apiHeaders.getHeaders(),
        }).pipe(
            map((x) => {
                return x.data.map((item, index) => {
                    return {
                        languageUUID: item.id,
                        code: item.attributes.LanguageCode,
                        name: item.name,
                        type:
                            item.attributes.VCR === 'true' ? 'vcr' : item.type,
                    };
                }) as LanguageProps[];
            })
        );
    }

    loadJobFunctionList(): Observable<JobFunctionProps[]> {
        return Axios.get<JobFunctionProps[]>(apiv1() + `jobfunction/all`, {
            headers: this.apiHeaders.getHeaders(),
        }).pipe(map((x) => x.data));
    }

    loadIndustryList(): Observable<IndustryProps[]> {
        return Axios.get<IndustryProps[]>(apiv1() + `industry/all`, {
            headers: this.apiHeaders.getHeaders(),
        }).pipe(map((x) => x.data));
    }

    saveProfile(params: {
        learnerUUID: string;
        languageId?: string;
        industryUUID?: string[];
        jobFunctionUUID?: string[];
    }): Observable<LearnerProfileProps> {
        const { learnerUUID, languageId, industryUUID, jobFunctionUUID } =
            params;

        const body = {
            defaultObjectiveUUID: learnerUUID,
            learnerUUID: learnerUUID,
            demographic: {
                industryUUID: industryUUID ? industryUUID : [],
                jobFunctionUUID: jobFunctionUUID ? jobFunctionUUID : [],
            },
            objectives: languageId
                ? [
                      {
                          creator: {
                              creatorType: 'Learner',
                              creatorUUID: learnerUUID,
                              organisationalPriority: 0,
                              organisationalUUID: learnerUUID,
                          },
                          learnerUUID: learnerUUID,
                          objectiveUUID: learnerUUID,
                          topic: {
                              topicUUID: languageId,
                          },
                      },
                  ]
                : [],
        };

        return Axios.post(apiv1() + `learning-profile/`, body, {
            headers: this.apiHeaders.getHeaders({
                'Content-Type': 'application/json',
            }),
        }).pipe(map((x) => x.data));
    }

    updateProfileIndustry(params: {
        LearnerProfile: LearnerProfileProps;
        topicUUID?: string;
        industryUUID?: string[];
        jobFunctionUUID?: string[];
        currentLanguage?: LanguageProps;
    }): Observable<LearnerProfileProps> {
        const { topicUUID, LearnerProfile, currentLanguage } = params;

        const learnerUUID = LearnerProfile.learnerUUID;
        const defaultObjectiveUUID = LearnerProfile.defaultObjectiveUUID;
        let objectives = LearnerProfile.objectives;

        if (currentLanguage) {
            if (LearnerProfile.objectives.length > 0) {
                objectives = LearnerProfile.objectives.map((item) => {
                    const objectiveTopic = item.topic as any;
                    if (item.learnerUUID === learnerUUID)
                        objectiveTopic.topicUUID = topicUUID;
                    return item;
                });
            } else {
                objectives = [
                    {
                        creator: {
                            creatorType: 'Learner',
                            creatorUUID: learnerUUID,
                            organisationalPriority: '0',
                            organisationalUUID: learnerUUID,
                        },
                        learnerUUID: learnerUUID,
                        objectiveUUID: learnerUUID,
                        topic: {
                            topicUUID: topicUUID as any,
                        },
                    },
                ];
            }
        }

        let demographic = LearnerProfile.demographic;
        if (params.industryUUID) demographic.industryUUID = params.industryUUID;

        if (params.jobFunctionUUID)
            demographic.jobFunctionUUID = params.jobFunctionUUID;

        const body = {
            learnerUUID,
            demographic,
            defaultObjectiveUUID,
            objectives,
        };

        return Axios.put(apiv1() + `learning-profile/${learnerUUID}`, body, {
            headers: this.apiHeaders.getHeaders({
                'Content-Type': 'application/json',
            }),
        }).pipe(map((x) => x.data));
    }

    loadProficiencyOverAllHistory(params: {
        learnerUUID: string;
        topicUUID: string;
    }): Observable<ProficienciesDetailDTO[]> {
        const { learnerUUID, topicUUID } = params;

        return Axios.get<ProficienciesDetailDTO[]>(
            apiv1() +
                `learning-profile/overall-level/history/${learnerUUID}/${topicUUID}`,
            { headers: this.apiHeaders.getHeaders() }
        ).pipe(map((x) => x.data));
    }

    loadCurrentProficiency(params: {
        learnerUUID: string;
        topicUUID: string;
    }): Observable<ProficienciesDetailDTO> {
        const { learnerUUID, topicUUID } = params;
        return Axios.get<ProficienciesDetailDTO>(
            apiv1() +
                `learning-profile/overall-level/current/${learnerUUID}/${topicUUID}`,
            { headers: this.apiHeaders.getHeaders() }
        ).pipe(
            map((x) => {
                return x.data;
            })
        );
    }

    loadProficienciesLevels(
        items: ProficienciesDetailDTO[]
    ): Observable<ProficienciesDTO[]> {
        return from(this.getCorrespondingLevels(items));
    }

    private async getCorrespondingLevels(
        items: ProficienciesDetailDTO[]
    ): Promise<ProficienciesDTO[]> {
        let mapCEFR: number[] = [];
        items.forEach((y: any) => {
            const proficiency = y?.scores?.PROFICIENCY;
            const ProficiencyTest = y?.elements?.PROFICIENCY_TEST?.PROFICIENCY;
            const speakingLevel = y?.elements?.SPEAKING_LEVEL?.PROFICIENCY;

            if (proficiency) {
                mapCEFR.push(proficiency);
            }

            if (ProficiencyTest) {
                mapCEFR.push(ProficiencyTest);
            }

            if (speakingLevel) {
                mapCEFR.push(speakingLevel);
            }
        });

        var unique = [...new Set(mapCEFR)];
        let newMapCEFR = new Map();
        let newMapGoCEFR = new Map();
        await Promise.all(
            unique.map(async (j: any) => {
                const CEFR = 'CEFR',
                    goCEFR = 'GoCEFR';
                let gradingCEFR = this.getGradingByTypeAndProficency({
                    type: CEFR,
                    intermediate: true,
                    proficiency: j,
                });

                let gradingGoCEFR = this.getGradingByTypeAndProficency({
                    type: goCEFR,
                    intermediate: true,
                    proficiency: j,
                });

                const dispatchPromiseList = [gradingCEFR, gradingGoCEFR];

                const objectKeyApi = ['gradingCEFR', 'gradingGoCEFR'];

                await Promise.allSettled(dispatchPromiseList).then((results) =>
                    results.forEach((y, x) => {
                        if (y.status === 'fulfilled') {
                            const value = y.value;
                            if (objectKeyApi[x] === 'gradingCEFR') {
                                newMapCEFR.set(j, value);
                            } else {
                                newMapGoCEFR.set(j, value);
                            }
                        } else {
                            newMapCEFR.set(j, null);
                            newMapGoCEFR.set(j, null);
                        }
                    })
                );
            })
        );

        const itemList = items.map((y: any) => {
            const proficiency = y?.scores?.PROFICIENCY;
            const ProficiencyTest = y?.elements?.PROFICIENCY_TEST?.PROFICIENCY;
            const speakingLevel = y?.elements?.SPEAKING_LEVEL?.PROFICIENCY;

            const convertedValue = {
                proficiency: newMapCEFR.get(proficiency),
                goProficiency: newMapGoCEFR.get(proficiency),
                speaking: newMapCEFR.get(speakingLevel),
                goSpeaking: newMapGoCEFR.get(speakingLevel),
                proficiencyTest: newMapCEFR.get(ProficiencyTest),
                goProficiencyTest: newMapGoCEFR.get(ProficiencyTest),
            } as ProficiencyConvertedValueDTO;

            const data = {
                details: y,
                convertedValue: convertedValue,
            } as ProficienciesDTO;

            return data;
        });

        return lastValueFrom(of(itemList));
    }

    private getGradingByTypeAndProficency({
        type,
        intermediate,
        proficiency,
    }: {
        type: string;
        intermediate: boolean;
        proficiency: number;
    }): Promise<GradingDto> {
        return lastValueFrom(
            ajax
                .getJSON<any>(
                    `${gradingFind()}${type}/${intermediate}/${proficiency}`,
                    this.apiHeaders.getHeaders()
                )
                .pipe(
                    map((response) => {
                        return response;
                    })
                )
        );
    }
}
