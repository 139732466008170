import { ApiHeaders } from './api-headers';
import { Axios } from 'axios-observable';
import { map } from 'rxjs/operators';
import { adaptiveTest } from './api-urls';
import {
    TestRecord,
    TestQuestionRecord,
} from '../store/adaptive-test/adaptive-test-data';
import { Observable } from 'rxjs';

/*
 *   Answer type
 */
export type TestAnswerType = 'Selection' | 'Timeout' | 'Idontknow' | 'Left';

/*
 *   Answer DTO
 */
export interface TestAnswerDTO {
    answer: string[];
    answerType: TestAnswerType;
    duration: number;
    questionId: string;
}

/*
 *   Api to load test data
 */
export class AdaptiveTestApi {
    apiHeaders: ApiHeaders;

    constructor(apiHeaders: ApiHeaders) {
        this.apiHeaders = apiHeaders;
    }

    // load data of new test
    loadTest(languageUUID: string, userUUID: string): Observable<TestRecord> {
        return Axios.get<TestRecord>(
            adaptiveTest() + `${languageUUID}/${userUUID}/new`,
            { headers: this.apiHeaders.getHeaders() }
        ).pipe(map((x) => new TestRecord(x.data)));
    }

    // load data of last completed test
    loadLastTest(
        languageUUID: string,
        userUUID: string
    ): Observable<TestRecord> {
        return Axios.get<TestRecord>(
            adaptiveTest() + `${languageUUID}/${userUUID}/last`,
            { headers: this.apiHeaders.getHeaders() }
        ).pipe(map((x) => new TestRecord({ ...x.data })));
    }

    // request a test complete
    completeTest(testUUID: string): Observable<TestRecord> {
        return Axios.get<TestRecord>(adaptiveTest() + `${testUUID}/complete`, {
            headers: this.apiHeaders.getHeaders(),
        }).pipe(map((x) => new TestRecord(x.data)));
    }

    // load current test question
    loadQuestion(testUUID: string): Observable<TestQuestionRecord> {
        return Axios.get<TestQuestionRecord>(
            adaptiveTest() + `${testUUID}/next-question`,
            {
                headers: {
                    ...this.apiHeaders.getHeaders(),
                    'If-Modified-Since': '0',
                },
            }
        ).pipe(map((x) => new TestQuestionRecord(x.data)));
    }

    // submit answer of question
    submitQuestion(testUUID: string, option: TestAnswerDTO): Observable<any> {
        return Axios.post(
            adaptiveTest() + `${testUUID}/process-answer`,
            option,
            {
                headers: this.apiHeaders.getHeaders({
                    'Content-Type': 'application/json',
                }),
            }
        ).pipe(map((x) => x.data));
    }
}
