import { combineReducers } from 'redux';
import { createReducer } from 'typesafe-actions';
import {
    loadGrading,
    loadAllGradings,
    loadAllGradingsGoCEFR,
} from './grading-actions';
import { GradingDto, GradingGoCEFR } from './grading-dto';

export type GradingSlice = Record<string, GradingDto>;

export interface GradingState {
    list: GradingDto[];
    grading: GradingSlice;
    goCEFR: GradingGoCEFR[];
}

const initialGradingState: GradingState = {
    list: [],
    grading: {},
    goCEFR: [],
};

export const allGradingReducer = createReducer(
    initialGradingState.list
).handleAction(loadAllGradings.success, (state, action) => action.payload);

export const allGradingGoCERFReducer = createReducer(
    initialGradingState.goCEFR
).handleAction(
    loadAllGradingsGoCEFR.success,
    (state, action: any) => action.payload
);

export const gradingByIdReducer = createReducer(
    initialGradingState.grading
).handleAction(loadGrading.success, (state, action) => ({
    ...state,
    [action.payload.id]: action.payload,
}));

export const gradingReducer = () =>
    combineReducers({
        list: allGradingReducer,
        grading: gradingByIdReducer,
        goCEFR: allGradingGoCERFReducer,
    });
