import urls from './urls.json';
import { environment } from '../../environments';

const isCordova =
    typeof (window as any)['cordova'] !== 'undefined' ||
    window.location.href.startsWith('file://');

/**
 * Wraps host url depending on platform mobile\desktop
 * @param url
 * @param host
 * @param useHost
 */
const wrapHost = (url: string, host: string) => {
    const shouldUseHost = isCordova;
    return shouldUseHost ? host + url : url;
};

export const mobileRest = () => {
    return wrapHost(urls['mobile-rest'], environment.API_HOST);
};

export const schedulerRest = () => {
    return wrapHost(urls['scheduler'], environment.API_HOST);
};

export const classroom = () => {
    return wrapHost(urls['classroom'], environment.API_HOST);
};

export const language = () => {
    return wrapHost(urls['language'], environment.API_HOST);
};

export const jobfunction = () => {
    return wrapHost(urls['jobfunction'], environment.API_HOST);
};

export const apiv1 = () => {
    return wrapHost(urls['apiv1'], environment.API_HOST);
};

export const logging = () => {
    return wrapHost(urls['logging'], environment.API_HOST);
};

export const tracking = () => {
    return wrapHost(urls['tracking'], environment.API_HOST);
};

export const telephone = () => {
    return wrapHost(urls['telephone'], environment.API_HOST);
};

export const adaptiveTest = () => {
    return wrapHost(urls['adaptiveTest'], environment.API_HOST);
};

export const activity = () => {
    return wrapHost(urls['activity'], environment.API_HOST);
};

export const contentActivity = () => {
    return wrapHost(urls['content-activity'], environment.API_HOST);
};

export const lessonReports = () => {
    return wrapHost(urls['lesson-reports'], environment.API_HOST);
};

export const user = () => {
    return wrapHost(urls['user'], environment.API_HOST);
};

export const lessonReportsContent = () => {
    return wrapHost(urls['lesson-reports-content'], environment.API_HOST);
};

export const tags = () => {
    return wrapHost(urls['tags'], environment.API_HOST);
};

export const legacyTags = () => {
    return wrapHost(urls['legacyTags'], environment.API_HOST);
};

export const homework = () => {
    return wrapHost(urls['homework'], environment.API_HOST);
};

export const recommended = () => {
    return wrapHost(urls['recommended'], environment.API_HOST);
};

export const saveContent = () => {
    return wrapHost(urls['save-content'], environment.API_HOST);
};

export const contentLegacy = () => {
    return wrapHost(urls['content-legacy'], environment.API_HOST);
};

export const workshop = () => {
    return wrapHost(urls['workshop'], environment.API_HOST);
};

export const assets = () => {
    return wrapHost(urls['assets'], environment.API_HOST);
};

export const credit = () => {
    return wrapHost(urls['credit'], environment.API_HOST);
};

export const activityReport = () => {
    return wrapHost(urls['activity-report'], environment.API_HOST);
};

export const channelReservation = () => {
    return wrapHost(urls['channel-reservation'], environment.API_HOST);
};

export const legacyIdService = () => {
    return wrapHost(urls['legacy-ids'], environment.API_HOST);
};

export const eWriting = () => {
    return wrapHost(urls['ewriting'], environment.API_HOST);
};

export const contract = () => {
    return wrapHost(urls['contract'], environment.API_HOST);
};

export const resourceContent = () => {
    return wrapHost(urls['resource-content'], environment.API_HOST);
};

export const grading = () => {
    return wrapHost(urls['grading'], environment.API_HOST);
};

export const gradingGoCEFR = () => {
    return wrapHost(urls['gradingGoCEFR'], environment.API_HOST);
};

export const gradingFind = () => {
    return wrapHost(urls['gradingFind'], environment.API_HOST);
};

export const activityFilters = () => {
    return wrapHost(urls['activity-filters'], environment.API_HOST);
};

export const learnerPreferences = () => {
    return wrapHost(urls['learner-preferences'], environment.API_HOST);
};

export const learnerChat = () => {
    return wrapHost(urls['learner-chat'], environment.API_HOST);
};

export const apiv100 = () => {
    return wrapHost(urls['apiv100'], environment.API_HOST);
};

export const surveyService = () => {
    return wrapHost(urls['survey-service'], environment.API_HOST);
};

export const speechRecognitionService = () => {
    return wrapHost(urls['speech-recognition-service'], environment.API_HOST);
};

export const virtualClasses = () => {
    return wrapHost(urls['virtual-class'], environment.API_HOST);
};

export const learnerRecommendation = () => {
    return wrapHost(urls['learning-recommendation'], environment.API_HOST);
};

export const learningChannel = () => {
    return wrapHost(urls['learning-channel'], environment.API_HOST);
};

export const feedbackService = () => {
    return wrapHost(urls['feedback-service'], environment.API_HOST);
};

export const contentService = () => {
    return wrapHost(urls['content-service'], environment.API_HOST);
};

export const imageService = () => {
    return wrapHost(urls['ftp-image'], environment.API_HOST);
};

export const vodService = () => {
    return wrapHost(urls['ftp-vod'], environment.API_HOST);
};

export const jwtLegacyService = () => {
    return wrapHost(urls['jwt-legacy-service'], environment.API_HOST);
};

export const referenceService = () => {
    return wrapHost(urls['reference-service'], environment.API_HOST);
};
