import { createAction, createAsyncAction } from 'typesafe-actions';
import { ProficienciesDetailDTO, ProficienciesDTO } from './proficiency-dto';

export const loadHistory = createAsyncAction(
    '@@proficiency/load-history/request',
    '@@proficiency/load-history/success',
    '@@proficiency/load-history/failure'
)<
    { learnerUUID: string; topicUUID: string },
    ProficienciesDetailDTO[],
    Error
>();

export const loadList = createAsyncAction(
    '@@proficiency/load-list/request',
    '@@proficiency/load-list/success',
    '@@proficiency/load-list/failure'
)<ProficienciesDetailDTO[], ProficienciesDTO[], Error>();

export const loadCurrent = createAsyncAction(
    '@@proficiency/load-current/request',
    '@@proficiency/load-current/success',
    '@@proficiency/load-current/failure'
)<
    { learnerUUID: string; topicUUID: string },
    ProficienciesDetailDTO[],
    Error
>();

export const setLoadList = createAction('@@proficiency/set-load-list')<
    ProficienciesDTO[] | null
>();

export const setCurrentTopicOPL = createAction(
    // OPL? OverAllProficiencyLevel seems better?
    '@@proficiency/set-current-topic-opl'
)<ProficienciesDTO | null>();

export const setHistory = createAction('@@proficiency/set-history')<
    ProficienciesDetailDTO[] | null
>();

export const loadCertificate = createAsyncAction(
    '@@proficiency/load-certificate/request',
    '@@proficiency/load-certificate/success',
    '@@proficiency/load-certificate/failure'
)<{ overAllLevelUuid: string; type: string }, any, Error>();

export const loadProficiencyOverviewCertificate = createAsyncAction(
    '@@proficiency/load-proficiency-overview-certificate/request',
    '@@proficiency/load-proficiency-overview-certificate/success',
    '@@proficiency/load-proficiency-overview-certificate/failure'
)<{ testUUID: string }, any, Error>();
