import { Observable, of } from 'rxjs';
import language_list from './language-list.json';
import preferred_language_list from './preferred-language-list.json';
import { delay, map } from 'rxjs/operators';
import {
    LocalizationLanguage,
    LearningPreferrences,
} from '../store/user/user-record';

export interface ILanguageApi {
    getLocalizationLanguages(): Observable<LocalizationLanguage[]>;
    getPreferredLocalizationLanguages(): Observable<LocalizationLanguage[]>;
    getLocalizationLanguage(code: string): Observable<LocalizationLanguage>;
    getPreferredLanguagesAndDefaultLanguage(
        code: any,
        preferredLanguageList: any
    ): Observable<LearningPreferrences>;
    getPreferredLanguagesAndDefaultLanguageSSO(
        code: string,
        preferredLanguageList: any
    ): Observable<LearningPreferrences>;
    getLocalizationLanguageByFullCode(
        code: string,
        preferredLanguageList: any
    ): Observable<LearningPreferrences>;
    getLocalizationLanguages(languages: {
        displayLanguageList?: any;
        preferredLanguageList: any;
    }): Observable<LocalizationLanguage[]>;
}

export class LanguageApiMoq implements ILanguageApi {
    getLocalizationLanguages(): Observable<LocalizationLanguage[]> {
        return of(
            language_list.map((l: any) => {
                let t = new Map<string, string>();
                // prettier-ignore
                // @ts-ignore
                Object.keys(l.translation).forEach((k) => t.set(k, l.translation[k]));

                return {
                    id: l.id,
                    name: l.name,
                    endonym: l.endonym,
                    translation: t,
                } as LocalizationLanguage;
            })
        ).pipe(delay(100));
    }

    getPreferredLocalizationLanguages(): Observable<LocalizationLanguage[]> {
        return of(
            preferred_language_list.map((l: any) => {
                let t = new Map<string, string>();
                // prettier-ignore
                // @ts-ignore
                Object.keys(l.translation).forEach((k) => t.set(k, l.translation[k]));

                return {
                    id: l.id,
                    name: l.name,
                    endonym: l.endonym,
                    translation: t,
                } as LocalizationLanguage;
            })
        ).pipe(delay(100));
    }

    getLocalizationPreferredAndDisplayLanguages(): Observable<any> {
        return of({
            displayLanguage: language_list.map((l: any) => {
                let t = new Map<string, string>();
                // prettier-ignore
                // @ts-ignore
                Object.keys(l.translation).forEach((k) => t.set(k, l.translation[k]));

                return {
                    id: l.id,
                    name: l.name,
                    endonym: l.endonym,
                    translation: t,
                } as LocalizationLanguage;
            }),
            preferredLanguage: preferred_language_list.map((l: any) => {
                let t = new Map<string, string>();
                // prettier-ignore
                // @ts-ignore
                Object.keys(l.translation).forEach((k) => t.set(k, l.translation[k]));

                return {
                    id: l.id,
                    name: l.name,
                    endonym: l.endonym,
                    translation: t,
                } as LocalizationLanguage;
            }),
        }).pipe(delay(100));
    }

    getLocalizationLanguage(code: string): Observable<LocalizationLanguage> {
        return this.getLocalizationLanguages().pipe(
            map((list) => {
                return (
                    list.find((l: any) => l.id === code) ||
                    LocalizationLanguage.getDefaultEnglish()
                );
            })
        );
    }

    getPreferredLanguagesAndDefaultLanguage(
        code: {
            displayLanguage: string;
            preferedCommunicationLanguage: string;
            userId: string;
        },
        preferredLanguageList: any
    ): Observable<LearningPreferrences> {
        return this.getLocalizationLanguages().pipe(
            map((list) => {
                return new LearningPreferrences({
                    displayLanguage: code?.displayLanguage
                        ? list.find((l: any) => {
                              return l.id === code?.displayLanguage;
                          })
                        : list.find((l: any) => {
                              return l.id === 'en';
                          }),
                    preferredLanguage: code?.preferedCommunicationLanguage
                        ? preferredLanguageList.find((l: any) => {
                              return (
                                  l.id === code?.preferedCommunicationLanguage
                              );
                          })
                        : code?.displayLanguage
                        ? preferredLanguageList.find((l: any) => {
                              return l.id === code?.displayLanguage;
                          })
                        : preferredLanguageList.find((l: any) => {
                              return l.id === 'en';
                          }),
                });
            })
        );
    }

    getPreferredLanguagesAndDefaultLanguageSSO(
        code: string,
        preferredLanguageList: any
    ): Observable<LearningPreferrences> {
        return this.getLocalizationLanguages().pipe(
            map((list) => {
                return new LearningPreferrences({
                    displayLanguage: code
                        ? list.find((l: any) => l.id === code)
                        : list.find((l: any) => l.id === 'en'),
                    preferredLanguage: code
                        ? preferredLanguageList.find((l: any) => l.id === code)
                        : preferredLanguageList.find((l: any) => l.id === 'en'),
                });
            })
        );
    }

    getLocalizationLanguageByFullCode(
        code: string,
        preferredLanguageList: any
    ): Observable<LearningPreferrences> {
        return this.getLocalizationLanguages().pipe(
            map((list) => {
                return new LearningPreferrences({
                    displayLanguage: code
                        ? list.find((l: any) => code.includes(l.id))
                        : list.find((l: any) => l.id === 'en'),
                    preferredLanguage: code
                        ? preferredLanguageList.find((l: any) =>
                              code.includes(l.id)
                          )
                        : preferredLanguageList.find((l: any) => l.id === 'en'),
                });
            })
        );
    }
}
