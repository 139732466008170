import { List, Record } from 'immutable';
import moment, { Moment } from 'moment';
import languageList from '../../localization/topic-language-list.json';

export interface LocalizationLanguageProps {
    id: string;
    name: string;
    endonym: string;
    translation: Map<string, string | undefined>;
}

export const localizationLanguageRecord = Record<LocalizationLanguageProps>({
    id: '',
    name: '',
    endonym: '',
    translation: new Map<string, string | undefined>(),
});

export class LocalizationLanguage extends localizationLanguageRecord {
    static getDefaultEnglish(): LocalizationLanguage {
        const english = languageList.find((item) => item.id === 'en');

        if (!english) {
            return new LocalizationLanguage();
        }

        const translation = Object.entries(english.translation);

        return new LocalizationLanguage({
            ...english,
            translation: new Map(translation),
        });
    }

    static getDefaultLanguageList(): LocalizationLanguage[] {
        return languageList.map(
            (item) =>
                new LocalizationLanguage({
                    ...item,
                    translation: new Map(Object.entries(item.translation)),
                })
        );
    }
}

export interface IChangeLanguageModalProps {
    open: boolean;
    newValue: LocalizationLanguage | null;
}

export interface LearningPreferrencesProps {
    displayLanguage: LocalizationLanguageProps;
    preferredLanguage: LocalizationLanguageProps;
}

export const learningPreferrencesRecord = Record<LearningPreferrencesProps>({
    displayLanguage: {
        id: '',
        name: '',
        endonym: '',
        translation: new Map<string, string>(),
    },
    preferredLanguage: {
        id: '',
        name: '',
        endonym: '',
        translation: new Map<string, string>(),
    },
});

export class LearningPreferrences extends learningPreferrencesRecord {
    static getDefaultLearnerPreferrencesEnglish(): LearningPreferrences {
        const english = languageList.find((item) => item.id === 'en');

        if (!english) {
            return new LearningPreferrences();
        }

        const translation = Object.entries(english.translation);

        return new LearningPreferrences({
            displayLanguage: {
                ...english,
                translation: new Map(translation),
            },
            preferredLanguage: {
                ...english,
                translation: new Map(translation),
            },
        });
    }
}

export interface UserTimespentProps {
    jsessionId: string;
    ipAddress: string;
}

export interface JWTokenProps {
    token: string;
    expiryInMinutes: number;
    renewDateTime: Moment;
    expiryDate: Moment;
}

export interface UserProps {
    emailAddress: string;
    image: string | null;
    learnerId: number;
    learnerUUID: string;
    learnerExternalId: number;
    firstname: string;
    lastname: string;
    middlename: string;
    authenticationToken: string;
    aes: string;
    timeZoneId: string;
    selectedTopic: string;
    securityId: string;
    nationality: string;
    allowInternetCall: boolean;
    allowCallback: boolean;
    language: string;
    timespent: UserTimespentProps;
    features: List<string>;
    roles: List<string>;
    shouldResetPassword: boolean;
    passwordPolicy: string;
    jwt: JWTokenProps;
}

const defaultUserProps = {
    emailAddress: '',
    image: null,
    learnerId: 0,
    learnerUUID: '',
    learnerExternalId: 0,
    firstname: '',
    lastname: '',
    middlename: '',
    authenticationToken: '',
    aes: '',
    timeZoneId: '',
    selectedTopic: '',
    securityId: '',
    nationality: '',
    allowInternetCall: false,
    allowCallback: false,
    language: '',
    timespent: {
        jsessionId: '',
        ipAddress: '',
    },
    features: List<string>(),
    roles: List<string>(),
    shouldResetPassword: false,
    passwordPolicy: `^(?=.*[0-9])(?=.*[a-zA-Z])[a-zA-Z0-9!"$%&.]{8,15}$`,
    jwt: {
        token: '',
        expiryInMinutes: 0,
        renewDateTime: moment().startOf('day'),
        expiryDate: moment().startOf('day'),
    },
};

export class UserRecord extends Record(defaultUserProps) implements UserProps {}
