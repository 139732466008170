import { Record } from 'immutable';

export interface ChatMessage {
    id: number;
    command: number;
    userId: number;
    timestamp: string;
    text: string;
}

export interface ChatUserProps {
    id: number;
    classId: number;
    role: number;
    name: string;
    hash: string;
    firstName?: string;
    lastName?: string;
}

export const defaultChatUsersProps: ChatUserProps = {
    id: 0,
    classId: 0,
    role: 0,
    name: '',
    hash: '',
    firstName: '',
    lastName: '',
};

export class ChatUser
    extends Record(defaultChatUsersProps)
    implements ChatUserProps {}
