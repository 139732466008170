import { createReducer } from 'typesafe-actions';
import {
    setIsRFY,
    setModuleRestricted,
    setRestrictions,
} from './module-restriction-action';

import {
    DisplayRuleObjectProps,
    ModuleRestrictionDisplayRecord,
} from './module-restriction-records';
import { combineReducers } from 'redux';

export const displayModuleRestricted =
    createReducer<ModuleRestrictionDisplayRecord | null>(null).handleAction(
        setModuleRestricted,
        (state, action) => action.payload
    );

export const displayAllRestrictions = createReducer<
    DisplayRuleObjectProps[] | null
>(null).handleAction(
    setRestrictions,
    (state, action) => action?.payload?.displayRule ?? null
);

export const displayIsRFY = createReducer<boolean>(true).handleAction(
    setIsRFY,
    (state, action) => action?.payload ?? true
);

export const moduleRestrictionReducer = combineReducers({
    displayModuleRestricted,
    displayAllRestrictions,
    displayIsRFY,
});
