import { IStorage } from './types';
import { GlobalStorage, PromiseStorage, CacheStorage } from './storage';
import { setupLifetime } from './setup-lifetime';

const globalCache = new GlobalStorage();

export function getStorage(storage: IStorage, globalPrefix?: any) {
    if (globalPrefix) {
        return globalCache.getStorage(globalPrefix, storage);
    }

    return storage;
}

export const getCacheStorage = {
    async(globalPrefix?: any) {
        const storage = setupLifetime(new PromiseStorage());

        return getStorage(storage, globalPrefix);
    },

    sync(globalPrefix?: any) {
        const storage = setupLifetime(new CacheStorage());

        return getStorage(storage, globalPrefix);
    },
};
