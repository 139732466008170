import { RootEpic } from '../../epics/root-epic';
import { filter, ignoreElements, map } from 'rxjs/operators';
import { of } from 'rxjs';
import { isActionOf } from 'typesafe-actions';
import { Action } from '../../store/root-action';
import { PortalEvent } from './models/portal-event';

const onTrackEvent: RootEpic = (action$, state$, { trackingService }) =>
    action$.pipe(
        filter(isActionOf(Action.tracker.trackEvent)),
        map((x) => {
            const portalEvent: PortalEvent = {
                category: x.payload.category,
                type: x.payload.type,
                topicId:
                    state$.value.learnerProfile.currentLanguage?.languageUUID,
                contextId: null,
                contentId:
                    state$.value.resources.displayedResource?.contentUUId ??
                    null,
            };

            const userId = state$.value.user.identity?.learnerUUID as string;

            if (!userId) return of();

            return trackingService.track(
                portalEvent,
                x.payload.timestamp,
                userId
            );
        }),
        ignoreElements()
    );

export const trackerEpics = [onTrackEvent];
