import { ApiHeaders } from '../api-headers';
import { catchError, map } from 'rxjs/operators';
import * as _ from 'lodash';
import { List, Map } from 'immutable';

import { from, lastValueFrom, Observable } from 'rxjs';
import { Axios } from 'axios-observable';
import { contentService } from '../api-urls';
import {
    ResourceContent,
    ResourceContentProps,
} from '../../store/resources/resource-content';
import { ResourceListParser } from '../resources/resource-list-parser';
import { validateNRT } from '../../store/resources/howtos/howto-description';
import { ResourceDescription } from '../../store/resources/resources';
import {
    QuizDescriptionOffline,
    QuizAttemptDescription,
    QuizAttemptDTO,
} from '../quizzes/quiz';
import Question from '../quizzes/question';
import { ResourceContentRecord } from '../../store/resources/resources-content-record';
import { modifyResourceStatus } from '../../epics/resources/helpers';
import { imageService, vodService, contentActivity } from '../api-urls';

interface SaveActivityProps {
    attributes: any;
    contentId: string;
    contentProgress: number;
    curriculumId: string;
    deviceId: string;
    end: any;
    quizId: string;
    quizScore: number;
    start: any;
    tabId: string;
    topicId: string;
    userId: string;
}

export class OfflineApi {
    resourceListParser = new ResourceListParser();

    constructor(
        private readonly apiHeaders: ApiHeaders,
        private readonly howtoApi: any,
        private readonly contentsApi: any,
        private readonly quizzesApi: any,
        private readonly tabId: any,
        private readonly indexDBService: any,
        private readonly homeworkApi: any
    ) {}

    private getDeviceId(): string {
        return localStorage.getItem('deviceId') || '';
    }

    public postSyncActivityTracking(args: {
        quiz: Map<string, QuizDescriptionOffline>;
        activity: List<any>;
    }): Observable<any> {
        return from(this.synActivityTrackingWithQuiz(args));
    }

    private async synActivityTrackingWithQuiz(args: any): Promise<any> {
        const { activity } = args;
        const deviceId = this.getDeviceId();
        const tabId = this.tabId;
        const synchActivityMap = await Promise.all(
            activity.map(async (l: any) => {
                const { event, trackingDetails } = l;
                const { quizArticleId, ...saveData } = event;
                const payloadSave: any = {
                    ...saveData,
                    deviceId: deviceId,
                    tabId: tabId,
                };

                const saveItemActivity = await lastValueFrom(
                    this.saveActivity(payloadSave)
                );

                if (saveItemActivity === '') {
                    const activityTrackingonly =
                        trackingDetails.hasOwnProperty('useruuid');
                    if (activityTrackingonly) {
                        this.indexDBService.updateActivityTracking({
                            activity: [trackingDetails],
                        });
                    } else {
                        this.indexDBService.updateActivityTrackingQuiz({
                            quizActivity: [trackingDetails],
                        });
                    }
                    return 'Success';
                } else {
                    return 'Error';
                }
            })
        );

        const checkHasError = synchActivityMap.find(
            (y: string) => y === 'Error'
        );
        if (!checkHasError && activity.size > 0) {
            const firstActivity = activity?.get?.(0);
            if (firstActivity && firstActivity.trackingDetails) {
                const { activityTrackingId } = firstActivity.trackingDetails;
                if (activityTrackingId) {
                    this.indexDBService.removeQuizTrackAttempts(
                        activityTrackingId
                    );
                }
            }
        }

        return synchActivityMap;
    }

    saveActivity(params: SaveActivityProps): Observable<any> {
        return Axios.post<any>(`${contentActivity()}offline/event`, params, {
            headers: this.apiHeaders.getHeaders({}, true),
        }).pipe(
            map((response) => response.data),
            catchError((error) => {
                throw error.message;
            })
        );
    }

    public postSyncQuiz(args: {
        learnerUUID: string;
        learnerId: number;
        quiz: Map<string, QuizDescriptionOffline>;
    }): Observable<Map<string, QuizDescriptionOffline>> {
        const params = {
            learnerUUID: args?.learnerUUID,
            learnerId: args?.learnerId,
            quiz: args?.quiz,
        };
        return from(this.syncQuiz(params));
    }

    private async syncQuiz(args: {
        learnerId: number;
        learnerUUID: string;
        quiz: Map<string, QuizDescriptionOffline>;
    }): Promise<any> {
        const { quiz, learnerId, learnerUUID } = args;

        const quizArray = Array.from(quiz.values());
        const qItems = await Promise.all(
            quizArray.map(async (i) => {
                const articleId = i.id.articleId;
                const qArticleId = i.id.qArticleId;
                const qAttempt = i.attempts;
                const qIdentifier = i.identifier();
                const qHistory = i._data.qh;

                const question = i.questions.map((u: any) => new Question(u));
                const quizOriginal = qHistory.map((h: QuizAttemptDTO) => {
                    return QuizAttemptDescription.fromDTO(h, question);
                });

                const filterNeedToSync = await Promise.all(
                    qAttempt
                        .filter((y: any, o: any) => {
                            const validateQuestion = quizOriginal.find(
                                (k: any) => {
                                    return (
                                        JSON.stringify(k) === JSON.stringify(y)
                                    );
                                }
                            );
                            return !validateQuestion;
                        })
                        .map(async (item) => {
                            const qSave: number = await lastValueFrom(
                                this.quizzesApi.saveQuizProgress(
                                    qIdentifier,
                                    item
                                )
                            );

                            const qAttempt = item.set('qri', qSave);
                            return qAttempt;
                        })
                );

                const toSaveUnfinishQuiz = filterNeedToSync.find(
                    (i) =>
                        i.suppliedAnswers.size >= i.questionsCount &&
                        i.qri !== 0
                );

                const savedQuizAttempt = filterNeedToSync.filter(
                    (q) => q.qri === 0
                );
                const allResourceDetails = await Promise.all([
                    this.indexDBService.getResourceDetail(),
                ]);
                const mergeMapResourceDetails = _.uniq(
                    [].concat.apply([], allResourceDetails)
                ).map((r: any) => {
                    return JSON.parse(r?.itemResource || '');
                });
                const resourceItem = mergeMapResourceDetails.find(
                    (j) =>
                        String(j.quizArticleId) === String(qArticleId) &&
                        String(j.resourceId) &&
                        String(articleId)
                );

                if (savedQuizAttempt.length === 0) {
                    const quizDetails = await lastValueFrom(
                        this.quizzesApi.loadQuizDescription({
                            qArticleId: Number(resourceItem?.quizArticleId),
                            articleId: Number(resourceItem?.resourceId),
                            learnerId: Number(learnerId),
                            groupId: Number(resourceItem?.groupId),
                        })
                    );
                    const checkIfError = quizDetails instanceof Error;
                    if (!checkIfError) {
                        this.indexDBService.updateResourcesQuiz({
                            learnerUUID: learnerUUID,
                            item: quizDetails,
                        });
                    }
                }

                if (toSaveUnfinishQuiz) {
                    let data = {
                        userId: learnerUUID,
                        topicId: resourceItem?.topicUUID,
                        contentId: resourceItem?.contentUUId,
                        dataSource: 'Assignment',
                    };

                    await lastValueFrom(this.homeworkApi.finishHomework(data));
                }

                return filterNeedToSync;
            })
        );

        return qItems;
    }

    public loadResourceQuiz(props: any): Observable<any> {
        return from(this.loadQuiz(props));
    }

    private async loadQuiz(props: any): Promise<any> {
        const { list, learnerId } = props;

        const quizItem = await Promise.all(
            Object.values(list.toJS())
                .filter((y: any) => {
                    return y.quizArticleId;
                })
                .map(async (l: any): Promise<QuizDescriptionOffline> => {
                    const { quizArticleId, groupId, resourceId } = l;
                    const resourceQuiz = await lastValueFrom(
                        this.quizzesApi.loadQuizDescription({
                            qArticleId: Number(quizArticleId),
                            articleId: Number(resourceId),
                            learnerId: learnerId,
                            groupId: groupId,
                        })
                    );
                    return resourceQuiz as QuizDescriptionOffline;
                })
        );

        const filterWorkingQuiz = quizItem.filter((resourceQuiz) => {
            const checkResourceIfError = resourceQuiz instanceof Error;
            return !checkResourceIfError;
        });

        const j = filterWorkingQuiz.reduce((m: any, t: any) => {
            return m.set(t?.id?.qArticleId.toString(), t);
        }, Map<string, QuizDescriptionOffline>());

        return j;
    }

    public loadResourceDetailsContent(
        props: any
    ): Observable<Map<string, ResourceContentRecord>> {
        return from(this.loadContents(props));
    }

    private async loadContents(props: any): Promise<any> {
        const { list, inProgressResources, completedResources } = props;
        const resourceContent = await Promise.all(
            Object.values(list.toJS()).map(
                async (l: any): Promise<ResourceContentRecord> => {
                    const resourceItem = await lastValueFrom(
                        this.contentsApi.loadContent(l?.contentUUId)
                    );
                    return resourceItem as any;
                }
            )
        );
        const modifyStatusItem = resourceContent.map((y: any) => {
            return modifyResourceStatus<ResourceContentProps>(
                inProgressResources,
                completedResources
            )(y);
        });

        const j = modifyStatusItem.reduce((m: any, t: any) => {
            return m.set(t.id, t);
        }, Map<string, ResourceContentRecord>());
        console.log('j', j);

        return j;
    }

    public loadResourceDetails(args: {
        items: List<ResourceContent>;
        languageCode: string;
    }): Observable<Map<string, ResourceDescription>> {
        return from(this.loadResourcesListDetail(args));
    }

    private async loadResourcesListDetail(args: {
        items: List<ResourceContent>;
        languageCode: string;
    }): Promise<Map<string, ResourceDescription>> {
        const { items, languageCode } = args;

        let resourceContent = await Promise.all(
            items.map(
                async (l: ResourceContent): Promise<ResourceDescription> => {
                    const { legacy } = l;
                    const resourceItem = await lastValueFrom(
                        this.howtoApi.loadContent({
                            contentType: legacy?.contentType,
                            resourceId: legacy?.articleId,
                            groupId: legacy?.groupId,
                            languageCode: languageCode,
                            fromDownload: true,
                        })
                    );

                    return resourceItem as any;
                }
            )
        );

        const itemDetails = resourceContent
            .filter(async (k) => {
                const response = (await k) instanceof Error;
                return !response;
            })
            .filter((y: any) => {
                return validateNRT(y);
            });

        const j = itemDetails.reduce((m: any, t: any) => {
            return m.set(t.contentUUId, t);
        }, Map<string, ResourceDescription>());

        return j;
    }

    loadContent({
        contentUuid,
        userUuid,
    }: {
        contentUuid: string;
        userUuid: string;
    }): Observable<List<ResourceContent>> {
        return Axios.get<any>(
            `${contentService()}download/${userUuid}/${contentUuid}`,
            {
                headers: this.apiHeaders.getHeaders({}, true),
            }
        ).pipe(
            map((response) => {
                const items = List(
                    Object.values(response.data)
                        .map((y: any) => {
                            const { legacy } = y;
                            const { mediumImage } = legacy;
                            const legacyItem = {
                                ...legacy,
                                medium_image: mediumImage,
                            };
                            return new ResourceContent({
                                ...y,
                                uuid: y?.id,
                                legacy: legacyItem,
                            });
                        })
                        .filter((x: ResourceContent) => {
                            return (
                                x?.legacy?.contentType.toLowerCase() ===
                                    'vocabulary' ||
                                x?.legacy?.contentType.toLowerCase() ===
                                    'grammar-rule' ||
                                x?.legacy?.contentType.toLowerCase() ===
                                    'how-to'
                            );
                        })
                );

                return items;
            })
        );
    }

    urlContentToDataUri(url: string) {
        return fetch(url)
            .then((response) => response.blob())
            .then(
                (blob) =>
                    new Promise((callback) => {
                        let reader = new FileReader();
                        reader.onload = function () {
                            callback(this.result);
                        };
                        reader.readAsDataURL(blob);
                    })
            );
    }

    private concatArrays(assets: any) {
        return _.uniq([].concat.apply([], assets));
    }

    private recursiveSearch(obj: any, searchKey: any, results = []) {
        const r: any = results;
        if (obj) {
            Object.keys(obj).forEach((key) => {
                const value: any = obj[key];
                if (key === searchKey && typeof value !== 'object') {
                    r.push(value);
                } else if (typeof value === 'object') {
                    this.recursiveSearch(value, searchKey, r);
                }
            });
        }
        return r;
    }

    private getUrl(data: any): any {
        const assetsArray = [
            'bgImage',
            'mediumImage',
            'medium_image',
            'mainImageUrl',
            'main_image_url',
            'thumbnail',
            'image',
            'audio',
            'video',
            'videoSubbed',
            'ao',
            'aom',
            'snap_image',
            'printable_quizz',
            'text_to_speech',
            'text_to_speech_url',
            'mainVideoUrl',
            'mainVideoUrlSubbed',
        ];

        const url = [].concat.apply(
            [],
            data.map((item: any) => {
                return assetsArray
                    .map((l: string) => {
                        return this.recursiveSearch(item, l);
                    })
                    .filter((h) => {
                        return h.length > 0;
                    });
            })
        );

        return this.concatArrays(url).filter((k) => k !== '');
    }

    public loadAssets(props: any): Observable<any> {
        const { list } = props;
        const getAssetsItem = this.getUrl(list);

        return from(
            this.loadAssetsFromUrl({ ...props, listAssets: getAssetsItem })
        );
    }

    private async loadAssetsFromUrl(props: any): Promise<any> {
        const { listAssets, displayAssets } = props;

        const items = await Promise.all(
            listAssets
                .filter((x: any) => {
                    return !displayAssets.get(x);
                })
                .map(async (url: string, index: number) => {
                    if (url.length > 0) {
                        let fullUrl = `${vodService()}${url}`;
                        if (url.indexOf('www') > -1) {
                            fullUrl = url;
                        } else if (url.indexOf('image_gallery?') > -1) {
                            fullUrl = `${imageService()}${url}`;
                        } else {
                            if (url.indexOf('vod/') > -1) {
                                fullUrl = url;
                            } else if (url.indexOf('image/journal') > -1) {
                                fullUrl = `${imageService()}${url}`;
                            }
                        }

                        const encoded = await this.urlContentToDataUri(fullUrl);
                        return {
                            encoded: encoded,
                            url: url,
                        };
                    }
                    return null;
                })
        );

        const j = items.reduce((m: any, t: any) => {
            return m.set(t?.url, t);
        }, Map<string, any>());

        return j;
    }
}
