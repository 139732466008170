import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { UseConvertStringToObjectMap } from '../../components/login/lib/use-convert-string-to-obj-map';
import { GetSSOEndpoint } from '../../components/login/lib/use-get-sso-endpoint';
import {
    IUseSSOToRetrieveUserToken,
    UseSSOToRetrieveUserToken,
} from '../../components/login/lib/use-retrieve-user-token';
import { useAppDispatch } from '../../components/root/app-dispatch';
import { GetConfigSSO } from '../../components/root/private-route';
import { Action } from '../../store/root-action';
import { userSSOLogout } from '../../store/user/user-selectors';
import { isCordova, windowOpen } from '../../utils/cordova';
import { apiv100 } from '../../services/api-urls';

export function useSSO() {
    const dispatch = useAppDispatch();
    const ssoIDPLogout = useSelector(userSSOLogout);
    const history = useHistory();

    const URI = `/app/samlconnector`;
    const errorLink = '/sso-error-login';

    const SSORedirectIDP = useCallback((url: string) => {
        window.location.assign(url);
    }, []);

    const logoutRequestDetail = useCallback(
        async (clientUrl: string) => {
            try {
                const urlValidateIsMigrated = `${apiv100()}sso-validator/saml/platform/language-academy/${clientUrl}`;
                const platformConfig = await fetch(urlValidateIsMigrated);

                if (platformConfig.ok) {
                    const data: GetConfigSSO = await platformConfig.json();
                    dispatch(Action.user.setSSOLogout(data));
                }
            } catch (error) {
                console.error('Error occurred during logout:', error);
            }
        },
        [dispatch]
    );

    const SSOPostUser = useCallback(
        (
            postLogin: { clientId: string; clientUrl: string },
            samlRes: string
        ) => {
            const apiEndpoint = GetSSOEndpoint(postLogin);

            const postUserToSSO = UseSSOToRetrieveUserToken({
                apiEndpoint,
                SAMLResponse: samlRes,
            });

            postUserToSSO.subscribe(
                async (userFromToken: IUseSSOToRetrieveUserToken) => {
                    if (userFromToken instanceof Error) {
                        // Handle the error here
                        history.push(errorLink);
                    } else {
                        // Handle the data here

                        await logoutRequestDetail(postLogin.clientUrl);
                        localStorage.removeItem('persist:root');
                        dispatch(
                            Action.user.loginSSO.request({
                                token: userFromToken.legacyToken,
                            })
                        );
                    }
                },
                (error) => {
                    console.error('Error occurred:', error);
                }
            );
        },
        [dispatch, history, logoutRequestDetail]
    );

    const SSORedirectIDPMobile = useCallback(
        (clientId: string, url: string) => {
            const inAppBrowserRef = windowOpen({
                link: url,
                settings:
                    'location=yes,clearsessioncache=no,clearcache=no,toolbar=no',
            });

            // Listen for URL changes in the InAppBrowser
            inAppBrowserRef.addEventListener(
                'loadstop',
                (event: { url: string }) => {
                    if (event.url.includes(URI)) {
                        // Parse the hash fragment to extract JSON response
                        const responseJson = UseConvertStringToObjectMap(
                            event.url
                        );

                        const loginUser = () => {
                            const urlObject = new URL(event.url);
                            const domain = urlObject.hostname;
                            SSOPostUser(
                                { clientId, clientUrl: domain },
                                responseJson.SAMLResponse || ''
                            );
                            inAppBrowserRef.close();
                        };

                        if (responseJson.RelayState) {
                            if (responseJson.RelayState !== '/logout') {
                                loginUser();
                            }
                        } else {
                            loginUser();
                        }
                    }
                }
            );
        },
        [URI, SSOPostUser]
    );

    const handleLogout = useCallback(async () => {
        if (ssoIDPLogout) {
            //logout
            const encodedLogoutRequest =
                ssoIDPLogout.logoutRequest.encodedLogoutRequest;
            // Base64 encode the XML string
            const url = `${
                ssoIDPLogout.idpLogoutUrl
            }?SAMLRequest=${encodeURIComponent(
                encodedLogoutRequest
            )}&RelayState=/logout`;

            if (isCordova) {
                SSORedirectIDPMobile(ssoIDPLogout.key.clientId, url);
            } else {
                SSORedirectIDP(url);
            }
        }
    }, [ssoIDPLogout, SSORedirectIDP, SSORedirectIDPMobile]);

    return {
        sloLogout: handleLogout,
        getLogoutInfo: logoutRequestDetail,
        SSORedirectIDPMobile: SSORedirectIDPMobile,
        SSOPostUser: SSOPostUser,
    };
}
