import { Record } from 'immutable';

export interface CreditDTOCreditAPI {
    amount: number;
    expire: string;
    orderId: number;
    product: string;
    productExternalId: number;
    productGroup: string;
    start: string;
    topic: string;
}

export interface CreditCreditAPIProps {
    amount: number;
    expire: string;
    orderId: number;
    product: string;
    productExternalId: number;
    productGroup: string;
    start: string;
    topic: string;
}

const defaultCreditCreditAPIProps: CreditCreditAPIProps = {
    amount: 0,
    expire: '',
    orderId: 0,
    product: '',
    productExternalId: 0,
    productGroup: '',
    start: '',
    topic: '',
};

/**
 * Class describing credit
 */
export class CreditCreditAPIRecord
    extends Record(defaultCreditCreditAPIProps)
    implements CreditCreditAPIProps {}
