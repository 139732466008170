import { catchError, map } from 'rxjs/operators';
import { List } from 'immutable';
import { Axios } from 'axios-observable';
import { ApiHeaders } from './api-headers';
import { contract } from './api-urls';
import { ContractDto, UserContract } from '../store/contracts/contract-data';

export class ContractApi {
    apiHeaders: ApiHeaders;

    constructor(apiHeaders: ApiHeaders) {
        this.apiHeaders = apiHeaders;
    }

    getUserContractTerm(userUUID: string) {
        return Axios.get<ContractDto[]>(contract() + 'user/' + userUUID, {
            headers: this.apiHeaders.getHeaders({}, true),
        }).pipe(
            map((response) => {
                const contracts = response.data;
                let userContracts = List(contracts).map((contract) => {
                    const contractService = contract.services[0];
                    const subscriptionRule =
                        contractService.terms[0].subscriptionRule;
                    const reassessmentRule =
                        contractService.terms[0].reassessmentRule;
                    const downloadRules =
                        contractService.terms[0].downloadRules;
                    const lessonsRule = contractService.terms[0].lessons;

                    let mapLessonRuleWithMap = [];
                    if (lessonsRule) {
                        mapLessonRuleWithMap = lessonsRule.map((x: any) => {
                            return {
                                ...x,
                                start: contractService.terms[0].start,
                                end: contractService.terms[0].end,
                            };
                        });
                    }

                    const limitationRule =
                        subscriptionRule ||
                        reassessmentRule ||
                        downloadRules ||
                        mapLessonRuleWithMap;
                    const limitationBanks = contractService.banks
                        ? contractService.banks[0]
                        : {};

                    return new UserContract({
                        type: contractService.type,
                        limitationRule: limitationRule,
                        limitationBanks: limitationBanks,
                    });
                });

                return userContracts;
            }),
            catchError((error) => {
                throw error.message;
            })
        );
    }
}
