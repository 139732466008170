import { createReducer } from 'typesafe-actions';
import { List } from 'immutable';
import { combineReducers } from 'redux';
import { Action } from '../root-action';

import {
    LessonReport,
    LessonReportListItem,
} from './data/lesson-report-models';
import { EwritingListItem } from '../e-writing/data/ewriting-models';

const lessonReportsListReducer =
    createReducer<List<LessonReportListItem> | null>(null)
        .handleAction(
            Action.lessonReports.loadLessonReportsAndEwritings.success,
            (state, { payload }) => {
                return payload.reports;
            }
        )
        .handleAction(
            Action.lessonReports.loadLessonReportsAndEwritings.failure,
            (state, { payload }) => {
                return List<LessonReportListItem>();
            }
        );

const ewritingsListReducer = createReducer<List<EwritingListItem> | null>(null)
    .handleAction(
        Action.lessonReports.loadLessonReportsAndEwritings.success,
        (state, { payload }) => {
            return payload.ewritings;
        }
    )
    .handleAction(
        Action.lessonReports.loadLessonReportsAndEwritings.failure,
        (state, { payload }) => {
            return List<EwritingListItem>();
        }
    );

const selectedLessonReportListItem = createReducer<LessonReportListItem | null>(
    null
)
    .handleAction(
        Action.lessonReports.lessonReportListItemSelected,
        (state, { payload }) => {
            return payload;
        }
    )
    .handleAction(
        Action.lessonReports.loadLessonReportsAndEwritings.request,
        (state, { payload }) => {
            return null;
        }
    )
    .handleAction(
        [
            Action.lessonReports.loadLessonReport.failure,
            Action.lessonReports.loadLessonReportFromFeedback.failure,
        ],
        (state, { payload }) => {
            return null;
        }
    )
    .handleAction(Action.lessonReports.lessonReportsOpened, (state) => {
        return null;
    })
    .handleAction(
        Action.lessonReports.ewritingListItemSelected,
        (state, { payload }) => {
            return null;
        }
    );

const selectedEwritingListItem = createReducer<EwritingListItem | null>(null)
    .handleAction(
        Action.lessonReports.ewritingListItemSelected,
        (state, { payload }) => {
            return payload;
        }
    )
    .handleAction(
        Action.lessonReports.loadLessonReportsAndEwritings.request,
        (state, { payload }) => {
            return null;
        }
    )
    .handleAction(
        Action.lessonReports.loadLessonReportsAndEwritings.failure,
        (state, { payload }) => {
            return null;
        }
    )
    .handleAction(Action.lessonReports.lessonReportsOpened, (state) => {
        return null;
    })
    .handleAction(
        Action.lessonReports.lessonReportListItemSelected,
        (state, { payload }) => {
            return null;
        }
    );

const isLoadingLessonReportContent = createReducer<boolean>(false)
    .handleAction(
        Action.lessonReports.lessonReportListItemSelected,
        (state, { payload }) => {
            return true;
        }
    )
    .handleAction(
        [
            Action.lessonReports.loadLessonReport.success,
            Action.lessonReports.loadLessonReportFromFeedback.success,
        ],
        (state, { payload }) => {
            return false;
        }
    )
    .handleAction(
        [
            Action.lessonReports.loadLessonReport.failure,
            Action.lessonReports.loadLessonReportFromFeedback.failure,
        ],
        (state, { payload }) => {
            return false;
        }
    );

const isLoadingLessonReportsList = createReducer<boolean>(false)
    .handleAction(
        Action.lessonReports.loadLessonReportsAndEwritings.request,
        (state, { payload }) => {
            return true;
        }
    )
    .handleAction(
        Action.lessonReports.loadLessonReportsAndEwritings.success,
        (state, { payload }) => {
            return false;
        }
    )
    .handleAction(
        Action.lessonReports.loadLessonReportsAndEwritings.failure,
        (state, { payload }) => {
            return false;
        }
    );

const selectedLessonReport = createReducer<LessonReport | null>(null)
    .handleAction(
        [
            Action.lessonReports.loadLessonReport.success,
            Action.lessonReports.loadLessonReportFromFeedback.success,
        ],
        (state, { payload }) => {
            return payload.report;
        }
    )
    .handleAction(
        Action.lessonReports.loadLessonReportsAndEwritings.request,
        (state, { payload }) => {
            return null;
        }
    )
    .handleAction(
        [
            Action.lessonReports.loadLessonReport.failure,
            Action.lessonReports.loadLessonReportFromFeedback.failure,
        ],
        (state, { payload }) => {
            return null;
        }
    )
    .handleAction(
        Action.lessonReports.ewritingListItemSelected,
        (state, { payload }) => {
            return null;
        }
    );

const lessonReportToPrint = createReducer<LessonReport | null>(null)
    .handleAction(
        Action.lessonReports.lessonReportLoadedFromLocalStorageSuccess,
        (state, { payload }) => {
            return new LessonReport(payload.lessonReport);
        }
    )
    .handleAction(
        Action.lessonReports.lessonReportLoadedFromLocalStorageFail,
        (state) => {
            return null;
        }
    );

const lessonReportListItemToPrint = createReducer<LessonReportListItem | null>(
    null
)
    .handleAction(
        Action.lessonReports.lessonReportLoadedFromLocalStorageSuccess,
        (state, { payload }) => {
            return new LessonReportListItem(payload.listItem);
        }
    )
    .handleAction(
        Action.lessonReports.lessonReportLoadedFromLocalStorageFail,
        (state) => {
            return null;
        }
    );

const lessonReportPageErrorReducer = createReducer<boolean>(false)
    .handleAction(
        Action.lessonReports.loadLessonReportsAndEwritings.failure,
        () => {
            return true;
        }
    )
    .handleAction(
        Action.lessonReports.loadLessonReportsAndEwritings.success,
        () => {
            return false;
        }
    );

const navigationReducer = createReducer<boolean>(false)
    .handleAction(Action.lessonReports.lessonReportsOpened, () => true)
    .handleAction(
        Action.lessonReports.lessonReportSetNavigation,
        (state, action) => action.payload
    );

export const lessonReportsReducer = () => {
    return combineReducers({
        lessonReportsList: lessonReportsListReducer,
        ewritingsList: ewritingsListReducer,
        selectedLessonReport: selectedLessonReport,
        selectedLessonReportListItem: selectedLessonReportListItem,
        isLoadingLessonReportContent: isLoadingLessonReportContent,
        isLoadingLessonReportsList: isLoadingLessonReportsList,
        lessonReportToPrint: lessonReportToPrint,
        lessonReportListItemToPrint: lessonReportListItemToPrint,
        selectedEwritingListItem: selectedEwritingListItem,
        lessonReportPageError: lessonReportPageErrorReducer,
        navigation: navigationReducer,
    });
};
