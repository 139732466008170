import { createReducer } from 'typesafe-actions';
import { LightstreamerClient } from 'lightstreamer-client-web';
import { Action } from '../../../root-action';
import { combineReducers } from 'redux';
import { SimpleUser } from 'sip.js/lib/platform/web';
import { clearClassroom } from '../../vcr-actions';

const lightstreamerSocket = createReducer<LightstreamerClient | null>(
    null
).handleAction(
    Action.vcr.websocket.setLightstreamerSocket,
    (state, action) => action.payload
);

const lightstreamerStatus = createReducer<string | null>(null)
    .handleAction(
        Action.vcr.websocket.setLightstreamerStatus,
        (state, action) => action.payload
    )
    .handleAction(clearClassroom, () => null);

const sipSocket = createReducer<SimpleUser | null>(null).handleAction(
    Action.vcr.websocket.setSipSocket,
    (state, action) => action.payload
);

export const websocket = combineReducers({
    lightstreamerSocket,
    lightstreamerStatus,
    sipSocket,
});
