import React from 'react';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import * as H from 'history';
import { Router } from 'react-router-dom';

interface AppWrapperProps {
    store: any;
    persistor: any;
    history: H.History;
}

export const AppWrapper: React.FC<AppWrapperProps> = ({
    store,
    persistor,
    history,
    children,
}) => {
    return (
        <Provider store={store}>
            <PersistGate persistor={persistor} loading={null}>
                <Router history={history}>{children}</Router>
            </PersistGate>
        </Provider>
    );
};
