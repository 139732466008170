import { fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react';
// TODO: Replace after full refactor react-redux to RTK on RTK's RootState
import { BaseQueryFn } from '@reduxjs/toolkit/dist/query/baseQueryTypes';
import {
    FetchArgs,
    FetchBaseQueryArgs,
    FetchBaseQueryError,
    FetchBaseQueryMeta,
} from '@reduxjs/toolkit/dist/query/fetchBaseQuery';
import { RootState } from 'typesafe-actions';
import { isCordova } from '../utils/cordova';

export const environment: {
    API_HOST: string;
    DEPLOY_PATH: string;
} = {
    API_HOST: process.env.REACT_APP_API_HOST || '',
    DEPLOY_PATH: process.env.REACT_APP_DEPLOY_PATH || '',
};

export const api = ({
    baseUrl,
    ...rest
}: FetchBaseQueryArgs): BaseQueryFn<
    string | FetchArgs,
    unknown,
    FetchBaseQueryError,
    {},
    FetchBaseQueryMeta
> => {
    if (isCordova) {
        if (!baseUrl) {
            baseUrl = environment.API_HOST;
        }
    }

    return fetchBaseQuery({
        baseUrl,
        prepareHeaders: (headers, { getState }) => {
            const token = (getState() as RootState)?.user?.identity?.jwt.token;

            // If we have a token set in state, let's assume that we should be passing it.
            if (token) {
                headers.set('authorization', `Bearer ${token}`);
            }
            return headers;
        },
        ...rest,
    });
};

export const defaultApiFetcher = api({});
