import { List } from 'immutable';
import { EMPTY, forkJoin, merge, of } from 'rxjs';
import {
    catchError,
    distinctUntilChanged,
    exhaustMap,
    filter,
    ignoreElements,
    map,
    mergeMap,
    switchMap,
    tap,
} from 'rxjs/operators';
import { isActionOf } from 'typesafe-actions';
import { localization } from '../localization';
import { logger } from '../logging';
import { Action } from '../store/root-action';
import { userActions } from '../store/user/user-actions';
import {
    JWTokenProps,
    LocalizationLanguage,
    UserRecord,
} from '../store/user/user-record';
import { RootEpic } from './root-epic';

const fetchUserTokenEpic: RootEpic = (action$, _, { authentication }) =>
    action$.pipe(
        filter(isActionOf(userActions.login.request)),
        switchMap((x) => {
            let errorMessage = (() =>
                localization.login_message_SomethingWentWrong)();

            let warningMessage = (() =>
                localization.login_credentials_disabled)();

            const observableLegacyAuth = authentication
                .loadLegacyToken(x.payload.email, x.payload.password)
                .pipe(
                    map((user) => {
                        return user;
                    }),
                    catchError(async (errorCode) => new Error(errorCode))
                );

            const observableLoadJWTAuth = authentication
                .loadJwtToken(x.payload.email, x.payload.password)
                .pipe(
                    map((user) => {
                        return user;
                    }),
                    catchError(async (errorCode) => new Error(errorCode))
                );

            return forkJoin([observableLegacyAuth, observableLoadJWTAuth]).pipe(
                map(([dataLegacyAuth, jwtData]) => {
                    // Check if there are any errors in the responses

                    if (
                        dataLegacyAuth instanceof Error ||
                        jwtData instanceof Error
                    ) {
                        if (jwtData instanceof Error) {
                            if (jwtData.message === '403') {
                                return userActions.login.failure(
                                    new Error(warningMessage)
                                );
                            } else {
                                return userActions.login.failure(
                                    new Error(errorMessage)
                                );
                            }
                        }
                        return userActions.login.failure(
                            new Error(errorMessage)
                        );
                    }

                    const {
                        User,
                        Features,
                        Roles,
                        NewPassword,
                        PasswordPolicy,
                    } = dataLegacyAuth;

                    const userRecordDetail = new UserRecord({
                        emailAddress: User?.emailAddress,
                        learnerUUID: User?.uuid,
                        image: User?.image as any,
                        learnerId: User?.learnerId,
                        learnerExternalId: User?.learnerExternalId,
                        firstname: User?.firstname,
                        lastname: User?.lastname,
                        middlename: User?.middlename,
                        authenticationToken: User?.authenticationToken,
                        aes: User?.aes,
                        timeZoneId: User?.timeZoneId,
                        selectedTopic: User?.selectedTopic,
                        securityId: User?.securityId,
                        nationality: User?.nationality,
                        allowInternetCall: User?.allowInternetCall,
                        allowCallback: User?.allowCallback,
                        language: User?.language,
                        timespent: {
                            jsessionId: User?.timespent?.jsessionId || '',
                            ipAddress:
                                User?.timespent?.ipAddress || '127.0.0.1',
                        },
                        features: List(Features),
                        roles: List(Roles),
                        shouldResetPassword: !!NewPassword,
                        passwordPolicy: PasswordPolicy,
                        jwt: {
                            ...jwtData,
                        },
                    });

                    // This block will be executed after all observables complete
                    if (userRecordDetail.shouldResetPassword) {
                        return userActions.demandNewPassword(userRecordDetail);
                    }
                    return userActions.login.success(userRecordDetail);
                })
            );
        })
    );

const postRenewJWToken: RootEpic = (action$, _, { authentication }) =>
    action$.pipe(
        filter(isActionOf(userActions.userRenewJWToken.request)),
        switchMap((x) => {
            return authentication.RenewJWToken(x.payload.authorization).pipe(
                switchMap((auth: JWTokenProps) => {
                    return of(userActions.userRenewJWToken.success(auth));
                }),
                catchError((e) => {
                    return of(userActions.setIsExpiredToken(true));
                })
            );
        })
    );

const generateJWTokenEpic: RootEpic = (action$, _, { authentication }) =>
    action$.pipe(
        filter(isActionOf(userActions.userGenerateToken.request)),
        switchMap((x) => {
            return authentication
                .loadJwtToken(x.payload.email, x.payload.password)

                .pipe(
                    switchMap((auth: JWTokenProps) => {
                        return of(userActions.userGenerateToken.success(auth));
                    }),
                    catchError((e) => {
                        return of(userActions.setIsExpiredToken(true));
                    })
                );
        })
    );

const fetchUserDataEpicSSO: RootEpic = (action$, _, { authentication }) =>
    action$.pipe(
        filter(isActionOf(userActions.loginSSO.request)),
        switchMap((x) =>
            authentication.loadUserData(x.payload.token).pipe(
                map(userActions.loginSSO.success),
                catchError((x) => of(userActions.loginSSO.failure(x)))
            )
        )
    );

const fetchUserDataEpicDingTalk: RootEpic = (action$, _, { authentication }) =>
    action$.pipe(
        filter(isActionOf(userActions.loginDingTalk.request)),
        switchMap((x) =>
            authentication.authorizeUserByJwt(x.payload).pipe(
                map(userActions.loginDingTalk.success),
                catchError((x) => of(userActions.loginDingTalk.failure(x)))
            )
        )
    );

const resetPassword: RootEpic = (action$, _, { authentication }) =>
    action$.pipe(
        filter(isActionOf(userActions.resetPassword.request)),
        switchMap((x) =>
            authentication.resetPasswordData(x.payload.email).pipe(
                map(userActions.resetPassword.success),
                catchError((x) => of(userActions.resetPassword.failure(x)))
            )
        )
    );

const setNewPassword: RootEpic = (action$, _, { authentication }) =>
    action$.pipe(
        filter(isActionOf(userActions.setNewPassword.request)),
        switchMap((x) =>
            authentication.setNewPassword(x.payload.newPassword).pipe(
                map(userActions.setNewPassword.success),
                catchError(() =>
                    of(
                        userActions.setNewPassword.failure(
                            new Error(
                                localization.login_message_SomethingWentWrong
                            )
                        )
                    )
                )
            )
        )
    );

const onUserSettingsOpened: RootEpic = (action$, state$, _) => {
    return action$.pipe(filter(isActionOf(Action.user.settingsOpened))).pipe(
        exhaustMap(() => {
            return of(Action.user.loadLanguageList.request(null));
        })
    );
};

const setupAuthHeaders: RootEpic = (_, state$, { apiHeaders }) =>
    state$.pipe(
        map((x) => x.user.identity),
        distinctUntilChanged(),
        tap((x) => apiHeaders.setAuthentcationToken(x?.authenticationToken)),
        tap((x) => apiHeaders.setAes(x?.aes)),
        ignoreElements()
    );

const setupDisplayLanguageAndPreferredDefault: RootEpic = (
    action$,
    state$,
    { localizationService }
) =>
    state$.pipe(
        map((x) => x.user.identity),
        filter(
            (identity) =>
                !!(identity && identity.learnerUUID) && navigator.onLine
        ),
        distinctUntilChanged(),
        switchMap((x) => {
            return localizationService
                .getUserLanguagePreferrence(x!.learnerUUID)
                .pipe(
                    switchMap((learnerPreferences: any) => {
                        const { offlineModeEnabled } = learnerPreferences;
                        return of(
                            userActions.getPreferredLanguagesListDefault.request(
                                {
                                    learnerPreferences: learnerPreferences,
                                }
                            ),
                            Action.contentSavedActions.getSavedContentItems.request(
                                true
                            ),
                            Action.moduleRestriction.loadRestrictions.request(),
                            Action.inProgressTab.loadInProgressTabItems(),
                            Action.offline.setEnableOffline(offlineModeEnabled)
                        );
                    }),
                    catchError((e) =>
                        of(userActions.changeUILanguage.failure(e))
                    )
                );
        })
    );

const loadLanguageListPreferredDefault: RootEpic = (
    action$,
    state$,
    { preferredLanguageApi }
) => {
    return action$
        .pipe(
            filter(
                isActionOf(userActions.getPreferredLanguagesListDefault.request)
            )
        )
        .pipe(
            switchMap((x) => {
                return preferredLanguageApi
                    .getCommunicationLanguages(
                        x.payload.learnerPreferences
                            .preferedCommunicationLanguage
                    )
                    .pipe(
                        switchMap((r) => {
                            return of(
                                userActions.getPreferredLanguagesListDefault.success(
                                    {
                                        preferredLanguages: r,
                                        learnerPreferences:
                                            x.payload.learnerPreferences,
                                    }
                                )
                            );
                        }),
                        catchError((e) =>
                            of(
                                userActions.getPreferredLanguagesListDefault.failure(
                                    e
                                )
                            )
                        )
                    );
            })
        );
};

const loadLanguageListPreferred: RootEpic = (
    action$,
    state$,
    { preferredLanguageApi }
) => {
    return action$
        .pipe(filter(isActionOf(userActions.getPreferredLanguagesList.request)))
        .pipe(
            switchMap((x) => {
                return preferredLanguageApi
                    .getCommunicationLanguages(x.payload.preferredLangaugeCode)
                    .pipe(
                        switchMap((r) => {
                            return of(
                                userActions.getPreferredLanguagesList.success({
                                    preferredLanguages: r,
                                })
                            );
                        }),
                        catchError((e) =>
                            of(userActions.getPreferredLanguagesList.failure(e))
                        )
                    );
            })
        );
};

const setupLocalization: RootEpic = (
    action$,
    state$,
    { localizationService }
) =>
    action$
        .pipe(
            filter(
                isActionOf(userActions.getPreferredLanguagesListDefault.success)
            )
        )
        .pipe(
            switchMap((x) => {
                return localizationService
                    .getUserLanguageByIdAndDefaultUserLanguage(
                        state$.value?.user.availablePreferredLanguageList,
                        state$.value.user.identity?.language as string,
                        x.payload.learnerPreferences
                    )
                    .pipe(
                        switchMap((lang) => {
                            return of(
                                userActions.changeUILanguage.request({
                                    userId: state$.value.user.identity
                                        ?.learnerUUID as string,
                                    language: new LocalizationLanguage({
                                        ...lang.displayLanguage,
                                    }),
                                    preferredLanguage: new LocalizationLanguage(
                                        {
                                            ...lang.preferredLanguage,
                                        }
                                    ),
                                    skip: true,
                                }),
                                userActions.changePreferredLanguage.request({
                                    skip: true,
                                    userId: state$.value.user.identity
                                        ?.learnerUUID as string,
                                    language: new LocalizationLanguage({
                                        ...lang.preferredLanguage,
                                    }),
                                    displayLanguage: new LocalizationLanguage({
                                        ...lang.displayLanguage,
                                    }),
                                })
                            );
                        }),
                        catchError((e) =>
                            of(userActions.changeUILanguage.failure(e))
                        )
                    );
            })
        );

const setupLoggerHeaders: RootEpic = (_, state$) =>
    state$.pipe(
        map((x) => x.user.identity),
        distinctUntilChanged(),
        tap((x) => {
            logger?.setLearnerId(x?.learnerId);
            logger?.apiHeaders.setAuthentcationToken(x?.authenticationToken);
            logger?.apiHeaders.setAes(x?.aes);
        }),
        ignoreElements()
    );

const loadLanguageList: RootEpic = (action$, state$, { languageApi }) =>
    action$.pipe(
        filter(isActionOf(userActions.settingsOpened)),
        switchMap(() =>
            languageApi.getLocalizationLanguages().pipe(
                map((list) => userActions.loadLanguageList.success(list)),
                catchError((error) =>
                    of(userActions.loadLanguageList.failure(error))
                )
            )
        )
    );

const loadPreferredLanguageList: RootEpic = (
    action$,
    state$,
    { languageApi }
) =>
    action$.pipe(
        filter(isActionOf(userActions.settingsOpened)),
        switchMap(() => {
            return languageApi.getPreferredLocalizationLanguages().pipe(
                map((list) =>
                    userActions.loadPreferredLanguageList.success(list)
                ),
                catchError((error) =>
                    of(userActions.loadPreferredLanguageList.failure(error))
                )
            );
        })
    );

const changeUILanguage: RootEpic = (
    action$,
    state$,
    { localizationService }
) => {
    return action$
        .pipe(filter(isActionOf(userActions.changeUILanguage.request)))
        .pipe(
            switchMap((r) => {
                return localizationService
                    .changeUILanguage(
                        r.payload.userId,
                        r.payload.language.id,
                        r.payload.preferredLanguage?.id ||
                            state$.value.user.currentPreferredLanguage.id
                    )
                    .pipe(
                        switchMap(() => {
                            return state$.value.learnerProfile
                                .isProfileChangesOnLoading && !r.payload.skip
                                ? of(
                                      userActions.changeUILanguage.success(
                                          r.payload.language
                                      ),
                                      Action.contentSavedActions.setNotification(
                                          {
                                              show: true,
                                              error: false,
                                              maximum: false,
                                              text: localization.settings_notification_save_success,
                                              key: 109,
                                          }
                                      )
                                  )
                                : of(
                                      userActions.changeUILanguage.success(
                                          r.payload.language
                                      )
                                  );
                        }),
                        catchError((e) =>
                            of(
                                userActions.changeUILanguage.failure(e),
                                Action.contentSavedActions.setNotification({
                                    show: true,
                                    error: true,
                                    maximum: false,
                                    text: localization.error,
                                    key: 109,
                                })
                            )
                        )
                    );
            })
        );
};

const changePreferredLanguage: RootEpic = (
    action$,
    state$,
    { preferredLanguageApi }
) => {
    return action$
        .pipe(filter(isActionOf(userActions.changePreferredLanguage.request)))
        .pipe(
            switchMap((r) => {
                return preferredLanguageApi
                    .setUserPreferredLanguageCode({
                        userId: r.payload.userId,
                        preferedCommunicationLanguage: r.payload.language.id,
                        displayLanguage:
                            r.payload.displayLanguage?.id ||
                            state$.value.user.currentUILanguage.id,
                    })
                    .pipe(
                        switchMap((data) => {
                            return state$.value.learnerProfile
                                .isProfileChangesOnLoading && !r.payload.skip
                                ? of(
                                      userActions.changePreferredLanguage.success(
                                          r.payload.language
                                      ),
                                      userActions.getPreferredLanguagesList.request(
                                          {
                                              preferredLangaugeCode:
                                                  r.payload.language.id,
                                          }
                                      ),
                                      Action.contentSavedActions.setNotification(
                                          {
                                              show: true,
                                              error: false,
                                              maximum: false,
                                              text: localization.settings_notification_save_success,
                                              key: 108,
                                          }
                                      )
                                  )
                                : of(
                                      userActions.changePreferredLanguage.success(
                                          r.payload.language
                                      ),
                                      userActions.getPreferredLanguagesList.request(
                                          {
                                              preferredLangaugeCode:
                                                  r.payload.language.id,
                                          }
                                      )
                                  );
                        }),
                        catchError((e) =>
                            of(
                                Action.contentSavedActions.setNotification({
                                    show: true,
                                    error: true,
                                    maximum: false,
                                    text: localization.error,
                                    key: 208,
                                }),
                                userActions.changePreferredLanguage.failure(e)
                            )
                        )
                    );
            })
        );
};

const logoutEpic: RootEpic = (action$, _, { authentication }) =>
    action$.pipe(
        filter(isActionOf(userActions.logout.request)),
        tap(() => {
            const deviceId = localStorage.getItem('deviceId') || '';
            localStorage.clear();
            localStorage.setItem('deviceId', deviceId);
        }),
        mergeMap(() =>
            merge(
                of(userActions.closeSession.request()),
                authentication.logout().pipe(
                    map(userActions.logout.success),
                    catchError((x) => of(userActions.logout.failure(x)))
                )
            )
        )
    );

const clearStorageOnLogout: RootEpic = (action$, state$, _) =>
    action$.pipe(
        filter(isActionOf(userActions.logout.success)),
        tap(() => {
            const deviceId = localStorage.getItem('deviceId') || '';
            localStorage.clear();
            localStorage.setItem('deviceId', deviceId);
        }),
        switchMap(() => {
            localStorage.setItem('restartApp', 'true');
            return of(
                Action.vcr.clearClassroom(),
                Action.resources.clearAllResources()
            );
        })
    );

const updateSessionEpic: RootEpic = (action$, state$, { authentication }) =>
    action$.pipe(
        filter(isActionOf(userActions.updateSession.request)),
        switchMap((x) => {
            const jsessionId = state$.value.user?.identity?.timespent
                .jsessionId as string;
            const userId = state$.value.user?.identity?.learnerId as number;

            return authentication.updateSession(jsessionId, userId).pipe(
                map(userActions.updateSession.success),
                catchError((x) => of(userActions.updateSession.failure(x)))
            );
        })
    );

const closeSessionEpic: RootEpic = (action$, state$, { authentication }) =>
    action$.pipe(
        filter(isActionOf(userActions.closeSession.request)),
        switchMap((x) => {
            const jsessionId = state$.value.user?.identity?.timespent
                .jsessionId as string;
            const userId = state$.value.user?.identity?.learnerId as number;

            return authentication.closeSession(jsessionId, userId).pipe(
                map(userActions.closeSession.success),
                catchError((x) => of(userActions.closeSession.failure(x)))
            );
        })
    );

const loadUser: RootEpic = (_, state$, { authentication }) =>
    state$.pipe(
        map((x) => x.user.identity),
        filter(
            (identity) =>
                !!(identity && identity.learnerUUID) && navigator.onLine
        ),
        distinctUntilChanged(),
        switchMap((x) => {
            return authentication.getUserInformation(x!.learnerUUID).pipe(
                switchMap(() => {
                    return EMPTY;
                }),
                catchError((e) => {
                    const message = e.response.message;
                    const containsUser = message.includes('User');
                    const containsDoesNotExist =
                        message.includes('does not exist');
                    if (containsUser && containsDoesNotExist) {
                        return of(userActions.logout.request());
                    }

                    return EMPTY;
                })
            );
        })
    );

export const userEpics = [
    fetchUserTokenEpic,
    fetchUserDataEpicSSO,
    fetchUserDataEpicDingTalk,
    setupAuthHeaders,
    logoutEpic,
    setupLoggerHeaders,
    clearStorageOnLogout,
    resetPassword,
    setNewPassword,
    setupLocalization,
    setupDisplayLanguageAndPreferredDefault,
    loadLanguageListPreferredDefault,
    loadLanguageListPreferred,
    changeUILanguage,
    changePreferredLanguage,
    onUserSettingsOpened,
    loadLanguageList,
    loadPreferredLanguageList,
    updateSessionEpic,
    closeSessionEpic,
    postRenewJWToken,
    loadUser,
    generateJWTokenEpic,
];
