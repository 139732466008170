import { useAppDispatch } from '../root/app-dispatch';
import React, { useEffect } from 'react';
import debounce from 'lodash/debounce';
import { Action } from '../../store/root-action';

interface WindowSizeTrackerProps {
    delay: number;
}

export const WindowSizeTracker: React.FC<WindowSizeTrackerProps> = ({
    delay,
}) => {
    const dispatch = useAppDispatch();

    useEffect(() => {
        const handleResize = () =>
            dispatch(Action.app.changeAppWindowWidth(window.innerWidth));
        handleResize();
        const debouncedHandleResize = debounce(handleResize, delay);
        window.addEventListener('resize', debouncedHandleResize);
        return () => {
            window.removeEventListener('resize', debouncedHandleResize);
        };
    }, [delay, dispatch]);

    return <></>;
};
