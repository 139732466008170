import { RootEpic } from './root-epic';
import { of } from 'rxjs';
import {
    distinctUntilChanged,
    ignoreElements,
    tap,
    map,
    filter,
    switchMap,
    catchError,
} from 'rxjs/operators';
import { isActionOf } from 'typesafe-actions';
import { SSOUtils } from '../utils/SSO';
import { userActions } from '../store/user/user-actions';

const SSOIntegrationEpic: RootEpic = (
    action$,
    state$,
    { integrationRequireService }
) =>
    action$.pipe(
        filter(isActionOf(userActions.loginSSO.success)),
        switchMap(() => {
            const learnerUUID = state$.value.user.identity?.learnerUUID;
            const learnerExternalId =
                state$.value.user.identity?.learnerExternalId.toString();

            if (learnerUUID && learnerExternalId) {
                if (SSOUtils.isUseProvisioning()) {
                    return integrationRequireService
                        .SSOProvisioning(learnerUUID, learnerExternalId)
                        .pipe(
                            map((response) => {
                                localStorage.setItem(
                                    'IntegrationStatus',
                                    response.AICCParamSteps.processStatus
                                );
                                const transferParam =
                                    response.AICCParamSteps.transferParam;
                                if (transferParam) {
                                    localStorage.setItem(
                                        'IntegrationReport',
                                        JSON.stringify(transferParam)
                                    );
                                }
                                // Use any to return observable, since the goal of this epic is to cancel the redundant call
                                return of() as any;
                            }),
                            catchError((e) => {
                                console.error('Error SSOProvisioning: ', e);
                                return [];
                            })
                        );
                } else if (SSOUtils.isAICCTriggerHook()) {
                    return integrationRequireService
                        .SSOValidator(learnerUUID, learnerExternalId)
                        .pipe(
                            map((response) => {
                                localStorage.setItem(
                                    'IntegrationStatus',
                                    response.status
                                );
                                // Use any to return observable, since the goal of this epic is to cancel the redundant call
                                return of() as any;
                            }),
                            catchError((e) => {
                                console.error('Error SSOValidator: ', e);
                                return [];
                            })
                        );
                }
            }

            return of();
        })
    );

const userCustomFieldEpic: RootEpic = (
    _,
    state$,
    { integrationRequireService }
) =>
    state$.pipe(
        map((x) => x.user.identity),
        filter(
            (identity) =>
                !!(identity && identity.learnerUUID) && navigator.onLine
        ),
        distinctUntilChanged(),
        tap((x) => {
            if (x && SSOUtils.isOCNTriggerHook()) {
                integrationRequireService.UserCustomField(x!.learnerUUID);
            }
        }),
        ignoreElements()
    );

export const integrationEpics = [userCustomFieldEpic, SSOIntegrationEpic];
