import { LogLevel } from '../services/logging/log-level';
import { ApiHeaders } from '../services/api-headers';
import log from 'loglevel';
import geolocation from '../utils/geolocation';
import { LoggingApi } from '../services/logging-api';
import { LogEntry } from '../services/logging/log-entry';
import { GenericQueue } from './generic-queue';

const LogLevelNames = ['FATAL', 'ERROR', 'WARN', 'INFO', 'DEBUG', 'TRACE'];

/**
 * Class for remote logging with queue
 */
class Logger {
    static logger: Logger;

    readonly appName: string;
    readonly logQueue: GenericQueue<LogEntry>;

    api: LoggingApi;
    apiHeaders: ApiHeaders;
    learnerId?: number | null = null;

    logLevel: LogLevel = LogLevel.INFO;

    addLogData: () => Object;

    /**
     * This is a constructor
     * @param appName
     * @param logLevel
     * @param addLogData
     */
    constructor(appName: string, addLogData: () => Object) {
        this.appName = appName;
        this.apiHeaders = new ApiHeaders();
        this.api = new LoggingApi(this.apiHeaders);

        this.logQueue = new GenericQueue<LogEntry>(this.api, 'log_queue');
        this.addLogData = addLogData;
    }

    setLearnerId(learnerId?: number | null) {
        this.learnerId = learnerId;
    }

    configure(): void {
        this.api.getSettings().subscribe((settings) => {
            this.logLevel = settings && settings.logLevel;
        });
    }

    private getLogLevelName(logLevel: LogLevel): string {
        if (logLevel < 0 || logLevel > 5) {
            return '';
        }

        return LogLevelNames[logLevel];
    }

    /**
     *
     * @param className
     * @param logLevel
     * @param messageFunction
     * @param withConsole
     */
    private sendLog(
        logLevel: LogLevel,
        className: string,
        eventName: string,
        messageFunction: () => Object,
        withConsole: Boolean
    ): void {
        if (this.logLevel >= logLevel) {
            geolocation.getGeolocationCoordinates().subscribe((coords) => {
                let message: LogEntry = {
                    level: this.getLogLevelName(logLevel),
                    appName: this.appName,
                    className: className ? className : 'default.class.name',
                    exception: eventName,
                    message: {
                        ...messageFunction(),
                        ...this.addLogData(),
                    },
                };
                this.logQueue.send(message, true);
            });

            if (withConsole) {
                let logfn = this.getLogFn(logLevel);
                logfn({
                    className: className,
                    eventName: eventName,
                    ...messageFunction(),
                });
            }
        }
    }

    private sendLogMessage(
        logLevel: LogLevel,
        className: string,
        message: any,
        optionalParams: any[],
        withConsole: Boolean = true
    ): void {
        if (this.logLevel >= logLevel) {
            this.sendLog(
                logLevel,
                className,
                '',
                () => {
                    return {
                        ...{ message },
                        ...(optionalParams.length > 0
                            ? { optionalParams }
                            : {}),
                    };
                },
                withConsole
            );
        }
    }

    fatal(message?: any, ...optionalParams: any[]) {
        this.sendLogMessage(LogLevel.FATAL, '', message, optionalParams, true);
    }

    error(message?: any, ...optionalParams: any[]) {
        this.sendLogMessage(LogLevel.ERROR, '', message, optionalParams, true);
    }

    warn(message?: any, ...optionalParams: any[]) {
        this.sendLogMessage(LogLevel.WARN, '', message, optionalParams, true);
    }

    info(message?: any, ...optionalParams: any[]) {
        this.sendLogMessage(LogLevel.INFO, '', message, optionalParams, true);
    }

    debug(message?: any, ...optionalParams: any[]) {
        this.sendLogMessage(LogLevel.DEBUG, '', message, optionalParams, true);
    }

    trace(message?: any, ...optionalParams: any[]) {
        this.sendLogMessage(LogLevel.TRACE, '', message, optionalParams, true);
    }

    getLogFn(
        logLevel: LogLevel
    ): (message?: any, ...optionalParams: any[]) => void {
        switch (logLevel) {
            case LogLevel.FATAL:
                return log.error;
            case LogLevel.ERROR:
                return log.error;
            case LogLevel.WARN:
                return log.warn;
            case LogLevel.INFO:
                return log.info;
            case LogLevel.DEBUG:
            case LogLevel.TRACE:
                return log.debug;
            default:
                return log.debug;
        }
    }
}

export { Logger, LogLevel };
