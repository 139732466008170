import { Record, List } from 'immutable';
import moment, { Moment } from 'moment';

type TeacherId = number;
type LessonId = string;

interface TeacherProps {
    teacherId: TeacherId;
    isWebcam: boolean;
    gender: string;
    name: string;
    lessonCount: number;
    photo?: string;
    authorizedMedia: List<MediaProps>;
    authorizedProducts: List<AuthorizedProductProps>;
}

const defaultTeacherProps: TeacherProps = {
    teacherId: 0,
    isWebcam: false,
    gender: '',
    name: '',
    lessonCount: 0,
    photo: '',
    authorizedMedia: List<MediaProps>(),
    authorizedProducts: List<AuthorizedProductProps>(),
};

/**
 * Class describing teacher
 */
export class Teacher
    extends Record(defaultTeacherProps)
    implements TeacherProps {}

interface AvailabilityProps {
    lessonId: LessonId;
    startDateTime: Moment;
    endDateTime?: Moment;
    status: string;
    teacherId?: TeacherId;
    teacher?: Teacher;
    teacherList?: List<Teacher>;
    creditId: number;
    duration: number;
    mediaId: number;
    eventType: string;
}

const defaultAvailabilityProps: AvailabilityProps = {
    startDateTime: moment().startOf('day'),
    endDateTime: moment().startOf('day'),
    status: '',
    lessonId: '',
    teacher: undefined,
    teacherId: 0,
    duration: 0,
    creditId: 0,
    teacherList: List(),
    mediaId: 0,
    eventType: '',
};

/**
 * Class describing Availability(possible lesson)
 */
export class Availability
    extends Record(defaultAvailabilityProps)
    implements AvailabilityProps {}

interface AttendeesGroupProps {
    firstName: string;
    lastName: string;
    media: string;
    mediaType: string;
    role: string;
    securityId: string;
    uuid: string;
}

interface AvailabilityGroupProps {
    attendees: List<AttendeesGroupProps>;
    duration: number;
    eventStatus: string;
    lessonStart: any;
    lessonStatus: string;
    meetingLink: string;
    sourceBusinessId: string;
    sourceBusinessType: string;
}

const defaultAvailabilityGroupProps: AvailabilityGroupProps = {
    attendees: List(),
    duration: 0,
    eventStatus: '',
    lessonStart: '',
    lessonStatus: '',
    meetingLink: '',
    sourceBusinessId: '',
    sourceBusinessType: '',
};

/**
 * Class describing Availability(possible lesson)
 */
export class AvailabilityGroupLesson
    extends Record(defaultAvailabilityGroupProps)
    implements AvailabilityGroupProps {}

export interface CreditProps {
    name: string;
    creditId: number;
    expiration: string;
    creditLeft: number;
    duration: number[];
}

const defaultCreditProps: CreditProps = {
    name: '',
    creditId: 0,
    expiration: '',
    creditLeft: 0,
    duration: [0],
};

/**
 * Class describing credit
 */
export class CreditRecord
    extends Record(defaultCreditProps)
    implements CreditProps {}

interface CancellationProps {
    credit: CreditProps;
    lessonId: string;
    label?: string;
}

const defaultCancellationProps: CancellationProps = {
    credit: {
        name: '',
        creditId: 0,
        duration: [0],
        expiration: '',
        creditLeft: 0,
    },
    lessonId: '',
    label: undefined,
};

/**
 * Class describing Cancellation
 */
export class Cancellation
    extends Record(defaultCancellationProps)
    implements CancellationProps {}

interface AuthorizedProductProps {
    name: string;
    id: number;
    priority: number;
    externalIdentifier: string;
    cost: number;
    costType: string;
    productGroup: string;
    productType: string;
    maximumMinutes: number;
    minimumMinutes: number;
    postLearnerMinutes: number;
    postTeacherMinutes: number;
    preLearnerMinutes: number;
    preTeacherMinutes: number;
    timeUnit: number;
    hasShortDuration: boolean;
}

interface MediaProps {
    default: boolean;
    mediaType: string;
    userValue: string;
    mediaID: number;
}

const defaultMediaProps: MediaProps = {
    default: false,
    mediaType: '',
    userValue: '',
    mediaID: 0,
};

const defaultProductProps: AuthorizedProductProps = {
    name: '',
    id: 0,
    priority: 0,
    externalIdentifier: '',
    cost: 0,
    costType: '',
    productGroup: '',
    productType: '',
    maximumMinutes: 0,
    minimumMinutes: 0,
    postLearnerMinutes: 0,
    postTeacherMinutes: 0,
    preLearnerMinutes: 0,
    preTeacherMinutes: 0,
    timeUnit: 0,
    hasShortDuration: false,
};

/**
 * Class describing media
 */
export class ProductRecord
    extends Record(defaultProductProps)
    implements AuthorizedProductProps {}

/**
 * Class describing media
 */
export class MediaRecord
    extends Record(defaultMediaProps)
    implements MediaProps {}

export interface NewMediaProps {
    mediaType: string;
    description?: string;
    dialingCode: string;
    phoneExtension?: string;
    userValue: string;
    learnerID: string;
}

interface ForesenProps {
    LessonExternalId: number;
    Media: string;
    Schedule: string;
    Duration: number;
    Trainer: string;
    Timezone: string;
    MediaType: string;
}

const defaultForesenProps: ForesenProps = {
    LessonExternalId: 0,
    Media: '',
    Schedule: '',
    Duration: 0,
    Trainer: '',
    Timezone: '',
    MediaType: '',
};

/**
 * Class describing foreseen
 */
export class Foresen
    extends Record(defaultForesenProps)
    implements ForesenProps {}

const defaultSchedulerSelectedTeachersProps: SchedulerSelectedTeachers = {
    selectedTeachers: List<Teacher>(),
    selectAll: false,
};

export interface SchedulerSelectedTeachers {
    selectedTeachers: List<Teacher>;
    selectAll: boolean;
}

/**
 * Class describing scheduler selected teacher properties
 */
export class SchedulerSelectedTeachersProps
    extends Record(defaultSchedulerSelectedTeachersProps)
    implements SchedulerSelectedTeachers {}

interface LimitationProps {
    maxLessonHours: string | number | null;
    maxLessonRange: string | number | null;
    displayAvailabilityValue: string | number | null;
    displayAvailabilities: string | number | null;
}

const defaultLimitationProps: LimitationProps = {
    maxLessonHours: 'none',
    maxLessonRange: 'none',
    displayAvailabilityValue: 'none',
    displayAvailabilities: 'none',
};

interface WithMaxLessonHoursLimitation {
    maxLessonHours: number | string;
    maxLessonRange: string;
}

interface WithAvailabilityLimitation {
    displayAvailabilityValue: number | string;
    displayAvailabilities: string;
}

type TimeUnit = 'week' | 'month' | 'year' | 'day';

function toTimeUnit(s: string) {
    s = s.toLowerCase();
    return s as TimeUnit;
}

const hasValue = (value: string | number | null) => {
    return value && String(value).toLowerCase() !== 'none';
};

export class SchedulerLimitation
    extends Record(defaultLimitationProps)
    implements LimitationProps
{
    hasDisplayAvailabilityLimitation(): this is WithAvailabilityLimitation {
        return Boolean(
            hasValue(this.displayAvailabilityValue) &&
                hasValue(this.displayAvailabilities)
        );
    }

    getMaxDateForBrowsing(): Moment {
        /* current week is always available and counts as 1,
                therefore if limit is 2 weeks, then _this_ week and _the next_
                weeks' days are selectable, but the week after that is not
            */
        const maxDate = moment();

        if (this.hasDisplayAvailabilityLimitation()) {
            const count = Number(this.displayAvailabilityValue);
            const unit = toTimeUnit(this.displayAvailabilities);
            maxDate.add(count, unit);
        } else {
            maxDate.add(1, 'y');
        }

        return maxDate;
    }

    hasHoursLimitation(): this is WithMaxLessonHoursLimitation {
        return Boolean(
            hasValue(this.maxLessonHours) && hasValue(this.maxLessonRange)
        );
    }

    getMaxHoursRange(): string {
        return String(this.maxLessonRange);
    }

    getMaxHours(): number {
        if (this.hasHoursLimitation()) {
            return Number(this.maxLessonHours);
        }

        return Number.POSITIVE_INFINITY;
    }
}

const defaultSchedulerLanguageProps: SchedulerLanguage = {
    learnerName: '',
    translations: {},
};

export interface SchedulerLanguage {
    learnerName: string;
    translations: { [key: string]: string };
}

/**
 * Class describing scheduler and lessons list language properties
 */
export class SchedulerLanguageProps
    extends Record(defaultSchedulerLanguageProps)
    implements SchedulerLanguage {}

interface MediaDTO {
    Default: boolean;
    MediaType: string;
    UserValue: string;
    MediaID: number;
}

interface AuthorizedMediaDTO {
    name: string;
    id: number;
    telco: boolean;
    textual: boolean;
    vocal: boolean;
    lessonMedia: boolean;
}

export interface LearnerDTO {
    LearnerName: string;
    LearnerID: number;
    LearnerTimezone: string;
    TimeZoneOffset: number;
    LearnerLanguage: string;
    LearnerDefaultMedia: MediaDTO;
    LearnerAuthorizedMedia: Array<AuthorizedMediaDTO>;
    LearnerIsBilingual?: boolean;
    LearnerCountry: string;
    LearnerIsWebcam: string;
}

const defaultLearnerProps: LearnerDTO = {
    LearnerName: '',
    LearnerID: 0,
    LearnerTimezone: '',
    TimeZoneOffset: 0,
    LearnerLanguage: '',
    LearnerDefaultMedia: {
        Default: false,
        MediaType: '',
        UserValue: '',
        MediaID: 0,
    },
    LearnerAuthorizedMedia: [],
    LearnerIsBilingual: false,
    LearnerIsWebcam: '',
    LearnerCountry: '',
};

/**
 * Class describing scheduler and lessons list language properties
 */
export class SchedulerLearnerProps
    extends Record(defaultLearnerProps)
    implements LearnerDTO {}
