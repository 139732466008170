import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Button } from '@arcadia-projects/portal2-ui-library';
import styles from './styles.module.css';

const DingAuthStartPage = () => {
    const search = useLocation().search;
    const corpId = new URLSearchParams(search).get('corpId');
    const clientId = new URLSearchParams(search).get('client');
    const history = useHistory();

    console.log('corpId', corpId);
    console.log('clientId', clientId);

    return (
        <div className={styles.dingPage}>
            <header className={styles.header}>
                <React.Fragment>
                    <div>Hello!</div>
                    <p>
                        You are going to be authorized in goFLUENT's Language
                        Academy
                    </p>
                    <Button
                        variant={'contained'}
                        className={styles.dingButton}
                        onClick={() => {
                            history.push(
                                `/dingconnectorauth?client=${clientId}&corpId=${corpId}`
                            );
                        }}
                    >
                        Continue
                    </Button>
                </React.Fragment>
            </header>
        </div>
    );
};

export default DingAuthStartPage;
