import { localization } from '../../../localization';
import {
    RateOptions,
    SurveyBusinessType,
    SurveyOptionsResponse,
} from '../types';

type localizationType = typeof localization;

export const getRateQuestion = (rate: number, type: SurveyBusinessType) => {
    if (rate <= 3) {
        return type === 'ConversationClass'
            ? localization.trainerRatingSurvey_conversationClass_bad_question
            : type === 'IndividualLesson'
            ? localization.trainerRatingSurvey_individualLesson_bad_question
            : localization.trainerRatingSurvey_groupLesson_bad_question;
    } else if (rate === 4) {
        return type === 'ConversationClass'
            ? localization.trainerRatingSurvey_conversationClass_good_question
            : type === 'IndividualLesson'
            ? localization.trainerRatingSurvey_individualLesson_good_question
            : localization.trainerRatingSurvey_groupLesson_good_question;
    } else {
        return type === 'ConversationClass'
            ? localization.trainerRatingSurvey_conversationClass_perfect_question
            : type === 'IndividualLesson'
            ? localization.trainerRatingSurvey_individualLesson_perfect_question
            : localization.trainerRatingSurvey_groupLesson_perfect_question;
    }
};

export const translateLabeledOption = (
    label: string,
    type: SurveyBusinessType
): string => {
    const groupKey = `survey_group_options_${label}`;
    const commonKey = `survey_lesson_options_${label}`;
    if (type === 'GroupLesson') {
        if (groupKey in localization) {
            return localization[groupKey as keyof localizationType] as string;
        } else {
            return localization[commonKey as keyof localizationType] as string;
        }
    } else {
        return localization[commonKey as keyof localizationType] as string;
    }
};

export const specifyRatingOptions = (
    options: SurveyOptionsResponse,
    type: SurveyBusinessType
): RateOptions => {
    let result: RateOptions = {};
    for (const rateOption of Object.entries(options)) {
        const key = rateOption[0];
        const rate = parseInt(key);
        result[rate] = {
            question: getRateQuestion(rate, type),
            options: rateOption[1].reduce((acc, option) => {
                acc[option] = translateLabeledOption(option, type);
                return acc;
            }, {} as { [key: string]: string }),
        };
    }
    return result;
};
