import Immutable from 'immutable';
import Serialize from 'remotedev-serialize';
import * as reduxPersist from 'redux-persist';

export default function immutableTransform(config: any): any {
    config = config || {};

    // @ts-ignore
    const serializer = Serialize.immutable(Immutable, config.records);

    return reduxPersist.createTransform(
        serializer.stringify,
        serializer.parse,
        config
    );
}
