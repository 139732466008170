import { Record } from 'immutable';

export interface WordDescriptionProps {
    content: string;
    imageUrl: string;
    title: string;
    index: number;
}

export const defaultWordDescriptionProps: WordDescriptionProps = {
    content: '',
    imageUrl: '',
    title: '',
    index: 0,
};

export class WordDescription
    extends Record(defaultWordDescriptionProps)
    implements WordDescriptionProps {}
