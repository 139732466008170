import {
    catchError,
    filter,
    map,
    mergeMap,
    switchMap,
    take,
    withLatestFrom,
} from 'rxjs/operators';
import { isActionOf } from 'typesafe-actions';
import { RootEpic } from '../root-epic';
import { Action } from '../../store/root-action';
import { combineLatest, of } from 'rxjs';

export const loadAll: RootEpic = (action$, state$) =>
    action$.pipe(
        filter(isActionOf([Action.inProgressTab.loadInProgressTabItems])),
        mergeMap(() =>
            of(
                Action.inProgressTab.loadActivityList.request(),
                Action.inProgressTab.loadCourseList.request(),
                Action.inProgressTab.loadTestsList.request()
                // Action.inProgressTab.loadEwritingsList.request(),
            )
        )
    );

export const loadActivityList: RootEpic = (
    action$,
    state$,
    { inProgressApi }
) =>
    action$.pipe(
        filter(
            isActionOf([
                Action.inProgressTab.loadActivityList.request,
                Action.inProgressTab.loadActivityList.cancel,
            ])
        ),
        withLatestFrom(state$),
        mergeMap(([x, state]) => {
            return inProgressApi
                .loadActivityList(state.user.identity?.learnerUUID as string)
                .pipe(
                    mergeMap((data) => {
                        return of(
                            Action.inProgressTab.loadActivityList.success(data)
                        );
                    }),
                    catchError((e) => {
                        console.log('loadActivityList epic error', e);
                        return of(
                            Action.inProgressTab.loadActivityList.failure(e)
                        );
                    })
                );
        })
    );

export const loadCourseList: RootEpic = (action$, state$, { inProgressApi }) =>
    action$.pipe(
        filter(
            isActionOf([
                Action.inProgressTab.loadCourseList.request,
                Action.inProgressTab.loadCourseList.cancel,
            ])
        ),
        withLatestFrom(state$),
        mergeMap(([x, state]) => {
            return inProgressApi
                .loadCoursesList(state.user.identity?.learnerUUID as string)
                .pipe(
                    mergeMap((data) => {
                        return of(
                            Action.inProgressTab.loadCourseList.success(data)
                        );
                    }),
                    catchError((e) => {
                        console.log('loadCourseList epic error', e);
                        return of(
                            Action.inProgressTab.loadCourseList.failure(e)
                        );
                    })
                );
        })
    );

export const loadTestsList: RootEpic = (action$, state$, { inProgressApi }) =>
    combineLatest([
        // Use combineLatest here for subscribe each Observable
        action$.pipe(
            filter(
                isActionOf([
                    Action.inProgressTab.loadTestsList.request,
                    Action.inProgressTab.loadTestsList.cancel,
                ])
            )
        ),
        state$,
    ]).pipe(
        filter(
            ([, state]) =>
                !!state.learnerProfile.currentLanguage &&
                !!state.user.identity?.learnerUUID
        ), // Filter to wait for currentLanguage && learnerUUID to be present
        take(1), // Add take(1) operator to ensure the API call is made only once
        switchMap(([x, state]) => {
            // Replace mergeMap with switchMap
            return inProgressApi
                .loadTestsList(
                    // Added here "!" because we already know that this values are not null or undefined, because of filter we use before, but typescript compiler is not satisfied
                    state.user.identity!.learnerUUID,
                    state.learnerProfile.currentLanguage!.languageUUID
                )
                .pipe(
                    mergeMap((data) => {
                        return of(
                            Action.inProgressTab.loadTestsList.success(data)
                        );
                    }),
                    catchError((e) => {
                        console.log('loadTestsList epic error', e);
                        return of(
                            Action.inProgressTab.loadTestsList.failure(e)
                        );
                    })
                );
        })
    );

const loadCompletedActivitiesForInProgressEpic: RootEpic = (
    action$,
    state$,
    { activityReportApi }
) =>
    action$.pipe(
        filter(
            isActionOf(
                Action.inProgressTab.loadInProgressCompletedActivities.request
            )
        ),
        switchMap((x) => {
            const userUUID = state$.value.user.identity?.learnerUUID;
            return activityReportApi
                .loadCompletedList(userUUID as string, null)
                .pipe(
                    map((x) =>
                        Action.inProgressTab.loadInProgressCompletedActivities.success(
                            x
                        )
                    ),
                    catchError((e) =>
                        of(
                            Action.inProgressTab.loadInProgressCompletedActivities.failure(
                                e
                            )
                        )
                    )
                );
        })
    );

export const inProgressTabEpics = [
    loadAll,
    loadActivityList,
    loadCourseList,
    loadTestsList,
    // loadEwritingsList,
    loadCompletedActivitiesForInProgressEpic,
];
