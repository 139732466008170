import { usePollingSurveys } from './usePollingSurveys';
import { useGetSurveysOptions } from './useGetSurveysOptions';

export const SurveyOptionsFetchAsComponent = () => {
    useGetSurveysOptions();
    return null;
};

// Fetching surveys data as component view. Hooks can't be called conditionally
export const SurveyFetchQueue = () => {
    const { isIL, isGL, isCC, isPS } = usePollingSurveys();
    if (isPS || isIL || isCC || isGL) {
        return <SurveyOptionsFetchAsComponent />;
    }
    return null;
};
