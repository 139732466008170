import { ApiHeaders } from '../api-headers';
import Axios from 'axios-observable';
import { map } from 'rxjs/operators';
import { HowtoDescription } from '../../store/resources/howtos/howto-description';
import { ResourceContent } from '../../store/resources/resource-content';
import { HowtoContent } from '../../store/resources/howtos/howto-content';
import { List } from 'immutable';
import { Section } from '../../store/resources/howtos/section';
import { TitleSlideContent } from '../../store/resources/howtos/title-slide-content';
import {
    DialogueLine,
    GenericSlideContent,
    GenericSlideSet,
} from '../../store/resources/howtos/generic-slide-content';
import { ResourceContentType } from '../../store/resources/resource-content-type';
import { parseDtoToResourceType } from './resource-type-dto';
import { SummarySlideContent } from '../../store/resources/howtos/summary-slide-content';

import { workshop } from '../api-urls';

export class ResourceContentApi {
    private readonly apiHeaders: ApiHeaders;

    constructor(apiHeaders: ApiHeaders) {
        this.apiHeaders = apiHeaders;
    }

    load(resource: { groupId: number; articleId: number }) {
        const { groupId, articleId } = resource;

        return Axios.get<ResourceContent>(
            `${workshop()}/${groupId}/${articleId}`,
            {
                headers: this.apiHeaders.getHeaders(),
            }
        ).pipe(
            map((x) => {
                const resource = x.data;

                if (!resource) throw new Error('Can not load resource');

                if (
                    resource.legacy.contentType === ResourceContentType.Workshop
                ) {
                    return this.parseHowtoContent(resource);
                } else {
                    throw new Error('Unknown resource');
                }
            })
        );
    }

    private parseHowtoContent(resource: ResourceContent) {
        const content = resource.content;
        const learnObjectives =
            content?.title_page?.learn_objectives?.objective;

        const titleSlide = new TitleSlideContent({
            objectives: learnObjectives ? List(learnObjectives) : undefined,
            studyTime: content?.title_page?.study_time,
            levels: content?.title_page?.skills,
            bgImage: content?.title_page?.background_image,
            resourceType: resource.legacy.contentType,
        });

        const titleSection = new Section({
            instanceId: '0',
            title: content?.title_page?.value,
            slides: List([titleSlide]),
        });

        const contentBlock = content.block as any[];
        const blocks =
            contentBlock?.map<Section>((x) => {
                const slides = (x.block_title.slide as any[]).map((y) => {
                    const sets = (y.sets as any[]).map(
                        (s) =>
                            new GenericSlideSet({
                                audio: s.audio,
                                content: s.content,
                                image: s.image,
                                video: s.video,
                                videoSubbed: s.video_subbed,
                            })
                    );
                    const dialogueLines = (y.dialogue_line as any[]).map(
                        (d) =>
                            new DialogueLine({
                                thumbnail: d.thumbnail,
                                audioTime: d.audio_time,
                                content: d.text,
                            })
                    );
                    return new GenericSlideContent({
                        audioMain: y.audio_main,
                        mainImageUrl: y.main_image_url,
                        template: y.slide_template,
                        alignment: y.alignment,
                        mainVideoUrl: y.main_video_url,
                        mainVideoUrlSubbed: y.main_video_url_subbed,
                        intro: y.intro,
                        size: y.size,
                        sets: List(sets),
                        dialogueLine: List(dialogueLines),
                    });
                });

                return new Section({
                    instanceId: x.value,
                    title: x.block_title.value,
                    slides: List(slides),
                });
            }) || [];

        const summarySlide = new SummarySlideContent({
            body: content?.summary,
        });

        const summarySection = new Section({
            instanceId: String(blocks.length + 1),
            title: 'Summary',
            slides: List([summarySlide]),
        });

        const sections = [titleSection, ...blocks, summarySection];

        const howtoContent = new HowtoContent({
            // instructionsPost: content.instructionsPost,
            // trainerNotes: content?.trainerNotes,
            // bgImage: content?.bgImage,
            titleSection: titleSection,
            // summarySection: summarySection,
            sections: List(sections),
        });

        return new HowtoDescription({
            contentMetadata: resource?.contentMetadata ?? null,
            resourceId: String(resource.legacy.articleId),
            groupId: resource.legacy.groupId,
            title: resource.title,
            content: howtoContent,
            type: parseDtoToResourceType(resource.legacy.contentType),
            contentUUId: resource.id,
            template: 'nrt',
        });
    }
}
