import { List, Map, Record } from 'immutable';
import Question from './question';
import { CommitableAnswer } from '../../store/quiz/quiz-actions';

/**
 * Correct answers as seen in server responses.
 */
type AnswerDTO =
    | Array<{ fNo: number; ans: string[] }>
    | Array<string>
    | Array<number>
    | string;

/**
 * Question described as a JSON object with concise
 * property names.
 *
 * q: HTML, question text
 * qt: enum, question type
 * ot: enum, output type
 * t: enum, type?
 *
 * i: HTML, what's needed to be done
 *
 * ao: ?
 * aom: media link
 *
 * sc: ?
 * aa: ?
 * qNo: order of the question in the quiz
 * ans: correct answer
 *
 * o1: first option, if present
 * o2: second option, if present
 * o3: third option, if present,
 * o4: fourth option, if present
 */
export type QuestionDTO = {
    q: string;
    qt: string;
    ot: string;
    t: string;

    i: string;

    ao: string;
    aom: string;

    // optionals
    sc?: string;
    aa: string;
    qNo: number;
    ans: AnswerDTO;

    o1?: any;
    o2?: any;
    o3?: any;
    o4?: any;
};

/**
 * QuizAttemptItem JSON representation
 *
 * a: correct answer
 * dt: date and time in full format (GMT), string
 * q: question number
 * r: "1" - right user answer, "0" - wrong
 * s: question string number
 * t: time in hh:mm:ss format, string
 *
 */
type QuizAttemptItemDTO = {
    a: AnswerDTO;
    dt?: string;
    q: number;
    r?: string;
    s: string;
    t: string;
};

/**
 * QuizAttempt JSON representation
 *
 * aif: ?
 * dc: ?
 * dt: ?
 * i: items (questions + answers)
 * noi: questions count
 * qri: quiz result identifier
 * s: quiz status
 * sc: correct answers count
 * sks: ?
 * sq: array of answered questions numbers
 */
export interface QuizAttemptDTO {
    aif: unknown;
    dc: unknown;
    dt: number;
    i: Array<QuizAttemptItemDTO>;
    noi: number;
    qri: number;
    s: string;
    sc: unknown;
    sks: unknown;
    sq: Array<unknown>;
}

/**
 * Quiz JSON representation
 *
 * noi: number of items (questions)
 * q: questions
 * qh: quiz attempts
 * ss: ?
 * t: ?
 */
type QuizDTO = {
    noi: number;
    q: QuestionDTO[];
    qh: QuizAttemptDTO[];
    ss: string;
    t: any;
};

/**
 * It seems, that quiz is identified by qArticleId (quiz article id),
 * but other parameters are also required.
 */
export type QuizIdentifier = {
    groupId?: number;
    articleId?: number;
    qArticleId: number;
    learnerId?: number;
    qri?: number;
};

interface QuizProps {
    id: QuizIdentifier;
    questions: Question[];
    _data: any;
    attempts: Array<QuizAttemptDescription>;
}

const defaultQuizProps: QuizProps = {
    id: {
        groupId: undefined,
        articleId: undefined,
        qArticleId: 0,
        learnerId: undefined,
        qri: undefined,
    },
    questions: [],
    _data: {},
    attempts: [],
};

/**
 * QuizMode is used to figure out what to do on Next button.
 */
type QuizMode = 'filling-in' | 'review' | 'results';

export interface QuizAttemptProps {
    qri: number;
    completed: boolean;
    status: string;
    suppliedAnswers: List<{
        ord: number;
        answer: any;
    }>;
    correctAnswers: Map<number, boolean>;
    questionsCount: number;
    currentQuestionNumber: number;
    currentMode: QuizMode;
}

const defaultQuizAttemptProps = {
    qri: 0,
    completed: false,
    status: '',
    suppliedAnswers: List<{ ord: number; answer: any }>(),
    correctAnswers: Map<number, boolean>(),
    questionsCount: 1,
    currentQuestionNumber: 1,
    currentMode: 'filling-in' as QuizMode,
};

function parseSuppliedAnswer(answer: unknown, question?: Question) {
    if (typeof answer === 'string') {
        let splitted = answer.split(',');
        let asNumbers = splitted.map((n) => parseInt(n, 10));
        if (asNumbers.every((n) => !Number.isNaN(n))) {
            // console.log('parseSuppliedAnswer| number[]', asNumbers);
            return List(asNumbers);
        }
    }
    if (Array.isArray(answer)) {
        if (
            answer.every(
                (item) =>
                    typeof item === 'string' && !Number.isNaN(Number(item))
            )
        ) {
            let ansNumbers = [] as number[];
            answer.map((ans) => ansNumbers.push(Number(ans)));
            // console.log('parseSuppliedAnswer| number[]', ansNumbers);
            return List(ansNumbers);
        }
        // console.log('parseSuppliedAnswer|any[]', answer);
        return List(answer);
    }
    // console.log('parseSuppliedAnswer|anything', answer);
    return answer;
}

/**
 * QuizAttemptDescription encapsulates a single attempt at passing
 * the quiz that can be reviewed or continued after browser refresh.
 */
export class QuizAttemptDescription
    extends Record(defaultQuizAttemptProps)
    implements QuizAttemptProps
{
    static fromDTO(attempt: QuizAttemptDTO, questions: Question[]) {
        let completed = attempt.s !== 'UNFINISHED';
        let qri = attempt.qri;
        let status = attempt.s;
        let questionsCount = attempt.noi;
        let suppliedAnswers = attempt.i
            .filter((x) => x)
            .map((item) => {
                let ord = item.q;
                let question = questions.find(
                    (question) => question.order === item.q
                );
                let answer = parseSuppliedAnswer(item.a, question);
                return {
                    ord,
                    answer,
                };
            });
        let correctAnswers: Map<number, boolean> = Map<number, boolean>(
            suppliedAnswers.map((ans) => {
                let question = questions[ans.ord - 1];
                let isCorrect = question
                    ? question.isValidAnswer(ans.answer as CommitableAnswer)
                    : false;
                return [ans.ord, isCorrect];
            })
        );

        let currentQuestionNumber = suppliedAnswers.length + 1;
        return new QuizAttemptDescription({
            completed,
            qri,
            status,
            suppliedAnswers: List(suppliedAnswers),
            questionsCount,
            currentQuestionNumber,
            correctAnswers,
            currentMode: completed ? 'results' : 'filling-in',
        });
    }
}

/**
 * QuizDescription holds immutable parts of the quiz that
 * do not depend on current attempt.
 */
export class QuizDescription
    extends Record(defaultQuizProps)
    implements QuizProps
{
    constructor({
        quizId,
        quizDto,
    }: {
        quizId: QuizIdentifier;
        quizDto: QuizDTO;
    }) {
        let questions = quizDto.q
            .map((question: any) => Question.fromJSON(question))
            .sort((a: Question, b: Question) => a.order - b.order);

        super({
            id: quizId,
            questions: questions,
            _data: quizDto,
            attempts:
                !!quizDto.qh && quizDto.qh.length > 0
                    ? quizDto.qh?.map((h: QuizAttemptDTO) =>
                          QuizAttemptDescription.fromDTO(h, questions)
                      )
                    : [],
        });
    }

    identifier() {
        return this.id;
    }
}

/**
 * QuizDescription holds immutable parts of the quiz that
 * do not depend on current attempt.
 */
export class QuizDescriptionOffline
    extends Record(defaultQuizProps)
    implements QuizProps
{
    constructor(props: QuizProps) {
        super({
            id: props.id,
            questions: props.questions,
            _data: props._data,
            attempts: props.attempts,
        });
    }

    identifier() {
        return this.id;
    }
}

export class QuizAttemptDescriptionOffline
    extends Record(defaultQuizAttemptProps)
    implements QuizAttemptProps {}
