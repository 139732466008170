import { useEffect } from 'react';
import { isCordova, changeStatusBarTheme } from '../../src/utils/cordova';
import { THEMES } from '../../src/utils/constants';

export function useStatusBarTheme(
    condition: boolean,
    trueTheme: THEMES,
    falseTheme: THEMES
) {
    useEffect(() => {
        if (isCordova) {
            changeStatusBarTheme(condition ? trueTheme : falseTheme);
        }
    }, [condition, falseTheme, trueTheme]);
}
