import { emptySplitApi } from '../../rtk/empty-split-api';
import { routes } from '../../rtk/routes';
import { TGetContactUsMailResponse, TGetContactUsPhoneResponse } from './types';

export const contactUsApi = emptySplitApi.injectEndpoints({
    endpoints: (builder) => ({
        getContactUsPhone: builder.query<TGetContactUsPhoneResponse, void>({
            query: routes.api.contactUs.getContactUsPhone,
            transformResponse: (response: TGetContactUsPhoneResponse) => {
                return response.sort((a, b) => {
                    if (a.location < b.location) {
                        return -1;
                    }

                    if (a.location > b.location) {
                        return 1;
                    }

                    return 0;
                });
            },
        }),
        getContactUsMail: builder.query<string | null, void>({
            query: routes.api.contactUs.getContactUsMail,
            transformResponse: (response: TGetContactUsMailResponse) => {
                return (
                    response.find((item) => item.system === 'LanguageAcademy')
                        ?.email ?? null
                );
            },
        }),
    }),
});

export const { useGetContactUsPhoneQuery, useGetContactUsMailQuery } =
    contactUsApi;
