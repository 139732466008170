import moment from 'moment';
import { ContentActivityTrackingSettings } from './content-activity-tracking/content-activity-tracking-settings';
import { IContentActivityTrackingApi } from './content-activity-tracking-api';
import { ContentActivityEvent } from './content-activity-tracking/content-activity-event';
import { ContentActivityEventType } from './content-activity-tracking/content-activity-event-type';
import { SSOUtils } from '../utils/SSO';

export interface IContentActivityTrackingService {
    /**
     * Track user activity in resources
     * @param contentId
     * @param userId
     * @param type
     * @param quizId
     * @param score
     * @param articleId
     * @param curriculumId
     */
    trackActivity(
        topicId: string,
        contentId: string,
        userId: string,
        type: ContentActivityEventType,
        quizId?: string,
        score?: number,
        articleId?: string | null,
        curriculumId?: string
    ): void;
}

interface ProvisioningAttributes {
    SessionToken: string;
    ReportTarget: string;
    ReportCourseId?: string;
    ReportCourse?: string;
    UserGUID?: string;
    ClientId: string;
}

export const reportsAttributesForProvision = () => {
    const integrationReport = localStorage.getItem('IntegrationReport');
    const userGuid = localStorage.getItem('userGuid');
    const sessionId = localStorage.getItem('aiccSID') || '';
    const clientId = localStorage.getItem('client') || '';

    let payloads: ProvisioningAttributes = {
        SessionToken: sessionId,
        ReportTarget: 'SSO_VALIDATOR',
        UserGUID: userGuid ? userGuid : undefined,
        ClientId: clientId,
    };

    if (integrationReport) {
        const provisionReport = JSON.parse(integrationReport);
        const ReportCourseId = provisionReport?.ReportCourseId;
        const ReportCourse = provisionReport?.ReportCourse;

        if (ReportCourseId) {
            payloads = {
                ...payloads,
                ReportCourseId,
            };
        }

        if (ReportCourse) {
            payloads = {
                ...payloads,
                ReportCourse,
            };
        }
    }

    return payloads;
};

/**
 *Service that tracks user activity in resources
 */
export class ContentActivityTrackingService
    implements IContentActivityTrackingService
{
    private settings: ContentActivityTrackingSettings;
    private inMemoryQueue: ContentActivityEvent[];
    private readonly queueGapMs = 500;

    constructor(
        private readonly contentActivityTrackingApi: IContentActivityTrackingApi,
        private readonly tabId: string
    ) {
        this.settings = ContentActivityTrackingSettings.getDefault();
        this.inMemoryQueue = [];
        setInterval(
            () => this.sendFromQueue(this.inMemoryQueue),
            this.queueGapMs
        );
        this.contentActivityTrackingApi.getSettings().subscribe((x) => {
            this.settings = x;
        });
    }
    private sendFromQueue(queue: ContentActivityEvent[]) {
        if (queue.length > 0) {
            let event = queue.shift()!;
            event.timestamp = moment().toISOString();
            this.contentActivityTrackingApi.sendEvent(event).subscribe(
                () => {
                    // console.log('success event');
                },
                (error: any) => {
                    if (error.status === 410) {
                        //resend open if backend lost content state
                        if (event.topicId) {
                            const newEvent = new ContentActivityEvent(
                                event.topicId,
                                event.contentId,
                                event.deviceId,
                                event.userId,
                                event.tabId,
                                moment().add(100, 'ms').toISOString(),
                                ContentActivityEventType.Open,
                                event.quizId,
                                event.score,
                                event.attributes,
                                event.curriculumId
                            );
                            this.contentActivityTrackingApi
                                .sendEvent(newEvent)
                                .toPromise();
                        }
                    }
                    console.error('Content activity tracking error', error);
                }
            );
        }
    }

    public static getDeviceId(): string {
        return localStorage.getItem('deviceId') || '';
    }

    /**
     * Track user activity in resources
     * @param contentId
     * @param userId
     * @param type
     * @param quizId
     * @param score
     * @param articleId
     */
    public trackActivity(
        topicId: string,
        contentId: string,
        userId: string,
        type: ContentActivityEventType,
        quizId?: string,
        score?: number,
        articleId?: string | null,
        curriculumId?: string
    ): void {
        const userGuid = localStorage.getItem('userGuid');
        const sessionToken = localStorage.getItem('sessionToken');
        const subDomain = localStorage.getItem('subdomain');
        const callbackUrl = localStorage.getItem('callbackUrl');
        const registrationNumber = localStorage.getItem('registrationNumber');
        const fqdn = localStorage.getItem('fqdn');
        const ocnCourseId = localStorage.getItem('ocnCourseId');
        const clientId = localStorage.getItem('client') || '';

        let trackingPortalId: string | undefined = '';
        if (SSOUtils.isSABATriggerPortal()) {
            trackingPortalId = articleId ? articleId : undefined;
        } else if (SSOUtils.isCSODTriggerPortal()) {
            trackingPortalId = '7191c86b-9b90-4f8c-9127-8eca06a50a84';
        } else {
            trackingPortalId = '7449c9c9-6523-4e16-9767-00a775969c12';
        }

        let courseId: string | undefined = articleId ? articleId : undefined;
        if (SSOUtils.isOCNTriggerHook()) {
            courseId = ocnCourseId ? ocnCourseId : undefined;
        }

        let attributes: any = {
            UserGUID: userGuid ? userGuid : undefined,
            CourseId: courseId,
            SessionToken: sessionToken ? sessionToken : undefined,
            CallBackUrl: callbackUrl ? callbackUrl : undefined,
            RegistrationNumber: registrationNumber
                ? registrationNumber
                : undefined,
            SubDomain: subDomain ? subDomain : undefined,
            FQDN: fqdn && !subDomain ? fqdn : undefined,
            PortalId: trackingPortalId,
        };

        if (SSOUtils.isOCNTriggerHook()) {
            attributes = {
                ...attributes,
                ReportTarget: 'OCN',
                ClientId: clientId,
            };
        } else if (SSOUtils.isUseProvisioning()) {
            const payload = reportsAttributesForProvision();

            attributes = {
                ...attributes,
                ...payload,
            };
        }

        let event = new ContentActivityEvent(
            topicId,
            contentId,
            ContentActivityTrackingService.getDeviceId(),
            userId,
            this.tabId,
            type === ContentActivityEventType.Open
                ? moment().add(100, 'ms').toISOString()
                : moment().toISOString(),
            type,
            quizId,
            score,
            attributes,
            curriculumId
        );
        this.inMemoryQueue.push(event);
    }
}
