import moment from 'moment';

export enum TimeLimitRule {
    Day = 'day',
    Week = 'week',
    Month = 'month',
    Year = 'year',
    None = 'none',
}
export interface TimeLimitRange {
    dateStart: Date;
    dateEnd: Date;
}

export function getTimeLimitRangeByRule(
    limitTimeRule: TimeLimitRule | undefined
): TimeLimitRange {
    const formatLimitTimeRule = limitTimeRule?.toLowerCase();
    switch (formatLimitTimeRule) {
        case TimeLimitRule.Day:
            return {
                dateStart: moment().clone().startOf('day').toDate(),
                dateEnd: moment().clone().endOf('day').toDate(),
            };
        case TimeLimitRule.Week:
            return {
                dateStart: moment().clone().startOf('week').toDate(),
                dateEnd: moment().clone().endOf('week').toDate(),
            };
        case TimeLimitRule.Month:
            return {
                dateStart: moment().clone().startOf('month').toDate(),
                dateEnd: moment().clone().endOf('month').toDate(),
            };
        case TimeLimitRule.Year:
            return {
                dateStart: moment().clone().startOf('year').toDate(),
                dateEnd: moment().clone().endOf('year').toDate(),
            };
        default:
            return {
                dateStart: moment().clone().startOf('date').toDate(),
                dateEnd: moment().clone().endOf('date').toDate(),
            };
    }
}
