import { of } from 'rxjs';
import {
    catchError,
    concatMap,
    filter,
    switchMap,
    withLatestFrom,
} from 'rxjs/operators';
import { isActionOf } from 'typesafe-actions';
import { RootEpic } from '../root-epic';
import { Action } from '../../store/root-action';
import { getStatusLoadingActions, modifyResourceStatus } from './helpers';
import { ResourceContentRecord } from '../../store/resources/resources-content-record';

export const loadResourceContent: RootEpic = (
    action$,
    state$,
    { contentsApi }
) =>
    action$.pipe(
        filter(
            isActionOf([
                Action.resources.loadSelectedResource.success,
                Action.resources.loadSelectedUsingUUIDResource.success,
            ])
        ),
        withLatestFrom(state$),
        switchMap(([x, state]) => {
            const { contentUUId } = x.payload;

            return contentsApi.loadContent(contentUUId).pipe(
                concatMap((loadedResource) => {
                    const inProgressResources = state.resources.inProgress.list;
                    const completedResources = state.resources.completed.list;
                    const actualizedResource =
                        modifyResourceStatus<ResourceContentRecord>(
                            inProgressResources,
                            completedResources
                        )(loadedResource);

                    return of(
                        ...getStatusLoadingActions(
                            inProgressResources,
                            completedResources
                        ),
                        Action.resources.loadSelectedResourceContent.success(
                            actualizedResource as ResourceContentRecord
                        )
                    );
                }),
                catchError((e) => {
                    return of(
                        Action.resources.loadSelectedResourceContent.failure(e)
                    );
                })
            );
        })
    );

export const resourceContentEpics = [loadResourceContent];
