import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Axios } from 'axios-observable';
import { ApiHeaders } from './api-headers';
import { activityReport } from './api-urls';
import { InProgressResource } from '../store/resources/in-progress/in-progress-record';
import { CompletedResource } from '../store/resources/completed/completed-record';
import { CourseStatusDetails } from '../store/resources/training-path/training-path-record';

export class ActivityReportApi {
    apiHeaders: ApiHeaders;

    constructor(apiHeaders: ApiHeaders) {
        this.apiHeaders = apiHeaders;
    }

    loadInProgressList(
        userUUID: string,
        topicUUID: null | string | undefined
    ): Observable<InProgressResource[]> {
        let url = activityReport() + `in-progress/${userUUID}`;

        if (topicUUID) {
            url += `?topicUUID=${topicUUID}`;
        }

        return Axios.get<InProgressResource[]>(url, {
            headers: this.apiHeaders.getHeaders(),
        }).pipe(map((x) => x.data));
    }

    loadCompletedList(
        userUUID: string,
        topicUUID: null | string | undefined
    ): Observable<CompletedResource[]> {
        let url = activityReport() + `content/${userUUID}`;

        if (topicUUID) {
            url += `?topicUUID=${topicUUID}`;
        }

        return Axios.get<CompletedResource[]>(url, {
            headers: this.apiHeaders.getHeaders(),
        }).pipe(map((x) => x.data));
    }

    loadCoursesStatuses(userUUID: string): Observable<CourseStatusDetails[]> {
        let url = activityReport() + `curriculum/${userUUID}`;

        return Axios.get<CourseStatusDetails[]>(url, {
            headers: this.apiHeaders.getHeaders(),
        }).pipe(map((x) => x.data));
    }
}
