import {
    ActionType,
    createAction,
    createAsyncAction,
    createReducer,
    RootState,
} from 'typesafe-actions';
import { createSelector } from 'reselect';
import { List } from 'immutable';
import { combineReducers } from 'redux';
import { ResourceContent } from './resource-content';
import { LoadingState } from './resources';
import { modifyResourceStatus } from '../../epics/resources/helpers';
import { loadInProgressActivities } from './in-progress/in-progress-actions';
import { loadCompletedActivities } from './completed/completed-actions';

export type SearchState = Readonly<{
    keywords: string;
    searchResults: List<ResourceContent>;
    loadingState: LoadingState;
}>;

export const initialSearchState: SearchState = {
    keywords: '',
    searchResults: List<ResourceContent>(),
    loadingState: 'idle',
};

export const setSearchKeyword = createAction('@@resources/search/bykeyword')<{
    keyword: string;
}>();

export const searchResource = createAsyncAction(
    '@@resources/search/request',
    '@@resources/search/success',
    '@@resources/search/failure'
)<{ keyword: string; moduleType: string }, ResourceContent[], Error>();

export const searchActions = {
    setSearchKeyword,
    searchResource,
};

export type SearchAction = ActionType<typeof searchActions>;

const keywords = createReducer('').handleAction(
    setSearchKeyword,
    (s, a) => a.payload.keyword
);

const searchResults = createReducer(List<ResourceContent>())
    .handleAction(setSearchKeyword, (state) => state.clear())
    .handleAction(searchResource.success, (state, action) =>
        state.merge(List(action.payload))
    )
    .handleAction(loadInProgressActivities.success, (state, action) => {
        const statuses = action.payload;
        return state.map(modifyResourceStatus<ResourceContent>(statuses, null));
    })
    .handleAction(loadCompletedActivities.success, (state, action) => {
        const statuses = action.payload;
        return state.map(modifyResourceStatus<ResourceContent>(null, statuses));
    });

const loadingState = createReducer('idle' as LoadingState)
    .handleAction(searchResource.request, () => 'pending' as LoadingState)
    .handleAction(searchResource.success, () => 'succeeded' as LoadingState)
    .handleAction(searchResource.failure, () => 'failed' as LoadingState);

export const searchReducer = combineReducers<SearchState, SearchAction>({
    keywords,
    searchResults,
    loadingState,
});

export const searchSelector = (state: RootState) => state.resources.search;

export const searchResultsSelector = createSelector(
    searchSelector,
    (search) => search.searchResults
);
export const searchLoadingState = createSelector(
    searchSelector,
    (search) => search.loadingState
);
