import { isCordova } from '../utils/cordova';

export const environment: {
    API_HOST: string;
    CLASSROOM_HOST: string;
    PROGRESSAPI_HOST: string;
    DEPLOY_PATH: string;
    PRIVACY_POLICY_LANGUAGE: string;
    FIREBASE_WEB_KEY: string;
} = {
    API_HOST: process.env.REACT_APP_API_HOST || '',
    CLASSROOM_HOST: process.env.REACT_APP_CLASSROOM_HOST || '',
    PROGRESSAPI_HOST: process.env.REACT_APP_PROGRESSAPI_HOST || '',
    DEPLOY_PATH: process.env.REACT_APP_DEPLOY_PATH || '',
    PRIVACY_POLICY_LANGUAGE: process.env.REACT_APP_PRIVACY_POLICY || 'en',
    FIREBASE_WEB_KEY: process.env.FIREBASE_WEB_KEY || '',
};

export const host = () => {
    const shouldUseHost = isCordova;
    return shouldUseHost ? environment.API_HOST : '';
};
