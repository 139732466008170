import { createReducer } from 'typesafe-actions';
import { Map, List, Record } from 'immutable';
import { combineReducers } from 'redux';
import { Action } from '../root-action';

import {
    Availability,
    Teacher,
    Cancellation,
    Foresen,
    MediaRecord,
    SchedulerLimitation,
    SchedulerSelectedTeachersProps,
    SchedulerLanguageProps,
    SchedulerLearnerProps,
} from './scheduler-data';
import moment from 'moment';

const learnerReducer = createReducer<SchedulerLearnerProps | null>(null)
    .handleAction(
        Action.scheduler.initializeScheduler.success,
        (state, { payload }) => {
            return payload.learner;
        }
    )
    .handleAction(Action.user.logout.success, (state, _) => null);

const lessonsReducer = createReducer(List<Availability>())
    .handleAction(
        Action.scheduler.initializeScheduler.success,
        (state, { payload }) => {
            return payload.lessons;
        }
    )
    .handleAction(Action.user.logout.success, (state, action) => state.clear());

const lessonListReducer = createReducer(List<Availability>())
    .handleAction(
        Action.scheduler.initLessons.success,
        (state, { payload }) => {
            return payload.lessons;
        }
    )
    .handleAction(
        Action.scheduler.initLessonsByMonth.success,
        (state, { payload }) => {
            return payload.lessons;
        }
    )
    .handleAction(Action.scheduler.initLessonsByMonth.failure, (state, _) =>
        List()
    )
    .handleAction(Action.scheduler.initLessons.failure, (state, _) => List())
    .handleAction(Action.user.logout.success, (state, action) => state.clear());

const selectedMonthDate = createReducer(moment.utc().startOf('day'))
    .handleAction(
        Action.scheduler.selectSchedulerDate,
        (_, { payload }) => payload
    )
    .handleAction(Action.scheduler.selectMonthDate, (_, { payload }) => payload)
    .handleAction(Action.user.logout.success, (state) =>
        moment.utc().startOf('day')
    );

const teachersReducer = createReducer(Map<number, Teacher>())
    .handleAction(
        Action.scheduler.initializeScheduler.success,
        (state, { payload: { teachers } }) => {
            return teachers.reduce(
                (m: any, t: any) => m.set(t.teacherId, t),
                Map<number, Teacher>()
            );
        }
    )
    .handleAction(Action.user.logout.success, (state) => state.clear());

const mediaReducer = createReducer(Map<string, MediaRecord>())
    .handleAction(
        Action.scheduler.initializeScheduler.success,
        (state, { payload }) => {
            return payload.medias;
        }
    )
    .handleAction(
        Action.scheduler.addNewMedia.success,
        (state, { payload: { medias } }) => {
            return state.merge(medias);
        }
    )
    .handleAction(Action.user.logout.success, (state, action) => state.clear());

const selectedAvailabilityReducer = createReducer<Availability | null>(null)
    .handleAction(
        Action.scheduler.setSelectedAvailability,
        (_, { payload }) => payload
    )
    .handleAction(Action.scheduler.selectSchedulerDate, (state, action) => null)
    .handleAction(
        Action.scheduler.initializeScheduler.success,
        (state, action) => null
    )
    .handleAction(
        Action.scheduler.changeSelectedTeachers,
        (state, action) => null
    )
    .handleAction(Action.user.logout.success, (state) => null);

/**
 * Scheduler availabilities reducer
 */
const availabilitiesReducer = createReducer(List<Availability>())
    .handleAction(
        Action.scheduler.loadAvailabilities.success,
        (state, { payload: { availabilities } }) => availabilities
    )
    .handleAction(
        Action.scheduler.loadMoreAvailabilities.success,
        (state, { payload: { availabilities } }) => {
            return state.merge(availabilities);
        }
    )
    .handleAction(
        [
            Action.scheduler.loadAvailabilities.failure,
            Action.scheduler.loadMoreAvailabilities.failure,
        ],
        (state) => state.clear()
    )
    .handleAction(Action.user.logout.success, (state, _) => state.clear());

const availabilitiesErrorReducer = createReducer<string | null>(null)
    .handleAction(
        Action.scheduler.loadAvailabilities.failure,
        (state, action) => {
            const payload = action.payload as any;
            // console.log('Availability error', payload);
            const error = (payload?.message as string) || 'server_error';

            return error;
        }
    )
    .handleAction(
        Action.scheduler.loadAvailabilities.success,
        (state, action) => null
    )
    .handleAction(
        Action.scheduler.loadAvailabilities.request,
        (state, action) => null
    )
    .handleAction(Action.user.logout.success, (state, action) => null)
    .handleAction(
        Action.scheduler.setAvailabilityError,
        (state, { payload }) => payload
    );

const weekLessonsReducer = createReducer(List<Availability>())
    .handleAction(
        Action.scheduler.loadInitWeekLessons.success,
        (state, { payload: { lessons } }) => lessons
    )
    .handleAction(
        Action.scheduler.loadInitWeekLessons.failure,
        (state, action) => state.clear()
    )
    .handleAction(Action.user.logout.success, (state, action) => state.clear());

const isUpdatingBookingReducer = createReducer(false)
    .handleAction([Action.scheduler.bookLesson.request], () => true)
    .handleAction(
        [
            Action.scheduler.bookLesson.success,
            Action.scheduler.bookLesson.failure,
        ],
        () => false
    );

const isUpdatingInitializeSchedulerReducer = createReducer(true)
    .handleAction([Action.scheduler.initializeScheduler.request], () => true)
    .handleAction(
        [
            Action.scheduler.initializeScheduler.success,
            Action.scheduler.initializeScheduler.failure,
            Action.scheduler.initializeSchedulerEnded,
        ],
        () => false
    );

const isUpdatingInitLessonsReducer = createReducer(false)
    .handleAction([Action.scheduler.initLessons.request], () => true)
    .handleAction(
        [
            Action.scheduler.initLessons.success,
            Action.scheduler.initLessons.failure,
        ],
        () => {
            return false;
        }
    );

const isLessonsUpdatingReducer = createReducer(true)
    .handleAction([Action.scheduler.loadAvailabilities.request], () => true)
    .handleAction(
        [
            Action.scheduler.loadAvailabilities.success,
            Action.scheduler.loadAvailabilities.failure,
        ],
        () => false
    )
    .handleAction(Action.user.logout.success, (state, action) => true);

const isLessonsUpdatingMoreReducer = createReducer(false)
    .handleAction([Action.scheduler.loadMoreAvailabilities.request], () => true)
    .handleAction(
        [
            Action.scheduler.loadMoreAvailabilities.success,
            Action.scheduler.loadMoreAvailabilities.failure,
        ],
        () => false
    )
    .handleAction(Action.user.logout.success, (state, action) => true);

const selectedDateReducer = createReducer(moment.utc().startOf('day'))
    .handleAction(
        Action.scheduler.selectSchedulerDate,
        (_, { payload }) => payload
    )
    .handleAction(Action.user.logout.success, (state) =>
        moment.utc().startOf('day')
    );

const selectedTeachersPropertiesReducer = createReducer(
    new SchedulerSelectedTeachersProps()
)
    .handleAction(
        Action.scheduler.initializeScheduler.success,
        (
            state,
            {
                payload: {
                    teachers,
                    learner,
                    sat,
                    isAvailabilitiesPreLoaded,
                    currentCredit,
                },
            }
        ) => {
            if (!sat) {
                const mediaType = learner?.LearnerDefaultMedia?.MediaType;

                if (isAvailabilitiesPreLoaded === false) {
                    if (
                        state.selectedTeachers.size === 0 &&
                        state.selectAll === false
                    ) {
                        const hasSameProduct = teachers.filter((t) => {
                            const test = t.authorizedProducts.find(
                                (product: any) => {
                                    return product.name === currentCredit?.name;
                                }
                            );

                            return test && t;
                        });

                        let newTeachers = hasSameProduct
                            .filter((t) =>
                                t.authorizedMedia.some(
                                    (m) => m.mediaType === mediaType
                                )
                            )
                            .slice(0, 1);

                        newTeachers =
                            newTeachers.size === 0
                                ? hasSameProduct.slice(0, 1)
                                : newTeachers;

                        let newSat = newTeachers.size === 0;
                        return new SchedulerSelectedTeachersProps({
                            selectedTeachers: newTeachers,
                            selectAll: newSat,
                        });
                    }
                }
            } else {
                return state.set('selectAll', true);
            }

            return state;
        }
    )
    .handleAction(Action.scheduler.changeSelectedTeachers, (_, { payload }) => {
        return payload;
    })
    .handleAction(
        Action.scheduler.loadAvailabilities.success,
        (state, { payload }) => {
            if (payload.selectAllAvailable) {
                return new SchedulerSelectedTeachersProps({
                    selectAll: true,
                    selectedTeachers: List<Teacher>(),
                });
            }
            return state;
        }
    )
    .handleAction(
        Action.user.logout.success,
        (state, action) => new SchedulerSelectedTeachersProps()
    )
    .handleAction(Action.scheduler.resetSchedulerTeacher, (_, { payload }) => {
        return payload;
    });

const cancellationReducer = createReducer(new Cancellation())
    .handleAction(
        Action.scheduler.cancelLesson.request,
        (state, { payload }) => {
            return new Cancellation({
                lessonId: payload.lessonId,
            });
        }
    )
    .handleAction(
        Action.scheduler.cancelLesson.success,
        (state, { payload }) => {
            return payload.cancellation;
        }
    )
    .handleAction(
        Action.scheduler.abortCancellation,
        (state, action) => new Cancellation()
    )
    .handleAction(
        Action.scheduler.confirmedCancelLesson.success,
        (state, action) => new Cancellation()
    )
    .handleAction(
        Action.scheduler.cancelLesson.failure,
        (state, action) => new Cancellation()
    )
    .handleAction(
        Action.scheduler.confirmedCancelLesson.failure,
        (state, action) => new Cancellation()
    )
    .handleAction(
        Action.user.logout.success,
        (state, action) => new Cancellation()
    );

const foreseenReducer = createReducer<Foresen | null>(null)
    .handleAction(
        Action.scheduler.loadForesen.success,
        (state, action) => action.payload
    )
    .handleAction(Action.scheduler.loadForesen.failure, (state, action) => null)
    .handleAction(Action.scheduler.resetForeseenLesson, (state, action) => {
        if (action.payload) {
            return null;
        } else {
            return state;
        }
    })
    .handleAction(Action.scheduler.loadForesen.failure, () => null)
    .handleAction(Action.user.logout.success, (state, action) => null);

const foreseenGroupReducer = createReducer<Foresen | null>(null)
    .handleAction(
        Action.scheduler.loadGroupForesen.success,
        (state, action) => action.payload
    )
    .handleAction(Action.scheduler.resetForeseenLesson, (state, action) => {
        if (action.payload) {
            return null;
        } else {
            return state;
        }
    })
    .handleAction(Action.scheduler.loadGroupForesen.failure, () => null)
    .handleAction(Action.user.logout.success, (state, action) => null);

const isLoadingForeseenReducer = createReducer(false)
    .handleAction(
        Action.scheduler.loadForesen.request,
        (state, { payload = true }) => Boolean(payload)
    )
    .handleAction(
        [
            Action.scheduler.loadForesen.success,
            Action.scheduler.loadForesen.failure,
        ],
        () => false
    )
    .handleAction(Action.user.logout.success, (state, action) => false);

const popupsOpenReducer = createReducer(
    Record({
        cancel: false,
        calendar: false,
        isAnyOpen: false,
    })()
)
    .handleAction(
        Action.scheduler.togglePopup,
        (state, { payload: { name, value } }) => {
            let newState = state.set(name, value);
            return newState.set(
                'isAnyOpen',
                newState.cancel || newState.calendar
            );
        }
    )
    .handleAction(Action.user.logout.success, (state, action) => state.clear());

const limitationReducer = createReducer(new SchedulerLimitation())
    .handleAction(
        Action.scheduler.setSchedulerLimitation,
        (state, { payload }) => payload
    )
    .handleAction(Action.user.logout.success, (state, action) => state.clear());

const languageReducer = createReducer(
    new SchedulerLanguageProps()
).handleAction(
    Action.scheduler.initLanguage.success,
    (state, { payload }) => payload
);

const isLoadingNextMonth = createReducer(false)
    .handleAction(
        Action.scheduler.selectMonthDate,
        (state, { payload }) => true
    )
    .handleAction(Action.scheduler.initLessons.success, () => {
        return false;
    })
    .handleAction(Action.scheduler.initLessons.failure, () => {
        return false;
    })
    .handleAction(Action.scheduler.setAvailabilityError, () => {
        return false;
    });

const isAvailabilitiesPreLoaded = createReducer(false)
    .handleAction(Action.scheduler.opened, (state, action) => false)
    .handleAction(
        Action.scheduler.loadAvailabilities.request,
        (state, action) => true
    );

const foreseenVCRReducer = createReducer<any>(null)
    .handleAction(
        Action.scheduler.loadForeseenVCR.success,
        (state, action) => action.payload
    )
    .handleAction(Action.scheduler.resetForeseenLesson, (state, action) => {
        if (action.payload) {
            return null;
        } else {
            return state;
        }
    })
    .handleAction(Action.scheduler.loadForeseenVCR.failure, () => null)
    .handleAction(Action.user.logout.success, (state, action) => null);

const resetForeseenLessonReducer = createReducer<boolean>(false).handleAction(
    Action.scheduler.resetForeseenLesson,
    (state, action) => action.payload
);

const isVCRStatusReducer = createReducer<string>('').handleAction(
    Action.scheduler.setIsVCRStatus,
    (state, action) => action.payload
);

const isDisabledVCRReducer = createReducer<boolean>(false).handleAction(
    Action.scheduler.setIsDisabledVCR,
    (state, action) => action.payload
);

const locationSchedulerFrom = createReducer<string>('scheduler').handleAction(
    Action.scheduler.setOriginLocation,
    (state, action) => action.payload
);

export const schedulerReducer = () => {
    return combineReducers({
        learner: learnerReducer,
        lessons: lessonsReducer,
        teachers: teachersReducer,
        media: mediaReducer,
        availabilities: availabilitiesReducer,
        availabilitiesError: availabilitiesErrorReducer,
        isLessonsUpdating: isLessonsUpdatingReducer,
        selectedDate: selectedDateReducer,
        cancellation: cancellationReducer,
        lessonList: lessonListReducer,
        foreseen: foreseenReducer,
        foreseenGroup: foreseenGroupReducer,
        foreseenVCR: foreseenVCRReducer,
        foreseenReset: resetForeseenLessonReducer,
        foreseenIsDisabledVCR: isDisabledVCRReducer,
        foreseenIsVCRStatus: isVCRStatusReducer,
        isLoadingForeseen: isLoadingForeseenReducer,
        selectedAvailability: selectedAvailabilityReducer,
        popupsOpen: popupsOpenReducer,
        selectedTeachersProperties: selectedTeachersPropertiesReducer,
        limitation: limitationReducer,
        weekLessons: weekLessonsReducer,
        language: languageReducer,
        isUpdatingInitLessons: isUpdatingInitLessonsReducer,
        isUpdatingInitializeScheduler: isUpdatingInitializeSchedulerReducer,
        isUpdatingBooking: isUpdatingBookingReducer,
        isLessonsUpdatingMore: isLessonsUpdatingMoreReducer,
        isLoadingNextMonth: isLoadingNextMonth,
        selectedMonthDate: selectedMonthDate,
        isAvailabilitiesPreLoaded: isAvailabilitiesPreLoaded,
        originLocation: locationSchedulerFrom,
    });
};
