import { combineReducers } from 'redux';
import { createReducer } from 'typesafe-actions';
import { IChannelsList } from '../../../services/resources/channel-api';
import { Action } from '../../root-action';
import { clearAllResources } from '../resources-actions';

interface IinitialState {
    list: IChannelsList[] | null;
    isLoading: boolean;
}
export const initialState: IinitialState = {
    list: null,
    isLoading: false,
};

export const list = createReducer(initialState.list)
    .handleAction(
        Action.channel.loadChannelList.success,
        (___, action) => action.payload
    )
    .handleAction(
        [Action.quizzes.loadQuiz.request, clearAllResources],
        () => initialState.list
    );

export const channelReducer = () =>
    combineReducers({
        list,
    });
