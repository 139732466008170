import { RootEpic } from './root-epic';
import { catchError, filter, map, switchMap } from 'rxjs/operators';
import { isActionOf } from 'typesafe-actions';
import { Action } from '../store/root-action';
import { of } from 'rxjs';

const loadCompletedActivitiesEpic: RootEpic = (
    action$,
    state$,
    { insightsApi }
) =>
    action$.pipe(
        filter(isActionOf(Action.insights.loadCompletedActivities.request)),
        switchMap((x) => {
            return insightsApi
                .loadCompletedActivity(
                    state$.value.user.identity?.learnerUUID as string,
                    x.payload.topicUUID,
                    x.payload.granularity
                )
                .pipe(
                    map((x) =>
                        Action.insights.loadCompletedActivities.success(x)
                    ),
                    catchError((e) =>
                        of(Action.insights.loadCompletedActivities.failure(e))
                    )
                );
        })
    );

export const insightsEpics = [loadCompletedActivitiesEpic];
