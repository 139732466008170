import { TrackingApi } from '../features/tracking/tracking-api';
import { TrackingService } from '../features/tracking/tracking-service';
import { ILanguageApi, LanguageApiMoq } from '../localization/language-api';
import {
    ILocalizationApi,
    LocalizationApi,
} from '../localization/localization-api';
import { LocalizationService } from '../localization/localization-service';
import { AdaptiveTestApi } from '../services/adaptive-test-api';
import { ApiHeaders } from '../services/api-headers';
import { AuthenticationApi } from '../services/authentication-api';
import { ContentActivityTrackingApi } from '../services/content-activity-tracking-api';
import { ContentActivityTrackingService } from '../services/content-activity-tracking-service';
import { DisplayStrategyApi } from '../services/display-strategy-api';
import { HomeworkApi } from '../services/homework-api';
import { LearnerProfileApi } from '../services/learner-profile-api';
import { ModuleRestrictionApi } from '../services/module-restriction';
import { LearnerOnboardingApi } from '../services/onboarding';
import { ContentApi } from '../services/resources/content-api';
import { HowtoApi } from '../services/resources/howto-api';
import { LegacyResourceContentApi } from '../services/resources/legacy-resource-content-api';
import { ResourceContentApi } from '../services/resources/resource-content-api';
import { TrainingPathApi } from '../services/training-path-api';

import { PortalTrackingApi } from '../services/portal-tracking-api';
import { PortalTrackingService } from '../services/portal-tracking-service';

import { IntegrationRequireApi } from '../services/integration-api';
import { IntegrationRequireService } from '../services/integration-service';

import { createEpicMiddleware } from 'redux-observable';
import { RootAction, RootState } from 'typesafe-actions';
import { v4 as uuidv4 } from 'uuid';
import { configuration } from '../configuration';
import { Services } from '../epics/services';
import { LearnerChatApi } from '../features/contact-us/learner-chat-api';
import { SpeechRecognitionApi } from '../features/speech-recognition/speech-recognition-api';
import { ActivityFiltersApi } from '../services/activity-filters-api';
import { ActivityLessonApi } from '../services/activity-lesson-api';
import { ActivityReportApi } from '../services/activity-report-api';
import { ContractApi } from '../services/contract-api';
import { CreditsApi } from '../services/credits-api';
import { EWritingApi } from '../services/e-writing-api';
import { GradingApi } from '../services/grading-api';
import { InProgressApi } from '../services/in-progress';
import { IndexDBService } from '../services/indexdb-service';
import { InsightsApi } from '../services/insights-api';
import { LearnerCertificateApi } from '../services/learner-certificate-api';
import { LearnerPreferencesApi } from '../services/learner-preferrence';
import { LessonReportsApi } from '../services/lesson-reports-api';
import { LessonReportsService } from '../services/lesson-reports-service';
import { OfflineApi } from '../services/offline';
import { PreferredLanguageApi } from '../services/preferred-language-api';
import { QuizzesApi } from '../services/quizzes-api';
import { ChannelApi } from '../services/resources/channel-api';
import { RecommendedApi } from '../services/resources/recommended-api';
import { SearchApi } from '../services/resources/search-api';
import { TranslateApi } from '../services/resources/translate-api';
import { SaveContentApi } from '../services/save-content';
import { SchedulerApi } from '../services/scheduler-api';
import { TrainingApi } from '../services/training-api';
import { VcrApi } from '../services/vcr-api';
import { VcrFetchApi } from '../services/vcr-fetch-api';

const apiHeaders = new ApiHeaders();
const howtosApi = new HowtoApi(configuration, apiHeaders);
const channelApi = new ChannelApi(apiHeaders);
const saveContentApi = new SaveContentApi(apiHeaders);
const inProgressApi = new InProgressApi(apiHeaders);
const contentsApi = new ContentApi(apiHeaders);
const resourceContentApi = new ResourceContentApi(apiHeaders);
const legacyResourceContentApi = new LegacyResourceContentApi(apiHeaders);
const trainingPathApi = new TrainingPathApi(apiHeaders);
const learnerProfileApi = new LearnerProfileApi(apiHeaders);
const learnerOnboardingApi = new LearnerOnboardingApi(apiHeaders);
const moduleRestrictionApi = new ModuleRestrictionApi(apiHeaders);
const homeworkApi = new HomeworkApi(apiHeaders);
const preferredLanguageApi = new PreferredLanguageApi(apiHeaders);
const localizationApi = new LocalizationApi(apiHeaders) as ILocalizationApi;
const languageApi = new LanguageApiMoq() as ILanguageApi;
const localizationService = new LocalizationService(
    localizationApi,
    languageApi
);
const adaptiveTestApi = new AdaptiveTestApi(apiHeaders);
const displayStrategyApi = new DisplayStrategyApi(apiHeaders);
const trackingApi = new TrackingApi(apiHeaders);
const trackingService = new TrackingService(trackingApi);

const learnerPreferencesApi = new LearnerPreferencesApi(apiHeaders);
const authentication = new AuthenticationApi(
    apiHeaders,
    localizationService,
    languageApi
);
const trainingApi = new TrainingApi(apiHeaders);
const contentActivityTrackingApi = new ContentActivityTrackingApi();
const contentActivityTrackingService = new ContentActivityTrackingService(
    contentActivityTrackingApi,
    uuidv4()
);

const portalTrackingApi = new PortalTrackingApi();
const portalTrackingService = new PortalTrackingService(
    portalTrackingApi,
    uuidv4()
);

const aiccTrackingApi = new IntegrationRequireApi();
const integrationRequireService = new IntegrationRequireService(
    aiccTrackingApi
);

const searchApi = new SearchApi(apiHeaders, configuration);
const lessonReportsApi = new LessonReportsApi(apiHeaders);
const creditsApi = new CreditsApi(apiHeaders);
const contentService = new LessonReportsService();
const recommendedApi = new RecommendedApi(apiHeaders);
const insightsApi = new InsightsApi(apiHeaders);

const eWritingApi = new EWritingApi(apiHeaders);
const contractApi = new ContractApi(apiHeaders);
const translateApi = new TranslateApi(apiHeaders);
const activityLessonApi = new ActivityLessonApi(apiHeaders);
const gradingApi = new GradingApi(apiHeaders);
const activityReportApi = new ActivityReportApi(apiHeaders);

const activityFiltersApi = new ActivityFiltersApi(apiHeaders);
const learnerChatApi = new LearnerChatApi(apiHeaders);
const speechToTextApi = new SpeechRecognitionApi(apiHeaders);
const learnerCertificateApi = new LearnerCertificateApi(apiHeaders);
const quizzesApi = new QuizzesApi(apiHeaders);

const indexDBService = new IndexDBService();

const offlineApi = new OfflineApi(
    apiHeaders,
    howtosApi,
    contentsApi,
    quizzesApi,
    uuidv4(),
    indexDBService,
    homeworkApi
);

export const epicMiddleware = createEpicMiddleware<
    RootAction,
    RootAction,
    RootState,
    Services
>({
    dependencies: {
        trackingService: trackingService,
        authentication: authentication,
        localizationApi: localizationApi,
        languageApi: languageApi,
        localizationService: localizationService,
        preferredLanguageApi,
        howtosApi,
        channelApi,
        contentsApi,
        searchApi,
        recommendedApi,
        resourceContentApi,
        legacyResourceContentApi,
        quizzesApi: quizzesApi,
        vcrApi: new VcrApi(apiHeaders),
        vcrFetchAPI: new VcrFetchApi(apiHeaders),
        schedulerApi: new SchedulerApi(apiHeaders),
        apiHeaders,
        trainingPathApi,
        learnerProfileApi,
        learnerOnboardingApi,
        moduleRestrictionApi,
        saveContentApi,
        inProgressApi,
        adaptiveTestApi,
        displayStrategyApi,
        contentActivityTrackingApi,
        contentActivityTrackingService,
        lessonReportsApi: lessonReportsApi,
        homeworkApi,
        creditsApi,
        trainingApi,
        lessonReportsService: contentService,
        eWritingApi,
        contractApi,
        insightsApi,
        portalTrackingService,
        translateApi,
        activityLessonApi,
        gradingApi,
        activityReportApi,
        activityFiltersApi,
        learnerChatApi,
        integrationRequireService,
        speechToTextApi,
        learnerCertificateApi,
        offlineApi,
        indexDBService: indexDBService,
        learnerPreferencesApi,
    },
});
