import { Record } from 'immutable';

export class UserCompletedHowToBlock extends Record({
    id: '',
    sections: Array<UserCompletedHowToSection>(),
}) {}

export class UserCompletedHowToSection extends Record({
    instanceId: '',
    dateCompleted: '',
}) {}
