import React, { useEffect, useMemo, useCallback } from 'react';
import { useSelector } from 'react-redux';
import {
    Redirect,
    Route,
    RouteProps,
    useHistory,
    useLocation,
} from 'react-router-dom';
import { isLocalhost } from '../../main';
import { apiv100 } from '../../services/api-urls';
import Action from '../../store/root-action';
import {
    isAuthenticated,
    isLogoutProcessSelector,
    redirectPageSelector,
} from '../../store/user/user-selectors';
import { SSOUtils, redirectLink } from '../../utils/SSO';
import { isCordova } from '../../utils/cordova';
import {
    useFirstDomainSegment,
    useFirstDomainSegmentWithoutDash,
    useMoreThanTwoPartsHostname,
} from '../login/lib/use-get-domains';
import { GetSSOEndpoint } from '../login/lib/use-get-sso-endpoint';
import { useAppDispatch } from './app-dispatch';
import { urlDeeplinkRedirectIdp } from '../login/lib/helpers';
import { IUseSSOToRetrieveIdp } from '../login/lib/use-retrieve-idp';

export const convertToJSON = (value: string) => {
    // Step 1: Parse the string into an object
    const keyValuePairs = value
        .replace(/^"|"$/g, '') // Remove leading and trailing quotes
        .split('\\n') // Split by newline
        .map((pair) => pair.split('=').map((entry) => entry.trim())); // Split each line by '=' and trim whitespaces

    // Step 2: Manipulate the object as necessary
    const jsonObject: any = {};
    keyValuePairs.forEach((pair) => {
        const [key, value] = pair;
        if (key && value) {
            jsonObject[key] = value;
        }
    });

    return jsonObject;
};

export interface GetConfigSSO {
    key: {
        clientId: string;
        platform: string;
        clientDomainUrl: string;
    };
    metadata: string;
    mapping: string;
    idpUrl: string;
    logoutRequest: {
        id: string;
        issueInstant: string;
        validationException: any;
        encodedLogoutRequest: string;
        logoutRequestXml: string;
        valid: boolean;
        error: string;
    };
    idpLogoutUrl: string;
    idpLogoutBinding: string;
}

export const PrivateRoute: React.FC<RouteProps> = ({ children, ...rest }) => {
    const isUserAuthenticated = useSelector(isAuthenticated);
    const userToken = localStorage.getItem('Authorization');
    const redirectPage = useSelector(redirectPageSelector);
    const isLogout = useSelector(isLogoutProcessSelector);
    const clientId =
        localStorage.getItem('client') ||
        localStorage.getItem('client_id') ||
        '';

    const dispatch = useAppDispatch();
    const getLocation = useLocation();

    /**TODO Process to auto redirect using deeplink client**/
    const domain = useFirstDomainSegmentWithoutDash();
    const notModifiedDomain = useFirstDomainSegment();

    const clientDomain = useMoreThanTwoPartsHostname();
    const history = useHistory();

    const changeRedirect: string = useMemo(() => {
        localStorage.setItem(
            'attrs',
            btoa(window.location.pathname + window.location.search)
        );
        return redirectPage === '/' ? `/login` : redirectPage;
    }, [redirectPage]);

    const AuthorizeUser = useCallback(() => {
        localStorage.removeItem('persist:root');
        SSOUtils.getSSOParamsFromURL();
        dispatch(
            Action.user.loginSSO.request({
                token: userToken || '',
            })
        );
    }, [dispatch, userToken]);

    const UseRedirectOldOrShortLink = useCallback(
        (clientOrPid: string) => {
            if (clientId) {
                redirectLink(clientOrPid);
            } else {
                window.location.assign(window.location.origin);
            }
        },
        [clientId]
    );

    const UseSSONewSystem = useCallback(async () => {
        try {
            const clientUrl = `${notModifiedDomain}${clientDomain}`;
            const urlValidateIsMigrated = `${apiv100()}sso-validator/saml/platform/language-academy/${clientUrl}`;
            const responseIsMigrated = await fetch(urlValidateIsMigrated);
            if (responseIsMigrated.ok) {
                const data: GetConfigSSO = await responseIsMigrated.json();
                const { key, mapping } = data;
                const { clientId: clientFromConfig } = key;
                const valueMapping = convertToJSON(mapping);
                const getIsMigrated = valueMapping['sso.ismigrated'] || 'true';
                if (getIsMigrated === 'true') {
                    const apiEndpoint = GetSSOEndpoint({
                        clientId: clientFromConfig || '',
                        clientUrl,
                    });
                    // deeplink new system
                    const responseRetrieveIdp = await fetch(apiEndpoint);
                    if (responseRetrieveIdp.ok) {
                        const idpInfo: IUseSSOToRetrieveIdp =
                            await responseRetrieveIdp.json();
                        const urlRedirect = urlDeeplinkRedirectIdp(idpInfo);
                        const pathUrl = `${getLocation.pathname}${getLocation.search}`;
                        window.location.href = `${urlRedirect}&RelayState=${encodeURIComponent(
                            pathUrl
                        )}`;
                    } else {
                        const errorLink = '/sso-error-login';
                        history.push(errorLink);
                    }
                } else {
                    UseRedirectOldOrShortLink(clientFromConfig);
                }
            } else {
                const hostname = window.location.hostname;
                // Check if the hostname is an IP address
                const isIPAddress = /^\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}$/.test(
                    hostname
                );
                if (!isIPAddress) {
                    UseRedirectOldOrShortLink(clientId);
                }
            }
        } catch (error) {
            // Handle network errors or parsing errors
            console.error('Handle network errors or parsing errors');
        }
    }, [
        clientDomain,
        notModifiedDomain,
        history,
        getLocation,
        UseRedirectOldOrShortLink,
        clientId,
    ]);

    useEffect(() => {
        if (!isLogout && !isCordova && !isLocalhost) {
            if (userToken) {
                AuthorizeUser();
            } else if (!isUserAuthenticated) {
                if (domain === 'portal') {
                    if (clientId) {
                        redirectLink(clientId);
                    }
                } else {
                    UseSSONewSystem();
                }
            }
        }
        // eslint-disable-next-line
    }, [isLogout, isUserAuthenticated]);

    return (
        <Route
            {...rest}
            render={({ location }) =>
                isUserAuthenticated ? (
                    children
                ) : (
                    <Redirect
                        to={{
                            pathname: changeRedirect,
                            state: { from: isLogout ? redirectPage : location },
                        }}
                    />
                )
            }
        />
    );
};
