import moment from 'moment';
import { ResourceContent } from '../../store/resources/resource-content';
import { parseDtoToResourceType, ResourceTypeDto } from './resource-type-dto';
import { ContentMetadataProps } from '../../store/resources/resources-content-record';

interface DynamicContent {
    'dynamic-content': string;
}

export interface GetIdsResponse {
    uuid: string;
    articleId: number;
    groupId: number;
    version: number;
    tagAssetId: number;
    smallImageId: number;
}

export interface ResourceListDTO {
    contentMetadata: ContentMetadataProps | null;
    articleId: number | string;
    groupId: number;
    medium_image?: string;
    categories: string[];
    type: ResourceTypeDto;
    title: string;
    id: number | string;
    contentUUID?: string;
    name: string;
    tags: string[] | null;
    content?: {
        medium_image: string | DynamicContent;
    };
    thumbnail?: string;
    date: string;
    label: string;
    legacy?: any;
    assigner?: {
        firstname: string;
        lastname: string;
        middlename: string;
        type: string;
        uuid: string;
        avatar: string;
    };
    assignmentDate?: string;
}

export class ResourceListParser {
    parseListItem(dto: ResourceListDTO): ResourceContent {
        const metadata = dto?.contentMetadata ? dto.contentMetadata : null;
        const timestamp = parseInt(dto.date as string);

        return new ResourceContent({
            contentMetadata: metadata,
            contentUUID: dto.contentUUID,
            title:
                dto.label === 'resource_not_found'
                    ? dto.label
                    : dto.title || dto.name,
            legacy: {
                articleId: Number(dto.articleId),
                groupId: dto.groupId,
                contentType: parseDtoToResourceType(dto.type),
                tagEntries: dto.tags
                    ? dto.tags.map((tag, index) => ({
                          id: index,
                          name: tag,
                          isCategory: false,
                      }))
                    : [],
                categories: dto.categories,
                medium_image:
                    dto?.medium_image ||
                    this.parseImage(dto?.content?.medium_image) ||
                    dto?.thumbnail,
            },
            date: moment(timestamp) as any,
            assigner: dto?.assigner,
            assignmentDate: dto?.assignmentDate,
        });
    }

    private parseImage(medium_image: string | DynamicContent | undefined) {
        if (!medium_image) return;

        if (typeof medium_image === 'string') {
            return medium_image;
        } else if (typeof medium_image['dynamic-content'] === 'string') {
            return medium_image['dynamic-content'];
        } else {
            return;
        }
    }
}
