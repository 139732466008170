import { useCallback, useMemo } from 'react';
import { useSSO } from '../../../features/auth/use-sso';
import { localization } from '../../../localization';
import { isCordova } from '../../../utils/cordova';
import { useMoreThanTwoPartsHostname } from './use-get-domains';
import { GetSSOEndpoint } from './use-get-sso-endpoint';
import { IUseSSOToRetrieveIdp, UseSSOToRetrieveIdp } from './use-retrieve-idp';

interface UseSSOProps {
    setLoginType: (type: string | null) => void;
    setSsoErrorText: (text: string | null) => void;
    setSsoLoadingText: (text: string | null) => void;
}

export const urlToRedirectIdp = (data: IUseSSOToRetrieveIdp) => {
    return `${data.idpUrl}${
        data.idpUrl.includes('?') ? `&` : '?'
    }SAMLRequest=${encodeURIComponent(data.authnRequest.encodedAuthnRequest)}${
        data.signatureAlgorithm
            ? `&SigAlg=${encodeURIComponent(
                  data.signatureAlgorithm.replace(/https/g, 'http')
              )}`
            : ''
    }${
        data.signature ? `&Signature=${encodeURIComponent(data.signature)}` : ''
    }${
        data.relayState
            ? `&RelayState=${encodeURIComponent(data.relayState)}`
            : ''
    }`;
};

export const urlDeeplinkRedirectIdp = (data: IUseSSOToRetrieveIdp) => {
    return `${data.idpUrl}${
        data.idpUrl.includes('?') ? `&` : '?'
    }SAMLRequest=${encodeURIComponent(data.authnRequest.encodedAuthnRequest)}${
        data.signatureAlgorithm
            ? `&SigAlg=${encodeURIComponent(
                  data.signatureAlgorithm.replace(/https/g, 'http')
              )}`
            : ''
    }${
        data.signature ? `&Signature=${encodeURIComponent(data.signature)}` : ''
    }`;
};

export const UseSSO = ({
    setLoginType,
    setSsoErrorText,
    setSsoLoadingText,
}: UseSSOProps) => {
    const twoPartsHostname = useMoreThanTwoPartsHostname();
    const { SSORedirectIDPMobile } = useSSO();

    const handleSSOSubmitMobile = useCallback(
        async (inputValue: string) => {
            const endPoint = {
                clientId: inputValue,
                clientUrl: `${inputValue}${twoPartsHostname}`,
            };
            const apiEndpoint = GetSSOEndpoint(endPoint);
            const getIdp = UseSSOToRetrieveIdp(apiEndpoint);
            setLoginType('sso');
            setSsoErrorText(null);
            setSsoLoadingText(localization.sso_loading);

            getIdp.subscribe((data: IUseSSOToRetrieveIdp) => {
                if (data instanceof Error) {
                    // Handle the error here
                    setSsoErrorText(localization.login_sso_domainNotFound);
                    setSsoLoadingText(null);
                } else {
                    // Handle the successful API response data here
                    // If the API request is successful, open SSO login page using InAppBrowser
                    const ssoAuthUrl = urlToRedirectIdp(data);
                    SSORedirectIDPMobile(endPoint.clientId, ssoAuthUrl);
                }
            });
        },
        [
            setLoginType,
            setSsoErrorText,
            setSsoLoadingText,
            SSORedirectIDPMobile,
            twoPartsHostname,
        ]
    );

    /** TODO this function works for web */
    const handleSSOSubmit = useCallback(
        async (inputValue: string) => {
            const apiEndpoint = GetSSOEndpoint({
                clientId: inputValue,
                clientUrl: `${inputValue}${twoPartsHostname}`,
            });
            const getIdp = UseSSOToRetrieveIdp(apiEndpoint);
            setLoginType('sso');
            setSsoErrorText(null);
            //Set message for loading screen while waiting for the authentication response
            setSsoLoadingText(localization.sso_loading);
            getIdp.subscribe((data: IUseSSOToRetrieveIdp) => {
                if (data instanceof Error) {
                    // Handle the error here
                    setSsoErrorText(localization.login_sso_domainNotFound);
                    setSsoLoadingText(null);
                } else {
                    // Handle the successful API response data here
                    const urlRedirect = `${
                        data.idpUrl
                    }?SAMLRequest=${encodeURIComponent(
                        data.authnRequest.encodedAuthnRequest
                    )}`;
                    window.location.href = urlRedirect;
                }
            });
        },
        [setLoginType, setSsoErrorText, setSsoLoadingText, twoPartsHostname]
    );

    /** END TODO this function works for web */

    const handleBack = useCallback(() => {
        setSsoErrorText(null);
    }, [setSsoErrorText]);

    const displaySSO = useMemo(() => {
        return {
            sso: {
                btnText: localization.login_sso_btnText,
                btnTextBack: localization.login_sso_btnTextBack,
                btnTextSubmit: localization.login_sso_btnTextSubmit,
                inputPlaceHolder: localization.login_sso_inputPlaceHolder,
                onChangeStep: () => {},
                onClickBack: handleBack,
                onSsoSubmit: isCordova
                    ? handleSSOSubmitMobile
                    : handleSSOSubmit,
                titleText: localization.login_sso_titleText,
                hintInput: twoPartsHostname,
                tooltip: {
                    title: localization.login_sso_tooltipTitle,
                    text: localization.login_sso_tooltipText,
                },
            },
        };
    }, [handleSSOSubmitMobile, handleSSOSubmit, twoPartsHostname, handleBack]);

    return { displaySSO };
};
