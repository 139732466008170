import { RootEpic } from './root-epic';
import { of } from 'rxjs';
import { filter, ignoreElements, map } from 'rxjs/operators';
import { isActionOf } from 'typesafe-actions';
import { Action } from '../store/root-action';

const onTrackEvent: RootEpic = (action$, state$, { portalTrackingService }) =>
    action$.pipe(
        filter(isActionOf(Action.portalTracker.trackEvent)),
        map((x) => {
            const topicId =
                state$.value.learnerProfile?.currentLanguage?.languageUUID;
            const userId = state$.value.user?.identity?.learnerUUID;

            if (!topicId || !userId) return of();

            return portalTrackingService.trackActivity(
                topicId,
                userId,
                x.payload.type
            );
        }),
        ignoreElements()
    );

export const portalTrackerEpics = [onTrackEvent];
