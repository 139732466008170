import { useLocation } from 'react-router-dom';
import { isCordova } from '../../../utils/cordova';
import { environment } from '../../../environments';

export function GetHostname() {
    const location = useLocation();
    if (isCordova) {
        return environment.API_HOST.replace(/^https?:\/\//, '');
    } else {
        const hostname = new URL(location.pathname, window.location.origin)
            .hostname;
        return hostname;
    }
}

export function useFirstDomainSegmentWithoutDash() {
    const firstSegment = useFirstDomainSegment();
    if (firstSegment) {
        return firstSegment.replace(/-/g, '');
    }

    return firstSegment;
}

export function useFirstDomainSegment() {
    const hostname = GetHostname();
    const segments = hostname.split('.');
    if (hostname === 'localhost') {
        return 'portal';
    } else if (segments.length > 1) {
        return segments[0];
    }
    return null; // Return null if the domain does not contain a dot
}

export function useMoreThanTwoPartsHostname() {
    const url = GetHostname();
    const hostnameParts = url.split('.'); // Split the hostname by dot

    // Check if there are more than two parts in the hostname
    if (hostnameParts.length > 2) {
        const domain = hostnameParts.slice(-2).join('.'); // Take the last two parts
        return `.${domain}`;
    } else {
        const urlEnvironment = environment.API_HOST;
        // The hostname already represents the domain
        const domain = new URL(urlEnvironment).hostname;
        // Split the hostname by "." and get the last two parts
        const parts = domain.split('.').slice(-2);
        // Join the last two parts to get the domain
        const domainName = parts.join('.');

        if (url === 'localhost') {
            return `.${domainName}`;
        } else {
            return url;
        }
    }
}
