import { RootEpic } from './root-epic';
import {
    catchError,
    filter,
    map,
    mergeMap,
    distinctUntilChanged,
    switchMap,
} from 'rxjs/operators';
import { isActionOf } from 'typesafe-actions';
import { Action } from '../store/root-action';
import { of } from 'rxjs';

const loadGrading: RootEpic = (action$, state$, { gradingApi }) => {
    return action$
        .pipe(filter(isActionOf(Action.grading.loadGrading.request)))
        .pipe(
            mergeMap((action) => {
                return gradingApi.loadGrading(action.payload.id).pipe(
                    map((response) => {
                        return Action.grading.loadGrading.success(response);
                    }),
                    catchError((e) => of(Action.grading.loadGrading.failure(e)))
                );
            })
        );
};

const loadGradingGoCEFR: RootEpic = (action$, state$, { gradingApi }) => {
    return action$
        .pipe(filter(isActionOf(Action.grading.loadAllGradingsGoCEFR.request)))
        .pipe(
            mergeMap(() => {
                return gradingApi.loadGradingGoCEFR().pipe(
                    map((response) => {
                        return Action.grading.loadAllGradingsGoCEFR.success(
                            response
                        );
                    }),
                    catchError((e) =>
                        of(Action.grading.loadAllGradingsGoCEFR.failure(e))
                    )
                );
            })
        );
};

const getAllGradingsEpic: RootEpic = (action$, state$, { gradingApi }) => {
    return action$
        .pipe(filter(isActionOf(Action.grading.loadAllGradings.request)))
        .pipe(
            mergeMap((action) => {
                return gradingApi.loadAllGrading().pipe(
                    map((response) => {
                        return Action.grading.loadAllGradings.success(response);
                    }),
                    catchError((e) =>
                        of(Action.grading.loadAllGradings.failure(e))
                    )
                );
            })
        );
};

const preloadGetAllGradingsEpic: RootEpic = (action$, state$, { gradingApi }) =>
    state$.pipe(
        map((x) => x.user.identity),
        filter(
            (identity) =>
                !!(identity && identity.learnerUUID) && navigator.onLine
        ),
        distinctUntilChanged(),
        switchMap((x) => {
            return of(
                Action.grading.loadAllGradings.request(),
                Action.grading.loadAllGradingsGoCEFR.request()
            );
        })
    );

export const gradingEpics = [
    loadGrading,
    getAllGradingsEpic,
    preloadGetAllGradingsEpic,
    loadGradingGoCEFR,
];
