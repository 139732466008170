import { Record } from 'immutable';
import { DisplayRule } from './display-rule';
import { DisplayHandleType } from './display-handle';

interface DisplayStrategyProps {
    displayHandle: DisplayHandleType;
    displayRule: DisplayRule[];
}

const defaultDisplayStrategy: DisplayStrategyProps = {
    displayHandle: 'navigation-restriction',
    displayRule: [],
};

const defaultDisplayCustomizationStrategy: DisplayStrategyProps = {
    displayHandle: 'customized-display',
    displayRule: [],
};

export class DisplayStrategyRecord
    extends Record(defaultDisplayStrategy)
    implements DisplayStrategyProps {}

export class DisplayStrategyCustomRecord
    extends Record(defaultDisplayCustomizationStrategy)
    implements DisplayStrategyProps {}
