import { createAction, createAsyncAction } from 'typesafe-actions';
import {
    TestAnswerSelection,
    TestRecord,
    TestQuestionRecord,
} from './adaptive-test-data';
import { TestAnswerType } from '../../services/adaptive-test-api';

export const startTest = createAction('@@adaptive-text/start')();

/*
 *   Action to start a test and load current data
 */
export const loadTest = createAsyncAction(
    '@@adaptive-test/load/request',
    '@@adaptive-test/load/success',
    '@@adaptive-test/load/failure'
)<{ languageUUID: string; userUUID: string }, TestRecord, Error>();

/*
 *   Action to load last completed test
 */
export const loadLastTest = createAsyncAction(
    '@@adaptive-test/load-last/request',
    '@@adaptive-test/load-last/success',
    '@@adaptive-test/load-last/failure'
)<{ languageUUID: string; userUUID: string }, TestRecord, Error>();

/*
 *   Action to complete a test
 */
export const completeTest = createAsyncAction(
    '@@adaptive-test/complete/request',
    '@@adaptive-test/complete/success',
    '@@adaptive-test/complete/failure'
)<void, TestRecord, Error>();

/*
 *   Action to load current question
 */
export const loadQuestion = createAsyncAction(
    '@@adaptive-test/question-load/request',
    '@@adaptive-test/question-load/success',
    '@@adaptive-test/question-load/failure'
)<{ testUUID: string }, TestQuestionRecord, Error>();

/*
 *   Action to set current chose answer
 */
export const setAnswer = createAction(
    '@@adaptive-test/selected-answer'
)<TestAnswerSelection>();

/*
 *   Action to set that we filled all answers
 */
export const setHasAnswers = createAction(
    '@@adaptive-test/has-answers'
)<boolean>();

/*
 *   Action to submit current question
 */
export const submitQuestion = createAsyncAction(
    '@@adaptive-test/submit/request',
    '@@adaptive-test/submit/success',
    '@@adaptive-test/submit/failure'
)<
    { type: TestAnswerType; time: number; answer: TestAnswerSelection },
    any,
    Error
>();

/*
 *   Action to clear all test data
 */
export const clearTest = createAction('@@adaptive-test/clear')();

/*
 *   Action to show modal with continue instruction
 */
export const showContinueModal = createAction(
    '@@adaptive-test/show-continue'
)<boolean>();

/*
 *   Action to show modal with close instruction
 */
export const showCloseModal = createAction(
    '@@adaptive-test/show-close'
)<boolean>();

export const setTimerState = createAction(
    '@@adaptive-test/set-timer-state'
)<boolean>();

export const setLocation = createAction(
    '@@adaptive-test/set-location'
)<string>();

const adaptiveTestAction = {
    loadTest,
    loadLastTest,
    loadQuestion,
    setAnswer,
    setHasAnswers,
    completeTest,
    submitQuestion,
    showCloseModal,
    showContinueModal,
    setTimerState,
    clearTest,
    setLocation,
};
export default adaptiveTestAction;
