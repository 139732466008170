import { localization } from '../../../localization';
import {
    PerformanceSurveyStepQuestions,
    PerformanceSurveySteps,
    QuestionChildDto,
    SurveyOptionsDto,
} from '../types';

type localizationType = typeof localization;

export const translateOption = (label: string): string => {
    const key = `performanceSurvey_${label}`;
    return localization[key as keyof localizationType] as string;
};

export const convertQuestions = (
    questions: QuestionChildDto[] | null | undefined
): PerformanceSurveyStepQuestions[] | null => {
    if (!questions || !questions.length) return null;
    return questions.map((question) => {
        return {
            title: translateOption(question.performanceOption),
            option: question.performanceOption,
            isParent: question.isParent,
            type: question.questionType === 'Score' ? 'choice' : 'text',
            questionType: question.questionType,
            childs: convertQuestions(question.childs),
        } as PerformanceSurveyStepQuestions;
    });
};

export const convertSteps = (
    steps: SurveyOptionsDto[]
): PerformanceSurveySteps[] => {
    return steps.map((step) => {
        return {
            ...step,
            title: translateOption(step.title),
            questions: convertQuestions(step.questions),
        };
    });
};
