import { createAction } from 'typesafe-actions';

export const loadProvision = createAction('@@integration/load-provisioning')<{
    learnerUUID: string;
    learnerExternalId: string;
}>();

export const integrationActions = {
    loadProvision,
};
