import { combineReducers } from 'redux';
import { createReducer } from 'typesafe-actions';
import { Action } from '../../root-action';

import {
    AccessTokenResDto,
    AllocatedVirtualClassDto,
    VCRStatus,
    VirtualClassDto,
} from './classroom-list-data';
import {
    clearJoinedClassError,
    closeVCRFullModal,
    getVCRToken,
    joinVCR,
    loadAvailableClasses,
    clearConversation,
    isVCRCompatible,
    setVCRFullModal,
} from './classroom-list-actions';
import { getJoinedParticipantsByClassId } from '../classroom/classroom-actions';
import { LoadingState } from '../../resources/resources';

const isLoading = createReducer(false)
    .handleAction(
        loadAvailableClasses.request,
        (state, { payload = true }) => payload
    )
    .handleAction(
        [loadAvailableClasses.success, loadAvailableClasses.failure],
        () => false
    );

const isLoadingVirtualPage = createReducer<LoadingState>('idle')
    .handleAction(getVCRToken.request, () => 'pending')
    .handleAction(getVCRToken.success, () => 'succeeded')
    .handleAction(getVCRToken.failure, () => 'failed')
    .handleAction(clearConversation, () => 'idle');

const showVCRFullModal = createReducer(false)
    .handleAction(
        joinVCR.success,
        (state, { payload }) => payload.status.toUpperCase() === VCRStatus.FULL
    )
    .handleAction([joinVCR.failure, closeVCRFullModal], () => false)
    .handleAction([setVCRFullModal], (state, { payload }) => {
        return payload;
    });

const availableClasses = createReducer<VirtualClassDto[] | null>(null)
    .handleAction(loadAvailableClasses.request, () => null)
    .handleAction([loadAvailableClasses.success], (state, { payload }) => {
        return payload;
    })
    .handleAction(loadAvailableClasses.failure, () => []);

const loadingJoinedParticipants = createReducer<boolean>(false)
    .handleAction(getJoinedParticipantsByClassId.request, () => true)
    .handleAction(
        [
            getJoinedParticipantsByClassId.success,
            getJoinedParticipantsByClassId.failure,
        ],
        () => false
    );

const joinedClass = createReducer<AllocatedVirtualClassDto | null>(null)
    .handleAction(joinVCR.success, (state, { payload }) => {
        return payload;
    })
    .handleAction([joinVCR.failure, clearConversation], () => {
        return null;
    });

const isJoinedClassError = createReducer(false)
    .handleAction(joinVCR.failure, () => true)
    .handleAction(
        [joinVCR.request, joinVCR.success, clearJoinedClassError],
        () => false
    );

const vcrToken = createReducer<AccessTokenResDto | null>(null)
    .handleAction(getVCRToken.success, (state, { payload }) => {
        return payload;
    })
    .handleAction([getVCRToken.failure, clearConversation], () => {
        return null;
    });

const isCompatible = createReducer<boolean>(false).handleAction(
    isVCRCompatible,
    (state, { payload }) => payload
);

const error = createReducer<string | null>(null).handleAction(
    [
        joinVCR.failure,
        getVCRToken.failure,
        Action.scheduler.loadForeseenVCR.failure,
    ],
    (state, { payload }) => {
        return payload.message;
    }
);

export const list = combineReducers({
    isLoading,
    isLoadingVirtualPage: isLoadingVirtualPage,
    showVCRFullModal,
    availableClasses,
    joinedClass,
    isJoinedClassError,
    loadingJoinedParticipants,
    vcrToken,
    isCompatible,
    error,
});
