import { ApiHeaders } from './api-headers';
import { Observable } from 'rxjs';
import { ajax } from 'rxjs/ajax';
import { homework, learnerRecommendation } from './api-urls';
import { map } from 'rxjs/operators';
import {
    Homework,
    NewHomeWorkProps,
    HomeworkProps,
} from '../store/homework/homework';
import { parseDtoToResourceType } from './resources/resource-type-dto';
import moment from 'moment/moment';

export class HomeworkApi {
    constructor(private readonly apiHeaders: ApiHeaders) {}

    loadACLHomeworkList(
        userUUID: string,
        languageUUID: string
    ): Observable<Homework[]> {
        return ajax
            .getJSON<HomeworkProps[]>(
                `${homework()}/${userUUID}/${languageUUID}`,
                this.apiHeaders.getHeaders()
            )
            .pipe(
                map((x) =>
                    x.map(
                        (x) =>
                            // Todo nicelly handle homework load
                            new Homework({
                                ...x,
                                ...{
                                    legacy: {
                                        contentType: parseDtoToResourceType(
                                            x.legacy.contentType
                                        ),
                                        id: x.legacy.id,
                                        articleId: x.legacy.articleId,
                                        tagAssetId: x.legacy.tagAssetId,
                                        groupId: x.legacy.groupId,
                                    },
                                },
                            })
                    )
                )
            );
    }

    /**
     * Notify outer LMS on finishing the quiz.
     */
    finishHomework(data: any) {
        const url = learnerRecommendation() + 'resource/finish';
        return ajax.post(url, data, {
            'Content-Type': 'application/json',
        });
    }

    loadHomeworkList(querySelector: any): Observable<any> {
        return ajax
            .getJSON<NewHomeWorkProps>(
                `${learnerRecommendation()}/resource/assigned/resource?${querySelector}`,
                this.apiHeaders.getHeaders()
            )
            .pipe(
                map((x) => {
                    const list = x.assignmentDto.filter(
                        (item) =>
                            item.legacy.contentType !== 'training-path' &&
                            item.legacy.contentType !== 'trainingpath' &&
                            (!item.dueDate ||
                                moment(item.dueDate).isSameOrAfter(moment()))
                    );
                    return {
                        homeworkList: list.map((x) => {
                            return new Homework({
                                ...x,
                                ...{
                                    legacy: {
                                        contentType: parseDtoToResourceType(
                                            x.legacy.contentType
                                        ),
                                        id: x.legacy.id,
                                        articleId: x.legacy.articleId,
                                        tagAssetId: x.legacy.tagAssetId,
                                        groupId: x.legacy.groupId,
                                    },
                                },
                            });
                        }),
                        nextKey: x?.nextKey || 0,
                    };
                })
            );
    }
}
