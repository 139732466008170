import { createReducer } from 'typesafe-actions';
import { List } from 'immutable';
import { CountryCodeData, InternetCallData } from './call-data';
import { Action } from '../../../root-action';
import { combineReducers } from 'redux';

export const countryCodes = createReducer(List<CountryCodeData>()).handleAction(
    Action.vcr.classroom.call.getCountryCodes.success,
    (state, action) => state.merge(action.payload)
);

export const isValidNumber = createReducer(false)
    .handleAction(
        Action.vcr.classroom.call.validateNumber.success,
        (state, action) => action.payload
    )
    .handleAction(Action.vcr.classroom.call.validateNumber.request, () => false)
    .handleAction(Action.vcr.classroom.call.resetValidation, () => false);

export const internetCall = createReducer<InternetCallData | null>(
    null
).handleAction(
    Action.vcr.classroom.call.validateInternetCall.success,
    (state, action) => action.payload
);

export const call = combineReducers({
    countryCodes,
    isValidNumber,
    internetCall,
});
