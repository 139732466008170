import { Observable } from 'rxjs';
import { Axios } from 'axios-observable';
import { catchError, map } from 'rxjs/operators';
import { ApiHeaders } from './api-headers';
import { apiv1 } from './api-urls';

export interface PreferredLangauges {
    displayLanguage: string;
    preferedCommunicationLanguage: string;
    userId: string;
    offlineModeEnabled: boolean;
}

export interface ILearnerPreferences {
    setLearnerPreference(user: {
        userId: string;
        preferedCommunicationLanguage: string;
        displayLanguage: string;
        offlineModeEnabled: boolean;
    }): Observable<void>;
}

export class LearnerPreferencesApi implements ILearnerPreferences {
    constructor(private readonly apiHeaders: ApiHeaders) {
        this.apiHeaders = apiHeaders;
    }

    private getPostHeaders() {
        return {
            ...this.apiHeaders.getHeaders(),
            'Content-Type': 'application/json',
        };
    }

    public setLearnerPreference(user: {
        userId: string;
        displayLanguage: string;
        preferedCommunicationLanguage: string;
        offlineModeEnabled: boolean;
    }): Observable<any> {
        return Axios.post(apiv1() + 'learner-preferences/user-profile/', user, {
            headers: this.getPostHeaders(),
        }).pipe(
            map((x) => {
                return x.data;
            }),
            catchError(() => {
                throw new Error("Can't save learner preference");
            })
        );
    }
}
