import { createAction } from 'typesafe-actions';
import { BoardFigureData, BoardTabProps } from './board-data';

const showBoard = createAction('@@board/show-board')<boolean>();

const addTab = createAction('@@board/add-tab')<BoardTabProps>();
const setActiveTab = createAction('@@board/active-tab')<number>();
const closeTab = createAction('@@board/remove-tab')<number>();
const addTabContent = createAction('@@board/add-content')<BoardTabProps>();

const playVideoTab = createAction('@@board/play-video')<BoardTabProps>();
const stopVideoTab = createAction('@@board/stop-video')<BoardTabProps>();

const addFigure = createAction('@@board/add-figure')<BoardFigureData>();
const removeFigure = createAction('@@board/remove-figure')<{
    tabId: number;
    id: number;
}>();
const clearFigures = createAction('@@board/clear-figures')<number>();

const boardActions = {
    showBoard,
    addTab,
    setActiveTab,
    closeTab,
    addTabContent,
    playVideoTab,
    stopVideoTab,
    addFigure,
    removeFigure,
    clearFigures,
};

export default boardActions;
