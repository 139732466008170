// TODO: uncomment this when refactor for RTK would be completed also add needed
import {
    Action,
    combineReducers,
    configureStore,
    ThunkAction,
} from '@reduxjs/toolkit';

import { setupListeners } from '@reduxjs/toolkit/query';
import { persistReducer, persistStore } from 'redux-persist';
import { emptySplitApi } from './empty-split-api';
import { surveysSlice } from '../features/surveys/slice';
import { epicMiddleware } from '../store/epic-middleware';
import { rootEpics } from '../epics';
import { persistConfig } from '../store/persistor-config';
import { userReducer } from '../store/user/user-reducer';
import { quizzesReducer } from '../store/quiz';
import { resourcesReducer } from '../store/resources/resources';
import { savedContentReducers } from '../store/save-content/save-content-reducers';
import { InProgressTabReducers } from '../store/in-progress/in-progress-reducers';
import { vcrReducer } from '../store/vcr/vcr-reducer';
import { vcrLinkReducer } from '../store/vcr/persist-message/persist-message-reducer';
import { schedulerReducer } from '../store/scheduler/scheduler-reducer';
import { lessonReportsReducer } from '../store/lesson-reports/lesson-reports-reducer';
import { lessonsListReducer } from '../store/scheduler/lessons-list-reducer';
import { lessonsSettingsReducer } from '../store/scheduler/lessons-settings-reducer';
import { trainingPathReducer } from '../store/resources/training-path/training-path-reducer';
import { configurationReducer } from '../store/configuration';
import { learnerProfileReducer } from '../store/learner-profile/learner-profile-reducers';
import { learnerOnboardingReducer } from '../store/learner-onboarding/learner-onboarding-reducers';
import { moduleRestrictionReducer } from '../store/module-restriction/module-restriction-reducers';
import { testReducer } from '../store/adaptive-test/adaptive-test-reducers';
import { displayStrategyReducer } from '../store/display-strategy/display-strategy-reducers';
import { homeworkReducer } from '../store/homework/homework-reducers';
import { creditsReducer } from '../store/credits/credit-reducers';
import { mutedReducer } from '../store/autoplay-helper';
import { appReducer } from '../store/app/app-reducers';
import { privacyPolicyReducer } from '../store/privacy-policy/privacy-policy-reducers';
import { trainingReducer } from '../store/training/training-reducer';
import { eWritingReducer } from '../store/e-writing/e-writing-reducers';
import { contractReducer } from '../store/contracts/contract-reducers';
import { insightsReducer } from '../store/insights/insights-reducer';
import { proficiencyReducer } from '../store/proficiency/proficiency-reducer';
import { alertReducer } from '../store/alert/alert-reducers';
import { gradingReducer } from '../store/grading/grading-reducer';
import { activityFiltersReducer } from '../store/activity-filters/activity-filters-reducer';
import { learnerChatReducer } from '../features/contact-us/learner-chat-reducer';
import { displayLanguageReducer } from '../store/display-language/display-language-reducer';
import { contactUsSlice } from '../features/contact-us/slice';

const sliceList = [surveysSlice, contactUsSlice];

const api = emptySplitApi;

// const persistConfig = {
//     key: 'rootRTK',
//     storage,
//     blacklist: [api.reducerPath],
// };

// TODO: for all - remove RTK prefix when we finish refactor from react-redux to RTK
export const storeRTK = configureStore({
    reducer: persistReducer(
        {
            ...persistConfig,
            blacklist: [api.reducerPath],
        },
        combineReducers({
            user: userReducer(),
            quizzes: quizzesReducer(),
            resources: resourcesReducer(),
            savedContentFeature: savedContentReducers,
            inProgressTabFeature: InProgressTabReducers,
            vcr: vcrReducer,
            vcrLink: vcrLinkReducer,
            scheduler: schedulerReducer(),
            lessonReports: lessonReportsReducer(),
            lessonsList: lessonsListReducer(),
            lessonsSettings: lessonsSettingsReducer(),
            trainingPath: trainingPathReducer(),
            configuration: configurationReducer,
            learnerProfile: learnerProfileReducer,
            learnerOnboarding: learnerOnboardingReducer,
            moduleRestriction: moduleRestrictionReducer,
            adaptiveTest: testReducer,
            test: testReducer,
            displayStrategy: displayStrategyReducer,
            homework: homeworkReducer(),
            credits: creditsReducer(),
            muted: mutedReducer,
            app: appReducer(),
            privacyPolicy: privacyPolicyReducer(),
            training: trainingReducer,
            eWriting: eWritingReducer,
            contracts: contractReducer,
            insights: insightsReducer,
            proficiency: proficiencyReducer,
            alert: alertReducer,
            grading: gradingReducer(),
            activityFilters: activityFiltersReducer,
            learnerChat: learnerChatReducer,
            displayLanguage: displayLanguageReducer,
            ...sliceList.reduce(
                (acc, slice) => ({ ...acc, [slice.name]: slice.reducer }),
                {}
            ),
            [api.reducerPath]: api.reducer,
        })
    ),
    devTools: process.env.NODE_ENV !== 'production',
    preloadedState: {},
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            immutableCheck: false,
            serializableCheck: false,
        }).concat(api.middleware, epicMiddleware),
});
epicMiddleware.run(rootEpics());
setupListeners(storeRTK.dispatch);

export type RootStateRTK = ReturnType<typeof storeRTK.getState>;

export type AppDispatchRTK = typeof storeRTK.dispatch;

export type AppThunk<ReturnType = void> = ThunkAction<
    ReturnType,
    RootStateRTK,
    unknown,
    Action<string>
>;

export const persistorRTK = persistStore(storeRTK);
