import { SurveyLessonsOptions, SurveyServiceType } from '../types';
import {
    ConversationClassOptionsSelector,
    GroupLessonOptionsSelector,
    IndividualLessonOptionsSelector,
    PerformanceOptionsSelector,
} from '../slice';
import { useGetPageOptionsQuery, useGetSurveysOptionsQuery } from '../api';
import { useSelector } from 'react-redux';
import { userSelector } from '../../../store/user/user-selectors';

export const useGetSurveysOptions = (): SurveyLessonsOptions => {
    // Get learner
    const learnerUUID = useSelector(userSelector)?.learnerUUID;
    // Get already fetched data
    const IndividualLessonOptions = useSelector(
        IndividualLessonOptionsSelector
    );
    const ConversationClassOptions = useSelector(
        ConversationClassOptionsSelector
    );
    const GroupLessonOptions = useSelector(GroupLessonOptionsSelector);
    const PerformanceOptions = useSelector(PerformanceOptionsSelector);
    // Queries
    const surveyType: SurveyServiceType = 'TrainerSurvey';
    useGetSurveysOptionsQuery(
        {
            surveyType: surveyType,
            businessType: 'ConversationClass',
            userUuid: learnerUUID ?? '',
        },
        { skip: ConversationClassOptions !== null }
    );
    useGetSurveysOptionsQuery(
        {
            surveyType: surveyType,
            businessType: 'GroupLesson',
            userUuid: learnerUUID ?? '',
        },
        { skip: GroupLessonOptions !== null }
    );
    useGetSurveysOptionsQuery(
        {
            surveyType: surveyType,
            businessType: 'IndividualLesson',
            userUuid: learnerUUID ?? '',
        },
        { skip: IndividualLessonOptions !== null }
    );
    useGetPageOptionsQuery(
        {
            surveyType: 'PerformanceSurvey',
            businessType: 'LearnerPortal',
        },
        { skip: PerformanceOptions !== null }
    );

    return {
        IndividualLessonOptions: IndividualLessonOptions,
        ConversationClassOptions: ConversationClassOptions,
        GroupLessonOptions: GroupLessonOptions,
        PerformanceOptions: PerformanceOptions,
    };
};
