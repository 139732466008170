import { createAction, createAsyncAction } from 'typesafe-actions';
import {
    ModuleRestrictionDisplayProps,
    ModuleRestrictionDisplayRecord,
} from './module-restriction-records';

export const loadModuleRestriction = createAsyncAction(
    '@@module-restriction/load/request',
    '@@module-restriction/load/success',
    '@@module-restriction/load/failure'
)<void, ModuleRestrictionDisplayProps | null, Error>();

export const setModuleRestricted = createAction(
    '@@module-restriction/set-module-restricted'
)<ModuleRestrictionDisplayRecord | null>();

export const loadRestrictions = createAsyncAction(
    '@@restrictions/load/request',
    '@@restrictions/load/success',
    '@@restrictions/load/failure'
)<void, ModuleRestrictionDisplayProps | null, Error>();

export const setRestrictions = createAction(
    '@@restrictions/set-restrictions'
)<ModuleRestrictionDisplayProps | null>();

export const setIsRFY = createAction('@@restrictions/set-is-rfy')<
    boolean | null
>();
