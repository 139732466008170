import { apiv100 } from '../../../services/api-urls';

interface ISamlSSO {
    clientId: string;
    clientUrl?: string;
}

export const GetSSOEndpoint = ({ clientId, clientUrl }: ISamlSSO) => {
    return `${apiv100()}sso-validator/saml/${clientId}/language-academy${
        clientUrl ? `/${clientUrl}` : ''
    }`;
};
