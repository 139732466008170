import React, { Suspense, lazy, useEffect, useMemo } from 'react';
import {
    GlobalStyles,
    Notification,
} from '@arcadia-projects/portal2-ui-library';
import 'moment/locale/ar-dz';
import 'moment/locale/cs';
import 'moment/locale/de';
import 'moment/locale/es';
import 'moment/locale/fr';
import 'moment/locale/he';
import 'moment/locale/id';
import 'moment/locale/it';
import 'moment/locale/ja';
import 'moment/locale/ko';
import 'moment/locale/nl';
import 'moment/locale/pl';
import 'moment/locale/pt';
import 'moment/locale/ro';
import 'moment/locale/ru';
import 'moment/locale/sk';
import 'moment/locale/tr';
import 'moment/locale/vi';
import 'moment/locale/zh-cn';
import 'moment/locale/zh-tw';
import { useSelector } from 'react-redux';
import { Route, Switch } from 'react-router-dom';
import { Connectivity } from '../../connectivity';
import { useCustomizedColors } from '../../hooks/use-customized-colors';
import { connectionActiveSelector } from '../../store/resources/offline/offline-selectors';
import { hasOfflineContractSelector } from '../../store/user/user-selectors';
import { Loading } from '../loading/loading';
import { LoginRouter } from '../login/login-router';
import { SSOErrorLoginPage } from '../login/ui/sso-error-page';
import { SSOSamlconnector } from '../login/ui/sso-samlconnector';
import { PrivacyPolicyDetail } from '../privacy-policy/privacy-policy-detail-page';
import { WindowSizeTracker } from '../window-size-tracker/window-size-tracker';
import { PrivateRoute } from './private-route';
import { initDingH5RemoteDebug } from 'dingtalk-h5-remote-debug';
import DingAuthStartPage from '../../features/ding-talk';
import DingAuthPage from '../../features/ding-talk/auth';

const MainRouter = lazy(() => import('./main-router'));
const MainRouterOffline = lazy(() => import('./main-router-offline'));

const App: React.FC = () => {
    const connectionActive = useSelector(connectionActiveSelector);
    const hasOfflineContract = useSelector(hasOfflineContractSelector);

    useCustomizedColors();

    useEffect(() => {
        initDingH5RemoteDebug();
    }, []);

    const DisplayRouter = useMemo(() => {
        if (connectionActive !== null) {
            if (
                (!!connectionActive && connectionActive) ||
                !hasOfflineContract
            ) {
                return <MainRouter />;
            } else {
                return <MainRouterOffline />;
            }
        } else {
            return <React.Fragment />;
        }
    }, [connectionActive, hasOfflineContract]);

    return (
        <Suspense fallback={<Loading />}>
            <Connectivity />
            <GlobalStyles />
            <WindowSizeTracker delay={500} />
            <Notification />
            <Switch>
                <Route path="/dingconnectorauth" exact={true}>
                    <DingAuthPage />
                </Route>
                <Route path="/dingconnector" exact={true}>
                    <DingAuthStartPage />
                </Route>
                <Route path="/login">
                    <LoginRouter />
                </Route>
                <Route path="/service-privacy-policy">
                    <PrivacyPolicyDetail />
                </Route>
                <Route path="/sso-error-login">
                    <SSOErrorLoginPage />
                </Route>
                <Route path="/samlconnector">
                    <SSOSamlconnector />
                </Route>
                <PrivateRoute>{DisplayRouter}</PrivateRoute>
            </Switch>
        </Suspense>
    );
};

export default App;
