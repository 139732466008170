import { RootEpic } from './root-epic';
import { catchError, filter, map, switchMap } from 'rxjs/operators';
import { isActionOf } from 'typesafe-actions';
import { Action } from '../store/root-action';
import { of } from 'rxjs';
import { PostEWritingDTO } from '../store/e-writing/data/ewriting-dtos';
import { EWritingLimitation } from '../store/e-writing/data/ewriting-models';
import { ContractTypes } from '../store/contracts/contract-data';
import { localization } from '../localization';
import { List } from 'immutable';
/*
 *  Epic to send new eWriting
 *  TODO: update values for:
 * - learnerGrading
 * - subjectActivity
 * - learnerTextHtml (send as html ?)
 */
const sendEWritingEpic: RootEpic = (action$, state$, { eWritingApi }) =>
    action$.pipe(
        filter(isActionOf(Action.eWriting.sendEWriting.request)),
        switchMap((x) => {
            const newEWriting: PostEWritingDTO = {
                learner: String(
                    state$.value.learnerProfile?.profile?.learnerUUID
                ),
                learnerGrading: String(
                    state$.value.learnerProfile?.profile?.learnerUUID
                ),
                learnerTextHtml: x.payload.writingText,
                subject: x.payload.subject,
                subjectActivity: String(
                    state$.value.learnerProfile?.currentLanguage?.languageUUID
                ),
                topic: String(
                    state$.value.learnerProfile?.currentLanguage?.languageUUID
                ),
            };

            return eWritingApi.postEWriting(newEWriting).pipe(
                map((x) => Action.eWriting.sendEWriting.success(x)),
                catchError((e) => of(Action.eWriting.sendEWriting.failure(e)))
            );
        })
    );

const sendingEWritingFailureEpic: RootEpic = (action$, state$, _) => {
    return action$.pipe(
        filter(isActionOf(Action.eWriting.sendEWriting.failure)),
        map(() => {
            return Action.contentSavedActions.setNotification({
                show: true,
                error: true,
                maximum: false,
                text: localization.eWriting_page_alert_error_message,
                key: 10,
            });
        })
    );
};

const setEWritingLimitationEpic: RootEpic = (action$, state$, _) =>
    action$.pipe(
        filter(isActionOf(Action.contracts.loadUserContracts.success)),
        map(() => {
            const eWritingLimitationRule: any =
                state$.value.contracts.userContracts.find(
                    (userContract) =>
                        userContract.type === ContractTypes.EWriting
                )?.limitationRule;

            return Action.eWriting.setEWritingLimitation(
                new EWritingLimitation({
                    postLimitValue: eWritingLimitationRule?.postLimit,
                    postLimitTimeRule: eWritingLimitationRule?.postLimitWindow,
                })
            );
        })
    );

const sendEWritingEpicSuccess: RootEpic = (action$, state$, _) => {
    return action$.pipe(
        filter(isActionOf(Action.eWriting.sendEWriting.success)),
        map(() => {
            const learnerID = state$.value.user?.identity
                ?.learnerUUID as string;
            return Action.eWriting.loadEWritingsList.request({ learnerID });
        })
    );
};

const loadEWritingsListEpic: RootEpic = (action$, state$, { eWritingApi }) =>
    action$.pipe(
        filter(isActionOf(Action.eWriting.loadEWritingsList.request)),
        switchMap((x) => {
            return eWritingApi.loadEwritingList(x.payload.learnerID).pipe(
                map((x) => Action.eWriting.loadEWritingsList.success(List(x))),
                catchError((e) =>
                    of(Action.eWriting.loadEWritingsList.failure(e))
                )
            );
        })
    );

export const eWritingEpics = [
    sendEWritingEpic,
    sendingEWritingFailureEpic,
    setEWritingLimitationEpic,
    loadEWritingsListEpic,
    sendEWritingEpicSuccess,
];
