import {
    TrainingPath,
    CourseCategory,
    CourseStatusDetails,
} from './training-path-record';
import { createReducer, ActionType } from 'typesafe-actions';
import { Action } from '../../root-action';
import { Map, List } from 'immutable';
import { combineReducers } from 'redux';
import { clearAllResources } from '../resources-actions';
import { ResourceContent, ResourceContentProps } from '../resource-content';
import { trainingPathActions } from './training-path-actions';
import { TrainingPathDataLegacyIds } from './training-path-data';
import { LoadingState } from '../resources';
import {
    modifyCourseStatus,
    modifyResourceStatus,
} from '../../../epics/resources/helpers';

export type TrainingPathAction = ActionType<typeof trainingPathActions>;

export type TrainingPathState = Readonly<{
    assignedTP: Map<string, TrainingPath>;
    activitiesTP: List<ResourceContent>;
    tpLoadingState: LoadingState;
    courseCategories: List<CourseCategory>;
    courseCategoriesLoadingInProgress: boolean;
    courses: Map<string, TrainingPath>;
    allCoursesGet: Map<string, TrainingPath>;
    coursesStatuses: CourseStatusDetails[] | null;
    coursesLoadingInProgress: boolean;
    courseLegacyId: TrainingPathDataLegacyIds | null;
    currentlyViewedCourseLoadingInProgress: boolean;
    currentlyViewedCourse: ResourceContentProps | null;
}>;

export const initialState: TrainingPathState = {
    assignedTP: Map<string, TrainingPath>(),
    activitiesTP: List<ResourceContent>(),
    tpLoadingState: 'idle',
    courseCategories: List<CourseCategory>(),
    courseCategoriesLoadingInProgress: false,
    courses: Map<string, TrainingPath>(),
    allCoursesGet: Map<string, TrainingPath>(),
    coursesStatuses: null,
    coursesLoadingInProgress: false,
    courseLegacyId: null,
    currentlyViewedCourseLoadingInProgress: false,
    currentlyViewedCourse: null,
};

export const allCoursesGet = createReducer(
    initialState.allCoursesGet
).handleAction(
    Action.trainingPath.loadAllCourses.success,
    (state, action: any) => {
        return action.payload;
    }
);

export const assignedTP = createReducer(initialState.assignedTP)
    .handleAction(
        [
            Action.trainingPath.loadTrainingPath.success,
            Action.trainingPath.loadAdditionalTrainingPath.success,
            Action.trainingPath.loadAdditionalTrainingPathContentTotal.success,
        ],
        (state, action) => {
            const keyedData = action.payload.map<[string, TrainingPath]>(
                (x) => [x.articleId, x]
            );

            return state.merge(Map(keyedData));
        }
    )
    .handleAction(
        Action.trainingPath.loadCoursesStatuses.success,
        (state, action) => {
            const statuses = action.payload;
            return state && state.map((x) => modifyCourseStatus(statuses)(x));
        }
    )
    .handleAction(
        [clearAllResources, Action.trainingPath.loadTrainingPath.failure],
        (state) => state.clear()
    );

export const activitiesTP = createReducer(initialState.activitiesTP)
    .handleAction(
        Action.trainingPath.loadActivitiesTrainingPath.success,
        (state, action) => state.merge(List(action.payload))
    )
    .handleAction(
        Action.resources.loadInProgressActivities.success,
        (state, action) => {
            const statuses = action.payload;
            return state.map(
                modifyResourceStatus<ResourceContent>(statuses, null)
            );
        }
    )
    .handleAction(
        Action.resources.loadCompletedActivities.success,
        (state, action) => {
            const statuses = action.payload;
            return state.map(
                modifyResourceStatus<ResourceContent>(null, statuses)
            );
        }
    )
    .handleAction(
        [
            Action.trainingPath.loadTrainingPath.success,
            Action.trainingPath.loadCourses.success,
            Action.trainingPath.clearActivitiesTrainingPath,
        ],
        (state) => state.clear()
    );

export const tpLoadingState = createReducer(initialState.tpLoadingState)
    .handleAction(
        Action.trainingPath.loadActivitiesTrainingPath.request,
        () => 'pending' as LoadingState
    )
    .handleAction(
        Action.trainingPath.loadActivitiesTrainingPath.success,
        () => 'succeeded' as LoadingState
    )
    .handleAction(
        Action.trainingPath.loadActivitiesTrainingPath.failure,
        () => 'failed' as LoadingState
    );

export const courseCategories = createReducer(initialState.courseCategories)
    .handleAction(
        Action.trainingPath.loadCourseCategories.success,
        (state, action) => action.payload
    )
    .handleAction(
        [clearAllResources, Action.trainingPath.loadCourseCategories.failure],
        (state) => state.clear()
    );

export const courseCategoriesLoadingInProgress = createReducer(
    initialState.courseCategoriesLoadingInProgress
)
    .handleAction(Action.trainingPath.loadCourseCategories.request, () => true)
    .handleAction(
        [
            Action.trainingPath.loadCourseCategories.success,
            Action.trainingPath.loadCourseCategories.failure,
        ],
        () => false
    );

export const courses = createReducer(initialState.courses)
    .handleAction(Action.trainingPath.loadCourses.success, (state, action) => {
        const keyedData = action.payload.map<[string, TrainingPath]>((x) => [
            x.articleId,
            x,
        ]);

        return Map(keyedData);
    })
    .handleAction(
        Action.trainingPath.loadCoursesStatuses.success,
        (state, action) => {
            const statuses = action.payload;
            return state && state.map((x) => modifyCourseStatus(statuses)(x));
        }
    )
    .handleAction(
        [
            clearAllResources,
            Action.trainingPath.loadCourses.failure,
            Action.trainingPath.clearCourses,
        ],
        (state) => state.clear()
    );

export const coursesStatuses = createReducer(initialState.coursesStatuses)
    .handleAction(
        Action.trainingPath.loadCoursesStatuses.success,
        (state, action) => action.payload
    )
    .handleAction(
        [clearAllResources, Action.trainingPath.resetCoursesStatuses.success],
        () => null
    );

export const coursesLoadingInProgress = createReducer(
    initialState.coursesLoadingInProgress
)
    .handleAction(
        [
            Action.trainingPath.loadCourses.request,
            Action.trainingPath.loadCoursesStatuses.request,
        ],
        () => true
    )
    .handleAction(
        [
            Action.trainingPath.loadCourses.success,
            Action.trainingPath.loadCourses.failure,
            Action.trainingPath.loadCoursesStatuses.success,
            Action.trainingPath.loadCoursesStatuses.failure,
        ],
        () => false
    );

export const currentlyViewedCourse = createReducer(
    initialState.currentlyViewedCourse
).handleAction(
    [Action.trainingPath.loadCurrentlyViewedCourse.success],
    (state, action) => action.payload
);

export const currentlyViewedCourseLoadingInProgress = createReducer(
    initialState.currentlyViewedCourseLoadingInProgress
)
    .handleAction(
        [Action.trainingPath.loadCurrentlyViewedCourse.request],
        () => true
    )
    .handleAction(
        [
            Action.trainingPath.loadCurrentlyViewedCourse.success,
            Action.trainingPath.loadCurrentlyViewedCourse.failure,
        ],
        () => false
    );

export const courseLegacyId = createReducer<TrainingPathDataLegacyIds | null>(
    null
)
    .handleAction(
        Action.trainingPath.loadLegacyCourseId.success,
        (state, action) => action.payload
    )
    .handleAction(
        [clearAllResources, Action.trainingPath.loadLegacyCourseId.failure],
        () => null
    );

export const trainingPathReducer = () =>
    combineReducers<TrainingPathState, TrainingPathAction>({
        assignedTP,
        activitiesTP,
        tpLoadingState,
        courseCategories,
        courseCategoriesLoadingInProgress,
        courses,
        allCoursesGet,
        coursesStatuses,
        coursesLoadingInProgress,
        courseLegacyId,
        currentlyViewedCourseLoadingInProgress,
        currentlyViewedCourse,
    });
