import { isNumber } from 'lodash';

export const checkScore = (score: number | string | undefined) => {
    if (!score) return false;
    if (isNumber(score)) {
        return score > 0;
    } else {
        return score.length > 0;
    }
};
