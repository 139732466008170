import { Observable } from 'rxjs';
import { ContentActivityTrackingSettings } from './content-activity-tracking/content-activity-tracking-settings';
import { ContentActivityEvent } from './content-activity-tracking/content-activity-event';
import { ajax } from 'rxjs/ajax';
import { contentActivity } from './api-urls';
import { map } from 'rxjs/operators';

export interface IContentActivityTrackingApi {
    getSettings(): Observable<ContentActivityTrackingSettings>;

    sendEvent(event: ContentActivityEvent): Observable<any>;
}

export class ContentActivityTrackingApi implements IContentActivityTrackingApi {
    getSettings(): Observable<ContentActivityTrackingSettings> {
        return ajax
            .getJSON<any>(contentActivity() + 'config', {
                'Content-Type': 'application/json',
            })
            .pipe(
                map((response) => {
                    if (response) {
                        return {
                            frequency: response.ping_frequency,
                            timeout: response.ping_timeout,
                        };
                    } else {
                        return ContentActivityTrackingSettings.getDefault();
                    }
                })
            );
    }

    sendEvent(event: ContentActivityEvent) {
        return ajax.post(contentActivity() + 'event', event, {
            'Content-Type': 'application/json',
        });
    }
}
