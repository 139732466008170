import { ApiHeaders } from './api-headers';
import { Observable } from 'rxjs';
import { ajax } from 'rxjs/ajax';
import { map } from 'rxjs/operators';
import {
    CreditCreditAPIRecord,
    CreditDTOCreditAPI,
} from '../store/credits/credits-data';
import { credit } from './api-urls';

export class CreditsApi {
    constructor(private readonly apiHeaders: ApiHeaders) {}

    loadCredits(userUUID: string): Observable<CreditCreditAPIRecord[]> {
        return ajax
            .getJSON<CreditDTOCreditAPI[]>(
                `${credit()}${userUUID}`,
                this.apiHeaders.getHeaders()
            )
            .pipe(
                map((x) =>
                    x.map((x) => {
                        return new CreditCreditAPIRecord(x);
                    })
                )
            );
    }
}
