interface SCORMMessage {
    sc_id: string;
    sc_version: string;
}

function stringToBoolean(str: string | null): boolean {
    return str === 'true';
}

export class ScormUtils {
    static getIdleTimeout(): number {
        const timeout = localStorage.getItem('idleTimeout');

        return Number(timeout ? timeout : 600);
    }

    private static getVersion(): number {
        const defaultVersion = 4;
        const storageVersion =
            localStorage.getItem('version') || defaultVersion;
        return Number(storageVersion);
    }

    private static getLogoutHide(): boolean {
        const storageValue = localStorage.getItem('hideLogoutButton');
        return stringToBoolean(storageValue);
    }

    static getLogoutUrl(): string | null {
        return localStorage.getItem('logoutUrl');
    }

    static showLogout(): boolean {
        const version = Number(this.getVersion());
        const hideLogout = this.getLogoutHide();

        return !hideLogout || version < 3;
    }

    // get SCORM setting throw iframe window message exchange
    static subscribeToGetSCORMSettings(): void {
        window.addEventListener('message', (event) => {
            let msg: SCORMMessage;

            if (typeof event.data === 'string') {
                // eliminate lightstreamer messages
                if (event.data === 'Lightstreamer.run') return;

                try {
                    msg = JSON.parse(event.data);

                    if (msg.sc_id === 'SC_ARCADIA_CS0') {
                        localStorage.setItem('version', msg.sc_version);
                    }
                } catch (e) {
                    console.log(e);
                }
            }
        });
    }

    // send message to parent iframe to logout
    static logout(logout: string): void {
        let msgLogout = {
            exitParam: 'logout',
            logoutCallbackUrl: logout,
        };

        window.parent.postMessage(JSON.stringify(msgLogout), '*');
    }
}
