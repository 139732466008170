import { createReducer } from 'typesafe-actions';
import {
    saveContent,
    setNotification,
    setSavedContent,
    setSavedContentToDisplay,
    unSaveContent,
    getSavedContentItems,
    clearLoadedSavedDisplayContent,
} from './save-content-actions';
import { combineReducers } from 'redux';

export const savedContent = createReducer([]).handleAction(
    setSavedContent,
    (state, action: any) => action.payload
);

export const isContentLoaded = createReducer(false)
    .handleAction(
        [
            getSavedContentItems.request,
            getSavedContentItems.cancel,
            getSavedContentItems.failure,
        ],
        () => false
    )
    .handleAction(getSavedContentItems.success, () => true);

export const isLoadingSavedContentAction = createReducer(false)
    .handleAction([saveContent.request, unSaveContent.request], () => true)
    .handleAction(
        [
            saveContent.success,
            unSaveContent.success,
            saveContent.failure,
            unSaveContent.failure,
        ],
        () => false
    );

export const savedContentToDisplay = createReducer([]).handleAction(
    [setSavedContentToDisplay, clearLoadedSavedDisplayContent],
    (state, action: any) => {
        return action.type === '@@saved-content/load-display-content/clear'
            ? []
            : action.payload;
    }
);

export const getSavedContentNotification = createReducer({
    show: false,
    error: false,
    info: false,
    maximum: false,
    text: '',
    key: 1,
}).handleAction(setNotification, (state, action: any) => action.payload);

export const savedContentReducers = combineReducers({
    savedContent,
    isLoadingSavedContentAction,
    savedContentToDisplay,
    getSavedContentNotification,
    isContentLoaded,
});
