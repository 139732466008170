import { createAction } from 'typesafe-actions';
import { PortalEventType } from './models/portal-event-type';
import { PortalEventCategory } from './models/portal-event-category';

export const trackEvent = createAction('@@track-event')<{
    category: PortalEventCategory;
    type: PortalEventType;
    timestamp: number;
}>();

export const trackerActions = {
    trackEvent,
};
