import { createReducer } from 'typesafe-actions';
import {
    setCurrentDisplayStrategy,
    setLearnerDisplayStrategy,
    setOnboardingStatus,
    setPageDisplay,
    setProgressOnboarding,
} from './learner-onboarding-action';

import {
    OnboardingDisplayStrategyRecord,
    OnboardingStatus,
    ProgressDisplayStrategyRecord,
    StateProgressOnboardingRecord,
} from './learner-onboarding-records';
import { combineReducers } from 'redux';

export const displayStrategy =
    createReducer<OnboardingDisplayStrategyRecord | null>(null).handleAction(
        setLearnerDisplayStrategy,
        (state, action) => action.payload
    );

export const currentDisplayStrategy =
    createReducer<ProgressDisplayStrategyRecord | null>(null).handleAction(
        setCurrentDisplayStrategy,
        (state, action) => action.payload
    );

export const displayStateProgress =
    createReducer<StateProgressOnboardingRecord | null>(null).handleAction(
        setProgressOnboarding,
        (state, action) => action.payload
    );

export const displayModulePage = createReducer<string | null>(
    null
).handleAction(setPageDisplay, (state, action) => action.payload);

export const status = createReducer<OnboardingStatus>('finished').handleAction(
    setOnboardingStatus,
    (state, action) => action.payload.status
);

export const learnerOnboardingReducer = combineReducers({
    displayStrategy,
    displayStateProgress,
    displayModulePage,
    currentDisplayStrategy,
    status,
});
