import { Record } from 'immutable';

export interface DisplayRuleObjectProps {
    component: string;
    display: string;
    order: number;
    subComponent?: string | unknown;
}

export interface OnboardingDisplayStrategyProps {
    displayHandle: string;
    displayRule: DisplayRuleObjectProps[];
}

export const defaultOnboardingDisplayStrategyDataProps: OnboardingDisplayStrategyProps =
    {
        displayHandle: 'onboarding',
        displayRule: [],
    };

export class OnboardingDisplayStrategyRecord
    extends Record(defaultOnboardingDisplayStrategyDataProps)
    implements OnboardingDisplayStrategyProps {}

export const defaultCurrentDisplayStrategy: DisplayRuleObjectProps = {
    component: '',
    display: '',
    order: 0,
    subComponent: '',
};

export class ProgressDisplayStrategyRecord
    extends Record(defaultCurrentDisplayStrategy)
    implements DisplayRuleObjectProps {}

interface OnboardingStateDetailsProps {
    component: string;
    state: string;
    subComponent?: string | unknown;
}

export interface StateProgressProps {
    learnerUUID: string;
    organizationId: number;
    handle: '';
    onboardingStateDetails: OnboardingStateDetailsProps[];
}

export const defualtStateProgress: StateProgressProps = {
    learnerUUID: '',
    organizationId: 0,
    handle: '',
    onboardingStateDetails: [],
};

export class StateProgressOnboardingRecord
    extends Record(defualtStateProgress)
    implements StateProgressProps {}

export type OnboardingStatus = 'start' | 'finished';
