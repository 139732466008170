import { createAction } from 'typesafe-actions';
import { PortalEventType } from '../../services/portal-activity-tracking/portal-event-type';

export const trackEvent = createAction('@@portal-track-event')<{
    type: PortalEventType;
}>();

export const portalTrackerActions = {
    trackEvent,
};
