import { createAction, createAsyncAction } from 'typesafe-actions';
import { Discussion, Message } from './model';
import { OOOMessage } from '../../localization/localization-api';

export const loadDiscussions = createAsyncAction(
    '@@contact-us/load-discussions/request',
    '@@contact-us/load-discussions/success',
    '@@contact-us/load-discussions/failure'
)<void, Discussion[], Error>();

export const updateDiscussions = createAsyncAction(
    '@@contact-us/update-discussions/request',
    '@@contact-us/update-discussions/success',
    '@@contact-us/update-discussions/failure'
)<void, Discussion[], Error>();

export const checkLcTeam = createAsyncAction(
    '@@contact-us/check-lc-team/request',
    '@@contact-us/check-lc-team/success',
    '@@contact-us/check-lc-team/failure'
)<string, string, Error>();

export const setActiveDiscussion = createAction(
    '@@contact-us/set-active-discussion'
)<Discussion | null>();

export const setDiscussionLanguage = createAction(
    '@@contact-us/set-discussion-language'
)<string>();

export const setChatSessionStarted = createAction(
    '@@contact-us/set-chat-session-started'
)<Date | null>();

export const updateMessagesFinish = createAction(
    '@@contact-us/update-messages/finish'
)<{
    discussionId: string;
    preferredLanguage: string;
    messages: Message[];
    newMessagesFound: boolean;
}>();

export const createDiscussion = createAsyncAction(
    '@@contact-us/create-discussion/request',
    '@@contact-us/create-discussion/success',
    '@@contact-us/create-discussion/failure'
)<
    { message: string; preferredLanguage: string },
    { discussion: Discussion; message: string },
    Error
>();

export const updateMessages = createAsyncAction(
    '@@contact-us/update-messages/request',
    '@@contact-us/update-messages/success',
    '@@contact-us/update-messages/failure'
)<
    { discussionId: string; preferredLanguage: string },
    {
        discussionId: string;
        preferredLanguage: string;
        messages: Message[];
        newMessagesFound: boolean;
    },
    Error
>();

export const readMessage = createAsyncAction(
    '@@contact-us/read-message/request',
    '@@contact-us/read-message/success',
    '@@contact-us/read-message/failure'
)<
    {
        discussionId: string;
        preferredLanguage: string;
        messageId: string;
    },
    {
        discussionId: string;
        messageId: string;
    },
    Error
>();

export const sendMessage = createAsyncAction(
    '@@contact-us/send-message/request',
    '@@contact-us/send-message/success',
    '@@contact-us/send-message/failure'
)<
    {
        discussionId: string;
        preferredLanguage: string;
        content: string;
    },
    { discussionId: string; newMessage: Message },
    Error
>();

export const loadOOOMessage = createAsyncAction(
    '@@contact-us/load-ooo-message-i18n/request',
    '@@contact-us/load-ooo-message-i18n/success',
    '@@contact-us/load-ooo-message-i18n/failure'
)<
    {
        preferredLanguage: string;
    },
    OOOMessage,
    Error
>();

export const sendOfficeHoursMessage = createAsyncAction(
    '@@contact-us/send-office-hours-message/request',
    '@@contact-us/send-office-hours-message/success',
    '@@contact-us/send-office-hours-message/failure'
)<
    {
        discussionId: string;
        preferredLanguage: string;
        content: string;
    },
    { discussionId: string; newMessage: Message },
    Error
>();

export const learnerChatActions = {
    loadDiscussions,
    updateDiscussions,
    setActiveDiscussion,
    setChatSessionStarted,
    updateMessagesFinish,
    createDiscussion,
    updateMessages,
    readMessage,
    sendMessage,
    sendOfficeHoursMessage,
    loadOOOMessage,
    setDiscussionLanguage,
    checkLcTeam,
};
