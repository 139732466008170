import { Observable } from 'rxjs';
import { Axios } from 'axios-observable';
import { map } from 'rxjs/operators';
import { ApiHeaders } from '../api-headers';
import { resourceContent } from '../api-urls';
import {
    ResourceContentProps,
    ResourceContentRecord,
} from '../../store/resources/resources-content-record';

export class ContentApi {
    private readonly apiHeaders: ApiHeaders;

    constructor(apiHeaders: ApiHeaders) {
        this.apiHeaders = apiHeaders;
    }

    private getPostHeaders() {
        return {
            ...this.apiHeaders.getHeaders(),
            'Content-Type': 'application/json',
        };
    }

    public loadContent(contentUUID: string): Observable<ResourceContentRecord> {
        return Axios.get<ResourceContentProps>(
            `${resourceContent()}${contentUUID}`,
            { headers: this.getPostHeaders() }
        ).pipe(
            map((x) => {
                return new ResourceContentRecord(x.data);
            })
        );
    }
}
