import {
    createAsyncAction,
    createCustomAction,
    createAction,
} from 'typesafe-actions';
import {
    IChangeLanguageModalProps,
    JWTokenProps,
    LocalizationLanguage,
    UserRecord,
} from './user-record';
import { JWTDto } from '../../services/authentication-api';
import { GetConfigSSO } from '../../components/root/private-route';

export const login = createAsyncAction(
    '@@user/login/request',
    '@@user/login/success',
    '@@user/login/failure'
)<{ email: string; password: string }, UserRecord, Error>();

export const loginSSO = createAsyncAction(
    '@@user/loginSSO/request',
    '@@user/loginSSO/success',
    '@@user/loginSSO/failure'
)<{ token: string }, UserRecord, Error>();

export const loginDingTalk = createAsyncAction(
    '@@user/loginDingTalk/request',
    '@@user/loginDingTalk/success',
    '@@user/loginDingTalk/failure'
)<JWTDto, UserRecord, Error>();

export const setDingTalkUser = createAction(
    '@@user/set-ding-talk-user'
)<UserRecord>();

export const resetPassword = createAsyncAction(
    '@@user/reset-password/request',
    '@@user/reset-password/success',
    '@@user/reset-password/failure'
)<{ email: string }, {}, Error>();

export const demandNewPassword = createAction(
    '@@user/demand-new-password'
)<UserRecord>();

export const setNewPassword = createAsyncAction(
    '@@user/new-password/request',
    '@@user/new-password/success',
    '@@user/new-password/failure'
)<{ newPassword: string }, {}, Error>();

export const logout = createAsyncAction(
    '@@user/logout/request',
    '@@user/logout/success',
    '@@user/logout/failure'
)<void, {}, Error>();

export const changeUILanguage = createAsyncAction(
    '@@user/change-ui-language/request',
    '@@user/change-ui-language/success',
    '@@user/change-ui-language/failure'
)<
    {
        userId: string;
        language: LocalizationLanguage;
        preferredLanguage?: LocalizationLanguage;
        skip?: boolean;
    },
    LocalizationLanguage,
    Error
>();

export const getPreferredLanguagesListDefault = createAsyncAction(
    '@@user/preferred-language-list-default/request',
    '@@user/preferred-language-list-default/success',
    '@@user/preferred-language-list-default/failure'
)<
    {
        learnerPreferences: any;
    },
    any,
    Error
>();

export const getPreferredLanguagesList = createAsyncAction(
    '@@user/preferred-language-list/request',
    '@@user/preferred-language-list/success',
    '@@user/preferred-language-list/failure'
)<
    {
        preferredLangaugeCode: string;
    },
    any,
    Error
>();

export const changePreferredLanguage = createAsyncAction(
    '@@user/change-preferred-language/request',
    '@@user/change-preferred-language/success',
    '@@user/change-preferred-language/failure'
)<
    {
        skip?: boolean;
        userId: string;
        language: LocalizationLanguage;
        displayLanguage?: LocalizationLanguage;
    },
    LocalizationLanguage,
    Error
>();

export const setLearnerPreferrence = createAction(
    '@@user/set-learner-preference'
)();

export const setChangeLanguageModalStatus = createAction(
    '@@user/set-change-language-modal-status'
)<IChangeLanguageModalProps>();

export const loadLanguageList = createAsyncAction(
    '@@user/load-language-list/request',
    '@@user/load-language-list/success',
    '@@user/load-language-list/failure'
)<null, LocalizationLanguage[], Error>();

export const loadPreferredLanguageList = createAsyncAction(
    '@@user/load-preferred-language-list/request',
    '@@user/load-preferred-language-list/success',
    '@@user/load-preferred-language-list/failure'
)<null, LocalizationLanguage[], Error>();

const setResetPasswordSuccessFalse = createCustomAction(
    '@@user/reset-password/set-false'
);

const settingsOpened = createCustomAction('@@user/settings-opened/set-false');

export const closeSession = createAsyncAction(
    '@@user/close-session/request',
    '@@user/close-session/success',
    '@@user/close-session/failure'
)<void, any, Error>();

export const updateSession = createAsyncAction(
    '@@user/update-session/request',
    '@@user/update-session/success',
    '@@user/update-session/failure'
)<void, any, Error>();

export const setRedirectPage = createAction('@@user/set-logout-page')<string>();
export const resetLogoutProcess = createAction('@@user/set-logout-process')();

export const userRenewJWToken = createAsyncAction(
    '@@user/token-renew/request',
    '@@user/token-renew/success',
    '@@user/token-renew/failure'
)<{ authorization: string }, JWTokenProps, Error>();

export const setRenewToken = createAction('@@user/set-token-renew')<boolean>();

export const setIsExpiredToken = createAction(
    '@@user/set-expired-token'
)<boolean>();

export const setSSOLogout = createAction('@@user/sso/logout')<GetConfigSSO>();

export const setIsLoggingIn = createAction('@@user/idp/logging-in')<boolean>();

export const userGenerateToken = createAsyncAction(
    '@@user/jwt-create/request',
    '@@user/jwt-create/success',
    '@@user/jwt-create/failure'
)<{ email: string; password: string }, JWTokenProps, Error>();

export const setUserAES = createAction('@@user/auth/secret')<string>();

export const userActions = {
    login,
    loginSSO,
    loginDingTalk,
    setDingTalkUser,
    logout,
    resetPassword,
    setNewPassword,
    demandNewPassword,
    setResetPasswordSuccessFalse,
    changeUILanguage,
    changePreferredLanguage,
    getPreferredLanguagesList,
    getPreferredLanguagesListDefault,
    setLearnerPreferrence,
    setChangeLanguageModalStatus,
    loadLanguageList,
    loadPreferredLanguageList,
    closeSession,
    updateSession,
    settingsOpened,
    setRedirectPage,
    resetLogoutProcess,
    userRenewJWToken,
    setRenewToken,
    setIsExpiredToken,
    setSSOLogout,
    setIsLoggingIn,
    setUserAES,
    userGenerateToken,
};
