import { createAction, createAsyncAction } from 'typesafe-actions';
import { Homework } from './homework';

export const loadHomeworkList = createAsyncAction(
    '@@homework/load/request',
    '@@homework/load/success',
    '@@homework/load/failure'
)<void, Homework[], Error>();

export const nextKey = createAction('@@homework/nextkey')<number>();

export const loadMoreList = createAction('@@homework/loadmore')<number>();
