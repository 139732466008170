import { combineReducers } from 'redux';
import { List } from 'immutable';
import {
    displayedResourceReducer,
    resourceContentProgressReducer,
    isLocationFrom,
} from './displayed-resource';
import { displayedResourceContentReducer } from './displayed-resource-content';
import { videosReducer } from './videos/videos-reducer';
import { articlesReducer } from './articles/articles-reducer';
import {
    howtosReducer,
    grammarReducer,
    vocabulariesReducer,
    howToCompletedBlocksReducer,
} from './howtos/howto-reducer';
import { translateResourceReducer } from './translate/translate-reducer';
import { inProgressReducer } from './in-progress/in-progress-reducer';
import { offlineReducer } from './offline/offline-reducer';

import { HowtoContent } from './howtos/howto-content';
import { Moment } from 'moment';
import { searchReducer } from './search';
import { ResourceContentType } from './resource-content-type';
import { ResourceContent } from './resource-content';
import { recommended } from './recommended';
import { completedReducer } from './completed/completed-reducer';
import { ContentMetadataProps } from './resources-content-record';
import { channelReducer } from './channel/channel-reducers';

export type LoadingState = 'idle' | 'pending' | 'succeeded' | 'failed';

export interface ResourceDescription {
    contentMetadata: ContentMetadataProps | null;
    resourceId: string;
    thumbnail?: string | null;
    title: string;
    date: Moment;
    tags: List<string>;
    categories: List<string>;
    index: number;
    groupId: number;
    content: HowtoContent | null;
    quizArticleId: string | null;
    type: ResourceContentType;
    contentUUId: string;
    quizUUID: string;
    template: string;
    topicUUID: string;
}

export type ResourceState = Readonly<{
    descriptions: List<ResourceContent>;
    filtered: List<ResourceContent>;
    loadingState: LoadingState;
}>;

export const initialResourceState: ResourceState = {
    descriptions: List<ResourceContent>(),
    filtered: List<ResourceContent>(),
    loadingState: 'idle',
};

export const resourcesReducer = () =>
    combineReducers({
        articles: articlesReducer(),
        videos: videosReducer(),
        howtos: howtosReducer(),
        grammar: grammarReducer(),
        vocabularies: vocabulariesReducer(),
        howToCompletedBlocks: howToCompletedBlocksReducer(),
        displayedResource: displayedResourceReducer,
        displayedResourceContent: displayedResourceContentReducer,
        displayTranslatedResource: translateResourceReducer(),
        search: searchReducer,
        recommended: recommended,
        resourceContentProgress: resourceContentProgressReducer,
        isLocationFrom: isLocationFrom,
        inProgress: inProgressReducer(),
        completed: completedReducer(),
        offline: offlineReducer(),
        channel: channelReducer(),
    });
