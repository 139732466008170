import { createAsyncAction } from 'typesafe-actions';
import { VcrLinkHashData, vcrFetchMessageProps } from './persist-message-data';
import { List } from 'immutable';

export const vcrFetchMessage = createAsyncAction(
    '@@classroom/message-fetch/request',
    '@@classroom/message-fetch/success',
    '@@classroom/message-fetch/failure'
)<vcrFetchMessageProps, List<VcrLinkHashData>, Error>();

const persistMessageActions = {
    vcrFetchMessage,
};
export default persistMessageActions;
