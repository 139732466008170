import { createReducer } from 'typesafe-actions';
import { combineReducers } from 'redux';
import { Action } from '../root-action';

const currentAppWidth = createReducer(0).handleAction(
    Action.app.changeAppWindowWidth,
    (state, { payload }) => {
        return payload;
    }
);

const currentAppMobileState = createReducer(false).handleAction(
    Action.app.changeAppMobileState,
    (state, { payload }) => {
        return payload;
    }
);

export const appReducer = () =>
    combineReducers({
        currentAppWidth,
        currentAppMobileState,
    });
