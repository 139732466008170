import { RootEpic } from './root-epic';
import { catchError, filter, map, switchMap } from 'rxjs/operators';
import { isActionOf } from 'typesafe-actions';
import { Action } from '../store/root-action';
import { of } from 'rxjs';
import { userActions } from '../store/user/user-actions';

export const loadDisplayStrategyConditionEpic: RootEpic = (action$) =>
    action$.pipe(
        filter(
            isActionOf([
                userActions.login.success,
                userActions.loginSSO.success,
                userActions.loginDingTalk.success,
                userActions.setNewPassword.success,
            ])
        ),
        map(() => {
            return Action.displayStrategy.loadDisplayStrategy.request(
                'navigation-restriction'
            );
        })
    );

export const loadDisplayStrategy: RootEpic = (
    action$,
    state$,
    { displayStrategyApi }
) =>
    action$.pipe(
        filter(isActionOf(Action.displayStrategy.loadDisplayStrategy.request)),
        switchMap((x) => {
            const userUUID = state$.value.user?.identity?.learnerUUID as string;

            return displayStrategyApi
                .loadDisplayStrategy({ displayHandle: x.payload, userUUID })
                .pipe(
                    map((x) => {
                        return Action.displayStrategy.loadDisplayStrategy.success(
                            x
                        );
                    }),
                    catchError((e) =>
                        of(
                            Action.displayStrategy.loadDisplayStrategy.failure(
                                e
                            )
                        )
                    )
                );
        })
    );

export const loadDisplayStrategyForCustomizationEpic: RootEpic = (
    action$,
    state$,
    { displayStrategyApi }
) =>
    action$.pipe(
        filter(
            isActionOf([
                userActions.login.success,
                userActions.loginSSO.success,
                userActions.loginDingTalk.success,
                userActions.setNewPassword.success,
            ])
        ),
        switchMap((x) => {
            const userUUID = state$.value.user?.identity?.learnerUUID as string;

            return displayStrategyApi
                .loadDisplayStrategy({
                    displayHandle: 'customized-display',
                    userUUID,
                })
                .pipe(
                    map((x) =>
                        Action.displayStrategy.loadDisplayStrategy.success(x)
                    ),
                    catchError((e) =>
                        of(
                            Action.displayStrategy.loadDisplayStrategy.failure(
                                e
                            )
                        )
                    )
                );
        })
    );

export const displayStrategyEpics = [
    loadDisplayStrategy,
    loadDisplayStrategyConditionEpic,
    loadDisplayStrategyForCustomizationEpic,
];
