import { LoginForm as LoginFormUI } from '@arcadia-projects/portal2-ui-library';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { localization } from '../../localization';
import { Action } from '../../store/root-action';
import {
    isLoadingDisplayLanguageOnLogin,
    isLoggingIn,
    loginError,
    resetPasswordSuccess,
} from '../../store/user/user-selectors';
import { encryptText } from '../../utils/crypto';
import { useAppDispatch } from '../root/app-dispatch';
import { UseSSO } from './lib/helpers';

export const LoginForm = () => {
    const history = useHistory();
    const dispatch = useAppDispatch();

    const isSigningIn = useSelector(isLoggingIn);
    const isLoadingLanguage = useSelector(isLoadingDisplayLanguageOnLogin);
    const loginErrorText = useSelector(loginError);

    const [submitted, setSubmitted] = useState(false);
    const [loginType, setLoginType] = useState<string | null>(null);
    const [ssoErrorText, setSsoErrorText] = useState<string | null>(null);
    const [ssoLoadingText, setSsoLoadingText] = useState<string | null>(null);
    const [showResetPasswordSuccess, setShowResetPasswordSuccess] = useState(
        useSelector(resetPasswordSuccess)
    );

    const { displaySSO } = UseSSO({
        setLoginType,
        setSsoErrorText,
        setSsoLoadingText,
    });

    const onLoginSubmit = useCallback(
        (login, password) => {
            const encryptedCreds = encryptText(`${login} ${password}`);
            setLoginType('regular');
            setShowResetPasswordSuccess(false);
            setSubmitted(true);
            dispatch(Action.user.login.request({ email: login, password }));
            dispatch(Action.user.setUserAES(encryptedCreds));
        },
        [dispatch]
    );

    useEffect(() => {
        if (showResetPasswordSuccess) {
            dispatch(Action.user.setResetPasswordSuccessFalse());
        }
    }, [showResetPasswordSuccess, dispatch]);

    const displayErrorText = useMemo(() => {
        if (loginType) {
            if (
                loginType === 'regular' &&
                loginErrorText &&
                !isSigningIn &&
                submitted
            ) {
                return loginErrorText ===
                    localization.login_credentials_disabled
                    ? ''
                    : loginErrorText;
            } else if (loginType === 'sso' && ssoErrorText) {
                return ssoErrorText;
            }
        }
        return '';
    }, [loginType, isSigningIn, loginErrorText, submitted, ssoErrorText]);

    const displayWarningText = useMemo(() => {
        if (loginType) {
            if (
                loginType === 'regular' &&
                loginErrorText &&
                !isSigningIn &&
                submitted
            ) {
                return loginErrorText ===
                    localization.login_credentials_disabled
                    ? loginErrorText
                    : '';
            }
        }
        return '';
    }, [loginType, isSigningIn, loginErrorText, submitted]);

    const displayLoadingText = useMemo(() => {
        if (isSigningIn || isLoadingLanguage) {
            return localization.login_label_SignInLoader;
        } else if (ssoLoadingText) {
            return ssoLoadingText;
        }

        return '';
    }, [isSigningIn, isLoadingLanguage, ssoLoadingText]);

    return (
        <LoginFormUI
            emailPlaceholder={localization.login_placeholder_EnterEmail}
            passwordPlaceholder={localization.login_placeholder_EnterPassword}
            loginText={localization.login_button_SignIn}
            warningText={displayWarningText}
            onSubmit={onLoginSubmit}
            loadingText={displayLoadingText}
            errorText={displayErrorText}
            successText={
                showResetPasswordSuccess
                    ? localization.login_message_ResetPasswordSuccess
                    : ''
            }
            forgot={{
                text: localization.login_link_ForgotPassword,
                onForgotClick: () => history.push('/login/forgot-password'),
            }}
            {...displaySSO}
        />
    );
};
