type BaseType = 'numeric' | '2-digit';
type MonthType = 'numeric' | '2-digit' | 'narrow' | 'short' | 'long';

export const messageDateParser = (
    dateISOstring: string,
    languageId?: string
) => {
    const fullDate = new Date(dateISOstring);
    const dateOptions = {
        day: 'numeric' as BaseType,
        month: 'short' as MonthType,
    };
    const timeOptions = {
        hour: 'numeric' as BaseType,
        minute: 'numeric' as BaseType,
    };
    const locale = !!languageId ? languageId : 'en';
    const date = fullDate.toLocaleDateString(locale, dateOptions);
    const month = date.slice(0, 3);
    const day = date.slice(4, date.length);
    const time = fullDate.toLocaleTimeString(locale, timeOptions);
    const dateTime = day + ' ' + month + ', ' + time;

    return dateTime;
};
