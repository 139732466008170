import {
    UserCompletedHowToBlock,
    UserCompletedHowToSection,
} from './user-completed-block';
import { ActionType, createReducer } from 'typesafe-actions';
import { List, Map } from 'immutable';
import {
    grammarActions,
    howToActions,
    howToCompletedBlockActions,
    vocabularyActions,
} from './howto-actions';
import { combineReducers } from 'redux';
import { ResourceContent } from '../resource-content';
import { LoadingState, ResourceState } from '../resources';
import { Action } from '../../root-action';
import { modifyResourceStatus } from '../../../epics/resources/helpers';

export type HowToAction = ActionType<typeof howToActions>;
export type GrammarAction = ActionType<typeof grammarActions>;
export type VocabularyAction = ActionType<typeof vocabularyActions>;

export type HowToCompletedBlockState = Readonly<{
    blocks: Map<string, UserCompletedHowToBlock>;
}>;

export const initialHowToCompletedBlockState: HowToCompletedBlockState = {
    blocks: Map<string, UserCompletedHowToBlock>(),
};

export type HowToCompletedBlockAction = ActionType<
    typeof howToCompletedBlockActions
>;

// How-tos
const descriptionsHowTo = createReducer(List<ResourceContent>())
    .handleAction(howToActions.loadHowtos.success, (state, action) => {
        return state.merge(List(action.payload));
    })
    .handleAction(howToActions.clearAllResources, (state) => state.clear())
    .handleAction(
        Action.resources.loadInProgressActivities.success,
        (state, action) => {
            const statuses = action.payload;
            return state.map(
                modifyResourceStatus<ResourceContent>(statuses, null)
            );
        }
    )
    .handleAction(
        Action.resources.loadCompletedActivities.success,
        (state, action) => {
            const statuses = action.payload;
            return state.map(
                modifyResourceStatus<ResourceContent>(null, statuses)
            );
        }
    )
    .handleAction(howToActions.setHowtoFilterItems, (state, action) => {
        return action.payload;
    });

const filteredHowTo = createReducer(List<ResourceContent>())
    .handleAction(howToActions.loadFilteredHowtos.success, (state, action) => {
        return state.merge(List(action.payload));
    })
    .handleAction(howToActions.resetHowtos, (_, action) => action.payload)
    .handleAction(
        [howToActions.clearHowtos, howToActions.clearAllResources],
        (state) => state.clear()
    )
    .handleAction(
        Action.resources.loadInProgressActivities.success,
        (state, action) => {
            const statuses = action.payload;
            return state.map(
                modifyResourceStatus<ResourceContent>(statuses, null)
            );
        }
    )
    .handleAction(
        Action.resources.loadCompletedActivities.success,
        (state, action) => {
            const statuses = action.payload;
            return state.map(
                modifyResourceStatus<ResourceContent>(null, statuses)
            );
        }
    );

const loadingStateHowTo = createReducer('idle' as LoadingState)
    .handleAction(
        [
            howToActions.loadHowtos.request,
            howToActions.loadFilteredHowtos.request,
        ],
        () => 'pending' as LoadingState
    )
    .handleAction(
        [
            howToActions.loadHowtos.success,
            howToActions.loadFilteredHowtos.success,
        ],
        () => 'succeeded' as LoadingState
    )
    .handleAction(
        [
            howToActions.loadHowtos.failure,
            howToActions.loadFilteredHowtos.failure,
        ],
        () => 'failed' as LoadingState
    );

export const howtosReducer = () =>
    combineReducers<ResourceState, HowToAction>({
        descriptions: descriptionsHowTo,
        filtered: filteredHowTo,
        loadingState: loadingStateHowTo,
    });

// Grammar
const descriptionsGrammar = createReducer(List<ResourceContent>())
    .handleAction(grammarActions.loadGrammar.success, (state, action) => {
        return state.merge(List(action.payload));
    })
    .handleAction(grammarActions.clearAllResources, (state) => state.clear())
    .handleAction(
        Action.resources.loadInProgressActivities.success,
        (state, action) => {
            const statuses = action.payload;
            return state.map(
                modifyResourceStatus<ResourceContent>(statuses, null)
            );
        }
    )
    .handleAction(
        Action.resources.loadCompletedActivities.success,
        (state, action) => {
            const statuses = action.payload;
            return state.map(
                modifyResourceStatus<ResourceContent>(null, statuses)
            );
        }
    );
const filteredGrammar = createReducer(List<ResourceContent>())
    .handleAction(
        grammarActions.loadFilteredGrammar.success,
        (state, action) => {
            return state.merge(List(action.payload));
        }
    )
    .handleAction(grammarActions.resetGrammar, (_, action) => action.payload)
    .handleAction(
        [grammarActions.clearGrammar, grammarActions.clearAllResources],
        (state) => state.clear()
    )
    .handleAction(
        Action.resources.loadInProgressActivities.success,
        (state, action) => {
            const statuses = action.payload;
            return state.map(
                modifyResourceStatus<ResourceContent>(statuses, null)
            );
        }
    )
    .handleAction(
        Action.resources.loadCompletedActivities.success,
        (state, action) => {
            const statuses = action.payload;
            return state.map(
                modifyResourceStatus<ResourceContent>(null, statuses)
            );
        }
    );

const loadingStateGrammar = createReducer('idle' as LoadingState)
    .handleAction(
        [
            grammarActions.loadGrammar.request,
            grammarActions.loadFilteredGrammar.request,
        ],
        () => 'pending' as LoadingState
    )
    .handleAction(
        [
            grammarActions.loadGrammar.success,
            grammarActions.loadFilteredGrammar.success,
        ],
        () => 'succeeded' as LoadingState
    )
    .handleAction(
        [
            grammarActions.loadGrammar.failure,
            grammarActions.loadFilteredGrammar.failure,
        ],
        () => 'failed' as LoadingState
    );

export const grammarReducer = () =>
    combineReducers<ResourceState, GrammarAction>({
        descriptions: descriptionsGrammar,
        filtered: filteredGrammar,
        loadingState: loadingStateGrammar,
    });

// Vocabulary
const vocabulariesDescriptions = createReducer(List<ResourceContent>())
    .handleAction(
        vocabularyActions.loadVocabularies.success,
        (state, action) => {
            return state.merge(List(action.payload));
        }
    )
    .handleAction(vocabularyActions.clearAllResources, (state) => state.clear())
    .handleAction(
        Action.resources.loadInProgressActivities.success,
        (state, action) => {
            const statuses = action.payload;
            return state.map(
                modifyResourceStatus<ResourceContent>(statuses, null)
            );
        }
    )
    .handleAction(
        Action.resources.loadCompletedActivities.success,
        (state, action) => {
            const statuses = action.payload;
            return state.map(
                modifyResourceStatus<ResourceContent>(null, statuses)
            );
        }
    );

const filteredVocabularies = createReducer(List<ResourceContent>())
    .handleAction(
        vocabularyActions.loadFilteredVocabularies.success,
        (state, action) => {
            return state.merge(List(action.payload));
        }
    )
    .handleAction(
        vocabularyActions.resetVocabularies,
        (_, action) => action.payload
    )
    .handleAction(
        [
            vocabularyActions.clearVocabularies,
            vocabularyActions.clearAllResources,
        ],
        (state) => state.clear()
    )
    .handleAction(
        Action.resources.loadInProgressActivities.success,
        (state, action) => {
            const statuses = action.payload;
            return state.map(
                modifyResourceStatus<ResourceContent>(statuses, null)
            );
        }
    )
    .handleAction(
        Action.resources.loadCompletedActivities.success,
        (state, action) => {
            const statuses = action.payload;
            return state.map(
                modifyResourceStatus<ResourceContent>(null, statuses)
            );
        }
    );
const loadingStateVocabularies = createReducer('idle' as LoadingState)
    .handleAction(
        [
            vocabularyActions.loadVocabularies.request,
            vocabularyActions.loadFilteredVocabularies.request,
        ],
        () => 'pending' as LoadingState
    )
    .handleAction(
        [
            vocabularyActions.loadVocabularies.success,
            vocabularyActions.loadFilteredVocabularies.success,
        ],
        () => 'succeeded' as LoadingState
    )
    .handleAction(
        [
            vocabularyActions.loadVocabularies.failure,
            vocabularyActions.loadFilteredVocabularies.failure,
        ],
        () => 'failed' as LoadingState
    );

export const vocabulariesReducer = () =>
    combineReducers<ResourceState, VocabularyAction>({
        descriptions: vocabulariesDescriptions,
        filtered: filteredVocabularies,
        loadingState: loadingStateVocabularies,
    });

const howToCompletedBlockReducer = createReducer(
    Map<string, UserCompletedHowToBlock>()
)
    .handleAction(
        howToCompletedBlockActions.loadCompletedBlock.success,
        saveCompletedBlockToStore
    )
    .handleAction(
        howToCompletedBlockActions.saveCompletedBlock.success,
        saveCompletedBlockToStore
    )
    .handleAction(
        howToCompletedBlockActions.updateCompletedBlock.success,
        saveCompletedSectionToStore
    )
    .handleAction(howToCompletedBlockActions.clearAllResources, (state) =>
        state.clear()
    );

export const howToCompletedBlocksReducer = () =>
    combineReducers<HowToCompletedBlockState, HowToCompletedBlockAction>({
        blocks: howToCompletedBlockReducer,
    });

function saveCompletedBlockToStore(
    state: Map<string, UserCompletedHowToBlock>,
    action: {
        payload: {
            resource: { resourceId: number };
            block: UserCompletedHowToBlock;
        };
    }
) {
    const { resource, block } = action.payload;
    return state.set(String(resource.resourceId), block);
}

function saveCompletedSectionToStore(
    state: Map<string, UserCompletedHowToBlock>,
    action: {
        payload: {
            resource: { resourceId: number };
            section: UserCompletedHowToSection;
        };
    }
) {
    const { resource, section } = action.payload;
    const existingBlock = state.get(String(resource.resourceId));

    if (!existingBlock) {
        return state;
    }

    existingBlock.sections.push(section);

    return state.set(
        String(resource.resourceId),
        existingBlock.withMutations((x) => x.set('sections', x.sections))
    );
}
