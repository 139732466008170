import { ApiHeaders } from '../api-headers';
import { catchError, concatAll, concatMap, map } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { Axios } from 'axios-observable';
import { activityReport, adaptiveTest, contentService } from '../api-urls';
import { InProgressResource } from '../../store/resources/in-progress/in-progress-record';
import {
    CoursesDTO,
    CoursesProps,
    CurriculumProps,
    PlacementTestDTO,
    PlacementTestProps,
} from '../../store/in-progress/in-progress-record';
import { AxiosResponse } from 'axios';

export class InProgressApi {
    constructor(private readonly apiHeaders: ApiHeaders) {}

    private getPostHeaders() {
        return {
            ...this.apiHeaders.getHeaders(),
            'Content-Type': 'application/json',
        };
    }

    loadActivityList(userUUID: string): Observable<InProgressResource[]> {
        const url = activityReport() + `in-progress/${userUUID}`;
        return Axios.get<InProgressResource[]>(url, {
            headers: this.getPostHeaders(),
        }).pipe(
            map((x) => {
                return x.data.map((item) => new InProgressResource(item));
            })
        );
    }

    loadCoursesList(userUUID: string): Observable<CoursesDTO[]> {
        const url_curriculum = `${activityReport()}curriculum/${userUUID}?lessonStatus=IN_PROGRESS`;
        const url_courses = `${contentService()}content/bulk`;

        const curriculumRequest = Axios.get<CurriculumProps[]>(url_curriculum, {
            headers: this.getPostHeaders(),
        }).pipe(
            map((x) => {
                const uuids = x.data
                    .filter((item) => item.progressStatus === 'IN_PROGRESS')
                    .map((item) => item.curriculumId as string);

                return Axios.post<CoursesProps[]>(url_courses, uuids, {
                    headers: this.getPostHeaders(),
                }).pipe(
                    map((y) => {
                        return y.data.map((item) => {
                            const curriculumData = x.data.find(
                                (curriculum) =>
                                    curriculum.curriculumId === item.id
                            );
                            if (curriculumData) {
                                item.curriculumId = curriculumData.curriculumId;
                                item.progressStatus =
                                    curriculumData.progressStatus;
                                item.completedContents =
                                    curriculumData.completedContents;
                                item.totalContents =
                                    curriculumData.totalContents;
                                item.percentsToComplete =
                                    curriculumData.percentsToComplete;
                                item.curriculumScoreAvg =
                                    curriculumData.curriculumScoreAvg;
                                item.endTimeStamp = curriculumData.endTimeStamp;
                            }
                            return new CoursesDTO(item);
                        });
                    }),
                    catchError((e) => {
                        console.log(e);
                        throw e;
                    })
                );
            }),
            catchError((e) => {
                console.log(e);
                throw e;
            })
        );

        return curriculumRequest.pipe(concatAll());
    }

    // loadCoursesList(
    //     uuids: string[],
    // ): Observable<CoursesProps[]> {
    //     let url = contentService() + `bulk`;
    //
    //     return Axios.post<CoursesProps[]>(url, uuids,
    //         {
    //             headers: this.getPostHeaders()
    //         }).pipe(map((x) => x.data));
    // }

    loadTestsList(
        userUUID: string,
        topic: string
    ): Observable<PlacementTestDTO[]> {
        const url =
            adaptiveTest() +
            `${userUUID}/progress?testStatus=Incomplete&testType=PlacementTest`;
        return Axios.get<PlacementTestProps[]>(url, {
            headers: this.getPostHeaders(),
        }).pipe(
            concatMap((x) => {
                if (x.data.length > 0) {
                    return of(x.data.map((item) => new PlacementTestDTO(item)));
                } else {
                    return Axios.get<PlacementTestProps[]>(
                        adaptiveTest() + `${topic}/${userUUID}/last`,
                        {
                            headers: this.getPostHeaders(),
                        }
                    ).pipe(
                        map((y: AxiosResponse<any>) => {
                            const fakeResults = [
                                {
                                    completionDate: '',
                                    grading: '',
                                    testStatus: y.data.status,
                                    testType: y.data.testType,
                                    topic: topic,
                                },
                            ];
                            return fakeResults.map(
                                (item) => new PlacementTestDTO(item)
                            );
                        }),
                        catchError((e) => {
                            console.log(e);
                            // throw e;
                            return of(
                                x.data.map((item) => new PlacementTestDTO(item))
                            );
                        })
                    );
                }
            }),
            catchError((e) => {
                console.log(e);
                throw e;
            })
        );
    }

    // loadEwritingsList(
    //     learnerUUID: string,
    // ): Observable<EwritingListItem[]> {
    //     let url = eWriting() + `learner/list/${learnerUUID}`;
    //
    //     return Axios.get<any>(url, {
    //         headers: this.getPostHeaders()
    //     }).pipe(map((r) => {
    //             return r.data
    //                 .sort((i: any, j: any) => {
    //                     return -(
    //                         moment(i.submitDate).unix() -
    //                         moment(j.submitDate).unix()
    //                     );
    //                 })
    //                 .map((l: any) => {
    //                     return new EwritingListItem({
    //                         assignDateTime: l.assignDate
    //                             ? moment(l.assignDate)
    //                             : moment(),
    //                         completeDateTime: l.completeDate
    //                             ? moment(l.completeDate)
    //                             : moment(),
    //                         submitDateTime: l.submitDate
    //                             ? moment(l.submitDate)
    //                             : moment(),
    //                         homeworkActivity: l.homeworkActivity,
    //                         ewritingUUID: l.id,
    //                         state: ((l.state as keyof typeof EwritingState)
    //                             ? (l.state as keyof typeof EwritingState)
    //                             : EwritingState.Submitted) as EwritingState,
    //                         teacher: new EwritingTeacher({
    //                             teacherUUID: l.trainer,
    //                         }),
    //                         learner: new EwritingLearner(),
    //                         learnerTextHtml: l.learnerTextHtml,
    //                         trainerTextHtml: l.trainerTextHtml,
    //                         subject: l.subject,
    //                         topic: l.topic,
    //                     });
    //                 })
    //         }),
    //         catchError((e) => {
    //             console.log(e);
    //             throw e;
    //         })
    //     );
    // }
}
