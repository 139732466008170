import { IStorage } from './types';
import { debounce } from '../debounce';

const defaultLifetime = 2 * 60 * 1000;

export function setupLifetime(
    cache: IStorage,
    lifetime: number = defaultLifetime
) {
    const debouncedClear = debounce(() => cache.clear(), lifetime);

    const original = cache.setItem;
    cache.setItem = function (...args: [any, any]) {
        original.apply(cache, args);

        debouncedClear();
    };

    return cache;
}
