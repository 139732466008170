import { createAction, createAsyncAction } from 'typesafe-actions';
import { CountryCodeData, InternetCallData } from './call-data';
import { List } from 'immutable';

const getCountryCodes = createAsyncAction(
    '@@classroom/call/country-codes/request',
    '@@classroom/call/country-codes/success',
    '@@classroom/call/country-codes/failure'
)<void, List<CountryCodeData>, Error>();

const validateNumber = createAsyncAction(
    '@@classroom/call/validate-number/request',
    '@@classroom/call/validate-number/success',
    '@@classroom/call/validate-number/failure'
)<{ countryId: number; number: string }, boolean, Error>();

const resetValidation = createAction(
    '@@classroom/call/reset-validation'
)<void>();

const startCallback = createAsyncAction(
    '@@classroom/call/start-callback/request',
    '@@classroom/call/start-callback/success',
    '@@classroom/call/start-callback/failure'
)<string, void, Error>();

const validateInternetCall = createAsyncAction(
    '@@classroom/call/validate-sip/request',
    '@@classroom/call/validate-sip/success',
    '@@classroom/call/validate-sip/failure'
)<void, InternetCallData, Error>();

const startInternetCall = createAsyncAction(
    '@@classroom/call/start-sip/request',
    '@@classroom/call/start-sip/success',
    '@@classroom/call/start-sip/failure'
)<string, void, Error>();

const callActions = {
    getCountryCodes,
    validateNumber,
    resetValidation,
    startCallback,
    validateInternetCall,
    startInternetCall,
};

export default callActions;
