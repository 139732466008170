import { createAction, createReducer, RootState } from 'typesafe-actions';

export const setMuted = createAction('@@autoplay/set-muted')<boolean>();

export const mutedReducer = createReducer(true).handleAction(
    setMuted,
    (state, action) => action.payload
);

export const mutedSelector = (state: RootState) => state.muted;
