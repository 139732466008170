import { RootState } from 'typesafe-actions';
import { createSelector } from 'reselect';
import { LearnerProfileState } from './learner-profile-reducers';
import { getCurrentUILanguage } from '../user/user-selectors';
import { TranslatedArray } from './learner-profile-records';

export const learnerProfileSelector = (state: RootState) =>
    state.learnerProfile as LearnerProfileState;

export const learnerProfileUUIDSelector = createSelector(
    learnerProfileSelector,
    (state) => state.profile?.learnerUUID
);

export const languageListSelector = createSelector(
    learnerProfileSelector,
    (state) => state.languageList
);

export const isProfileChangesOnLoadingSelector = createSelector(
    learnerProfileSelector,
    (state) => state.isProfileChangesOnLoading
);

export const currentLanguageIdSelector = createSelector(
    learnerProfileSelector,
    (state) => state.currentLanguage && state.currentLanguage.languageUUID
);

export const currentLanguageCodeSelector = createSelector(
    learnerProfileSelector,
    (state) => state.currentLanguage && state.currentLanguage.code
);

export const isRtlViewByTopicSelector = createSelector(
    (s: RootState) => {
        return s.learnerProfile?.currentLanguage;
    },
    (language) => {
        if (!language) return;
        return (
            language?.code.toLowerCase() === 'ar' ||
            language?.code.toLowerCase() === 'he'
        );
    }
);

export const currentLanguageNameSelector = createSelector(
    learnerProfileSelector,
    (state) => state.currentLanguage && state.currentLanguage.name
);

export const localizedCurrentLanguageNameSelector = createSelector(
    currentLanguageCodeSelector,
    currentLanguageNameSelector,
    getCurrentUILanguage,
    (code, currentLanguage, currentUILanguage) => {
        if (code) {
            return `language_label_${code}`;
        }

        return currentLanguage || '';
    }
);

export const currentLearningLanguagePreferenceSelector = createSelector(
    currentLanguageCodeSelector,
    currentLanguageIdSelector,
    (code, id) => ({
        code,
        name: `language_label_${code}`,
        value: id,
    })
);

export const currentLanguageTypeSelector = createSelector(
    learnerProfileSelector,
    (state) => state.currentLanguage && state.currentLanguage.type
);

export const industryListSelector = createSelector(
    learnerProfileSelector,
    (state) => state.industryList
);

export const currentIndustrySelector = createSelector(
    learnerProfileSelector,
    (state) => state.currentIndustry && state.currentIndustry.industryList
);

export const localizedCurrentIndustrySelector = createSelector(
    currentIndustrySelector,
    getCurrentUILanguage,
    (list, language) => localizeArray(list, language.id)
);

export const jobFunctionListSelector = createSelector(
    learnerProfileSelector,
    (state) => state.jobFunctionList
);

export const currentJobfunctionSelector = createSelector(
    learnerProfileSelector,
    (state) =>
        state.currentJobFunction && state.currentJobFunction.jobFunctionList
);

export const localizedCurrentJobfunctionSelector = createSelector(
    currentJobfunctionSelector,
    getCurrentUILanguage,
    (list, language) => localizeArray(list, language.id)
);

export const getLearnerProfile = createSelector(
    learnerProfileSelector,
    (state) => state.profile
);

export const isVcrAvailableByLanguage = createSelector(
    currentLanguageTypeSelector,
    (type) => type === 'vcr'
);

const localizeArray = (array: TranslatedArray[] | null, languageId: string) => {
    if (array) {
        return array.map((item) => {
            const translatedItem = item.translations.find(
                (translation) => translation.locale === languageId
            );

            return translatedItem ? translatedItem.label : item.name;
        });
    }

    return array;
};
