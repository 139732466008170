import { createAction, createAsyncAction } from 'typesafe-actions';
import {
    AccessTokenResDto,
    AllocatedVirtualClassDto,
    VcrClassProps,
    VirtualClassDto,
} from './classroom-list-data';

export const loadAvailableClasses = createAsyncAction(
    '@@vcr/available-classes/request',
    '@@vcr/available-classes/success',
    '@@vcr/available-classes/failure'
)<boolean | void, VirtualClassDto[] | null, Error>();

export const joinVCR = createAsyncAction(
    '@@vcr/join/request',
    '@@vcr/join/success',
    '@@vcr/join/failure'
)<
    { virtualClassId: string; type?: string; attendeeType?: string },
    AllocatedVirtualClassDto,
    Error
>();

export const getVCRToken = createAsyncAction(
    '@@vcr/get-token/request',
    '@@vcr/get-token/success',
    '@@vcr/get-token/failure'
)<
    {
        chatExternalRoomSid: string;
        groupExternalRoomSid: string;
    },
    AccessTokenResDto,
    Error
>();

export const closeVCRFullModal = createAction('@@vcr/close-full-modal')();

export const clearJoinedClassError = createAction('@@vcr/clear-joined-error')();

export const loadActiveClassroom = createAsyncAction(
    '@@vcr/active-classroom/request',
    '@@vcr/active-classroom/success',
    '@@vcr/active-classroom/failure'
)<number, VcrClassProps, Error>();

export const clearConversation = createAction('@@vcr/clear-conversation')();

export const isVCRCompatible = createAction('@@vcr/is-compatible')<boolean>();

export const setVCRFullModal = createAction('@@vcr/set-full-modal')<boolean>();

const classroomListActions = {
    closeVCRFullModal,
    clearJoinedClassError,
    loadAvailableClasses,
    joinVCR,
    getVCRToken,
    loadActiveClassroom,
    clearConversation,
    isVCRCompatible,
    setVCRFullModal,
};
export default classroomListActions;
