import { List, Map, Record } from 'immutable';
import Moment from 'moment';
import { ResourceDescription } from '../resources';
import { WordDescriptionProps } from './word-description';
import { ResourceContentType } from '../resource-content-type';

export interface ArticleDescriptionProps extends ResourceDescription {
    learn: string;
    textToSpeachUrl: string | null;
    description: string;
    source: string | null;
    wordDescriptions: Map<string, WordDescriptionProps>;
    template: string;
}

const defaultArticleDescriptionProps: ArticleDescriptionProps = {
    contentMetadata: null,
    resourceId: '',
    groupId: 0,
    categories: List(),
    date: Moment.invalid(),
    index: 0,
    tags: List(),
    thumbnail: null,
    title: '',
    quizArticleId: null,
    content: null,
    learn: '',
    textToSpeachUrl: null,
    type: ResourceContentType.Article,
    description: '',
    source: null,
    wordDescriptions: Map<string, WordDescriptionProps>(),
    contentUUId: '',
    quizUUID: '',
    template: 'old',
    topicUUID: '',
};

export class ArticleDescription
    extends Record(defaultArticleDescriptionProps)
    implements ArticleDescriptionProps {}

export function isArticle(
    resource: ResourceDescription
): resource is ArticleDescription {
    return resource.type === ResourceContentType.Article;
}
