import { createAsyncAction, createAction } from 'typesafe-actions';
import { EWritingLimitation, EwritingListItem } from './data/ewriting-models';
import { List } from 'immutable';
import { EwritingDTO } from './data/ewriting-dtos';

export const sendEWriting = createAsyncAction(
    '@@e-writing/send/request',
    '@@e-writing/send/success',
    '@@e-writing/send/failure'
)<{ subject: string; writingText: string }, EwritingDTO, Error>();

export const setEWritingLimitation = createAction(
    '@@e-writing/limitations'
)<EWritingLimitation>();

export const loadEWritingsList = createAsyncAction(
    '@@e-writing/load-list/request',
    '@@e-writing/load-list/success',
    '@@e-writing/load-list/failure'
)<{ learnerID: string }, List<EwritingListItem>, Error>();

export const setPreselectedEWritingLessonId = createAction(
    '@@e-writing/preselected-lesson-id'
)<string>();

export const eWritingActions = {
    sendEWriting,
    setEWritingLimitation,
    loadEWritingsList,
    setPreselectedEWritingLessonId,
};
