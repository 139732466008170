import { createReducer } from 'typesafe-actions';
import { Action } from '../../root-action';
import { combineReducers } from 'redux';
import { CompletedResource } from './completed-record';
import { clearAllResources } from '../resources-actions';

export type CompletedState = Readonly<{
    list: CompletedResource[] | null;
    isLoading: boolean;
}>;

export const initialState: CompletedState = {
    list: null,
    isLoading: false,
};

export const completedActivities = createReducer(initialState.list)
    .handleAction(
        Action.resources.loadCompletedActivities.success,
        (state, action) => action.payload
    )
    .handleAction(
        [Action.resources.resetCompletedActivities.success, clearAllResources],
        () => null
    );

export const isLoadingCompletedActivities = createReducer(
    initialState.isLoading
)
    .handleAction(Action.resources.loadCompletedActivities.request, () => true)
    .handleAction(
        [
            Action.resources.loadCompletedActivities.success,
            Action.resources.loadCompletedActivities.failure,
        ],
        () => false
    );

export const completedReducer = () =>
    combineReducers({
        list: completedActivities,
        isLoading: isLoadingCompletedActivities,
    });
