import CryptoJS from 'crypto-js';
import { secretKey } from '../constants';

const encryptText = (text: string) => {
    const encrypted = CryptoJS.AES.encrypt(text, secretKey).toString();
    return encrypted;
};

const decryptText = (text: string) => {
    try {
        const decrypted = CryptoJS.AES.decrypt(text, secretKey).toString(
            CryptoJS.enc.Utf8
        );
        return decrypted;
    } catch (error) {
        console.log('Decryption failed. Make sure the input is valid.');
    }
};

export { decryptText, encryptText };
