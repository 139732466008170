interface QueryStringProps {
    [key: string]: any;
}

export const queryString = (params: QueryStringProps) =>
    Object.entries(params)
        .map(
            ([key, value]) =>
                `${encodeURIComponent(key)}=${encodeURIComponent(value)}`
        )
        .join('&');
