import { ApiHeaders } from '../api-headers';
import { catchError, map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { Axios } from 'axios-observable';
import { ResourceContent } from '../../store/resources/resource-content';
import { parseDtoToResourceType } from './resource-type-dto';
import {
    RecommendedLevel,
    RecommendedLevelProps,
} from '../../store/resources/recommended-level';
import { recommended, learnerRecommendation } from '../api-urls';
import { isEmpty } from 'lodash';

export class RecommendedApi {
    constructor(private readonly apiHeaders: ApiHeaders) {}

    private getPostHeaders() {
        return {
            ...this.apiHeaders.getHeaders(),
            'Content-Type': 'application/json',
        };
    }

    private findUUIDRFY(item: ResourceContent) {
        if (!isEmpty(item?.uuid)) {
            return item.uuid;
        }
        if (!isEmpty(item?.contentUUID)) {
            return item.contentUUID;
        }
        if (!isEmpty(item?.id)) {
            return item.id;
        }
        return '';
    }

    public loadRecommendedItems(
        languageId: string,
        userUUID: string
    ): Observable<ResourceContent[]> {
        return Axios.get<{ content?: ResourceContent[]; state: string }>(
            `${learnerRecommendation()}content/${languageId}/${userUUID}`
        ).pipe(
            map((x) =>
                x.data?.content
                    ? x.data.content.map((item) => {
                          const newUUID = this.findUUIDRFY(item);
                          return new ResourceContent({
                              ...item,
                              uuid: newUUID,
                              contentUUID: newUUID,
                              legacy: {
                                  ...item.legacy,
                                  contentType: parseDtoToResourceType(
                                      item.legacy.contentType
                                  ),
                              },
                          });
                      })
                    : []
            )
        );
    }

    public loadTestLevel(
        languageId: string,
        userUUID: string
    ): Observable<RecommendedLevel> {
        return Axios.get<RecommendedLevelProps>(
            `${recommended()}/level/${languageId}/${userUUID}`
        ).pipe(map((x) => new RecommendedLevel(x.data)));
    }

    public removeRecommendedItem(resoureDetail: {
        topicUUID: string;
        userUUID: string;
        contentId: string;
        reason: string;
    }): Observable<void> {
        const { topicUUID, userUUID, contentId, reason } = resoureDetail;

        return Axios.delete(
            `${learnerRecommendation()}content/${topicUUID}/${userUUID}`,
            {
                headers: this.apiHeaders.getHeaders({}, true),
                data: { contentId, reason },
            }
        ).pipe(
            map((response) => response.data),
            catchError((error) => {
                throw error.message;
            })
        );
    }
}
