import { createAction, createAsyncAction } from 'typesafe-actions';
import { List } from 'immutable';
import { Moment } from 'moment';
import {
    LessonReport,
    LessonReportListItem,
} from './data/lesson-report-models';
import { EwritingListItem } from '../e-writing/data/ewriting-models';

export const loadLessonReportsAndEwritings = createAsyncAction(
    '@@lesson-reports/load-lesson-reports-and-ewritings/request',
    '@@lesson-reports/load-lesson-reports-and-ewritings/success',
    '@@lesson-reports/load-lesson-reports-and-ewritings/failure'
)<
    { learnerID: string },
    { reports: List<LessonReportListItem>; ewritings: List<EwritingListItem> },
    Error
>();

export const loadLessonReport = createAsyncAction(
    '@@lesson-reports/load-lesson-report/request',
    '@@lesson-reports/load-lesson-report/success',
    '@@lesson-reports/load-lesson-report/failure'
)<{ lessonID: string }, { report: LessonReport }, Error>();

export const loadLessonReportFromFeedback = createAsyncAction(
    '@@lesson-reports/load-lesson-report-from-feedback/request',
    '@@lesson-reports/load-lesson-report-from-feedback/success',
    '@@lesson-reports/load-lesson-report-from-feedback/failure'
)<
    {
        lessonID: string;
        trainerID: string;
        endDateTime: Moment;
        startDateTime: Moment;
    },
    { report: LessonReport },
    Error
>();

export const lessonReportsOpened = createAction(
    '@@lesson-reports/lesson-reports/opened'
)();

export const ewritingListItemSelected = createAction(
    '@@lesson-reports/ewriting-list-item/selected'
)<EwritingListItem | null>();

export const lessonReportListItemSelected = createAction(
    '@@lesson-reports/lesson-report-list-item/selected'
)<LessonReportListItem | null>();

export const lessonReportPageItemSelected = createAction(
    '@@lesson-reports/lesson-report-page-item/selected'
)<LessonReportListItem | EwritingListItem | null>();

export const lessonReportsPrintOpened = createAction(
    '@@lesson-reports/print/opened'
)();

export const printLessonReport = createAction('@@lesson-reports/print')<{
    listItem: LessonReportListItem;
    lessonReport: LessonReport;
}>();

export const lessonReportLoadedFromLocalStorageSuccess = createAction(
    '@@lesson-reports/print/loaded-success'
)<{ listItem: LessonReportListItem; lessonReport: LessonReport }>();

export const lessonReportLoadedFromLocalStorageFail = createAction(
    '@@lesson-reports/print/loaded-fail'
)();

export const lessonReportSetNavigation = createAction(
    '@@lesson-reports/set-navigation'
)<boolean>();
