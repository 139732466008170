import { Configuration } from '../../configuration';
import { ApiHeaders } from '../api-headers';
import { catchError, concatMap, map } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { Axios } from 'axios-observable';
import { contentService, language } from '../api-urls';
import { ResourceListDTO, ResourceListParser } from './resource-list-parser';
import { ResourceContent } from '../../store/resources/resource-content';

export class SearchApi {
    constructor(
        private readonly apiHeaders: ApiHeaders,
        private readonly configuration: Configuration,
        private readonly resourceListParser = new ResourceListParser()
    ) {}

    loadUUID(articleIds: number[]) {
        return Axios.post<any[]>(
            `${contentService()}legacy-ids/from-articleId-list`,
            articleIds,
            { headers: this.apiHeaders.getHeaders() }
        );
    }

    public search(
        index: number,
        keywords: string[],
        languageCode: string,
        moduleType: string
    ): Observable<ResourceContent[]> {
        return Axios.post<ResourceListDTO[]>(
            `${language()}${languageCode}/api/v1.0/content/search`,
            this.getSearchRequestBody(
                index,
                keywords,
                languageCode,
                moduleType
            ),
            { headers: this.getPostHeaders() }
        ).pipe(
            concatMap((x) => {
                const ids = x.data.map((item) => item.articleId as number);
                const items = x.data;
                return this.loadUUID(ids).pipe(
                    map((y) => {
                        const uuids = y.data;
                        const newItemsWithUuids = items.map((item) => {
                            const uuid = uuids.find(
                                (id) =>
                                    String(id.articleId) ===
                                    String(item.articleId)
                            );
                            item.contentUUID = uuid ? uuid.uuid : '';

                            return item;
                        });
                        return newItemsWithUuids.map((x) =>
                            this.resourceListParser.parseListItem(x)
                        );
                    }),
                    catchError((e) => {
                        console.error(e);
                        return of(
                            x.data.map((item) =>
                                this.resourceListParser.parseListItem(item)
                            )
                        );
                    })
                );
            }),
            catchError((e) => {
                // throw new Error("Can't load search itens list");
                return [];
            })
        );
    }

    private getPostHeaders() {
        return {
            ...this.apiHeaders.getHeaders(),
            'Content-Type': 'application/json',
        };
    }

    private getSearchRequestBody(
        index: number,
        keywords: string[],
        language: string,
        moduleType: string
    ) {
        const isSelectionOtherLanguage: boolean = language !== 'en';

        let availableModules: any = [];
        let otherLanguagesAvailableModules: any = [];

        availableModules = [
            {
                groupId:
                    this.configuration.wellKnownIds.howtoGroupId.toString(),
                name: 'HowTo',
            },
            {
                groupId:
                    this.configuration.wellKnownIds.articlesGroupId.toString(),
                name: 'Article',
            },
            {
                groupId:
                    this.configuration.wellKnownIds.videoGroupId.toString(),
                name: 'Video',
            },
            {
                groupId:
                    this.configuration.wellKnownIds.grammarGroupId.toString(),
                name: 'Grammar',
            },
            {
                groupId:
                    this.configuration.wellKnownIds.vocabularyGroupID.toString(),
                name: 'Vocabulary',
            },
        ];

        otherLanguagesAvailableModules = [
            { name: 'Video', type: 'video' },
            { name: 'Grammar', type: 'grammar' },
            { name: 'HowTo', type: 'how-to' },
            { name: 'Article', type: 'article' },
            { name: 'Vocabulary', type: 'vocabulary' },
        ];

        let mapTypeToName: { [key: string]: string } = {
            videos: 'Video',
            articles: 'Article',
            grammar: 'Grammar',
            vocabulary: 'Vocabulary',
            howtos: 'HowTo',
        };

        availableModules = isSelectionOtherLanguage
            ? otherLanguagesAvailableModules
            : availableModules;

        if (moduleType !== 'all') {
            availableModules = availableModules.filter(
                (item: any) => item.name === mapTypeToName[moduleType]
            );
        }

        return {
            Index: index,
            OtherLanguage: isSelectionOtherLanguage,
            Language: language,
            Keywords: keywords,
            Modules: availableModules,
            SortBy: 'Relevance',
        };
    }
}
