import { RootEpic } from './root-epic';
import { catchError, filter, map, switchMap } from 'rxjs/operators';
import { isActionOf } from 'typesafe-actions';
import { Action } from '../store/root-action';
import { of } from 'rxjs';

const trainingPageOpenedEpic: RootEpic = (action$, state$) =>
    action$.pipe(
        filter(isActionOf(Action.training.trainingPageOpened)),
        switchMap(() => {
            return of(
                Action.training.loadTrainingActivity.request({ topicId: null }),
                Action.training.loadTrainingEwriting.request({ topicId: null }),
                Action.training.loadLessonsActivity.request({ topicId: null }),
                Action.training.loadTrainingVCRActivity.request({
                    topicId: null,
                }),
                Action.training.loadTrainingCoursesActivity.request({
                    topicId: null,
                }),
                Action.training.loadPlacementTrainingActivity.request(),
                Action.contentSavedActions.clearLoadedSavedDisplayContent(),
                Action.contentSavedActions.getSavedContentItems.request(true),
                Action.inProgressTab.loadInProgressTabItems()
            );
        })
    );

const loadActivitiesEpic: RootEpic = (action$, state$, { trainingApi }) =>
    action$.pipe(
        filter(isActionOf(Action.training.loadTrainingActivity.request)),
        switchMap((x) => {
            return trainingApi
                .loadActivity(
                    state$.value.user.identity?.learnerUUID as string,
                    x.payload.topicId
                )
                .pipe(
                    map((x) => Action.training.loadTrainingActivity.success(x)),
                    catchError((e) =>
                        of(Action.training.loadTrainingActivity.failure(e))
                    )
                );
        })
    );

const loadVCRActivitiesEpic: RootEpic = (action$, state$, { trainingApi }) =>
    action$.pipe(
        filter(isActionOf(Action.training.loadTrainingVCRActivity.request)),
        switchMap((x) => {
            return trainingApi
                .loadVCR(
                    state$.value.user.identity?.learnerUUID as string,
                    x.payload.topicId,
                    state$.value.grading.list
                )
                .pipe(
                    map((x) => {
                        return Action.training.loadTrainingVCRActivity.success(
                            x
                        );
                    }),
                    catchError((e) =>
                        of(Action.training.loadTrainingVCRActivity.failure(e))
                    )
                );
        })
    );

// const loadGradingAfterVCREpic: RootEpic = (action$, state$, { trainingApi }) =>
//     action$.pipe(
//         filter(isActionOf(Action.training.loadTrainingVCRActivity.success)),
//         switchMap((x) => {
//             const getUniqueGradingIdMap = new Map(
//                 x.payload.map((item) => [item.gradingId, item.gradingId])
//             );

//             return from(
//                 Array.from(getUniqueGradingIdMap.keys()).map((item) =>
//                     Action.grading.loadGrading.request({
//                         id: item,
//                     })
//                 )
//             );
//         })
//     );

const loadCoursesActivitiesEpic: RootEpic = (
    action$,
    state$,
    { trainingApi }
) =>
    action$.pipe(
        filter(isActionOf(Action.training.loadTrainingCoursesActivity.request)),
        switchMap((x) => {
            return trainingApi
                .loadCourses(
                    state$.value.user.identity?.learnerUUID as string,
                    x.payload.topicId
                )
                .pipe(
                    map((x) =>
                        Action.training.loadTrainingCoursesActivity.success(x)
                    ),
                    catchError((e) => {
                        return of(
                            Action.training.loadTrainingCoursesActivity.failure(
                                e
                            )
                        );
                    })
                );
        })
    );

const loadEWritingsEpic: RootEpic = (action$, state$, { eWritingApi }) =>
    action$.pipe(
        filter(isActionOf(Action.training.loadTrainingEwriting.request)),
        switchMap((x) => {
            return eWritingApi
                .loadEwritingListWithTeachers(
                    state$.value.user.identity?.learnerUUID as string,
                    x.payload.topicId ? x.payload.topicId : undefined
                )
                .pipe(
                    map((x) => {
                        return Action.training.loadTrainingEwriting.success(x);
                    }),
                    catchError((e) =>
                        of(Action.training.loadTrainingActivity.failure(e))
                    )
                );
        })
    );

const loadLessonActivitiesEpic: RootEpic = (
    action$,
    state$,
    { activityLessonApi }
) =>
    action$.pipe(
        filter(isActionOf(Action.training.loadLessonsActivity.request)),
        switchMap((x) => {
            return activityLessonApi
                .loadLessonActivitiesListWithTrainers(
                    state$.value.user.identity?.learnerUUID as string,
                    x.payload.topicId ? x.payload.topicId : undefined
                )
                .pipe(
                    map((x) => {
                        return Action.training.loadLessonsActivity.success(x);
                    }),
                    catchError((e) => {
                        return of(
                            Action.training.loadLessonsActivity.failure(e)
                        );
                    })
                );
        })
    );

const loadPlacementActivitiesEpic: RootEpic = (
    action$,
    state$,
    { trainingApi }
) =>
    action$.pipe(
        filter(isActionOf(Action.training.loadTrainingActivity.request)),
        switchMap((x) => {
            const trainingCard$ = trainingApi
                .loadPlacementTrainingActivity(
                    state$.value.user.identity?.learnerUUID as string
                )
                .pipe(
                    map((x) =>
                        Action.training.loadPlacementTrainingActivity.success(x)
                    ),
                    catchError((e) =>
                        of(
                            Action.training.loadPlacementTrainingActivity.failure(
                                e
                            )
                        )
                    )
                );
            return trainingCard$;
        })
    );

export const trainingEpics = [
    loadActivitiesEpic,
    // loadGradingAfterVCREpic,
    loadEWritingsEpic,
    loadLessonActivitiesEpic,
    trainingPageOpenedEpic,
    loadVCRActivitiesEpic,
    loadCoursesActivitiesEpic,
    loadPlacementActivitiesEpic,
];
