import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Redirect, useLocation, useRouteMatch } from 'react-router-dom';
import { LoginPage } from './login-page';

import {
    isAuthenticated,
    isLoadingDisplayLanguageOnLogin,
    redirectPageSelector,
} from '../../store/user/user-selectors';
import { isCordova } from '../../utils/cordova';
import { THEMES } from '../../utils/constants';
import { useStatusBarTheme } from '../../hooks/useStatusBarTheme';

import {
    privacyPolicy,
    privacyPolicyCountry,
} from '../../store/privacy-policy/privacy-policy-selectors';
import { PrivacyPage } from '../privacy-policy/privacy-policy-page';
import { useAppDispatch } from '../root/app-dispatch';
import { Action } from '../../store/root-action';

export const LoginRouter: React.FC = () => {
    const isUserAuthenticated = useSelector(isAuthenticated);
    const toDisplayPrivacyPolicy = useSelector(privacyPolicy);
    const isAlreadySetPrivacyCountry = useSelector(privacyPolicyCountry);
    const redirectPage = useSelector(redirectPageSelector);

    const isLoadingLanguage = useSelector(isLoadingDisplayLanguageOnLogin);
    const location = useLocation();
    const isLoginPage = useRouteMatch('/login');
    const { from } = (location.state as { from: { pathname: string } }) || {
        from: { pathname: '/' },
    };

    const dispatch = useAppDispatch();

    useEffect(() => {
        const simCountryFrom = localStorage.getItem('simCountry');
        if (simCountryFrom && isAlreadySetPrivacyCountry === null) {
            dispatch(Action.privacyPolicy.countryPreload(simCountryFrom));
        }
    }, [dispatch, isAlreadySetPrivacyCountry]);

    useEffect(() => {
        if (redirectPage === '/login') {
            dispatch(Action.user.setRedirectPage('/'));
        }
        // eslint-disable-next-line
    }, []);

    useStatusBarTheme(isLoginPage !== null, THEMES.LIGHT, THEMES.SEMI_LIGHT);

    let DispalyComponent;
    if (isCordova) {
        if (
            (isAlreadySetPrivacyCountry &&
                isAlreadySetPrivacyCountry.toLowerCase() === `cn`) ||
            (isAlreadySetPrivacyCountry &&
                isAlreadySetPrivacyCountry.toLowerCase() === `chn`)
        ) {
            if (!toDisplayPrivacyPolicy) {
                DispalyComponent = <PrivacyPage />;
            } else {
                DispalyComponent =
                    isUserAuthenticated && !isLoadingLanguage ? (
                        <Redirect to={from} />
                    ) : (
                        <LoginPage />
                    );
            }
        } else {
            DispalyComponent =
                isUserAuthenticated && !isLoadingLanguage ? (
                    <Redirect to={from} />
                ) : (
                    <LoginPage />
                );
        }
    } else {
        DispalyComponent =
            isUserAuthenticated && !isLoadingLanguage ? (
                <Redirect to={from} />
            ) : (
                <LoginPage />
            );
    }

    return (
        <>
            {isCordova
                ? isAlreadySetPrivacyCountry !== null && DispalyComponent
                : DispalyComponent}
        </>
    );
};
