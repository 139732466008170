export const downloadFile = (
    fileName: string,
    extension: string,
    fileContent: Blob
) => {
    const url = window.URL.createObjectURL(fileContent);
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', `${fileName}.${extension}`);
    document.body.appendChild(link);
    link.click();
};
