export class ApiHeaders {
    token: string | null | undefined = null;
    aes: string | null | undefined = null;

    setAuthentcationToken(token: string | null | undefined) {
        this.token = token;
    }

    setAes(aes: string | null | undefined) {
        this.aes = aes;
    }

    getHeaders(
        additionalHeaders?: { [key: string]: string },
        aes: boolean = false
    ) {
        let headers = additionalHeaders ? additionalHeaders : {};
        let auth = aes ? this.aes : this.token;

        if (auth) {
            headers = { Authorization: auth, ...headers };
        }

        return headers;
    }
}
