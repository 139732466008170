import { createReducer } from 'typesafe-actions';
import { resourceContentActions } from './resources-content-actions';
import { ResourceContentProps } from './resources-content-record';
import { resourceActions } from './resources-actions';
import { Action } from '../root-action';
import { modifyResourceStatus } from '../../epics/resources/helpers';

export const displayedResourceContentReducer = createReducer(
    null as ResourceContentProps | null
)
    .handleAction(
        [
            resourceActions.loadSelectedResource.request,
            resourceActions.loadSelectedUsingUUIDResource.request,
        ],
        () => null
    )
    .handleAction(
        [resourceContentActions.loadSelectedResourceContent.success],
        (state, action) => action.payload
    )
    .handleAction(
        [resourceContentActions.loadSelectedResourceContent.failure],
        () => null
    )
    .handleAction(
        Action.resources.loadInProgressActivities.success,
        (state, action) => {
            const statuses = action.payload;
            return state
                ? modifyResourceStatus<ResourceContentProps>(
                      statuses,
                      null
                  )(state)
                : state;
        }
    )
    .handleAction(
        Action.resources.loadCompletedActivities.success,
        (state, action) => {
            const statuses = action.payload;
            return state
                ? modifyResourceStatus<ResourceContentProps>(
                      null,
                      statuses
                  )(state)
                : state;
        }
    );
