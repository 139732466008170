import { of } from 'rxjs';
import { catchError, filter, map, switchMap } from 'rxjs/operators';
import { isActionOf } from 'typesafe-actions';
import { Action } from '../../store/root-action';
import { RootEpic } from '../root-epic';

const loadChannelList: RootEpic = (action$, _, { channelApi }) =>
    action$.pipe(
        filter(isActionOf(Action.channel.loadChannelList.request)),
        switchMap((x) =>
            channelApi.loadList(x.payload).pipe(
                map((loadedResources) => {
                    return Action.channel.loadChannelList.success(
                        loadedResources
                    );
                }),
                catchError((e) => of(Action.channel.loadChannelList.failure(e)))
            )
        )
    );

export const channelEpics = [loadChannelList];
