import { Axios } from 'axios-observable';
import { ajax } from 'rxjs/ajax';
import { from, lastValueFrom, Observable, of } from 'rxjs';
import { ApiHeaders } from '../../services/api-headers';
import { learnerChat, user } from '../../services/api-urls';
import { catchError, map } from 'rxjs/operators';
import { Discussion, LearnerConsult, Message, MessageDTO } from './model';
import { Cache } from '../../utils/cache';
import { messageDateParser } from './lib';

export class LearnerChatApi {
    apiHeaders: ApiHeaders;

    constructor(apiHeaders: ApiHeaders) {
        this.apiHeaders = apiHeaders;
    }

    public checkLcTeam(lang: string) {
        return Axios.get<{ interfaceLanguage: string }>(
            learnerChat() + `/team-contact?lang=${lang}&portal=LEARNER`,
            {
                headers: this.apiHeaders.getHeaders({}, true),
            }
        ).pipe(
            map((response) => {
                return response.data.interfaceLanguage;
            }),
            catchError((error) => {
                throw error.message;
            })
        );
    }

    private getDiscussionsList(learnerId: string) {
        return Axios.get<Discussion[]>(
            learnerChat() + learnerId + '/discussion',
            {
                headers: this.apiHeaders.getHeaders({}, true),
            }
        ).pipe(
            map((response) => {
                const discussions = response.data;
                return discussions.map((discussion) => {
                    return {
                        ...discussion,
                        discussionId: discussion.discussionId ?? 'empty',
                        previousLastSeenMessageId: discussion.lastSeenMessageId,
                    };
                });
            }),
            catchError((error) => {
                throw error.message;
            })
        );
    }

    public loadDiscussions(
        learnerId: string,
        learnerName: string,
        learnerImage: string
    ): Observable<Discussion[]> {
        return from(
            this.loadDiscussionsWithMessages(
                learnerId,
                learnerName,
                learnerImage
            )
        );
    }

    private async loadDiscussionsWithMessages(
        learnerId: string,
        learnerName: string,
        learnerImage: string
    ): Promise<Discussion[]> {
        let discussions = await lastValueFrom(
            this.getDiscussionsList(learnerId)
        );
        const discussionsWithMessages: Discussion[] = await Promise.all(
            discussions.map(async (discussion): Promise<Discussion> => {
                if (
                    !!discussion.discussionId &&
                    discussion.discussionId !== 'empty'
                ) {
                    let messages = await lastValueFrom(
                        this.loadMessages(
                            learnerId,
                            learnerName,
                            learnerImage,
                            discussion.interfaceLanguage,
                            discussion.discussionId
                        )
                    );
                    return {
                        ...discussion,
                        messages: messages,
                    };
                } else {
                    return {
                        ...discussion,
                        messages: null,
                    };
                }
            })
        );

        return lastValueFrom(of(discussionsWithMessages));
    }

    public createDiscussion(
        learnerId: string,
        interfaceLanguage: string,
        message: string
    ) {
        return Axios.post<Discussion>(
            learnerChat() + learnerId + '/discussion',
            { interfaceLanguage, messageContent: message },
            {
                headers: this.apiHeaders.getHeaders({}, true),
            }
        ).pipe(
            map((response) => response.data),
            catchError((error) => {
                throw error.message;
            })
        );
    }

    private getMessages(
        learnerId: string,
        interfaceLanguage: string,
        discussionId: string
    ) {
        return Axios.get<MessageDTO[]>(
            learnerChat() +
                learnerId +
                '/discussion/' +
                interfaceLanguage +
                '/' +
                discussionId +
                '/message',
            {
                headers: this.apiHeaders.getHeaders({}, true),
            }
        ).pipe(
            map((response) => response.data),
            catchError((error) => {
                throw error.message;
            })
        );
    }

    public loadMessages(
        learnerId: string,
        currentUserName: string,
        currentUserImage: string,
        interfaceLanguage: string,
        discussionId: string
    ): Observable<Message[]> {
        return from(
            this.loadMessagesWithConsults(
                learnerId,
                currentUserName,
                currentUserImage,
                interfaceLanguage,
                discussionId
            )
        );
    }

    private async loadMessagesWithConsults(
        learnerId: string,
        currentUserName: string,
        currentUserImage: string,
        interfaceLanguage: string,
        discussionId: string
    ): Promise<Message[]> {
        let messages = await lastValueFrom(
            this.getMessages(learnerId, interfaceLanguage, discussionId)
        );

        let learnerConsultsIds: string[] = [];
        messages.forEach((message) => {
            if (
                message.userUuid !== learnerId &&
                !learnerConsultsIds.includes(message.userUuid)
            ) {
                learnerConsultsIds.push(message.userUuid);
            }
        });

        let learnerConsults = await this.loadLearnerConsults(
            learnerConsultsIds
        );

        const messagesWithConsults = messages.map((message) => {
            const isLearnerConsult = message.userUuid !== learnerId;
            let currentLearnerConsult = null;
            if (isLearnerConsult && !!learnerConsults) {
                currentLearnerConsult = learnerConsults.find(
                    (learnerConsult) =>
                        learnerConsult.consultUUID === message.userUuid
                );
            }
            return {
                messageId: message.messageId,
                userName: !!currentLearnerConsult
                    ? currentLearnerConsult.firstName +
                      ' ' +
                      currentLearnerConsult.lastName
                    : currentUserName,
                userImage: !!currentLearnerConsult
                    ? currentLearnerConsult.image
                    : currentUserImage,
                date: messageDateParser(message.creationTimestamp),
                creationTimestamp: message.creationTimestamp,
                text: message.content,
                isFromCurrentUser: !isLearnerConsult,
            } as Message;
        });
        return lastValueFrom(of(messagesWithConsults));
    }

    readMessage(
        learnerId: string,
        interfaceLanguage: string,
        discussionId: string,
        messageId: string
    ) {
        return Axios.post(
            learnerChat() +
                learnerId +
                '/discussion/' +
                interfaceLanguage +
                '/' +
                discussionId +
                '/message/' +
                messageId +
                '/read',
            {
                headers: this.apiHeaders.getHeaders({}, true),
            }
        ).pipe(
            map((response) => response.data),
            catchError((error) => {
                throw error.message;
            })
        );
    }

    createMessage(
        learnerId: string,
        interfaceLanguage: string,
        discussionId: string,
        content: string
    ) {
        return Axios.post<MessageDTO>(
            learnerChat() +
                learnerId +
                '/discussion/' +
                interfaceLanguage +
                '/' +
                discussionId +
                '/message',
            { content },
            {
                headers: this.apiHeaders.getHeaders({}, true),
            }
        ).pipe(
            map((response) => response.data),
            catchError((error) => {
                throw error.message;
            })
        );
    }

    private async loadLearnerConsults(
        consultsIds: string[]
    ): Promise<LearnerConsult[]> {
        if (consultsIds.length > 0) {
            const learnerConsults = consultsIds.map((id) =>
                this.getLearnerConsult(id)
            );
            return Promise.all(learnerConsults);
        }
        return [];
    }

    @Cache({ globalPrefix: 'loadProfile' })
    private getLearnerConsult(userId: string): Promise<LearnerConsult> {
        return lastValueFrom(
            ajax
                .getJSON<any>(user() + userId, this.apiHeaders.getHeaders())
                .pipe(
                    map((r) => {
                        return {
                            firstName: r.firstname,
                            lastName: r.lastname,
                            consultUUID: r.uuid,
                            image: r.avatar,
                        } as LearnerConsult;
                    })
                )
        );
    }
}
