import { createReducer } from 'typesafe-actions';
import { Map, List, Record } from 'immutable';
import { combineReducers } from 'redux';
import { Action } from '../root-action';

import {
    Availability,
    Cancellation,
    Foresen,
    MediaRecord,
    SchedulerLanguageProps,
    AvailabilityGroupLesson,
} from './scheduler-data';
import moment from 'moment';

/**
 * lessons list learner reducer
 */
const learnerReducer = createReducer(null)
    .handleAction(
        Action.lessonsList.initializeLessonsList.success,
        (state, { payload }) => {
            return payload.learner;
        }
    )
    .handleAction(Action.user.logout.success, (state, _) => null);

/**
 * lessons list current availabilities reducer
 */
const lessonsReducer = createReducer(List<Availability>())
    .handleAction(
        Action.lessonsList.initializeLessonsList.success,
        (state, { payload }) => {
            return payload.lessons;
        }
    )
    .handleAction(Action.user.logout.success, (state, action) => state.clear());

/**
 * lessons list current availabilities list reducer
 */
const lessonListReducer = createReducer(List<Availability>())
    .handleAction(
        Action.lessonsList.initLessons.success,
        (state, { payload }) => {
            // console.log('initlessons', payload.lessons.toJS());
            return payload.lessons;
        }
    )
    .handleAction(Action.lessonsList.initLessons.failure, (state, _) => List())
    .handleAction(Action.user.logout.success, (state, action) => state.clear());

/**
 * lessons list current availabilities list reducer
 */
const lessonGroupListReducer = createReducer(List<AvailabilityGroupLesson>())
    .handleAction(
        Action.lessonsList.initLessons.success,
        (state, { payload }) => {
            return payload.groupLessonAvail;
        }
    )
    .handleAction(Action.lessonsList.initLessons.failure, (state, _) => List())
    .handleAction(Action.user.logout.success, (state, action) => state.clear());

/**
 * lessons list media reducer
 */
const mediaReducer = createReducer(Map<string, MediaRecord>())
    .handleAction(
        Action.lessonsList.initializeLessonsList.success,
        (state, { payload }) => {
            return payload.medias;
        }
    )
    .handleAction(Action.user.logout.success, (state, action) => state.clear());

/**
 * lessons list selected availability reducer
 */
const selectedAvailabilityReducer = createReducer<Availability | null>(null)
    .handleAction(
        Action.lessonsList.setSelectedAvailability,
        (_, { payload }) => payload
    )
    .handleAction(
        Action.lessonsList.selectLessonsListDate,
        (state, action) => null
    )
    .handleAction(
        Action.lessonsList.initializeLessonsList.success,
        (state, action) => null
    )
    .handleAction(Action.user.logout.success, (state) => null);

//ToDo: Never called, delete if don't used in calendar
/**
 * lessons list availabilities list reducer
 */
const availabilitiesReducer = createReducer(List<Availability>())
    .handleAction(
        Action.lessonsList.loadAvailabilities.success,
        (state, { payload: { availabilities } }) => availabilities
    )
    .handleAction(
        Action.lessonsList.loadMoreAvailabilities.success,
        (state, { payload: { availabilities } }) => {
            return state.merge(availabilities);
        }
    )
    .handleAction(
        [
            Action.lessonsList.loadAvailabilities.failure,
            Action.lessonsList.loadMoreAvailabilities.failure,
        ],
        (state) => state.clear()
    )
    .handleAction(Action.user.logout.success, (state, _) => state.clear());

//ToDo: Never called, delete if don't used in calendar
/**
 * Lessons list reducer for availabilities load errors
 */
const availabilitiesErrorReducer = createReducer<string | null>(null)
    .handleAction(
        Action.lessonsList.loadAvailabilities.failure,
        (state, action) => {
            const payload = action.payload as any;
            /* if server replied with 500, we would not have response.Label */
            // console.log('Availability error', payload);
            const error = (payload.response?.Label as string) || 'server_error';

            return error;
        }
    )
    .handleAction(
        Action.lessonsList.loadAvailabilities.success,
        (state, action) => null
    )
    .handleAction(
        Action.lessonsList.loadAvailabilities.request,
        (state, action) => {
            return null;
        }
    )
    .handleAction(Action.user.logout.success, (state, action) => null)
    .handleAction(
        Action.lessonsList.setAvailabilityError,
        (state, { payload }) => payload
    );

const weekLessonsReducer = createReducer(List<Availability>())
    .handleAction(
        Action.lessonsList.loadInitWeekLessons.success,
        (state, { payload: { lessons } }) => lessons
    )
    .handleAction(
        Action.lessonsList.loadInitWeekLessons.failure,
        (state, action) => state.clear()
    )
    .handleAction(Action.user.logout.success, (state, action) => state.clear());

const isUpdatingInitializeLessonListReducer = createReducer(true)
    .handleAction(
        [Action.lessonsList.initializeLessonsList.request],
        () => true
    )
    .handleAction(
        [
            Action.lessonsList.initializeLessonsList.failure,
            Action.lessonsList.initializeLessonsList.success,
        ],
        () => false
    );

const isUpdatingInitLessonsReducer = createReducer(true)
    .handleAction([Action.lessonsList.initLessons.request], () => true)
    .handleAction(
        [
            Action.lessonsList.initLessons.failure,
            Action.lessonsList.initLessons.success,
        ],
        () => false
    );

const isUpdatingLoadAvailabilitiesReducer = createReducer(true)
    .handleAction([Action.lessonsList.loadAvailabilities.request], () => true)
    .handleAction(
        [
            Action.lessonsList.loadAvailabilities.failure,
            Action.lessonsList.loadAvailabilities.success,
        ],
        () => false
    );

/**
 * Reducer for lessons list updating state
 */
const isLessonsUpdatingReducer = createReducer(true)
    .handleAction(
        [
            Action.lessonsList.loadMoreAvailabilities.request,
            Action.lessonsList.loadAvailabilities.request,
        ],
        () => true
    )
    .handleAction(
        [
            Action.lessonsList.loadAvailabilities.success,
            Action.lessonsList.loadMoreAvailabilities.success,
            Action.lessonsList.loadMoreAvailabilities.failure,
            Action.lessonsList.loadAvailabilities.failure,
        ],
        () => false
    )
    .handleAction(Action.user.logout.success, (state, action) => true);

/**
 * Lessons list selected date
 */
const selectedDateReducer = createReducer(moment.utc().startOf('day'))
    .handleAction(
        Action.lessonsList.selectLessonsListDate,
        (_, { payload }) => {
            return payload;
        }
    )
    .handleAction(Action.user.logout.success, (state) =>
        moment.utc().startOf('day')
    );

/**
 * Lessons list cancellation reducer
 */
const cancellationReducer = createReducer(new Cancellation())
    .handleAction(
        Action.lessonsList.cancelLesson.request,
        (state, { payload }) => {
            return new Cancellation({
                lessonId: payload.lessonId,
            });
        }
    )
    .handleAction(
        Action.lessonsList.cancelLesson.success,
        (state, { payload }) => {
            return payload.cancellation;
        }
    )
    .handleAction(
        Action.lessonsList.abortCancellation,
        (state, action) => new Cancellation()
    )
    .handleAction(
        Action.lessonsList.confirmedCancelLesson.success,
        (state, action) => new Cancellation()
    )
    .handleAction(
        Action.lessonsList.cancelLesson.failure,
        (state, action) => new Cancellation()
    )
    .handleAction(
        Action.lessonsList.confirmedCancelLesson.failure,
        (state, action) => new Cancellation()
    )
    .handleAction(
        Action.user.logout.success,
        (state, action) => new Cancellation()
    );

/**
 * Lessons list foreseen Reducer
 */
const foreseenReducer = createReducer<Foresen | null>(null)
    .handleAction(
        Action.lessonsList.loadForesen.success,
        (state, action) => action.payload
    )
    .handleAction(Action.lessonsList.loadForesen.failure, () => null)
    .handleAction(Action.user.logout.success, (state, action) => null);

/**
 * Lessons list reducer for forseen loading state
 */
const isLoadingForeseenReducer = createReducer(false)
    .handleAction(Action.lessonsList.loadForesen.request, () => true)
    .handleAction(
        [
            Action.lessonsList.loadForesen.success,
            Action.lessonsList.loadForesen.failure,
        ],
        () => false
    )
    .handleAction(Action.user.logout.success, (state, action) => false);

/**
 * Lesson list popups state reducer
 */
const popupsOpenReducer = createReducer(
    Record({
        cancel: false,
        calendar: false,
        isAnyOpen: false,
    })()
)
    .handleAction(
        Action.lessonsList.togglePopup,
        (state, { payload: { name, value } }) => {
            let newState = state.set(name, value);
            return newState.set(
                'isAnyOpen',
                newState.cancel || newState.calendar
            );
        }
    )
    .handleAction(Action.user.logout.success, (state, action) => state.clear());

/**
 * Lessons list language reducer
 */
const languageReducer = createReducer(
    new SchedulerLanguageProps()
).handleAction(
    Action.lessonsList.initLanguage.success,
    (state, { payload }) => payload
);

const selectedMonthDate = createReducer(moment.utc().startOf('day'))
    .handleAction(Action.lessonsList.selectDate, (_, { payload }) => payload)
    .handleAction(
        Action.lessonsList.selectMonthDate,
        (_, { payload }) => payload
    )
    .handleAction(Action.user.logout.success, (state) =>
        moment.utc().startOf('day')
    );

const isLoadingNextMonth = createReducer(false)
    .handleAction(
        Action.lessonsList.selectMonthDate,
        (state, { payload }) => true
    )
    .handleAction(Action.lessonsList.initLessons.success, () => {
        return false;
    })
    .handleAction(Action.lessonsList.initLessons.failure, () => {
        return false;
    });

export const lessonsListReducer = () => {
    return combineReducers({
        learner: learnerReducer,
        lessons: lessonsReducer,
        media: mediaReducer,
        availabilities: availabilitiesReducer,
        availabilitiesError: availabilitiesErrorReducer,
        isLessonsUpdating: isLessonsUpdatingReducer,
        selectedDate: selectedDateReducer,
        cancellation: cancellationReducer,
        lessonList: lessonListReducer,
        lessonGroupList: lessonGroupListReducer,
        foreseen: foreseenReducer,
        isLoadingForeseen: isLoadingForeseenReducer,
        selectedAvailability: selectedAvailabilityReducer,
        popupsOpen: popupsOpenReducer,
        weekLessons: weekLessonsReducer,
        language: languageReducer,
        isUpdatingInitializeLessonList: isUpdatingInitializeLessonListReducer,
        isUpdatingInitLessons: isUpdatingInitLessonsReducer,
        isUpdatingLoadAvailabilities: isUpdatingLoadAvailabilitiesReducer,
        isLoadingNextMonth: isLoadingNextMonth,
        selectedMonthDate: selectedMonthDate,
    });
};
