import { useSelector } from 'react-redux';
import {
    ConversationClassSurveyDetailsSelector,
    GroupLessonSurveyDetailsSelector,
    IndividualLessonDetailsSurveySelector,
} from '../slice';
import { RequiredSurveysDataDetails, SurveyBusinessType } from '../types';

export const useGetLessonDetails = (
    businessType: SurveyBusinessType
): RequiredSurveysDataDetails | null => {
    const ILData = useSelector(IndividualLessonDetailsSurveySelector);
    const CCData = useSelector(ConversationClassSurveyDetailsSelector);
    const GLData = useSelector(GroupLessonSurveyDetailsSelector);

    if (businessType === 'IndividualLesson') {
        return ILData;
    }
    if (businessType === 'ConversationClass') {
        return CCData;
    }
    if (businessType === 'GroupLesson') {
        return GLData;
    }

    return null;
};
