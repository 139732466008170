import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { contactUsApi } from './api';
import { RootStateRTK } from '../../rtk/store';
import { createSelector } from 'reselect';
import { TGetContactUsPhoneResponse } from './types';

export interface IContactUsState {
    email: string;
    phones: TGetContactUsPhoneResponse;
    chatOpen: boolean;
}
export const initialState: IContactUsState = {
    email: 'contact-us@gofluent.com',
    phones: [],
    chatOpen: false,
};

export const contactUsSlice = createSlice({
    name: 'contactUs',
    initialState,
    reducers: {
        setChatOpen: (state, action: PayloadAction<boolean>) => {
            state.chatOpen = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder.addMatcher(
            contactUsApi.endpoints.getContactUsPhone.matchFulfilled,
            (state, action: PayloadAction<TGetContactUsPhoneResponse>) => {
                state.phones = action.payload;
            }
        );
        builder.addMatcher(
            contactUsApi.endpoints.getContactUsMail.matchFulfilled,
            (state, action: PayloadAction<string | null>) => {
                if (action.payload) {
                    state.email = action.payload;
                }
            }
        );
    },
});

const { reducer, actions } = contactUsSlice;
export default reducer;

export const { setChatOpen } = actions;

export const contactUsSelector = (state: RootStateRTK) =>
    state.contactUs as IContactUsState;

export const contactUsEmailSelector = createSelector(
    contactUsSelector,
    (contactUsState) => contactUsState.email as string
);
export const contactUsPhonesSelector = createSelector(
    contactUsSelector,
    (contactUsState) => contactUsState.phones as TGetContactUsPhoneResponse
);

export const contactUsChatOpenSelector = createSelector(
    contactUsSelector,
    (contactUsState) => contactUsState.chatOpen as boolean
);
