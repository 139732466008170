import {
    catchError,
    filter,
    mergeMap,
    switchMap,
    withLatestFrom,
} from 'rxjs/operators';
import { isActionOf } from 'typesafe-actions';
import { RootEpic } from '../root-epic';
import { Action } from '../../store/root-action';
import { asapScheduler, of, scheduled } from 'rxjs';
import { AssignmentDTO } from '../../store/save-content/save-content-record';
import { ResourceContent } from '../../store/resources/resource-content';
import { localization } from '../../localization';

export const loadSavedContentFeature: RootEpic = (
    action$,
    state$,
    { saveContentApi }
) =>
    action$.pipe(
        filter(
            isActionOf([
                Action.contentSavedActions.getSavedContentItems.request,
                Action.contentSavedActions.getSavedContentItems.cancel,
            ])
        ),
        withLatestFrom(state$),
        switchMap(([x, state]) => {
            return saveContentApi
                .loadSavedContent(state.user.identity?.learnerUUID as string)
                .pipe(
                    switchMap((loadedSavedContent) => {
                        const result = loadedSavedContent.assignmentDto;
                        const array = [
                            // Action.contentSavedActions.clearLoadedSavedDisplayContent(),
                            // Action.contentSavedActions.setSavedContent(result),
                            // Action.trainingPath.loadTrainingPath.request(),
                        ];
                        result.forEach((item) => {
                            array.push(
                                Action.contentSavedActions.loadSavedDisplayContent.request(
                                    item
                                )
                            );
                        });
                        array.push(
                            Action.contentSavedActions.setSavedContent(result)
                        );

                        return scheduled(array, asapScheduler);
                    }),
                    catchError((e) => {
                        return of(
                            Action.contentSavedActions.getSavedContentItems.failure(
                                e
                            )
                        );
                    })
                );
        })
    );

export const loadSavedContentToDisplayEpic: RootEpic = (
    action$,
    state$,
    { saveContentApi }
) =>
    action$.pipe(
        filter(
            isActionOf(
                Action.contentSavedActions.loadSavedDisplayContent.request
            )
        ),
        withLatestFrom(state$),
        mergeMap(([x]) => {
            return saveContentApi
                .loadSavedContentForDisplay(x.payload.contentId)
                .pipe(
                    mergeMap((item) => {
                        const currentState: ResourceContent[] =
                            state$.value.savedContentFeature
                                .savedContentToDisplay;
                        const isExists = currentState.find((item) => {
                            return (
                                item.contentUUID === x.payload.contentId ||
                                item.id === x.payload.contentId
                            );
                        });

                        if (!isExists) {
                            if (item) {
                                const checkMediumImage =
                                    item.legacy.medium_image ||
                                    item.legacy.mediumImage;
                                if (
                                    !checkMediumImage ||
                                    checkMediumImage === ''
                                ) {
                                    item.legacy.medium_image = `/image?image_id=${item.legacy.smallImageId}`;
                                    item.legacy.mediumImage = `/image?image_id=${item.legacy.smallImageId}`;
                                    item.image = `/image?image_id=${item.legacy.smallImageId}`;
                                } else {
                                    item.legacy.medium_image = checkMediumImage;
                                    item.legacy.mediumImage = checkMediumImage;
                                    item.image = checkMediumImage;
                                }
                                item.resourceType =
                                    item.legacy.contentType ===
                                        'training-path' ||
                                    item.legacy.contentType === 'Course'
                                        ? 'Course'
                                        : 'Content';
                                item.contentUUID = item.id;
                                item.topicId = x.payload.topicId;
                                item.date = (
                                    x.payload as AssignmentDTO
                                ).dueDate;
                                currentState.push(item);
                            }
                        }
                        return of(
                            Action.contentSavedActions.loadSavedDisplayContent.success(
                                null
                            ),
                            Action.contentSavedActions.setSavedContentToDisplay(
                                currentState
                            )
                        );
                    }),
                    catchError((e) => {
                        return of(
                            Action.contentSavedActions.loadSavedDisplayContent.failure(
                                e
                            )
                        );
                    })
                );
        })
    );

export const saveContentFeature: RootEpic = (
    action$,
    state$,
    { saveContentApi }
) =>
    action$.pipe(
        filter(isActionOf(Action.contentSavedActions.saveContent.request)),
        withLatestFrom(state$),
        switchMap(([x]) => {
            const languageUUID = x.payload.item?.topicId
                ? x.payload.item?.topicId
                : (state$.value.learnerProfile.currentLanguage
                      ?.languageUUID as string);

            return saveContentApi
                .saveContent(
                    languageUUID,
                    state$.value.user.identity?.learnerUUID as string,
                    x.payload.id,
                    x.payload.item?.resourceType === 'Course'
                        ? 'Course'
                        : 'Content'
                )
                .pipe(
                    switchMap((y) => {
                        const currentSaved: AssignmentDTO[] =
                            state$.value.savedContentFeature.savedContent;
                        const isExists = currentSaved.find(
                            (item) => item.contentId === x.payload.id
                        );
                        const current = y[0];
                        if (!isExists) {
                            currentSaved.push({
                                userId: y[0].key.userId,
                                topicId: y[0].key.topicId,
                                contentId: y[0].key.contentId,
                                assignerId: y[0].assignerId,
                                assignedDate: y[0].assignedDate,
                                dueDate: y[0].dueDate,
                                modified: y[0].modifiedDate,
                                status: 'Assigned',
                            });
                        }
                        return of(
                            Action.contentSavedActions.saveContent.success(
                                y[0]
                            ),
                            Action.contentSavedActions.getSavedContentItems.request(
                                true
                            ),
                            Action.contentSavedActions.setSavedContent(
                                currentSaved
                            ),
                            Action.contentSavedActions.setNotification({
                                show: true,
                                error: false,
                                maximum: false,
                                text: localization.savedContent_message_saved,
                                key: 1,
                            }),
                            Action.contentSavedActions.loadSavedDisplayContent.request(
                                current.key
                            )
                        );
                    }),
                    catchError((y) => {
                        let text = localization.savedContent_message_error;
                        let maximum = false;
                        if (
                            y?.message &&
                            y.message.indexOf('Max Saving Content') !== -1
                        ) {
                            maximum = true;
                            text = localization.savedContent_message_maxLimit;
                        }
                        return of(
                            Action.contentSavedActions.saveContent.failure(y),
                            Action.contentSavedActions.setNotification({
                                show: true,
                                error: true,
                                maximum: maximum,
                                text: text,
                                key: 3,
                            })
                        );
                    })
                );
        })
    );

export const unSaveContentFeature: RootEpic = (
    action$,
    state$,
    { saveContentApi }
) =>
    action$.pipe(
        filter(isActionOf(Action.contentSavedActions.unSaveContent.request)),
        withLatestFrom(state$),
        switchMap(([x]) => {
            const languageUUID = x.payload.item?.topicId
                ? x.payload.item?.topicId
                : (state$.value.learnerProfile.currentLanguage
                      ?.languageUUID as string);

            return saveContentApi
                .unSaveContent(
                    languageUUID,
                    state$.value.user.identity?.learnerUUID as string,
                    x.payload.id
                )
                .pipe(
                    switchMap(() => {
                        //const currentState: ResourceContent[] = state$.value.savedContentFeature.savedContentToDisplay;
                        const currentSaved: AssignmentDTO[] =
                            state$.value.savedContentFeature.savedContent;
                        const newSavedArr = currentSaved.filter(
                            (item) => item.contentId !== x.payload.id
                        );
                        //const newStateArr = currentState.filter(item => item.contentUUID !== x.payload)
                        return of(
                            Action.contentSavedActions.unSaveContent.success(),
                            Action.contentSavedActions.getSavedContentItems.request(
                                true
                            ),
                            Action.contentSavedActions.setSavedContent(
                                newSavedArr
                            ),
                            //Action.contentSavedActions.setSavedContentToDisplay(newStateArr),
                            Action.contentSavedActions.setNotification({
                                show: true,
                                error: false,
                                text: localization.savedContent_message_unsaved,
                                key: 2,
                            })
                        );
                    }),
                    catchError((y) =>
                        of(
                            Action.contentSavedActions.unSaveContent.failure(y),
                            Action.contentSavedActions.setNotification({
                                show: true,
                                error: true,
                                text: localization.savedContent_message_error,
                                key: 4,
                            })
                        )
                    )
                );
        })
    );

export const saveContentEpics = [
    loadSavedContentFeature,
    loadSavedContentToDisplayEpic,
    saveContentFeature,
    unSaveContentFeature,
];
