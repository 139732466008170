import { RootEpic } from './root-epic';
import { forkJoin, of } from 'rxjs';
import moment from 'moment';
import {
    filter,
    map,
    switchMap,
    catchError,
    withLatestFrom,
    tap,
    ignoreElements,
} from 'rxjs/operators';
import { isActionOf } from 'typesafe-actions';
import { Action } from '../store/root-action';
import { List } from 'immutable';
import { EwritingListItem } from '../store/e-writing/data/ewriting-models';
import { LessonReportListItem } from '../store/lesson-reports/data/lesson-report-models';

const selectReportPageItem: RootEpic = (action$) =>
    action$.pipe(
        filter(isActionOf(Action.lessonReports.lessonReportPageItemSelected)),
        map((action) => {
            if (action.payload instanceof EwritingListItem) {
                return Action.lessonReports.ewritingListItemSelected(
                    action.payload as EwritingListItem
                );
            } else {
                return Action.lessonReports.lessonReportListItemSelected(
                    action.payload as LessonReportListItem
                );
            }
        })
    );

const loadLessonReportsAndEwritingsEpic: RootEpic = (
    action$,
    state$,
    { eWritingApi, lessonReportsApi }
) =>
    action$.pipe(
        filter(
            isActionOf(
                Action.lessonReports.loadLessonReportsAndEwritings.request
            )
        ),
        switchMap((action) => {
            const ewritings$ = eWritingApi
                .loadEwritingListWithTeachers(action.payload.learnerID)
                .pipe(map((x) => List(x)));

            const reports$ = lessonReportsApi
                .loadLessonReportsList(action.payload.learnerID)
                .pipe(map((x) => List(x)));

            const newReports$ = lessonReportsApi
                .loadLessonReportFromActivity(action.payload.learnerID)
                .pipe(map((x) => List(x)));

            const result$ = forkJoin({
                ewritings$,
                reports$,
                newReports$,
            }).pipe(
                map((r) => {
                    const reportLists = r.newReports$.concat(r.reports$);
                    const sortedListReportLessons = reportLists.sort(
                        (i: any, j: any) => {
                            return -(
                                moment(i.startDateTime).unix() -
                                moment(j.startDateTime).unix()
                            );
                        }
                    );
                    return Action.lessonReports.loadLessonReportsAndEwritings.success(
                        {
                            reports: sortedListReportLessons,
                            ewritings: r.ewritings$,
                        }
                    );
                }),
                catchError((e) =>
                    of(
                        Action.lessonReports.loadLessonReportsAndEwritings.failure(
                            e
                        )
                    )
                )
            );

            return result$;
        })
    );

const selectLessonReportAfterListLoadEpic: RootEpic = (action$, state$) =>
    action$.pipe(
        filter(
            isActionOf(
                Action.lessonReports.loadLessonReportsAndEwritings.success
            )
        ),
        withLatestFrom(state$),
        switchMap(([action, state]) => {
            if (state$.value?.eWriting?.preselectedEwritingLessonId) {
                let preselectedEwriting = action.payload.ewritings.find(
                    (e) =>
                        e.ewritingUUID ===
                        state$.value?.eWriting?.preselectedEwritingLessonId
                );

                if (preselectedEwriting)
                    return of(
                        Action.lessonReports.ewritingListItemSelected(
                            preselectedEwriting
                        )
                    );

                let preselectedReport = action.payload.reports.find((e) => {
                    return (
                        String(e.lessonId) ===
                        state$.value?.eWriting?.preselectedEwritingLessonId
                    );
                });

                if (preselectedReport)
                    return of(
                        Action.lessonReports.lessonReportListItemSelected(
                            preselectedReport
                        )
                    );
            }

            const firstItem =
                action.payload.ewritings.size > 0
                    ? action.payload.ewritings.get(0)
                    : action.payload.reports.size > 0
                    ? action.payload.reports.get(0)
                    : undefined;

            return state$.value?.app.currentAppMobileState
                ? of(Action.lessonReports.lessonReportPageItemSelected(null))
                : firstItem
                ? of(
                      Action.lessonReports.lessonReportPageItemSelected(
                          firstItem
                      )
                  )
                : of(Action.lessonReports.lessonReportPageItemSelected(null));
        })
    );

const loadLessonReportEpic: RootEpic = (
    action$,
    state$,
    { lessonReportsApi, lessonReportsService }
) =>
    action$.pipe(
        filter(isActionOf(Action.lessonReports.loadLessonReport.request)),
        withLatestFrom(state$),
        switchMap(([action, state]) => {
            return lessonReportsApi
                .loadLessonReport(action.payload.lessonID)
                .pipe(
                    map((x) =>
                        Action.lessonReports.loadLessonReport.success({
                            report: lessonReportsService.buildLessonReport(
                                x,
                                state?.lessonReports
                                    ?.selectedLessonReportListItem?.teacher,
                                state?.lessonReports
                                    ?.selectedLessonReportListItem?.learner
                            ),
                        })
                    ),
                    catchError((e) =>
                        of(Action.lessonReports.loadLessonReport.failure(e))
                    )
                );
        })
    );

const loadLessonReportEpicFromFeedback: RootEpic = (
    action$,
    state$,
    { lessonReportsApi, lessonReportsService }
) =>
    action$.pipe(
        filter(
            isActionOf(
                Action.lessonReports.loadLessonReportFromFeedback.request
            )
        ),
        withLatestFrom(state$),
        switchMap(([action, state]) => {
            return lessonReportsApi
                .lessonFromFeedbackReport(action.payload)
                .pipe(
                    map((x) => {
                        return Action.lessonReports.loadLessonReportFromFeedback.success(
                            {
                                report: lessonReportsService.buildLessonReport(
                                    x,
                                    state?.lessonReports
                                        ?.selectedLessonReportListItem?.teacher,
                                    state?.lessonReports
                                        ?.selectedLessonReportListItem?.learner
                                ),
                            }
                        );
                    }),
                    catchError((e) =>
                        of(
                            Action.lessonReports.loadLessonReportFromFeedback.failure(
                                e
                            )
                        )
                    )
                );
        })
    );

const lessonReportsPageOpened: RootEpic = (action$, state$, _) => {
    return action$
        .pipe(filter(isActionOf(Action.lessonReports.lessonReportsOpened)))
        .pipe(
            switchMap(() => {
                const learnerID = String(
                    state$.value.user.identity?.learnerUUID
                );

                return of(
                    Action.lessonReports.loadLessonReportsAndEwritings.request({
                        learnerID: learnerID,
                    })
                );
            })
        );
};

const lessonReportSaveToLocalStorage: RootEpic = (
    action$,
    state$,
    { lessonReportsService }
) => {
    return action$
        .pipe(filter(isActionOf(Action.lessonReports.printLessonReport)))
        .pipe(
            map((x) => x.payload),
            tap((p) => {
                lessonReportsService.saveLessonReportToLocalStorage(
                    p.listItem,
                    p.lessonReport
                );
            }),
            ignoreElements()
        );
};

const lessonReportsPrintOpened: RootEpic = (
    action$,
    state$,
    { lessonReportsService }
) => {
    return action$
        .pipe(filter(isActionOf(Action.lessonReports.lessonReportsPrintOpened)))
        .pipe(
            switchMap(() => {
                const data =
                    lessonReportsService.tryGetLessonReportFromLocalStorage();
                if (data.listItem && data.lessonReport) {
                    return of(
                        Action.lessonReports.lessonReportLoadedFromLocalStorageSuccess(
                            {
                                listItem: data.listItem,
                                lessonReport: data.lessonReport,
                            }
                        )
                    );
                } else {
                    return of(
                        Action.lessonReports.lessonReportLoadedFromLocalStorageFail()
                    );
                }
            })
        );
};

const lessonReportSelectedEpic: RootEpic = (action$) =>
    action$.pipe(
        filter(isActionOf(Action.lessonReports.lessonReportListItemSelected)),
        switchMap((action) => {
            return action?.payload?.derive === 'legacy'
                ? action?.payload?.lessonReportUUID
                    ? of(
                          Action.lessonReports.loadLessonReport.request({
                              lessonID: action.payload.lessonReportUUID,
                          })
                      )
                    : of(
                          Action.lessonReports.loadLessonReport.failure(
                              new Error('no lessonId')
                          )
                      )
                : action?.payload
                ? of(
                      Action.lessonReports.loadLessonReportFromFeedback.request(
                          {
                              lessonID: String(action.payload.lessonId || 0),
                              trainerID: String(
                                  action.payload.teacher.teacherUUID || 0
                              ),
                              endDateTime: action?.payload.endDateTime,
                              startDateTime: action?.payload.startDateTime,
                          }
                      )
                  )
                : of(
                      Action.lessonReports.loadLessonReportFromFeedback.failure(
                          new Error('no payload')
                      )
                  );
        })
    );

export const lessonReportsEpics = [
    loadLessonReportsAndEwritingsEpic,
    loadLessonReportEpicFromFeedback,
    loadLessonReportEpic,
    lessonReportsPageOpened,
    lessonReportSelectedEpic,
    selectLessonReportAfterListLoadEpic,
    lessonReportSaveToLocalStorage,
    lessonReportsPrintOpened,
    selectReportPageItem,
];
