import { Loading } from '@arcadia-projects/portal2-ui-library';
import { isEmpty } from 'lodash';
import React, { useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { useSSO } from '../../../features/auth/use-sso';
import { localization } from '../../../localization';
import Action from '../../../store/root-action';
import { isAuthenticated } from '../../../store/user/user-selectors';
import { SSOUtils } from '../../../utils/SSO';
import { useAppDispatch } from '../../root/app-dispatch';
import { urlToRedirectIdp } from '../lib/helpers';
import { GetHostname } from '../lib/use-get-domains';
import { GetSSOEndpoint } from '../lib/use-get-sso-endpoint';
import {
    IUseSSOToRetrieveIdp,
    UseSSOToRetrieveIdp,
} from '../lib/use-retrieve-idp';

export const useQuery = () => {
    const { search } = useLocation();
    return useMemo(() => new URLSearchParams(search), [search]);
};

export const SSOSamlconnector: React.FC = () => {
    const history = useHistory();
    const query = useQuery();
    const dispatch = useAppDispatch();
    const isUserAuthenticated = useSelector(isAuthenticated);

    const clientId = useMemo(() => query.get('client'), [query]);
    const samlRes = useMemo(() => query.get('SAMLResponse'), [query]);
    const clientUrl = useMemo(() => query.get('clientUrl'), [query]);
    const relayStateDeeplinkUrl = useMemo(
        () => query.get('RelayState'),
        [query]
    );

    const { SSOPostUser } = useSSO();

    const hostName = GetHostname();

    const isFromLogout = useMemo(() => {
        return relayStateDeeplinkUrl && relayStateDeeplinkUrl === '/logout';
    }, [relayStateDeeplinkUrl]);

    useEffect(() => {
        const errorLink = '/sso-error-login';
        const userToken = localStorage.getItem('Authorization');
        const paramClientsInfo = {
            clientId: clientId || '',
            clientUrl: clientUrl || hostName,
        };
        const apiEndpoint = GetSSOEndpoint(paramClientsInfo);
        if ((!isEmpty(clientId) && isEmpty(samlRes)) || isFromLogout) {
            // First step - page landing redirect to idp with backend fetched config
            const getIdp = UseSSOToRetrieveIdp(apiEndpoint);
            getIdp.subscribe((data: IUseSSOToRetrieveIdp) => {
                if (data instanceof Error) {
                    // Handle the error here
                    if (userToken) {
                        localStorage.removeItem('persist:root');
                        SSOUtils.getSSOParamsFromURL();
                        dispatch(
                            Action.user.loginSSO.request({
                                token: userToken,
                            })
                        );
                    } else {
                        history.push(errorLink);
                    }
                } else {
                    const urlRedirect = urlToRedirectIdp(data);
                    window.location.href = urlRedirect;
                }
            });
        } else if (!isEmpty(clientId) && !isEmpty(samlRes) && samlRes) {
            SSOPostUser(paramClientsInfo, samlRes);
        }
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (isUserAuthenticated) {
            console.log('SAML Connector: ', relayStateDeeplinkUrl);
            history.push(
                relayStateDeeplinkUrl
                    ? relayStateDeeplinkUrl === '/logout'
                        ? '/login'
                        : relayStateDeeplinkUrl
                    : '/dashboard'
            );
        }
        // eslint-disable-next-line
    }, [isUserAuthenticated, dispatch]);

    return <Loading title={localization.loading_label_Loading} />;
};
