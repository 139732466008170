import { RootEpic } from '../root-epic';
import { filter, map, mergeMap } from 'rxjs/operators';
import { concat } from 'rxjs';
import { isActionOf } from 'typesafe-actions';
import { Action } from '../../store/root-action';
import {
    WebsocketTabMessage,
    WebsocketChatMessage,
    WebsocketJoinMessage,
    WebsocketMessage,
    WebsocketFigureMessage,
    WebsocketJoinUserMessage,
} from '../../store/vcr/classroom/websocket/websocket-data';
import { from, of } from 'rxjs';
import {
    BoardFigureData,
    BoardTabData,
    BoardTabProps,
} from '../../store/vcr/classroom/board/board-data';
import { ChatUser } from '../../store/vcr/classroom/chat/chat-data';

const receiveWebsocketMessage: RootEpic = (action$, state$) =>
    action$.pipe(
        filter(isActionOf(Action.vcr.websocket.receiveCommand)),
        mergeMap((x) => {
            const {
                a: id,
                b: command,
                ah: timestamp,
                c: userId,
            } = x.payload as WebsocketMessage;

            const message = { id, command, timestamp, userId };

            // Join a class
            if (x.payload.b === 1001) {
                const {
                    // a: id,
                    // b: command,
                    // ah: timestamp,
                    c: userId,
                    aw: hideCamera,
                    f: arrayOfCommands,
                    r: { a: arrayOfShowBoard, e: arrayOfTabs },
                } = x.payload as WebsocketJoinMessage;

                // Initialize chat and figures on board
                const historyOfcommands = from(arrayOfCommands).pipe(
                    map((x) => Action.vcr.websocket.receiveCommand(x))
                );

                // Initialize board visibility
                const historyOfShowBoard = arrayOfShowBoard.reduce(
                    (sum, value) => sum + value.r,
                    0
                );
                const showBoard = of(
                    Action.vcr.classroom.board.showBoard(historyOfShowBoard > 0)
                );

                // Initialize video trainer camera visibility
                const hideCameraCommand = of(
                    Action.vcr.classroom.videoTrainer.showTrainer(!hideCamera)
                );

                // Initialize tabs
                const tabsCommand = from(
                    arrayOfTabs.flatMap(
                        ({
                            f: id,
                            k: hash,
                            i: number,
                            w: videoSrc,
                            g: isActiveTab,
                        }) => {
                            const imgSrc = hash
                                ? `/website/documentImage?h=${hash}&n=${number}&w=800`
                                : '';

                            const arrayOfActions = [
                                Action.vcr.classroom.board.addTab({
                                    id,
                                    imgSrc,
                                    videoSrc,
                                } as BoardTabProps),
                            ];

                            if (isActiveTab)
                                arrayOfActions.push(
                                    Action.vcr.classroom.board.setActiveTab(
                                        id
                                    ) as any
                                );

                            return arrayOfActions;
                        }
                    )
                );

                const isSkipCommand =
                    userId !== state$.value.vcr.classroom?.chat?.user?.id;

                const skipAction = of(Action.vcr.websocket.skipCommand());

                const arrayOfActions = concat(
                    historyOfcommands,
                    hideCameraCommand,
                    tabsCommand,
                    showBoard
                );

                return isSkipCommand ? skipAction : arrayOfActions;
            }
            // Join user
            if (x.payload.b === 351) {
                const {
                    a: id,
                    t: firstName,
                    u: lastName,
                    r: role,
                } = x.payload as WebsocketJoinUserMessage;

                return of(
                    Action.vcr.classroom.chat.addUser(
                        new ChatUser({ id, firstName, lastName, role })
                    )
                );
            }

            // Add message
            if (x.payload.b === 1011) {
                const { s: text } = x.payload as WebsocketChatMessage;
                return of(
                    Action.vcr.classroom.chat.addMessage({
                        ...message,
                        text,
                    })
                );
            }
            // Clear chat messages
            if (x.payload.b === 1012) {
                return of(Action.vcr.classroom.chat.clearMessages());
            }

            // Add tab
            if (x.payload.b === 2001 || x.payload.b === 2011) {
                const { i: id, an: videoSrc } =
                    x.payload as WebsocketTabMessage;
                return from([
                    Action.vcr.classroom.board.addTab(
                        new BoardTabData({ id, videoSrc })
                    ),
                    Action.vcr.classroom.board.setActiveTab(id),
                ]);
            }

            // Select tab
            if (x.payload.b === 2002) {
                const { i: id } = x.payload as WebsocketTabMessage;

                return of(Action.vcr.classroom.board.setActiveTab(id));
            }

            // Close tab
            if (x.payload.b === 2003) {
                const { i: id, h: nextId } = x.payload as WebsocketTabMessage;

                const arrayOfActions = [
                    Action.vcr.classroom.board.closeTab(id),
                ];

                if (nextId) {
                    arrayOfActions.push(
                        Action.vcr.classroom.board.setActiveTab(nextId) as any
                    );
                }

                return from(arrayOfActions);
            }

            // Show board
            if (x.payload.b === 3201) {
                return of(Action.vcr.classroom.board.showBoard(true));
            }

            // Add board content
            if (x.payload.b === 4001) {
                const {
                    i: id,
                    u: hashString,
                    t: number,
                    o: fileName,
                } = x.payload as WebsocketTabMessage;

                const imgSrc = `/website/documentImage?h=${hashString}&n=${number}&w=800`;
                const userClassId =
                    state$.value.vcr.classroom?.chat?.user?.classId.toString();
                return of(
                    Action.vcr.classroom.board.addTabContent(
                        new BoardTabData({ id, imgSrc })
                    ),
                    Action.fetchMessageLink.vcrFetchMessage.request({
                        label: fileName as string,
                        classId: userClassId as string,
                        hash: hashString as string,
                        chatId: message.id as number,
                    })
                );
            }

            // Play video tab
            if (x.payload.b === 4212) {
                const { i: id, aq: videoPosition } =
                    x.payload as WebsocketTabMessage;

                return of(
                    Action.vcr.classroom.board.playVideoTab(
                        new BoardTabData({
                            id,
                            isVideoPlaying: true,
                            videoPosition,
                        })
                    )
                );
            }

            // Stop video tab
            if (x.payload.b === 4213) {
                const { i: id } = x.payload as WebsocketTabMessage;

                return of(
                    Action.vcr.classroom.board.stopVideoTab(
                        new BoardTabData({
                            id,
                            isVideoPlaying: false,
                        })
                    )
                );
            }

            // Hide video trainer
            if (x.payload.b === 3003) {
                return of(Action.vcr.classroom.videoTrainer.showTrainer(false));
            }

            // Show video trainer
            if (x.payload.b === 3004) {
                return of(Action.vcr.classroom.videoTrainer.showTrainer(true));
            }

            // Draw figures
            if (
                x.payload.b === 7001 ||
                x.payload.b === 7002 ||
                x.payload.b === 7003 ||
                x.payload.b === 7004 ||
                x.payload.b === 7005
            ) {
                const {
                    a: id,
                    i: tabId,
                    x: color,
                    k: left,
                    m: width,
                    n: height,
                    l: top,
                } = x.payload as WebsocketFigureMessage;

                const mapCommandToType = {
                    7001: 'pointer',
                    7002: 'line',
                    7003: 'rectangle',
                    7004: 'circle',
                    7005: 'highlighter-rectangle',
                };

                const type = mapCommandToType[x.payload.b] as any;

                return of(
                    Action.vcr.classroom.board.addFigure(
                        new BoardFigureData({
                            id,
                            tabId,
                            left,
                            top,
                            width,
                            height,
                            type,
                            color,
                        })
                    )
                );
            }

            // Draw text
            if (x.payload.b === 7101) {
                const {
                    a: id,
                    i: tabId,
                    x: color,
                    k: left,
                    l: top,
                    s: text,
                } = x.payload as WebsocketFigureMessage;

                return of(
                    Action.vcr.classroom.board.addFigure(
                        new BoardFigureData({
                            id,
                            tabId,
                            left,
                            top,
                            text,
                            type: 'text',
                            color,
                        })
                    )
                );
            }

            // Remove figure
            if (x.payload.b === 7501) {
                const { h: id } = x.payload as WebsocketFigureMessage;

                return of(
                    Action.vcr.classroom.board.removeFigure({
                        id,
                        tabId: state$.value.vcr.classroom.board.activeTab,
                    })
                );
            }

            // Clear all figures on active tab
            if (x.payload.b === 7502) {
                return of(
                    Action.vcr.classroom.board.clearFigures(
                        state$.value.vcr.classroom.board.activeTab
                    )
                );
            }

            return of(Action.vcr.websocket.receiveUnknownCommand(x.payload));
        })
    );

export const websocketEpics = [receiveWebsocketMessage];
