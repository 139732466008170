import { RootState } from 'typesafe-actions';
import { createSelector } from 'reselect';
import * as _ from 'lodash';

export const offlineSelector = (state: RootState) => state?.resources?.offline;

export const offlineQuizSelector = (state: RootState) =>
    state.resources.offline.displayedResourceQuiz;

export const isLoadingDownloadSelector = (state: RootState) => {
    return state.resources.offline.isLoadingDownloadAction ?? false;
};

export const isLoadingSynchroSelector = (state: RootState) => {
    return state.resources.offline.isSyncDownloading;
};

export const coursesSelector = createSelector(
    offlineSelector,
    (offline) => offline.displayedCourseList
);

export const quizSelector = createSelector(
    offlineSelector,
    (offline) => offline.displayedCourseList
);

export const courseListSelector = createSelector(
    offlineSelector,
    (offline) => offline.displayedCourseList.courseList
);

export const courseResourceListSelector = createSelector(
    offlineSelector,
    (offline) => offline.displayedResourceList.valueSeq().toArray()
);

export const coursesResourceListSelector = createSelector(
    offlineSelector,
    (offline) => offline.displayedCourseList.resourceList.valueSeq().toArray()
);

export const displayedResourceSelector = createSelector(
    offlineSelector,
    (offline) => offline.displayedResource
);

export const displayedResourceContentSelector = createSelector(
    offlineSelector,
    (offline) => offline.displayedResourceContent
);

export const quizListSelector = createSelector(
    offlineQuizSelector,
    (displayQuiz) => displayQuiz.quiz
);

export const assetsListSelector = createSelector(offlineSelector, (offline) => {
    return offline?.displayedAssets;
});

export const activityResourceTrackingSelector = createSelector(
    offlineSelector,
    (offline) => {
        return offline?.activityResourceTracking;
    }
);

export const setupStateSelector = createSelector(offlineSelector, (offline) => {
    return offline?.setupState;
});

export const connectionActiveSelector = createSelector(
    offlineSelector,
    (offline) => {
        return offline?.connectionActive;
    }
);

export const activeResourceDisplayedSelector = createSelector(
    offlineSelector,
    (offline) => {
        return offline?.activeResourceDisplayed;
    }
);

export const selectAssetsEncode = createSelector(
    (state: any) => state?.resources?.offline?.displayedAssets,
    (k: any, url: any) => url, // this is the parameter we need
    (assets: any, url: any) => {
        return assets.get(url)?.encoded;
    }
);

export const selectAssetsEncodeCustomHook = createSelector(
    [
        // Usual first input - extract value from `state`
        (state) => state,
        // Take the second arg, `category`, and forward to the output selector
        (state, url) => url,
    ],
    // Output selector gets (`items, category)` as args
    (items, url) => {
        return items.get(url)?.encoded;
    }
);

// selector.js
export const selectResourcesIdsByCourse = createSelector(
    [
        // Usual first input - extract value from `state`
        (state) => state,
        // Take the second arg, `category`, and forward to the output selector
        (state, courseId) => courseId,
    ],
    // Output selector gets (`items, category)` as args
    (items, courseId) => {
        return _.uniq(
            [].concat.apply(
                [],
                items
                    .filter((item: any) => {
                        const { courseUuid } = item;
                        return courseUuid === courseId;
                    })
                    ?.map((k: any) => {
                        const { resourceUuid } = k;
                        return resourceUuid;
                    })
            )
        );
    }
);

// selector.js
export const selectResourcesByCourse = createSelector(
    [
        // Usual first input - extract value from `state`
        (state) => state,
        // Take the second arg, `category`, and forward to the output selector
        (state, resourcesIds) => resourcesIds,
    ],
    // Output selector gets (`items, category)` as args
    (items, resourcesIds) => {
        return resourcesIds.map((itemUuid: string) =>
            items.find((y: any) => {
                return y.uuid === itemUuid;
            })
        );
    }
);

export const selectResourcesWithDetailsByCourse = createSelector(
    [
        // Usual first input - extract value from `state`
        (state) => state,
        // Take the second arg, `category`, and forward to the output selector
        (state, resourcesDetails) => resourcesDetails,
    ],
    // Output selector gets (`items, category)` as args
    (items, resourcesDetails) => {
        const withDetail = items.filter((l: any) =>
            resourcesDetails.find((y: any) => {
                return y.contentUUId === l.uuid;
            })
        );

        return withDetail;
    }
);

// selector.js
export const selectCourseByUuid = createSelector(
    [
        // Usual first input - extract value from `state`
        (state) => state,
        // Take the second arg, `category`, and forward to the output selector
        (state, courseUuid) => courseUuid,
    ],
    // Output selector gets (`items, category)` as args
    (items, courseUuid) => {
        return items.get(courseUuid);
    }
);

// selector.js
export const selectItemFromSelector = createSelector(
    [
        // Usual first input - extract value from `state`
        (state) => state,
        // Take the second arg, `category`, and forward to the output selector
        (state, resourceUuid) => resourceUuid,
    ],
    // Output selector gets (`items, category)` as args
    (items, resourceUuid) => {
        return items.get(String(resourceUuid));
    }
);
