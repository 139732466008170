import { Record } from 'immutable';

export enum AlertType {
    Error = 'error',
    Success = 'success',
    Info = 'info',
}

const defaultAlertProps: AlertProps = {
    type: AlertType.Error,
    content: '',
};

export interface AlertProps {
    type: AlertType;
    content: string;
}

/**
 * Class describing alert: type and message content
 */
export class Alert extends Record(defaultAlertProps) implements AlertProps {}
