import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { CustomScroll, Icon } from '@arcadia-projects/portal2-ui-library';
import './privacy-policy-detail.css';

export const PrivacyPolicyDetail: React.FC = () => {
    const history = useHistory();

    const handleBack = useCallback(() => {
        history.goBack();
    }, [history]);

    return (
        <>
            <div className="privacy-policy-wrapper">
                <CustomScroll>
                    <div className="back-main-wrapper">
                        <div
                            className="back-wrapper"
                            onClick={handleBack}
                            onTouchStart={() => {}}
                        >
                            <Icon name="back" className="" />
                        </div>
                    </div>

                    <div className="entry-content">
                        <div id="et-boc" className="et-boc">
                            <div className="et-l et-l--post">
                                <div className="et_builder_inner_content et_pb_gutters3">
                                    <div className="et_pb_section et_pb_section_0 content-container et_section_regular">
                                        <div className="et_pb_row et_pb_row_0">
                                            <div
                                                className="et_pb_column et_pb_column_4_4 et_pb_column_0  et_pb_css_mix_blend_mode_passthrough et-last-child"
                                                id="header"
                                            >
                                                <div className="et_pb_module et_pb_text et_pb_text_0  et_pb_text_align_center et_pb_bg_layout_light">
                                                    <div className="et_pb_text_inner">
                                                        <h1>
                                                            goFLUENT服务隐私政策
                                                        </h1>
                                                    </div>
                                                </div>{' '}
                                                <div className="et_pb_module et_pb_text et_pb_text_1  et_pb_text_align_center et_pb_bg_layout_light">
                                                    <div className="et_pb_text_inner">
                                                        <p>
                                                            更新日期：
                                                            2022年10月20日
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>{' '}
                                    <div className="et_pb_section et_pb_section_1 et_section_regular">
                                        <div className="et_pb_row et_pb_row_1">
                                            <div className="et_pb_column et_pb_column_4_4 et_pb_column_1  et_pb_css_mix_blend_mode_passthrough et-last-child">
                                                <div className="et_pb_module et_pb_text et_pb_text_2  et_pb_text_align_left et_pb_bg_layout_light">
                                                    <div className="et_pb_text_inner">
                                                        <p>
                                                            goFLUENT承诺保护个人数据不被滥用，遵守《通用数据保护条例》2016/679（“GDPR”）、中华人民共和国适用法律以及goFLUENT运营所在的其他数据保护立法，保护个人数据保护和隐私权利。
                                                        </p>
                                                        <p>
                                                            goFLUENT Group
                                                            SA及其子公司，包括上海古浮兰企业管理咨询有限公司，（“goFLUENT”、“公司”、“我们”）制定了本隐私政策，以表明我们对个人（“您”、“您的”）数据保护和隐私权利的坚定承诺。本隐私政策概述了我们如何处理您的个人数据以及您可能在本网站上提供的可用于直接或间接识别您身份的所有信息。我们也会向您说明您的隐私权。
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>{' '}
                                    <div
                                        id="table-of-content"
                                        className="et_pb_section et_pb_section_2 et_section_regular"
                                    >
                                        <div className="et_pb_row et_pb_row_2">
                                            <div className="et_pb_column et_pb_column_4_4 et_pb_column_2  et_pb_css_mix_blend_mode_passthrough et-last-child">
                                                <div className="et_pb_module et_pb_text et_pb_text_3  et_pb_text_align_left et_pb_bg_layout_light">
                                                    <div className="et_pb_text_inner">
                                                        <ul className="mother-container">
                                                            <li>
                                                                <a
                                                                    href="#general-information"
                                                                    data-et-has-event-already="true"
                                                                >
                                                                    1. 基本信息
                                                                </a>
                                                                <ul>
                                                                    <li>
                                                                        <a
                                                                            href="#who-we-are"
                                                                            data-et-has-event-already="true"
                                                                        >
                                                                            1.1.
                                                                            我们是谁
                                                                        </a>
                                                                    </li>
                                                                    <li>
                                                                        <a
                                                                            href="#when-does-this-privacy-policy-apply"
                                                                            data-et-has-event-already="true"
                                                                        >
                                                                            1.2.
                                                                            本隐私政策何时适用？
                                                                        </a>
                                                                    </li>
                                                                    <li>
                                                                        <a
                                                                            href="#what-data-do-we-collect"
                                                                            data-et-has-event-already="true"
                                                                        >
                                                                            1.3.
                                                                            我们会处理哪些数据？
                                                                        </a>
                                                                        <ul>
                                                                            <li>
                                                                                <a
                                                                                    href="#general-data"
                                                                                    data-et-has-event-already="true"
                                                                                >
                                                                                    a.
                                                                                    通用数据
                                                                                </a>
                                                                            </li>
                                                                            <li>
                                                                                <a
                                                                                    href="#personal-data"
                                                                                    data-et-has-event-already="true"
                                                                                >
                                                                                    b.
                                                                                    个人数据
                                                                                </a>
                                                                            </li>
                                                                            <li>
                                                                                <a
                                                                                    href="#sensitive-personal-data"
                                                                                    data-et-has-event-already="true"
                                                                                >
                                                                                    c.
                                                                                    敏感个人信息
                                                                                </a>
                                                                            </li>
                                                                            <li>
                                                                                <a
                                                                                    href="#children-personal-data"
                                                                                    data-et-has-event-already="true"
                                                                                >
                                                                                    d.
                                                                                    儿童个人数据
                                                                                </a>
                                                                            </li>
                                                                            <li>
                                                                                <a
                                                                                    href="#personal-data-of-deceased-persons"
                                                                                    data-et-has-event-already="true"
                                                                                >
                                                                                    e.
                                                                                    已故人士的个人数据
                                                                                </a>
                                                                            </li>
                                                                        </ul>
                                                                    </li>
                                                                    <li>
                                                                        <a
                                                                            href="#where-do-we-process-and-transfer-your-personal-data"
                                                                            data-et-has-event-already="true"
                                                                        >
                                                                            1.4.
                                                                            您的个人数据将在何处处理和传输？
                                                                        </a>
                                                                        <ul>
                                                                            <li>
                                                                                <a
                                                                                    href="#where-gofluent-stores-your-data"
                                                                                    data-et-has-event-already="true"
                                                                                >
                                                                                    a.
                                                                                    goFLUENT
                                                                                    存储您的数据的位置。
                                                                                </a>
                                                                            </li>
                                                                            <li>
                                                                                <a
                                                                                    href="#how-do-we-handle-your-personal-data-globally"
                                                                                    data-et-has-event-already="true"
                                                                                >
                                                                                    b.
                                                                                    我们如何在全球范围内处理您的个人数据？
                                                                                </a>
                                                                            </li>
                                                                        </ul>
                                                                    </li>
                                                                    <li>
                                                                        <a
                                                                            href="#how-do-we-secure-and-store-your-personal-data"
                                                                            data-et-has-event-already="true"
                                                                        >
                                                                            1.5.
                                                                            我们如何保护和存储您的个人数据？
                                                                        </a>
                                                                        <ul>
                                                                            <li>
                                                                                <a
                                                                                    href="#data-security"
                                                                                    data-et-has-event-already="true"
                                                                                >
                                                                                    a.
                                                                                    数据安全
                                                                                </a>
                                                                            </li>
                                                                            <li>
                                                                                <a
                                                                                    href="#handling-data-incidents"
                                                                                    data-et-has-event-already="true"
                                                                                >
                                                                                    b.
                                                                                    处理数据事件
                                                                                </a>
                                                                            </li>
                                                                            <li>
                                                                                <a
                                                                                    href="#data-retention-policy"
                                                                                    data-et-has-event-already="true"
                                                                                >
                                                                                    c.
                                                                                    数据保存政策
                                                                                </a>
                                                                            </li>
                                                                        </ul>
                                                                    </li>
                                                                    <li>
                                                                        <a
                                                                            href="#your-individual-data-protection-rights"
                                                                            data-et-has-event-already="true"
                                                                        >
                                                                            1.6.
                                                                            您的个人数据保护权利
                                                                        </a>
                                                                    </li>
                                                                    <li>
                                                                        <a
                                                                            href="#cookie-policy"
                                                                            data-et-has-event-already="true"
                                                                        >
                                                                            1.7.
                                                                            Cookie政策
                                                                        </a>
                                                                    </li>
                                                                </ul>
                                                            </li>

                                                            <li>
                                                                <a
                                                                    href="#corporate-language-academy"
                                                                    data-et-has-event-already="true"
                                                                >
                                                                    2.
                                                                    企业语言学院
                                                                </a>
                                                                <ul>
                                                                    <li>
                                                                        <a
                                                                            href="#your-personal-data-in-the-gofluent-corporate-language-academy"
                                                                            data-et-has-event-already="true"
                                                                        >
                                                                            2.1.
                                                                            您在goFLUENT企业语言学院中的个人数据。
                                                                        </a>
                                                                    </li>
                                                                    <li>
                                                                        <a
                                                                            href="#how-do-we-collect-your-personal-data"
                                                                            data-et-has-event-already="true"
                                                                        >
                                                                            2.2.
                                                                            我们如何收集您的个人数据？
                                                                        </a>
                                                                    </li>
                                                                    <li>
                                                                        <a
                                                                            href="#how-do-we-use-and-process-your-personal-data"
                                                                            data-et-has-event-already="true"
                                                                        >
                                                                            2.3.
                                                                            我们如何使用和处理您的个人数据？
                                                                        </a>
                                                                    </li>
                                                                    <li>
                                                                        <a
                                                                            href="#data-retention-policy"
                                                                            data-et-has-event-already="true"
                                                                        >
                                                                            2.4.
                                                                            数据保留政策
                                                                        </a>
                                                                    </li>
                                                                </ul>
                                                            </li>

                                                            <li>
                                                                <a
                                                                    href="#assessment-portal"
                                                                    data-et-has-event-already="true"
                                                                >
                                                                    3.
                                                                    语言能力测评门户
                                                                </a>
                                                                <ul>
                                                                    <li>
                                                                        <a
                                                                            href="#your-personal-data-on-the-assessment-portal"
                                                                            data-et-has-event-already="true"
                                                                        >
                                                                            3.1.
                                                                            您在语言能力测评门户网站上的个人数据
                                                                        </a>
                                                                    </li>
                                                                    <li>
                                                                        <a
                                                                            href="#where-does-your-personal-data-come-from"
                                                                            data-et-has-event-already="true"
                                                                        >
                                                                            3.2.
                                                                            您的个人数据从何而来？
                                                                        </a>
                                                                    </li>
                                                                    <li>
                                                                        <a
                                                                            href="#what-are-the-purposes-of-processing-your-personal-data"
                                                                            data-et-has-event-already="true"
                                                                        >
                                                                            3.3.
                                                                            处理您的个人数据的目的是什么？
                                                                        </a>
                                                                        <ul>
                                                                            <li>
                                                                                <a
                                                                                    href="#registration-to-our-assessment-portal"
                                                                                    data-et-has-event-already="true"
                                                                                >
                                                                                    a.
                                                                                    注册我们的语言能力测评门户
                                                                                </a>
                                                                            </li>
                                                                            <li>
                                                                                <a
                                                                                    href="#execution-of-the-assessment-test"
                                                                                    data-et-has-event-already="true"
                                                                                >
                                                                                    b.
                                                                                    进行语言能力评估测试
                                                                                </a>
                                                                            </li>
                                                                            <li>
                                                                                <a
                                                                                    href="#email-communications"
                                                                                    data-et-has-event-already="true"
                                                                                >
                                                                                    c.
                                                                                    电子邮件通讯
                                                                                </a>
                                                                            </li>
                                                                            <li>
                                                                                <a
                                                                                    href="#compliance-of-the-performance-of-our-services"
                                                                                    data-et-has-event-already="true"
                                                                                >
                                                                                    d.
                                                                                    遵守我们服务的执行情况
                                                                                </a>
                                                                            </li>
                                                                        </ul>
                                                                    </li>
                                                                    <li>
                                                                        <a
                                                                            href="#data-retention"
                                                                            data-et-has-event-already="true"
                                                                        >
                                                                            3.4.
                                                                            数据保存
                                                                        </a>
                                                                    </li>
                                                                </ul>
                                                            </li>
                                                            <li>
                                                                <a
                                                                    href="#how-to-contact-us"
                                                                    data-et-has-event-already="true"
                                                                >
                                                                    4.
                                                                    如何联系我们？
                                                                </a>
                                                            </li>
                                                            <li>
                                                                <a
                                                                    href="#effectivity-and-changes-on-this-privacy-policy"
                                                                    data-et-has-event-already="true"
                                                                >
                                                                    5.
                                                                    本隐私政策的有效性和变更
                                                                </a>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>{' '}
                                    <div className="et_pb_section et_pb_section_3 et_section_regular">
                                        <div className="et_pb_row et_pb_row_3">
                                            <div className="et_pb_column et_pb_column_4_4 et_pb_column_3  et_pb_css_mix_blend_mode_passthrough et-last-child">
                                                <div className="et_pb_module et_pb_text et_pb_text_4  et_pb_text_align_left et_pb_bg_layout_light">
                                                    <div className="et_pb_text_inner">
                                                        <h2 id="general-information">
                                                            1. 基本信息
                                                        </h2>
                                                        <h3 id="who-we-are">
                                                            1.1. 我们是谁
                                                        </h3>
                                                        <p>
                                                            goFLUENT Group
                                                            SA（Route de
                                                            Pré-Bois 20, 1215
                                                            Geneva,
                                                            Switzerland）是goFLUENT企业语言学院和goFLUENT语言能力测评门户的主要控制实体。
                                                        </p>
                                                        <p>
                                                            您在使用我们的服务时，goFLUENT作为“数据管制员“管理您的个人数据。goFLUENT尊重您的隐私，我们承诺按照GDPR的原则和责任来处理您的个人数据。
                                                        </p>
                                                        <p>
                                                            如果您对本隐私政策有任何疑虑或疑问，包括任何要求行使您与下文所述的数据保护相关的权利，请随时联系我们
                                                            <b>
                                                                指定的数据保护官（“DPO”）：
                                                            </b>{' '}
                                                            <a
                                                                href="mailto:privacy@gofluent.com"
                                                                className="cta-text"
                                                            >
                                                                privacy@gofluent.com
                                                            </a>
                                                        </p>
                                                        <p>
                                                            goFLUENT
                                                            承诺遵循GDPR第5条原则和中华人民共和国相关法律处理您的个人数据。
                                                        </p>
                                                        <h3 id="when-does-this-privacy-policy-apply">
                                                            1.2.
                                                            本隐私政策何时适用？
                                                        </h3>
                                                        <p>
                                                            本隐私政策适用于我们为提供企业语言学院、在线培训和语言能力测评服务而处理的所有您的个人数据。
                                                        </p>
                                                        <p>
                                                            我们的
                                                            <b>企业语言学院</b>
                                                            是您用于语言培训的学习门户。
                                                        </p>
                                                        <p>
                                                            我们的
                                                            <b>
                                                                语言能力测评门户
                                                            </b>
                                                            是您用于参加定级测试的门户网站。
                                                        </p>
                                                        <p>
                                                            要了解有关我们可能收集的个人数据及其使用目的等更多信息，请访问下文第2节企业语言学院和第3节语言能力测评门户。
                                                        </p>
                                                        <h3 id="what-data-do-we-collect">
                                                            1.3.
                                                            我们会处理哪些数据？
                                                        </h3>
                                                        <h4 id="general-data">
                                                            a. 通用数据
                                                        </h4>
                                                        <p>
                                                            我们会从我们的服务中收集并处理以下信息：
                                                        </p>
                                                        <ul>
                                                            <li>
                                                                <b>
                                                                    培训路径数据：
                                                                </b>{' '}
                                                                包括有关您的测试结果、课程报告、会话跟踪、绩效调查、测验和在门户上进行的活动等信息。
                                                            </li>
                                                            <li>
                                                                <b>
                                                                    语言能力测评数据：
                                                                </b>{' '}
                                                                包括您的评估测试结果。
                                                            </li>
                                                            <li>
                                                                <b>
                                                                    技术和使用数据：
                                                                </b>{' '}
                                                                包括您的密码、浏览器和设备信息，以及您的设备唯一识别号码（例如，Android
                                                                ID）以及您可能提供的和/或我们通过我们的服务收集的任何其他信息。这些数据对于向您的组织提供有关您使用我们服务的报告是必要的。
                                                            </li>
                                                        </ul>
                                                        <h4 id="personal-data">
                                                            b. 个人数据
                                                        </h4>
                                                        <p>
                                                            个人数据是指直接或间接识别您或其他个人的任何信息：
                                                        </p>
                                                        <ul>
                                                            <li>
                                                                <b>
                                                                    身份和联系方式：
                                                                </b>{' '}
                                                                姓名、职务、公司名称、工作职能、图像和声音，以及您的IP地址。
                                                            </li>
                                                            <li>
                                                                <strong>
                                                                    访问您的设备摄像头和录音权限：
                                                                </strong>
                                                                在您同意的情况下，访问这些数据可以让您在与培训师和其他参与者的个人和小组课程期间共享来自您的设备摄像头的视频流。如果您选择不允许访问，您仍然可以参加课程，但您的摄像头将不会显示您的画面。
                                                            </li>
                                                            <li>
                                                                <b>
                                                                    个人资料和使用数据：
                                                                </b>{' '}
                                                                包括您用于访问我们的服务的电子邮件或用户ID、您的通信偏好和有关您使用我们服务的信息，以便跟踪您的进度，让您获取这些信息。
                                                            </li>
                                                        </ul>
                                                        <p>
                                                            如果您提交与其他人有关的任何个人数据，则代表您已获得他人授权，并且您允许我们根据本隐私政策使用这些信息。
                                                        </p>
                                                        <h4 id="sensitive-personal-data">
                                                            c. 敏感个人信息
                                                        </h4>
                                                        <p>
                                                            goFLUENT不会为了向您推广服务，通过我们网站收集、使用或处理任何敏感的个人数据。为保证遵守内部承诺，我们要求您不要向我们发送或披露任何敏感的个人数据（例如，社会保险号、与种族或民族血统、性取向、政治观点、宗教或其他信仰、健康、基因或生物特征数据、犯罪背景或工会会员身份相关的信息）。
                                                        </p>
                                                        <h4 id="children-personal-data">
                                                            d. 儿童个人数据
                                                        </h4>
                                                        <p>
                                                            我们禁止13岁以下的任何人使用我们的网站或在线服务。如果您未满13岁，您不得在本网站上注册或使用本网站。如果您是家长或监护人，并且认为我们可能已收集有关儿童的信息，请通过{' '}
                                                            <a
                                                                href="mailto:privacy@gofluent.com"
                                                                className="cta-text"
                                                            >
                                                                privacy@gofluent.com
                                                            </a>
                                                            与我们联系。
                                                        </p>
                                                        <h4 id="personal-data-of-deceased-persons">
                                                            e.
                                                            已故人士的个人数据
                                                        </h4>
                                                        <p>
                                                            根据GDPR，《地方数据保护条例》可能会对处理已故人士个人数据的规则作出规定，我们将对能够代表已故人士的继承人作出回应，告知其能够代为行使的权利。
                                                        </p>
                                                        <h3 id="where-do-we-process-and-transfer-your-personal-data">
                                                            1.4.
                                                            您的个人数据将在何处处理和传输？
                                                        </h3>
                                                        <h4 id="where-gofluent-stores-your-data">
                                                            a. goFLUENT
                                                            存储您的数据的位置。
                                                        </h4>
                                                        <p>
                                                            goFLUENT
                                                            处理和存储您的个人数据的服务器位于欧洲经济区（“EEA”）。
                                                        </p>
                                                        <h4 id="how-do-we-handle-your-personal-data-globally">
                                                            b.
                                                            我们如何在全球范围内处理您的个人数据？
                                                        </h4>
                                                        <p>
                                                            作为全球性的公司集团的一部分，goFLUENT在欧洲经济区(“EEA”)内外都设有子公司。因此，每当goFLUENT根据本隐私政策第
                                                            2)
                                                            节企业语言学院和第
                                                            3)
                                                            节语言能力测评门户中的规定的目的使用或以其他方式处理您的个人数据时，我们可能会将其转移至EEA以外的国家或地区，包括一些数据保护法规与EEA的要求不匹配的国家或地区。无论何时发生此类转让，均基于标准合同条款（根据第46.2c条）GDPR和欧盟委员会2021/914号决定或未来的任何替代)，从而以合同方式规定您的个人数据受到
                                                            EEA内一定程度的保护
                                                            。
                                                        </p>
                                                        <p>
                                                            要了解可以访问您的个人数据的goFLUENT实体列表，您可以发送邮件至{' '}
                                                            <a
                                                                href="mailto:privacy@gofluent.com"
                                                                className="cta-text"
                                                            >
                                                                privacy@gofluent.com
                                                            </a>
                                                            与我们联系。
                                                        </p>
                                                        <p>
                                                            我们不会向第三方出售或分享您的个人资料。
                                                        </p>
                                                        <h3 id="how-do-we-secure-and-store-your-personal-data">
                                                            1.5.
                                                            我们如何保护和存储您的个人数据？
                                                        </h3>
                                                        <h4 id="data-security">
                                                            a. 数据安全
                                                        </h4>
                                                        <p>
                                                            goFLUENT已经实施了符合ISO
                                                            27001认证的适当的组织、技术和行政措施，以保护我们组织内的个人数据。这些措施包括信息安全工具，防止未经授权人员访问我们的系统。虽然我们采取了合理措施保护您的个人数据免于丢失、滥用、干扰和未经授权的访问、修改和披露，但您应知道，任何安全程序或协议都不能100%保证防止入侵或黑客攻击。因此，个人数据管理始终存在一定的风险。如果您认为goFLUENT处理的您的个人数据的安全性受到威胁，请立即通过privacy@gofluent.com通知我们。
                                                        </p>
                                                        <h4 id="handling-data-incidents">
                                                            b. 处理数据事件
                                                        </h4>
                                                        <p>
                                                            goFLUENT已经建立了一个内部框架，其中包括有关我们如何处理和解决您对数据保护和数据泄露的问题的审核和程序。
                                                        </p>
                                                        <p>
                                                            如果您认为有任何与我们的服务有关的个人数据泄露事件发生，请立即联系我们，并将详细信息发送到{' '}
                                                            <a
                                                                href="mailto:privacy@gofluent.com"
                                                                className="cta-text"
                                                            >
                                                                privacy@gofluent.com
                                                            </a>
                                                            。
                                                        </p>
                                                        <h4 id="data-retention-policy">
                                                            c. 数据保存政策
                                                        </h4>
                                                        <p>
                                                            当goFLUENT在GDPR允许的情况下处理和使用您的个人数据，goFLUENT将仅在满足处理您的个人数据的目的所需的时间范围内存储您的个人数据，如下文第
                                                            2) 节企业语言学院和
                                                            第3)节
                                                            语言能力测评门户部分所述。
                                                        </p>
                                                        <p>
                                                            然而，如果强制性法律要求goFLUENT保留您的个人数据更长时间，或者goFLUENT需要您的个人数据主张法律索赔或对法律索赔进行辩护，goFLUENT将保留您的个人数据，直到相关保留期结束或相关索赔得到解决。
                                                        </p>
                                                        <h3 id="your-individual-data-protection-rights">
                                                            1.6.
                                                            您的个人数据保护权利
                                                        </h3>
                                                        <p>
                                                            如果您有
                                                            <b>
                                                                任何要求行使与下述数据保护有关的权利，
                                                            </b>
                                                            请随时与我们联系：{' '}
                                                            <a
                                                                href="mailto:privacy@gofluent.com"
                                                                className="cta-text"
                                                            >
                                                                privacy@gofluent.com
                                                            </a>
                                                            ，我们承诺在三十(30)天内答复您的请求。
                                                        </p>
                                                        <p>
                                                            <b>a. 访问权：</b>{' '}
                                                            您可以在任何时候要求goFLUENT提供关于goFLUENT处理您的哪些个人数据的信息。
                                                        </p>
                                                        <p>
                                                            <b>b. 更正权:</b>{' '}
                                                            如果我们处理的个人数据不完整或不正确，您有权随时要求填写或更正这些数据。然而，我们鼓励您通过访问您的个人在线帐户来查看和更新​​您的个人数据。
                                                        </p>
                                                        <p>
                                                            <b>c. 删除权:</b>{' '}
                                                            您有权在任何时候要求删除您的个人资料。然而，如果goFLUENT因法律原因需要保留您的个人数据，您的个人数据将被限制进一步处理，但仅在法律要求的期限内保留。
                                                        </p>
                                                        <p>
                                                            因为goFLUENT不是您个人数据的数据管控者，所以在处理您的删除请求时，我们会将您的请求通知您的组织，以便给您一个符合您组织的数据保留政策的处理结果。
                                                        </p>
                                                        <p>
                                                            <b>
                                                                d.
                                                                限制处理的权利：
                                                            </b>{' '}
                                                            您可请求goFLUENT在以下任何事件中限制对您的个人数据做任何进一步的处理：
                                                        </p>
                                                        <ul>
                                                            <li>
                                                                您声明goFLUENT拥有的关于您的个人数据是不正确的，（但仅在goFLUENT要求检查相关个人数据的准确性时），
                                                            </li>
                                                            <li>
                                                                goFLUENT处理您的个人数据没有法律依据，您要求goFLUENT限制对您的个人数据做任何进一步的处理，
                                                            </li>
                                                            <li>
                                                                goFLUENT不再需要您的个人数据，但您声称您要求goFLUENT保留此类数据，以便主张或行使法律权利或针对第三方索赔进行辩护，或
                                                            </li>
                                                            <li>
                                                                如果您反对goFLUENT处理您的个人数据（基于goFLUENT的合法利益），只需要审查
                                                                goFLUENT是否拥有处理您个人数据的普遍权益或法定义务。
                                                            </li>
                                                        </ul>
                                                        <p>
                                                            如果您要求限制处理您的个人数据，在我们处理您的请求期间，您访问我们的解决方案及其他服务的权限将被暂停。
                                                        </p>
                                                        <p>
                                                            <b>e. 反对权：</b>{' '}
                                                            当goFLUENT根据本隐私政策中规定的条款合法处理您的数据时，您可以反对goFLUENT使用您的个人数据。如果您提出了反对的请求，goFLUENT将停止出于上述目的使用您的个人数据（即根据上述合法权益），并将数据从其系统中删除，除非goFLUENT可将此类个人数据用于本隐私声明中规定的其他目的，或者goFLUENT确定并证明了其拥有继续处理您的个人数据的令人信服的合法权益。
                                                        </p>
                                                        <p>
                                                            <b>
                                                                f.
                                                                撤回同意的权利：
                                                            </b>{' '}
                                                            如果您的个人数据是在您同意的基础上处理的，您有权在任何时候撤回您的同意。撤回您的同意并不影响在此之前基于同意的信息处理的合法性。
                                                        </p>
                                                        <p>
                                                            因为goFLUENT不是您个人数据的数据管控者，所以在处理您的撤回同意请求时，我们会将您的请求通知您的组织，以便为您提供符合您的组织内数据保留政策的处理结果。
                                                        </p>
                                                        <p>
                                                            <b>
                                                                g.
                                                                自动决策及资料收集:
                                                            </b>{' '}
                                                            我们尊重您的合法权利，不接受仅基于自动处理您的个人数据的决定（包括资料收集)。
                                                        </p>
                                                        <p>
                                                            在向您提供服务时，goFLUENT不会基于自动决策（包括资料收集），对您的数据进行任何处理。
                                                        </p>
                                                        <p>
                                                            <b>
                                                                h.
                                                                提出投诉的权利:
                                                            </b>{' '}
                                                            如果您认为goFLUENT未按照本隐私政策中规定的要求或适用的EEA数据保护法律处理您的个人数据，您可随时向您居住的EEA国家的数据保护机构或goFLUENT注册所在地的国家或州的数据保护机构提出申诉。
                                                        </p>
                                                        <h3 id="cookie-policy">
                                                            1.7. Cookie政策
                                                        </h3>
                                                        <p>
                                                            goFLUENT服务不会为实现其功能使用cookie或其他跟踪技术。
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>{' '}
                                    <div className="et_pb_section et_pb_section_4 et_section_regular">
                                        <div className="et_pb_row et_pb_row_4">
                                            <div className="et_pb_column et_pb_column_4_4 et_pb_column_4  et_pb_css_mix_blend_mode_passthrough et-last-child">
                                                <div className="et_pb_module et_pb_text et_pb_text_5  et_pb_text_align_left et_pb_bg_layout_light">
                                                    <div className="et_pb_text_inner">
                                                        <h2 id="corporate-language-academy">
                                                            2. 企业语言学院
                                                        </h2>
                                                        <h3 id="your-personal-data-in-the-gofluent-corporate-language-academy">
                                                            2.1.
                                                            您在goFLUENT企业语言学院中的个人数据。
                                                        </h3>
                                                        <p>
                                                            本隐私政策部分仅适用于goFLUENT企业语言学院的用户（“学员”）。
                                                        </p>
                                                        <p>
                                                            学员的定义：学员是组织的员工，其组织与goFLUENT签订了合同，有权使用goFLUENT企业语言学院提供的培训服务。
                                                        </p>
                                                        <h3 id="how-do-we-collect-your-personal-data">
                                                            2.2.
                                                            我们如何收集您的个人数据？
                                                        </h3>
                                                        <p>
                                                            我们以各种方式收集您的个人数据，包括：
                                                        </p>
                                                        <p>
                                                            <b>
                                                                a.
                                                                通过您的雇主或与您有关的组织：
                                                            </b>{' '}
                                                            当您的雇主通过直接向goFLUENT提供您的个人数据为您注册以使您能接受goFLUENT的服务时；
                                                        </p>
                                                        <p>
                                                            <b>
                                                                b.
                                                                通过goFLUENT企业语言学院（门户）：
                                                            </b>
                                                            当您进入我们的在线学习平台接受我们的服务时；当您在用户信息部分编辑和更新您的个人数据时；当您在上课时，通过聊天，写作练习，通过填写调查问卷和在与goFLUENT学习顾问团队沟通时共享和提供的其他信息。
                                                        </p>
                                                        <p>
                                                            <b>
                                                                c.
                                                                通过我们的app：
                                                            </b>
                                                            当您下载并使用goFLUENT
                                                            app或goFLUENT开发的其他app时，我们和我们的服务提供商会根据您的移动设备号码跟踪并收集app使用数据，例如，日期和时间、app访问我们的服务器的时间以及下载到app中的信息等数据；
                                                        </p>
                                                        <p>
                                                            <b>
                                                                d. 直接来自您：
                                                            </b>
                                                            当您联系我们更新您的个人数据或联系方式时。
                                                        </p>
                                                        <h3 id="how-do-we-use-and-process-your-personal-data">
                                                            2.3.
                                                            我们如何使用和处理您的个人数据？
                                                        </h3>
                                                        <p>
                                                            goFLUENT将根据GDPR中规定的法定许可处理您的个人数据，用于本隐私政策中所述的目的。
                                                        </p>
                                                        <p>
                                                            goFLUENT在提供服务的基础上使用您的个人数据。
                                                        </p>
                                                        <p>
                                                            根据 GDPR 第 6.1 b)
                                                            条，goFLUENT
                                                            为以下情况使用和处理您的个人数据：
                                                        </p>
                                                        <h4>
                                                            a.
                                                            注册并提供企业语言学院的访问权限
                                                        </h4>
                                                        <p>
                                                            为了让您能够访问goFLUENT企业语言学院，我们会用您的个人数据来注册并创建您的用户资料。
                                                        </p>
                                                        <p>
                                                            如果没有这些相关的个人数据，goFLUENT将无法创建此类用户资料，也无法为您提供访问我们的企业语言学院的权限。
                                                        </p>
                                                        <h4>
                                                            b.
                                                            个性化您的学员资料
                                                        </h4>
                                                        <p>
                                                            您在使用企业语言学院的过程中，goFLUENT为您提供了添加其他信息的选项，例如您的职位和行业。
                                                        </p>
                                                        <p>
                                                            此类信息的提供完全出于自愿。
                                                            如果您不希望提供此信息，您依然可以使用我们的服务。
                                                        </p>
                                                        <h4>c. 课程报告</h4>
                                                        <ul>
                                                            <li>
                                                                定级测试结果
                                                            </li>
                                                        </ul>
                                                        <p>
                                                            goFLUENT收集处理并存储您在首次访问我们的门户网站时参加的定级测试产生的信息。
                                                        </p>
                                                        <p>
                                                            我们处理数据以便为您提供定级测试结果。我们还会基于您的语言理解水平结果为您提供定制的内容和活动。
                                                        </p>
                                                        <ul>
                                                            <li>课程报告</li>
                                                        </ul>
                                                        <p>
                                                            您参加goFLUENT1对1私教课后，您的培训师会向您发送一份课程报告，其中包括您培训的相关信息和资源。
                                                        </p>
                                                        <h4>d. 课堂互动工具</h4>
                                                        <p>
                                                            基于goFLUENT向您提供的服务，为帮助您获得培训，您可以使用聊天、交互式白板或文件传输工具将文件发送给培训师。当您允许访问您的设备摄像头和录音权限时，我们将处理这些数据，使您对培训师可见，如果是小组课程，则对其他课程参与者可见，以便在课程中提供更深入的互动。
                                                        </p>
                                                        <h4>e. 支持服务</h4>
                                                        <p>
                                                            为了处理您的咨询和请求，我们可能会通过电话、聊天或电子邮件与您沟通。
                                                        </p>
                                                        <h4>f. 提醒和通知</h4>
                                                        <p>
                                                            我们会将您的课程通知发送到您的电子邮件地址。
                                                        </p>
                                                        <h4>g. 学员参与活动</h4>
                                                        <p>
                                                            我们可能会向您发送消息、问卷、调查或邀请参加由goFLUENT主办的培训相关活动，旨在帮助您使用我们的门户网站，邀请您参加活动和网络研讨会，并帮助您获得更多动力投入培训。
                                                        </p>
                                                        <p>
                                                            您在回答问卷和调查时无需提供您的任何个人数据。这些问卷和调查是为了了解您对我们门户网站的总体满意度。
                                                        </p>
                                                        <h4>
                                                            h.
                                                            使用情况和进度报告
                                                        </h4>
                                                        <p>
                                                            我们会向您报告您的门户使用情况和您在门户中的实时培训进度、您参加的测验和活动、您完成的满意度调查，以及您在门户上花费的时间。
                                                        </p>
                                                        <p>
                                                            这些信息作为我们报告服务的一部分，也会经过收集、整合之后提供给您的组织。
                                                        </p>
                                                        <h3 id="data-retention-policy">
                                                            2.4. 数据保留政策
                                                        </h3>
                                                        <p>
                                                            只要我们通过门户为您提供服务，goFLUENT就会保留您的个人数据。在您访问我们的门户网站和实时培训服务到期后，goFLUENT将遵守适用的法规，在必要的时间内存储您的个人数据，并用于审查和报告的目的。
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>{' '}
                                    <div className="et_pb_section et_pb_section_5 et_section_regular">
                                        <div className="et_pb_row et_pb_row_5">
                                            <div className="et_pb_column et_pb_column_4_4 et_pb_column_5  et_pb_css_mix_blend_mode_passthrough et-last-child">
                                                <div className="et_pb_module et_pb_text et_pb_text_6  et_pb_text_align_left et_pb_bg_layout_light">
                                                    <div className="et_pb_text_inner">
                                                        <h2 id="assessment-portal">
                                                            3. 语言能力测评门户
                                                        </h2>
                                                        <h3 id="your-personal-data-on-the-assessment-portal">
                                                            3.1.
                                                            您在语言能力测评门户网站上的个人数据
                                                        </h3>
                                                        <p>
                                                            本隐私政策部分仅适用于语言能力测评门户网站的用户（以下简称候选人）。
                                                        </p>
                                                        <p>
                                                            候选人的定义：候选人是指与他们有关的组织或在工作申请过程中要求评估其语言技能的评估门户网站的用户。
                                                        </p>
                                                        <h3 id="where-does-your-personal-data-come-from">
                                                            3.2.
                                                            您的个人数据从何而来？
                                                        </h3>
                                                        <p>
                                                            <b>
                                                                a.
                                                                通过您所应聘的组织：
                                                            </b>
                                                            您所在的组织直接向goFLUENT提供您的个人数据，注册您的账号，让您进行语言能力测评时；
                                                        </p>
                                                        <p>
                                                            <b>
                                                                b.
                                                                通过您的雇主或与您相关的组织：
                                                            </b>
                                                            您的雇主直接向goFLUENT提供您的个人数据，注册您的账号，以接受goFLUENT的服务时；
                                                        </p>
                                                        <p>
                                                            <b>
                                                                c.
                                                                通过自动化技术：
                                                            </b>
                                                            您与我们的语言能力测评门户互动时，我们可能会通过您的浏览器或您的设备自动收集某些信息，例如您的IP地址；
                                                        </p>
                                                        <p>
                                                            <b>
                                                                d.
                                                                通过我们的语言能力测评门户：
                                                            </b>
                                                            当您访问或登录我们的语言能力测评门户以接受我们的服务时，goFLUENT可能会根据您在测试中的表现收集您的信息。
                                                        </p>
                                                        <h3 id="what-are-the-purposes-of-processing-your-personal-data">
                                                            3.3.
                                                            处理您的个人数据的目的是什么？
                                                        </h3>
                                                        <p>
                                                            根据 GDPR 第 6.1 b)
                                                            条，我们将处理您的个人数据，以提供我们的服务：
                                                        </p>
                                                        <h4 id="registration-to-our-assessment-portal">
                                                            a.
                                                            注册我们的语言能力测评门户
                                                        </h4>
                                                        <p>
                                                            创建候选人档案需要相关且必要的个人数据，以便您访问我们的语言能力测评门户解决方案。这些数据对于创建候选人资料、为您提供访问我们的语言能力测评门户的权限是必要的。
                                                        </p>
                                                        <h4 id="execution-of-the-assessment-test">
                                                            b.
                                                            进行语言能力评估测试
                                                        </h4>
                                                        <p>
                                                            为了对您所选语言的词汇和语法理解进行评估，我们会在口语测试中记录您的形象和声音。该记录将仅用于评估您的流利程度。没有这些个人数据，我们将无法生成评估结果。
                                                        </p>
                                                        <h4 id="email-communications">
                                                            c. 电子邮件通讯
                                                        </h4>
                                                        <p>
                                                            我们会出于以下目的向您发送与我们的语言能力测评服务相关的电子邮件：
                                                        </p>
                                                        <ul>
                                                            <li>
                                                                确认您已经注册了我们的语言能力测评门户
                                                            </li>
                                                            <li>
                                                                向您发送有关如何使用我们的门户网站的说明、如何进行测试以及您可以测试的时间
                                                            </li>
                                                            <li>
                                                                在您的测试结果出来后向您通报。
                                                            </li>
                                                        </ul>
                                                        <p>
                                                            以上通讯与营销无关，只是为相关服务的执行所需。
                                                        </p>
                                                        <h4 id="compliance-of-the-performance-of-our-services">
                                                            d.
                                                            遵守我们服务的执行情况
                                                        </h4>
                                                        <p>
                                                            在您进行口语测试期间，我们会为您拍照。该图像将被发送给使用我们语言能力测评服务的组织，以核实您的身份。
                                                        </p>
                                                        <h3 id="data-retention">
                                                            3.4. 数据保存
                                                        </h3>
                                                        <p>
                                                            只要我们通过语言能力测评门户为您提供服务，goFLUENT就会保留您的个人数据。在您完成评估后，goFLUENT将遵守适用的法规，在必要的时间内存储您的个人数据，并用于审查和报告目的。
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>{' '}
                                    <div className="et_pb_section et_pb_section_6 et_section_regular">
                                        <div className="et_pb_row et_pb_row_6">
                                            <div className="et_pb_column et_pb_column_4_4 et_pb_column_6  et_pb_css_mix_blend_mode_passthrough et-last-child">
                                                <div className="et_pb_module et_pb_text et_pb_text_7  et_pb_text_align_left et_pb_bg_layout_light">
                                                    <div className="et_pb_text_inner">
                                                        <h2 id="how-to-contact-us">
                                                            4. 如何联系我们？
                                                        </h2>
                                                        <p>
                                                            goFLUENT尊重您的隐私，这对我们很重要。如果您对本隐私政策有任何疑问或问题，包括行使上述数据保护相关权利的任何请求，请随时联系我们指定的负责监督本隐私政策相关问题的数据保护官员（“DPO”）。
                                                        </p>
                                                        <p>
                                                            您可通过以下详细方式向我们发送您的疑问或问题：
                                                        </p>
                                                    </div>
                                                </div>{' '}
                                                <div
                                                    id="table-section"
                                                    className="et_pb_module et_pb_text et_pb_text_8  et_pb_text_align_left et_pb_bg_layout_light"
                                                >
                                                    <div className="et_pb_text_inner">
                                                        <table>
                                                            <tbody>
                                                                <tr>
                                                                    <th>
                                                                        信息栏
                                                                    </th>
                                                                    <th>
                                                                        详情
                                                                    </th>
                                                                </tr>
                                                                <tr>
                                                                    <td>DPO</td>
                                                                    <td>
                                                                        Evgeny
                                                                        Boychuk
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td>
                                                                        电子邮箱地址
                                                                    </td>
                                                                    <td>
                                                                        <a
                                                                            href="mailto:privacy@gofluent.com"
                                                                            className="cta-text"
                                                                        >
                                                                            privacy@gofluent.com
                                                                        </a>
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>{' '}
                                    <div className="et_pb_section et_pb_section_7 et_section_regular">
                                        <div className="et_pb_row et_pb_row_7">
                                            <div className="et_pb_column et_pb_column_4_4 et_pb_column_7  et_pb_css_mix_blend_mode_passthrough et-last-child">
                                                <div className="et_pb_module et_pb_text et_pb_text_9  et_pb_text_align_left et_pb_bg_layout_light">
                                                    <div className="et_pb_text_inner">
                                                        <h2 id="effectivity-and-changes-on-this-privacy-policy">
                                                            5.
                                                            本隐私政策的有效性和变更
                                                        </h2>
                                                        <p>
                                                            为确保更新我们的隐私政策以履行我们对您的数据保护和隐私权以及最佳实践的承诺，goFLUENT可能会不时修订本隐私政策。如上所述，更新日期为本隐私政策的有效修订日期。
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>{' '}
                                </div>
                            </div>
                        </div>
                    </div>
                </CustomScroll>
            </div>
        </>
    );
};
