import { PerformanceSurveyStepQuestions } from '../types';
import { collectAnswers } from './collectAnswers';

export const updateQuestionsResult = (
    questions: PerformanceSurveyStepQuestions[] | null,
    option: string,
    value: number | string
): PerformanceSurveyStepQuestions | null => {
    if (!questions || !questions.length) return null;
    const changed = questions.find((question) => question.option === option);
    if (changed) {
        return {
            ...changed,
            score: value,
        } as PerformanceSurveyStepQuestions;
    } else {
        const childs = collectAnswers(questions, false);
        const changedChilds = childs.find(
            (question) => question.option === option
        );
        if (changedChilds) {
            return {
                ...changedChilds,
                score: value,
            } as PerformanceSurveyStepQuestions;
        } else {
            return null;
        }
    }
};
