import { PortalEventType } from './portal-event-type';

export class PortalEvent {
    constructor(
        public readonly topicId: string,
        public readonly deviceId: string,
        public readonly userId: string,
        public readonly tabId: string,
        public timestamp: string,
        public readonly type: PortalEventType,
        public readonly portalId: string,
        public readonly attributes?: any
    ) {}
}
