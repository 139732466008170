import { TrackingApi } from './tracking-api';
import { PortalEvent } from './models/portal-event';
import { v4 as uuidv4 } from 'uuid';
import { BasePortalEvent } from './models/base-portal-event';
import packageJSON from '../../../package.json';
import { isCordova } from '../../utils/cordova';

export interface ITrackingService {
    /**
     * Track user activity
     * @param portalEvent
     * @param timestamp
     */
    track(portalEvent: PortalEvent, timestamp: number, userId: string): void;
}

export class TrackingService implements ITrackingService {
    private readonly deviceTabId: string;
    private readonly api: TrackingApi;
    private latitude: number | null;
    private longitude: number | null;

    constructor(private readonly trackingApi: TrackingApi) {
        this.deviceTabId = uuidv4();
        this.api = trackingApi;
        this.latitude = null;
        this.longitude = null;
    }

    private getDeviceId(): string {
        return localStorage.getItem('deviceId') || '';
    }

    private sendLog(
        portalEvent: PortalEvent,
        timestamp: number,
        userId: string
    ): void {
        const device = (window as any).device;

        const trackEvent: BasePortalEvent = {
            userId: userId,
            deviceId: this.getDeviceId(),
            deviceTabId: this.deviceTabId,
            portalEvent,
            timestamp,
            latitude: this.latitude,
            longitude: this.longitude,
            client: {
                app: {
                    type: isCordova ? 'native' : 'browser',
                    version: `${packageJSON.name} ${packageJSON.version}`,
                },
                browser: !isCordova
                    ? {
                          resolution: {
                              width: window.outerWidth,
                              height: window.outerHeight,
                          },
                      }
                    : undefined,
                native: isCordova
                    ? {
                          brand: device.manufacturer,
                          model: device.model,
                          platform: {
                              platform: device.platform,
                              platformversion: device.version,
                          },
                      }
                    : undefined,
            },
        };

        this.api.saveEntry(trackEvent).subscribe();
    }

    public track(portalEvent: PortalEvent, timestamp: number, userId: string) {
        this.sendLog(portalEvent, timestamp, userId);
    }
}
