// FullHD (Full High Definition) - aka 1080p 	1920x1080
// HD (High Definition) - aka 720p 	1280x720
// qHD (Quarter High Definition) - aka 540p 	960x540
// VGA (Video Graphics Array) 	640x480
// QCIF (Quarter Common Interface Format) 	176×144

export const DEFAULT_VIDEO_CONSTRAINTS: MediaStreamConstraints['video'] = {
    width: 640,
    height: 480,
    frameRate: 24,
};

// These are used to store the selected media devices in localStorage
export const SELECTED_AUDIO_INPUT_KEY = 'TwilioVideoApp-selectedAudioInput';
export const SELECTED_AUDIO_OUTPUT_KEY = 'TwilioVideoApp-selectedAudioOutput';
export const SELECTED_VIDEO_INPUT_KEY = 'TwilioVideoApp-selectedVideoInput';

// This is used to store the current background settings in localStorage
export const SELECTED_BACKGROUND_SETTINGS_KEY =
    'TwilioVideoApp-selectedBackgroundSettings';

export const GALLERY_VIEW_ASPECT_RATIO = 9 / 16; // 16:9
export const GALLERY_VIEW_MARGIN = 3;

export const SET_CUSTOM_TIMER_REFRESH = 1;

export const MAX_SURVEY_CALLS = 10;
export const SURVEY_PAUSE_TIME = 10 * 60 * 1000;

export const MUI_MOUNTERS = {
    mountOnEnter: true,
    unmountOnExit: true,
};

export const secretKey = 'goFLUENT-Secret';
