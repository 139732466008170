import {
    ILocalizationApi,
    LocalizationFile,
    OOOMessage,
} from './localization-api';
import { Observable, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { localization } from './index';
import { ILanguageApi } from './language-api';
import {
    LearningPreferrences,
    LocalizationLanguage,
} from '../store/user/user-record';

export interface ILocalizationService {
    changeUILanguage(
        userId: string,
        code: string,
        preferredLanguage: string
    ): Observable<void>;

    getUserLanguageCode(userId: string): Observable<string>;

    getUserLanguage(userId: string): Observable<LocalizationLanguage>;

    /**
     * @param userId
     * @param defaultLanguageCode
     */
    getUserLanguageByIdAndDefaultUserLanguage(
        preferredLanguageList: any,
        defaultLanguageCode?: string,
        learnerPreferreces?: any
    ): Observable<LearningPreferrences>;

    getUserLanguagePreferrence(userId: string): Observable<any>;

    getOOOMessage(language: string): Observable<OOOMessage>;
}

export class LocalizationService implements ILocalizationService {
    constructor(
        private localizationApi: ILocalizationApi,
        private languageApi: ILanguageApi
    ) {}

    getUserLanguageByIdAndDefaultUserLanguage(
        preferredLanguageList: any,
        defaultLanguageCode?: string,
        learnerPreferreces?: any
    ): Observable<LearningPreferrences> {
        const getQueryStringParamLtl = localStorage.getItem('localePref');
        if (getQueryStringParamLtl) {
            return this.languageApi
                .getPreferredLanguagesAndDefaultLanguageSSO(
                    getQueryStringParamLtl,
                    preferredLanguageList
                )
                .pipe(
                    map((lang) => {
                        return lang;
                    })
                );
        } else {
            if (learnerPreferreces) {
                return this.languageApi
                    .getPreferredLanguagesAndDefaultLanguage(
                        learnerPreferreces,
                        preferredLanguageList
                    )
                    .pipe(
                        map((lang) => {
                            return lang;
                        })
                    );
            } else if (defaultLanguageCode) {
                return this.languageApi.getLocalizationLanguageByFullCode(
                    defaultLanguageCode,
                    preferredLanguageList
                );
            }
            return of(
                LearningPreferrences.getDefaultLearnerPreferrencesEnglish()
            );
        }
    }

    getUserLanguagePreferrence(userId: string): Observable<any> {
        return this.localizationApi
            .getUserPreferredLanguageAndCode(userId)
            .pipe(
                map((code) => {
                    return code;
                }),
                catchError((error) => {
                    console.error('Cannot get language for user:', error);
                    return of('');
                })
            );
    }

    public changeUILanguage(
        userId: string,
        code: string,
        preferredLanguage: string
    ): Observable<void> {
        return this.downloadLocalizationForLanguage(code).pipe(
            map((file) => {
                let content: any = {};
                content[code] = this.getLanguageDictionaryAsJson(file);
                localization.setContent(content);
                localization.setLanguage(code);
                this.localizationApi
                    .setUserLanguageCode({
                        userId,
                        displayLanguage: code,
                        preferedCommunicationLanguage: preferredLanguage,
                    })
                    .subscribe();
            }),
            catchError((e) => {
                return of(
                    console.error(
                        'Can not load localization for language:',
                        code,
                        e
                    )
                );
            })
        );
    }

    public getUserLanguage(userId: string): Observable<LocalizationLanguage> {
        return this.localizationApi.getUserLanguageCode(userId).pipe(
            switchMap((code) => {
                return this.languageApi.getLocalizationLanguage(code);
            })
        );
    }
    public getOOOMessage(language: string): Observable<OOOMessage> {
        return this.localizationApi.loadOOOMessage(language);
    }

    public getUserLanguageCode(userId: string): Observable<string> {
        return this.localizationApi.getUserLanguageCode(userId).pipe(
            map((code) => code),
            catchError((error) => {
                console.error('Cannot get language for user:', error);
                return of('');
            })
        );
    }

    private downloadLocalizationForLanguage(
        code: string
    ): Observable<LocalizationFile> {
        return this.localizationApi.loadLanguageLocalization(code);
    }

    private getLanguageDictionaryAsJson(file: LocalizationFile) {
        if (file.version < 0) {
            return null;
        } else {
            let json = {};
            file.dictionary.forEach((x) => {
                // @ts-ignore
                json[x.key] = x.text;
            });
            return json;
        }
    }
}
