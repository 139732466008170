import { openDB } from 'idb';
import * as _ from 'lodash';
import {
    ResourcesDBSchema,
    QuizDBSchema,
    AssetsDBSchema,
    DownloadsDBSchema,
    CoursesDBSchema,
    ActivityTrackingDBSchema,
} from '../components/root/indexdb-helper';

import Question from './quizzes/question';
import {
    QuizAttemptDescriptionOffline,
    QuizAttemptDescription,
    QuizAttemptDTO,
} from './quizzes/quiz';
import { ActivityTrackingProps } from '../store/resources/offline/offline-actions';

/**
 *Service that tracks user activity in resources
 */
export class IndexDBService {
    /**
     *
     * Track user activity in resources
     */
    public async addActivityTracking(props: {
        learnerUUID: string;
        resource: Map<string, ActivityTrackingProps>;
    }): Promise<any> {
        const { learnerUUID, resource } = props;
        const connectionActivityDB = await openDB<ActivityTrackingDBSchema>(
            'Activity',
            1
        );
        const resourceArray = Array.from(resource.values());
        const connectionCoursesDB = await openDB<CoursesDBSchema>('Courses', 1);

        const activityFromDB = await connectionActivityDB.getAllFromIndex(
            'list',
            'activity-by-user',
            learnerUUID
        );

        const courseFromDB = await connectionCoursesDB.getAll('resourceList');
        const activityResource = resourceArray
            .map((y: any, i: number) => {
                const courseItem = courseFromDB.find(
                    (l: any) =>
                        l.courseUuid === y.courseUuid &&
                        l.resourceUuid === y.resourceUuid
                );
                return {
                    useruuid: learnerUUID,
                    resourceListId: String(courseItem?.id),
                    status: 'new',
                };
            })
            .filter((p) => {
                const checkActivity = activityFromDB.filter(
                    (i) => i.resourceListId === p.resourceListId
                );
                const checkUser = checkActivity.find(
                    (i) => i.useruuid === p.useruuid
                );
                return !checkUser;
            });

        const tx = connectionActivityDB.transaction('list', 'readwrite');
        await Promise.all([
            ...activityResource.map((i: any) => {
                return tx.store.add(i);
            }),
            tx.done,
        ]);
    }

    /**
     *
     * Track user activity in resources
     */
    public async addQuizActivityTracking(props: any): Promise<any> {
        const { learnerUUID, attempts, resourceDetail, quizItem } = props;

        const question = quizItem.questions.map((u: any) => new Question(u));
        const quizOriginal = quizItem?._data.qh?.map((h: QuizAttemptDTO) => {
            return QuizAttemptDescription.fromDTO(h, question);
        });

        const { courseUuid, resourceUuid } = resourceDetail;
        const filterCompleted = attempts
            .filter((y: any, o: any) => {
                const validateQuestion = quizOriginal.find((k: any) => {
                    return JSON.stringify(k) === JSON.stringify(y);
                });
                return !validateQuestion;
            })
            .map((x: any, i: number) => {
                return i;
            });

        const connectionActivityDB = await openDB<ActivityTrackingDBSchema>(
            'Activity',
            1
        );

        const connectionCoursesDB = await openDB<CoursesDBSchema>('Courses', 1);
        const couseFromDB = await connectionCoursesDB.getFromIndex(
            'resourceList',
            'resources-by-course-by-resourceid',
            [courseUuid, resourceUuid]
        );
        const activityFromDB = await connectionActivityDB.getFromIndex(
            'list',
            'activity-by-user-by-resource',
            [learnerUUID, String(couseFromDB?.id)]
        );

        const getAllTakeQuiz = await connectionActivityDB.getAll('takeQuiz');
        var uniqueFilterCompleted = _.uniqWith(filterCompleted, _.isEqual);

        if (activityFromDB) {
            const eventRecordQuiz = uniqueFilterCompleted
                .map((y: any) => {
                    return {
                        activityTrackingId: activityFromDB?.id,
                        attemptIndex: y,
                        status: 'new',
                    };
                })
                .filter((l: any) => {
                    const fromDBTakeQuizIndex = getAllTakeQuiz.filter(
                        (k) => k.attemptIndex === l.attemptIndex
                    );
                    return !fromDBTakeQuizIndex.find(
                        (j) => j.activityTrackingId === l.activityTrackingId
                    );
                });

            const tx = connectionActivityDB.transaction(
                'takeQuiz',
                'readwrite'
            );
            await Promise.all([
                ...eventRecordQuiz.map((i: any) => {
                    return tx.store.add(i);
                }),
                tx.done,
            ]);
        }
    }
    public async updateActivityTracking(props: any): Promise<any> {
        const { activity } = props;
        if (activity) {
            console.log('updateActivityTracking', activity);
            const connectionActivityDB = await openDB<ActivityTrackingDBSchema>(
                'Activity',
                1
            );

            const tx = connectionActivityDB.transaction('list', 'readwrite');
            await Promise.all([
                ...activity.map((i: any) => {
                    return tx.store.put(i);
                }),
                tx.done,
            ]);
        }
    }

    public async updateActivityTrackingQuiz(props: any): Promise<any> {
        const { quizActivity } = props;
        const connectionActivityDB = await openDB<ActivityTrackingDBSchema>(
            'Activity',
            1
        );

        const tx = connectionActivityDB.transaction('takeQuiz', 'readwrite');
        await Promise.all([
            ...quizActivity.map((i: any) => {
                return tx.store.put(i);
            }),
            tx.done,
        ]);
    }

    public async getResourceDetail(): Promise<any> {
        const connectionActivityDB = await openDB<ResourcesDBSchema>(
            'Resources',
            1
        );

        return await connectionActivityDB.getAll('detail');
    }

    public async removeQuizTrackAttempts(trackingId: string): Promise<any> {
        const connectionActivityDB = await openDB<ActivityTrackingDBSchema>(
            'Activity',
            1
        );
        const fromDb = await connectionActivityDB.getAllFromIndex(
            'takeQuiz',
            'activity-take-by-trackingid',
            trackingId
        );

        const takenAttempts = fromDb.map((y: any) => {
            return y.id as string;
        });

        const tx = connectionActivityDB.transaction('takeQuiz', 'readwrite');

        //Usage example using await:
        await Promise.all([
            ...takenAttempts.map((i: any) => {
                return tx.store.delete(i);
            }),
            tx.done,
        ]);
    }

    /**
     *
     * Track user activity in resources
     */
    public async removeAllDownloaded(learnerUUID: string): Promise<any> {
        const connectionDownloadsDB = await openDB<DownloadsDBSchema>(
            'Downloads',
            1
        );
        const fromDb = await connectionDownloadsDB.getAllFromIndex(
            'list',
            'download-by-user',
            learnerUUID
        );

        const downloaded = fromDb.map((y: any) => {
            return y.id as string;
        });

        const tx = connectionDownloadsDB.transaction('list', 'readwrite');

        //Usage example using await:
        await Promise.all([
            ...downloaded.map((i: any) => {
                return tx.store.delete(i);
            }),
            tx.done,
        ]);
    }

    /**
     *
     * Sync data
     */
    public async updateActivities(props: {
        learnerUUID: string;
        x: any;
        offlineDisplayedResource: any;
    }): Promise<any> {
        const { learnerUUID, x, offlineDisplayedResource } = props;
        const requestItem: any = { ...x.quiz.toJS() };
        const connectionQuizDB = await openDB<QuizDBSchema>('Quiz', 1);
        const fromDb = await connectionQuizDB.getFromIndex(
            'detail',
            'by-user-by-quizid',
            [learnerUUID, String(requestItem?.id?.qArticleId)]
        );

        if (fromDb?.quiz) {
            const newItem: any = JSON.parse(fromDb?.quiz);

            if (newItem) {
                let newQuiz = x.quizAttempt.toJS();
                const quizAttempts = newItem.attempts;
                let newQuizRecord: any = {};

                if (quizAttempts.length > 0) {
                    let newAttemptRecord = quizAttempts.map((quizData: any) => {
                        if (
                            quizData.qri === 0 &&
                            quizData.suppliedAnswers.length !==
                                quizData.questionsCount
                        ) {
                            return newQuiz;
                        } else if (
                            quizData.qri === 0 &&
                            quizData.suppliedAnswers.length ===
                                quizData.questionsCount
                        ) {
                            return quizData;
                        } else {
                            // withqri
                            if (newQuiz.qri === quizData.qri) {
                                if (
                                    JSON.stringify(newQuiz) ===
                                    JSON.stringify(quizData)
                                ) {
                                    return quizData;
                                } else {
                                    return newQuiz;
                                }
                            } else {
                                return quizData;
                            }
                        }
                    });

                    const allItemsAreFinished = newAttemptRecord
                        .map((y: any, i: number) => {
                            return {
                                ...y,
                                index: i,
                            };
                        })
                        .filter(
                            (x: any) =>
                                x.suppliedAnswers.length !== x.questionsCount
                        );

                    if (allItemsAreFinished.length === 0) {
                        if (newQuiz.qri === 0) {
                            const checkHasSame = JSON.stringify(
                                newAttemptRecord[0]
                            );
                            const qT = JSON.stringify(newQuiz);
                            if (qT === checkHasSame) {
                                newAttemptRecord = [
                                    JSON.parse(
                                        JSON.stringify(
                                            new QuizAttemptDescriptionOffline()
                                        )
                                    ),
                                    ...newAttemptRecord,
                                ];
                            } else {
                                newAttemptRecord = [
                                    newQuiz,
                                    ...newAttemptRecord,
                                ];
                            }
                        }
                    } else {
                        if (
                            !allItemsAreFinished.find((d: any) => d.index === 0)
                        ) {
                            newAttemptRecord = [
                                JSON.parse(
                                    JSON.stringify(
                                        new QuizAttemptDescriptionOffline()
                                    )
                                ),
                                ...newAttemptRecord,
                            ];
                        }
                    }

                    var uniqueAttempts = _.uniqWith(
                        newAttemptRecord,
                        _.isEqual
                    );

                    newQuizRecord = {
                        ...newItem,
                        attempts: uniqueAttempts,
                    };
                } else {
                    newQuizRecord = {
                        ...newItem,
                        attempts: [newQuiz],
                    };
                }

                await this.updateResourcesQuiz({
                    learnerUUID: learnerUUID,
                    item: newQuizRecord,
                });
                await this.addQuizActivityTracking({
                    resourceDetail: offlineDisplayedResource,
                    attempts: newQuizRecord.attempts,
                    quizItem: requestItem,
                    learnerUUID: learnerUUID,
                });
            }
        }
    }

    /**
     *
     * Track user activity in resources
     */
    public async updateResourcesQuiz(props: {
        learnerUUID: string;
        item: any;
    }): Promise<any> {
        const { learnerUUID, item } = props;
        const connectionQuizDB = await openDB<QuizDBSchema>('Quiz', 1);
        const fromDb = await connectionQuizDB.getFromIndex(
            'detail',
            'by-user-by-quizid',
            [learnerUUID, String(item?.id?.qArticleId)]
        );

        if (fromDb) {
            const keyPathId = fromDb?.id as string | '0' as string;
            const fromDbQuiz = fromDb?.quiz;
            const quizStringRecord = JSON.stringify(item);
            const tx = connectionQuizDB.transaction('detail', 'readwrite');
            if (quizStringRecord !== fromDbQuiz) {
                await Promise.all([
                    tx.store.put({
                        id: keyPathId,
                        useruuid: learnerUUID,
                        quiz: JSON.stringify(item),
                        quizId: String(item?.id?.qArticleId),
                        status: navigator.onLine === true ? 'old' : 'new',
                    }),
                    tx.done,
                ]);
            }
        }
    }

    /**
     *
     * Track user activity in resources
     */
    public async addAssets(props: { item: any }): Promise<any> {
        const { item } = props;
        const connectionAssetsDB = await openDB<AssetsDBSchema>('Assets', 1);
        const fromDb = await connectionAssetsDB.getAll('item');
        const Assets = Object.entries(item.toJS())
            .map((y: any) => {
                return {
                    encoded: y[1],
                    url: y[0],
                };
            })
            .filter((p) => {
                const checkHasFromDb = fromDb.find((i) => i.url === p.url);
                return !checkHasFromDb;
            });

        const tx = connectionAssetsDB.transaction('item', 'readwrite');
        await Promise.all([
            ...Assets.map((i: any) => {
                return tx.store.add(i);
            }),
            tx.done,
        ]);
    }

    /**
     *
     * Track user activity in resources
     */
    public async addResourcesQuiz(props: {
        learnerUUID: string;
        item: any;
    }): Promise<any> {
        const { learnerUUID, item } = props;
        const connectionDownloadsDB = await openDB<QuizDBSchema>('Quiz', 1);
        const fromDb = await connectionDownloadsDB.getAll('detail');
        const ResourceQuiz = Object.entries(item.toJS())
            .map((y: any, i: number) => {
                return {
                    useruuid: learnerUUID,
                    quiz: JSON.stringify(y[1]),
                    quizId: y[0],
                    status: 'old',
                };
            })
            .filter((p) => {
                const checkQuiz = fromDb.filter((i) => i.quizId === p.quizId);
                const checkUser = checkQuiz.find(
                    (i) => i.useruuid === p.useruuid
                );
                return !checkUser;
            })
            .map((k, i) => {
                return {
                    ...k,
                    id: String(fromDb.length + (i + 1)),
                };
            });

        const tx = connectionDownloadsDB.transaction('detail', 'readwrite');

        //Usage example using await:
        await Promise.all([
            ...ResourceQuiz.map((i: any) => {
                return tx.store.add(i);
            }),
            tx.done,
        ]);
    }

    /**
     *
     * Track user activity in resources
     */
    public async addResourcesDetails(props: {
        item: any;
        content: any;
    }): Promise<any> {
        const { item, content } = props;
        const connectionResourceDetailDB = await openDB<ResourcesDBSchema>(
            'Resources',
            1
        );
        const fromDb = await connectionResourceDetailDB.getAll('list');
        const toAdd = Object.keys(item.toJS())
            .map((y: string) => {
                return {
                    contentuuid: y,
                    itemResource: JSON.stringify(item.get(y)),
                    itemResourceContent: JSON.stringify(content.get(y)),
                };
            })
            .filter((p) => {
                const checkHasFromDb = fromDb.find(
                    (i) => i.contentuuid === p.contentuuid
                );
                return !checkHasFromDb;
            });

        const tx = connectionResourceDetailDB.transaction(
            'detail',
            'readwrite'
        );

        //Usage example using await:
        await Promise.all([
            ...toAdd.map((i: any) => {
                return tx.store.add(i);
            }),
            tx.done,
        ]);
    }

    /**
     *
     * Track user activity in resources
     */
    public async addResourcesList(props: { item: any }): Promise<any> {
        const { item } = props;
        const connectionResourcesListDB = await openDB<ResourcesDBSchema>(
            'Resources',
            1
        );
        const fromDb = await connectionResourcesListDB.getAll('list');
        const toAdd = Object.values(item.toJS())
            .map((y: any) => {
                return {
                    contentuuid: y.uuid,
                    contentItem: JSON.stringify(y),
                };
            })
            .filter((p) => {
                const checkHasFromDb = fromDb.find(
                    (i) => i.contentuuid === p.contentuuid
                );
                return !checkHasFromDb;
            });

        const tx = connectionResourcesListDB.transaction('list', 'readwrite');
        //Usage example using await:
        await Promise.all([
            ...toAdd.map((i: any) => {
                return tx.store.add(i);
            }),
            tx.done,
        ]);
    }

    /**
     *
     * Track user activity in resources
     */
    public async addResourcesByCourse(props: { item: any }): Promise<any> {
        const { item } = props;
        const connectionCoursesDB = await openDB<CoursesDBSchema>('Courses', 1);
        const fromDb = await connectionCoursesDB.getAll('resourceList');
        const ResourcesByCourse = Object.values(item.toJS())
            .map((y: any) => {
                return {
                    courseUuid: y.courseUuid,
                    resourceUuid: y.resourceUuid,
                };
            })
            .filter((p) => {
                const checkCourse = fromDb.filter(
                    (i) => i.courseUuid === p.courseUuid
                );
                const checkRource = checkCourse.find(
                    (i) => i.resourceUuid === p.resourceUuid
                );
                return !checkRource;
            });

        const tx = connectionCoursesDB.transaction('resourceList', 'readwrite');
        //Usage example using await:
        await Promise.all([
            ...ResourcesByCourse.map((i: any) => {
                return tx.store.add(i);
            }),
            tx.done,
        ]);
    }

    /**
     *
     * Track user activity in resources
     */
    public async addCourses(props: { item: any }): Promise<any> {
        const { item } = props;
        const connectionCourseDB = await openDB<CoursesDBSchema>('Courses', 1);
        const fromDb = await connectionCourseDB.getAll('list');
        const toAdd = Object.values(item.toJS())
            .map((y: any) => {
                return {
                    courseUuid: y.uuid,
                    contentItem: JSON.stringify(y),
                };
            })
            .filter((p) => {
                const checkHasFromDb = fromDb.find(
                    (i) => i.courseUuid === p.courseUuid
                );
                return !checkHasFromDb;
            });

        const tx = connectionCourseDB.transaction('list', 'readwrite');
        //Usage example using await:
        await Promise.all([
            ...toAdd.map((i: any) => {
                return tx.store.add(i);
            }),
            tx.done,
        ]);
    }

    /**
     *
     * Track user activity in resources
     */
    public async addDownloads(props: {
        learnerUUID: string;
        item: any;
    }): Promise<any> {
        const { learnerUUID = '', item } = props;
        const connectionDownloadsDB = await openDB<DownloadsDBSchema>(
            'Downloads',
            1
        );
        const fromDb = await connectionDownloadsDB.getAllFromIndex(
            'list',
            'download-by-user',
            learnerUUID
        );

        const toAdd = Object.keys(item.toJS())
            .map((y: any) => {
                return {
                    useruuid: learnerUUID,
                    courseUuid: y,
                };
            })
            .filter((p) => {
                const checkHasFromDb = fromDb.find(
                    (i) => i.courseUuid === p.courseUuid
                );
                return !checkHasFromDb;
            });

        const tx = connectionDownloadsDB.transaction('list', 'readwrite');
        await Promise.all([
            ...toAdd.map((i: any) => {
                return tx.store.add(i);
            }),
            tx.done,
        ]);
    }
}
