import { Record } from 'immutable';

export interface CountryCodeDto {
    CountryCodes: CountryCodeProps[];
}

export interface NumberValidationDto {
    IsValid: boolean;
}

export interface CountryCodeProps {
    CountryId: number;
    CountryName: string;
    Code: string;
}

const defaultCountryCodeProps: CountryCodeProps = {
    CountryId: 0,
    CountryName: '',
    Code: '',
};

export class CountryCodeData
    extends Record(defaultCountryCodeProps)
    implements CountryCodeProps {}

export interface InternetCallProps {
    fsIp: string;
    fsDomain: string;
    pwd: string;
    confId: number;
    learnerIp: string;
    extensionId: string;
    status: number;
    learnerId: string;
}

const defaultInternetCallProps: InternetCallProps = {
    fsIp: '',
    fsDomain: '',
    pwd: '',
    confId: 0,
    learnerIp: '',
    extensionId: '',
    status: 0,
    learnerId: '',
};

export class InternetCallData
    extends Record(defaultInternetCallProps)
    implements InternetCallProps {}
