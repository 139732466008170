import { createReducer } from 'typesafe-actions';

import { combineReducers } from 'redux';
import { Map } from 'immutable';
import { Action } from '../root-action';
import { CreditCreditAPIRecord } from './credits-data';

const currentUserCreditsReducer = createReducer(
    Map<string, CreditCreditAPIRecord>()
)
    .handleAction(Action.credits.loadCredits.success, (state, { payload }) => {
        return payload.credits;
    })
    .handleAction(Action.user.logout.success, (state) => state.clear());

export const creditsReducer = () =>
    combineReducers({
        currentUserCreditsReducer,
    });
