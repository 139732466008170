import { combineEpics } from 'redux-observable';
import { userEpics } from './user-epics';
import { quizzesEpics } from './quiz-epics';
import { resourcesEpics } from './resources';
import { errorHandlingEpics } from './errors-handling';
import { activitiesTrackingEpics } from './activities-tracking';
import { learnerProfileEpics } from './learner-profile-epics';
import { vcrEpics } from './vcr/vcr-epics';
import { schedulerEpics } from './scheduler-epics';
import { lessonsListEpics } from './lessons-list-epics';
import { trainingPathEpics } from './training-path-epics';
import { learnerOnboardingEpic } from './onboarding';
import { adaptiveTestEpics } from './adaptive-test-epics';
import { displayStrategyEpics } from './display-strategy-epics';
import { trackerEpics } from '../features/tracking/tracker-epics';
import { portalTrackerEpics } from './portal-tracker-epics';
import { lessonReportsEpics } from './lesson-reports-epics';
import { homeworkEpics } from './homework-epics';
import { creditEpics } from './credit-epics';
import { appEpics } from './app-epics';
import { trainingEpics } from './training-epics';
import { eWritingEpics } from './e-writing-epics';
import { contractEpics } from './contract-epics';
import { insightsEpics } from './insights-epics';
import { proficiencyEpics } from './proficiency-epics';
import { FetchMessageEpics } from './vcr/persist-message-epics';
import { gradingEpics } from './grading-epics';
import { activityFiltersEpics } from './activity-filters-epics';
import { learnerChatEpics } from '../features/contact-us/learner-chat-epics';
import { activityReportEpics } from './activity-report-epics';
import { integrationEpics } from './integration-epic';
import { restrictionEpics } from './restriciton-epics';
import { saveContentEpics } from './save-content';
import { inProgressTabEpics } from './in-progress';
import { offlineEpics } from './resources/offline-epics';

export const rootEpics = () =>
    combineEpics(
        ...resourcesEpics,
        ...userEpics,
        ...quizzesEpics,
        ...errorHandlingEpics,
        ...activitiesTrackingEpics,
        ...trainingPathEpics,
        ...learnerProfileEpics,
        ...learnerOnboardingEpic,
        ...vcrEpics,
        ...restrictionEpics,
        ...saveContentEpics,
        ...inProgressTabEpics,
        ...schedulerEpics,
        ...lessonsListEpics,
        ...adaptiveTestEpics,
        ...displayStrategyEpics,
        ...trackerEpics,
        ...lessonReportsEpics,
        ...homeworkEpics,
        ...creditEpics,
        ...appEpics,
        ...trainingEpics,
        ...eWritingEpics,
        ...contractEpics,
        ...insightsEpics,
        ...proficiencyEpics,
        ...FetchMessageEpics,
        ...portalTrackerEpics,
        ...gradingEpics,
        ...activityReportEpics,
        ...activityFiltersEpics,
        ...learnerChatEpics,
        ...integrationEpics,
        ...offlineEpics
    );
