import { List, Map } from 'immutable';

import { catchError, map } from 'rxjs/operators';
import { Axios } from 'axios-observable';

import moment from 'moment';

import { ApiHeaders } from './api-headers';

import {
    Availability,
    Teacher,
    CreditRecord,
    MediaRecord,
    NewMediaProps,
    SchedulerLearnerProps,
    ProductRecord,
    AvailabilityGroupLesson,
} from '../store/scheduler/scheduler-data';

import { schedulerRest, channelReservation } from './api-urls';

interface MediaDTO {
    Default: boolean;
    MediaType: string;
    UserValue: string;
    MediaID: number;
}

interface AuthorizedMediaDTO {
    name: string;
    id: number;
    telco: boolean;
    textual: boolean;
    vocal: boolean;
    lessonMedia: boolean;
}

interface LearnerDTO {
    LearnerName: string;
    LearnerID: number;
    LearnerTimezone: string;
    TimeZoneOffSet: number;
    LearnerLanguage: string;
    LearnerDefaultMedia: MediaDTO;
    LearnerAuthorizedMedia: Array<AuthorizedMediaDTO>;
    LearnerIsBilingual?: boolean;
    LearnerCountry: string;
    LearnerIsWebcam: string;
}

interface LanguageDTO {
    Translations: { [key: string]: string };
    Learner: LearnerDTO;
}

interface ForeseenDTO {
    LessonExternalId: number;
    Media: string;
    Schedule: string;
    Duration: number;
    Trainer: string;
    Timezone: string;
}

interface LessonDTO {
    StartDateTime: string;
    Status: string;
    Teacher?: TeacherDTO;
    TeacherList?: TeacherDTO[];
    Media: MediaDTO;
    Duration: number;
    EndDateTime: string;
    LessonID: string;
    EventType: string;
    Credit: CreditDTO;
}

interface CreditDTO {
    Name: string;
    CreditID: number;
    Expiration: string;
    CreditLeft: number;
    Duration: Array<number>;
}

interface AuthorizedProductDTO {
    name: string;
    id: number;
    priority: number;
    externalIdentifier: string;
    cost: number;
    costType: string;
    productGroup: string;
    productType: string;
    maximumMinutes: number;
    minimumMinutes: number;
    postLearnerMinutes: number;
    postTeacherMinutes: number;
    preLearnerMinutes: number;
    preTeacherMinutes: number;
    timeUnit: number;
    hasShortDuration: boolean;
}

interface TeacherDTO {
    isWebcam: boolean;
    Name: string;
    LessonCount: number;
    TeacherID: number;
    AuthorizedMedia: Array<AuthorizedMediaDTO>;
    Gender: string;
    CurriculumVitae: string;
    AuthorizedProducts: Array<AuthorizedProductDTO>;
    Photo: string;
    isBillingual: boolean;
}

interface InitializeDTO {
    LearnerLessons: Array<LessonDTO>;
    Media: Array<MediaDTO>;
    CreditList: Array<CreditDTO>;
    TeacherList: Array<TeacherDTO>;
    Learner: LearnerDTO;
}

export interface RetrieveAvailsOptions {
    learnerID: string;
    mediaID: string;
    duration: string;
    teacherID?: string[] | null;
    creditID: string;
    isBilingual: string;
    selectedWeek: {
        start: string;
    };
    selectedShift: string;
    SAT: string;
}

interface AvailabilityDTO {
    StartDateTime: string;
    Status: string;
    Teacher?: TeacherDTO;
    TeacherList?: TeacherDTO[];
    Media: MediaDTO;
    Duration: number;
    Credit: CreditDTO;
    EndDateTime: string;
    LessonID: string;
    EventType: string;
}

interface AvailabilitiesSuccessDTO {
    Availabilities: Array<AvailabilityDTO>;
}

interface SchedulerApiErrorDTO {
    Label: string;
}

type AvailabilitiesDTO = SchedulerApiErrorDTO | AvailabilitiesSuccessDTO;

function isApiError<T>(
    response: SchedulerApiErrorDTO | T
): response is SchedulerApiErrorDTO {
    return (response as SchedulerApiErrorDTO).Label !== undefined;
}

interface InitWeekLessonsOptions {
    learnerID: string;
    startTime: string;
}

interface ValidateLessonOptions {
    creditID: string;
    duration: number;
    mediaID: string;
    startTime: string;
    endTime: string;
    learnerID: string;
    teacherID: string;
}

interface InitWeekLessonsDTO {
    LearnerLessons: Array<LessonDTO> | string;
    Learner?: LearnerDTO;
}

interface CancelLessonOptions {
    learnerID: string;
    lessonID: string;
    confirmCancel?: string;
}

interface InitLessonsOptions {
    learnerID: string;
    startTime: string;
}

interface InitLessonsDTO {
    LearnerLessons: Array<AvailabilityDTO>;
    Learner: LearnerDTO;
}

interface UpdateMediaDTO {
    Media: Array<MediaDTO>;
    Learner: LearnerDTO;
}

export class SchedulerApi {
    apiHeaders: ApiHeaders;

    constructor(apiHeaders: ApiHeaders) {
        this.apiHeaders = apiHeaders;
    }

    /**
     * Get language for learner by learnerId
     * @param learnerId
     */
    getLanguage(learnerId: string) {
        return Axios.get<LanguageDTO>(
            schedulerRest() + 'ws/language/' + learnerId,
            { headers: this.apiHeaders.getHeaders({}, true) }
        ).pipe(map((response) => response.data));
    }

    /**
     * Get foreseen by learnerId
     * @param learnerId
     */
    getForeseen(learnerId: string) {
        return Axios.get<ForeseenDTO>(
            schedulerRest() + 'ws/foreseen/' + learnerId,
            { headers: this.apiHeaders.getHeaders({}, true) }
        ).pipe(
            map((response) => {
                return response.data;
            })
        );
    }

    /**
     * Get foreseen by learnerId
     * @param learnerId
     */
    getGroupForeseen(learnerUUID: string) {
        return Axios.get<List<AvailabilityGroupLesson>>(
            channelReservation() + '/learner/v2/' + learnerUUID,
            { headers: this.apiHeaders.getHeaders({}, true) }
        ).pipe(
            map((response) => {
                let responseGroup = {};
                const groupDetails = response.data
                    .filter(
                        (item) =>
                            item.sourceBusinessType.toLowerCase() === 'group'
                    )
                    .find((item, i) => i === 0);
                if (groupDetails) {
                    const dateStart = moment(
                        new Date(groupDetails?.lessonStart as any)
                    ).format('DD MM YYYY HH:mm:ss');
                    responseGroup = {
                        LessonExternalId: groupDetails?.sourceBusinessId,
                        Media: groupDetails?.meetingLink,
                        Schedule: moment
                            .utc(dateStart, 'DD MM YYYY HH:mm:ss')
                            .format('YYYY-MM-DDTHH:mm:ss'),
                        Duration: groupDetails?.duration,
                        Trainer: groupDetails?.attendees.find(
                            (x: any, i: number) => i === 0
                        )?.media,
                        Timezone: '',
                    };
                }
                return responseGroup;
            })
        );
    }

    /**
     * Get language for learner by learnerId
     * @param learnerId
     */
    getLearnerEvents(learnerUUID: string) {
        return Axios.get<List<AvailabilityGroupLesson>>(
            channelReservation() + '/learner/v2/' + learnerUUID,
            { headers: this.apiHeaders.getHeaders({}, true) }
        ).pipe(
            map((response) => {
                const listGroupLesson = List(response.data)
                    .filter(
                        (x) => x.sourceBusinessType.toLowerCase() === 'group'
                    )
                    .map((lesson) => {
                        const dateStart = moment(
                            new Date(lesson.lessonStart as any)
                        ).format('DD MM YYYY HH:mm:ss');
                        return new AvailabilityGroupLesson({
                            ...lesson,
                            lessonStart: moment.utc(
                                dateStart,
                                'DD MM YYYY HH:mm:ss'
                            ),
                        });
                    });
                return listGroupLesson;
            })
        );
    }

    /**
     * Get scheduler/lesson List initialize data by learnerId
     * @param learnerId
     */
    getInitialize({ learnerId }: { learnerId: string }) {
        return Axios.get<InitializeDTO>(
            schedulerRest() + 'ws/initialize/' + learnerId,
            { headers: this.apiHeaders.getHeaders({}, true) }
        ).pipe(
            map((response) => {
                let learner = response.data.Learner;
                let lessons =
                    typeof response.data.LearnerLessons === 'string'
                        ? List<Availability>()
                        : List(response.data.LearnerLessons).map((lesson) => {
                              let lessonTeacherList = lesson.TeacherList
                                  ? List(
                                        lesson.TeacherList.map((teacher) => {
                                            let authorizedMedia =
                                                teacher.AuthorizedMedia.map(
                                                    (m) =>
                                                        new MediaRecord({
                                                            mediaType: m.name,
                                                        })
                                                );

                                            let authorizedProduct =
                                                teacher.AuthorizedProducts.map(
                                                    (m) => {
                                                        return new ProductRecord(
                                                            {
                                                                cost: m.cost,
                                                                costType:
                                                                    m.costType,
                                                                externalIdentifier:
                                                                    m.externalIdentifier,
                                                                hasShortDuration:
                                                                    m.hasShortDuration,
                                                                id: m.id,
                                                                maximumMinutes:
                                                                    m.maximumMinutes,
                                                                minimumMinutes:
                                                                    m.minimumMinutes,
                                                                name: m.name,
                                                                postLearnerMinutes:
                                                                    m.postLearnerMinutes,
                                                                postTeacherMinutes:
                                                                    m.postTeacherMinutes,
                                                                preLearnerMinutes:
                                                                    m.preLearnerMinutes,
                                                                preTeacherMinutes:
                                                                    m.preTeacherMinutes,
                                                                priority:
                                                                    m.priority,
                                                                productGroup:
                                                                    m.productGroup,
                                                                productType:
                                                                    m.productType,
                                                                timeUnit:
                                                                    m.timeUnit,
                                                            }
                                                        );
                                                    }
                                                );

                                            return new Teacher({
                                                teacherId: teacher.TeacherID,
                                                name: teacher.Name,
                                                gender: teacher.Gender,
                                                lessonCount:
                                                    teacher.LessonCount,
                                                isWebcam: teacher.isWebcam,
                                                photo: teacher.Photo,
                                                authorizedMedia:
                                                    List(authorizedMedia),
                                                authorizedProducts:
                                                    List(authorizedProduct),
                                            });
                                        })
                                    )
                                        .toSet()
                                        .toList()
                                  : List<Teacher>();

                              return new Availability({
                                  startDateTime: moment.utc(
                                      lesson.StartDateTime
                                  ),
                                  endDateTime: moment.utc(lesson.EndDateTime),
                                  teacherId: lesson.Teacher?.TeacherID,
                                  lessonId: lesson.LessonID,
                                  status: lesson.Status,
                                  duration: lesson.Duration,
                                  teacherList: lessonTeacherList,
                                  creditId: lesson.Credit.CreditID,
                                  mediaId: lesson.Media?.MediaID,
                              });
                          });
                let teachers = List(response.data.TeacherList).map(
                    (teacher) => {
                        let authorizedMedia = teacher.AuthorizedMedia.map(
                            (m) =>
                                new MediaRecord({
                                    mediaType: m.name,
                                })
                        );

                        let authorizedProduct = teacher.AuthorizedProducts.map(
                            (m) => {
                                return new ProductRecord({
                                    cost: m.cost,
                                    costType: m.costType,
                                    externalIdentifier: m.externalIdentifier,
                                    hasShortDuration: m.hasShortDuration,
                                    id: m.id,
                                    maximumMinutes: m.maximumMinutes,
                                    minimumMinutes: m.minimumMinutes,
                                    name: m.name,
                                    postLearnerMinutes: m.postLearnerMinutes,
                                    postTeacherMinutes: m.postTeacherMinutes,
                                    preLearnerMinutes: m.preLearnerMinutes,
                                    preTeacherMinutes: m.preTeacherMinutes,
                                    priority: m.priority,
                                    productGroup: m.productGroup,
                                    productType: m.productType,
                                    timeUnit: m.timeUnit,
                                });
                            }
                        );

                        return new Teacher({
                            teacherId: teacher.TeacherID,
                            name: teacher.Name,
                            gender: teacher.Gender,
                            lessonCount: teacher.LessonCount,
                            isWebcam: teacher.isWebcam,
                            photo: teacher.Photo,
                            authorizedMedia: List(authorizedMedia),
                            authorizedProducts: List(authorizedProduct),
                        });
                    }
                );

                let credits = Map(
                    response.data.CreditList.map<[string, CreditRecord]>(
                        (x) => [
                            String(x.CreditID),
                            new CreditRecord({
                                name: x.Name,
                                creditId: x.CreditID,
                                duration: x.Duration,
                                expiration: x.Expiration,
                                creditLeft: x.CreditLeft,
                            }),
                        ]
                    )
                );

                let medias = Map(
                    response.data.Media.map<[string, MediaRecord]>((x) => [
                        String(x?.MediaID),
                        new MediaRecord({
                            mediaID: x?.MediaID,
                            default: x.Default,
                            mediaType: x.MediaType,
                            userValue: x.UserValue,
                        }),
                    ])
                );

                const LearnerDetails = new SchedulerLearnerProps({
                    LearnerAuthorizedMedia: learner.LearnerAuthorizedMedia,
                    LearnerCountry: learner.LearnerCountry,
                    LearnerDefaultMedia: learner.LearnerDefaultMedia,
                    LearnerID: learner.LearnerID,
                    LearnerIsBilingual: learner?.LearnerIsBilingual,
                    LearnerIsWebcam: learner.LearnerIsWebcam,
                    LearnerLanguage: learner.LearnerLanguage,
                    LearnerName: learner.LearnerName,
                    LearnerTimezone: learner.LearnerTimezone,
                    TimeZoneOffset: learner.TimeZoneOffSet,
                });

                return {
                    teachers: teachers,
                    lessons: lessons,
                    credits: credits,
                    medias: medias,
                    learner: LearnerDetails,
                    sat: false,
                    isAvailabilitiesPreLoaded: false,
                };
            })
        );
    }

    /**
     * Get availabilities by options
     * @param {RetrieveAvailsOptions} opts
     */
    retrieveAvails(opts: RetrieveAvailsOptions) {
        return Axios.post<AvailabilitiesDTO>(
            schedulerRest() + 'ws/retrieveavails',
            opts,
            {
                headers: this.apiHeaders.getHeaders(
                    {
                        'Content-Type': 'application/json',
                    },
                    true
                ),
            }
        ).pipe(
            map((response) => {
                if (isApiError(response.data)) {
                    throw new Error(response.data.Label);
                }

                const avails = response.data?.Availabilities;
                const teachers = List(
                    avails.map(
                        (availability) =>
                            availability.Teacher || availability.TeacherList
                    )
                )
                    .toSet()
                    .toList()
                    .flatMap((teacher) =>
                        Array.isArray(teacher) ? teacher : [teacher]
                    )
                    .filter((x) => x)
                    .map((teacher) => {
                        let authorizedMedia = teacher?.AuthorizedMedia?.map(
                            (m) =>
                                new MediaRecord({
                                    mediaType: m.name,
                                })
                        );

                        let authorizedProduct =
                            teacher?.AuthorizedProducts?.map((m) => {
                                return new ProductRecord({
                                    cost: m.cost,
                                    costType: m.costType,
                                    externalIdentifier: m.externalIdentifier,
                                    hasShortDuration: m.hasShortDuration,
                                    id: m.id,
                                    maximumMinutes: m.maximumMinutes,
                                    minimumMinutes: m.minimumMinutes,
                                    name: m.name,
                                    postLearnerMinutes: m.postLearnerMinutes,
                                    postTeacherMinutes: m.postTeacherMinutes,
                                    preLearnerMinutes: m.preLearnerMinutes,
                                    preTeacherMinutes: m.preTeacherMinutes,
                                    priority: m.priority,
                                    productGroup: m.productGroup,
                                    productType: m.productType,
                                    timeUnit: m.timeUnit,
                                });
                            });
                        return new Teacher({
                            teacherId: teacher?.TeacherID,
                            name: teacher?.Name,
                            gender: teacher?.Gender,
                            lessonCount: teacher?.LessonCount,
                            isWebcam: teacher?.isWebcam,
                            photo: teacher?.Photo,
                            authorizedMedia: List(
                                authorizedMedia ? authorizedMedia : []
                            ),
                            authorizedProducts: List(
                                authorizedProduct ? authorizedProduct : []
                            ),
                        });
                    });
                const availabilities = List(
                    avails.map((availability: AvailabilityDTO) => {
                        let availabilityTeachersList = List(
                            teachers.filter((t) =>
                                availability.TeacherList?.some(
                                    (tdto) => tdto.TeacherID === t.teacherId
                                )
                            )
                        )
                            .toSet()
                            .toList();
                        return new Availability({
                            lessonId: availability.LessonID,
                            duration: availability.Duration,
                            teacherId: availability.Teacher?.TeacherID,
                            teacher: teachers.find(
                                (t) =>
                                    t.teacherId ===
                                    availability.Teacher?.TeacherID
                            ),
                            status: availability.Status,
                            startDateTime: moment.utc(
                                availability.StartDateTime
                            ),
                            endDateTime: moment.utc(availability.EndDateTime),
                            teacherList: availabilityTeachersList,
                            creditId: availability.Credit.CreditID,
                            mediaId: availability.Media?.MediaID,
                            eventType: availability.EventType,
                        });
                    })
                );

                return {
                    availabilities,
                    teachers,
                };
            }),
            catchError((e) => {
                if (e?.message === 'creditexpired') {
                    throw e;
                } else {
                    throw new Error("Can't load availabilities");
                }
            })
        );
    }

    /**
     * Init week lessons for scheduler/lessons list by learnerId and start date
     * @param {InitWeekLessonsOptions} opts
     */
    initWeekLessons(opts: InitWeekLessonsOptions) {
        return Axios.post(schedulerRest() + 'ws/initweeklessons', opts, {
            headers: this.apiHeaders.getHeaders(
                {
                    'Content-Type': 'application/json',
                },
                true
            ),
        }).pipe(
            map((response) => {
                return response.data as InitWeekLessonsDTO;
            }),
            map((response) => {
                let lessons: List<Availability> =
                    typeof response.LearnerLessons === 'string'
                        ? List()
                        : List(response.LearnerLessons).map((availability) => {
                              let lessonTeacherList = availability.TeacherList
                                  ? List(
                                        availability.TeacherList.map(
                                            (teacher) => {
                                                let authorizedMedia =
                                                    teacher.AuthorizedMedia.map(
                                                        (m) =>
                                                            new MediaRecord({
                                                                mediaType:
                                                                    m.name,
                                                            })
                                                    );
                                                return new Teacher({
                                                    teacherId:
                                                        teacher.TeacherID,
                                                    name: teacher.Name,
                                                    gender: teacher.Gender,
                                                    lessonCount:
                                                        teacher.LessonCount,
                                                    isWebcam: teacher.isWebcam,
                                                    photo: teacher.Photo,
                                                    authorizedMedia:
                                                        List(authorizedMedia),
                                                });
                                            }
                                        )
                                    )
                                        .toSet()
                                        .toList()
                                  : List<Teacher>();
                              return new Availability({
                                  lessonId: availability.LessonID,
                                  duration: availability.Duration,
                                  teacherId: availability.Teacher?.TeacherID,
                                  status: availability.Status,
                                  startDateTime: moment.utc(
                                      availability.StartDateTime
                                  ),
                                  endDateTime: moment.utc(
                                      availability.EndDateTime
                                  ),
                                  teacherList: lessonTeacherList,
                                  creditId: availability.Credit.CreditID,
                                  mediaId: availability.Media?.MediaID,
                              });
                          });
                return {
                    lessons,
                };
            })
        );
    }

    /**
     * Post validate lesson
     * @param {ValidateLessonOptions} opts
     */
    validateLesson(opts: ValidateLessonOptions) {
        // console.log({
        //     validateLesson: opts,
        // });
        return Axios.post(schedulerRest() + 'ws/validatelesson', opts, {
            headers: this.apiHeaders.getHeaders(
                {
                    'Content-Type': 'application/json',
                },
                true
            ),
        }).pipe(
            map((response) => {
                // console.log({
                //     inside: response,
                // });
                if (response.data.Lesson) {
                    let availabilityDTO = response.data.Lesson as LessonDTO;

                    let lessonTeacherList = availabilityDTO.TeacherList
                        ? List(
                              availabilityDTO.TeacherList.map((teacher) => {
                                  let authorizedMedia =
                                      teacher.AuthorizedMedia.map(
                                          (m) =>
                                              new MediaRecord({
                                                  mediaType: m.name,
                                              })
                                      );
                                  return new Teacher({
                                      teacherId: teacher.TeacherID,
                                      name: teacher.Name,
                                      gender: teacher.Gender,
                                      lessonCount: teacher.LessonCount,
                                      isWebcam: teacher.isWebcam,
                                      photo: teacher.Photo,
                                      authorizedMedia: List(authorizedMedia),
                                  });
                              })
                          )
                              .toSet()
                              .toList()
                        : List<Teacher>();

                    const availability = new Availability({
                        lessonId: availabilityDTO.LessonID,
                        duration: availabilityDTO.Duration,
                        teacherId: availabilityDTO.Teacher?.TeacherID,
                        status: availabilityDTO.Status,
                        startDateTime: moment.utc(
                            availabilityDTO.StartDateTime
                        ),
                        endDateTime: moment.utc(availabilityDTO.EndDateTime),
                        teacherList: lessonTeacherList,
                        creditId: availabilityDTO.Credit.CreditID,
                        mediaId: availabilityDTO.Media?.MediaID,
                    });

                    let creditDTO = availabilityDTO.Credit;
                    const credit = new CreditRecord({
                        creditId: creditDTO.CreditID,
                        creditLeft: creditDTO.CreditLeft,
                        expiration: creditDTO.Expiration,
                        duration: creditDTO.Duration,
                        name: creditDTO.Name,
                    });

                    return { availability, credit };
                }
                // console.log({
                //     response: response.data,
                // });
                throw new Error('Could not book lesson');
            })
        );
    }

    /**
     * Post cancel lesson
     * @param {CancelLessonOptions} opts
     */
    cancelLesson(opts: CancelLessonOptions) {
        return Axios.post(
            schedulerRest() + 'ws/cancellesson',
            {
                lessonID: opts.lessonID,
                learnerID: String(opts.learnerID),
                confirmCancel: opts.confirmCancel,
            },
            {
                headers: this.apiHeaders.getHeaders(
                    {
                        'Content-Type': 'application/json',
                    },
                    true
                ),
            }
        ).pipe(
            map((response) => {
                return response.data;
            })
        );
    }

    /**
     * Post initialize lessions for scheduler/lessons list
     * @param {InitLessonsOptions} opts
     */
    getInitLessons(opts: InitLessonsOptions) {
        return Axios.post(
            schedulerRest() + 'ws/initlessons',
            {
                learnerID: opts.learnerID,
                startTime: opts.startTime,
            },
            {
                headers: this.apiHeaders.getHeaders(
                    {
                        'Content-Type': 'application/json',
                    },
                    true
                ),
            }
        ).pipe(
            map((response) => {
                return response.data;
            }),
            map((response: InitLessonsDTO) => {
                let teachers = List(response.LearnerLessons).map(
                    (availability) => {
                        let teacher = availability.Teacher;
                        let authorizedMedia = teacher?.AuthorizedMedia?.map(
                            (m) =>
                                new MediaRecord({
                                    mediaType: m.name,
                                })
                        );
                        return new Teacher({
                            teacherId: teacher?.TeacherID,
                            isWebcam: teacher?.isWebcam,
                            name: teacher?.Name,
                            lessonCount: teacher?.LessonCount,
                            gender: teacher?.Gender,
                            photo: teacher?.Photo,
                            authorizedMedia: List(
                                authorizedMedia ? authorizedMedia : []
                            ),
                        });
                    }
                );
                let lessons = List(response.LearnerLessons).map(
                    (availability) => {
                        let availabilityDTO = {};
                        if (availability.Teacher) {
                            availabilityDTO = {
                                lessonId: availability.LessonID,
                                startDateTime: moment.utc(
                                    availability.StartDateTime
                                ),
                                status: availability.Status,
                                teacherId: availability.Teacher?.TeacherID,
                                duration: availability.Duration,
                                endDateTime: moment.utc(
                                    availability.EndDateTime
                                ),
                                creditId: availability.Credit.CreditID,
                                mediaId: availability.Media?.MediaID,
                                teacher: teachers.find(
                                    (t) =>
                                        t.teacherId ===
                                        availability.Teacher?.TeacherID
                                ),
                            };
                        }

                        return new Availability(availabilityDTO);
                    }
                );

                return {
                    lessons,
                    teachers,
                };
            })
        );
    }

    /**
     * Post new media
     * @param media
     */
    createMedia(media: NewMediaProps) {
        return Axios.post(schedulerRest() + 'ws/createmedia', media, {
            headers: this.apiHeaders.getHeaders(
                {
                    'Content-Type': 'application/json',
                },
                true
            ),
        }).pipe(
            map((response) => {
                return response.data;
            }),
            map((response: UpdateMediaDTO) => {
                let medias = Map(
                    response.Media.map<[string, MediaRecord]>((x) => [
                        String(x?.MediaID),
                        new MediaRecord({
                            mediaID: x?.MediaID,
                            default: x.Default,
                            mediaType: x.MediaType,
                            userValue: x.UserValue,
                        }),
                    ])
                );

                let learner = response.Learner;

                return {
                    medias,
                    learner,
                };
            })
        );
    }
}
