import { isImmutable, List, Record, Set } from 'immutable';
import { logger } from '../../logging';
import { CommitableAnswer } from '../../store/quiz/quiz-actions';

/**
 * The "type" of the question
 */
export enum QuestionArchetype {
    MultipleChoice,
    TrueOrFalse,
    FillInTheGap,
    MatchText,
    ShortAnswer,
    ScrambledLetters,
    ScrambledSentence,
    SpeechRecognition,
}

function questionArchetypeFromString(s: string) {
    switch (s) {
        case 'mc':
            return QuestionArchetype.MultipleChoice;
        case 'tf':
            return QuestionArchetype.TrueOrFalse;
        case 'fg':
            return QuestionArchetype.FillInTheGap;
        case 'mt':
            return QuestionArchetype.MatchText;
        case 'sa':
            return QuestionArchetype.ShortAnswer;
        case 'sl':
            return QuestionArchetype.ScrambledLetters;
        case 'ss':
            return QuestionArchetype.ScrambledSentence;
        case 'sr':
            return QuestionArchetype.SpeechRecognition;
        default:
            throw new Error('Unsupported question archetype:' + s);
    }
}

function stringFromQuestionArchetype(a: QuestionArchetype) {
    switch (a) {
        case QuestionArchetype.MultipleChoice:
            return 'mc';
        case QuestionArchetype.TrueOrFalse:
            return 'tf';
        case QuestionArchetype.FillInTheGap:
            return 'fg';
        case QuestionArchetype.MatchText:
            return 'mt';
        case QuestionArchetype.ShortAnswer:
            return 'sa';
        case QuestionArchetype.ScrambledLetters:
            return 'sl';
        case QuestionArchetype.ScrambledSentence:
            return 'ss';
    }
}

/**
 * Helps differentiate what kind of object is required as an answer.
 * TODO: rename to ObjectType (?)
 */
export enum OutputType {
    Text,
    TextMultiple,
    TextBlocks,
    Picture,
    PictureMultiple,
    Audio,
}

function outputTypeFromString(s: string) {
    switch (s) {
        case 'oT':
            return OutputType.Text;
        case 'oTM':
            return OutputType.TextMultiple;
        case 'oTB':
            return OutputType.TextBlocks;
        case 'oP':
            return OutputType.Picture;
        case 'oPM':
            return OutputType.PictureMultiple;
        case 'oA':
            return OutputType.Audio;
        default:
            throw new Error('Unsupported output type: ' + s);
    }
}

function stringFromOutputType(o: OutputType) {
    switch (o) {
        case OutputType.Text:
            return 'oT';
        case OutputType.TextMultiple:
            return 'oTM';
        case OutputType.TextBlocks:
            return 'oTB';
        case OutputType.Picture:
            return 'oP';
        case OutputType.PictureMultiple:
            return 'oPM';
        case OutputType.Audio:
            return 'oA';
    }
}

/**
 * Additional materials (attachment?), like a picture or a video.
 */
export enum AdditionalType {
    to = 'to', // text only
    wp = 'wp', // with picture
    wa = 'wa', // with audio
    wai = 'wai', // with audio image
    wv = 'wv', // with video
    wc = 'wc',
}

function additionalTypeFromString(s: string) {
    switch (s) {
        case 'to':
            return AdditionalType.to;
        case 'wp':
            return AdditionalType.wp;
        case 'wa':
            return AdditionalType.wa;
        case 'wai':
            return AdditionalType.wai;
        case 'wv':
            return AdditionalType.wv;
        case 'wc':
            return AdditionalType.wc;
        default:
            throw new Error('Unsupported additional type: ' + s);
    }
}

function stringFromAdditionalType(a: AdditionalType) {
    switch (a) {
        case AdditionalType.to:
            return 'to';
        case AdditionalType.wp:
            return 'wp';
        case AdditionalType.wa:
            return 'wa';
        case AdditionalType.wai:
            return 'wai';
        case AdditionalType.wv:
            return 'wv';
        case AdditionalType.wc:
            return 'wc';
    }
}

export interface AnswerOption {
    ord: number;
    value: string;
    text: string;
}

type FieldCorrectAnswer = {
    kind: 'field';
    fieldNumber: number;
    answers: string[];
};

type FieldCorrectAnswers = {
    kind: 'fields';
    fields: Array<FieldCorrectAnswer>;
};

type StringCorrectAnswer = {
    kind: 'string';
    answer: string;
};

type MultipleCorrectAnswers = {
    kind: 'string[]';
    answers: string[];
};

type CorrectOptionAnswer = {
    kind: 'option';
    correctOption: number;
};

type CorrectOptionsAnswer = {
    kind: 'options';
    correctOptions: number[];
};

type CorrectPositionsAnswer = {
    kind: 'positions';
    positions: number[];
};

type NoCorrectAnswer = {
    kind: 'no answer';
};

type CorrectAnswer =
    | FieldCorrectAnswer
    | FieldCorrectAnswers
    | StringCorrectAnswer
    | MultipleCorrectAnswers
    | CorrectOptionAnswer
    | CorrectOptionsAnswer
    | CorrectPositionsAnswer
    | NoCorrectAnswer;

interface QuestionProps {
    order: number;
    archetype: QuestionArchetype;
    output: OutputType;
    additional: AdditionalType;
    options: AnswerOption[];
    information: string;
    stemTokens: StemToken[];
    correctAnswer: CorrectAnswer;
    // FIXME: proper name for this attr
    additionalInfo: {
        ao: string;
        aom: string;
    };
}

const defaultQuestionProps: QuestionProps = {
    order: 0,
    archetype: QuestionArchetype.MultipleChoice,
    output: OutputType.Text,
    additional: AdditionalType.to,
    options: [],
    information: '',
    stemTokens: [],
    correctAnswer: { kind: 'no answer' },
    additionalInfo: {
        ao: '',
        aom: '',
    },
};

const toAnswerOption = ({ ord, val }: { ord: number; val: unknown }) => {
    if (typeof val === 'string') {
        return { text: val, value: val, ord };
    } else if (
        Array.isArray(val) &&
        val.length === 1 &&
        typeof val[0] === 'string'
    ) {
        return { text: val[0], value: val[0], ord };
    } else {
        throw new Error(
            'unsupported serialized answer option: ' + JSON.stringify(val)
        );
    }
};

const extractOptions: (old: any) => AnswerOption[] = (old: any) =>
    Object.keys(old)
        .filter((k) => k.startsWith('o'))
        .filter((k) => !Number.isNaN(Number(k.slice(1))))
        .map((k) => ({ ord: Number(k.slice(1)), val: old[k] }))
        .sort((a, b) => a.ord - b.ord)
        .map((a) => (Array.isArray(a.val) ? a.val : [a.val])) // there are cases, where o3 has 2 options inside
        .reduce((a, b) => a.concat(b), [])
        .map((val, i) => toAnswerOption({ ord: i + 1, val }));

type TextToken = {
    nodeType: 3;
    text: string;
    tagToUse?: string;
    tag?: string;
};

type TagToken = {
    nodeType: 1;
    tag: string;
    style: object;
};

type WhitespaceToken = {
    nodeType: 2;
    tag?: string;
};

type StemToken = TextToken | TagToken | WhitespaceToken;

/**
 * Transforms case-like-this to caseLikeThis
 * @param s string to transform
 */
const kebabToCamelCase = (s: string) => {
    let r = s.split('-');
    return r
        .slice(0, 1)
        .concat(r.slice(1).map((s) => s[0].toUpperCase() + s.slice(1)))
        .join('');
};

/**
 * Transforms inline style="" to object passable as style attr in React components
 * @param css css to parse
 */
const parseInlineStyle = (css: string) =>
    css
        .split(';')
        .map((s) => s.trim())
        .map((s: string) => s.split(':', 2).map((s) => s.trim()))
        .map(([rule, value]) => [kebabToCamelCase(rule), value])
        .reduce((ruleset, [rule, value]) => {
            ruleset[rule] = value;
            return ruleset;
        }, Object());

/**
 * Check if next text part starts with punctuation mark
 * @param text
 */

const isStringStartsWithPunctuationMark = (text: string) => {
    const regex = /[^\w\s]/g;
    const index = text.search(regex);
    return !!(index === 0);
};

/**
 * Check if additional white space is needed (for pair tags)
 * It's needed:
 *      1. before Input field
 *      2. before any of other tags if text doesn't start with punctuation mark
 * For second point this function checks if current tag is nested or not
 *
 * @param item
 */
const isWhiteSpaceNeeded = (item: any) => {
    let isNeeded = false;
    if (item?.nextSibling?.tagName?.toLowerCase() === 'input') {
        isNeeded = true;
    } else if (item?.parentElement?.nextSibling?.textContent) {
        isNeeded = !isStringStartsWithPunctuationMark(
            item?.parentElement?.nextSibling?.textContent
        );
    } else if (item?.nextSibling?.textContent) {
        isNeeded = !isStringStartsWithPunctuationMark(
            item?.nextSibling?.textContent
        );
    }
    return isNeeded;
};

/**
 * Parse HTML from question to renderable structure, preserving
 * inline styles, width, rows, etc.
 */
const parseHTML = (html: string) => {
    try {
        //let fragment = document.createDocumentFragment();
        let d = document.createElement('div');
        let parts = Array<StemToken>();

        const regex = /\r?\n/g;
        d.innerHTML = html.replace(regex, '<br />');
        let queue: Array<any> = Array.from(d.children);

        while (queue.length > 0) {
            let item = queue.shift();
            if (item === undefined) {
                continue;
            }
            if (item.childNodes.length > 0) {
                for (let i = item.childNodes.length - 1; i >= 0; i--) {
                    queue.unshift(item.childNodes[i]);
                }
            } else {
                if (item.tagName) {
                    let attr = item.getAttribute('style');
                    let width = item.getAttribute('width');
                    let rows = item.getAttribute('rows');
                    let style = attr ? parseInlineStyle(attr) : {};

                    if (width) {
                        style.width = width;
                    }

                    if (style.width) {
                        let parsedWidth = parseInt(style.width, 10);
                        style.width = parsedWidth + 40 + 'px';
                    }

                    if (rows && !Number.isNaN(+rows)) {
                        style.minHeight = 24 * rows + 12 + 'px';
                    }

                    parts.push({
                        nodeType: item.nodeType,
                        tag: item.tagName.toLowerCase(),
                        style: style,
                    });
                    /** For single tags it has a separate checking is space is needed */
                    if (
                        item?.nextSibling?.textContent &&
                        !isStringStartsWithPunctuationMark(
                            item?.nextSibling?.textContent
                        ) &&
                        item?.tagName?.toLowerCase() !== 'br'
                    ) {
                        parts.push({
                            nodeType: 2,
                        });
                    }
                } else {
                    parts.push({
                        nodeType: item.nodeType,
                        text: item.textContent,
                        tagToUse: item.parentElement
                            ? item.parentElement.tagName?.toLowerCase()
                            : 'p',
                    });
                    if (isWhiteSpaceNeeded(item)) {
                        parts.push({
                            nodeType: 2,
                        });
                    }
                }
            }
        }

        return parts;
    } catch (e) {
        logger.error(e);
    }

    return [];
};

function isFieldArray(arr: any): arr is Array<{ fNo: number; ans: string[] }> {
    return (
        Array.isArray(arr) &&
        arr.every(
            (item) => typeof item.fNo === 'number' && isStringArray(item.ans)
        )
    );
}

function isStringArray(arr: any): arr is string[] {
    return Array.isArray(arr) && arr.every((item) => typeof item === 'string');
}

function isNumberArray(arr: any): arr is number[] {
    return Array.isArray(arr) && arr.every((item) => typeof item === 'number');
}

function isNumberList(list: List<number | string>): boolean {
    if (list.size > 0) {
        let f = list.first();
        return typeof f === 'number';
    } else {
        return true;
    }
}

/**
 * TestQuestion is a part of the quiz.
 */
export class Question
    extends Record(defaultQuestionProps)
    implements QuestionProps
{
    constructor(values?: QuestionProps) {
        values ? super(values) : super();
    }

    /**
     * Returns question type description (not used anywhere atm)
     */
    toTypeDescription() {
        return [
            stringFromQuestionArchetype(this.archetype),
            stringFromOutputType(this.output),
            stringFromAdditionalType(this.additional),
        ].join('');
    }

    /**
     * Parse and instantiate TestQuestion from DTO object.
     * @param old DTO object
     */
    static fromJSON(old: any) {
        let archetype = questionArchetypeFromString(old.qt);
        let output = outputTypeFromString(old.ot);
        let additional = additionalTypeFromString(old.t);
        let options: AnswerOption[] = extractOptions(old);

        let answers: CorrectAnswer = { kind: 'no answer' };

        const fixmeUnsupported = (outputType: string, ans: unknown) => {
            console.error({
                output_type: outputType,
                ans: ans,
                original: old,
            });
            throw new Error('Unsupported answer format');
        };

        switch (output) {
            case OutputType.Text: {
                if (Array.isArray(old.ans)) {
                    if (isNumberArray(old.ans)) {
                        answers = {
                            correctOptions: old.ans,
                            kind: 'options',
                        };
                    } else if (isFieldArray(old.ans)) {
                        interface FieldAnswerDTO {
                            fNo: number;
                            ans: string[];
                        }
                        let fieldAnswers: FieldAnswerDTO[] = old.ans;
                        let cmp = (a: FieldAnswerDTO, b: FieldAnswerDTO) =>
                            a.fNo - b.fNo;
                        let fields: Array<FieldCorrectAnswer> = fieldAnswers
                            .sort(cmp)
                            .map((field) => {
                                return {
                                    kind: 'field',
                                    fieldNumber: field.fNo,
                                    answers:
                                        field.ans /* multiple correct alternatives are possible */,
                                };
                            });
                        answers = {
                            kind: 'fields',
                            fields: fields,
                        };
                    } else if (isStringArray(old.ans)) {
                        let strings = old.ans;
                        answers = {
                            kind: 'string[]',
                            answers: strings,
                        };
                    } else fixmeUnsupported('Text', old.ans);
                } else if (typeof old.ans === 'string') {
                    answers = {
                        kind: 'string',
                        answer: old.ans,
                    };
                } else fixmeUnsupported('Text', old.ans);
                break;
            }
            case OutputType.TextBlocks: {
                if (typeof old.ans === 'string') {
                    let indices = old.ans
                        .split(',')
                        .map((option: string) => Number(option));
                    answers = {
                        positions: indices,
                        kind: 'positions',
                    };
                } else if (Array.isArray(old.ans)) {
                    if (
                        old.ans.every(
                            (answer: any) => typeof answer === 'number'
                        )
                    ) {
                        answers = {
                            positions: old.ans,
                            kind: 'positions',
                        };
                    } else fixmeUnsupported('TextBlocks', old.ans);
                } else fixmeUnsupported('TextBlocks', old.ans);
                break;
            }

            case OutputType.TextMultiple: {
                if (Array.isArray(old.ans)) {
                    if (isNumberArray(old.ans)) {
                        answers = {
                            kind: 'options',
                            correctOptions: old.ans,
                        };
                    } else fixmeUnsupported('TextMultiple', old.ans);
                } else fixmeUnsupported('TextMultiple', old.ans);
                break;
            }

            case OutputType.Picture: {
                if (isNumberArray(old.ans)) {
                    answers = {
                        kind: 'options',
                        correctOptions: old.ans,
                    };
                } else fixmeUnsupported('Picture', old.ans);
                break;
            }

            case OutputType.PictureMultiple: {
                if (isNumberArray(old.ans)) {
                    answers = {
                        kind: 'options',
                        correctOptions: old.ans,
                    };
                } else fixmeUnsupported('PictureMultiple', old.ans);
                break;
            }

            case OutputType.Audio: {
                if (isNumberArray(old.ans)) {
                    answers = {
                        correctOptions: old.ans,
                        kind: 'options',
                    };
                } else fixmeUnsupported('Audio', old.ans);
                break;
            }
            default: {
                fixmeUnsupported(output, old.ans);
            }
        }

        if (!answers || answers.kind === 'no answer') {
            fixmeUnsupported('No Answer', old.ans);
        }

        let parts = parseHTML(old.q.replace(/(?:\r\n|\r|\n)/g, '<br/>'));

        let props: QuestionProps = {
            order: old.qNo,
            archetype,
            output,
            additional,
            options,
            information: old.i,
            stemTokens: parts,
            correctAnswer: answers,
            additionalInfo: {
                ao: old.ao,
                aom: old.aom,
            },
        };

        let question = new Question(props);
        return question;
    }

    /**
     * Checks whether the supplied answer is correct.
     * @param ans answer to be checked
     */
    isValidAnswer(ans: CommitableAnswer) {
        if (this.correctAnswer.kind === 'fields') {
            // console.table({ kind: 'fields', correct: this.correctAnswer.fields, answered: ans });
            if (isImmutable(ans)) {
                return this.correctAnswer.fields.every((field) => {
                    let attempted = ans.get(field.fieldNumber - 1);
                    if (attempted) {
                        const attemptedAnswer = attempted.toString();
                        return field.answers
                            .map((answer) => answer.trim().toLowerCase())
                            .includes(attemptedAnswer);
                    }
                    return false;
                });
            }
        }

        if (this.correctAnswer.kind === 'string[]') {
            // console.table({ kind: 'string[]', correct: this.correctAnswer.answers, answered: ans });
            if (typeof ans === 'string') {
                return this.correctAnswer.answers
                    .map((answer) => answer.trim().toLowerCase())
                    .includes(ans.trim().toLowerCase());
            }
        }

        if (this.correctAnswer.kind === 'option') {
            // console.table({ kind: 'option', correct: this.correctAnswer.correctOption, answered: ans });
            return this.correctAnswer.correctOption === ans;
        }

        if (this.correctAnswer.kind === 'options') {
            let correctSet = Set(this.correctAnswer.correctOptions);
            let suppliedSet: Set<number>;

            if (typeof ans === 'number') {
                suppliedSet = Set.of(ans);
            } else if (Set.isSet(ans)) {
                suppliedSet = ans;
            } else if (Array.isArray(ans)) {
                suppliedSet = Set(ans);
            } else if (List.isList(ans)) {
                if (isNumberList(ans)) {
                    suppliedSet = Set(ans as List<number>);
                } else {
                    suppliedSet = Set();
                }
            } else {
                suppliedSet = Set();
            }
            // console.table({ kind: 'options', correct: correctSet.toJS(), answered: suppliedSet.toJS() });
            return correctSet.equals(suppliedSet);
        }

        if (this.correctAnswer.kind === 'string') {
            if (typeof ans === 'string') {
                // console.table({ kind: 'string', correct: this.correctAnswer.answer, answered: ans });
                return (
                    this.correctAnswer.answer.trim().toLowerCase() ===
                    ans.trim().toLowerCase()
                );
            } else if (ans === null || ans === undefined) {
                return false;
            } else {
                // console.table({ kind: 'string', correct: this.correctAnswer.answer, answered: ans.toString() });
                return (
                    this.correctAnswer.answer.trim().toLowerCase() ===
                    ans.toString().trim().toLowerCase()
                );
            }
        }

        if (this.correctAnswer.kind === 'positions') {
            if (List.isList(ans)) {
                let correctList = List(this.correctAnswer.positions);
                // console.table({ kind: 'positions', correct: correctList.toJS(), answered: ans.toJS() });
                return correctList.equals(ans);
            }

            if (Array.isArray(ans)) {
                let suppliedPositions = ans;
                // console.table({ kind: 'positions', correct: this.correctAnswer.positions, answered: suppliedPositions });
                return this.correctAnswer.positions.every(
                    (v, i) => v === suppliedPositions[i]
                );
            }
        }

        console.error('Hit base case', {
            correct: this.correctAnswer,
            answer: ans,
        });
        return false;
    }

    /**
     * Checks whether passed option is correct.
     * There could be multiple correct options.
     * @param opt Option to check
     */
    isCorrectOption(opt: AnswerOption) {
        if (this.correctAnswer.kind === 'option') {
            return this.correctAnswer.correctOption === opt.ord;
        } else if (this.correctAnswer.kind === 'options') {
            return this.correctAnswer.correctOptions.includes(opt.ord);
        } else {
            return false;
        }
    }

    /**
     * TBD.
     */
    getInteraction() {
        return {
            a: [],
            q: this.order,
            s: this.order,
            t: '00:00:00',
        };
    }
}

export default Question;
