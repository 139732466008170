import { ApiHeaders } from './api-headers';
import { from, lastValueFrom, Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { Axios } from 'axios-observable';
import { eWriting } from './api-urls';
import { user } from './api-urls';

import {
    EwritingLearner,
    EwritingListItem,
    EwritingTeacher,
} from '../store/e-writing/data/ewriting-models';
import { ajax } from 'rxjs/ajax';
import moment from 'moment';
import { EwritingState } from '../store/e-writing/data/ewriting-type';
import { PostEWritingDTO } from '../store/e-writing/data/ewriting-dtos';
import { Cache } from '../utils/cache';

export interface IEwritingApi {
    loadEwritingList(
        learnerUUID: string,
        topicUUID: string
    ): Observable<EwritingListItem[]>;

    loadEwritingListWithTeachers(
        learnerUUID: string,
        topicUUID?: string
    ): Observable<EwritingListItem[]>;

    postEWriting(writing: PostEWritingDTO): Observable<any>;
}

export class EWritingApi implements IEwritingApi {
    apiHeaders: ApiHeaders;

    constructor(apiHeaders: ApiHeaders) {
        this.apiHeaders = apiHeaders;
    }

    postEWriting(writing: PostEWritingDTO): Observable<any> {
        return Axios.put(eWriting() + `learner/post`, writing, {
            headers: this.apiHeaders.getHeaders({
                'Content-Type': 'application/json',
            }),
        }).pipe(
            map((x) => x.data),
            catchError((error) => {
                throw error;
            })
        );
    }

    loadEwritingList(
        learnerUUID: string,
        topicUUID?: string
    ): Observable<EwritingListItem[]> {
        let url = eWriting() + `learner/list/${learnerUUID}`;
        if (topicUUID) url += `?topic=${topicUUID}`;

        return ajax.getJSON<any>(url, this.apiHeaders.getHeaders()).pipe(
            map((r) => {
                return r
                    .sort((i: any, j: any) => {
                        return -(
                            moment(i.submitDate).unix() -
                            moment(j.submitDate).unix()
                        );
                    })
                    .map((l: any) => {
                        return new EwritingListItem({
                            assignDateTime: l.assignDate
                                ? moment(l.assignDate)
                                : moment(),
                            completeDateTime: l.completeDate
                                ? moment(l.completeDate)
                                : moment(),
                            submitDateTime: l.submitDate
                                ? moment(l.submitDate)
                                : moment(),
                            homeworkActivity: l.homeworkActivity,
                            ewritingUUID: l.id,
                            state: ((l.state as keyof typeof EwritingState)
                                ? (l.state as keyof typeof EwritingState)
                                : EwritingState.Submitted) as EwritingState,
                            teacher: new EwritingTeacher({
                                teacherUUID: l.trainer,
                            }),
                            learner: new EwritingLearner(),
                            learnerTextHtml: l.learnerTextHtml,
                            trainerTextHtml: l.trainerTextHtml,
                            subject: l.subject,
                            topic: l.topic,
                            aiText: l.aiText,
                        });
                    });
            })
        );
    }

    public loadEwritingListWithTeachers(
        learnerUUID: string,
        topicUUID?: string
    ): Observable<EwritingListItem[]> {
        return from(this.loadEwritingListFilled(learnerUUID, topicUUID));
    }

    private async loadEwritingListFilled(
        learnerUUID: string,
        topicUUID?: string
    ): Promise<EwritingListItem[]> {
        let list = await lastValueFrom(
            this.loadEwritingList(learnerUUID, topicUUID)
        );

        let teacherIds = list
            .map((ewr) => ewr?.teacher?.teacherUUID)
            .filter((id) => id);

        let uniqueTeacherIds = teacherIds.filter(
            (v, i, a) => a.indexOf(v) === i
        );

        let teachers = await this.loadTeachers(uniqueTeacherIds);
        let newItems = Array<EwritingListItem>();

        list.forEach((ewr) => {
            let filledTeacher = teachers.find(
                (t) => t.teacherUUID === ewr.teacher.teacherUUID
            );
            if (filledTeacher) {
                ewr = new EwritingListItem({
                    assignDateTime: ewr.assignDateTime
                        ? moment(ewr.assignDateTime)
                        : moment(),
                    completeDateTime: ewr.completeDateTime
                        ? moment(ewr.completeDateTime)
                        : moment(),
                    submitDateTime: ewr.submitDateTime
                        ? moment(ewr.submitDateTime)
                        : moment(),
                    homeworkActivity: ewr.homeworkActivity,
                    ewritingUUID: ewr.ewritingUUID,
                    state: ((ewr.state as keyof typeof EwritingState)
                        ? (ewr.state as keyof typeof EwritingState)
                        : EwritingState.Submitted) as EwritingState,
                    teacher: filledTeacher,
                    learner: new EwritingLearner(),
                    learnerTextHtml: ewr.learnerTextHtml,
                    trainerTextHtml: ewr.trainerTextHtml,
                    subject: ewr.subject,
                    topic: ewr.topic,
                    aiText: ewr.aiText,
                });
            }
            newItems.push(ewr);
        });

        return lastValueFrom(of(newItems));
    }

    private async loadTeachers(
        teachersIds: string[]
    ): Promise<EwritingTeacher[]> {
        if (teachersIds.length > 0) {
            const teacherRequests = teachersIds.map((t) => this.loadTeacher(t));
            return Promise.all(teacherRequests);
        }

        return lastValueFrom(of([]));
    }

    @Cache({ globalPrefix: 'loadProfile' })
    private loadTeacher(userId: string): Promise<EwritingTeacher> {
        return lastValueFrom(
            ajax
                .getJSON<any>(user() + userId, this.apiHeaders.getHeaders())
                .pipe(
                    map((r) => {
                        return new EwritingTeacher({
                            firstName: r.firstname,
                            lastName: r.lastname,
                            teacherUUID: r.uuid,
                            photo: r.avatar,
                        });
                    })
                )
        );
    }
}
