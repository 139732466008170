import { Observable } from 'rxjs';
import {
    IntegrationActivityTrackingSettings,
    IntegProvisioningActivityTrackingSettings,
} from './integrations/integrations-settings';
import { IIntegrationApi } from './integration-api';
import {
    AICCSSOValidatorEvent,
    UserCustomFieldEvent,
} from './integrations/integrations-event';

export interface IintegrationRequireService {
    /**
     * Track user activity in resources
     * @param learnerUUID
     * @param learnerExternalId
     */
    SSOValidator(
        learnerUUID: string,
        learnerExternalId: string
    ): Observable<IntegrationActivityTrackingSettings>;

    SSOProvisioning(
        learnerUUID: string,
        learnerExternalId: string
    ): Observable<IntegProvisioningActivityTrackingSettings>;

    UserCustomField(learnerUUID: string): void;
}

/**
 *Service that tracks user activity in resources
 */
export class IntegrationRequireService implements IintegrationRequireService {
    private settings: IntegrationActivityTrackingSettings;
    private settingsProvision: IntegProvisioningActivityTrackingSettings;

    constructor(private readonly IntegrationRequireApi: IIntegrationApi) {
        this.settings = IntegrationActivityTrackingSettings.getDefault();
        this.settingsProvision =
            IntegProvisioningActivityTrackingSettings.getDefault();
    }

    /**
     *
     * Track user activity in resources for lufthansa
     */
    public SSOProvisioning(
        learnerUUID: string,
        learnerExternalId: string
    ): Observable<IntegProvisioningActivityTrackingSettings> {
        const event = this.getEvent({ learnerUUID, learnerExternalId });
        return this.IntegrationRequireApi.SSOProvisioning(event);
    }

    /**
     *
     * Track user activity in resources
     */
    public SSOValidator(
        learnerUUID: string,
        learnerExternalId: string
    ): Observable<IntegrationActivityTrackingSettings> {
        const event = this.getEvent({ learnerUUID, learnerExternalId });
        return this.IntegrationRequireApi.SSOLearnerValidate(event);
    }

    private getEvent({
        learnerUUID,
        learnerExternalId,
    }: {
        learnerUUID: string;
        learnerExternalId: string;
    }) {
        const clientUrl = localStorage.getItem('aiccURL') || '';
        const sessionId = localStorage.getItem('aiccSID') || '';
        const providerId = localStorage.getItem('client') || '';

        return new AICCSSOValidatorEvent(
            learnerUUID,
            learnerExternalId,
            sessionId,
            clientUrl,
            providerId
        );
    }

    /**
     *
     * Track user activity in resources
     */
    public UserCustomField(learnerUUID: string): void {
        let event = new UserCustomFieldEvent(learnerUUID);

        this.IntegrationRequireApi.UserCustomField(event).subscribe(
            (response) => {
                if (response?.userGUID) {
                    localStorage.setItem('userGuid', response?.userGUID);
                }
            },
            (error: any) => {
                if (error.status === 410) {
                    //resend open if backend lost content state
                }
                console.error('custom field', error);
            }
        );
    }
}
