import { createReducer, RootState } from 'typesafe-actions';
import { createSelector } from 'reselect';
import { resourceActions } from './resources-actions';
import { ResourceDescription } from './resources';

export const resourceSelector = (state: RootState) => state.resources;

export const displayedResourceSelector = createSelector(
    resourceSelector,
    (resource) => resource.displayedResource
);

export const isfromOriginSelector = createSelector(
    resourceSelector,
    (resource) => resource.isLocationFrom
);

export const displayedResourceReducer = createReducer(
    null as ResourceDescription | null
)
    .handleAction(
        [
            resourceActions.loadSelectedResource.failure,
            resourceActions.loadSelectedUsingUUIDResource.failure,
        ],
        () => null
    )
    .handleAction(
        [
            resourceActions.loadSelectedResource.success,
            resourceActions.loadSelectedUsingUUIDResource.success,
        ],
        (state, action) => action.payload
    )
    .handleAction(
        resourceActions.setDisplayResource,
        (state, action) => action.payload
    );

export const resourceContentProgressReducer = createReducer(0).handleAction(
    resourceActions.changeResourceContentProgress,
    (state, action) => {
        return action.payload.score;
    }
);

export const isLocationFrom = createReducer<string>('').handleAction(
    resourceActions.changeResourceFrom,
    (state, action) => {
        return action.payload;
    }
);
