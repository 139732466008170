import { Record, List } from 'immutable';

export class Section extends Record({
    instanceId: '',
    title: '',
    slides: List<SlideContent>(),
}) {}

export interface SlideContent {
    readonly template: string;
}
