import { ApiHeaders } from './api-headers';
import { ajax, AjaxResponse } from 'rxjs/ajax';
import { map } from 'rxjs/operators';
import { logging } from './api-urls';
import { LogSettings } from './logging/log-settings';
import { Observable } from 'rxjs';
import { LogEntry } from './logging/log-entry';

export interface LogApi<LogType> {
    saveEntry(entry: LogType): Observable<AjaxResponse<any>>;
    saveEntries(entries: LogType[]): Observable<AjaxResponse<any>>;
}

export class LoggingApi implements LogApi<LogEntry> {
    apiHeaders: ApiHeaders;

    constructor(apiHeaders: ApiHeaders) {
        this.apiHeaders = apiHeaders;
    }

    getSettings(): Observable<LogSettings> {
        return new Observable<LogSettings>();
        // return ajax.getJSON<LogSettings>(logging() + 'settings', {})
        //     .pipe(map((response) => {
        //         return response
        //     }))
    }

    saveEntry(entry: LogEntry): Observable<AjaxResponse<any>> {
        return ajax
            .post(
                logging() + 'log',
                entry,
                this.apiHeaders.getHeaders({
                    'Content-Type': 'application/json',
                })
            )
            .pipe(map((response) => response));
    }

    saveEntries(entries: LogEntry[]): Observable<AjaxResponse<any>> {
        return ajax
            .post(
                logging() + 'logs',
                entries,
                this.apiHeaders.getHeaders({
                    'Content-Type': 'application/json',
                })
            )
            .pipe(map((response) => response));
    }
}
