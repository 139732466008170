import { of } from 'rxjs';
import { catchError, filter, map, switchMap } from 'rxjs/operators';
import { isActionOf } from 'typesafe-actions';
import { RootEpic } from '../root-epic';
import { Action } from '../../store/root-action';

const loadTranslateResource: RootEpic = (action$, state$, { translateApi }) => {
    return action$
        .pipe(filter(isActionOf(Action.resources.translateAction.request)))
        .pipe(
            switchMap((r) => {
                const toTranslateText = r.payload.text.map((data) => {
                    return data.replace(/"/g, "'");
                });

                let toLanguage = state$.value.user?.currentUILanguage?.id;
                if (toLanguage.toLowerCase() === 'zh_cn') {
                    toLanguage = 'zh';
                } else {
                    toLanguage = toLanguage.replaceAll('_', '-');
                }

                const topicId =
                    state$.value.resources?.displayedResourceContent?.metadata
                        ?.topics[0];

                const contentUUId =
                    state$.value.resources?.displayedResource?.contentUUId;

                const mapPayload: any = {
                    activity: contentUUId,
                    topic: topicId,
                    user: state$.value.user?.identity?.learnerUUID,
                    text: toTranslateText,
                    toLanguage,
                };

                return translateApi.loadTranslate(mapPayload).pipe(
                    map((response) => {
                        return Action.resources.translateAction.success(
                            response
                        );
                    }),
                    catchError((e) =>
                        of(Action.resources.translateAction.failure(e))
                    )
                );
            })
        );
};

export const translateResourceEpics = [loadTranslateResource];
