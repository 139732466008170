import { List, Record } from 'immutable';
import { ResourceContentType } from '../resources/resource-content-type';
import { ContentMetadataProps } from '../resources/resources-content-record';

export interface TagProps {
    id: number;
    name: string;
    isCategory: boolean;
}

type HomeworkContentType =
    | ResourceContentType
    | 'training-path'
    | 'trainingpath';

export interface HomeworkProps {
    legacy: {
        id: number;
        articleId: number;
        tagAssetId: number;
        contentType: HomeworkContentType;
        groupId: number;
    };
    contentMetadata: ContentMetadataProps | null;
    id: string;
    contentUUID: string;
    type: 'Homework';
    status: string;
    score: number;
    assignmentDate: string;
    dueDate: string;
    modifiedDate: string;
    mediator: {
        avatar?: string;
        uuid: string;
        firstname: string;
        lastname: string;
    };
    tags: TagProps[];
    title: string;
    thumbnail: string;
    date: null | string;
    uuid: string;
}

export interface NewHomeWorkProps {
    assignmentDto: HomeworkProps[];
    dataSource: string;
    nextKey: number;
    topicId: string;
    userId: string;
}

const defaultHomeworkProps: HomeworkProps = {
    legacy: {
        id: 0,
        articleId: 0,
        tagAssetId: 0,
        contentType: ResourceContentType.Unknown,
        groupId: 0,
    },
    id: '',
    contentUUID: '',
    type: 'Homework',
    status: '',
    score: 0,
    assignmentDate: '',
    dueDate: '',
    modifiedDate: '',
    mediator: {
        uuid: '',
        firstname: '',
        lastname: '',
    },
    contentMetadata: null,
    tags: [],
    title: '',
    thumbnail: '',
    date: null,
    uuid: '',
};

export class Homework
    extends Record(defaultHomeworkProps)
    implements HomeworkProps {}

export function isHomework(item: any): item is Homework {
    const homework: Homework = item as Homework;

    return homework.type && homework.type === 'Homework';
}

export function isHomeworkArray(
    items: any[] | List<any> | null
): items is Homework[] {
    if (!items) return true;
    if (items instanceof List) return false;

    return (
        items instanceof Array &&
        (items.length === 0 || (items.length > 0 && isHomework(items[0])))
    );
}
