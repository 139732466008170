import { Record } from 'immutable';

export interface TranslateResourceProps {
    src: string;
    dst: string;
    text: Array<string>;
}

const defaultTranslateResourceProps: TranslateResourceProps = {
    src: '',
    dst: '',
    text: [],
};

export class TranslateResourceData
    extends Record(defaultTranslateResourceProps)
    implements TranslateResourceProps {}
