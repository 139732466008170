import { ResourceContentProps } from '../resource-content';
import { Record } from 'immutable';
import { ResourceContentType } from '../resource-content-type';
import { ContentMetadataProps } from '../resources-content-record';

type InProgressExcludedFields =
    | 'content'
    | 'status'
    | 'score'
    | 'date'
    | 'dueDate';

export interface InProgressResourceProps
    extends Omit<ResourceContentProps, InProgressExcludedFields> {
    contentMetadata: ContentMetadataProps | null;
    contentProgress: number;
    totalDurationInSeconds: number;
    numberOfVisit: number;
    lastVisit: string;
}

export const defaultInProgressResourceContentProps: InProgressResourceProps = {
    thumbnail: '',
    administration: {
        approved: true,
        copyright: '',
        createdDate: '',
        displayDate: '',
        license: '',
        modifiedDate: '',
        version: 0,
    },
    description: '',
    id: '',
    contentUUID: '',
    uuid: '',
    contentMetadata: null,
    legacy: {
        articleId: 0,
        contentType: ResourceContentType.Unknown,
        groupId: 0,
        groupName: '',
        id: 0,
        resourcePrimKey: 0,
        structureId: '',
        tagAssetId: 0,
        tagEntries: [],
        tags: [],
        categories: null,
        medium_image: '',
    },
    metadata: {
        accessRights: [],
        gradings: [],
        groups: [],
        organisations: [],
        tags: [],
        teachingConcept: [],
        topics: [],
    },
    title: '',
    contentProgress: 0,
    totalDurationInSeconds: 0,
    numberOfVisit: 0,
    lastVisit: '',
};

export class InProgressResource
    extends Record(defaultInProgressResourceContentProps)
    implements InProgressResourceProps {}
