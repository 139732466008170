import { List } from 'immutable';
import { createAsyncAction, createAction } from 'typesafe-actions';
import { ResourceContent } from '../resource-content';
import { ResourceContentRecord } from '../resources-content-record';
import { ResourceDescription } from '../resources';
import { TrainingPath } from '../training-path/training-path-record';
import {
    QuizDescription,
    QuizDescriptionOffline,
    QuizAttemptDescription,
} from '../../../services/quizzes/quiz';

export interface ActivityTrackingProps {
    courseUuid: string;
    resourceUuid: string;
    status: string;
}

export interface ActivityTrackingDetailProps {
    event: any;
    trackingDetails: any;
}

// export interface DisplayResourceProps {
//     courseUuid: string;
//     resourceUuid: string;
// }

// export interface ActivityTrackingProps extends DisplayResourceProps {
//     status: string;
// }

// export interface ActivityTrackingDetailProps {
//     event: any;
//     trackingDetails: any;
// }

// export interface SettingsProps {
//     displayLanguage: string;
//     offlineModeEnabled: boolean;
//     preferedCommunicationLanguage: string;
//     userId: string;
// }

export const loadResourceDetails = createAsyncAction(
    '@@offline/load-resource-details/request',
    '@@offline/load-resource-details/success',
    '@@offline/load-resource-details/failure'
)<
    {
        list: List<ResourceContent>;
        courseContentUuid: string;
    },
    Map<string, ResourceDescription>,
    {
        error: Error;
        contentUuid: string;
    }
>();

export const loadResourceContent = createAsyncAction(
    '@@offline/load-resource-content/request',
    '@@offline/load-resource-content/success',
    '@@offline/load-resource-content/failure'
)<
    Map<string, ResourceDescription>,
    Map<string, ResourceContentRecord>,
    Error
>();

export const loadResourceItemByCourse = createAsyncAction(
    '@@offline/load-course-resource-item/request',
    '@@offline/load-course-resource-item/success',
    '@@offline/load-course-resource-item/failure'
)<any, List<ResourceContent>, { error: Error; contentUuid: string }>();

export const loadResourceQuiz = createAsyncAction(
    '@@offline/load-resource-quiz/request',
    '@@offline/load-resource-quiz/success',
    '@@offline/load-resource-quiz/failure'
)<
    Map<string, ResourceDescription>,
    Map<string, QuizDescriptionOffline>,
    Error
>();

export const setSelectedCourseItem = createAction(
    '@@offline/set-selected-course-item'
)<Map<string, TrainingPath>>();

export const setItems = createAction('@@offline/set-items')<{
    setSelectedCourseItem: Map<string, TrainingPath>;
    setResourceItemByCourse: List<ResourceContent>;
    setResourceContent: Map<string, ResourceContentRecord>;
    setResourceDetail: Map<string, ResourceDescription>;
    setQuiz: Map<string, QuizDescriptionOffline>;
    preSetDislayAssets: any;
    setResourceListByCourse: List<any>;
    preSetActivity: Map<string, ActivityTrackingProps>;
}>();

export const setDislayAssets = createAction('@@offline/set-display-assets')<
    Map<string, any>
>();

export const preSetDislayAssets = createAction(
    '@@offline/pre-set-display-assets'
)<Map<string, any>>();

export const setResourceListByCourse = createAction(
    '@@offline/set-resources-by-course'
)<List<any>>();

export const setResourceItemByCourse = createAction(
    '@@offline/set-course-resource-item'
)<List<ResourceContent>>();

export const setResourceDetail = createAction('@@offline/set-resource-detail')<
    Map<string, ResourceDescription>
>();

export const setResourceContent = createAction(
    '@@offline/set-resource-content'
)<Map<string, ResourceContentRecord>>();

export const setCurrentQuizAttempt = createAction(
    '@@offline/set-current-quiz-attempt'
)<QuizAttemptDescription>();

export const setCurrentQuiz = createAction(
    '@@offline/set-current-quiz'
)<QuizDescriptionOffline>();

export const setCurrentQuizId = createAction(
    '@@offline/set-current-quiz-id'
)<string>();

export const setQuiz =
    createAction('@@offline/set-quiz')<Map<string, QuizDescriptionOffline>>();

export const setEnableOffline = createAction(
    '@@offline/set-enable-offline'
)<boolean>();

export const postOfflineSetting = createAsyncAction(
    '@@offline/set-offline-setting/request',
    '@@offline/set-offline-setting/success',
    '@@offline/set-offline-setting/failure'
)<
    {
        offlineModeEnabled: boolean;
        userUUID: string;
    },
    any,
    Error
>();

export const postSyncQuiz = createAsyncAction(
    '@@offline/post-offline-quiz/request',
    '@@offline/post-offline-quiz/success',
    '@@offline/post-offline-quiz/failure'
)<
    {
        quizFromServer: Map<string, QuizDescription>;
        quiz: Map<string, QuizDescriptionOffline>;
    },
    Map<string, QuizDescriptionOffline>,
    Error
>();

export const setActivity = createAction('@@offline/set-activity')<
    Map<string, ActivityTrackingProps>
>();

export const updateActivity = createAction('@@offline/update-activity')<
    Map<string, ActivityTrackingProps>
>();

export const postSyncActivity = createAsyncAction(
    '@@offline/post-offline-activity/request',
    '@@offline/post-offline-activity/success',
    '@@offline/post-offline-activity/failure'
)<
    {
        quizFromServer: Map<string, QuizDescription>;
        activity: List<ActivityTrackingDetailProps>;
    },
    any,
    Error
>();

export const postSynchronize = createAsyncAction(
    '@@offline/post-synchronize/request',
    '@@offline/post-synchronize/success',
    '@@offline/post-synchronize/failure'
)<any, any, Error>();

export const preSetActivity = createAction('@@offline/pre-set-activity')<
    Map<string, ActivityTrackingProps>
>();

export const setConnectivity = createAction('@@offline/set-connectivity')<
    boolean | null
>();

export const setActiveResourceDisplayed = createAction(
    '@@offline/set-active-resource-display'
)<any>();

export const setNoSizeContent = createAction(
    '@@offline/no-content-size'
)<void>();

export const updateSyncDB = createAction('@@offline/update-sync-db')<any>();

export const offlineActions = {
    setSelectedCourseItem,
    setDislayAssets,
    loadResourceDetails,
    loadResourceQuiz,
    loadResourceContent,
    loadResourceItemByCourse,
    setResourceListByCourse,
    setResourceItemByCourse,
    setResourceDetail,
    setResourceContent,
    setQuiz,
    setItems,
    preSetDislayAssets,
    setCurrentQuizAttempt,
    setCurrentQuiz,
    setCurrentQuizId,
    setEnableOffline,
    postSyncQuiz,
    postOfflineSetting,
    setActivity,
    preSetActivity,
    updateActivity,
    setConnectivity,
    setActiveResourceDisplayed,
    postSynchronize,
    updateSyncDB,
    setNoSizeContent,
    // postSyncActivityWithQuiz,
};
