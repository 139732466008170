import { defaultColors } from '../customization/colors';
import { setSecondaryColor } from '../customization/set-secondary-color';
import { lightOrDarkTheme } from '../utils/lightOrDarkTheme';
import { customizedColorsSelector } from '../store/display-strategy/display-strategy-selectors';
import { useSelector } from 'react-redux';

export const useCustomizedColors = () => {
    const customizedColors = useSelector(customizedColorsSelector);
    document.documentElement.style.setProperty(
        '--main',
        customizedColors?.mainColor ?? defaultColors.mainColor,
        'important'
    );
    document.documentElement.style.setProperty(
        '--secondary',
        customizedColors && customizedColors.mainColor
            ? setSecondaryColor(customizedColors.mainColor)
            : defaultColors.secondaryColor,
        'important'
    );
    document.documentElement.style.setProperty(
        '--accent',
        customizedColors?.accentColor ?? defaultColors.accentColor,
        'important'
    );
    document.documentElement.style.setProperty(
        '--navigation',
        customizedColors?.navigationColor ?? defaultColors.navigationColor,
        'important'
    );
    document.documentElement.style.setProperty(
        '--sideMenu',
        customizedColors?.sideMenuColor ?? defaultColors.sideMenuColor,
        'important'
    );

    const theme = lightOrDarkTheme(
        customizedColors?.navigationColor ?? defaultColors.navigationColor
    );

    document.documentElement.style.setProperty(
        '--hover',
        theme.hover,
        'important'
    );
    document.documentElement.style.setProperty(
        '--active',
        theme.active,
        'important'
    );
    document.documentElement.style.setProperty(
        '--text',
        theme.text,
        'important'
    );
    document.documentElement.style.setProperty(
        '--secondaryText',
        theme.secondaryText,
        'important'
    );
};
