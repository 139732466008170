import en_US from './en_US.json';

import LocalizedStrings from 'react-localization';

class Localization {
    static strings = new LocalizedStrings({
        en_US: en_US,
    });
}

export const localization = Localization.strings;

export const tryGetLocalization = (
    key: string,
    languageCode: string | null
): string => {
    let val = languageCode
        ? localization.getString(key + '_' + languageCode)
        : null;
    if (val === null) {
        val = localization.getString(key);
    }

    //ToDo: remove after database update
    if (key.includes('adaptive_') && val === null) {
        const oldKey = key.replace('adaptive_', '');
        val = localization.getString(oldKey);
    }

    if (key.includes('placement_') && val === null) {
        const oldKey = key.replace('placement_', '');
        val = localization.getString(oldKey);
    }

    return val;
};
