import { createReducer } from 'typesafe-actions';
import { combineReducers } from 'redux';
import { List } from 'immutable';
import {
    toggleTranslateSwitchAction,
    translateAction,
    translateOriginalText,
} from './translate-actions';
import { TranslateResourceData } from './translate-record';
import { resourceActions } from '../resources-actions';

const translateComponent = createReducer(new TranslateResourceData())
    .handleAction(translateAction.success, (state, action) => action.payload)
    .handleAction(toggleTranslateSwitchAction, (state, action) => {
        if (!action.payload) {
            return new TranslateResourceData();
        }

        return new TranslateResourceData({
            src: state.src,
            dst: state.dst,
            text: state.text,
        });
    })
    .handleAction(
        [
            resourceActions.loadSelectedUsingUUIDResource.request,
            resourceActions.loadSelectedResource.request,
        ],
        (state, action) => new TranslateResourceData()
    );

const toggleSwtich = createReducer(false)
    .handleAction(
        [
            resourceActions.loadSelectedUsingUUIDResource.request,
            resourceActions.loadSelectedResource.request,
        ],
        (state, action) => false
    )
    .handleAction(
        toggleTranslateSwitchAction,
        (state, action) => action.payload
    );

const originalText = createReducer(List<string>())
    .handleAction(toggleTranslateSwitchAction, (state, action) => {
        if (action.payload) {
            return state;
        } else {
            return List();
        }
    })
    .handleAction(translateOriginalText, (state, action) => action.payload)
    .handleAction(
        [
            resourceActions.loadSelectedUsingUUIDResource.request,
            resourceActions.loadSelectedResource.request,
        ],
        (state, action) => List()
    );

export const translateResourceReducer = () =>
    combineReducers({
        switch: toggleSwtich,
        content: translateComponent,
        originalText,
    });
