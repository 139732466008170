import moment from 'moment';
import { PortalTrackingSettings } from './portal-activity-tracking/portal-tracking-settings';
import { IPortalTrackingApi } from './portal-tracking-api';
import { PortalEvent } from './portal-activity-tracking/portal-event';
import { PortalEventType } from './portal-activity-tracking/portal-event-type';
import { SSOUtils } from '../utils/SSO';
import { reportsAttributesForProvision } from './content-activity-tracking-service';

export interface IPortalTrackingService {
    /**
     * Track user activity in resources
     * @param type
     */
    trackActivity(topicId: string, userId: string, type: PortalEventType): void;
}

/**4
 *Service that tracks user activity in resources
 */
export class PortalTrackingService implements IPortalTrackingService {
    private settings: PortalTrackingSettings;
    private inMemoryQueue: PortalEvent[];
    private readonly queueGapMs = 500;

    constructor(
        private readonly contentActivityTrackingApi: IPortalTrackingApi,
        private readonly tabId: string
    ) {
        this.settings = PortalTrackingSettings.getDefault();
        this.inMemoryQueue = [];
        setInterval(
            () => this.sendFromQueue(this.inMemoryQueue),
            this.queueGapMs
        );
        this.contentActivityTrackingApi.getSettings().subscribe((x) => {
            this.settings = x;
        });
    }
    private sendFromQueue(queue: PortalEvent[]) {
        if (queue.length > 0) {
            let event = queue.shift()!;
            event.timestamp = moment().toISOString();
            this.contentActivityTrackingApi.sendEvent(event).subscribe(
                () => {},
                (error: any) => {
                    if (error.status === 410) {
                        //resend open if backend lost content state
                        const newEvent = new PortalEvent(
                            event.topicId,
                            event.deviceId,
                            event.userId,
                            event.tabId,
                            event.timestamp,
                            PortalEventType.Open,
                            event.portalId,
                            event.attributes
                        );

                        this.contentActivityTrackingApi
                            .sendEvent(newEvent)
                            .toPromise();
                    }
                    console.error('Content activity tracking error', error);
                }
            );
        }
    }

    public static getDeviceId(): string {
        return localStorage.getItem('deviceId') || '';
    }

    /**
     * Track user activity in resources
     * @param userId
     * @param type
     */
    public trackActivity(
        topicId: string,
        userId: string,
        type: PortalEventType
    ): void {
        const userGuid = localStorage.getItem('userGuid');
        const sessionToken = localStorage.getItem('sessionToken');
        const subDomain = localStorage.getItem('subdomain');
        const callbackUrl = localStorage.getItem('callbackUrl');
        const registrationNumber = localStorage.getItem('registrationNumber');
        const fqdn = localStorage.getItem('fqdn');

        let portalId: string | undefined = undefined,
            courseId: string | undefined = undefined,
            attribPortalId: string | undefined = undefined;

        if (SSOUtils.isSABATriggerPortal()) {
            const uuid = 'c71f6538-e379-447e-aaf3-3c9e806b1ade';
            courseId = uuid;
            portalId = uuid;
            attribPortalId = uuid;
        } else if (SSOUtils.isCSODTriggerPortal()) {
            const uuid = '7191c86b-9b90-4f8c-9127-8eca06a50a84';
            const csodCourseId = 'c71f6538-e379-447e-aaf3-3c9e806b1ade';
            courseId = csodCourseId;
            portalId = uuid;
            attribPortalId = uuid;
        } else {
            const uuid = '7449c9c9-6523-4e16-9767-00a775969c12';
            courseId = undefined;
            attribPortalId = undefined;
            portalId = uuid;
        }

        let attributes: any = {
            UserGUID: userGuid ? userGuid : undefined,
            CourseId: courseId,
            SessionToken: sessionToken ? sessionToken : undefined,
            CallBackUrl: callbackUrl ? callbackUrl : undefined,
            RegistrationNumber: registrationNumber
                ? registrationNumber
                : undefined,
            SubDomain: subDomain ? subDomain : undefined,
            FQDN: fqdn && !subDomain ? fqdn : undefined,
            PortalId: attribPortalId,
        };

        if (SSOUtils.isUseProvisioning()) {
            const reportsPayload = reportsAttributesForProvision();

            attributes = {
                ...attributes,
                ...reportsPayload,
            };
        }

        let event = new PortalEvent(
            topicId,
            PortalTrackingService.getDeviceId(),
            userId,
            this.tabId,
            type === PortalEventType.Open
                ? moment().add(100, 'ms').toISOString()
                : moment().toISOString(),
            type,
            portalId,
            attributes
        );
        this.inMemoryQueue.push(event);
    }
}
