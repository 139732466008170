import { useCallback, useMemo } from 'react';

function modNames(
    qualifiedName: string,
    mods: { [key: string]: boolean | string | null }
) {
    return Object.keys(mods)
        .filter((modName) => Boolean(mods[modName]) && mods[modName] !== 'no')
        .map((modName) => {
            let modValue =
                typeof mods[modName] === 'boolean' ? 'yes' : mods[modName];
            return qualifiedName + '_' + modName + '_' + modValue;
        })
        .join(' ');
}

export function useBem(blockName: string, asElementOf?: string) {
    const toString = useCallback(
        () => blockName + (asElementOf ? ' ' + asElementOf : ''),
        [blockName, asElementOf]
    );

    const mods = useCallback(
        function (mods: { [key: string]: boolean | string | null }) {
            return toString() + ' ' + modNames(blockName, mods);
        },
        [blockName, toString]
    );

    const elem = useCallback(
        function (
            elemName: string,
            mods?: { [key: string]: boolean | string | null }
        ) {
            let qualifiedName = blockName + '__' + elemName;
            if (mods) {
                let activeMods = modNames(qualifiedName, mods);
                return qualifiedName + ' ' + activeMods;
            } else {
                return qualifiedName;
            }
        },
        [blockName]
    );

    const elems = useCallback(
        function (...elemNames: string[]) {
            return elemNames.map((elemName) => elem(elemName)).join(' ');
        },
        [elem]
    );

    return useMemo(() => {
        return {
            block: blockName,
            elem,
            elems,
            mods,
            toString,
        };
    }, [blockName, elem, elems, mods, toString]);
}

export type BemType = ReturnType<typeof useBem>;
