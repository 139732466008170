import { RootEpic } from './root-epic';
import { catchError, filter, map, switchMap } from 'rxjs/operators';
import { UseCaseEnum } from '../components/analytics/proficiency-certificate';
import { isActionOf } from 'typesafe-actions';
import { Action } from '../store/root-action';
import { localization } from '../localization';
import { of } from 'rxjs';
import { isCordova } from '../utils/cordova';

const loadHistoryEpic: RootEpic = (action$, state$, { learnerProfileApi }) =>
    action$.pipe(
        filter(isActionOf(Action.proficiency.loadHistory.request)),
        switchMap((x) => {
            const { learnerUUID, topicUUID } = x.payload;

            return learnerProfileApi
                .loadProficiencyOverAllHistory({
                    learnerUUID: learnerUUID,
                    topicUUID: topicUUID,
                })
                .pipe(
                    map((x) => Action.proficiency.loadHistory.success(x)),
                    catchError((e) =>
                        of(Action.proficiency.loadHistory.failure(e))
                    )
                );
        })
    );

const loadFilteredList: RootEpic = (action$, state$, { learnerProfileApi }) =>
    action$.pipe(
        filter(isActionOf(Action.proficiency.loadList.request)),
        switchMap((x) => {
            return learnerProfileApi.loadProficienciesLevels(x.payload).pipe(
                map((x) => {
                    return Action.proficiency.loadList.success(x);
                }),
                catchError((e) => of(Action.proficiency.loadList.failure(e)))
            );
        })
    );

const preloadHistoryEpic: RootEpic = (action$, state$, { learnerProfileApi }) =>
    action$.pipe(
        filter(isActionOf(Action.proficiency.loadCurrent.request)),
        switchMap((x) => {
            const { learnerUUID, topicUUID } = x.payload;
            return learnerProfileApi
                .loadProficiencyOverAllHistory({
                    learnerUUID: learnerUUID,
                    topicUUID: topicUUID,
                })
                .pipe(
                    map((x) => {
                        return Action.proficiency.loadCurrent.success(x);
                    }),
                    catchError((e) =>
                        of(Action.proficiency.loadHistory.failure(e))
                    )
                );
        })
    );

const loadLatestCurrentTopicOPL: RootEpic = (
    action$,
    state$,
    { learnerProfileApi }
) =>
    action$.pipe(
        filter(isActionOf(Action.proficiency.loadCurrent.success)),
        switchMap((x) => {
            const { payload } = x;
            if (payload.length > 0) {
                const sortListHistory = payload
                    .filter((x: any) => {
                        return x?.useCase === UseCaseEnum.Portal;
                    })
                    .sort((a: any, b: any) => {
                        if (a.calculationDate > b.calculationDate) return 1;
                        if (a.calculationDate < b.calculationDate) return -1;
                        return 0;
                    })
                    .reverse();

                return learnerProfileApi
                    .loadProficienciesLevels([sortListHistory[0]])
                    .pipe(
                        map((x) => {
                            return Action.proficiency.setCurrentTopicOPL(x[0]);
                        }),
                        catchError((e) =>
                            of(Action.proficiency.loadList.failure(e))
                        )
                    );
            } else {
                return of(Action.proficiency.setCurrentTopicOPL(null));
            }
        })
    );

const getCertificateEpic: RootEpic = (
    action$,
    state$,
    { learnerCertificateApi }
) =>
    action$.pipe(
        filter(isActionOf(Action.proficiency.loadCertificate.request)),
        switchMap((x) => {
            return learnerCertificateApi.getCertificate(x.payload).pipe(
                map((x) => {
                    if (isCordova) {
                        return Action.contentSavedActions.setNotification({
                            show: true,
                            error: false,
                            maximum: true,
                            text: localization.analytics_component_button_downloaded_success,
                            key: 109,
                        });
                    } else {
                        return Action.proficiency.loadCertificate.success(x);
                    }
                }),
                catchError((e) =>
                    of(
                        Action.proficiency.loadCertificate.failure(e),
                        Action.contentSavedActions.setNotification({
                            show: true,
                            error: true,
                            maximum: false,
                            text: localization.error,
                            key: 109,
                        })
                    )
                )
            );
        })
    );

const getCertificateAdaptiveTestEpic: RootEpic = (
    action$,
    state$,
    { learnerCertificateApi }
) =>
    action$.pipe(
        filter(
            isActionOf(
                Action.proficiency.loadProficiencyOverviewCertificate.request
            )
        ),
        switchMap((x) => {
            return learnerCertificateApi
                .getProficiencyOverview({
                    testUUID: x.payload?.testUUID as string,
                })
                .pipe(
                    map((x) => {
                        if (isCordova) {
                            return Action.contentSavedActions.setNotification({
                                show: true,
                                error: false,
                                maximum: true,
                                text: localization.analytics_component_button_downloaded_success,
                                key: 109,
                            });
                        } else {
                            return Action.proficiency.loadProficiencyOverviewCertificate.success(
                                x
                            );
                        }
                    }),
                    catchError((e) => {
                        return of(
                            Action.proficiency.loadProficiencyOverviewCertificate.failure(
                                e
                            ),
                            // change contentSavedActions to notificationActions, we have notification component.
                            Action.contentSavedActions.setNotification({
                                show: true,
                                error: true,
                                maximum: false,
                                text: localization.error,
                                key: 109,
                            })
                        );
                    })
                );
        })
    );

export const proficiencyEpics = [
    loadHistoryEpic,
    loadFilteredList,
    preloadHistoryEpic,
    loadLatestCurrentTopicOPL,
    getCertificateEpic,
    getCertificateAdaptiveTestEpic,
];
