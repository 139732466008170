import { createReducer } from 'typesafe-actions';
import { combineReducers } from 'redux';
import { Action } from '../root-action';
import {
    IChangeLanguageModalProps,
    LocalizationLanguage,
    UserRecord,
} from './user-record';
import { setConnectivity } from '../resources/offline/offline-actions';
import { GetConfigSSO } from '../../components/root/private-route';

const identity = createReducer<UserRecord | null>(null)
    .handleAction(Action.user.login.success, (_, action) => action.payload)
    .handleAction(Action.user.login.failure, () => null)
    .handleAction(Action.user.logout.success, () => null)
    .handleAction(Action.user.loginSSO.success, (_, action) => action.payload)
    .handleAction(Action.user.loginSSO.failure, (state, action) => null)
    .handleAction(
        Action.user.loginDingTalk.success,
        (_, action) => action.payload
    )
    .handleAction(Action.user.setDingTalkUser, (_, action) => action.payload)
    .handleAction(Action.user.loginDingTalk.failure, () => null)
    .handleAction(
        Action.user.demandNewPassword,
        (state, action) => action.payload
    )
    .handleAction(Action.user.setNewPassword.success, (state, action) => {
        if (state) {
            var newState = state.set('shouldResetPassword', false);
            return newState;
        }
        return state;
    })
    .handleAction(
        [
            Action.user.userRenewJWToken.success,
            Action.user.userGenerateToken.success,
        ],
        (state, action) => {
            if (state) {
                var newState = state.set('jwt', action.payload);
                return newState;
            }
            return state;
        }
    );

const loginError = createReducer<string | null>(null)
    .handleAction(Action.user.login.failure, (state, action) =>
        action.payload.message.toString()
    )
    .handleAction(Action.user.loginSSO.failure, (state, action) =>
        action.payload.toString()
    )
    .handleAction(Action.user.loginDingTalk.failure, (state, action) =>
        action.payload.toString()
    )
    .handleAction(Action.user.login.success, (state, action) => null)
    .handleAction(Action.user.demandNewPassword, (state, action) => null);

const resetPasswordError = createReducer<string | null>(null)
    .handleAction(Action.user.resetPassword.failure, (state, action) => {
        if (action.payload.message.toString() === 'ajax error 417') {
            return 'You have entered an unregistered email address.';
        } else {
            return 'Unknown error';
        }
    })
    .handleAction(Action.user.resetPassword.success, (state, action) => null);

const resetPasswordSuccess = createReducer<boolean>(false)
    .handleAction(Action.user.resetPassword.failure, (state, action) => false)
    .handleAction(Action.user.resetPassword.success, (state, action) => true)
    .handleAction(Action.user.resetPassword.request, (state, action) => false)
    .handleAction(
        Action.user.setResetPasswordSuccessFalse,
        (state, action) => false
    );

const isLoggingIn = createReducer(false)
    .handleAction(Action.user.login.request, () => {
        localStorage.setItem('restartApp', 'true');
        return true;
    })
    .handleAction(Action.user.login.success, () => false)
    .handleAction(Action.user.login.failure, () => false)
    .handleAction(Action.user.demandNewPassword, () => false)
    .handleAction(Action.user.setNewPassword.request, () => true)
    .handleAction(Action.user.setNewPassword.success, () => false)
    .handleAction(Action.user.setNewPassword.failure, () => false)
    .handleAction(Action.user.setIsLoggingIn, (_, action) => action.payload);

const isLoggingOut = createReducer(false)
    .handleAction(Action.user.logout.request, () => true)
    .handleAction(Action.user.login.success, () => false) // reset only on successful login
    .handleAction(Action.user.loginSSO.success, () => false)
    .handleAction(Action.user.loginDingTalk.success, () => false)
    .handleAction(Action.user.setNewPassword.success, () => false);

const newPasswordError = createReducer<string | null>(null)
    .handleAction(Action.user.setNewPassword.failure, (_, action) =>
        action.payload.message.toString()
    )
    .handleAction(Action.user.setNewPassword.success, () => null);

const currentUILanguage = createReducer<LocalizationLanguage>(
    LocalizationLanguage.getDefaultEnglish()
)
    .handleAction(Action.user.changeUILanguage.success, (_, action) => {
        let lang = new LocalizationLanguage(
            action.payload
                ? action.payload
                : LocalizationLanguage.getDefaultEnglish()
        );
        return lang;
    })
    .handleAction(Action.user.changeUILanguage.failure, () => {
        return LocalizationLanguage.getDefaultEnglish();
    });

const currentPreferredLanguage = createReducer<LocalizationLanguage>(
    LocalizationLanguage.getDefaultEnglish()
)
    .handleAction(Action.user.changePreferredLanguage.success, (_, action) => {
        let lang = new LocalizationLanguage(
            action.payload
                ? action.payload
                : LocalizationLanguage.getDefaultEnglish()
        );
        return lang;
    })
    .handleAction(
        Action.user.changePreferredLanguage.failure,
        (state, action) => {
            return LocalizationLanguage.getDefaultEnglish();
        }
    );

const availableUILanguages = createReducer<LocalizationLanguage[]>(
    []
).handleAction(
    Action.user.loadLanguageList.success,
    (state, action) => action.payload
);

const availablePreferredLanguageList = createReducer<any>([]).handleAction(
    [
        Action.user.getPreferredLanguagesListDefault.success,
        Action.user.getPreferredLanguagesList.success,
    ],
    (state, action) => {
        return action.payload.preferredLanguages.map((l: any) => {
            let t = new Map<string, string>();
            Object.keys(l.code).forEach((k) => t.set(k, l.code[k]));

            return {
                id: l.code,
                name: l.name,
                endonym: l.endonym,
                translation: t,
            } as LocalizationLanguage;
        });
    }
);

const isLoadingDisplayLanguageOnLogin = createReducer<boolean>(false)
    .handleAction(Action.user.login.request, () => true)
    .handleAction(Action.user.changeUILanguage.success, () => false)
    .handleAction(Action.user.login.failure, () => false)
    .handleAction(Action.user.setNewPassword.success, () => false);

const isDisplayLanguageLoaded = createReducer<boolean>(false)
    .handleAction(Action.user.changeUILanguage.request, () => false)
    .handleAction(Action.user.changeUILanguage.success, () => true)
    .handleAction(Action.user.changeUILanguage.failure, () => true);

const isChangingUILanguage = createReducer<boolean>(false)
    .handleAction(Action.user.changeUILanguage.request, () => true)
    .handleAction(
        Action.user.changeUILanguage.success,
        (state, action) => false
    )
    .handleAction(
        Action.user.changeUILanguage.failure,
        (state, action) => false
    );

const isChangingPreferredLanguage = createReducer<boolean>(false)
    .handleAction(
        Action.user.changePreferredLanguage.request,
        (state, action) => true
    )
    .handleAction(
        Action.user.changePreferredLanguage.success,
        (state, action) => false
    )
    .handleAction(
        Action.user.changePreferredLanguage.failure,
        (state, action) => false
    );

const isLoginSSO = createReducer<boolean>(false)
    .handleAction(
        [
            Action.user.login.success,
            Action.user.login.failure,
            Action.user.loginSSO.request,
            Action.user.loginSSO.failure,
            Action.user.loginDingTalk.success,
            Action.user.loginDingTalk.failure,
            Action.user.logout.success,
        ],
        () => false
    )
    .handleAction(Action.user.loginSSO.success, () => {
        localStorage.setItem('restartApp', 'true');
        return true;
    });

const isLoginDingTalk = createReducer<false | UserRecord>(false)
    .handleAction(
        [
            Action.user.login.success,
            Action.user.login.failure,
            Action.user.loginSSO.success,
            Action.user.loginSSO.failure,
            Action.user.loginDingTalk.request,
            Action.user.loginDingTalk.failure,
            Action.user.logout.success,
        ],
        () => false
    )
    .handleAction(Action.user.loginDingTalk.success, (_, action) => {
        localStorage.setItem('restartApp', 'true');
        return action.payload;
    });

/** NOTE: When we wanted to implement the SSO deeplink, the default value should be / **/
export const redirectPage = createReducer('/').handleAction(
    Action.user.setRedirectPage,
    (state, action) => action.payload
);

export const isLogoutProcess = createReducer(false)
    .handleAction(Action.user.logout.request, () => true)
    .handleAction(Action.user.resetLogoutProcess, () => false);

export const enableOfflineReducer = createReducer<boolean>(false)
    .handleAction(Action.offline.setEnableOffline, (_, action) =>
        Boolean(action.payload)
    )
    .handleAction(Action.offline.postOfflineSetting.success, (_, action) =>
        Boolean(action.payload.offlineModeEnabled)
    );

export const hasContractOfflineReducer = createReducer<boolean>(
    false
).handleAction(Action.contracts.loadUserContracts.success, (_, action) => {
    const offlineContent = action.payload
        .valueSeq()
        .toArray()
        .find((y) => {
            return y.type === 'offline-activity';
        });
    return Boolean(offlineContent);
});

export const notifConnectionReducer = createReducer<boolean | null>(
    null
).handleAction(setConnectivity, (_, action) => {
    return action.payload;
});

const isLoadingJWToken = createReducer<boolean>(false)
    .handleAction(
        [
            Action.user.userRenewJWToken.request,
            Action.user.userGenerateToken.request,
        ],
        () => true
    )
    .handleAction(
        [
            Action.user.userRenewJWToken.success,
            Action.user.userGenerateToken.success,
        ],
        () => false
    )
    .handleAction(
        [
            Action.user.userRenewJWToken.failure,
            Action.user.userGenerateToken.failure,
        ],
        () => false
    );

export const restartToken = createReducer<boolean>(false)
    .handleAction(Action.user.setRenewToken, (_, action) => action.payload)
    .handleAction(Action.user.userRenewJWToken.success, () => false)
    .handleAction(Action.user.userRenewJWToken.failure, () => true);

export const isExpiredToken = createReducer<boolean>(false)
    .handleAction(Action.user.setIsExpiredToken, (_, action) => action.payload)
    .handleAction(
        [Action.user.logout.success, Action.user.userGenerateToken.success],
        (_, action) => false
    )
    .handleAction(Action.user.userGenerateToken.failure, (_, action) => true);

export const ssoLogout = createReducer<GetConfigSSO | null>(null).handleAction(
    Action.user.setSSOLogout,
    (_, action) => action.payload
);

const changeLanguageModalStatus = createReducer<IChangeLanguageModalProps>({
    open: false,
    newValue: null,
})
    .handleAction(
        Action.user.setChangeLanguageModalStatus,
        (_, action) => action.payload
    )
    .handleAction(Action.user.changeUILanguage.success, () => {
        return {
            open: false,
            newValue: null,
        };
    })
    .handleAction(Action.user.changeUILanguage.failure, () => {
        return {
            open: false,
            newValue: null,
        };
    });

export const secretUser = createReducer<string | null>(null).handleAction(
    Action.user.setUserAES,
    (_, action) => action.payload
);

export const userReducer = () =>
    combineReducers({
        identity,
        loginError,
        isLoggingIn,
        isLoggingOut,
        resetPasswordError,
        resetPasswordSuccess,
        newPasswordError,
        currentUILanguage,
        currentPreferredLanguage,
        isChangingUILanguage,
        isChangingPreferredLanguage,
        isLoadingDisplayLanguageOnLogin,
        availableUILanguages,
        isDisplayLanguageLoaded,
        isLoginSSO,
        isLoginDingTalk,
        redirectPage,
        isLogoutProcess,
        availablePreferredLanguageList,
        enableOffline: enableOfflineReducer,
        hasContractOffline: hasContractOfflineReducer,
        notifConnection: notifConnectionReducer,
        isLoadingJWToken,
        restartToken,
        isExpiredToken,
        changeLanguageModalStatus,
        ssoLogout,
        secretUser,
    });
