import { localization } from '../../../localization';

export const getPerformanceOptionVariant = (variant: number) => {
    if (variant === 5) {
        return localization.performanceSurvey_choices_strongly_agree;
    }
    if (variant === 4) {
        return localization.performanceSurvey_choices_agree;
    }
    if (variant === 3) {
        return localization.performanceSurvey_choices_no_answer;
    }
    if (variant === 2) {
        return localization.performanceSurvey_choices_disagree;
    }
    return localization.performanceSurvey_choices_strongly_disagree;
};
