import { of } from 'rxjs';
import { ajax } from 'rxjs/ajax';
import { catchError, map } from 'rxjs/operators';
import { jwtLegacyService } from '../../../services/api-urls';
import { queryString } from './use-parse-query-string';

interface UseGetLegacyTokenProps {
    email: string;
    password: string;
    hasEmailValidation: boolean;
}

export interface IUseGetLegacyToken {
    IpAddrses: string;
    JsessionId: string;
    token: string;
}

export const UseGetLegacyToken = (params: UseGetLegacyTokenProps) => {
    return ajax
        .get(
            `${jwtLegacyService()}/jwt/auth/legacy/portal/token_cookie?${queryString(
                params
            )}`
        )
        .pipe(
            map((response) => {
                if (response.status === 200) {
                    // Handle successful response
                    const data = response.response;
                    return {
                        ...(data as any),
                        token: response.responseHeaders.authorization,
                    } as IUseGetLegacyToken;
                } else {
                    // Handle non-200 status code (business rule)
                    throw new Error('Invalid status code: ' + response.status);
                }
            }),
            catchError((error) => {
                // Handle network errors or errors thrown from the map operator
                console.error('Error:', error);
                // Handle the error and return a default value or rethrow the error if necessary
                return of(error);
            })
            // Extract response data
        );
};
