import { createSelector } from 'reselect';
import { RootState } from 'typesafe-actions';

export const proficiencySelector = (state: RootState) => state.proficiency;

export const proficiencyHistorySelector = createSelector(
    proficiencySelector,
    (state) => state.history
);

export const proficiencyListSelector = createSelector(
    proficiencySelector,
    (state) => state.list
);

export const proficiencyCurrentSelector = createSelector(
    proficiencySelector,
    (state) => state.current
);
