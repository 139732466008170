import { Record } from 'immutable';

export interface VcrLinkHashProps {
    id: number;
    userId: number;
    filename: string;
    fileLink: string;
}
export interface vcrFetchMessageProps {
    label?: string;
    classId?: string;
    hash?: string;
    chatId?: number;
}
export const defaultVcrLinkHashProps = {
    id: 0,
    userId: 0,
    filename: '',
    fileLink: '',
};
export class VcrLinkHashData
    extends Record(defaultVcrLinkHashProps)
    implements VcrLinkHashProps {}
