import { createAction } from 'typesafe-actions';
import { ContentActivityEventType } from '../../services/content-activity-tracking/content-activity-event-type';

export const trackContentActivity = createAction(
    '@@track-content-activity-event'
)<{
    contentId: string;
    userId: string;
    type: ContentActivityEventType;
    quizId?: string;
    score?: number;
}>();

export const contentActivityTrackingActions = {
    trackContentActivity,
};
