import { List, Record } from 'immutable';
import Moment from 'moment';
import { ResourceDescription } from '../resources';
import { ResourceContentType } from '../resource-content-type';

export interface StandaloneQuizDescriptionProps extends ResourceDescription {}

const defaultArticleDescriptionProps: StandaloneQuizDescriptionProps = {
    resourceId: '',
    thumbnail: null,
    title: '',
    date: Moment.invalid(),
    tags: List(),
    categories: List(),
    index: 0,
    groupId: 0,
    content: null,
    quizArticleId: null,
    type: ResourceContentType.Quiz,
    contentMetadata: null,
    contentUUId: '',
    quizUUID: '',
    template: 'old',
    topicUUID: '',
};

export class StandaloneQuizDescription
    extends Record(defaultArticleDescriptionProps)
    implements StandaloneQuizDescriptionProps {}

export function isStandaloneQuiz(
    resource: ResourceDescription
): resource is StandaloneQuizDescription {
    return resource.type === ResourceContentType.Quiz;
}
