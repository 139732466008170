import { createAction, createAsyncAction } from 'typesafe-actions';
import {
    OnboardingDisplayStrategyProps,
    StateProgressProps,
    OnboardingDisplayStrategyRecord,
    ProgressDisplayStrategyRecord,
    StateProgressOnboardingRecord,
    OnboardingStatus,
} from './learner-onboarding-records';

export const loadLearnerDisplayStrategy = createAsyncAction(
    '@@learner-onboarding/load/request',
    '@@learner-onboarding/load/success',
    '@@learner-onboarding/load/failure'
)<void, OnboardingDisplayStrategyProps | null, Error>();

export const loadProgressStateOnboarding = createAsyncAction(
    '@@learner-onboarding/load-progress/request',
    '@@learner-onboarding/load-progress/success',
    '@@learner-onboarding/load-progress/failure'
)<void, StateProgressProps | null, Error>();

export const setLearnerDisplayStrategy = createAction(
    '@@learner-onboarding/set-learner-onboarding'
)<OnboardingDisplayStrategyRecord | null>();

export const setCurrentDisplayStrategy = createAction(
    '@@learner-onboarding/set-current-display-strategy'
)<ProgressDisplayStrategyRecord | null>();

export const saveProgressStateDisplay = createAsyncAction(
    '@@learner-onboarding/save-current-display-strategy/request',
    '@@learner-onboarding/save-current-display-strategy/success',
    '@@learner-onboarding/save-current-display-strategy/failure'
)<number, StateProgressProps | null, Error>();

export const setPageDisplay = createAction(
    '@@learner-onboarding/set-page-display'
)<string | null>();

export const setProgressOnboarding = createAction(
    '@@learner-onboarding/set-progress-state-onboarding'
)<StateProgressOnboardingRecord | null>();

export const setOnboardingStatus = createAction(
    '@@learner-onboarding/set-status'
)<{ status: OnboardingStatus }>();
