import { createAsyncAction } from 'typesafe-actions';
import { GradingDto, GradingGoCEFR } from './grading-dto';

export const loadGrading = createAsyncAction(
    '@@grading/request',
    '@@grading/success',
    '@@grading/failure'
)<{ id: string }, GradingDto, Error>();

export const loadAllGradings = createAsyncAction(
    '@@grading/all/request',
    '@@grading/all/success',
    '@@grading/all/failure'
)<void, GradingDto[], Error>();

export const loadAllGradingsGoCEFR = createAsyncAction(
    '@@grading/GoCEFR/request',
    '@@grading/GoCEFR/success',
    '@@grading/GoCEFR/failure'
)<void, GradingGoCEFR[], Error>();

export const gradingActions = {
    loadAllGradings,
    loadGrading,
    loadAllGradingsGoCEFR,
};
