import { Record } from 'immutable';
import { TimeLimitRule } from './time-limit-rule';

export enum ContractTypes {
    Scheduler = 'scheduler',
    EWriting = 'ewriting',
    ConversationClass = 'lesson',
}

export enum LessonTypes {
    Group = 'Group',
    Individual = 'Individual',
    Conversation = 'Conversation',
}

export enum LessonAccessTypes {
    Intra = 'Intra',
    Inter = 'Inter',
    All = 'All',
    OneToOne = 'OneToOne',
}

export enum LessonAccessParamTypes {
    Intra = 'IntraCompany',
    Inter = 'InterCompany',
    All = 'All',
    None = 'None',
}

export interface SubscriptionRule {
    displayLimitWindow?: TimeLimitRule;
    displayLimit?: number;
    hourLimitWindow?: TimeLimitRule;
    hourLimit?: number;
    postLimitWindow?: TimeLimitRule;
    postLimit?: number;
}

export interface LessonRule {
    type: LessonTypes;
    lessonType: string;
    accessType: LessonAccessTypes;
    lessonLimitUnit: string;
    lessonLimit: number;
    lessonTopicLimit: any;
    end?: string;
    start?: string;
}

export interface ReassessmentRule {
    ruleType: string;
    ruleValue: number;
}

export interface DownloadRules {
    allowableContentCount: number;
    allowableCourseCount: number;
    topicUuids: string[];
}

interface ContractTerm {
    mode?: string;
    enabled: boolean;
    start: any;
    end: any;
    subscriptionRule?: SubscriptionRule;
    reassessmentRule?: ReassessmentRule;
    downloadRules?: DownloadRules[];
    lessons?: LessonRule[];
}

interface ContractBank {
    bankId?: number;
    start?: string;
    end?: string;
    contractTimeZone?: string;
}

interface ContractService {
    type: string;
    terms: ContractTerm[];
    banks: ContractBank[] | null;
}

export interface ContractDto {
    contractId: string;
    services: ContractService[];
}

export interface UserContractProps {
    type: string;
    limitationRule: SubscriptionRule | ReassessmentRule | LessonRule[] | null;
    limitationBanks: ContractBank;
}

const defaultUserContractProps: UserContractProps = {
    type: '',
    limitationRule: null,
    limitationBanks: {},
};

export class UserContract
    extends Record(defaultUserContractProps)
    implements UserContractProps {}
