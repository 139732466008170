import { RootEpic } from './root-epic';
import {
    catchError,
    distinctUntilChanged,
    filter,
    map,
    switchMap,
} from 'rxjs/operators';
import { isActionOf } from 'typesafe-actions';
import { Action } from '../store/root-action';
import { Map } from 'immutable';
import { of } from 'rxjs';
import { CreditCreditAPIRecord } from '../store/credits/credits-data';

const loadUserCreditsEpic: RootEpic = (action$, state$, { creditsApi }) => {
    return action$
        .pipe(filter(isActionOf(Action.credits.loadCredits.request)))
        .pipe(
            switchMap((r) => {
                return creditsApi.loadCredits(r.payload.userUUID).pipe(
                    map((response) => {
                        let credits = Map(
                            response.map<[string, CreditCreditAPIRecord]>(
                                (x) => [String(x.orderId), x]
                            )
                        );
                        return Action.credits.loadCredits.success({
                            credits: credits,
                        });
                    }),
                    catchError((e) => of(Action.credits.loadCredits.failure(e)))
                );
            })
        );
};

const updateCreditsForCurrentUserEpic: RootEpic = (action$, state$) =>
    state$.pipe(
        map((x) => x.user.identity),
        filter(
            (identity) =>
                !!(identity && identity.learnerUUID) && navigator.onLine
        ),
        distinctUntilChanged(),
        switchMap((x) => {
            return of(
                Action.credits.loadCredits.request({ userUUID: x!.learnerUUID })
            );
        })
    );

export const creditEpics = [
    updateCreditsForCurrentUserEpic,
    loadUserCreditsEpic,
];
