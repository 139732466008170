import { createReducer } from 'typesafe-actions';
import Action from '../root-action';
import { combineReducers } from 'redux';

export interface DisplayLanguageState {
    isDisplayLanguageLoaded: boolean;
}

const initialDisplayLanguageState: DisplayLanguageState = {
    isDisplayLanguageLoaded: false,
};

const isDisplayLanguageLoaded = createReducer(
    initialDisplayLanguageState.isDisplayLanguageLoaded
)
    .handleAction(Action.user.changeUILanguage.request, () => false)
    .handleAction(
        [
            Action.user.changeUILanguage.success,
            Action.user.changeUILanguage.failure,
        ],
        () => true
    );

export const displayLanguageReducer = combineReducers({
    isDisplayLanguageLoaded,
});
