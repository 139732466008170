import moment from 'moment';

const getMomentFromHours = (hoursString: string) => {
    const [h, m] = hoursString
        .replace('Z', '')
        .split(':')
        .map((val: string) => +val);

    return moment().utc().set('hours', h).set('minutes', m).set('seconds', 0);
};
export const checkLcTeamOfficeHours = (team: any) => {
    let officeHours = false;

    const now = moment().utc();
    for (let contact of team.contacts) {
        if (contact.startOpeningHours && contact.endOpeningHours) {
            const start = getMomentFromHours(contact.startOpeningHours);
            const end = getMomentFromHours(contact.endOpeningHours);
            if (now.isBetween(start, end)) {
                officeHours = true;
                break;
            }
        }
    }

    return officeHours;
};
