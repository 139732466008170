export const setSecondaryColor = (color: any) => {
    let newColor = '';
    if (color.match(/^rgb/)) {
        newColor = `${color.slice(0, color.length - 2)}, 0.05)`;
    } else {
        color = +(
            '0x' + color.slice(1).replace(color.length < 5 && /./g, '$&$&')
        );

        const r = color >> 16;
        const g = (color >> 8) & 255;
        const b = color & 255;

        newColor = `rgba(${r}, ${g}, ${b}, 0.05)`;
    }
    return newColor;
};
