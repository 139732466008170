import { createReducer } from 'typesafe-actions';
import {
    loadActivityList,
    loadCourseList,
    loadInProgressCompletedActivities,
    // loadEwritingsList,
    loadTestsList,
} from './in-progress-actions';
import { combineReducers } from 'redux';
import {
    defaultInProgressFeatureData,
    InProgressFeatureData,
    PlacementTestDTO,
} from './in-progress-record';
import { CompletedResource } from '../resources/completed/completed-record';

// Requests
export const inProgressTabActivities = createReducer([])
    .handleAction([loadActivityList.failure, loadActivityList.cancel], () => [])
    .handleAction(
        loadActivityList.success,
        (state, action: any) => action.payload
    );

export const inProgressTabCourses = createReducer([])
    .handleAction([loadCourseList.failure, loadCourseList.cancel], () => [])
    .handleAction(
        loadCourseList.success,
        (state, action: any) => action.payload
    );

export const inProgressTabTests = createReducer([])
    .handleAction([loadTestsList.failure, loadTestsList.cancel], () => [])
    .handleAction(loadTestsList.success, (state, action: any) =>
        action.payload.filter(
            (loadedTest: PlacementTestDTO) =>
                loadedTest.testStatus === 'Incomplete'
        )
    );

export const loadCompletedActivitiesInProgress = createReducer<
    CompletedResource[] | null
>(null)
    .handleAction(
        [
            loadInProgressCompletedActivities.failure,
            loadInProgressCompletedActivities.cancel,
        ],
        () => []
    )
    .handleAction(
        loadInProgressCompletedActivities.success,
        (state, action: any) => action.payload
    );

// export const inProgressTabEwritings = createReducer([])
//     .handleAction(
//         [
//             loadEwritingsList.failure,
//             loadEwritingsList.cancel
//         ],
//         () => []
//     )
//     .handleAction(
//         loadEwritingsList.success,
//         (state, action: any) => action.payload
//     )

// Loadings
export const isLoadingInProgressTabActivities = createReducer(false)
    .handleAction(
        [
            loadActivityList.success,
            loadActivityList.failure,
            loadActivityList.cancel,
        ],
        () => false
    )
    .handleAction([loadActivityList.request], () => true);

export const isLoadingInProgressTabCourses = createReducer(false)
    .handleAction([loadCourseList.request], () => true)
    .handleAction(
        [loadCourseList.success, loadCourseList.failure, loadCourseList.cancel],
        () => false
    );

export const isLoadingInProgressTabTests = createReducer(false)
    .handleAction([loadTestsList.request], () => true)
    .handleAction(
        [loadTestsList.success, loadTestsList.failure, loadTestsList.cancel],
        () => false
    );

// export const isLoadingInProgressTabEwritings = createReducer(false)
//     .handleAction(
//         [
//             loadEwritingsList.request,
//         ],
//         () => true
//     )
//     .handleAction(
//         [
//             loadEwritingsList.success,
//             loadEwritingsList.failure,
//             loadEwritingsList.cancel,
//         ],
//         () => false
//     )

export const loadingActivitiesFinished = createReducer(false).handleAction(
    [loadActivityList.success, loadActivityList.failure],
    () => true
);

export const loadingCoursesFinished = createReducer(false).handleAction(
    [loadCourseList.success, loadCourseList.failure],
    () => true
);

export const loadingTestsFinished = createReducer(false).handleAction(
    [loadTestsList.success, loadTestsList.failure],
    () => true
);

// export const loadingEWritingsFinished = createReducer(false)
//     .handleAction(
//         [
//             loadEwritingsList.success
//         ],
//         () => true
//     )

export const InProgressFeatureDataItems = createReducer(
    defaultInProgressFeatureData
).handleAction(
    [
        loadActivityList.success,
        loadActivityList.failure,
        loadActivityList.cancel,
        loadCourseList.success,
        loadCourseList.failure,
        loadCourseList.cancel,
        loadTestsList.success,
        loadTestsList.failure,
        loadTestsList.cancel,
        // loadEwritingsList.success,
        // loadEwritingsList.failure,
        // loadEwritingsList.cancel,
    ],
    (state, action: any) => {
        let activities = state.activities;
        let courses = state.courses;
        let tests = state.tests;
        // let ewritings = state.ewritings

        // Activities
        if (action.type === '@@in-progress-tab/load-activity-list/success') {
            activities = action.payload;
        }
        if (action.type === '@@in-progress-tab/load-activity-list/failure') {
            activities = [];
        }

        // Courses
        if (action.type === '@@in-progress-tab/load-course-list/success') {
            courses = action.payload;
        }
        if (action.type === '@@in-progress-tab/load-course-list/failure') {
            courses = [];
        }

        // Tests
        if (action.type === '@@in-progress-tab/load-tests-list/success') {
            tests = action.payload.filter(
                (loadedTest: PlacementTestDTO) =>
                    loadedTest.testStatus === 'Incomplete'
            );
        }
        if (action.type === '@@in-progress-tab/load-tests-list/failure') {
            tests = [];
        }

        // // Ewritings
        // if(action.type === '@@in-progress-tab/load-ewritings-list/success'){
        //     ewritings = action.payload;
        // }
        // if(action.type === '@@in-progress-tab/load-ewritings-list/failure'){
        //     ewritings = [];
        // }

        return new InProgressFeatureData({
            activities: activities,
            courses: courses,
            tests: tests,
            // ewritings: ewritings
        });
    }
);

// Combiner
export const InProgressTabReducers = combineReducers({
    inProgressTabActivities,
    inProgressTabCourses,
    inProgressTabTests,
    // inProgressTabEwritings,
    isLoadingInProgressTabActivities,
    isLoadingInProgressTabCourses,
    isLoadingInProgressTabTests,
    // isLoadingInProgressTabEwritings,
    InProgressFeatureDataItems,
    loadingActivitiesFinished,
    loadingCoursesFinished,
    loadingTestsFinished,
    // loadingEWritingsFinished,
    loadCompletedActivitiesInProgress,
});
