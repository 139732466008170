import { Axios } from 'axios-observable';
import { catchError, map } from 'rxjs/operators';
import { ApiHeaders } from '../../services/api-headers';
import { speechRecognitionService } from '../../services/api-urls';

export class SpeechRecognitionApi {
    apiHeaders: ApiHeaders;

    constructor(apiHeaders: ApiHeaders) {
        this.apiHeaders = apiHeaders;
    }

    public getSpeechRecognitionResult(formData: FormData) {
        return Axios.post<SpeechToTextResult>(
            `${speechRecognitionService()}/speech-quiz`,
            formData,
            {
                headers: this.apiHeaders.getHeaders({}, true),
            }
        ).pipe(
            map((response) => {
                if (response && response.data) {
                    return response.data;
                } else return null;
            }),
            catchError((error) => {
                throw error.message;
            })
        );
    }
}
