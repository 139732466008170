import { Record } from 'immutable';
import { InProgressResource } from '../resources/in-progress/in-progress-record';
// import { EwritingListItem } from '../e-writing/data/ewriting-models';

export interface CurriculumPropsLegacyTagEntries {
    id: number;
    isCategory: boolean;
    name: number;
}

export interface CurriculumPropsLegacy {
    articleId: number;
    contentType: string;
    groupId: number;
    groupName: string;
    id: number;
    resourcePrimKey: number;
    structureId: string;
    tagAssetId: number;
    tags: number[];
    tagEntries: CurriculumPropsLegacyTagEntries[];
}

export interface CoursesLegacyProps extends CurriculumPropsLegacy {
    smallImageId: number;
    mediumImage: string;
}

const defaultCoursesLegacy: CoursesLegacyProps = {
    articleId: 0,
    contentType: '',
    groupId: 0,
    groupName: '',
    id: 0,
    resourcePrimKey: 0,
    structureId: '',
    tagAssetId: 0,
    tags: [],
    tagEntries: [],
    smallImageId: 0,
    mediumImage: '',
};

export interface CurriculumProps {
    curriculumId: string;
    legacy: CurriculumPropsLegacy;
    progressStatus: 'COMPLETED' | 'IN_PROGRESS';
    completedContents: number;
    totalContents: number;
    percentsToComplete: number;
    curriculumScoreAvg: number;
    endTimeStamp: string;
}

export interface ContentMetadata {
    tags: string[];
    teachingConcept: string[];
    gradings: string[];
    topics: string[];
    accessRights: string[];
    organisations: string[];
    targetType: string;
    targetFunction: string;
    businessType: string;
    groupingList: string[];
}

const defaultContentMetadata = {
    tags: [],
    teachingConcept: [],
    gradings: [],
    topics: [],
    accessRights: [],
    organisations: [],
    targetType: '',
    targetFunction: '',
    businessType: '',
    groupingList: [],
};

export interface CourseCategoryDto {
    category: string;
    subCategory: string;
}

export interface CoursesProps {
    id: string;
    title: string;
    description: string;
    metadata: ContentMetadata;
    legacy: CoursesLegacyProps;
    content: any;
    migrationUuid: string;
    courseCategories?: CourseCategoryDto[];
    curriculumId: string;
    progressStatus: 'COMPLETED' | 'IN_PROGRESS';
    completedContents: number;
    totalContents: number;
    percentsToComplete: number;
    curriculumScoreAvg: number;
    endTimeStamp: string;
}

export interface PlacementTestProps {
    completionDate: string;
    grading: string;
    testStatus: 'Complete' | 'Incomplete' | 'New';
    testType: 'AdaptiveTest' | 'AssesmentTest' | 'PlacementTest';
    topic: string;
}

const defaultPlacementTestProps: PlacementTestProps = {
    completionDate: '',
    grading: '',
    testStatus: 'Incomplete',
    testType: 'PlacementTest',
    topic: '',
};

const defaultCoursesProps: CoursesProps = {
    id: '',
    title: '',
    description: '',
    metadata: defaultContentMetadata,
    legacy: defaultCoursesLegacy,
    content: {},
    migrationUuid: '',
    courseCategories: [],
    curriculumId: '',
    progressStatus: 'IN_PROGRESS',
    completedContents: 0,
    totalContents: 0,
    percentsToComplete: 0,
    curriculumScoreAvg: 0,
    endTimeStamp: '',
};

export class CoursesDTO
    extends Record(defaultCoursesProps)
    implements CoursesProps {}

export class PlacementTestDTO
    extends Record(defaultPlacementTestProps)
    implements PlacementTestProps {}

export interface InProgressFeatureDataProps {
    activities: InProgressResource[];
    courses: CoursesDTO[];
    tests: PlacementTestDTO[];
    // ewritings: EwritingListItem[]
}

export const defaultInProgressFeatureData = {
    activities: [],
    courses: [],
    tests: [],
    // ewritings: []
};

export class InProgressFeatureData
    extends Record(defaultInProgressFeatureData)
    implements InProgressFeatureDataProps {}
