import storage from 'redux-persist/lib/storage';
import { LocalizationLanguage, UserRecord } from './user/user-record';
import {
    IndustryCurrentRecord,
    IndustryRecord,
    JobFunctionListRecord,
    JobFunctionRecord,
    LanguageRecord,
    LearnerProfileRecord,
} from './learner-profile/learner-profile-records';
import {
    TestQuestionRecord,
    TestRecord,
} from './adaptive-test/adaptive-test-data';
import {
    OnboardingDisplayStrategyRecord,
    ProgressDisplayStrategyRecord,
    StateProgressOnboardingRecord,
} from './learner-onboarding/learner-onboarding-records';
import { CreditRecord, MediaRecord } from './scheduler/scheduler-data';
import { DisplayStrategyRecord } from './display-strategy/display-strategy';
import { ModuleRestrictionDisplayRecord } from './module-restriction/module-restriction-records';
import immutableTransform from './immutable-transform';
import { VcrLinkHashData } from './vcr/persist-message/persist-message-data';
import { SaveContentDTO } from './save-content/save-content-record';

export const persistConfig = {
    key: 'root',
    storage,
    transforms: [
        immutableTransform({
            records: [
                UserRecord,
                LearnerProfileRecord,
                LanguageRecord,
                JobFunctionRecord,
                JobFunctionListRecord,
                IndustryCurrentRecord,
                IndustryRecord,
                TestRecord,
                TestQuestionRecord,
                ProgressDisplayStrategyRecord,
                StateProgressOnboardingRecord,
                OnboardingDisplayStrategyRecord,
                CreditRecord,
                MediaRecord,
                LocalizationLanguage,
                DisplayStrategyRecord,
                ModuleRestrictionDisplayRecord,
                VcrLinkHashData,
                SaveContentDTO,
            ],
        }),
    ],
    whitelist: [
        'user',
        'learnerProfile',
        'test',
        'learnerOnboarding',
        'lessonsSettings',
        'displayStrategy',
        'moduleRestriction',
        'privacyPolicy',
        'vcrLink',
        'contentSavedActions',
        'performanceSurvey',
    ],
};
