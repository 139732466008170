export class PortalTrackingSettings {
    constructor(
        public readonly frequency: number,
        public readonly timeout: number
    ) {}

    public static getDefault(): PortalTrackingSettings {
        return new PortalTrackingSettings(60, 120);
    }
}
