import { createReducer } from 'typesafe-actions';
import { combineReducers } from 'redux';
import { ActivityCategory } from './activity-filters-data';
import { activityFiltersActions } from './activity-filters-actions';

export interface ActivityFiltersState {
    activityCategories: ActivityCategory[];
    isActivityCategoriesLoading: boolean;
}

const initialState: ActivityFiltersState = {
    activityCategories: [],
    isActivityCategoriesLoading: false,
};

const activityCategories = createReducer(
    initialState.activityCategories
).handleAction(
    activityFiltersActions.loadActivityCategories.success,
    (_, { payload }) => payload
);

const isActivityCategoriesLoading = createReducer(
    initialState.isActivityCategoriesLoading
)
    .handleAction(
        activityFiltersActions.loadActivityCategories.request,
        () => true
    )
    .handleAction(
        [
            activityFiltersActions.loadActivityCategories.success,
            activityFiltersActions.loadActivityCategories.failure,
        ],
        () => false
    );

export const activityFiltersReducer = combineReducers({
    activityCategories,
    isActivityCategoriesLoading,
});
