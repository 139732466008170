export enum ResourceContentType {
    Article = 'article',
    Video = 'video',
    Grammar = 'grammar',
    GrammarRule = 'grammar-rule',
    Vocabulary = 'vocabulary',
    Howto = 'howto',
    HowTo = 'how-to',
    Workshop = 'workshop',
    Quiz = 'standalone-quiz',
    Unknown = 'unknown',
}

export const isResourceContentType = (item: string) => {
    return Object.values(ResourceContentType).includes(
        item as ResourceContentType
    );
};
