import { createAction } from 'typesafe-actions';
import { LightstreamerClient } from 'lightstreamer-client-web';
import { WebsocketMessage } from './websocket-data';
import { SimpleUser } from 'sip.js/lib/platform/web';

const setLightstreamerSocket = createAction(
    '@@classroom/lightstreamer-websocket/instance'
)<LightstreamerClient>();
const setLightstreamerStatus = createAction(
    '@@classroom/lightstreamer-websocket/status'
)<string>();
export const receiveCommand = createAction(
    '@@classroom/lightstreamer-websocket/command'
)<WebsocketMessage>();
export const skipCommand = createAction(
    '@@classroom/lightstreamer-websocket/skip-command'
)();
export const receiveUnknownCommand = createAction(
    '@@classroom/lightstreamer-websocket/command-unknown'
)<WebsocketMessage>();

const setSipSocket = createAction('@@classroom/sip-websocket')<SimpleUser>();

const websocketActions = {
    setLightstreamerSocket,
    setLightstreamerStatus,
    receiveCommand,
    receiveUnknownCommand,
    skipCommand,
    setSipSocket,
};

export default websocketActions;
