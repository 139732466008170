import { of } from 'rxjs';
import { filter, map, catchError, switchMap } from 'rxjs/operators';
import { isActionOf } from 'typesafe-actions';
import { RootEpic } from '../root-epic';
import { Action } from '../../store/root-action';
import { websocketEpics } from './websocket-epics';
import {
    ContractTypes,
    LessonTypes,
    LessonAccessTypes,
    LessonAccessParamTypes,
} from '../../store/contracts/contract-data';

const loadAvailableClasses: RootEpic = (action$, state$, { vcrApi }) =>
    action$.pipe(
        filter(isActionOf(Action.vcr.list.loadAvailableClasses.request)),
        switchMap(({ payload }) => {
            const hasVCRRole =
                state$.value.user.identity?.features.indexOf(
                    'virtual classroom'
                ) !== -1;

            const lessonLimitationRule =
                state$.value.contracts.userContracts.find(
                    (userContract) =>
                        userContract.type === ContractTypes.ConversationClass
                )?.limitationRule;

            let classAccessType = null;
            if (lessonLimitationRule) {
                const getConversationLesson = Object.values(
                    lessonLimitationRule
                ).filter((lesson: any) => {
                    return (
                        lesson.lessonType === LessonTypes.Conversation &&
                        lesson.accessType === LessonAccessTypes.Intra
                    );
                });

                if (hasVCRRole) {
                    if (getConversationLesson.length > 0) {
                        classAccessType = LessonAccessParamTypes.All;
                    } else {
                        classAccessType = LessonAccessParamTypes.Inter;
                    }
                } else {
                    if (getConversationLesson.length > 0) {
                        classAccessType = LessonAccessParamTypes.Intra;
                    }
                }
            }

            const languageUUID =
                state$.value.learnerProfile?.currentLanguage?.languageUUID;

            const learnerUUID = state$.value.user?.identity?.learnerUUID;

            if (!learnerUUID || !languageUUID) return of();

            return vcrApi
                .loadAvailableClasses(
                    languageUUID,
                    learnerUUID,
                    classAccessType as LessonAccessParamTypes,
                    state$.value.grading.list
                )
                .pipe(
                    map((x) => {
                        const newData = x.map((data: any) => {
                            const languageList =
                                state$.value.learnerProfile?.languageList
                                    .valueSeq()
                                    .toArray();
                            const topicLanguage = languageList.find(
                                (y: any) => y.languageUUID === data.topicId
                            );

                            return {
                                ...data,
                                topicCode: topicLanguage?.code,
                                topicName: topicLanguage?.name,
                            };
                        });

                        return Action.vcr.list.loadAvailableClasses.success(
                            newData
                        );
                    }),
                    catchError((e) =>
                        of(Action.vcr.list.loadAvailableClasses.failure(e))
                    )
                );
        })
    );

const joinVCR: RootEpic = (action$, state$, { vcrApi }) =>
    action$.pipe(
        filter(isActionOf(Action.vcr.list.joinVCR.request)),
        switchMap(({ payload }) => {
            const type = payload.type;
            const attendeeType = payload?.attendeeType;
            let availableClass: any = state$.value.vcr.list?.availableClasses;

            const vcrClassId = payload.virtualClassId;
            let findRoom: any =
                availableClass?.find(
                    (y: any) => y.virtualClassId === vcrClassId
                ) || null;

            if (type === 'individual') {
                availableClass =
                    state$.value.scheduler.foreseenVCR?.virtualClasses;

                findRoom =
                    availableClass?.find((y: any) => {
                        return y.virtualClassDto.virtualClassId === vcrClassId;
                    })?.virtualClassDto || null;
            }

            return vcrApi
                .joinVCR({
                    topicId: findRoom?.topicId || '',
                    userId: state$.value.user?.identity?.learnerUUID || '',
                    virtualClassId: vcrClassId,
                    attendeeType: attendeeType || 'Learner',
                })
                .pipe(
                    switchMap((x) => {
                        return of(
                            Action.vcr.classroom.setSelectedRoom(findRoom),
                            Action.vcr.list.joinVCR.success(x)
                        );
                    }),
                    catchError((e) => of(Action.vcr.list.joinVCR.failure(e)))
                );
        })
    );

const validateKickStatus: RootEpic = (action$, state$, { vcrApi }) =>
    action$.pipe(
        filter(
            isActionOf(Action.vcr.classroom.checkKickoutClassStatus.request)
        ),
        switchMap((x) => {
            return vcrApi
                .validateKickStatus({
                    virtualClassId: x.payload.virtualClassId,
                    userId: x.payload.userId,
                })
                .pipe(
                    map((x) =>
                        Action.vcr.classroom.checkKickoutClassStatus.success(x)
                    ),
                    catchError((e) =>
                        of(
                            Action.vcr.classroom.checkKickoutClassStatus.failure(
                                e
                            )
                        )
                    )
                );
        })
    );

const getVCRToken: RootEpic = (action$, state$, { vcrApi }) =>
    action$.pipe(
        filter(isActionOf(Action.vcr.list.getVCRToken.request)),
        switchMap((x) => {
            return vcrApi
                .getVCRToken({
                    chatChannelSid: x.payload.chatExternalRoomSid,
                    groupRoomSid: x.payload.groupExternalRoomSid,
                    userId: state$.value.user?.identity?.learnerUUID || '',
                })
                .pipe(
                    map((x) => {
                        return Action.vcr.list.getVCRToken.success(x);
                    }),
                    catchError((e) =>
                        of(Action.vcr.list.getVCRToken.failure(e))
                    )
                );
        })
    );

const loadActiveClassroom: RootEpic = (action$, state$, { vcrApi }) =>
    action$.pipe(
        filter(isActionOf(Action.vcr.list.loadActiveClassroom.request)),
        switchMap((x) =>
            vcrApi.loadActiveClassroom(x.payload).pipe(
                map((x) => Action.vcr.list.loadActiveClassroom.success(x)),
                catchError((e) =>
                    of(Action.vcr.list.loadActiveClassroom.failure(e))
                )
            )
        )
    );

const loadPersonalClassroom: RootEpic = (action$, state$, { vcrApi }) =>
    action$.pipe(
        filter(isActionOf(Action.vcr.classroom.getPersonalClassroom.request)),
        switchMap((x) => {
            return vcrApi.loadPersonalClassroom(x.payload).pipe(
                map((x) =>
                    Action.vcr.classroom.getPersonalClassroom.success(x)
                ),
                catchError((e) =>
                    of(Action.vcr.classroom.getPersonalClassroom.failure(e))
                )
            );
        })
    );

const loadPersonalClassroomSecurityId: RootEpic = (
    action$,
    state$,
    { vcrApi }
) =>
    action$.pipe(
        filter(
            isActionOf(
                Action.vcr.classroom.getPersonalClassroomSecurity.request
            )
        ),
        switchMap((x) => {
            const { learnerUUID, learnerExternalID } = x.payload;
            return vcrApi
                .loadPersonalClassroomSecurityId(learnerUUID, learnerExternalID)
                .pipe(
                    map((x) =>
                        Action.vcr.classroom.getPersonalClassroomSecurity.success(
                            x
                        )
                    ),
                    catchError((e) =>
                        of(
                            Action.vcr.classroom.getPersonalClassroomSecurity.failure(
                                e
                            )
                        )
                    )
                );
        })
    );

const registerUserToClassroom: RootEpic = (action$, state$, { vcrApi }) =>
    action$.pipe(
        filter(
            isActionOf(Action.vcr.classroom.registerUserToClassroom.request)
        ),
        switchMap((x) =>
            vcrApi
                .registerUserToClassroom(state$.value.user?.identity, x.payload)
                .pipe(
                    map((x) =>
                        Action.vcr.classroom.registerUserToClassroom.success(x)
                    ),
                    catchError((e) =>
                        of(
                            Action.vcr.classroom.registerUserToClassroom.failure(
                                e
                            )
                        )
                    )
                )
        )
    );

const checkActiveClassroom: RootEpic = (action$, state$, { vcrApi }) =>
    action$.pipe(
        filter(isActionOf(Action.vcr.classroom.checkActiveClassroom.request)),
        switchMap(() =>
            vcrApi
                .checkActiveClassroom(
                    state$.value.user?.identity,
                    state$.value.learnerProfile.currentLanguage?.name as string
                )
                .pipe(
                    map((x) =>
                        Action.vcr.classroom.checkActiveClassroom.success(x)
                    ),
                    catchError((e) =>
                        of(Action.vcr.classroom.checkActiveClassroom.failure(e))
                    )
                )
        )
    );

const loadClassroomUsers: RootEpic = (action$, state$, { vcrApi }) =>
    action$.pipe(
        filter(isActionOf(Action.vcr.classroom.chat.loadUsers.request)),
        switchMap((action) =>
            vcrApi.loadClassroomUsers(action.payload).pipe(
                map((x) => Action.vcr.classroom.chat.loadUsers.success(x)),
                catchError((e) =>
                    of(Action.vcr.classroom.chat.loadUsers.failure(e))
                )
            )
        )
    );

const loadClassroomUser: RootEpic = (action$, state$, { vcrApi }) =>
    action$.pipe(
        filter(isActionOf(Action.vcr.classroom.chat.loadUser.request)),
        switchMap((action) =>
            vcrApi
                .loadClassroomUser(state$.value.user.identity, action.payload)
                .pipe(
                    map((x) => Action.vcr.classroom.chat.loadUser.success(x)),
                    catchError((e) =>
                        of(Action.vcr.classroom.chat.loadUser.failure(e))
                    )
                )
        )
    );

const loadCountryCodes: RootEpic = (action$, state$, { vcrApi }) =>
    action$.pipe(
        filter(isActionOf(Action.vcr.classroom.call.getCountryCodes.request)),
        switchMap(() =>
            vcrApi
                .loadCountyCodes(
                    state$.value.user.identity?.learnerExternalId as any
                )
                .pipe(
                    map((x) =>
                        Action.vcr.classroom.call.getCountryCodes.success(x)
                    ),
                    catchError((e) =>
                        of(Action.vcr.classroom.call.getCountryCodes.failure(e))
                    )
                )
        )
    );

const validateNumber: RootEpic = (action$, state$, { vcrApi }) =>
    action$.pipe(
        filter(isActionOf(Action.vcr.classroom.call.validateNumber.request)),
        switchMap(({ payload: { countryId, number } }) =>
            vcrApi.validateNumber(countryId, number).pipe(
                map((x) => Action.vcr.classroom.call.validateNumber.success(x)),
                catchError((e) =>
                    of(Action.vcr.classroom.call.validateNumber.failure(e))
                )
            )
        )
    );

const startCallback: RootEpic = (action$, state$, { vcrApi }) =>
    action$.pipe(
        filter(isActionOf(Action.vcr.classroom.call.startCallback.request)),
        switchMap((x) =>
            vcrApi
                .startCall(
                    { number: x.payload, originator: 'P', callType: '1' },
                    state$.value.user?.identity,
                    state$.value.vcr?.classroom?.activeClassroom?.id
                )
                .pipe(
                    map((x) =>
                        Action.vcr.classroom.call.startCallback.success(x)
                    ),
                    catchError((e) =>
                        of(Action.vcr.classroom.call.startCallback.failure(e))
                    )
                )
        )
    );

const validateInternetCall: RootEpic = (action$, state$, { vcrApi }) =>
    action$.pipe(
        filter(
            isActionOf(Action.vcr.classroom.call.validateInternetCall.request)
        ),
        switchMap((x) =>
            vcrApi
                .validateInternetCall(
                    state$.value.user?.identity,
                    state$.value.vcr?.classroom?.activeClassroom?.id
                )
                .pipe(
                    map((x) =>
                        Action.vcr.classroom.call.validateInternetCall.success(
                            x
                        )
                    ),
                    catchError((e) =>
                        of(
                            Action.vcr.classroom.call.validateInternetCall.failure(
                                e
                            )
                        )
                    )
                )
        )
    );

const startInternetCall: RootEpic = (action$, state$, { vcrApi }) =>
    action$.pipe(
        filter(isActionOf(Action.vcr.classroom.call.startInternetCall.request)),
        switchMap((x) =>
            vcrApi
                .startCall(
                    { number: x.payload, originator: 'M', callType: '2' },
                    state$.value.user?.identity,
                    state$.value.vcr?.classroom?.activeClassroom?.id
                )
                .pipe(
                    map((x) =>
                        Action.vcr.classroom.call.startInternetCall.success(x)
                    ),
                    catchError((e) =>
                        of(
                            Action.vcr.classroom.call.startInternetCall.failure(
                                e
                            )
                        )
                    )
                )
        )
    );

const getJoinedParticipants: RootEpic = (action$, state$, { vcrApi }) =>
    action$.pipe(
        filter(isActionOf(Action.vcr.classroom.getJoinedParticipants.request)),
        switchMap((x) => {
            return vcrApi
                .getJoinedParticipantsByClassId(x.payload.virtualClassId)
                .pipe(
                    map((x) =>
                        Action.vcr.classroom.getJoinedParticipants.success(x)
                    ),
                    catchError((e) =>
                        of(
                            Action.vcr.classroom.getJoinedParticipants.failure(
                                e
                            )
                        )
                    )
                );
        })
    );

const updateJoinedParticipants: RootEpic = (action$, state$, { vcrApi }) =>
    action$.pipe(
        filter(
            isActionOf(
                Action.vcr.classroom.updateJoinedParticipantsByClassId.request
            )
        ),
        switchMap((x) => {
            return vcrApi
                .getJoinedParticipantsByClassId(x.payload.virtualClassId)
                .pipe(
                    map((x) =>
                        Action.vcr.classroom.updateJoinedParticipantsByClassId.success(
                            x
                        )
                    ),
                    catchError((e) =>
                        of(
                            Action.vcr.classroom.updateJoinedParticipantsByClassId.failure(
                                e
                            )
                        )
                    )
                );
        })
    );

export const vcrEpics = [
    loadAvailableClasses,
    joinVCR,
    getVCRToken,
    registerUserToClassroom,
    checkActiveClassroom,
    loadPersonalClassroom,
    loadClassroomUser,
    loadClassroomUsers,
    loadActiveClassroom,
    loadCountryCodes,
    validateNumber,
    startCallback,
    validateInternetCall,
    startInternetCall,
    loadPersonalClassroomSecurityId,
    validateKickStatus,
    getJoinedParticipants,
    updateJoinedParticipants,
    ...websocketEpics,
];
