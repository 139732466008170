import { ResourceDescription } from './resources';
import { createAction, createAsyncAction } from 'typesafe-actions';
import { ResourceContentProps } from './resource-content';
import { ResourceContentType } from './resource-content-type';
import { FilterOption } from '../../components/filters/filters';

export const loadSelectedResource = createAsyncAction(
    '@@resources/load-selected/request',
    '@@resources/load-selected/success',
    '@@resources/load-selected/failure'
)<
    {
        contentType: ResourceContentType;
        resourceId: number;
        groupId: number;
    },
    ResourceDescription,
    Error
>();

export const loadSelectedUsingUUIDResource = createAsyncAction(
    '@@resources/load-selected-using-cuuid/request',
    '@@resources/load-selected-using-cuuid/success',
    '@@resources/load-selected-using-cuuid/failure'
)<string, ResourceDescription, Error>();

export const openResource = createAction('@@resources/open')<{
    contentType: ResourceContentType;
    resourceId: number;
    groupId: number;
}>();

export const openResourceUsingCuuid = createAction(
    '@@resources/open-cuuid'
)<string>();

export const changeResourceContentProgress = createAction(
    '@@resources/switch-tab'
)<{ score: number }>();

export const changeResourceFrom = createAction(
    '@@resources/coming-from'
)<string>();

export const createLoadResourcesAction = <T extends ResourceContentProps>(
    resourceType: string
) =>
    createAsyncAction(
        `@@${resourceType}/request`,
        `@@${resourceType}/success`,
        `@@${resourceType}/failure`
    )<{ levels?: FilterOption[]; categories?: FilterOption[] }, T[], Error>();

export const clearAllResources = createAction('@@resources/clear-all')();

export const setDisplayResource = createAction(
    '@@resources/set-display-resource'
)<ResourceDescription>();

export const resourceActions = {
    loadSelectedResource,
    loadSelectedUsingUUIDResource,
    openResource,
    openResourceUsingCuuid,
    changeResourceContentProgress,
    changeResourceFrom,
    createLoadResourcesAction,
    clearAllResources,
    setDisplayResource,
};
