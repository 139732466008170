import {
    createAsyncAction,
    createReducer,
    RootState,
    createAction,
} from 'typesafe-actions';
import { ResourceContent } from './resource-content';
import { clearAllResources } from './resources-actions';
import { RecommendedLevel } from './recommended-level';
import { combineReducers } from 'redux';
import { createSelector } from 'reselect';
import { modifyResourceStatus } from '../../epics/resources/helpers';
import { loadInProgressActivities } from './in-progress/in-progress-actions';
import { loadCompletedActivities } from './completed/completed-actions';

export const loadRecommendedResources = createAsyncAction(
    '@@resources/recommended/request',
    '@@resources/recommended/success',
    '@@resources/recommended/failure'
)<void, ResourceContent[], Error>();

export const loadRecommendedLevel = createAsyncAction(
    '@@resources/recommended-level/request',
    '@@resources/recommended-level/success',
    '@@resources/recommended-level/failure'
)<void, RecommendedLevel, Error>();

export const removeRecommendedResources = createAsyncAction(
    '@@resources/recommended-hide/request',
    '@@resources/recommended-hide/success',
    '@@resources/recommended-hide/failure'
)<{ reason: string }, ResourceContent[], Error>();

export const selectedRemoveRecommendedResource = createAction(
    '@@resources/recommended-selected-hide'
)<string>();

export const selectedReassessmentCard = createAction(
    '@@resources/recommended-selected-reassessment-card'
)<boolean>();

interface RecommendedState {
    items: ResourceContent[] | null;
    level: RecommendedLevel | null;
    selectedHideItem: string;
    useReassessmentCard: boolean;
}

const initialState: RecommendedState = {
    items: null,
    level: null,
    selectedHideItem: '',
    useReassessmentCard: false,
};

export const items = createReducer(initialState.items)
    .handleAction(
        loadRecommendedResources.success,
        (state, action) => action.payload
    )
    .handleAction(clearAllResources, () => null)
    .handleAction(removeRecommendedResources.success, (state, action) => {
        return action.payload;
    })
    .handleAction(loadInProgressActivities.success, (state, action) => {
        const statuses = action.payload;
        return (state || []).map(
            modifyResourceStatus<ResourceContent>(statuses, null)
        );
    })
    .handleAction(loadCompletedActivities.success, (state, action) => {
        const statuses = action.payload;
        return (state || []).map(
            modifyResourceStatus<ResourceContent>(null, statuses)
        );
    });

export const level = createReducer(initialState.level)
    .handleAction(
        loadRecommendedLevel.success,
        (state, action) => action.payload
    )
    .handleAction(clearAllResources, () => null);

export const selectedHideRecommended = createReducer<string>('').handleAction(
    selectedRemoveRecommendedResource,
    (state, action) => action.payload
);

export const useReassessmentCard = createReducer<boolean>(false).handleAction(
    selectedReassessmentCard,
    (state, action) => action.payload
);

export const recommended = combineReducers({
    items,
    level,
    selectedHideItem: selectedHideRecommended,
    useReassessmentCard,
});

const recommendedSelector = (state: RootState) =>
    state.resources.recommended as RecommendedState;

export const recommendedItemsSelector = createSelector(
    recommendedSelector,
    (state) => state.items
);

export const recommendedLevelSelector = createSelector(
    recommendedSelector,
    (state) => state.level
);

export const recommendedHideItemSelector = createSelector(
    recommendedSelector,
    (state) => state.selectedHideItem
);

export const recommendedReassessmentUseSelector = createSelector(
    recommendedSelector,
    (state) => state.useReassessmentCard
);
