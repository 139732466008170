import { LogApi } from '../services/logging-api';
import { empty } from 'rxjs';
import { map, catchError } from 'rxjs/operators';

export class GenericQueue<QueueType> {
    private readonly QUEUE: string;
    private remoteServerTimeDifference = 0;
    private retryTimeoutId: NodeJS.Timeout | undefined;
    private retryTimeoutStartInterval: number;
    private retryTimeoutMaximumInterval: number;
    private currentRetryTimeoutInterval: number;

    api: LogApi<QueueType>;

    constructor(
        api: LogApi<QueueType>,
        queueName: string,
        retryTimeoutStartInterval = 5,
        retryTimeoutMaximumInterval = 3 * 60
    ) {
        this.QUEUE = 'Queue_' + queueName;
        this.api = api;
        this.retryTimeoutId = undefined;
        this.retryTimeoutStartInterval = retryTimeoutStartInterval;
        this.retryTimeoutMaximumInterval = retryTimeoutMaximumInterval;
        this.currentRetryTimeoutInterval = retryTimeoutStartInterval;

        let logQueue = this.getLogQueue();

        if (logQueue.length > 0) {
            this.sendLogQueue();
        }
    }

    send(messageObject: QueueType, addTimestamp: boolean = false): void {
        let updatedMessageObject: any = {
            ...messageObject,
        };

        if (addTimestamp) {
            updatedMessageObject.timestamp =
                Date.now() + this.remoteServerTimeDifference;
        }

        let logQueue = this.getLogQueue();

        if (logQueue.length > 0) {
            this.queueLogMessage(updatedMessageObject);
        } else {
            this.sendToRemoteServer(updatedMessageObject);
        }
    }

    sendToRemoteServer(messageObject: QueueType) {
        if (this.retryTimeoutId) {
            clearTimeout(this.retryTimeoutId);
        }

        this.api
            .saveEntry(messageObject)
            .pipe(
                catchError((error) => {
                    this.queueLogMessage(messageObject);
                    this.setLogQueueRetryTimer();
                    return empty();
                })
            )
            .subscribe();
    }

    setLogQueueRetryTimer(): void {
        this.updateCurrentRetryTimeoutInterval();
        this.retryTimeoutId = setTimeout(
            () => this.sendLogQueue(),
            this.currentRetryTimeoutInterval * 1000
        );
    }

    resetCurrentRetryTimeoutInterval(): void {
        this.currentRetryTimeoutInterval = this.retryTimeoutStartInterval;
    }

    updateCurrentRetryTimeoutInterval(): void {
        this.currentRetryTimeoutInterval =
            this.currentRetryTimeoutInterval * 1.3;
        if (
            this.currentRetryTimeoutInterval > this.retryTimeoutMaximumInterval
        ) {
            this.currentRetryTimeoutInterval = this.retryTimeoutMaximumInterval;
        }
    }

    sendLogQueue(): void {
        let logQueue = this.getLogQueue();

        /**
         * Dont send empty queue
         */
        if (logQueue.length === 0) return;

        this.api
            .saveEntries(logQueue)
            .pipe(
                map((response) => {
                    if (response.status === 202) {
                        this.emptyLogQueue();
                    } else {
                        this.emptyLogQueue();
                        this.setLogQueueRetryTimer();
                    }
                }),
                catchError((error) => {
                    this.setLogQueueRetryTimer();
                    return empty();
                })
            )
            .subscribe();
    }

    getLogQueue(): QueueType[] {
        let logQueueString = localStorage.getItem(this.QUEUE);
        if (logQueueString == null) logQueueString = '[]';

        return JSON.parse(logQueueString) as QueueType[];
    }

    emptyLogQueue(): void {
        localStorage.setItem(this.QUEUE, '[]');
        this.resetCurrentRetryTimeoutInterval();
    }

    queueLogMessage(messageObject: QueueType): void {
        let logQueue = this.getLogQueue();

        logQueue.push(messageObject);

        localStorage.setItem(this.QUEUE, JSON.stringify(logQueue));
    }

    getRemoteServerTimeDifference(): Number {
        return 0;
    }
}
