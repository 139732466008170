import { SurveyTrainer } from '../types';

interface IUpdateTitleInSurveysLoc {
    title: string;
    trainer?: SurveyTrainer;
}

export const updateTitleInSurveysLoc = ({
    title,
    trainer,
}: IUpdateTitleInSurveysLoc) => {
    if (!trainer) return title.replace(`{trainer_name}`, '');
    const trainerFullName = `${trainer.firstname} ${trainer.lastname}`;
    return title.replace(`{trainer_name}`, trainerFullName);
};
