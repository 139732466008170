import { List, Record } from 'immutable';
import { Section } from './section';

interface HowtoContentProps {
    instructionsPost: string;
    trainerNotes: string;
    sections: List<Section>;
    titleSection: Section | null;
    summarySection: Section | null;
    bgImage: string | null;
    mediumImage: string;
    medium_image: string;
}

const howtoContentDefaultProps: HowtoContentProps = {
    instructionsPost: '',
    trainerNotes: '',
    sections: List(),
    titleSection: null,
    summarySection: null,
    bgImage: null,
    mediumImage: '',
    medium_image: '',
};

export class HowtoContent
    extends Record(howtoContentDefaultProps)
    implements HowtoContentProps
{
    findSection(instanceId: string) {
        return this.sections.find((x) => x.instanceId === instanceId);
    }
}
