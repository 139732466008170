import { createReducer, ActionType } from 'typesafe-actions';
import { combineReducers } from 'redux';
import { alertActions } from './alert-actions';
import { Alert } from './alert-data';

export type AlertAction = ActionType<typeof alertActions>;

export interface AlertState {
    currentAlert: Alert;
}

const initialState: AlertState = {
    currentAlert: new Alert(),
};

const currentAlert = createReducer(initialState.currentAlert)
    .handleAction(alertActions.setAlert, (_, action) => action.payload)
    .handleAction(alertActions.clearAlert, () => initialState.currentAlert);

export const alertReducer = combineReducers<AlertState, AlertAction>({
    currentAlert,
});
