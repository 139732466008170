import { ApiHeaders } from '../../services/api-headers';
import { ajax, AjaxResponse } from 'rxjs/ajax';
import { map } from 'rxjs/operators';
import { tracking } from '../../services/api-urls';
import { Observable } from 'rxjs';
import { LogApi } from '../../services/logging-api';
import { BasePortalEvent } from './models/base-portal-event';

export class TrackingApi implements LogApi<BasePortalEvent> {
    private readonly apiHeaders: ApiHeaders;

    constructor(apiHeaders: ApiHeaders) {
        this.apiHeaders = apiHeaders;
    }

    saveEntry(entry: BasePortalEvent): Observable<AjaxResponse<any>> {
        return ajax
            .post(
                tracking() + 'event',
                entry,
                this.apiHeaders.getHeaders({
                    'Content-Type': 'application/json',
                })
            )
            .pipe(map((response) => response));
    }

    saveEntries(entries: BasePortalEvent[]): Observable<AjaxResponse<any>> {
        return ajax
            .post(
                tracking() + 'events',
                entries,
                this.apiHeaders.getHeaders({
                    'Content-Type': 'application/json',
                })
            )
            .pipe(map((response) => response));
    }
}
