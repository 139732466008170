import { createAsyncAction } from 'typesafe-actions';
import { CompletedResource } from './completed-record';

export const loadCompletedActivities = createAsyncAction(
    '@@resources/completed/request',
    '@@resources/completed/success',
    '@@resources/completed/failure'
)<undefined, CompletedResource[] | null, Error>();

export const resetCompletedActivities = createAsyncAction(
    '@@resources/completed-reset/request',
    '@@resources/completed-reset/success',
    '@@resources/completed-reset/failure'
)<undefined, undefined, Error>();

export const completedActions = { loadCompletedActivities };
