import { Record, Map } from 'immutable';
import moment, { Moment } from 'moment';

enum VCRAccessType {
    All = 'All',
    AssignedLearner = 'AssignedLearner',
    Organization = 'Organization',
}

enum VCRClassStatus {
    Cancel = 'Cancel',
    Fail = 'Fail',
    Finish = 'Finish',
    Normal = 'Normal',
    Reserved = 'Reserved',
}

enum VCRDataSource {
    Lms = 'Lms',
    VirtualClass = 'VirtualClass',
}

export enum VCRStatus {
    ALREADY = 'ALREADY',
    CONSUMED = 'CONSUMED',
    FULL = 'FULL',
    NO = 'NO',
    OK = 'OK',
    PROCESSING = 'PROCESSING',
    GONE = 'GONE',
}

export enum KickOutStatus {
    kickedOut = 'KickedOut',
    noKickOut = 'NoKickOut',
}

// enum VCRProviderStatus {
//     Active = 'Active',
//     Deleted = 'Deleted',
//     Disabled = 'Disabled',
// }

export enum VCRProviderType {
    GoFluent = 'GoFluent',
    Teams = 'Teams',
    Twilio = 'Twilio',
}

enum VCRServiceType {
    ChatRoom = 'ChatRoom',
    GroupRoom = 'GroupRoom',
}

// interface AllocatedVirtualRoom {
//     externalChatSid: string;
//     externalRoomSid: string;
//     id: string;
//     participantNumber: number;
//     providerStatus: VCRProviderStatus;
//     providerType: VCRProviderType;
//     serviceType: VCRServiceType;
// }

interface TokenDto {
    accessToken: string;
    serviceType: VCRServiceType;
}

export interface VirtualClassDto {
    accessType: VCRAccessType;
    classStatus: VCRClassStatus;
    dataSource: VCRDataSource;
    description: string;
    durationInMinutes: string;
    endTime: string;
    gradingIds: string[];
    gradingList: Array<{ id: string; name: string }>;
    maxLearners: number;
    name: string;
    resourceIds: string[];
    startTime: string;
    status: string;
    tags: string[];
    topicId: string;
    tutorRequired: boolean;
    virtualClassId: string;
    topicCode: string;
    topicName: string;
    joined?: boolean;
    attendeeType?: string;
    maxSpeakerParticipants: number;
    maxListenerParticipants: number;
    maxParticipants: number;
}

export interface VirtualClassAttendDto {
    attended: boolean;
    attendeeType: string;
    virtualClassDto: VirtualClassDto;
}

export interface VirtualClasses {
    attended: boolean;
    virtualClassDto: VirtualClassDto[];
}

export interface UpcomingClassDto {
    topicId: string;
    virtualClasses: VirtualClasses;
}

export interface AskJoinUserReqDto {
    topicId: string;
    userId: string;
    virtualClassId: string;
    attendeeType: string;
}

export interface AllocatedVirtualClassDto {
    allocatedVirtualRooms: any;
    participantNumber: number;
    status: VCRStatus;
    userId: string;
    virtualClassId: string;
}

export interface AccessTokenResDto {
    identity: string;
    tokens: TokenDto[];
}

export interface VcrClassProps {
    id: number;
    type: number;
    startDateTime: string;
    length: number;
    level: string;
    title: string;
    language: string;
    levelDescription: string;
    status: string;
    statusDescription: string;
}

const defaultVcrClassProps: VcrClassProps = {
    id: 0,
    startDateTime: '',
    title: '',
    level: '',
    language: '',
    type: 0,
    length: 0,
    levelDescription: '',
    status: '',
    statusDescription: '',
};

export class VcrClassData
    extends Record(defaultVcrClassProps)
    implements VcrClassProps {}

export interface ClassroomListDataProps {
    upcoming: Map<string, VcrClassData>;
    active: Map<string, VcrClassData>;
}

const defaultVcrDataProps: ClassroomListDataProps = {
    upcoming: Map<string, VcrClassData>(),
    active: Map<string, VcrClassData>(),
};

export class ClassroomListData
    extends Record(defaultVcrDataProps)
    implements ClassroomListDataProps {}

export interface joinedParticipantsRecordProps {
    attendedTime: Moment;
    type: string;
    userId: string;
    virtualClassId: string;
}

const joinedParticipantsRecordDefault: joinedParticipantsRecordProps = {
    attendedTime: moment().startOf('day'),
    type: '',
    userId: '',
    virtualClassId: '',
};

export class JoinedParticipantsRecord
    extends Record(joinedParticipantsRecordDefault)
    implements joinedParticipantsRecordProps {}
