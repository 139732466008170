import { RootEpic } from './root-epic';
import {
    catchError,
    filter,
    map,
    switchMap,
    distinctUntilChanged,
} from 'rxjs/operators';
import { isActionOf } from 'typesafe-actions';
import { Action } from '../store/root-action';
import { of } from 'rxjs';

const loadUserContractsEpic: RootEpic = (action$, _, { contractApi }) =>
    action$.pipe(
        filter(isActionOf(Action.contracts.loadUserContracts.request)),
        switchMap((x) =>
            contractApi.getUserContractTerm(x.payload.userUUID).pipe(
                map((userContracts) => {
                    return Action.contracts.loadUserContracts.success(
                        userContracts
                    );
                }),
                catchError((x) =>
                    of(Action.contracts.loadUserContracts.failure(x))
                )
            )
        )
    );

const preloadContractService: RootEpic = (action$, state$, { contractApi }) =>
    state$.pipe(
        map((x) => x.user.identity),
        filter(
            (identity) =>
                !!(identity && identity.learnerUUID) && navigator.onLine
        ),
        distinctUntilChanged(),
        switchMap((x) => {
            return of(
                Action.contracts.loadUserContracts.request({
                    userUUID: x!.learnerUUID,
                })
            );
        })
    );

export const contractEpics = [loadUserContractsEpic, preloadContractService];
