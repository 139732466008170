import { createAsyncAction, createAction } from 'typesafe-actions';
import {
    TrainingPath,
    CourseCategory,
    CourseStatusDetails,
} from './training-path-record';
import { List } from 'immutable';
import { ResourceContent, ResourceContentProps } from '../resource-content';
import { clearAllResources } from '../resources-actions';
import { TrainingPathDataLegacyIds } from './training-path-data';
import { AssignmentDTOInterface } from '../../../services/training-path-api';

export const loadTrainingPath = createAsyncAction(
    '@@training-path/request',
    '@@training-path/success',
    '@@training-path/failure'
)<void, TrainingPath[], Error>();

export const loadAdditionalTrainingPath = createAsyncAction(
    '@@training-path-additional/request',
    '@@training-path-additional/success',
    '@@training-path-additional/failure'
)<void, TrainingPath[], Error>();

export const loadAdditionalTrainingPathContentTotal = createAsyncAction(
    '@@training-path-additional-content-total/request',
    '@@training-path-additional-content-total/success',
    '@@training-path-additional-content-total/failure'
)<AssignmentDTOInterface[], TrainingPath[], Error>();

export const loadActivitiesTrainingPath = createAsyncAction(
    '@@training-path/activities/request',
    '@@training-path/activities/success',
    '@@training-path/activities/failure'
)<{ activityId: string }, ResourceContent[], Error>();

export const clearActivitiesTrainingPath = createAction(
    '@@training-path/clear-activities-tp'
)<void>();

export const loadCourseCategories = createAsyncAction(
    '@@course-categories/request',
    '@@course-categories/success',
    '@@course-categories/failure'
)<void, List<CourseCategory>, Error>();

export const loadCourses = createAsyncAction(
    '@@courses/request',
    '@@courses/success',
    '@@courses/failure'
)<{ courseTags: List<number> }, TrainingPath[], Error>();

export const loadAllCourses = createAsyncAction(
    '@@courses/all/request',
    '@@courses/all/success',
    '@@courses/all/failure'
)<{ courseTags: string[] }, TrainingPath[], Error>();

export const loadCurrentlyViewedCourse = createAsyncAction(
    '@@courses/currently-viewed/request',
    '@@courses/currently-viewed/success',
    '@@courses/currently-viewed/failure'
)<{ activityId: string }, ResourceContentProps, Error>();

export const loadCoursesStatuses = createAsyncAction(
    '@@courses/statuses/request',
    '@@courses/statuses/success',
    '@@courses/statuses/failure'
)<undefined, CourseStatusDetails[], Error>();

export const resetCoursesStatuses = createAsyncAction(
    '@@courses/statuses-reset/request',
    '@@courses/statuses-reset/success',
    '@@courses/statuses-reset/failure'
)<undefined, undefined, Error>();

export const loadLegacyCourseId = createAsyncAction(
    '@@courses/legacy-id/request',
    '@@courses/legacy-id/success',
    '@@courses/legacy-id/failure'
)<{ trainingPathId: string }, TrainingPathDataLegacyIds, Error>();

export const clearCourses = createAction('@@courses/clear-courses')<void>();

export const trainingPathActions = {
    loadTrainingPath,
    loadAdditionalTrainingPath,
    loadAdditionalTrainingPathContentTotal,
    loadActivitiesTrainingPath,
    clearActivitiesTrainingPath,
    loadCourseCategories,
    loadCourses,
    loadAllCourses,
    loadCurrentlyViewedCourse,
    clearCourses,
    clearAllResources,
    loadLegacyCourseId,
    loadCoursesStatuses,
    resetCoursesStatuses,
};
