import { SurveyOptionsResponse } from './types';

// Take a not that values like BadAttitude, NST, etc. are labels for localization

// Individual lesson backup options
export const ILOptions: SurveyOptionsResponse = {
    '1.0': [
        'BadAttitude',
        'LackFeedback',
        'LackInteraction',
        'BadTopic',
        'BadHomework',
        'TechIssue',
        'NST',
    ],
    '2.0': [
        'BadAttitude',
        'LackFeedback',
        'LackInteraction',
        'BadTopic',
        'BadHomework',
        'TechIssue',
    ],
    '3.0': [
        'BadAttitude',
        'LackFeedback',
        'LackInteraction',
        'BadTopic',
        'BadHomework',
        'TechIssue',
    ],
    '4.0': [
        'MoreFeedbackCorrection',
        'MoreRelevantHomework',
        'MoreInterestingTopic',
        'MoreSpeakingTime',
    ],
    '5.0': [
        'GoodAttitude',
        'Motivating',
        'GoodFeedback',
        'GoodCorrection',
        'GoodTopic',
        'GoodHomework',
    ],
};

// Conversation class backup options
export const CCOptions: SurveyOptionsResponse = {
    '1.0': [
        'TrainerNotMotivating',
        'TopicNotInteresting',
        'DisruptiveParticipants',
        'WrongLevel',
        'TooManyPeople',
        'TrainerModeration',
    ],
    '2.0': [
        'TrainerNotMotivating',
        'TopicNotInteresting',
        'DisruptiveParticipants',
        'WrongLevel',
        'TooManyPeople',
        'TrainerModeration',
    ],
    '3.0': [
        'TrainerNotMotivating',
        'TopicNotInteresting',
        'DisruptiveParticipants',
        'WrongLevel',
        'TooManyPeople',
        'TrainerModeration',
    ],
    '4.0': [
        'TrainerNotMotivating',
        'TopicNotInteresting',
        'WrongLevel',
        'TrainerModeration',
        'NotEnoughGrammar',
    ],
    '5.0': ['GreatTrainer', 'InterestingTopic', 'ClassUseful', 'LessonDynamic'],
};

// Group lesson backup options
export const GLOptions: SurveyOptionsResponse = {
    '1.0': [
        'TrainerNotMotivating',
        'ContentNotUseful',
        'TimeToSpeak',
        'LackFeedback',
        'UnclearGoals',
        'GroupLevel',
    ],
    '2.0': [
        'TrainerNotMotivating',
        'ContentNotUseful',
        'TimeToSpeak',
        'LackFeedback',
        'UnclearGoals',
        'GroupLevel',
    ],
    '3.0': [
        'TrainerNotMotivating',
        'ContentNotUseful',
        'TimeToSpeak',
        'LackFeedback',
        'UnclearGoals',
        'GroupLevel',
    ],
    '4.0': [
        'TrainerNotMotivating',
        'ContentNotUseful',
        'TimeToSpeak',
        'LackFeedback',
        'UnclearGoals',
        'GroupLevel',
    ],
    '5.0': ['InterestingTopics', 'UsefulLessons', 'GreatTrainer', 'GoodLesson'],
};
