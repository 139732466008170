import { ApiHeaders } from './api-headers';
import { Observable } from 'rxjs';
import { Axios } from 'axios-observable';
import { apiv1 } from './api-urls';
import { map } from 'rxjs/operators';
import { DisplayHandleType } from '../store/display-strategy/display-handle';
import { DisplayStrategyRecord } from '../store/display-strategy/display-strategy';

export class DisplayStrategyApi {
    constructor(private readonly apiHeaders: ApiHeaders) {}

    loadDisplayStrategy({
        displayHandle,
        userUUID,
    }: {
        displayHandle: DisplayHandleType;
        userUUID: string;
    }): Observable<DisplayStrategyRecord> {
        return Axios.get<DisplayStrategyRecord>(
            `${apiv1()}display-strategy/handle/${displayHandle}/user/${userUUID}`,
            { headers: this.apiHeaders.getHeaders() }
        ).pipe(map((x) => x.data));
    }
}
