import { PerformanceSurveyStepQuestions } from '../types';

export const collectAnswers = (
    questions: PerformanceSurveyStepQuestions[],
    onlyRequired: boolean = true
) => {
    return questions.reduce(
        (acc: PerformanceSurveyStepQuestions[], question) => {
            if (question.childs) {
                for (const child of question.childs) {
                    if (onlyRequired && child.questionType === 'Score') {
                        if (child) acc.push(child);
                    } else if (!onlyRequired) {
                        if (child) acc.push(child);
                    }
                }
            } else {
                if (question.questionType === 'Score' && onlyRequired) {
                    acc.push(question);
                } else if (!onlyRequired) {
                    acc.push(question);
                }
            }
            return acc;
        },
        []
    );
};
