import { ApiHeaders } from './api-headers';
import { Observable } from 'rxjs';
import { Axios } from 'axios-observable';
import { activityReport } from './api-urls';
import { map } from 'rxjs/operators';
import { ChartView, InsightReportDTO } from '../store/insights/insight-report';

export class InsightsApi {
    apiHeaders: ApiHeaders;

    constructor(apiHeaders: ApiHeaders) {
        this.apiHeaders = apiHeaders;
    }

    loadCompletedActivity(
        userUUID: string,
        topicUUID?: string,
        granularity?: ChartView
    ): Observable<any> {
        let successCriteria = 50;
        let activityUrl =
            activityReport() + `insight/${userUUID}/${successCriteria}`;

        if (topicUUID) {
            activityUrl += `?topic=${topicUUID}`;
        }

        if (granularity) {
            activityUrl += `${topicUUID ? '&' : '?'}granularity=${granularity}`;
        }

        return Axios.get<InsightReportDTO[]>(activityUrl, {
            headers: this.apiHeaders.getHeaders({
                'Content-Type': 'application/json',
            }),
        }).pipe(
            map((x) =>
                x.data.map((item) => {
                    return {
                        ...item,
                        activityCompleted: item.activityCompleted || 0,
                        activityPassed: item.activityPassed || 0,
                        courseCompleted: item.courseCompleted || 0,
                        coursePassed: item.coursePassed || 0,
                        lessonCompleted: item.lessonCompleted || 0,
                        vcrCompleted: item.vcrCompleted || 0,
                        timeSpentSec: item.timeSpentSec || 0,
                        writingsCompleted: item.writingsCompleted || 0,
                    };
                })
            )
        );
    }
}
