import { List } from 'immutable';
import { from, lastValueFrom, Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { Axios } from 'axios-observable';
import { ApiHeaders } from './api-headers';
import {
    channelReservation,
    classroom,
    telephone,
    virtualClasses,
} from './api-urls';
import {
    ActiveClassroomData,
    ActiveClassroomSecurityData,
    ActiveClassroomSecurityIdProps,
} from '../store/vcr/classroom/classroom-data';
import { UserRecord } from '../store/user/user-record';
import { ChatUser } from '../store/vcr/classroom/chat/chat-data';
import {
    AccessTokenResDto,
    AllocatedVirtualClassDto,
    AskJoinUserReqDto,
    VcrClassProps,
    VCRProviderType,
    VirtualClassDto,
    UpcomingClassDto,
    VirtualClasses,
    JoinedParticipantsRecord,
    joinedParticipantsRecordProps,
} from '../store/vcr/list/classroom-list-data';
import {
    CountryCodeData,
    CountryCodeDto,
    InternetCallData,
    InternetCallProps,
    NumberValidationDto,
} from '../store/vcr/classroom/call/call-data';

import { LessonAccessParamTypes } from '../store/contracts/contract-data';

export interface IVcrApi {
    loadAvailableClasses(
        topicId: string,
        userId: string,
        accessType: LessonAccessParamTypes | null,
        gradingList: any
    ): Observable<VirtualClassDto[]>;
}

export class VcrApi implements IVcrApi {
    private readonly apiHeaders: ApiHeaders;

    constructor(apiHeaders: ApiHeaders) {
        this.apiHeaders = apiHeaders;
    }

    public loadAvailableClasses(
        topicId: string,
        userId: string,
        accessType: LessonAccessParamTypes | null,
        gradingList: any
    ): Observable<VirtualClassDto[]> {
		alert("[STANLLEY] entered loadAvailableClasses: ",topicId, " ", userId, " ", accessType, " ", gradingList);
		console.log("[STANLLEY] entered loadAvailableClasses: ",topicId, " ", userId, " ", accessType, " ", gradingList);
        return from(
            this.loadAvailableClassWithGrading(
                topicId,
                userId,
                accessType,
                gradingList
            )
        );
    }

    loadAvailableClassesAPI(
        topicId: string,
        userId: string,
        accessType: LessonAccessParamTypes | null
    ): Observable<VirtualClasses> {
        let requestParam = {};

        if (accessType) {
            requestParam = {
                dataAccessType: accessType,
            };
        }

        return Axios.get<UpcomingClassDto>(
            `${virtualClasses()}${topicId}/upcoming-class/${userId}`,
            {
                headers: this.apiHeaders.getHeaders({}, true),
                params: {
                    ...requestParam,
                },
            }
        ).pipe(
            map((x) => {
                const { virtualClasses } = x.data;
                return virtualClasses;
            })
        );
    }

    private async loadAvailableClassWithGrading(
        topicId: string,
        userId: string,
        accessType: LessonAccessParamTypes | null,
        gradings: any
    ): Promise<VirtualClassDto[]> {
        let list = await lastValueFrom(
            this.loadAvailableClassesAPI(topicId, userId, accessType)
        );

        const listMap = Object.entries(list).map((x) => {
            const newData = {
                ...x[1].virtualClassDto,
                joined: x[1].attended,
                attendeeType: x[1].attendeeType,
            };
            return newData;
        });

        const newItem: VirtualClassDto[] = listMap.map(
            (ewr: any, index: number) => {
                const gradingList = ewr.gradingIds;
                const gradingListItem = gradingList.map((j: any) => {
                    const k = gradings.find((t: any) => t.id === j);
                    if (k) {
                        return k;
                    }
                    return null;
                });

                return {
                    ...ewr,
                    gradingList: gradingListItem,
                };
            }
        );

        return lastValueFrom(of(newItem));
    }

    validateKickStatus(params: { virtualClassId: string; userId: string }) {
        return Axios.get<any>(
            `${virtualClasses()}kick-out/${params.virtualClassId}/${
                params.userId
            }`,
            {
                headers: this.apiHeaders.getHeaders({}, true),
            }
        ).pipe(map((x) => x.data));
    }

    joinVCR(body: AskJoinUserReqDto) {
        return Axios.post<AllocatedVirtualClassDto>(
            `${virtualClasses()}join`,
            body,
            {
                headers: this.apiHeaders.getHeaders({}, true),
            }
        ).pipe(map((x) => x.data));
    }

    getForeseenClass(params: {
        learnerUUID: string;
        lessonExternalID: string;
    }) {
        return Axios.get<any>(
            `${virtualClasses()}individual-class/upcoming-class/${
                params.learnerUUID
            }/${params.lessonExternalID}`,
            {
                headers: this.apiHeaders.getHeaders({}, true),
            }
        ).pipe(map((x) => x.data));
    }

    getVCRToken(params: {
        chatChannelSid: string;
        groupRoomSid: string;
        userId: string;
    }) {
        const providerType: VCRProviderType = VCRProviderType.Twilio;

        return Axios.get<AccessTokenResDto>(`${virtualClasses()}token/`, {
            params: { ...params, providerType },
            headers: this.apiHeaders.getHeaders({}, true),
        }).pipe(map((x) => x.data));
    }

    loadActiveClassroom(classroomId: number): Observable<VcrClassProps> {
        return Axios.get<VcrClassProps>(
            `${classroom()}ws/classes/classid/${classroomId}`,
            { headers: this.apiHeaders.getHeaders({}, true) }
        ).pipe(map((x) => x.data));
    }

    loadPersonalClassroom(
        classroomId: string
    ): Observable<ActiveClassroomData> {
        return Axios.get<ActiveClassroomData>(
            `${classroom()}ws/classes/externalid/${classroomId}`,
            { headers: this.apiHeaders.getHeaders({}, true) }
        ).pipe(map((x) => new ActiveClassroomData(x.data)));
    }

    getJoinedParticipantsByClassId(
        virtualClassId: string
    ): Observable<List<JoinedParticipantsRecord>> {
        return Axios.get<List<JoinedParticipantsRecord>>(
            `${virtualClasses()}participant/${virtualClassId}`,
            { headers: this.apiHeaders.getHeaders({}, true) }
        ).pipe(
            map((x) => {
                const ParticipantsList = x.data.map(
                    (item: joinedParticipantsRecordProps) => {
                        return new JoinedParticipantsRecord(item);
                    }
                );

                return ParticipantsList;
            })
        );
    }

    loadPersonalClassroomSecurityId(
        learnerUUID: string,
        learnerExternalID: number
    ): Observable<ActiveClassroomSecurityData> {
        return Axios.get<ActiveClassroomSecurityData>(
            `${channelReservation()}/learner/${learnerUUID}`,
            { headers: this.apiHeaders.getHeaders({}, true) }
        ).pipe(
            map((x) => {
                const selectedLessonReservationDetail = x.data.find(
                    (item: ActiveClassroomSecurityIdProps) =>
                        item.lessonExternalId === learnerExternalID
                );

                return new ActiveClassroomSecurityData(
                    selectedLessonReservationDetail
                );
            })
        );
    }

    registerUserToClassroom(
        user: UserRecord | null,
        classroomData: {
            topicLanguage: string;
        }
    ): Observable<ActiveClassroomData> {
        const { topicLanguage } = classroomData;
        let aes = '',
            nationality = '',
            ipAddress = '127.0.0.1';

        if (user) {
            aes = user.aes;
            nationality = user.nationality;
            ipAddress = user.timespent.ipAddress;
        }

        return Axios.get<ActiveClassroomData>(
            `${classroom()}ws/classes/register/${aes}/${nationality}/${ipAddress}/${topicLanguage}`,
            { headers: this.apiHeaders.getHeaders({}, true) }
        ).pipe(map((x) => new ActiveClassroomData(x.data)));
    }

    checkActiveClassroom(
        user: UserRecord | null,
        language: string | null
    ): Observable<ActiveClassroomData> {
        let aes = '';

        if (user) {
            aes = user.aes;
        }

        return Axios.get<ActiveClassroomData>(
            `${classroom()}ws/classes/checkuser/${aes}/${language?.toUpperCase()}`,
            { headers: this.apiHeaders.getHeaders({}, true) }
        ).pipe(map((x) => new ActiveClassroomData(x.data)));
    }

    loadClassroomUser(
        user: UserRecord | null,
        classroomId: number = 0
    ): Observable<ChatUser> {
        let aes = '';

        if (user) {
            aes = user.aes;
        }

        return Axios.get<ChatUser>(
            `${classroom()}ws/users/auth/${aes}/${classroomId}`,
            { headers: this.apiHeaders.getHeaders() }
        ).pipe(map((x) => new ChatUser(x.data)));
    }

    loadClassroomUsers(classroomId: number = 0) {
        return Axios.get<ChatUser[]>(
            `${classroom()}ws/classes/attendees2/${classroomId}`,
            { headers: this.apiHeaders.getHeaders({}, true) }
        ).pipe(map((x) => x.data.map((x) => new ChatUser(x))));
    }

    loadCountyCodes(userId: string = ''): Observable<List<CountryCodeData>> {
        return Axios.get<CountryCodeDto>(
            telephone() +
                `DialingCodes/Learner/GetAuthorizedDialingCodeFromCompany/${userId}`
        ).pipe(
            map((x) =>
                List(x.data.CountryCodes.map((x) => new CountryCodeData(x)))
            )
        );
    }

    validateNumber(countryId: number, number: string): Observable<boolean> {
        return Axios.get<NumberValidationDto>(
            telephone() +
                `DialingCodes/Learner/IsValidPhoneNumber/${countryId}/${number}`
        ).pipe(map((x) => x.data.IsValid));
    }

    startCall(
        {
            number,
            originator,
            callType,
        }: { number?: string; callType: '1' | '2'; originator: 'P' | 'M' },
        user: UserRecord | null,
        classId?: number
    ): Observable<any> {
        let securityId = '',
            country = '',
            email = '',
            ip = '',
            phone = '';

        if (user) {
            securityId = callType === '1' ? user.securityId : user.aes;
            country = user.nationality;
            email = user.emailAddress;
            ip = user.timespent.ipAddress;
        }

        if (number) phone = number;

        return Axios.get(`${classroom()}ws/classes/initiateCall`, {
            headers: this.apiHeaders.getHeaders({
                callType,
                originator,
                learnerIp: ip,
                securityId: securityId,
                country: country,
                learnerEmail: email,
                classId: `${classId}`,
                number: phone,
                extension: phone,
            }),
        }).pipe(map((x) => x.data));
    }

    validateInternetCall(
        user: UserRecord | null,
        classId?: number
    ): Observable<InternetCallData> {
        let securityId = '',
            country = '';

        if (user) {
            securityId = user.aes;
            country = user.nationality;
        }

        return Axios.get<InternetCallProps>(
            `${classroom()}ws/classes/validateInternetCall`,
            {
                headers: this.apiHeaders.getHeaders({
                    originator: 'M',
                    securityId: securityId,
                    country: country,
                    classId: `${classId}`,
                }),
            }
        ).pipe(map((x) => new InternetCallData(x.data)));
    }
}
