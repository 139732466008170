export enum KEY_CODES {
    TAB = 9,
    ENTER = 13,
    ESCAPE = 27,
    SPACE = 32,
    LEFT_ARROW = 37,
    UP_ARROW = 38,
    RIGHT_ARROW = 39,
    DOWN_ARROW = 40,
}

export enum THEMES {
    LIGHT = '#ffffff',
    SEMI_LIGHT = '#f2f4f4',
    DARK = '#404040',
    NATIVE_COLOR = '#16aae7',
}
