import { RootState } from 'typesafe-actions';
import { createSelector } from 'reselect';

export const userSelector = (state: RootState) => state.user.identity;

export const isAuthenticated = (state: RootState) =>
    !!state.user.identity?.authenticationToken &&
    !state.user.identity?.shouldResetPassword;

export const isLoadingDisplayLanguageOnLogin = (state: RootState) =>
    !!state.user.isLoadingDisplayLanguageOnLogin;

export const loginError = (state: RootState) => state.user.loginError;

export const resetPasswordError = (state: RootState) =>
    state.user.resetPasswordError;

export const resetPasswordSuccess = (state: RootState) =>
    state.user.resetPasswordSuccess;

export const isLoggingIn = (state: RootState) => state.user.isLoggingIn;

export const shouldResetPassword = (state: RootState) =>
    state.user.identity?.shouldResetPassword;

export const emailAddressUserSelector = (state: RootState) =>
    state.user.identity?.emailAddress;

export const timeZoneUserSelector = (state: RootState) =>
    state.user.identity?.timeZoneId;

export const newPasswordError = (state: RootState) =>
    state.user.newPasswordError;

export const getCurrentUILanguage = (state: RootState) =>
    state.user.currentUILanguage;

export const currentUILanguagePreferenceSelector = createSelector(
    getCurrentUILanguage,

    (currentUILanguage) => ({
        name: currentUILanguage.endonym,
        value: currentUILanguage.id,
    })
);

export const getCurrentPreferredLanguage = (state: RootState) =>
    state.user.currentPreferredLanguage;

export const getAvailableUILanguages = (state: RootState) =>
    state.user.availableUILanguages;

export const getChangeLanguageModalStatusSelector = (state: RootState) =>
    state.user.changeLanguageModalStatus;

export const getAvailablePreferredLanguages = (state: RootState) =>
    state.user.availablePreferredLanguageList;

export const isChangingUILanguage = (state: RootState) =>
    state.user.isChangingUILanguage;

export const userRoles = (state: RootState) => state.user.identity?.roles;

export const userLearnerUUID = (state: RootState) =>
    state.user.identity?.learnerUUID;

export const userPasswordPolicySelector = (state: RootState) =>
    state.user.identity?.passwordPolicy;

export const enableOfflineSelector = (state: RootState) =>
    state.user.enableOffline;

export const hasOfflineContractSelector = (state: RootState) => {
    return state.user.hasContractOffline;
};

export const notifConnectionSelector = (state: RootState) => {
    return state.user.notifConnection;
};

export const isUserTeacher = (state: RootState) => {
    //User with this roles is teacher
    const teacherRoles = [
        'Teacher',
        'FaceToFace Trainer',
        'FaceToFace Admin',
        'External Trainers',
        'goFLUENT Trainer',
        'goFLUENT QA Trainer',
        'Novo Trainer',
        'Personea Trainer',
        'VCR Trainer',
    ];
    return (
        state.user.identity?.roles &&
        teacherRoles.some((r) => state.user.identity?.roles.indexOf(r) !== -1)
    );
};

export const userFullname = createSelector(
    (s: RootState) => s.user.identity,
    (user) => {
        if (!user) {
            return '';
        }
        return `${user.firstname} ${user.lastname}`.trim();
    }
);

export const userFirstname = createSelector(
    (s: RootState) => s.user.identity,
    (user) => {
        if (!user) {
            return '';
        }
        return `${user.firstname}`.trim();
    }
);

export const userLastname = createSelector(
    (s: RootState) => s.user.identity,
    (user) => {
        if (!user) {
            return '';
        }
        return `${user.lastname}`.trim();
    }
);

export const userImage = createSelector(
    (s: RootState) => s.user.identity,
    (user) => {
        if (!user) {
            return '';
        }
        return `${user.image}`.trim();
    }
);

export const isDashboardAvailable = createSelector(
    (s: RootState) => s.user.identity,
    (user) => {
        if (!user) return false;
        return user.features.indexOf('dashboard') !== -1;
    }
);

export const isVCRAvailable = createSelector(
    (s: RootState) => s.user.identity,
    (user) => {
        if (!user) return false;
        return user.features.indexOf('virtual classroom') !== -1;
    }
);

export const isSchedulerAvailable = createSelector(
    (s: RootState) => s.user.identity,
    (user) => {
        if (!user) return false;
        return user.features.indexOf('scheduler') !== -1;
    }
);

export const isLessonListAvailable = createSelector(
    (s: RootState) => s.user.identity,
    (user) => {
        if (!user) return false;
        return user.features.indexOf('lesson list') !== -1;
    }
);

export const isELearningRole = createSelector(
    (s: RootState) => s.user.identity,
    (user) => {
        if (!user) return false;
        return user.roles.indexOf('eLearning') !== -1;
    }
);

export const isAssignedTPRole = createSelector(
    (s: RootState) => s.user.identity,
    (user) => {
        if (!user) return false;
        return user.roles.indexOf('Assigned TP') !== -1;
    }
);

export const getExternalLearnerId = createSelector(
    (s: RootState) => s.user.identity,
    (user) => {
        if (!user) return;
        return user.learnerExternalId;
    }
);

export const isRtlViewSelector = createSelector(
    (s: RootState) => {
        return s.user.currentUILanguage;
    },
    (languageId) => {
        if (!languageId) return false;
        return (
            languageId?.id.toLowerCase() === 'ar' ||
            languageId?.id.toLowerCase() === 'he'
        );
    }
);

export const isLoginSSOSelector = createSelector(
    (s: RootState) => s.user,
    (user) => user.isLoginSSO
);

export const isLoginDingTalkSelector = createSelector(
    (s: RootState) => s.user,
    (user) => user.isLoginDingTalk
);

export const redirectPageSelector = (state: RootState) =>
    state.user.redirectPage;

export const isLogoutProcessSelector = (state: RootState) =>
    state.user.isLogoutProcess;

export const isToRestartTokenSelector = (state: RootState) =>
    state.user.restartToken;

export const loadingJWTokenSelector = (state: RootState) => {
    return state.user.isLoadingJWToken;
};
export const userJWTokenSelector = (state: RootState) =>
    state.user.identity?.jwt.token;

export const userExpiredToken = (state: RootState) => state.user.isExpiredToken;

export const userSSOLogout = (state: RootState) => state.user.ssoLogout;

export const userSecretSelector = (state: RootState) => state.user.secretUser;
