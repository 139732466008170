import { Record } from 'immutable';

export interface LegacyIdsDataProps {
    uuid: string;
    articleId: number;
    groupId: number;
    version: number;
    smallImageId: number;
}

const defaultTrainingPathDataLegacyIds: LegacyIdsDataProps = {
    uuid: '',
    articleId: 0,
    groupId: 0,
    version: 0,
    smallImageId: 0,
};

export class TrainingPathDataLegacyIds
    extends Record(defaultTrainingPathDataLegacyIds)
    implements LegacyIdsDataProps {}
