import { Observable } from 'rxjs';
import { ajax } from 'rxjs/ajax';
import { map } from 'rxjs/operators';
import { PortalTrackingSettings } from './portal-activity-tracking/portal-tracking-settings';
import { PortalEvent } from './portal-activity-tracking/portal-event';
import { contentActivity } from './api-urls';

export interface IPortalTrackingApi {
    getSettings(): Observable<PortalTrackingSettings>;

    sendEvent(event: PortalEvent): Observable<any>;
}

export class PortalTrackingApi implements IPortalTrackingApi {
    getSettings(): Observable<PortalTrackingSettings> {
        return ajax
            .getJSON<any>(contentActivity() + 'config', {
                'Content-Type': 'application/json',
            })
            .pipe(
                map((response) => {
                    if (response) {
                        return {
                            frequency: response.ping_frequency,
                            timeout: response.ping_timeout,
                        };
                    } else {
                        return PortalTrackingSettings.getDefault();
                    }
                })
            );
    }

    sendEvent(event: PortalEvent) {
        return ajax.post(contentActivity() + 'portal/event', event, {
            'Content-Type': 'application/json',
        });
    }
}
