import { createSelector } from 'reselect';
import { Availability } from './scheduler-data';
import { Moment } from 'moment';
import { RootState } from 'typesafe-actions';
import { List, Set } from 'immutable';

export const getAvailabilities = (state: RootState) =>
    state.scheduler.availabilities;
export const getLearner = (state: RootState) => state.scheduler.learner;
export const getTeachers = (state: RootState) => state.scheduler.teachers;
export const getScheduler = (state: RootState) => state.scheduler;
export const getCancellableLesson = (state: RootState) =>
    state.scheduler.cancellation;
export const foreseenSelector = (state: RootState) => state.scheduler.foreseen;
export const foreseenGroupSelector = (state: RootState) =>
    state.scheduler.foreseenGroup;
export const isLoadingForeseenSelector = (state: RootState) =>
    state.scheduler.isLoadingForeseen;
export const getSelectedDate = (state: RootState) =>
    state.scheduler.selectedDate;
export const getSelectedAvailability = (state: RootState) =>
    state.scheduler.selectedAvailability;
export const hasSelectedAvailability = (state: RootState) =>
    state.scheduler.selectedAvailability !== null;
export const getMediaList = (state: RootState) => state.scheduler.media;
export const getIsSchedulerListUpdating = (state: RootState) =>
    state.scheduler.isUpdatingInitLessons ||
    state.scheduler.isUpdatingInitializeScheduler ||
    state.scheduler.isUpdatingBooking ||
    state.scheduler.isLessonsUpdating;

export const getIsLoadingMoreAvailabilities = (state: RootState) =>
    state.scheduler.isLessonsUpdatingMore;
export const isAnyPopupOpen = (state: RootState) =>
    state.scheduler.popupsOpen.isAnyOpen;
export const getCurrentLimitation = (state: RootState) =>
    state.scheduler.limitation;
export const getMaxDateForBrowsing = (state: RootState) =>
    state.scheduler.limitation.getMaxDateForBrowsing();
export const getAvailabilitiesError = (state: RootState) =>
    state.scheduler.availabilitiesError;

export const foreseenVCRSelector = (state: RootState) =>
    state.scheduler.foreseenVCR;

export const foreseenIsDisabledVCRSelector = (state: RootState) =>
    state.scheduler.foreseenIsDisabledVCR;

export const foreseenIsVCRStatusSelector = (state: RootState) =>
    state.scheduler.foreseenIsVCRStatus;

export const foreseenResetSelector = (state: RootState) =>
    state.scheduler.foreseenReset;

export const foreseenVCRLessonsList = createSelector(
    foreseenVCRSelector,
    (lessons) => lessons?.virtualClasses
);

const weekLessons = (state: RootState) => state.scheduler.weekLessons;
const lessons = (state: RootState) => state.scheduler.lessons;
const lessonsList = (state: RootState) => state.scheduler.lessonList;

export const isSchedulerNextMonthLoading = (state: RootState) =>
    state.scheduler.isLoadingNextMonth;

export const getForeseenAvailabilities = createSelector(
    getAvailabilities,
    (availabilities) => availabilities.filter((a) => a.status === 'FORSEEN')
);

export const getMediaAuthorize = createSelector(
    getLearner,
    (learner) => learner?.LearnerAuthorizedMedia
);

export const foreseenLessons = createSelector(lessons, (lessons) =>
    lessons.filter((l) => l.status === 'FORSEEN')
);

export const foreseenLessonsList = createSelector(lessonsList, (lessons) =>
    lessons.filter((l) => l.status === 'FORSEEN')
);

export const monthDaysForeseenFromAvailabilities = createSelector(
    getForeseenAvailabilities,
    (availabilities) =>
        availabilities.map((a) => startOfDay(a.startDateTime)).toSet()
);

export const monthDaysForeseenFromLessons = createSelector(
    foreseenLessons,
    (lessons) =>
        lessons.map((lesson) => startOfDay(lesson.startDateTime)).toSet()
);

export const monthDaysForeseenFromLessonList = createSelector(
    foreseenLessonsList,
    (lessons) =>
        lessons.map((lesson) => startOfDay(lesson.startDateTime)).toSet()
);

export const getMonthDaysForeseen = createSelector(
    monthDaysForeseenFromAvailabilities,
    monthDaysForeseenFromLessons,
    monthDaysForeseenFromLessonList,
    (a, b, c) => a.merge(b).merge(c)
);

const selectedDate = (state: RootState) => state.scheduler.selectedDate;
const selectedWeek = createSelector(selectedDate, (date) =>
    date.clone().startOf('week')
);
const endOfSelectedWeek = createSelector(selectedDate, (date) =>
    date.clone().endOf('week')
);

const startOfDay = (d: Moment) => d.clone().startOf('day').valueOf();

export const getWeekDaysForeseen = createSelector(
    selectedWeek,
    endOfSelectedWeek,
    weekLessons,
    getMonthDaysForeseen,
    (
        start: Moment,
        end: Moment,
        lessons: List<Availability>,
        monthDays: Set<number>
    ) => {
        const fromMonth = monthDays.filter(
            (monthDate) =>
                start.isSameOrBefore(monthDate) && end.isSameOrAfter(monthDate)
        );
        const fromWeekLessons = lessons
            .filter((lesson) => lesson.status === 'FORSEEN')
            .map((lesson) => startOfDay(lesson.startDateTime))
            .toSet();

        const merged = fromWeekLessons.merge(fromMonth);
        return merged;
    }
);

export const getHoursBookedWeek = createSelector(
    lessonsList,
    selectedDate,
    (lessons: List<Availability>, week: Moment) => {
        const weekStart = week.clone().startOf('week');
        const weekEnd = week.clone().endOf('week');

        const minutes = lessons
            .filter(
                (lesson) =>
                    lesson.startDateTime.isSameOrAfter(weekStart) &&
                    lesson.startDateTime.isSameOrBefore(weekEnd)
            )
            .filter(
                (lesson) =>
                    lesson.status === 'FORSEEN' ||
                    lesson.status === 'NO_SHOW_LEARNER' ||
                    lesson.status === 'COMPLETED'
            )
            .map((lesson) => lesson.duration)
            .reduce((a, b) => a + b, 0);

        return minutes / 60;
    }
);

export const getHoursBookedMonth = createSelector(
    lessonsList,
    selectedDate,
    (lessons: List<Availability>, selected: Moment) => {
        const startDate = selected.clone().startOf('month');
        const endDate = selected.clone().endOf('month');

        const minutes = lessons
            .filter(
                (lesson) =>
                    lesson.startDateTime.isSameOrAfter(startDate) &&
                    lesson.startDateTime.isSameOrBefore(endDate)
            )
            .filter(
                (lesson) =>
                    lesson.status === 'FORSEEN' ||
                    lesson.status === 'NO_SHOW_LEARNER' ||
                    lesson.status === 'COMPLETED'
            )
            .map((lesson) => lesson.duration)
            .reduce((a, b) => a + b, 0);

        return minutes / 60;
    }
);
