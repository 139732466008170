import { ApiHeaders } from '../api-headers';
import { Axios } from 'axios-observable';
import { ModuleRestrictionDisplayProps } from '../../store/module-restriction/module-restriction-records';
import { Observable } from 'rxjs';
import { apiv1 } from '../api-urls';
import { map } from 'rxjs/operators';

export class ModuleRestrictionApi {
    constructor(private readonly apiHeaders: ApiHeaders) {}

    loadModuleRestrictionCall(
        learnerUUID: string
    ): Observable<ModuleRestrictionDisplayProps | null> {
        return Axios.get<ModuleRestrictionDisplayProps | null>(
            apiv1() +
                `display-strategy/handle/module-restriction/user/${learnerUUID}`,
            { headers: this.apiHeaders.getHeaders() }
        ).pipe(map((x) => x.data));
    }
}
