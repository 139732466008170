import { createAction, createAsyncAction } from 'typesafe-actions';
import { ActivityReportContentDto } from './training-activity';
import { EwritingListItem } from '../e-writing/data/ewriting-models';
import { ActivityTrainingLessonDto } from '../../services/activity-lesson-api';
import { ActivityReportVcr } from './training-vcr';
import { ActivityReportCourses } from './training-course';
import { ActivityPlacementTestDto } from './training-placement-test';

export const trainingPageOpened = createAction('@@training/page/opened')();

export const loadTrainingActivity = createAsyncAction(
    '@@training/activity/request',
    '@@training/activity/success',
    '@@training/activity/failure'
)<{ topicId: null | string | undefined }, ActivityReportContentDto[], Error>();

export const loadTrainingVCRActivity = createAsyncAction(
    '@@training/activity-vcr/request',
    '@@training/activity-vcr/success',
    '@@training/activity-vcr/failure'
)<{ topicId: null | string | undefined }, ActivityReportVcr[], Error>();

export const loadTrainingEwriting = createAsyncAction(
    '@@training/ewriting/request',
    '@@training/ewriting/success',
    '@@training/ewriting/failure'
)<{ topicId: null | string | undefined }, EwritingListItem[], Error>();

export const loadLessonsActivity = createAsyncAction(
    '@@training/lesson/request',
    '@@training/lesson/success',
    '@@training/lesson/failure'
)<{ topicId: null | string | undefined }, ActivityTrainingLessonDto[], Error>();

export const loadTrainingCoursesActivity = createAsyncAction(
    '@@training/courses/request',
    '@@training/courses/success',
    '@@training/courses/failure'
)<{ topicId: null | string | undefined }, ActivityReportCourses[], Error>();

export const loadPlacementTrainingActivity = createAsyncAction(
    '@@training/activity-placement/request',
    '@@training/activity-placement/success',
    '@@training/activity-placement/failure'
)<void, ActivityPlacementTestDto[], Error>();

export const resetAllTraining = createAction('@@training/reset')();

export const setNavigation = createAction('@@training/navigation')<boolean>();
