import { createLoadResourcesAction } from '../resources-actions';
import { createAction } from 'typesafe-actions';
import { ResourceContent } from '../resource-content';
import { ResourceContentType } from '../resource-content-type';
import { clearAllResources } from '../resources-actions';
import { List } from 'immutable';

export const loadArticles = createLoadResourcesAction<ResourceContent>(
    ResourceContentType.Article
);

export const loadFilteredArticles = createLoadResourcesAction<ResourceContent>(
    `${ResourceContentType.Article}-filtered`
);

export const resetArticles =
    createAction('@@articles/reset')<List<ResourceContent>>();
export const clearArticles = createAction('@@articles/clear')();

export const articleActions = {
    loadArticles,
    loadFilteredArticles,
    resetArticles,
    clearArticles,
    clearAllResources,
};
