import { Axios } from 'axios-observable';
import { List, Map } from 'immutable';
import { keyBy } from 'lodash';
import moment from 'moment';
import { Observable, of } from 'rxjs';
import { catchError, concatMap, map } from 'rxjs/operators';
import {
    ResourceContent,
    ResourceContentProps,
} from '../store/resources/resource-content';
import {
    LegacyIdsDataProps,
    TrainingPathDataLegacyIds,
} from '../store/resources/training-path/training-path-data';
import {
    CourseCategory,
    CourseSubCategory,
    CourseTopic,
    TrainingPath,
} from '../store/resources/training-path/training-path-record';
import { UserRecord } from '../store/user/user-record';
import { ApiHeaders } from './api-headers';
import {
    apiv1,
    contentService,
    learnerRecommendation,
    legacyIdService,
    mobileRest,
} from './api-urls';
import {
    ResourceListDTO,
    ResourceListParser,
} from './resources/resource-list-parser';

export interface LegacyIdentifierDto {
    id: number;
    name: string;
}

export interface LegacyFilterDto {
    identifiers: LegacyIdentifierDto[];
    type:
        | 'Tag'
        | 'Group'
        | 'ContentType'
        | 'ContentStructure'
        | 'Vocabulary'
        | 'ContentGroup';
}

export interface TopicDto {
    code: string;
    name: string;
    topicUUID: string;
}

export interface BrowseItemDto {
    name: string;
    ordinal: number;
    parent: string;
    rule: 'NoRule' | 'LegacyTrainingPathFilter' | 'LegacyContentFilter';
    filters: LegacyFilterDto[];
    topic: TopicDto;
}

interface CoursesApiErrorDTO {
    message: string;
}

type CoursesSuccessDTO = TrainingPath[];

type CoursesDTO = CoursesApiErrorDTO | CoursesSuccessDTO;

function isApiError<T>(
    response: CoursesApiErrorDTO | T
): response is CoursesApiErrorDTO {
    return (response as CoursesApiErrorDTO).message !== undefined;
}

// User assignment DTO for show recommended in slider
type AssignmentDTOType =
    | 'Homework'
    | 'LessonReport'
    | 'Workshop'
    | 'HomeworkAndWorkshop';
interface AssignmentDTOTagEntry {
    id: number;
    name: string;
    isCategory: boolean;
}
interface AssignmentDTOLegacy {
    id: number;
    articleId: number;
    resourcePrimKey: number;
    tagAssetId: number;
    contentType: string;
    structureId: string;
    groupId: number;
    groupName: string;
    tags: number[];
    tagEntries: AssignmentDTOTagEntry[];
    smallImageId: number;
    mediumImage: string;
}
type AssignedDataSourceType = 'Assignment' | 'LearnerBookmark';
type AssignmentDTOAssignmentManagementType = 'Individual' | 'Organizational';
type AssignmentDTOStatusType = 'Assigned' | 'Completed' | 'Draft';
type AssignmentDTOUserDTOType =
    | 'Learner'
    | 'Trainer'
    | 'Manager'
    | 'LC'
    | 'Unknown';
interface AssignmentDTOUserDTO {
    uuid: string;
    type: AssignmentDTOUserDTOType;
    avatar: string;
    firstname: string;
    lastname: string;
    middlename: string;
}
interface AssignmentDTOLessonDurationInterface {
    seconds: number;
    nano: number;
    negative: boolean;
    zero: boolean;
}
interface AssignmentDTOLessonDurationUnitsInterface {
    dateBased: boolean;
    timeBased: boolean;
    duration: AssignmentDTOLessonDurationInterface[];
    durationEstimated: boolean;
}
interface AssignmentDTOLessonBaseDurationInterface
    extends AssignmentDTOLessonDurationInterface {
    units: AssignmentDTOLessonDurationUnitsInterface;
}
interface AssignmentDTOLessonInterface {
    lessonId: number;
    productName: string;
    start: string;
    end: string;
    duration: AssignmentDTOLessonBaseDurationInterface;
}

export interface AssignmentDTOInterface {
    legacy: AssignmentDTOLegacy;
    type: AssignmentDTOType;
    assignmentManagementType: AssignmentDTOAssignmentManagementType;
    status: AssignmentDTOStatusType;
    assignmentDate: string;
    dueDate: string;
    modifiedDate: string;
    topic: string;
    assigner: AssignmentDTOUserDTO;
    mediator: AssignmentDTOUserDTO;
    assignee: AssignmentDTOUserDTO;
    lessonId: number;
    tags: AssignmentDTOTagEntry[];
    lessonInfo: AssignmentDTOLessonInterface;
    title: string;
    description: string;
    thumbnail: string;
    uuid: string;
    // contentUUID: string;
    // articleId: string;
    // image: string;
    // contentTotal: number;
}

interface UserAssignmentContentDto {
    userId: string;
    topicId: string;
    nextKey: string;
    dataSource: AssignedDataSourceType;
    assignmentDto: AssignmentDTOInterface[];
}
// Add type for categories/assigned response
interface CategoriesAssignedItem {
    articleId: string;
    contentTotal: number;
    contentUUID: string;
    description: string;
    image: string;
    title: string;
}
// Add type to manual all courses
interface AllCoursesParsed {
    image: string;
    createdDate?: string;
    displayDate: string;
    articleId: string;
    modifiedDate: string;
    description: string;
    title: string;
    contentTotal: number;
    uuid: string;
    contentUUID: string;
}

export class TrainingPathApi {
    apiHeaders: ApiHeaders;
    resourceListParser = new ResourceListParser();

    constructor(apiHeaders: ApiHeaders) {
        this.apiHeaders = apiHeaders;
    }

    private collectTags(filteredAssignmentDto: AssignmentDTOInterface[]) {
        const tags = new Set<number>();
        for (let i = 0; i < filteredAssignmentDto.length; i++) {
            for (let j = 0; j < filteredAssignmentDto[i].tags.length; j++) {
                tags.add(filteredAssignmentDto[i].tags[j].id);
            }
        }
        return Array.from(tags);
    }

    private parseCourses(
        allCourses: AllCoursesParsed[],
        filteredAssignmentDto: AssignmentDTOInterface[]
    ) {
        const articleIdMap = keyBy(allCourses, 'articleId');
        const findArticleById = (id: string) => articleIdMap[id];
        const neededCourses = [];
        for (let i = 0; i < filteredAssignmentDto.length; i++) {
            const item = findArticleById(
                filteredAssignmentDto[i].legacy.articleId.toString()
            );
            neededCourses.push(item);
        }
        return neededCourses;
    }

    loadAllCoursesForTrainingPath(tags: number[]) {
        return Axios.post<AllCoursesParsed[]>(
            `${mobileRest()}ws/v1.0/courses`,
            {
                tagsIds: tags,
            },
            { headers: { 'Content-Type': 'application/json' } }
        ).pipe(
            map((response) => response.data),
            catchError((e) => {
                console.error(e);
                return of([] as AllCoursesParsed[]);
            })
        );
    }

    loadAdditionalTrainingPathContentTotal(
        filteredAssignmentDto: AssignmentDTOInterface[]
    ) {
        const tags = this.collectTags(filteredAssignmentDto);
        return this.loadAllCoursesForTrainingPath(tags).pipe(
            map((allCourses) => {
                const result = this.parseCourses(
                    allCourses,
                    filteredAssignmentDto
                );
                return result.map((tp) => new TrainingPath(tp));
            }),
            catchError((e) => {
                console.log(e);
                throw new Error("Can't load training paths");
            })
        );
    }

    loadAdditionalTrainingPath(
        user: UserRecord | null,
        selectedLanguageUUID: string | null
    ): Observable<AssignmentDTOInterface[]> {
        if (!user) {
            throw new Error(
                "Can't load training paths because of user identity is null"
            );
        }
        if (!selectedLanguageUUID) {
            throw new Error(
                "Can't load training paths because of selected language is null"
            );
        }
        const { learnerUUID } = user;

        return Axios.get<UserAssignmentContentDto>(
            `${learnerRecommendation()}resource/assigned/resource`,
            {
                params: {
                    dataSource: 'Assignment',
                    userId: learnerUUID,
                    topicId: selectedLanguageUUID,
                },
            }
        ).pipe(
            map((response) =>
                response.data.assignmentDto.filter(
                    (item) =>
                        (item.legacy.contentType === 'training-path' ||
                            item.legacy.contentType === 'trainingpath') &&
                        (!item.dueDate ||
                            moment(item.dueDate).isSameOrAfter(moment()))
                )
            ),
            catchError((e) => {
                console.error(e);
                return of([]);
            })
        );
    }

    loadTrainingPath(
        user: UserRecord | null,
        selectedLanguageCode: string
    ): Observable<TrainingPath[]> {
        if (!user) {
            throw new Error(
                "Can't load training paths because of user identity is null"
            );
        }
        const { learnerId } = user;

        return Axios.get<CategoriesAssignedItem[]>(
            mobileRest() +
                `ws/trainingpath/${selectedLanguageCode}/categories/assigned/${learnerId}`,
            { headers: this.apiHeaders.getHeaders() }
        ).pipe(
            map((x) => x.data.map((tp) => new TrainingPath(tp))),
            catchError((e) => {
                console.error(e);
                throw new Error("Can't load training paths");
            })
        );
    }

    loadUUID(articleIds: number[]) {
        return Axios.post<any[]>(
            `${contentService()}legacy-ids/from-articleId-list`,
            articleIds,
            { headers: this.apiHeaders.getHeaders() }
        );
    }

    loadTrainingList(
        index: number,
        articleId: string
    ): Observable<ResourceContent[]> {
        return Axios.get<ResourceListDTO[]>(
            `${contentService()}acl/trainingpath/v1.0/tp/assigned/${index}/${articleId}`,
            { headers: this.apiHeaders.getHeaders() }
        ).pipe(
            concatMap((x) => {
                const items = x.data;
                const ids = x.data.map((item) => item.articleId as number);
                return this.loadUUID(ids).pipe(
                    map((y) => {
                        const uuids = y.data;
                        return items.map((item) => {
                            const uuid = uuids.find(
                                (id: any) =>
                                    String(id.articleId) ===
                                    String(item.articleId)
                            );
                            item.contentUUID = uuid ? uuid.uuid : '';
                            return this.resourceListParser.parseListItem(item);
                        });
                    }),
                    catchError((e) => {
                        console.error(e);
                        return of(
                            items.map((item) =>
                                this.resourceListParser.parseListItem(item)
                            )
                        );
                    })
                );
            }),
            catchError(() => {
                throw new Error("Can't load training paths list");
            })
        );
    }

    loadCourseCategories(
        learningLanguageCode: string,
        displayLanguage: string
    ): Observable<List<CourseCategory>> {
        return Axios.get<BrowseItemDto[]>(
            `${apiv1()}courses/all?code=${displayLanguage}`,
            {
                headers: this.apiHeaders.getHeaders(),
            }
        ).pipe(
            map((response) => this.mapToCategories(response.data)),
            catchError(() => {
                throw new Error("Can't load course categories");
            })
        );
    }

    loadCourses(courseTags: List<number> | []) {
        return Axios.post(
            `${mobileRest()}ws/v1.0/courses`,
            {
                tagsIds: Array.isArray(courseTags)
                    ? courseTags
                    : courseTags.toArray(),
            },
            { headers: { 'Content-Type': 'application/json' } }
        ).pipe(
            map((response: { data: CoursesDTO }) => {
                if (isApiError(response.data)) {
                    throw new Error(response.data.message);
                }
                return response.data.map(
                    (x: TrainingPath) => new TrainingPath(x)
                );
            }),
            catchError((e) => {
                if (e.response.message) {
                    throw new Error(e.response.message);
                } else {
                    throw new Error("Can't load courses");
                }
            })
        );
    }

    loadCoursesLegacyId(
        trainingPathId: string
    ): Observable<TrainingPathDataLegacyIds> {
        return Axios.get<LegacyIdsDataProps>(
            `${legacyIdService()}/from-a/${trainingPathId}`,
            {
                headers: this.apiHeaders.getHeaders(),
            }
        ).pipe(map((x) => new TrainingPathDataLegacyIds(x.data)));
    }

    loadNowViewableCourseData(
        trainingPathId: string
    ): Observable<ResourceContentProps> {
        return this.loadCoursesLegacyId(trainingPathId).pipe(
            concatMap((x) => {
                return Axios.get<ResourceContentProps>(
                    contentService() + `content/${x.uuid}`,
                    {
                        headers: this.apiHeaders.getHeaders(),
                    }
                ).pipe(
                    map((y) => {
                        return y.data;
                    })
                );
            })
        );
    }

    private mapToCategories(
        browseItems: BrowseItemDto[]
    ): List<CourseCategory> {
        let categoriesMap = Map<string, List<CourseSubCategory>>();

        browseItems.forEach((item) => {
            const subcategories =
                categoriesMap.get(item.parent) ?? List<CourseSubCategory>();

            const subcategory = subcategories.find(
                (subcategory) => subcategory.title === item.name
            );

            let subcategoryTags = Map<string, List<string>>();
            let topic = List<CourseTopic>();
            let subcategoryTagsId = Map<string, List<number>>();

            if (subcategory) {
                subcategoryTags = subcategory.tags;
                topic = subcategory.topic;
                subcategoryTagsId = subcategory.tagsId;
            }

            if (!subcategory) {
                categoriesMap = categoriesMap.set(
                    item.parent,
                    subcategories.push(
                        new CourseSubCategory({
                            id: item.ordinal,
                            title: item.name,
                            tags: Map([
                                [
                                    item.topic.topicUUID,
                                    List(this.getTagsFromFilters(item.filters)),
                                ],
                            ]),
                            tagsId: Map([
                                [
                                    item.topic.topicUUID,
                                    List(
                                        this.getTagsIdFromFilters(item.filters)
                                    ),
                                ],
                            ]),
                            topic: List([item.topic]),
                        })
                    )
                );
            } else {
                categoriesMap = categoriesMap.set(
                    item.parent,
                    subcategories
                        .remove(subcategories.indexOf(subcategory))
                        .push(
                            new CourseSubCategory({
                                id: item.ordinal,
                                title: item.name,
                                tags: subcategoryTags.set(
                                    item.topic.topicUUID,
                                    List(this.getTagsFromFilters(item.filters))
                                ),
                                tagsId: subcategoryTagsId.set(
                                    item.topic.topicUUID,
                                    List(
                                        this.getTagsIdFromFilters(item.filters)
                                    )
                                ),
                                topic: topic.concat([item.topic]),
                            })
                        )
                );
            }
        });

        let categories = List<CourseCategory>();
        const parentItems = categoriesMap.get('');

        if (parentItems) {
            parentItems.forEach((parent: CourseSubCategory) => {
                categories = categories.push(
                    new CourseCategory({
                        id: parent.id,
                        title: parent.title,
                        subcategories: categoriesMap
                            .get(parent.title)
                            ?.sortBy((x) => x.id),
                        topic: parent.topic,
                    })
                );
            });
        }

        return categories.sortBy((x) => x.id);
    }

    private getTagsFromFilters(filters: LegacyFilterDto[]) {
        return filters
            .filter((filter) => filter.type === 'Tag')
            .reduce(
                (allFilters: string[], filter) =>
                    allFilters.concat(filter.identifiers.map((x) => x.name)),
                []
            );
    }

    private getTagsIdFromFilters(filters: LegacyFilterDto[]) {
        return filters
            .filter((filter) => filter.type === 'Tag')
            .reduce(
                (allFilters: number[], filter) =>
                    allFilters.concat(filter.identifiers.map((x) => x.id)),
                []
            );
    }
}
