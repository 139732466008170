import { createLoadResourcesAction } from '../resources-actions';
import { createAsyncAction, createAction } from 'typesafe-actions';
import {
    UserCompletedHowToBlock,
    UserCompletedHowToSection,
} from './user-completed-block';
import { ResourceContentType } from '../resource-content-type';
import { ResourceContent } from '../resource-content';
import { clearAllResources } from '../resources-actions';
import { List } from 'immutable';

export const loadVocabularies = createLoadResourcesAction<ResourceContent>(
    ResourceContentType.Vocabulary
);
export const loadFilteredVocabularies =
    createLoadResourcesAction<ResourceContent>(
        `${ResourceContentType.Vocabulary}-filtered`
    );

export const resetVocabularies = createAction('@@vocabularies/reset')<
    List<ResourceContent>
>();
export const clearVocabularies = createAction('@@vocabularies/clear')();

export const vocabularyActions = {
    loadVocabularies,
    loadFilteredVocabularies,
    resetVocabularies,
    clearVocabularies,
    clearAllResources,
};

export const loadGrammar = createLoadResourcesAction<ResourceContent>(
    ResourceContentType.Grammar
);
export const loadFilteredGrammar = createLoadResourcesAction<ResourceContent>(
    `${ResourceContentType.Grammar}-filtered`
);

export const resetGrammar =
    createAction('@@grammar/reset')<List<ResourceContent>>();
export const clearGrammar = createAction('@@grammar/clear')();

export const grammarActions = {
    loadGrammar,
    loadFilteredGrammar,
    resetGrammar,
    clearGrammar,
    clearAllResources,
};

export const loadHowtos = createLoadResourcesAction<ResourceContent>(
    ResourceContentType.Howto
);
export const loadFilteredHowtos = createLoadResourcesAction<ResourceContent>(
    `${ResourceContentType.Howto}-filtered`
);

export const resetHowtos =
    createAction('@@howtos/reset')<List<ResourceContent>>();
export const clearHowtos = createAction('@@howtos/clear')();

export const setHowtoFilterItems = createAction('@@howto/set-filter-item')<
    List<ResourceContent>
>();

export const openHowto = createAction('@@open-howto')<{
    contentType: ResourceContentType;
    resourceId: number;
    groupId: number;
}>();

export const openHowtoUsingCuuid = createAction(
    '@@open-howto-from-cuuid'
)<string>();

export const howToActions = {
    loadHowtos,
    loadFilteredHowtos,
    resetHowtos,
    clearHowtos,
    openHowtoUsingCuuid,
    openHowto,
    clearAllResources,
    setHowtoFilterItems,
};

export const loadCompletedBlock = createAsyncAction(
    '@@load-completed-sections/request',
    '@@load-completed-sections/success',
    '@@load-completed-sections/failure'
)<
    {
        resourceId: number;
        groupId: number;
        learnerId: string;
    },
    {
        resource: { resourceId: number };
        block: UserCompletedHowToBlock;
    },
    Error
>();

export const saveCompletedSection = createAction('@@save-completed-section')<{
    resource: {
        resourceId: number;
        groupId: number;
    };
    section: {
        blockId: string | null;
        instanceId: string;
    };
}>();

export const saveCompletedBlock = createAsyncAction(
    '@@save-completed-block/request',
    '@@save-completed-block/success',
    '@@save-completed-block/failure'
)<
    {
        resource: {
            resourceId: number;
            groupId: number;
        };
        user: {
            learnerId: string;
        };
        section: {
            instanceId: string;
        };
    },
    {
        resource: { resourceId: number };
        block: UserCompletedHowToBlock;
    },
    Error
>();

export const updateCompletedBlock = createAsyncAction(
    '@@update-completed-block/request',
    '@@update-completed-block/success',
    '@@update-completed-block/failure'
)<
    {
        resource: { resourceId: number };
        section: {
            blockId: string | null;
            instanceId: string;
        };
    },
    {
        resource: { resourceId: number };
        section: UserCompletedHowToSection;
    },
    Error
>();

export const howToCompletedBlockActions = {
    loadCompletedBlock,
    saveCompletedSection,
    saveCompletedBlock,
    updateCompletedBlock,
    clearAllResources,
};

export const allHowToActions = {
    ...howToActions,
    ...grammarActions,
    ...vocabularyActions,
    ...howToCompletedBlockActions,
};
