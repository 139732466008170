import { ApiHeaders } from './api-headers';
import { from, lastValueFrom, Observable, of } from 'rxjs';
import { Axios } from 'axios-observable';
import { activityReport, user } from './api-urls';
import { map } from 'rxjs/operators';
import { ActivityReportContentDto } from '../store/training/training-activity';
import {
    ActivityReportVcrDto,
    ActivityReportVcr,
} from '../store/training/training-vcr';
import {
    ActivityReportCourses,
    ActivityReportCoursesDto,
} from '../store/training/training-course';
import { ActivityPlacementTestDto } from '../store/training/training-placement-test';
import { Cache } from '../utils/cache';
import { ajax } from 'rxjs/ajax';
import { ActivityUserDto } from '../store/training/training-user';

export class TrainingApi {
    apiHeaders: ApiHeaders;

    constructor(apiHeaders: ApiHeaders) {
        this.apiHeaders = apiHeaders;
    }

    loadActivity(
        userUUID: string,
        topicUUID: null | string | undefined
    ): Observable<ActivityReportContentDto[]> {
        let activityUrl = activityReport() + `content/${userUUID}`;

        if (topicUUID) {
            activityUrl += `?topicUUID=${topicUUID}`;
        }

        return Axios.get<ActivityReportContentDto[]>(activityUrl, {
            headers: this.apiHeaders.getHeaders(),
        }).pipe(map((x) => x.data));
    }

    loadVCR(
        userUUID: string,
        topicUUID: null | string | undefined,
        gradings: any
    ): Observable<ActivityReportVcr[]> {
        return from(this.loadVCRWithGrading(userUUID, topicUUID, gradings));
    }

    private async loadVCRWithGrading(
        userUUID: string,
        topicUUID: null | string | undefined,
        gradings: any
    ): Promise<ActivityReportVcr[]> {
        let vcrUrl = activityReport() + `vcr/${userUUID}`;

        if (topicUUID) {
            vcrUrl += `?topicUUID=${topicUUID}`;
        }

        const vcrList = await lastValueFrom(
            Axios.get<ActivityReportVcrDto[]>(vcrUrl, {
                headers: this.apiHeaders.getHeaders(),
            }).pipe(map((x) => x.data))
        );

        let newItems = Array<ActivityReportVcr>();

        newItems = vcrList.map((vcrItem) => {
            const gradingList = vcrItem.gradingIds.map((j: any) => {
                const gradingItem = gradings.find((t: any) => t.id === j);
                if (gradingItem) {
                    return gradingItem;
                }
                return null;
            });

            return {
                ...vcrItem,
                gradingList,
            };
        });

        return lastValueFrom(of(newItems));
    }

    loadCourses(
        userUUID: string,
        topicUUID: null | string | undefined
    ): Observable<ActivityReportCourses[]> {
        return from(this.loadCoursesWithAssigner(userUUID, topicUUID));
    }

    private async loadCoursesWithAssigner(
        userUUID: string,
        topicUUID: null | string | undefined
    ): Promise<ActivityReportCourses[]> {
        let vcrUrl = activityReport() + `courses/${userUUID}`;

        if (topicUUID) {
            vcrUrl += `?topicUUID=${topicUUID}`;
        }

        const list = await lastValueFrom(
            Axios.get<ActivityReportCoursesDto[]>(vcrUrl, {
                headers: this.apiHeaders.getHeaders(),
            }).pipe(map((x) => x.data))
        );

        function notEmpty<TValue>(
            value: TValue | null | undefined
        ): value is TValue {
            return value !== null && value !== undefined;
        }

        let trainerIds = list
            .map((course) => course?.assigner)
            .filter(notEmpty);

        let uniqueTrainerIds = trainerIds.filter(
            (v, i, a) => a.indexOf(v) === i
        );

        let trainers = await this.loadUsers(uniqueTrainerIds);

        let newItems = Array<ActivityReportCourses>();

        list.forEach((l) => {
            const assigner = trainers.find((t) => t.uuid === l.assigner);

            newItems.push({ ...l, assigner });
        });

        return lastValueFrom(of(newItems));
    }

    loadPlacementTrainingActivity(
        userUUID: string
    ): Observable<ActivityPlacementTestDto[]> {
        return Axios.get<ActivityPlacementTestDto[]>(
            activityReport() + `adaptive-test/${userUUID}`,
            { headers: this.apiHeaders.getHeaders() }
        ).pipe(map((x) => x.data));
    }

    private async loadUsers(ids: string[]): Promise<ActivityUserDto[]> {
        if (ids.length > 0) {
            const requests = ids.map((t) => this.loadUser(t));
            return Promise.all(requests);
        }

        return lastValueFrom(of([]));
    }

    @Cache({ globalPrefix: 'loadProfile' })
    private loadUser(userId: string): Promise<ActivityUserDto> {
        return lastValueFrom(
            ajax
                .getJSON<ActivityUserDto>(
                    user() + userId,
                    this.apiHeaders.getHeaders()
                )
                .pipe(map((r) => r))
        );
    }
}
