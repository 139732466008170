import { Record } from 'immutable';

export interface AclResponseInterface {
    id: string;
    title: string;
    description: string;
    metadata: {
        tags: string[];
        teachingConcept: string[];
        gradings: string[];
        topics: string[];
        accessRights: string[];
        organisations: number[];
        targetType: string;
        targetFunction: string;
        businessType: string;
        groupingList: string[];
    };
    legacy: {
        id: number;
        articleId: number;
        resourcePrimKey: number;
        tagAssetId: number;
        contentType: string;
        structureId: string;
        groupId: number;
        groupName: string;
        tags: string[];
        tagEntries: string[];
        smallImageId: number;
        mediumImage: string;
    };
    content: any;
    migrationUuid: string;
}

export interface SavedContentNotification {
    show: boolean;
    error: boolean;
    info?: boolean;
    text: string;
    maximum?: boolean;
    key: number;
}

export interface SavedContentPostResponseKey {
    userId: string;
    topicId: string;
    dataSource: 'Assignment' | 'LearnerBookmark' | 'LegacyPortal';
    contentId: string;
}

export interface SavedContentPostResponse {
    key: SavedContentPostResponseKey;
    assignerId: string;
    assignedDate: string;
    assignedInMicroSeconds: number;
    dueDate: string;
    modifiedDate: string;
}

export interface AssignmentDTO {
    userId: string;
    topicId: string;
    contentId: string;
    assignerId: string;
    assignedDate: string;
    dueDate: string;
    status: 'Assigned' | 'Completed' | 'Draft';
    modified: string;
}

export interface SaveContentProps {
    userId: string;
    topicId: string;
    dataSource: 'Assignment' | 'LearnerBookmark' | 'LegacyPortal';
    assignmentDto: AssignmentDTO[];
}

export const defaultSaveContentProps: SaveContentProps = {
    userId: '',
    topicId: '',
    dataSource: 'LearnerBookmark',
    assignmentDto: [],
};

export class SaveContentDTO
    extends Record(defaultSaveContentProps)
    implements SaveContentProps {}
