export interface ThemeColors {
    text: string;
    secondaryText: string;
    hover: string;
    active: string;
}

export interface Themes {
    light: ThemeColors;
    dark: ThemeColors;
}

export const defaultThemeColors: Themes = {
    light: {
        text: '#162235',
        secondaryText: 'rgba(22, 34, 53, 0.8)',
        hover: '#F2F4F4',
        active: '#E7E7E7',
    },
    dark: {
        text: '#FFFFFF',
        secondaryText: 'rgba(255, 255, 255, 0.65)',
        hover: 'rgba(255, 255, 255, 0.2)',
        active: 'rgba(255, 255, 255, 0.5)',
    },
};
