import { List, Record } from 'immutable';
import moment from 'moment';
import { ResourceDescription } from '../resources';
import { ResourceContentType } from '../resource-content-type';

export interface VideoDescriptionProps extends ResourceDescription {
    learn: string;
    subtitles?: string;
    description: string;
    urls: List<string>;
    source?: string;
}

const defaultVideoDescriptionProps: VideoDescriptionProps = {
    contentMetadata: null,
    resourceId: '',
    groupId: 0,
    learn: '',
    categories: List(),
    subtitles: undefined,
    date: moment.invalid(),
    description: '',
    index: 0,
    tags: List(),
    thumbnail: undefined,
    title: '',
    urls: List(),
    source: '',
    quizArticleId: null,
    content: null,
    type: ResourceContentType.Video,
    contentUUId: '',
    quizUUID: '',
    template: 'old',
    topicUUID: '',
};

export class VideoDescription
    extends Record(defaultVideoDescriptionProps)
    implements VideoDescriptionProps
{
    get videoUrl() {
        return this.urls.first<string>();
    }

    get videoWithoutSubUrl() {
        // console.log(this.urls.toArray(), this.urls.get(1, this.videoUrl));
        return this.urls.get(1, this.videoUrl);
    }
}

export function isVideo(
    resource: ResourceDescription
): resource is VideoDescription {
    return resource.type === ResourceContentType.Video;
}
