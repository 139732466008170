import { List } from 'immutable';
import moment from 'moment';
import { Observable } from 'rxjs';
import { ajax, AjaxResponse } from 'rxjs/ajax';
import { catchError, map, switchMap } from 'rxjs/operators';
import { IUseRetrieveUser } from '../components/login/lib/use-retrieve-user';
import { ILanguageApi } from '../localization/language-api';
import { ILocalizationService } from '../localization/localization-service';
import {
    JWTokenProps,
    UserRecord,
    UserTimespentProps,
} from '../store/user/user-record';
import { ApiHeaders } from './api-headers';
import { activity, jwtLegacyService, mobileRest, user } from './api-urls';
import { generate } from 'generate-password-ts';
import { UseGetLegacyToken } from '../components/login/lib/use-retrieve-token';

interface UserDto {
    User?: {
        emailAddress: string;
        uuid: string;
        image: string | null;
        learnerId: number;
        learnerExternalId: number;
        firstname: string;
        lastname: string;
        middlename: string;
        authenticationToken: string;
        aes: string;
        timeZoneId: string;
        selectedTopic: string;
        securityId: string;
        nationality: string;
        schedulerMaxLessonHours: string;
        schedulerMaxLessonRange: string;
        schedulerDisplayAvailabilityValue: string;
        schedulerDisplayAvailabilities: string;
        allowInternetCall: boolean;
        allowCallback: boolean;
        language: string;
        timespent: UserTimespentProps;
    };
    Features: string[];
    Roles: string[];
    NewPassword: boolean;
    PasswordPolicy: string;
}

export interface JWTDto {
    jwt: string;
    userId: string;
    expiryInMinutes: number;
    mustChangePassword: boolean;
}

export class AuthenticationApi {
    private readonly apiHeaders: ApiHeaders;
    private readonly localizationService: ILocalizationService;
    private readonly languageApi: ILanguageApi;

    constructor(
        apiHeaders: ApiHeaders,
        localization: ILocalizationService,
        language: ILanguageApi
    ) {
        this.apiHeaders = apiHeaders;
        this.localizationService = localization;
        this.languageApi = language;
    }

    loadLegacyToken(email: string, password: string): Observable<UserDto> {
        const payload = {
            Email: email,
            Password: password,
            hasEmailValidation: 'true',
        };

        return ajax
            .post(`${mobileRest()}ws/v1.0/authentication`, payload, {
                'Content-Type': 'application/json',
            })
            .pipe(
                map((x: AjaxResponse<any>) => {
                    const token = x.xhr.getResponseHeader('Authorization');
                    if (!token) {
                        throw new Error('Authorization failure');
                    }

                    const dto: UserDto = {
                        ...x.response,
                        User: {
                            ...x.response.User,
                            authenticationToken: token,
                        },
                    };

                    return dto;
                }),
                catchError((error) => {
                    throw error.status;
                })
            );
    }

    loadJwtToken(email: string, password: string): Observable<JWTokenProps> {
        const params = new URLSearchParams();
        params.append('username', email);
        params.append('password', password);

        const url = `${jwtLegacyService()}/jwt/auth/credentials`;

        return ajax({
            url: url,
            method: 'POST',
            headers: {
                'content-type': 'application/x-www-form-urlencoded',
                accept: 'application/json',
            },
            body: params,
        }).pipe(
            map((x: AjaxResponse<any>) => this.AssembleJWToken(x.response)),
            catchError((error) => {
                throw error.status;
            })
        );
    }

    loadUserData(
        authToken: string,
        jwtData: JWTokenProps | null = null
    ): Observable<UserRecord> {
        return ajax
            .getJSON<UserDto>(`${mobileRest()}ws/user`, {
                Authorization: authToken,
            })
            .pipe(
                map(
                    (x) =>
                        new UserRecord({
                            emailAddress: x.User?.emailAddress,
                            learnerUUID: x.User?.uuid,
                            image: x.User?.image as any,
                            learnerId: x.User?.learnerId,
                            learnerExternalId: x.User?.learnerExternalId,
                            firstname: x.User?.firstname,
                            lastname: x.User?.lastname,
                            middlename: x.User?.middlename,
                            authenticationToken: authToken,
                            aes: x.User?.aes,
                            timeZoneId: x.User?.timeZoneId,
                            selectedTopic: x.User?.selectedTopic,
                            securityId: x.User?.securityId,
                            nationality: x.User?.nationality,
                            allowInternetCall: x.User?.allowInternetCall,
                            allowCallback: x.User?.allowCallback,
                            language: x.User?.language,
                            timespent: {
                                jsessionId: x.User?.timespent?.jsessionId || '',
                                ipAddress: x.User?.timespent?.ipAddress || '',
                            },
                            features: List(x.Features),
                            roles: List(x.Roles),
                            ...(jwtData
                                ? {
                                      jwt: jwtData,
                                  }
                                : {}),
                        })
                )
            );
    }

    authorizeUserByJwt(JWT: JWTDto): Observable<UserRecord> {
        // TODO: Add email to JWT information, so we would not ask user service for only email
        return this.getUserInformation(JWT.userId).pipe(
            switchMap((userInfo) => {
                const params = {
                    email: userInfo.emailaddress,
                    password: generate({
                        length: 12,
                        numbers: true,
                        uppercase: false,
                    }),
                    hasEmailValidation: false,
                };
                return UseGetLegacyToken(params).pipe(
                    switchMap((legacyData) => {
                        const jwtData = this.AssembleJWToken(JWT);
                        return this.loadUserData(
                            legacyData.token,
                            jwtData
                        ).pipe(
                            map((user: UserRecord) => user),
                            catchError((error) => {
                                throw error;
                            })
                        );
                    }),
                    catchError((error) => {
                        throw error;
                    })
                );
            }),
            catchError((error) => {
                throw error;
            })
        );
    }

    RenewJWToken(Authorization: string): Observable<JWTokenProps> {
        const url = `${jwtLegacyService()}/jwt/auth/continue`;

        return ajax({
            url: url,
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${Authorization}`,
            },
        }).pipe(
            map((x: AjaxResponse<any>) => this.AssembleJWToken(x.response)),
            catchError((error) => {
                throw error.status;
            })
        );
    }

    resetPasswordData(email: string): Observable<string> {
        return ajax
            .post(
                `${mobileRest()}ws/v1.0/forgotpassword`,
                {
                    Email: email,
                },
                {
                    'Content-Type': 'application/json',
                }
            )
            .pipe(
                map((x: AjaxResponse<any>) => {
                    return x.response.Label;
                }),
                catchError((error) => {
                    throw error;
                })
            );
    }

    logout(): Observable<string> {
        return ajax
            .get(
                `${mobileRest()}ws/logout?`,
                this.apiHeaders.getHeaders({
                    'Content-Type': 'application/json',
                })
            )
            .pipe(
                map((x) => {
                    return 'success';
                }),
                catchError((error) => {
                    throw error;
                })
            );
    }

    setNewPassword(newPassword: string): Observable<string> {
        return ajax
            .post(
                `${mobileRest()}ws/newpassword`,
                {
                    Password: newPassword,
                    Confirm: newPassword,
                },
                this.apiHeaders.getHeaders({
                    'Content-Type': 'application/json',
                })
            )
            .pipe(
                map((x: AjaxResponse<any>) => {
                    return x.response.Label;
                }),
                catchError((error) => {
                    throw error.status;
                })
            );
    }

    updateSession(jsessionId: string, userId: number): Observable<any> {
        return ajax
            .post(
                `${activity()}save`,
                {
                    jsessionId,
                    userId,
                },
                this.apiHeaders.getHeaders({
                    'Content-Type': 'application/json',
                })
            )
            .pipe(
                map((x) => x),
                catchError((error) => {
                    throw error.status;
                })
            );
    }

    closeSession(jsessionId: string, userId: number): Observable<any> {
        return ajax
            .put(
                `${activity()}expire`,
                {
                    jsessionId,
                    userId,
                },
                this.apiHeaders.getHeaders({
                    'Content-Type': 'application/json',
                })
            )
            .pipe(
                map((x) => x),
                catchError((error) => {
                    throw error.status;
                })
            );
    }

    AssembleJWToken(response: JWTDto): JWTokenProps {
        const expiryInMinutes = response.expiryInMinutes;
        const expiryDate = moment().add(expiryInMinutes, 'minutes');
        const renewToken = moment().add(expiryInMinutes / 2, 'minutes'); // Default value is 12h, to have safety refresh token: divide it by 2 (6h)
        return {
            token: response.jwt,
            expiryInMinutes: expiryInMinutes,
            renewDateTime: renewToken,
            expiryDate: expiryDate,
        };
    }

    getUserInformation(userId: string): Observable<IUseRetrieveUser> {
        return ajax
            .get(
                user() + userId,
                this.apiHeaders.getHeaders({
                    'Content-Type': 'application/json',
                })
            )
            .pipe(
                map((x) => {
                    return x.response as IUseRetrieveUser;
                }),
                catchError((error) => {
                    throw error;
                })
            );
    }
}
