import { createReducer } from 'typesafe-actions';
import { Action } from '../../../root-action';
import { ChatMessage, ChatUser } from './chat-data';
import { Map } from 'immutable';
import { combineReducers } from 'redux';
import { clearClassroom } from '../../vcr-actions';

const messages = createReducer(Map<string, ChatMessage>())
    .handleAction(Action.vcr.classroom.chat.addMessage, (state, action) => {
        const keyedData: { [key: string]: ChatMessage } = {
            [action.payload.id]: action.payload,
        };
        return state.merge(Map(keyedData));
    })
    .handleAction(
        [Action.vcr.classroom.chat.clearMessages, clearClassroom],
        (state) => {
            return state.clear();
        }
    );

const users = createReducer(Map<string, ChatUser>())
    .handleAction(
        Action.vcr.classroom.chat.loadUsers.success,
        (state, action) => {
            const keyedData = action.payload.map<[string, ChatUser]>((x) => [
                String(x.id),
                x,
            ]);
            return state.merge(Map(keyedData));
        }
    )
    .handleAction(Action.vcr.classroom.chat.addUser, (state, action) =>
        state.merge(Map({ [action.payload.id]: action.payload }))
    )
    .handleAction(clearClassroom, (state) => state.clear());

const isLoadingUsers = createReducer(false)
    .handleAction(Action.vcr.classroom.chat.loadUsers.request, () => true)
    .handleAction(Action.vcr.classroom.chat.loadUsers.success, () => false)
    .handleAction(Action.vcr.classroom.chat.loadUsers.failure, () => false);

export const user = createReducer<ChatUser | null>(null)
    .handleAction(
        Action.vcr.classroom.chat.loadUser.success,
        (state, action) => action.payload
    )
    .handleAction(
        [Action.vcr.classroom.chat.loadUser.failure, clearClassroom],
        () => null
    );

const isLoadingUser = createReducer(false)
    .handleAction(Action.vcr.classroom.chat.loadUser.request, () => true)
    .handleAction(Action.vcr.classroom.chat.loadUser.success, () => false)
    .handleAction(Action.vcr.classroom.chat.loadUser.failure, () => false);

export const chat = combineReducers({
    messages,
    users,
    isLoadingUsers,
    user,
    isLoadingUser,
});
