import { createReducer } from 'typesafe-actions';
import {
    DisplayStrategyRecord,
    DisplayStrategyCustomRecord,
} from './display-strategy';
import { loadDisplayStrategy } from './display-strategy-actions';
import { combineReducers } from 'redux';

const displayStrategy = createReducer<DisplayStrategyRecord | null>(null)
    .handleAction(loadDisplayStrategy.success, (state, action) => {
        if (action.payload?.displayHandle === 'navigation-restriction') {
            return action.payload;
        } else {
            return state;
        }
    })
    .handleAction(
        [loadDisplayStrategy.failure, loadDisplayStrategy.request],
        () => null
    );

const customDisplay = createReducer<DisplayStrategyCustomRecord | null>(null)
    .handleAction(loadDisplayStrategy.success, (state, action) => {
        if (action.payload?.displayHandle !== 'navigation-restriction') {
            return action.payload;
        } else {
            return state;
        }
    })
    .handleAction(
        [loadDisplayStrategy.failure, loadDisplayStrategy.request],
        () => null
    );

export const displayStrategyReducer = combineReducers({
    navigation: displayStrategy,
    customDisplay: customDisplay,
});
