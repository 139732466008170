import {
    SurveyBusinessType,
    SurveyServiceType,
} from '../features/surveys/types';
import { referenceService, surveyService } from '../services/api-urls';

export interface Routing {
    api: {
        survey: {
            skip: (
                businessType: SurveyBusinessType,
                userUuid: string
            ) => string;
            save: (surveyType: SurveyServiceType) => string;
            getByBusinessId: (
                surveyType: SurveyServiceType,
                userUuid: string,
                businessType: SurveyBusinessType,
                businessId: string
            ) => string;
            isRequired: (userUuid: string) => string;
            surveyState: (userUuid: string) => string;
            options: (
                surveyType: SurveyServiceType,
                businessType: SurveyBusinessType,
                userUuid: string
            ) => string;
            pageOptions: (
                surveyType: SurveyServiceType,
                businessType: SurveyBusinessType
            ) => string;
            getTrainer: (uuid: string) => string;
        };
        contactUs: {
            getContactUsPhone: () => string;
            getContactUsMail: () => string;
        };
    };
}

export const routes: Routing = {
    api: {
        survey: {
            skip: (businessType: SurveyBusinessType, userUuid: string) =>
                `${surveyService()}/${businessType}/skip/${userUuid}`,
            save: (surveyType: SurveyServiceType) =>
                `${surveyService()}/${surveyType}`,
            getByBusinessId: (
                surveyType: SurveyServiceType,
                userUuid: string,
                businessType: SurveyBusinessType,
                businessId: string
            ) =>
                `${surveyService()}/${surveyType}/${userUuid}/${businessType}/${businessId}`,
            isRequired: (userUuid: string) =>
                `${surveyService()}/isRequired/${userUuid}`,
            surveyState: (userUuid: string) =>
                `${surveyService()}/surveyState/${userUuid}`,
            options: (
                surveyType: SurveyServiceType,
                businessType: SurveyBusinessType,
                userUuid: string
            ) =>
                `${surveyService()}/options/${surveyType}/${businessType}/${userUuid}`,
            pageOptions: (
                surveyType: SurveyServiceType,
                businessType: SurveyBusinessType
            ) =>
                `${surveyService()}/page-options/${surveyType}/${businessType}`,
            getTrainer: (uuid) => `/v1.0/user/${uuid}`,
        },
        contactUs: {
            getContactUsPhone: () => `${referenceService()}/contact-us/phone/`,
            getContactUsMail: () => `${referenceService()}/contact-us/email/`,
        },
    },
};
