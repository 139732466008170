import { useGetRequiredSurveysQuery } from '../api';
import { useSelector } from 'react-redux';
import { userSelector } from '../../../store/user/user-selectors';
import {
    CallSurveysAfterDateSelector,
    IsConversationClassSurveySelector,
    IsGroupLessonSurveySelector,
    IsIndividualLessonSurveySelector,
    IsLearnerPortalSurveySelector,
    IsSurveysCallsPausedSelector,
    SurveysCallsCountSelector,
} from '../slice';
import moment from 'moment';

export const usePollingSurveys = () => {
    // Get learner
    const learnerUUID = useSelector(userSelector)?.learnerUUID;
    // Get surveys statuses
    const CC = useSelector(IsConversationClassSurveySelector);
    const GL = useSelector(IsGroupLessonSurveySelector);
    const IL = useSelector(IsIndividualLessonSurveySelector);
    const PS = useSelector(IsLearnerPortalSurveySelector);
    // Get query manipulators
    const isPaused = useSelector(IsSurveysCallsPausedSelector);
    const canCallAfter = useSelector(CallSurveysAfterDateSelector);
    const numberOfCalls = useSelector(SurveysCallsCountSelector);

    const canCall =
        canCallAfter && moment(canCallAfter).isSameOrAfter(moment());
    const skip = isPaused || canCall || numberOfCalls > 9;

    useGetRequiredSurveysQuery(learnerUUID ?? '', {
        pollingInterval: skip ? 0 : numberOfCalls < 5 ? 1000 : 3000,
        skip: skip,
    });

    return {
        isCC: CC,
        isGL: GL,
        isIL: IL,
        isPS: PS,
    };
};
