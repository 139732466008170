import { Axios } from 'axios-observable';
import { catchError, map } from 'rxjs/operators';
import { ApiHeaders } from '../api-headers';
import { learningChannel } from '../api-urls';
import { ResourceListDTO, ResourceListParser } from './resource-list-parser';

export interface IResourceListDTOWithUUID extends ResourceListDTO {
    uuid: string;
}

export interface IChannelsList {
    title: string;
    items: IResourceListDTOWithUUID[];
}

export class ChannelApi {
    private readonly apiHeaders: ApiHeaders;
    private readonly resourceListParser = new ResourceListParser();

    constructor(apiHeaders: ApiHeaders) {
        this.apiHeaders = apiHeaders;
    }

    loadList({ userUUID, topicUUID }: { topicUUID: string; userUUID: string }) {
        return Axios.get<IChannelsList[]>(
            learningChannel() + `${topicUUID}/${userUUID}`
        ).pipe(
            map((x) => {
                const response = x.data;
                const channels = response.map((y) => {
                    const itemList = y.items.map((x) => {
                        const newData = { ...x, contentUUID: x.uuid };
                        return this.resourceListParser.parseListItem(newData);
                    });

                    return {
                        title: y.title,
                        items: itemList,
                    };
                });

                return channels;
            }),
            catchError((error) => {
                throw error.message;
            })
        );
    }

    private getPostHeaders() {
        return {
            ...this.apiHeaders.getHeaders(),
            'Content-Type': 'application/json',
        };
    }
}
