import { Observable, Observer } from 'rxjs';

export default class Geolocation {
    private static geoLocationAvailable: Boolean | null = null;

    static getGeolocationCoordinates(): Observable<GeolocationCoordinates | null> {
        return Observable.create(
            (observer: Observer<GeolocationCoordinates | null>) => {
                //remove coordinates
                /*if(Geolocation.geoLocationAvailable === null || Geolocation.geoLocationAvailable === true){
                if('geolocation' in navigator){
                    navigator.geolocation.getCurrentPosition(
                        (pos) => {Geolocation.geoLocationAvailable = true; observer.next(pos.coords);},
                        () => {Geolocation.geoLocationAvailable = false; observer.next(null);})
                }
                else{
                    Geolocation.geoLocationAvailable = false;
                    observer.next(null);
                }
            }
            else{
                observer.next(null);
            } */
            }
        );
    }
}
