import {
    isResourceContentType,
    ResourceContentType,
} from './resource-content-type';
import { Record } from 'immutable';
import { TagProps } from '../homework/homework';
import { ContentMetadataProps } from './resources-content-record';

export interface ResourceContentProps {
    thumbnail: string;
    administration: {
        approved: true;
        copyright: string;
        createdDate: string;
        displayDate: string;
        license: string;
        modifiedDate: string;
        version: 0;
    };
    content: any;
    contentUUID: string;
    topicId?: string;
    resourceType?: string;
    description: string;
    id: string;
    legacy: {
        articleId: number;
        contentType: ResourceContentType;
        groupId: number;
        groupName?: string;
        id?: number;
        resourcePrimKey?: number;
        structureId?: string;
        tagAssetId?: number;
        tagEntries: TagProps[];
        tags?: number[];
        // TODO: request this fields from backend
        categories: string[] | null;
        medium_image?: string;
    };
    metadata: {
        accessRights: string[];
        gradings: string[];
        groups: string[];
        organisations: number[];
        tags: string[];
        teachingConcept: string[];
        topics: string[];
    };
    contentMetadata: ContentMetadataProps | null;
    title: string;
    status: string | null;
    score: number;
    date: null | string;
    dueDate: string | undefined;
    uuid: string;
    assigner?: {
        firstname: string;
        lastname: string;
        middlename: string;
        type: string;
        uuid: string;
        avatar: string;
    };
    assignmentDate?: string;
}

export const defaultResourceContentProps: ResourceContentProps = {
    thumbnail: '',
    administration: {
        approved: true,
        copyright: '',
        createdDate: '',
        displayDate: '',
        license: '',
        modifiedDate: '',
        version: 0,
    },
    content: {},
    contentUUID: '',
    description: '',
    id: '',
    legacy: {
        articleId: 0,
        contentType: ResourceContentType.Unknown,
        groupId: 0,
        groupName: '',
        id: 0,
        resourcePrimKey: 0,
        structureId: '',
        tagAssetId: 0,
        tagEntries: [],
        tags: [],
        categories: null,
        medium_image: '',
    },
    metadata: {
        accessRights: [],
        gradings: [],
        groups: [],
        organisations: [],
        tags: [],
        teachingConcept: [],
        topics: [],
    },
    contentMetadata: null,
    title: '',
    date: null,
    dueDate: undefined,
    status: null,
    score: 0,
    uuid: '',
    assigner: undefined,
    assignmentDate: undefined,
};

export class ResourceContent
    extends Record(defaultResourceContentProps)
    implements ResourceContentProps {}

export function isResourceContent(item: any): item is ResourceContent {
    const resource: ResourceContent = item as ResourceContent;

    return isResourceContentType(resource?.legacy?.contentType);
}
