/*
 *   Epic to load new test data
 */
import { RootEpic } from './root-epic';
import {
    catchError,
    filter,
    switchMap,
    withLatestFrom,
    concatMap,
} from 'rxjs/operators';
import { isActionOf } from 'typesafe-actions';
import { Action } from '../store/root-action';
import { of } from 'rxjs';
import {
    getStatusLoadingActions,
    modifyResourceStatus,
} from './resources/helpers';
import { Homework } from '../store/homework/homework';

const loadHomeworkEpic: RootEpic = (action$, state$, { homeworkApi }) =>
    action$.pipe(
        filter(isActionOf(Action.homework.loadHomeworkList.request)),
        withLatestFrom(state$),
        switchMap(([x, state]) => {
            const userUUID = state$.value.user.identity?.learnerUUID || '';
            const nextKey = state$.value.homework.nextKey || 0;
            const languageUUID =
                state$.value.learnerProfile.currentLanguage?.languageUUID || '';

            const params = new URLSearchParams({
                dataSource: 'Assignment',
                userId: userUUID,
                topicId: languageUUID,
                nextKey: String(nextKey),
                pageSize: '20',
            });

            return homeworkApi.loadHomeworkList(params).pipe(
                concatMap((loadedResources) => {
                    const inProgressResources = state.resources.inProgress.list;
                    const completedResources = state.resources.completed.list;

                    const actualizedResources =
                        loadedResources.homeworkList.map(
                            modifyResourceStatus<Homework>(
                                inProgressResources,
                                completedResources
                            )
                        );

                    return of(
                        ...getStatusLoadingActions(
                            inProgressResources,
                            completedResources
                        ),
                        Action.homework.loadHomeworkList.success(
                            actualizedResources as Homework[]
                        ),
                        Action.homework.nextKey(loadedResources.nextKey)
                    );
                }),
                catchError((e) =>
                    of(Action.homework.loadHomeworkList.failure(e))
                )
            );
        })
    );

export const homeworkEpics = [loadHomeworkEpic];
