import { createAsyncAction } from 'typesafe-actions';
import { ActivityCategory } from './activity-filters-data';

/*
 *   Action to load activity categories
 */
export const loadActivityCategories = createAsyncAction(
    '@@activity-filters/load-categories/request',
    '@@activity-filters/load-categories/success',
    '@@activity-filters/load-categories/failure'
)<{ languageUUID: string }, ActivityCategory[], Error>();

export const activityFiltersActions = {
    loadActivityCategories,
};
