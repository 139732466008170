import { SurveyBusinessType } from '../types';
import { localization } from '../../../localization';

export const useThankYouText = (
    businessType: SurveyBusinessType,
    rate: number
) => {
    return {
        title:
            businessType === 'LearnerPortal'
                ? localization.performanceSurvey_completionScreen_thankyou_message_first
                : rate <= 3
                ? localization.trainerRatingSurvey_completionScreen_sorry_message_first
                : localization.trainerRatingSurvey_completionScreen_thankyou_message_first,
        subTitle:
            businessType === 'LearnerPortal'
                ? localization.performanceSurvey_completionScreen_thankyou_message_second
                : rate <= 3
                ? localization.trainerRatingSurvey_completionScreen_sorry_message_second
                : localization.trainerRatingSurvey_completionScreen_thankyou_message_second,
    };
};
