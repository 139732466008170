import { ActionType, createReducer } from 'typesafe-actions';
import { List } from 'immutable';
import { videoActions } from './videos-actions';
import { combineReducers } from 'redux';
import { ResourceContent } from '../resource-content';
import { LoadingState, ResourceState } from '../resources';
import { modifyResourceStatus } from '../../../epics/resources/helpers';
import { loadInProgressActivities } from '../in-progress/in-progress-actions';
import { loadCompletedActivities } from '../completed/completed-actions';

export type VideoAction = ActionType<typeof videoActions>;

interface VideosState {
    items: List<ResourceContent>;
    isLoading: boolean;
}

const initialState: VideosState = {
    items: List<ResourceContent>(),
    isLoading: false,
};

const descriptions = createReducer(initialState.items)
    .handleAction(videoActions.loadVideos.success, (state, action) => {
        return state.merge(List(action.payload)) as List<ResourceContent>;
    })
    .handleAction(
        videoActions.clearAllResources,
        (state) => state.clear() as List<ResourceContent>
    )
    .handleAction(loadInProgressActivities.success, (state, action) => {
        const statuses = action.payload;
        return state.map(modifyResourceStatus<ResourceContent>(statuses, null));
    })
    .handleAction(loadCompletedActivities.success, (state, action) => {
        const statuses = action.payload;
        return state.map(modifyResourceStatus<ResourceContent>(null, statuses));
    });

const filtered = createReducer(List<ResourceContent>())
    .handleAction(videoActions.loadFilteredVideos.success, (state, action) => {
        return state.merge(List(action.payload));
    })
    .handleAction(videoActions.resetVideos, (_, action) => action.payload)
    .handleAction(
        [videoActions.clearVideos, videoActions.clearAllResources],
        (state) => state.clear()
    )
    .handleAction(loadInProgressActivities.success, (state, action) => {
        const statuses = action.payload;
        return state.map(modifyResourceStatus<ResourceContent>(statuses, null));
    })
    .handleAction(loadCompletedActivities.success, (state, action) => {
        const statuses = action.payload;
        return state.map(modifyResourceStatus<ResourceContent>(null, statuses));
    });

const loadingState = createReducer('idle' as LoadingState)
    .handleAction(
        [
            videoActions.loadVideos.request,
            videoActions.loadFilteredVideos.request,
        ],
        () => 'pending' as LoadingState
    )
    .handleAction(
        [
            videoActions.loadVideos.success,
            videoActions.loadFilteredVideos.success,
        ],
        () => 'succeeded' as LoadingState
    )
    .handleAction(
        [
            videoActions.loadVideos.failure,
            videoActions.loadFilteredVideos.failure,
        ],
        () => 'failed' as LoadingState
    );

export const videosReducer = () =>
    combineReducers<ResourceState, VideoAction>({
        descriptions,
        filtered,
        loadingState,
    });
