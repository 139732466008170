import { ActionType, createReducer } from 'typesafe-actions';
import { List, Map } from 'immutable';
import { allHowToActions } from '../howtos/howto-actions';
import { combineReducers } from 'redux';
import { ResourceContent } from '../resource-content';
import { ResourceDescription } from '../resources';
import {
    loadResourceDetails,
    loadResourceContent,
    setSelectedCourseItem,
    setNoSizeContent,
    loadResourceItemByCourse,
    loadResourceQuiz,
    setDislayAssets,
    preSetDislayAssets,
    setResourceListByCourse,
    setResourceItemByCourse,
    setResourceContent,
    setResourceDetail,
    setQuiz,
    postSyncQuiz,
    postOfflineSetting,
    ActivityTrackingProps,
    setActivity,
    preSetActivity,
    setConnectivity,
    updateActivity,
    setActiveResourceDisplayed,
} from './offline-actions';

import { TrainingPath } from '../training-path/training-path-record';
import { QuizDescriptionOffline } from '../../../services/quizzes/quiz';
import { Action } from '../../root-action';
import { ResourceContentRecord } from '../resources-content-record';

export type ResourceState = Readonly<{
    displayedCourseList: {
        courseList: Map<string, TrainingPath>;
        resourceList: List<any>;
        downloadedResourceSize: number;
    };
    displayedResourceList: Map<string, ResourceContent>;
    displayedResource: Map<string, ResourceDescription>;
    displayedResourceContent: Map<string, ResourceContentRecord>;
    displayedResourceQuiz: {
        quiz: Map<string, QuizDescriptionOffline>;
    };
    displayedAssets: Map<string, any>;
    activityResourceTracking: Map<string, ActivityTrackingProps>;
    isLoadingDownloadAction: boolean;
    isSyncDownloading: boolean;
    setupState: boolean;
    connectionActive: boolean | null;
    activeResourceDisplayed: any;
}>;

export type AllActionsResource = ActionType<typeof allHowToActions>;

const displayedResourceListReducer = createReducer(
    Map<string, ResourceContent>()
)
    .handleAction(
        [loadResourceItemByCourse.success, setResourceItemByCourse],
        (state, action) => {
            const keyedData = action.payload.map<[string, ResourceContent]>(
                (x: any) => {
                    return [x.id, new ResourceContent(x)];
                }
            );
            return state.merge(Map(keyedData));
        }
    )
    .handleAction(Action.user.logout.success, (state, action) => state.clear());

export const displayedResourceReducer = createReducer(
    Map<string, ResourceDescription>()
)
    .handleAction(
        [loadResourceDetails.success, setResourceDetail],
        (state, { payload }) => state.merge(Map(payload))
    )
    .handleAction(Action.user.logout.success, (state, action) => state.clear());

export const displayedResourceContentReducer = createReducer(
    Map<string, ResourceContentRecord>()
)
    .handleAction(
        [loadResourceContent.success, setResourceContent],
        (state, { payload }) => state.merge(Map(payload))
    )
    .handleAction(Action.user.logout.success, (state, action) => state.clear());

export const displayedAssetsReducer = createReducer(Map<string, any>())
    .handleAction(
        [setDislayAssets, preSetDislayAssets],
        (state, { payload }) => {
            return state.merge(Map(payload));
        }
    )
    .handleAction(Action.user.logout.success, (state, action) => state.clear());

export const loaderStateReducer = createReducer(false)
    .handleAction(setSelectedCourseItem, (state, { payload }) => true)
    .handleAction(Action.user.logout.success, (state, action) => false);

//Quiz
export const displayedResourceQuizReducer = createReducer(
    Map<string, QuizDescriptionOffline>()
)
    .handleAction(
        [loadResourceQuiz.success, postSyncQuiz.success, setQuiz],
        (state, { payload }) => state.merge(Map(payload))
    )
    .handleAction(Action.user.logout.success, (state, action) => state.clear());

const quizzesReducer = () =>
    combineReducers({
        quiz: displayedResourceQuizReducer,
    });
//end quiz

// courses
const displayedCourseListReducer = createReducer(Map<string, TrainingPath>())
    .handleAction(setSelectedCourseItem, (state, { payload }) => {
        return state.merge(Map(payload));
    })
    .handleAction(postOfflineSetting.success, (state, { payload }) => {
        return state.clear();
    })
    .handleAction(Action.user.logout.success, (state, action) => state.clear())
    .handleAction(
        Action.offline.loadResourceItemByCourse.failure,
        (state, { payload }) => {
            const { contentUuid } = payload;
            return state.remove(contentUuid);
        }
    );

const displayedResourceListByCourseReducer = createReducer(List<any>())
    .handleAction(setResourceListByCourse, (state, { payload }) => {
        return state.merge(List(payload));
    })
    .handleAction(Action.user.logout.success, (state, action) => state.clear());

const downloadedResourceSizeReducer = createReducer<number>(0)
    .handleAction(loadResourceQuiz.success, (state, { payload }) => {
        return payload.size;
    })
    .handleAction(Action.user.logout.success, (state, action) => 0);

const coursesReducer = () =>
    combineReducers<any, any>({
        courseList: displayedCourseListReducer,
        resourceList: displayedResourceListByCourseReducer,
        downloadedResourceSize: downloadedResourceSizeReducer,
    });
// end courses

export const isLoadingDownloadActionReducer = createReducer(false)
    .handleAction([setSelectedCourseItem], () => true)
    .handleAction(
        [
            setDislayAssets,
            preSetDislayAssets,
            loadResourceQuiz.failure,
            loadResourceDetails.failure,
            loadResourceContent.failure,
            loadResourceItemByCourse.failure,
            setNoSizeContent,
        ],
        () => {
            return false;
        }
    )
    .handleAction(Action.user.logout.success, (state, action) => false);

const activityResourceTrackingReducer = createReducer(
    Map<string, ActivityTrackingProps>()
)
    .handleAction(
        [setActivity, preSetActivity, updateActivity],
        (state, { payload }) => {
            return state.merge(Map(payload));
        }
    )
    .handleAction(Action.user.logout.success, (state, action) => state.clear());

export const isSyncDownloadingReducer = createReducer(false)
    .handleAction([postSyncQuiz.request], () => true)
    .handleAction([postSyncQuiz.success, postSyncQuiz.failure], () => {
        return false;
    })
    .handleAction(Action.user.logout.success, (state, action) => false);

export const setupStateActivityReducer = createReducer(false).handleAction(
    [preSetActivity],
    () => {
        return true;
    }
);

export const connectionActiveReducer = createReducer<boolean | null>(null)
    .handleAction(setConnectivity, (state, action) => {
        return action.payload;
    })
    .handleAction(Action.user.login.success, (state, action) => true);

export const activeResourceDisplayedReducer = createReducer<any | null>(null)
    .handleAction(setActiveResourceDisplayed, (state, action) => {
        return action.payload;
    })
    .handleAction(Action.user.logout.success, (state, action) => null);

export const offlineReducer = () =>
    combineReducers<ResourceState, AllActionsResource>({
        displayedCourseList: coursesReducer(),
        displayedResourceList: displayedResourceListReducer,
        displayedResource: displayedResourceReducer,
        displayedResourceContent: displayedResourceContentReducer,
        displayedResourceQuiz: quizzesReducer(),
        displayedAssets: displayedAssetsReducer,
        activityResourceTracking: activityResourceTrackingReducer,
        isLoadingDownloadAction: isLoadingDownloadActionReducer,
        isSyncDownloading: isSyncDownloadingReducer,
        setupState: setupStateActivityReducer,
        connectionActive: connectionActiveReducer,
        activeResourceDisplayed: activeResourceDisplayedReducer,
    });
