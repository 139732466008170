import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { getCurrentUILanguage } from '../../store/user/user-selectors';

import './localization-text.css';

export interface LocalizationTextProps {
    text?: string;
    asHtml?: boolean;
}

export const LocalizationText: FC<LocalizationTextProps> = (
    props: LocalizationTextProps
) => {
    const currentLanguage = useSelector(getCurrentUILanguage);
    const languageId = currentLanguage?.id;
    let displayedText = props.text || '';

    return (
        <>
            {!props?.asHtml ? (
                <span className={'language_' + languageId} lang={languageId}>
                    {displayedText}
                </span>
            ) : (
                <span
                    className={'language_' + languageId}
                    lang={languageId}
                    dangerouslySetInnerHTML={{
                        __html: props?.text ? props?.text : '',
                    }}
                ></span>
            )}
        </>
    );
};
