import { RootEpic } from './root-epic';
import { Action } from '../store/root-action';
import { isActionOf } from 'typesafe-actions';
import { of, Observable, forkJoin } from 'rxjs';
import {
    filter,
    switchMap,
    map,
    catchError,
    withLatestFrom,
    exhaustMap,
    concatMap,
    takeUntil,
} from 'rxjs/operators';
import {
    CreditRecord,
    Foresen,
    MediaRecord,
    SchedulerLanguageProps,
} from '../store/scheduler/scheduler-data';

import { RootAction } from 'typesafe-actions';
import { localization } from '../localization';

const loggedOut = (action$: Observable<RootAction>) =>
    action$.pipe(filter(isActionOf(Action.user.logout.request)));

const onOpenEpic: RootEpic = (action$, state$, _) => {
    return action$.pipe(filter(isActionOf(Action.lessonsList.opened))).pipe(
        exhaustMap(() => {
            let learnerID = String(
                state$.value.user.identity?.learnerExternalId
            );
            return of(
                Action.lessonsList.initializeLessonsList.request({
                    learnerID: learnerID,
                })
            );
        })
    );
};

const onSettingsOpenEpic: RootEpic = (action$, state$, _) => {
    return action$
        .pipe(filter(isActionOf(Action.lessonsList.settingsOpen)))
        .pipe(
            switchMap(() => {
                let learnerID = String(
                    state$.value.user.identity?.learnerExternalId
                );
                return of(
                    Action.lessonsList.initializeLessonsList.request({
                        learnerID: learnerID,
                    })
                );
            })
        );
};

const onBookingEpic: RootEpic = (action$, state$, { schedulerApi }) => {
    return action$
        .pipe(
            filter(
                isActionOf([Action.lessonsList.confirmedCancelLesson.success])
            )
        )
        .pipe(
            withLatestFrom(state$),
            map(([r, state]) => {
                let learner: any = state.lessonsList.learner;
                let learnerId: any = learner
                    ? learner.LearnerID
                    : state.user.identity?.learnerExternalId;

                return Action.lessonsList.initLessons.request({
                    learnerId: String(learnerId),
                    startTime: state.lessonsList.selectedDate.format(
                        'YYYY-MM-DDT00:00:00'
                    ),
                });
            })
        );
};

const cancelMessageSuccess: RootEpic = (action$, state$, _) => {
    return action$.pipe(
        filter(isActionOf(Action.lessonsList.confirmedCancelLesson.success)),
        map(() =>
            Action.contentSavedActions.setNotification({
                show: true,
                error: false,
                maximum: false,
                text: localization.scheduler_message_LessonSuccessfullyCanceled,
                key: 11,
            })
        )
    );
};

const cancelMessageFailure: RootEpic = (action$, state$, _) => {
    return action$.pipe(
        filter(isActionOf(Action.lessonsList.confirmedCancelLesson.failure)),
        map(({ payload }) => {
            let payloadWithResponse: any = payload;
            let errorLabel: string =
                payloadWithResponse?.response?.Label || 'generic_error';
            return errorLabel;
        }),
        map((errorLabel) => {
            let message = (() =>
                localization.scheduler_message_CantCancelLesson)();
            if (errorLabel === 'pastlesson') {
                message = (() =>
                    localization.scheduler_message_CantCancelPastLesson)();
            }
            return Action.contentSavedActions.setNotification({
                show: true,
                error: true,
                maximum: false,
                text: message,
                key: 12,
            });
        })
    );
};

const initializeLessonsListEpic: RootEpic = (
    action$,
    state$,
    { schedulerApi }
) => {
    return action$
        .pipe(
            filter(isActionOf(Action.lessonsList.initializeLessonsList.request))
        )
        .pipe(
            switchMap((r) => {
                return schedulerApi
                    .getInitialize({
                        learnerId: String(r.payload.learnerID),
                    })
                    .pipe(
                        map((response) => {
                            response.sat = !!localStorage.getItem('SAT_NEW');
                            return Action.lessonsList.initializeLessonsList.success(
                                response
                            );
                        }),
                        catchError((e) =>
                            of(
                                Action.lessonsList.initializeLessonsList.failure(
                                    e
                                )
                            )
                        )
                    );
            })
        );
};

const changeLessonListDateEpic: RootEpic = (action$, state$, _) => {
    return action$
        .pipe(filter(isActionOf(Action.lessonsList.selectLessonsListDate)))
        .pipe(
            withLatestFrom(state$),
            filter(([action, state]) => !state.user.isLoggingOut),
            concatMap(([action, state]) => {
                const date = action.payload;
                const learnerID = String(
                    state$.value.user.identity?.learnerExternalId
                );

                return of(
                    Action.lessonsList.initializeLessonsList.request({
                        learnerID: learnerID,
                        selectedDate: date,
                    }),
                    Action.lessonsList.loadInitWeekLessons.request({
                        startDate: date,
                        learnerId: learnerID,
                    })
                );
            })
        );
};

const initLessonsEpic: RootEpic = (action$, state$, { schedulerApi }) => {
    return action$
        .pipe(filter(isActionOf(Action.lessonsList.initLessons.request)))
        .pipe(
            switchMap((r) => {
                const learnerUUID = state$.value.user?.identity
                    ?.learnerUUID as string;

                const getLearnerEvents$ = schedulerApi
                    .getLearnerEvents(learnerUUID)
                    .pipe(map((x) => x));

                const getInitLesson$ = schedulerApi
                    .getInitLessons({
                        learnerID: r.payload.learnerId,
                        startTime: r.payload.startTime,
                    })
                    .pipe(map((response) => response));

                const result$ = forkJoin({
                    getLearnerEvents$,
                    getInitLesson$,
                }).pipe(
                    map((response) => {
                        const addedResponse = {
                            ...response.getInitLesson$,
                            groupLessonAvail: response.getLearnerEvents$,
                        };

                        return Action.lessonsList.initLessons.success(
                            addedResponse
                        );
                    }),
                    catchError((e) =>
                        of(Action.lessonsList.initLessons.failure(e))
                    )
                );

                return result$;
            })
        );
};

const cancelLessonEpic: RootEpic = (action$, state$, { schedulerApi }) => {
    return action$
        .pipe(filter(isActionOf(Action.lessonsList.cancelLesson.request)))
        .pipe(
            switchMap((r) => {
                const learner = state$.value.user?.identity?.learnerExternalId;
                return schedulerApi
                    .cancelLesson({
                        lessonID: String(r.payload.lessonId),
                        learnerID: String(learner),
                    })
                    .pipe(
                        withLatestFrom(state$),
                        map(([response, state]) => {
                            return Action.lessonsList.cancelLesson.success({
                                cancellation: state.lessonsList.cancellation
                                    .set(
                                        'credit',
                                        new CreditRecord({
                                            name: response.Credit.Name,
                                            creditId: response.Credit.CreditID,
                                            expiration:
                                                response.Credit.Expiration,
                                            creditLeft:
                                                response.Credit.CreditLeft,
                                            duration: response.Credit.Duration,
                                        })
                                    )
                                    .set('label', response.Label),
                            });
                        }),
                        catchError((e) =>
                            of(Action.lessonsList.cancelLesson.failure(e))
                        ),
                        takeUntil(loggedOut(action$))
                    );
            })
        );
};

const trulyCancelLessonEpic: RootEpic = (action$, state$, { schedulerApi }) => {
    return action$
        .pipe(
            filter(isActionOf(Action.lessonsList.confirmedCancelLesson.request))
        )
        .pipe(
            switchMap((r) => {
                const learner: any = state$.value.lessonsList.learner;
                let learnerId: any = learner
                    ? learner.LearnerID
                    : state$.value.user.identity?.learnerExternalId;

                return schedulerApi
                    .cancelLesson({
                        lessonID: String(r.payload.lessonId),
                        learnerID: learnerId,
                        confirmCancel: 'true',
                    })
                    .pipe(
                        map((response) => {
                            return Action.lessonsList.confirmedCancelLesson.success(
                                response
                            );
                        }),
                        catchError((e) => {
                            console.error(e);
                            return of(
                                Action.lessonsList.confirmedCancelLesson.failure(
                                    e
                                )
                            );
                        }),
                        takeUntil(loggedOut(action$))
                    );
            })
        );
};

const deselectOnBookingOrCancelEpic: RootEpic = (action$, state$, helpers) => {
    return action$.pipe(
        filter(
            isActionOf([
                Action.lessonsList.confirmedCancelLesson.success,
                Action.lessonsList.confirmedCancelLesson.failure,
            ])
        ),
        map(() => Action.lessonsList.setSelectedAvailability(null))
    );
};

const uiEpic: RootEpic = (action$, state$, helpers) => {
    return action$
        .pipe(
            filter(isActionOf([Action.lessonsList.initializeLessonsListEnded]))
        )
        .pipe(
            map((_) => {
                let lessonsListState = state$.value.lessonsList;
                let learnerId = String(
                    state$.value.user.identity?.learnerExternalId
                );
                let startDate = lessonsListState.selectedDate;

                return Action.lessonsList.initLessons.request({
                    learnerId: learnerId,
                    startTime: startDate.format('YYYY-MM-DDT00:00:00'),
                });
            })
        );
};

const loadForesenEpic: RootEpic = (action$, state$, { schedulerApi }) => {
    return action$
        .pipe(filter(isActionOf(Action.lessonsList.loadForesen.request)))
        .pipe(
            switchMap((r) => {
                const learner = state$.value.user.identity?.learnerExternalId;
                return schedulerApi.getForeseen(learner as any).pipe(
                    map((response) => {
                        return Action.lessonsList.loadForesen.success(
                            new Foresen(response)
                        );
                    }),
                    catchError((e) =>
                        of(Action.lessonsList.loadForesen.failure(e))
                    ),
                    takeUntil(loggedOut(action$))
                );
            })
        );
};

const InitializeLessonSettingsEpic: RootEpic = (action$, state$) =>
    action$.pipe(
        filter(isActionOf(Action.lessonsList.initializeLessonsList.success)),
        switchMap(({ payload }) => {
            const stateMedia = state$.value.lessonsSettings.currentMedia;
            const stateCredit = state$.value.lessonsSettings.currentCredit;
            const stateDuration = state$.value.lessonsSettings.currentDuration;

            let currentMedia = stateMedia;
            let currentCredit: CreditRecord = payload.credits.first();
            let currentDuration = String(currentCredit?.duration[0]);

            if (!stateMedia || stateMedia?.mediaID === 0) {
                const authorizedMedia = payload.learner.LearnerAuthorizedMedia;
                const defaultMedia = payload.learner.LearnerDefaultMedia;

                const defaultMediaInList = authorizedMedia.find((item: any) => {
                    return item.name === defaultMedia.MediaType;
                }) as MediaRecord;

                if (defaultMediaInList) {
                    currentMedia = payload.medias.find(
                        (item) => item.mediaID === defaultMedia.MediaID
                    ) as MediaRecord;
                    if (!currentMedia) {
                        currentMedia = new MediaRecord({
                            userValue: defaultMedia.UserValue,
                            mediaType: defaultMedia.MediaType,
                            mediaID: defaultMedia.MediaId,
                            default: defaultMedia.Default,
                        });
                    }
                } else {
                    let dataTest: any = [];
                    payload.medias.forEach((x) => dataTest.push(x));

                    const uniqueObjArray = [
                        ...new Map(
                            dataTest.map((item: any) => [
                                item['mediaType'],
                                item,
                            ])
                        ).values(),
                    ];
                    const teams = uniqueObjArray.find((x: any) => {
                        return x.mediaType === 'Teams';
                    }) as MediaRecord;

                    const sequence: any = [];
                    authorizedMedia.forEach((y: any, i: any) => {
                        if (teams) {
                            if (y.name === 'Teams') {
                                sequence.push({ ...y, index: 0 });
                            } else {
                                const teamsSequence = sequence.find(
                                    (x: any) => {
                                        return x.name === 'Teams';
                                    }
                                ) as MediaRecord;
                                sequence.push({
                                    ...y,
                                    index: teamsSequence ? i : i + 1,
                                });
                            }
                        } else {
                            sequence.push({ ...y, index: i });
                        }
                    });

                    const listSortSequence = sequence.sort(
                        (a: any, b: any) => a.index - b.index
                    );
                    listSortSequence.every((t: any) => {
                        const mediaItem = uniqueObjArray.find((x: any) => {
                            return x.mediaType === t.name;
                        }) as MediaRecord;

                        if (mediaItem) {
                            currentMedia = new MediaRecord(mediaItem);
                            return false;
                        }
                        return true;
                    });

                    if (!currentMedia) {
                        currentMedia = new MediaRecord({
                            userValue: defaultMedia.UserValue,
                            mediaType: defaultMedia.MediaType,
                            mediaID: defaultMedia.MediaId,
                            default: defaultMedia.Default,
                        });
                    }
                }
            }

            if (stateCredit) {
                const creditId = stateCredit.creditId;
                currentCredit = payload.credits.find(
                    (item) => item.creditId === creditId
                ) as CreditRecord;
                currentDuration = stateDuration as string;
            }

            return of(
                Action.lessonsList.setCurrentMedia(currentMedia as MediaRecord),
                Action.lessonsList.setCurrentCredit(
                    currentCredit ? currentCredit : null
                ),
                Action.lessonsList.setCurrentDuration(
                    currentDuration as string
                ),
                Action.lessonsList.initializeLessonsListEnded()
            );
        }),
        catchError((e) => {
            console.error('InitializeLessonSettingsEpic error: ', e);
            return of(Action.lessonsList.initializeLessonsList.failure(e));
        })
    );

const initLanguageEpic: RootEpic = (action$, state$, { schedulerApi }) => {
    return action$
        .pipe(filter(isActionOf(Action.lessonsList.initLanguage.request)))
        .pipe(
            switchMap((r) => {
                return schedulerApi.getLanguage(r.payload.learnerId).pipe(
                    map((response) => {
                        return Action.lessonsList.initLanguage.success(
                            new SchedulerLanguageProps({
                                learnerName: response.Learner.LearnerName,
                                translations: response.Translations,
                            })
                        );
                    }),
                    catchError((e) =>
                        of(Action.lessonsList.initLanguage.failure(e))
                    )
                );
            })
        );
};

const loadForeseenLessonsForNextMonth: RootEpic = (action$, state$, _) => {
    return action$
        .pipe(filter(isActionOf(Action.lessonsList.selectMonthDate)))
        .pipe(
            switchMap((action) => {
                let learnerID = String(
                    state$.value.user.identity?.learnerExternalId
                );
                return of(
                    Action.lessonsList.initLessons.request({
                        startTime: action.payload.format('YYYY-MM-DDT00:00:00'),
                        learnerId: learnerID,
                    })
                );
            })
        );
};

export const lessonsListEpics = [
    initializeLessonsListEpic,
    uiEpic,
    onOpenEpic,
    onBookingEpic,
    cancelLessonEpic,
    trulyCancelLessonEpic,
    initLessonsEpic,
    cancelMessageSuccess,
    cancelMessageFailure,
    loadForesenEpic,
    deselectOnBookingOrCancelEpic,
    onSettingsOpenEpic,
    InitializeLessonSettingsEpic,
    changeLessonListDateEpic,
    initLanguageEpic,
    loadForeseenLessonsForNextMonth,
];
