import { RootState } from 'typesafe-actions';
import { createSelector } from 'reselect';

export const gradingAllSelector = (state: RootState) => state.grading.list;
export const gradingGoCERFSelector = (state: RootState) => state.grading.goCEFR;

export const gradingCEFRSelector = createSelector(
    gradingAllSelector,
    (grading) => grading.filter((l) => l.type === 'CEFR')
);
