import { RootEpic } from './root-epic';
import { of } from 'rxjs';
import {
    catchError,
    distinctUntilChanged,
    filter,
    ignoreElements,
    map,
    switchMap,
    tap,
    withLatestFrom,
} from 'rxjs/operators';
import { isActionOf } from 'typesafe-actions';
import { Action } from '../store/root-action';
import { QuizzesApi } from '../services/quizzes-api';
import { HomeworkApi } from '../services/homework-api';

import {
    currentQuestion,
    currentQuizAttempt,
    currentQuizSelector,
    hasNextQuestion,
} from '../store/quiz/quiz-selectors';

import { QuizIdentifier } from '../services/quizzes/quiz';
import { ContentActivityEventType } from '../services/content-activity-tracking/content-activity-event-type';
import { IContentActivityTrackingService } from '../services/content-activity-tracking-service';

import { QuestionArchetype } from '../services/quizzes/question';
import { isStandaloneQuiz } from '../store/resources/standalone-quiz/standalone-quiz';

const {
    loadQuiz,
    commitedAnswer,
    saveSpeechRecognitionAnswer,
    displayGenericError,
    commitedSpeechRecognitionAnswer,
    gotNewQuizResultIdentifier,
    finishedQuestion,
    reachedQuizEnd,
    finishedQuizAttempt,
} = Action.quizzes;

/**
 * Load quiz description from the server.
 * @param action$ ActionObservable
 * @param state$ StateObservable
 * @param dependencies Epic dependencies
 */

export const loadQuizEpic: RootEpic = (
    action$,
    state$,
    { quizzesApi }: { quizzesApi: QuizzesApi }
) => {
    return action$.pipe(
        filter(isActionOf(loadQuiz.request)),
        withLatestFrom(state$),
        switchMap(
            ([
                {
                    payload: { qArticleId, articleId, groupId },
                },
                state,
            ]) =>
                quizzesApi
                    .loadQuizDescription({
                        qArticleId: Number(qArticleId),
                        articleId: Number(articleId),
                        learnerId: state.user.identity?.learnerId,
                        groupId: groupId,
                    })
                    .pipe(
                        map((response) => {
                            return loadQuiz.success(response);
                        }),
                        catchError((err) => of(loadQuiz.failure(err)))
                    )
        )
    );
};

/**
 * Check Speech recognition result.
 * @param action$ ActionObservable
 * @param state$ StateObservable
 * @param dependencies Epic dependencies
 */
export const checkSpeechRecognitionAnswerEpic: RootEpic = (
    action$,
    state$,
    { speechToTextApi }
) => {
    return action$.pipe(
        filter(isActionOf(commitedSpeechRecognitionAnswer)),
        withLatestFrom(state$),
        switchMap(([_, state]) => {
            const question = currentQuestion(state);
            if (
                question &&
                question?.archetype === QuestionArchetype.SpeechRecognition
            ) {
                const topicId =
                    state.learnerProfile?.currentLanguage?.languageUUID;
                let attempt = currentQuizAttempt(state);
                let questionNumber = attempt.currentQuestionNumber;
                let formData = attempt.suppliedAnswers.get(questionNumber - 1)
                    ?.answer as FormData;

                if (formData && typeof topicId === 'string') {
                    if (formData.get('topic')) {
                        formData.set('topic', topicId);
                    } else {
                        formData.append('topic', topicId);
                    }
                    return speechToTextApi
                        .getSpeechRecognitionResult(formData)
                        .pipe(
                            map((data) => ({ data, question, questionNumber })),
                            catchError(() => {
                                return of('internal error');
                            })
                        );
                } else return 'internal error';
            } else return 'internal error';
        }),
        map((result) => {
            if (typeof result === 'string') {
                return displayGenericError(true);
            } else {
                const { data, question, questionNumber } = result;
                return saveSpeechRecognitionAnswer(
                    data,
                    questionNumber,
                    question
                );
            }
        })
    );
};

/**
 * Check Speech recognition result.
 * @param action$ ActionObservable
 * @param state$ StateObservable
 * @param dependencies Epic dependencies
 */
export const saveSpeechRecognitionAnswerEpic: RootEpic = (
    action$,
    state$,
    { quizzesApi }
) => {
    return action$.pipe(
        filter(isActionOf(saveSpeechRecognitionAnswer)),
        withLatestFrom(state$),
        switchMap(([_, state]) => {
            let quiz = currentQuizSelector(state);
            let learnerId = state.user.identity?.learnerId;
            let identifier: QuizIdentifier | undefined = quiz?.identifier();
            let attempt = currentQuizAttempt(state);

            if (identifier && quiz && learnerId) {
                identifier.learnerId = learnerId;
                return quizzesApi.saveQuizProgress(identifier, attempt);
            } else {
                return of(0);
            }
        }),
        distinctUntilChanged(),
        map((qri) => {
            return gotNewQuizResultIdentifier(qri);
        })
    );
};
/**
 * Save curent quiz attempt state to the server.
 * @param action$ ActionObservable
 * @param state$ StateObservable
 * @param dependencies Epic dependencies
 */
export const saveProgressQuiz: RootEpic = (
    action$,
    state$,
    { quizzesApi }: { quizzesApi: QuizzesApi }
) => {
    return action$.pipe(
        filter(isActionOf(commitedAnswer)),
        withLatestFrom(state$),
        switchMap(([_, state]) => {
            let quiz = currentQuizSelector(state);
            let learnerId = state.user.identity?.learnerId;
            let identifier: QuizIdentifier | undefined = quiz?.identifier();
            let attempt = currentQuizAttempt(state);

            if (navigator.onLine === true) {
                if (identifier && quiz && learnerId) {
                    identifier.learnerId = learnerId;
                    return quizzesApi.saveQuizProgress(identifier, attempt);
                } else {
                    return of(0);
                }
            } else {
                return of(attempt.qri);
            }
        }),
        distinctUntilChanged(),
        map((qri) => {
            return gotNewQuizResultIdentifier(qri);
        })
    );
};

/**
 * Emit reachedQuizEnd after the last question was finished.
 * @param action$ ActionObservable
 * @param state$ StateObservable
 */
export const quizQuestionFlow: RootEpic = (action$, state$, _) => {
    return action$.pipe(
        filter(isActionOf(finishedQuestion)),
        withLatestFrom(state$),
        filter(([action, state]) => {
            return !hasNextQuestion(state);
        }),
        switchMap(([action, state]) => {
            return of(reachedQuizEnd());
        })
    );
};

const quizQuestionSendQuizScore: RootEpic = (
    action$,
    state$,
    { contractApi }
) =>
    state$.pipe(
        map((x) => x.quizzes),
        filter((y) => {
            const questions = y.quiz && y.quiz.questions.length;
            const answer = y.quizAttempt && y.quizAttempt.suppliedAnswers.size;
            const status = y.quizAttempt && y.quizAttempt.status;
            return (
                questions === answer &&
                (status === '' || status === 'UNFINISHED')
            ); //P2-2785: Need to finish 'UNFINISHED' quizzes
        }),
        distinctUntilChanged(),
        switchMap((x) => {
            const questions = x?.quiz?.questions.length;
            const answer = x?.quizAttempt?.suppliedAnswers.size;
            if (
                x?.quizAttempt?.currentMode === 'filling-in' &&
                questions === answer
            ) {
                return of(finishedQuizAttempt());
            } else {
                return of();
            }
        })
    );

const contentActivityTrackingEpic: RootEpic = (
    action$,
    state$,
    { contentActivityTrackingService }
) =>
    action$.pipe(
        filter(isActionOf(Action.contentActivityTracking.trackContentActivity)),
        map((x) => {
            const articleId =
                state$.value.resources?.displayedResource?.resourceId;
            const topicId =
                state$.value.resources?.displayedResourceContent?.metadata
                    ?.topics[0];

            const curriculumId = state$.value.trainingPath.courseLegacyId?.uuid;

            if (topicId) {
                contentActivityTrackingService.trackActivity(
                    topicId,
                    x.payload.contentId,
                    x.payload.userId,
                    x.payload.type,
                    x.payload.quizId,
                    x.payload.score,
                    articleId,
                    curriculumId
                );
            }
        }),
        ignoreElements()
    );

/**
 * Call progressApi when quiz is finished in filling-mode.
 * @param action$ ActionObservable
 * @param state$ StateObservable
 * @param dependencies Epic deps
 */
export const progressCallEpic: RootEpic = (
    action$,
    state$,
    {
        quizzesApi,
        contentActivityTrackingService,
    }: {
        quizzesApi: QuizzesApi;
        contentActivityTrackingService: IContentActivityTrackingService;
    }
) => {
    return action$.pipe(
        filter(isActionOf(finishedQuizAttempt)),
        switchMap(() => {
            const userId = state$.value.user?.identity?.learnerUUID;
            const resource = state$.value.resources?.displayedResource;
            const contentId = resource?.contentUUId;

            const attempt = state$.value.quizzes?.quizAttempt;
            const topicId =
                state$.value.resources?.displayedResourceContent?.metadata
                    ?.topics[0];

            if (contentId && userId && attempt && topicId && resource) {
                const quizId = isStandaloneQuiz(resource)
                    ? contentId
                    : resource?.quizUUID;

                const score =
                    attempt.correctAnswers.filter((v: boolean) => v).size /
                    attempt.questionsCount;

                const articleId =
                    state$.value.resources?.displayedResource?.resourceId;

                const curriculumId =
                    state$.value.trainingPath.courseLegacyId?.uuid;

                contentActivityTrackingService.trackActivity(
                    topicId,
                    contentId,
                    userId,
                    ContentActivityEventType.QuizScore,
                    quizId,
                    score,
                    articleId,
                    curriculumId
                );
            }
            return quizzesApi.callProgressApi();
        }),
        ignoreElements()
    );
};

/**
 * Call progressApi when quiz is finished in filling-mode.
 * @param action$ ActionObservable
 * @param state$ StateObservable
 * @param dependencies Epic deps
 */
export const finishHomeworkCallEpic: RootEpic = (
    action$,
    state$,
    {
        homeworkApi,
    }: {
        homeworkApi: HomeworkApi;
    }
) => {
    return action$.pipe(
        filter(isActionOf(finishedQuizAttempt)),
        switchMap(() => {
            const userId = state$.value.user?.identity?.learnerUUID;
            const resource = state$.value.resources?.displayedResource;
            const contentId = resource?.contentUUId;
            const topicId =
                state$.value.resources?.displayedResourceContent?.metadata
                    ?.topics[0];

            let data = {
                userId: userId,
                topicId: topicId,
                contentId: contentId,
                dataSource: 'Assignment',
            };

            if (navigator.onLine === true) {
                return homeworkApi.finishHomework(data);
            } else {
                return of(0);
            }
        }),
        ignoreElements()
    );
};

const trackResourceContentProgressEpic: RootEpic = (
    _,
    state$,
    { contentActivityTrackingService }
) =>
    state$.pipe(
        map((x) => x.resources.resourceContentProgress),
        distinctUntilChanged(),
        tap((x) => {
            const userId = state$.value.user?.identity?.learnerUUID;
            const resource = state$.value.resources?.displayedResource;
            const contentId = resource?.contentUUId;

            if (contentId && userId && resource) {
                const articleId =
                    state$.value.resources?.displayedResource?.resourceId;
                const topicId =
                    state$.value.resources?.displayedResourceContent?.metadata
                        ?.topics[0];

                const quizId = isStandaloneQuiz(resource)
                    ? contentId
                    : resource?.quizUUID;
                const curriculumId =
                    state$.value.trainingPath.courseLegacyId?.uuid;

                if (topicId) {
                    contentActivityTrackingService.trackActivity(
                        topicId,
                        contentId,
                        userId,
                        ContentActivityEventType.ContentProgression,
                        quizId,
                        x,
                        articleId,
                        curriculumId
                    );
                }
            }
        }),
        ignoreElements()
    );

const trackResourceContentProgressByQuizEpic: RootEpic = (_, state$) =>
    state$.pipe(
        map((x) => x.quizzes?.quizAttempt?.currentQuestionNumber),
        filter((n) => n > 1),
        distinctUntilChanged(),
        map((x) => {
            const attempt = state$.value.quizzes.quizAttempt;
            const currentProgress =
                state$.value.resources.resourceContentProgress;
            let newProgress =
                currentProgress +
                ((1 - currentProgress) * x) / attempt.questionsCount;
            newProgress = newProgress > 1 ? 1 : newProgress; // cannot be more than 1
            return Action.resources.changeResourceContentProgress({
                score: newProgress,
            });
        })
    );

export const quizzesEpics = [
    checkSpeechRecognitionAnswerEpic,
    saveSpeechRecognitionAnswerEpic,
    loadQuizEpic,
    quizQuestionFlow,
    saveProgressQuiz,
    progressCallEpic,
    contentActivityTrackingEpic,
    trackResourceContentProgressEpic,
    trackResourceContentProgressByQuizEpic,
    finishHomeworkCallEpic,
    quizQuestionSendQuizScore,
];
