import { RootEpic } from './root-epic';
import { concat, forkJoin, of } from 'rxjs';
import { catchError, filter, map, mergeMap, switchMap } from 'rxjs/operators';
import { isActionOf } from 'typesafe-actions';
import {
    loadIndustryList,
    loadJobFunctionList,
    loadLanguageList,
    loadLearnerProfile,
    saveIndustryProfile,
    saveJobFunctionProfile,
    saveLanguageToProfile,
    setLearnerIndustry,
    setLearnerJobFunction,
    setLearnerLanguage,
    setLearnerProfile,
    updateIndustryProfile,
    updateJobFunctionProfile,
    updateLearnerProfile,
} from '../store/learner-profile/learner-profile-action';
import {
    IndustryCurrentRecord,
    IndustryProps,
    JobFunctionProps,
    JobFunctionRecord,
    LanguageProps,
    LanguageRecord,
    LearnerProfileProps,
    LearnerProfileRecord,
} from '../store/learner-profile/learner-profile-records';

import {
    setOnboardingCurrentDisplayStrategy,
    setOnboardingProgressState,
} from './onboarding';

import {
    loadLearnerDisplayStrategy,
    loadProgressStateOnboarding,
    setLearnerDisplayStrategy,
} from '../store/learner-onboarding/learner-onboarding-action';

import {
    loadModuleRestriction,
    setModuleRestricted,
} from '../store/module-restriction/module-restriction-action';

import {
    OnboardingDisplayStrategyProps,
    OnboardingDisplayStrategyRecord,
} from '../store/learner-onboarding/learner-onboarding-records';

import {
    ModuleRestrictionDisplayProps,
    ModuleRestrictionDisplayRecord,
} from '../store/module-restriction/module-restriction-records';

import { userActions } from '../store/user/user-actions';
import { Action } from '../store/root-action';
import { localization } from '../localization';

const loadLearnerProfileAndLanguageEpics: RootEpic = (
    action$,
    state$,
    { learnerProfileApi, learnerOnboardingApi, moduleRestrictionApi }
) =>
    action$.pipe(
        filter(
            isActionOf([
                userActions.login.success,
                userActions.loginSSO.success,
                userActions.loginDingTalk.success,
                userActions.setNewPassword.success,
            ])
        ),
        switchMap(() => {
            const learnerUUID = state$.value.user.identity?.learnerUUID;

            const profile$ = learnerProfileApi
                .loadLearnerProfilePath(learnerUUID)
                .pipe(
                    switchMap((x) =>
                        of(
                            loadLearnerProfile.success(x),
                            setCurrentLearnerProfile(x)
                        )
                    ),
                    catchError((e) => of(loadLearnerProfile.failure(e)))
                );

            const displayStrategy$ = learnerOnboardingApi
                .loadLearnerOnboardingDisplayStrategy(learnerUUID as string)
                .pipe(
                    mergeMap((x) => {
                        return of(
                            loadLearnerDisplayStrategy.success(x),
                            setOnboardingDisplayStrategy(x)
                        );
                    }),
                    catchError((e) => of(loadLearnerDisplayStrategy.failure(e)))
                );

            const displayModuleRestricted$ = moduleRestrictionApi
                .loadModuleRestrictionCall(learnerUUID as string)
                .pipe(
                    switchMap((x) =>
                        of(
                            loadModuleRestriction.success(x),
                            setDisplayModuleRestriction(x)
                        )
                    ),
                    catchError((e) => of(loadModuleRestriction.failure(e)))
                );

            const displayStateProgress$ = learnerOnboardingApi
                .loadCurrentLearnerOnboardingDisplay(learnerUUID as string)
                .pipe(
                    switchMap((x) =>
                        of(
                            loadProgressStateOnboarding.success(x),
                            setOnboardingProgressState(x)
                        )
                    ),
                    catchError((e) =>
                        of(loadProgressStateOnboarding.failure(e))
                    )
                );

            const currentDisplayStrategy$ = forkJoin([
                displayStateProgress$,
                displayStrategy$,
            ]).pipe(
                map(([listProgressAction, displayStrategyAction]) => {
                    const listProgress = listProgressAction.payload;
                    const displayStrategy = displayStrategyAction.payload;

                    if (displayStrategy instanceof Error) {
                        return setOnboardingCurrentDisplayStrategy(null);
                    }

                    return setOnboardingCurrentDisplayStrategy(
                        displayStrategy,
                        listProgress as any
                    );
                })
            );

            return concat(
                profile$,
                displayModuleRestricted$,
                currentDisplayStrategy$,
                displayStateProgress$,
                displayStrategy$
            );
        })
    );

const setDisplayModuleRestriction = (
    displayModuleRestriction: ModuleRestrictionDisplayProps | null
) => {
    if (!displayModuleRestriction) {
        return setModuleRestricted(null);
    }

    const getDisplayRule = displayModuleRestriction?.displayRule;
    const orderItem = getDisplayRule
        .filter((a: any) => a.display !== 'No')
        .sort((a: any, b: any) => {
            if (a.order > b.order) {
                return 1;
            }
            if (a.order < b.order) {
                return -1;
            }
            return 0;
        })
        .map((item: any, key: number) => {
            item.order = key;
            return item;
        });

    let filteredItem = { ...displayModuleRestriction };
    filteredItem.displayRule = orderItem;

    return setModuleRestricted(
        new ModuleRestrictionDisplayRecord(filteredItem)
    );
};

const setOnboardingDisplayStrategy = (
    displayStrategy: OnboardingDisplayStrategyProps | null
) => {
    if (!displayStrategy) {
        return setLearnerDisplayStrategy(null);
    }

    const getDisplayRule = displayStrategy?.displayRule;
    const orderItem = getDisplayRule
        .filter((a: any) => a.display !== 'No' && a.subComponent)
        .sort((a: any, b: any) => {
            if (a.order > b.order) {
                return 1;
            }
            if (a.order < b.order) {
                return -1;
            }
            return 0;
        })
        .map((item: any, key: number) => {
            item.order = key;
            return item;
        });

    let filteredItem = { ...displayStrategy };
    filteredItem.displayRule = orderItem;

    return setLearnerDisplayStrategy(
        new OnboardingDisplayStrategyRecord(filteredItem)
    );
};

const saveLanguageToProfileEpics: RootEpic = (
    action$,
    state$,
    { learnerProfileApi }
) =>
    action$.pipe(
        filter(isActionOf(saveLanguageToProfile.request)),
        switchMap((x) => {
            const learnerUUID = state$.value.user?.identity
                ?.learnerUUID as string;
            const languageList = state$.value.learnerProfile?.languageList;
            return learnerProfileApi
                .saveLanguageToProfile(learnerUUID, x.payload.languageUUID)
                .pipe(
                    switchMap((x) => {
                        localStorage.removeItem('languagePref');
                        return of(
                            saveLanguageToProfile.success(x),
                            setCurrentLanguage(
                                x,
                                languageList.valueSeq().toArray(),
                                learnerUUID as string
                            ),
                            setCurrentLearnerProfile(x),
                            Action.contentSavedActions.setNotification({
                                show: true,
                                error: false,
                                maximum: false,
                                text: localization.settings_notification_save_success,
                                key: 100,
                            })
                        );
                    }),
                    catchError((e) =>
                        of(
                            Action.contentSavedActions.setNotification({
                                show: true,
                                error: true,
                                maximum: false,
                                text: localization.error,
                                key: 200,
                            }),
                            saveLanguageToProfile.failure(e)
                        )
                    )
                );
        })
    );

const updateLanguageToProfileEpics: RootEpic = (
    action$,
    state$,
    { learnerProfileApi }
) =>
    action$.pipe(
        filter(isActionOf(updateLearnerProfile.request)),
        switchMap((x) => {
            // const objectiveUUID = x.payload;
            const learnerUUID = state$.value.user?.identity?.learnerUUID;
            const languageList = state$.value.learnerProfile?.languageList;
            const learnerProfile = state$.value.learnerProfile?.profile;
            const currentLanguage =
                state$.value.learnerProfile?.currentLanguage;
            return learnerProfileApi
                .updateLanguageLearnerProfile(
                    learnerProfile as any,
                    currentLanguage as any,
                    x.payload as string
                )
                .pipe(
                    switchMap((x) => {
                        localStorage.removeItem('languagePref');
                        return of(
                            updateLearnerProfile.success(x),
                            setCurrentLanguage(
                                x,
                                languageList.valueSeq().toArray(),
                                learnerUUID as string
                            ),
                            setCurrentLearnerProfile(x),
                            Action.contentSavedActions.setNotification({
                                show: true,
                                error: false,
                                maximum: false,
                                text: localization.settings_notification_save_success,
                                key: 101,
                            })
                        );
                    }),
                    catchError((e) =>
                        of(
                            Action.contentSavedActions.setNotification({
                                show: true,
                                error: true,
                                maximum: false,
                                text: localization.error,
                                key: 201,
                            }),
                            saveLanguageToProfile.failure(e)
                        )
                    )
                );
        })
    );
const saveIndustryToProfileEpics: RootEpic = (
    action$,
    state$,
    { learnerProfileApi }
) =>
    action$.pipe(
        filter(isActionOf(saveIndustryProfile.request)),
        switchMap((x) => {
            const learnerUUID = state$.value.user?.identity
                ?.learnerUUID as string;

            const industryList = state$.value.learnerProfile?.industryList;

            return learnerProfileApi
                .saveIndustryToProfile(learnerUUID, x.payload.industryUUID)
                .pipe(
                    switchMap((x) =>
                        of(
                            saveIndustryProfile.success(x),
                            setCurrentIndustry(
                                x,
                                industryList.valueSeq().toArray()
                            ),
                            setCurrentLearnerProfile(x),
                            Action.contentSavedActions.setNotification({
                                show: true,
                                error: false,
                                maximum: false,
                                text: localization.settings_notification_save_success,
                                key: 102,
                            })
                        )
                    ),
                    catchError((e) =>
                        of(
                            Action.contentSavedActions.setNotification({
                                show: true,
                                error: false,
                                maximum: false,
                                text: localization.error,
                                key: 202,
                            }),
                            saveIndustryProfile.failure(e)
                        )
                    )
                );
        })
    );

const saveJobFunctionToProfileEpics: RootEpic = (
    action$,
    state$,
    { learnerProfileApi }
) =>
    action$.pipe(
        filter(isActionOf(saveJobFunctionProfile.request)),
        switchMap((x) => {
            const learnerUUID = state$.value.user?.identity
                ?.learnerUUID as string;

            const jobfunctionList =
                state$.value.learnerProfile?.jobFunctionList;

            return learnerProfileApi
                .saveJobFunctionToProfile(learnerUUID, x.payload.jobFunctions)
                .pipe(
                    switchMap((x) =>
                        of(
                            saveJobFunctionProfile.success(x),
                            setCurrentJobFunction(
                                x,
                                jobfunctionList.valueSeq().toArray()
                            ),
                            setCurrentLearnerProfile(x),
                            Action.contentSavedActions.setNotification({
                                show: true,
                                error: false,
                                maximum: false,
                                text: localization.settings_notification_save_success,
                                key: 105,
                            })
                        )
                    ),
                    catchError((e) =>
                        of(
                            Action.contentSavedActions.setNotification({
                                show: true,
                                error: true,
                                maximum: false,
                                text: localization.error,
                                key: 205,
                            }),
                            saveJobFunctionProfile.failure(e)
                        )
                    )
                );
        })
    );

const updateIndustryToProfileEpics: RootEpic = (
    action$,
    state$,
    { learnerProfileApi }
) =>
    action$.pipe(
        filter(isActionOf(updateIndustryProfile.request)),
        switchMap((x) => {
            const industryList = state$.value.learnerProfile?.industryList;
            const learnerProfile = state$.value.learnerProfile?.profile;
            return learnerProfileApi
                .updateIndustryLearnerProfile(
                    learnerProfile as any,
                    x.payload as string[]
                )
                .pipe(
                    switchMap((x) =>
                        of(
                            updateIndustryProfile.success(x),
                            setCurrentIndustry(
                                x,
                                industryList.valueSeq().toArray()
                            ),
                            setCurrentLearnerProfile(x),
                            Action.contentSavedActions.getSavedContentItems.request(
                                true
                            ),
                            Action.inProgressTab.loadInProgressTabItems(),
                            Action.contentSavedActions.setNotification({
                                show: true,
                                error: false,
                                maximum: false,
                                text: localization.settings_notification_save_success,
                                key: 103,
                            })
                        )
                    ),
                    catchError((e) =>
                        of(
                            Action.contentSavedActions.setNotification({
                                show: true,
                                error: true,
                                maximum: false,
                                text: localization.error,
                                key: 203,
                            }),
                            updateIndustryProfile.failure(e)
                        )
                    )
                );
        })
    );

const updateJobFunctionToProfileEpics: RootEpic = (
    action$,
    state$,
    { learnerProfileApi }
) =>
    action$.pipe(
        filter(isActionOf(updateJobFunctionProfile.request)),
        switchMap((x) => {
            const jobfunctionList =
                state$.value.learnerProfile?.jobFunctionList;
            const learnerProfile = state$.value.learnerProfile?.profile;
            return learnerProfileApi
                .updateJobFunctionToProfile(
                    learnerProfile as any,
                    x.payload as string[]
                )
                .pipe(
                    switchMap((x) =>
                        of(
                            saveJobFunctionProfile.success(x),
                            setCurrentJobFunction(
                                x,
                                jobfunctionList.valueSeq().toArray()
                            ),
                            setCurrentLearnerProfile(x),
                            Action.contentSavedActions.setNotification({
                                show: true,
                                error: false,
                                maximum: false,
                                text: localization.settings_notification_save_success,
                                key: 107,
                            })
                        )
                    ),
                    catchError((e) =>
                        of(
                            Action.contentSavedActions.setNotification({
                                show: true,
                                error: true,
                                maximum: false,
                                text: localization.error,
                                key: 207,
                            }),
                            saveJobFunctionProfile.failure(e)
                        )
                    )
                );
        })
    );

const setCurrentLanguage = (
    learnerProfile: LearnerProfileProps | null,
    languageList: LanguageProps[],
    learnerUUID: string
) => {
    const defaultLanguageName = 'English';
    const defaultLanguage = languageList.find(
        (item) => item.name === defaultLanguageName
    );

    if (
        !learnerProfile ||
        learnerProfile.objectives === null ||
        learnerProfile.objectives.length === 0
    ) {
        return setLearnerLanguage(new LanguageRecord(defaultLanguage));
    }

    const currentObjective = learnerProfile.objectives.find(
        (item) => item.objectiveUUID === learnerUUID
    );

    const currentLanguageId = currentObjective?.topic.topicUUID;
    const currentLanguage = languageList.find(
        (item) => item.languageUUID === currentLanguageId
    );

    if (!currentLanguage) {
        return setLearnerLanguage(new LanguageRecord(defaultLanguage));
    } else {
        return setLearnerLanguage(new LanguageRecord(currentLanguage));
    }
};

const setCurrentLearnerProfile = (
    learnerProfile: LearnerProfileProps | null
) => {
    if (!learnerProfile) {
        return setLearnerProfile(null);
    }
    return setLearnerProfile(new LearnerProfileRecord(learnerProfile));
};

const setCurrentIndustry = (
    learnerProfile: LearnerProfileProps | null,
    industryList: IndustryProps[]
) => {
    if (!learnerProfile) {
        return setLearnerIndustry(new IndustryCurrentRecord());
    }

    const industryUUID = learnerProfile.demographic.industryUUID;
    if (!industryUUID) {
        return setLearnerIndustry(new IndustryCurrentRecord());
    }

    const industryUUIDMap = industryUUID.map((itemIndustry) => {
        const currentIndustry = industryList.find(
            (item) => item.industryUUID === itemIndustry
        );
        return currentIndustry;
    });

    const dataCurrIndustry = {
        industryList: industryUUIDMap,
    };

    return setLearnerIndustry(
        new IndustryCurrentRecord(dataCurrIndustry as any)
    );
};

const setCurrentJobFunction = (
    learnerProfile: LearnerProfileProps | null,
    jobFunctionList: JobFunctionProps[]
) => {
    if (!learnerProfile) {
        return setLearnerJobFunction(new JobFunctionRecord());
    }

    const jobfunctionUUID = learnerProfile.demographic.jobFunctionUUID;
    if (!jobfunctionUUID) {
        return setLearnerJobFunction(new JobFunctionRecord());
    }

    const currentjobFunctionUUID =
        jobfunctionUUID.map((UUID, index) => {
            const currentJobFunction = jobFunctionList.find(
                (item) => item.jobfunctionUUID === UUID
            );
            return currentJobFunction;
        }) || [];

    return setLearnerJobFunction(
        new JobFunctionRecord({
            jobFunctionList: currentjobFunctionUUID as any,
        })
    );
};

const loadLanguageListEpics: RootEpic = (
    action$,
    state$,
    { learnerProfileApi }
) =>
    action$.pipe(
        filter(
            isActionOf([loadLanguageList.request, loadLearnerProfile.success])
        ),
        switchMap(() => {
            return learnerProfileApi.loadLanguageList().pipe(
                map((x) => loadLanguageList.success(x)),
                catchError((e) => of(loadLanguageList.failure(e)))
            );
        })
    );

const setCurrentLanguageEpic: RootEpic = (action$, state$) =>
    action$.pipe(
        filter(isActionOf(loadLanguageList.success)),
        map((x) => {
            const learnerProfile = state$.value.learnerProfile?.profile;
            const learnerUUID = state$.value.user.identity?.learnerUUID;

            return setCurrentLanguage(
                learnerProfile,
                x.payload,
                learnerUUID as string
            );
        })
    );

const loadJobFunctionListEpics: RootEpic = (
    action$,
    state$,
    { learnerProfileApi }
) =>
    action$.pipe(
        filter(
            isActionOf([
                loadJobFunctionList.request,
                loadLearnerProfile.success,
            ])
        ),
        switchMap(() => {
            return learnerProfileApi.loadJobFunctionList().pipe(
                map((x) => loadJobFunctionList.success(x)),
                catchError((e) => of(loadJobFunctionList.failure(e)))
            );
        })
    );

const setCurrentJobFunctionEpic: RootEpic = (action$, state$) =>
    action$.pipe(
        filter(isActionOf(loadJobFunctionList.success)),
        map((x) => {
            const learnerProfile = state$.value.learnerProfile?.profile;

            return setCurrentJobFunction(learnerProfile, x.payload);
        })
    );

const loadIndustryListEpics: RootEpic = (
    action$,
    state$,
    { learnerProfileApi }
) =>
    action$.pipe(
        filter(
            isActionOf([loadIndustryList.request, loadLearnerProfile.success])
        ),
        switchMap(() => {
            return learnerProfileApi.loadIndustryList().pipe(
                map((x) => loadIndustryList.success(x)),
                catchError((e) => of(loadIndustryList.failure(e)))
            );
        })
    );

const setCurrentIndustryEpic: RootEpic = (action$, state$) =>
    action$.pipe(
        filter(isActionOf(loadIndustryList.success)),
        map((x) => {
            const learnerProfile = state$.value.learnerProfile?.profile;

            return setCurrentIndustry(learnerProfile, x.payload);
        })
    );

export const learnerProfileEpics = [
    loadLearnerProfileAndLanguageEpics,
    saveLanguageToProfileEpics,
    loadLanguageListEpics,
    setCurrentLanguageEpic,
    loadJobFunctionListEpics,
    setCurrentJobFunctionEpic,
    loadIndustryListEpics,
    setCurrentIndustryEpic,
    updateIndustryToProfileEpics,
    saveIndustryToProfileEpics,
    updateLanguageToProfileEpics,
    updateJobFunctionToProfileEpics,
    saveJobFunctionToProfileEpics,
];
