import { useSelector } from 'react-redux';
import {
    ConversationClassOptionsSelector,
    GroupLessonOptionsSelector,
    IndividualLessonOptionsSelector,
    PerformanceOptionsSelector,
} from '../slice';
import { localization } from '../../../localization';
import { specifyRatingOptions } from './specifyRatingOptions';
import { SurveysTranslatedItems } from '../types';
import { convertSteps } from './convertSteps';

export const useGetSurveysTranslatedItems = (): SurveysTranslatedItems => {
    // Get received options from BE
    const IndividualLessonOptions = useSelector(
        IndividualLessonOptionsSelector
    );
    const ConversationClassOptions = useSelector(
        ConversationClassOptionsSelector
    );
    const GroupLessonOptions = useSelector(GroupLessonOptionsSelector);
    const PerformanceSurveyOptions = useSelector(PerformanceOptionsSelector);
    // Construct resulted object
    return {
        IndividualLesson: IndividualLessonOptions
            ? {
                  title: localization.trainerRatingSurvey_IL_starScreen_main_question,
                  subTitle:
                      localization.trainerRatingSurvey_IL_starScreen_sub_question,
                  rate: specifyRatingOptions(
                      IndividualLessonOptions,
                      'IndividualLesson'
                  ),
              }
            : null,
        ConversationClass: ConversationClassOptions
            ? {
                  title: localization.trainerRatingSurvey_CC_starScreen_main_question,
                  subTitle:
                      localization.trainerRatingSurvey_CC_starScreen_sub_question,
                  rate: specifyRatingOptions(
                      ConversationClassOptions,
                      'ConversationClass'
                  ),
              }
            : null,
        GroupLesson: GroupLessonOptions
            ? {
                  title: localization.trainerRatingSurvey_GL_starScreen_main_question,
                  rate: specifyRatingOptions(GroupLessonOptions, 'GroupLesson'),
              }
            : null,
        PerformanceSurvey:
            PerformanceSurveyOptions && PerformanceSurveyOptions.length > 0
                ? {
                      title: localization.performanceSurvey_completionScreen_start_message_first,
                      subTitle:
                          localization.performanceSurvey_completionScreen_start_message_second,
                      steps: convertSteps(PerformanceSurveyOptions),
                  }
                : null,
    };
};
