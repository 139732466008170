import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { classroom } from './api-urls';
import { Axios } from 'axios-observable';
import { ApiHeaders } from './api-headers';
import { List } from 'immutable';
import { VcrLinkHashData } from '../store/vcr/persist-message/persist-message-data';

export class VcrFetchApi {
    apiHeaders: ApiHeaders;

    constructor(apiHeaders: ApiHeaders) {
        this.apiHeaders = apiHeaders;
    }
    loadChatMessage(
        label: string,
        hash: string,
        classId: string,
        chatId: number
    ): Observable<List<VcrLinkHashData>> {
        return Axios.post(
            classroom() + `ws/media/fetch`,
            {
                label: label,
                classId: classId,
                hash: hash,
            },
            { headers: this.apiHeaders.getHeaders({}, true) }
        ).pipe(
            map((x) => {
                const data = new VcrLinkHashData({
                    id: chatId as number,
                    userId: classId as any,
                    filename: label as string,
                    fileLink: x.data.filename as string,
                });
                return List([data]);
            })
        );
    }
}
