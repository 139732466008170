export enum ProcessStatusEnum {
    OK = 'Ok',
    AccessGranted = 'AccessGranted',
    AccessDuplicated = 'AccessDuplicated',
    AccessDenied = 'AccessDenied',
    Full = 'Full',
}

export interface ProvisioningSteps {
    processId: string;
    processStatus: ProcessStatusEnum;
    transferParam?: {
        [key: string]: any;
    };
}

export interface ProvisioningDto {
    userUUID: string;
    clientId: string;
    sessionId: string;
    phase: string;
    processSteps: ProvisioningSteps[];
}

export class IntegProvisioningActivityTrackingSettings {
    constructor(public readonly AICCParamSteps: ProvisioningSteps) {}

    public static getDefault(): IntegProvisioningActivityTrackingSettings {
        return new IntegProvisioningActivityTrackingSettings({
            processId: '',
            processStatus: 'AccessDenied' as ProcessStatusEnum,
        });
    }
}

export class IntegrationActivityTrackingSettings {
    constructor(public readonly status: ProcessStatusEnum) {}

    public static getDefault(): IntegrationActivityTrackingSettings {
        return new IntegrationActivityTrackingSettings(
            ProcessStatusEnum.AccessDenied
        );
    }
}

export class UserCustomFields {
    constructor(public readonly userGUID: null | string) {}

    public static getDefault(): UserCustomFields {
        return new UserCustomFields(null);
    }
}
