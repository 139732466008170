import { catchError, map } from 'rxjs/operators';
import { Axios } from 'axios-observable';
import { ApiHeaders } from './api-headers';
import { activityFilters } from './api-urls';
import {
    ActivityCategory,
    ActivityCategoryDto,
} from '../store/activity-filters/activity-filters-data';
import { parseDtoToResourceType } from './resources/resource-type-dto';

export class ActivityFiltersApi {
    apiHeaders: ApiHeaders;

    constructor(apiHeaders: ApiHeaders) {
        this.apiHeaders = apiHeaders;
    }

    getActivityCategoriesList(languageUUID: string) {
        return Axios.get<ActivityCategoryDto[]>(
            activityFilters() + languageUUID + '/categories',
            {
                headers: this.apiHeaders.getHeaders({}, true),
            }
        ).pipe(
            map((response) => {
                const categories = response.data;

                const activityCategories = categories.map((category) => {
                    return {
                        id: String(category.id),
                        name: category.name,
                        contentType: parseDtoToResourceType(
                            category.contentType
                        ),
                        occurrence: category.occurence,
                    } as ActivityCategory;
                });
                return activityCategories;
            }),
            catchError((error) => {
                throw error.message;
            })
        );
    }
}
