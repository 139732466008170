import { Observable } from 'rxjs';
import { ApiHeaders } from './api-headers';
import { ajax } from 'rxjs/ajax';
import { apiv1, learnerChat } from './api-urls';
import { Axios } from 'axios-observable';
import { map } from 'rxjs/operators';

export interface LocalizationString {
    key: string;
    text: string;
}

export interface IPreferredLanguageApi {
    setUserPreferredLanguageCode(user: {
        userId: string;
        preferedCommunicationLanguage: string;
        displayLanguage: string;
    }): Observable<void>;

    getCommunicationLanguages(code?: string): Observable<any>;
}

export class PreferredLanguageApi implements IPreferredLanguageApi {
    constructor(private readonly apiHeaders: ApiHeaders) {
        this.apiHeaders = apiHeaders;
    }

    setUserPreferredLanguageCode(user: {
        userId: string;
        preferedCommunicationLanguage: string;
        displayLanguage: string;
    }): Observable<any> {
        return ajax
            .post(
                apiv1() + 'learner-preferences/user-profile/',
                user,
                this.apiHeaders.getHeaders(
                    {
                        'Content-Type': 'application/json',
                    },
                    true
                )
            )
            .pipe();
    }

    getCommunicationLanguages(code?: string): Observable<any> {
        let translatedList = '';
        if (code) {
            translatedList = `?code=${code}`;
        }

        return Axios.get<any>(
            learnerChat() + 'communication-language' + translatedList,
            { headers: this.apiHeaders.getHeaders() }
        )
            .pipe(map((response) => response.data))
            .pipe(map((x) => x));
    }
}
