import { createReducer } from 'typesafe-actions';
import { combineReducers } from 'redux';
import {
    loadTrainingCoursesActivity,
    loadLessonsActivity,
    loadTrainingActivity,
    loadTrainingEwriting,
    loadTrainingVCRActivity,
    resetAllTraining,
    loadPlacementTrainingActivity,
    setNavigation,
    trainingPageOpened,
} from './training-actions';
import { ActivityReportContent } from './training-activity';
import { parseDtoToResourceType } from '../../services/resources/resource-type-dto';
import { EwritingListItem } from '../e-writing/data/ewriting-models';
import { ActivityTrainingLessonDto } from '../../services/activity-lesson-api';
import { ActivityReportVcr } from './training-vcr';
import { ActivityReportCourses } from './training-course';
import { ActivityPlacementTest } from './training-placement-test';

export interface TrainingState {
    activity: ActivityReportContent[] | null;
    placementActivity: ActivityPlacementTest[] | null;
    ewritings: EwritingListItem[] | null;
    lessons: ActivityTrainingLessonDto[] | null;
    vcr: ActivityReportVcr[] | null;
    courses: ActivityReportCourses[] | null;
    loadingActivity: boolean;
    loadingLessons: boolean;
    loadingEwritings: boolean;
    loadingVCR: boolean;
    loadingCourses: boolean;
    loadingPlacementActivity: boolean;
    navigation: boolean;
}

const initialTrainingState: TrainingState = {
    activity: null,
    placementActivity: null,
    ewritings: null,
    lessons: null,
    vcr: null,
    courses: null,
    loadingActivity: false,
    loadingEwritings: false,
    loadingLessons: false,
    loadingVCR: false,
    loadingCourses: false,
    loadingPlacementActivity: false,
    navigation: false,
};

const activityReducer = createReducer(initialTrainingState.activity)
    .handleAction(loadTrainingActivity.success, (state, action) =>
        action.payload.map((item) => ({
            ...item,
            contentMetadata: item.contentMetadata ?? null,
            legacy: {
                ...item.legacy,
                contentType: parseDtoToResourceType(item.legacy.contentType),
            },
        }))
    )
    .handleAction(
        [
            loadTrainingActivity.request,
            loadTrainingActivity.failure,
            resetAllTraining,
        ],
        () => null
    );

const ewritingReducer = createReducer(initialTrainingState.ewritings)
    .handleAction(
        loadTrainingEwriting.success,
        (state, action) => action.payload
    )
    .handleAction(
        [
            loadTrainingEwriting.request,
            loadTrainingEwriting.failure,
            resetAllTraining,
        ],
        () => null
    );

const vcrReducer = createReducer(initialTrainingState.vcr)
    .handleAction(
        loadTrainingVCRActivity.success,
        (state, action) => action.payload
    )
    .handleAction(
        [
            loadTrainingVCRActivity.request,
            loadTrainingVCRActivity.failure,
            resetAllTraining,
        ],
        () => null
    );

const activityLessonReducer = createReducer(initialTrainingState.lessons)
    .handleAction(
        loadLessonsActivity.success,
        (state, action) => action.payload
    )
    .handleAction(
        [
            loadLessonsActivity.request,
            loadLessonsActivity.failure,
            resetAllTraining,
        ],
        () => null
    );

const activityCoursesReducer = createReducer(initialTrainingState.courses)
    .handleAction(
        loadTrainingCoursesActivity.success,
        (state, action) => action.payload
    )
    .handleAction(
        [
            loadTrainingCoursesActivity.request,
            loadTrainingCoursesActivity.failure,
            resetAllTraining,
        ],
        () => null
    );

const loadingActivityReducer = createReducer(
    initialTrainingState.loadingActivity
)
    .handleAction(loadTrainingActivity.request, () => true)
    .handleAction(
        [loadTrainingActivity.success, loadTrainingActivity.failure],
        () => false
    );

const loadingEwritingReducer = createReducer(
    initialTrainingState.loadingActivity
)
    .handleAction(loadTrainingEwriting.request, () => true)
    .handleAction(
        [loadTrainingEwriting.success, loadTrainingEwriting.failure],
        () => false
    );

const loadingLessonsReducer = createReducer(
    initialTrainingState.loadingActivity
)
    .handleAction(loadLessonsActivity.request, () => true)
    .handleAction(
        [loadLessonsActivity.success, loadLessonsActivity.failure],
        () => false
    );

const loadingVCRReducer = createReducer(initialTrainingState.loadingVCR)
    .handleAction(loadTrainingVCRActivity.request, () => true)
    .handleAction(
        [loadTrainingVCRActivity.success, loadTrainingVCRActivity.failure],
        () => false
    );

const loadingCoursesReducer = createReducer(initialTrainingState.loadingVCR)
    .handleAction(loadTrainingCoursesActivity.request, () => true)
    .handleAction(
        [
            loadTrainingCoursesActivity.success,
            loadTrainingCoursesActivity.failure,
        ],
        () => false
    );

const loadingPlacementActivityReducer = createReducer(
    initialTrainingState.loadingPlacementActivity
)
    .handleAction(loadPlacementTrainingActivity.request, () => true)
    .handleAction(
        [
            loadPlacementTrainingActivity.success,
            loadPlacementTrainingActivity.failure,
        ],
        () => false
    );

const activityPlacementReducer = createReducer(
    initialTrainingState.placementActivity
)
    .handleAction(loadPlacementTrainingActivity.success, (state, action) =>
        action.payload.map((item) => ({
            ...item,
            dateCompleted: item.completedTimestamp,
        }))
    )
    .handleAction(
        [
            loadTrainingActivity.request,
            loadTrainingActivity.failure,
            resetAllTraining,
        ],
        () => null
    );

const navigationReducer = createReducer(initialTrainingState.navigation)
    .handleAction(trainingPageOpened, () => true)
    .handleAction(setNavigation, (state, action) => action.payload);

export const trainingReducer = combineReducers({
    activity: activityReducer,
    loadingActivity: loadingActivityReducer,
    ewritings: ewritingReducer,
    vcr: vcrReducer,
    courses: activityCoursesReducer,
    loadingEwritings: loadingEwritingReducer,
    lessons: activityLessonReducer,
    loadingLessons: loadingLessonsReducer,
    loadingVCR: loadingVCRReducer,
    loadingCourses: loadingCoursesReducer,
    placementActivity: activityPlacementReducer,
    loadingPlacementActivity: loadingPlacementActivityReducer,
    navigation: navigationReducer,
});
