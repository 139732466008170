import { createReducer } from 'typesafe-actions';
import {
    TestAnswerSelection,
    TestRecord,
    TestQuestionRecord,
} from './adaptive-test-data';
import {
    clearTest,
    completeTest,
    loadLastTest,
    loadQuestion,
    loadTest,
    setAnswer,
    setHasAnswers,
    showCloseModal,
    showContinueModal,
    startTest,
    submitQuestion,
    setLocation,
    setTimerState,
} from './adaptive-test-actions';
import { combineReducers } from 'redux';

export interface AdaptiveTestState {
    common: TestRecord | null;
    isLoadingTest: boolean;
    question: TestQuestionRecord | null;
    isLoadingQuestion: boolean;
    selectedAnswer: TestAnswerSelection;
    hasAnswers: boolean;
    isLoadingResults: boolean;
    isContinueModal: boolean;
    isCloseModal: boolean;
    timerPause: boolean;
    locationFrom: string;
}

const initialState: AdaptiveTestState = {
    common: null,
    isLoadingTest: false,
    question: null,
    isLoadingQuestion: false,
    selectedAnswer: [],
    hasAnswers: false,
    isLoadingResults: false,
    isCloseModal: false,
    timerPause: false,
    isContinueModal: false,
    locationFrom: '',
};

/*
 *   Hold common test data
 */
const common = createReducer(initialState.common)
    .handleAction(
        [
            loadTest.failure,
            loadLastTest.failure,
            completeTest.failure,
            clearTest,
        ],
        () => null
    )
    .handleAction(
        [loadTest.success, loadLastTest.success, completeTest.success],
        (state, action) => action.payload
    )
    .handleAction(
        startTest,
        (state) => state && state.set('status', 'Incomplete')
    );

/*
 *   Hold loading state of test
 */
const isLoadingTest = createReducer(initialState.isLoadingTest)
    .handleAction([loadTest.request, loadLastTest.request], () => true)
    .handleAction(
        [
            loadTest.success,
            loadTest.failure,
            loadLastTest.success,
            loadLastTest.failure,
            clearTest,
        ],
        () => false
    );

/*
 *   Hold current question test
 */
const question = createReducer(initialState.question)
    .handleAction(loadQuestion.success, (state, action) => action.payload)
    .handleAction(clearTest, () => null);

/*
 *   Hold loading state of question
 */
const isLoadingQuestion = createReducer(initialState.isLoadingQuestion)
    .handleAction([loadQuestion.request, submitQuestion.request], () => true)
    .handleAction(
        [loadQuestion.success, loadQuestion.failure, clearTest],
        () => false
    );

/*
 *   Hold selected answer to sumbit
 */
const selectedAnswer = createReducer(initialState.selectedAnswer).handleAction(
    setAnswer,
    (state, action) => action.payload
);

/*
 *   Hold state that we filled all answers
 */
const hasAnswers = createReducer(initialState.hasAnswers).handleAction(
    setHasAnswers,
    (state, action) => action.payload
);

/*
 *   Hold loading state of results
 */
const isLoadingResults = createReducer(initialState.isLoadingResults)
    .handleAction([completeTest.request, submitQuestion.request], () => true)
    .handleAction(
        [completeTest.success, completeTest.failure, clearTest],
        () => false
    );

/*
 *   Hold when need to show continue modal
 *   (after open a test and have incompleted state)
 */
const isContinueModal = createReducer(initialState.isContinueModal)
    .handleAction(showContinueModal, (store, action) => action.payload)
    .handleAction(clearTest, () => false);

/*
 *   Hold when need to show close modal
 *   (after click to close button)
 */
const isCloseModal = createReducer(initialState.isCloseModal)
    .handleAction(showCloseModal, (store, action) => action.payload)
    .handleAction(clearTest, () => false);

const timerPause = createReducer(initialState.timerPause).handleAction(
    setTimerState,
    (_, action) => action.payload
);

/*
 *   Hold when need to show close modal
 *   (after click to close button)
 */
const locationFrom = createReducer('/dashboard').handleAction(
    setLocation,
    (store, action) => action.payload
);

export const testReducer = combineReducers({
    common,
    isLoadingTest,
    question,
    isLoadingQuestion,
    selectedAnswer,
    hasAnswers,
    isLoadingResults,
    isContinueModal,
    isCloseModal,
    timerPause,
    locationFrom,
});
