export class ContentActivityTrackingSettings {
    constructor(
        public readonly frequency: number,
        public readonly timeout: number
    ) {}

    public static getDefault(): ContentActivityTrackingSettings {
        return new ContentActivityTrackingSettings(60, 120);
    }
}
