export const wellKnownIds = Object.freeze({
    videoGroupId: 81850,
    articlesGroupId: 80885,
    howtoGroupId: 81862,
    grammarGroupId: 81858,
    vocabularyGroupID: 81854,
});

export interface Configuration {
    wellKnownIds: typeof wellKnownIds;
}

export const configuration: Configuration = {
    wellKnownIds,
};
