import { Record } from 'immutable';
import moment, { Moment } from 'moment';

export interface RecommendedLevelProps {
    gofluentLevel: string | null;
    grading: string | null;
    reassessState: string;
    state: 'MissingAdaptiveTest' | 'OK';
    topic: string | null;
    userid: string | null;
    level: number;
    goCefrLevel: string | null;
    goCefrLevelId: string | null;
    dateCompleted: Moment;
}

export const defaultRecommendedLevelProps: RecommendedLevelProps = {
    gofluentLevel: null,
    grading: null,
    state: 'OK',
    topic: null,
    userid: null,
    reassessState: 'None',
    level: 0,
    goCefrLevel: null,
    goCefrLevelId: null,
    dateCompleted: moment().startOf('day'),
};

export class RecommendedLevel
    extends Record(defaultRecommendedLevelProps)
    implements RecommendedLevelProps {}
