import { RootEpic } from './root-epic';
import { catchError, filter, map, switchMap } from 'rxjs/operators';
import { isActionOf } from 'typesafe-actions';
import { Action } from '../store/root-action';
import { of } from 'rxjs';
import { TestAnswerDTO } from '../services/adaptive-test-api';

/*
 *   Epic to load new test data
 */
const loadTestEpic: RootEpic = (action$, state$, { adaptiveTestApi }) =>
    action$.pipe(
        filter(isActionOf(Action.adaptiveTest.loadTest.request)),
        switchMap((x) =>
            adaptiveTestApi
                .loadTest(x.payload.languageUUID, x.payload.userUUID)
                .pipe(
                    map((x) => Action.adaptiveTest.loadTest.success(x)),
                    catchError((e) =>
                        of(Action.adaptiveTest.loadTest.failure(e))
                    )
                )
        )
    );

/*
 *   Epic to load last completed test
 */
const loadLastTestEpic: RootEpic = (action$, state$, { adaptiveTestApi }) =>
    action$.pipe(
        filter(isActionOf(Action.adaptiveTest.loadLastTest.request)),
        switchMap((x) =>
            adaptiveTestApi
                .loadLastTest(x.payload.languageUUID, x.payload.userUUID)
                .pipe(
                    map((x) => Action.adaptiveTest.loadLastTest.success(x)),
                    catchError((e) =>
                        of(Action.adaptiveTest.loadLastTest.failure(e))
                    )
                )
        )
    );

/*
 *   Epic to complete a test
 */
const completeTestEpic: RootEpic = (action$, state$, { adaptiveTestApi }) =>
    action$.pipe(
        filter(isActionOf(Action.adaptiveTest.completeTest.request)),
        switchMap((x) => {
            const testUUID = state$.value.adaptiveTest.common?.id as string;

            return adaptiveTestApi.completeTest(testUUID).pipe(
                map((x) => Action.adaptiveTest.completeTest.success(x)),
                catchError((e) =>
                    of(Action.adaptiveTest.completeTest.failure(e))
                )
            );
        })
    );

/*
 *   Epic to load current question
 */
const loadQuestionEpic: RootEpic = (action$, state$, { adaptiveTestApi }) =>
    action$.pipe(
        filter(isActionOf(Action.adaptiveTest.loadQuestion.request)),
        switchMap((x) => {
            return adaptiveTestApi.loadQuestion(x.payload.testUUID).pipe(
                map((x) => Action.adaptiveTest.loadQuestion.success(x)),
                catchError((e) =>
                    of(Action.adaptiveTest.loadQuestion.failure(e))
                )
            );
        })
    );

/*
 *   Epic to load current question or complete a test
 */
const loadNextQuestionOrCompleteEpic: RootEpic = (
    action$,
    state$,
    { adaptiveTestApi }
) =>
    action$.pipe(
        filter(isActionOf(Action.adaptiveTest.submitQuestion.success)),
        map(() => {
            const testUUID = state$.value.adaptiveTest.common?.id as string;
            const amount = state$.value.adaptiveTest.common
                ?.questionPoolSize as number;
            const position = state$.value.adaptiveTest.question
                ?.questionNumber as number;

            return position < amount
                ? Action.adaptiveTest.loadQuestion.request({ testUUID })
                : Action.adaptiveTest.completeTest.request();
        })
    );

/*
 *   Epic to submit current question
 */
const submitQuestionEpic: RootEpic = (action$, state$, { adaptiveTestApi }) =>
    action$.pipe(
        filter(isActionOf(Action.adaptiveTest.submitQuestion.request)),
        switchMap((x) => {
            const testUUID = state$.value.adaptiveTest.common?.id as string;

            const option: TestAnswerDTO = {
                answer: x.payload.answer,
                answerType: x.payload.type,
                questionId: state$.value.adaptiveTest.question?.id as string,
                duration: x.payload.time,
            };

            return adaptiveTestApi.submitQuestion(testUUID, option).pipe(
                map((x) => Action.adaptiveTest.submitQuestion.success(x)),
                catchError((e) =>
                    of(Action.adaptiveTest.submitQuestion.failure(e))
                )
            );
        })
    );

export const adaptiveTestEpics = [
    loadTestEpic,
    loadLastTestEpic,
    completeTestEpic,
    loadQuestionEpic,
    loadNextQuestionOrCompleteEpic,
    submitQuestionEpic,
];
