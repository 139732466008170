import { createReducer } from 'typesafe-actions';
import {
    loadLanguageList,
    setLearnerLanguage,
    loadJobFunctionList,
    loadIndustryList,
    setLearnerIndustry,
    setLearnerJobFunction,
    setLearnerProfile,
    saveLanguageToProfile,
    updateLearnerProfile,
    saveIndustryProfile,
    updateIndustryProfile,
    saveJobFunctionProfile,
    updateJobFunctionProfile,
} from './learner-profile-action';
import {
    logout,
    loginSSO,
    changePreferredLanguage,
    changeUILanguage,
    loginDingTalk,
} from '../user/user-actions';
import {
    LanguageRecord,
    LearnerProfileRecord,
    JobFunctionRecord,
    IndustryRecord,
    IndustryCurrentRecord,
    JobFunctionListRecord,
} from './learner-profile-records';
import { combineReducers } from 'redux';
import { Map } from 'immutable';
import { saveProgressStateDisplay } from '../learner-onboarding/learner-onboarding-action';
import { Action } from '../root-action';

export type LearnerProfileState = Readonly<{
    profile: LearnerProfileRecord | null;
    languageList: Map<string, LanguageRecord>;
    jobFunctionList: Map<string, JobFunctionListRecord>;
    industryList: Map<string, IndustryRecord>;
    currentLanguage: LanguageRecord | null;
    currentIndustry: IndustryCurrentRecord | null;
    currentJobFunction: JobFunctionRecord | null;
    isProfileChangesOnLoading: boolean | null;
}>;

export const profile = createReducer<LearnerProfileRecord | null>(
    null
).handleAction(setLearnerProfile, (state, action) => action.payload);

export const languageList = createReducer(
    Map<string, LanguageRecord>()
).handleAction(loadLanguageList.success, (state, action) => {
    const keyedData = action.payload.map<[string, LanguageRecord]>((x) => {
        return [x.languageUUID, new LanguageRecord(x)];
    });
    return Map(keyedData);
});

export const jobFunctionList = createReducer(
    Map<string, JobFunctionListRecord>()
).handleAction(loadJobFunctionList.success, (state, action) => {
    const keyedData = action.payload.map<[string, JobFunctionListRecord]>(
        (x) => {
            return [x.jobfunctionUUID, new JobFunctionListRecord(x)];
        }
    );

    return state.merge(Map(keyedData));
});

export const industryList = createReducer(
    Map<string, IndustryRecord>()
).handleAction(loadIndustryList.success, (state, action) => {
    const keyedData = action.payload.map<[string, IndustryRecord]>((x) => [
        x.industryUUID,
        new IndustryRecord(x),
    ]);

    return state.merge(Map(keyedData));
});

export const currentLanguage = createReducer<LanguageRecord | null>(null)
    .handleAction(setLearnerLanguage, (state, action) => action.payload)
    .handleAction(logout.success, () => null)
    .handleAction(loginSSO.request, () => null)
    .handleAction(loginDingTalk.request, () => null);

export const currentIndustry = createReducer<IndustryCurrentRecord | null>(null)
    .handleAction(setLearnerIndustry, (state, action) => action.payload)
    .handleAction(logout.success, () => null);

export const currentJobFunction = createReducer<JobFunctionRecord | null>(null)
    .handleAction(setLearnerJobFunction, (state, action) => action.payload)
    .handleAction(logout.success, () => null);

export const isProfileChangesOnLoading = createReducer<boolean | null>(null)
    .handleAction(
        [
            // profile
            saveLanguageToProfile.request,
            updateLearnerProfile.request,
            saveIndustryProfile.request,
            updateIndustryProfile.request,
            saveProgressStateDisplay.request,
            saveJobFunctionProfile.request,
            updateJobFunctionProfile.request,
            // settings
            changePreferredLanguage.request,
            changeUILanguage.request,
        ],
        (state, action) => {
            if ((action.payload as any)?.skip) {
                return null;
            } else {
                return true;
            }
        }
    )
    .handleAction(
        [
            saveLanguageToProfile.success,
            updateLearnerProfile.success,
            saveLanguageToProfile.failure,
            updateLearnerProfile.failure,
            saveIndustryProfile.success,
            updateIndustryProfile.success,
            saveIndustryProfile.failure,
            updateIndustryProfile.failure,
            saveProgressStateDisplay.success,
            saveProgressStateDisplay.failure,
            saveJobFunctionProfile.success,
            updateJobFunctionProfile.success,
            saveJobFunctionProfile.failure,
            updateJobFunctionProfile.failure,
            // settings
            changePreferredLanguage.success,
            changePreferredLanguage.failure,
            changeUILanguage.success,
            changeUILanguage.failure,
        ],
        (state, action) => {
            if ((action.payload as any)?.skip) {
                return null;
            } else {
                return false;
            }
        }
    )
    .handleAction(
        [
            Action.user.logout.request,
            Action.user.logout.success,
            Action.user.logout.failure,
            Action.user.login.request,
            Action.user.login.success,
            Action.user.login.failure,
        ],
        () => null
    );

export const learnerProfileReducer = combineReducers({
    profile,
    languageList,
    currentLanguage,
    jobFunctionList,
    industryList,
    currentIndustry,
    currentJobFunction,
    isProfileChangesOnLoading,
});
