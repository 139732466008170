import { filter, map } from 'rxjs/operators';
import { isActionOf } from 'typesafe-actions';
import { Action } from '../../store/root-action';
import { RootEpic } from '../root-epic';
import { articlesEpics } from './articles-epics';
import { channelEpics } from './channel-epic';
import { resourceContentEpics } from './content-epics';
import { howtosEpics } from './howtos-epics';
import { recommendedEpics } from './recommended-epics';
import { searchEpics } from './search-epics';
import { translateResourceEpics } from './translate-epics';
import { videosEpics } from './videos-epics';

const clearResourcesEpic: RootEpic = (action$) =>
    action$.pipe(
        filter(
            isActionOf([
                Action.learnerProfile.setLearnerLanguage,
                Action.learnerProfile.setLearnerIndustry,
            ])
        ),
        map((x) => Action.resources.clearAllResources())
    );

export const resourcesEpics = [
    ...howtosEpics,
    ...articlesEpics,
    ...videosEpics,
    ...searchEpics,
    ...recommendedEpics,
    ...resourceContentEpics,
    ...translateResourceEpics,
    ...channelEpics,
    clearResourcesEpic,
];
