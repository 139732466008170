import { createReducer } from 'typesafe-actions';
import { Action } from '../../root-action';
import { VcrLinkHashData } from './persist-message-data';
import { List } from 'immutable';
import { combineReducers } from 'redux';

export const messageHashReducer = createReducer<List<VcrLinkHashData>>(
    List<VcrLinkHashData>()
).handleAction(
    Action.fetchMessageLink.vcrFetchMessage.success,
    (state, action) => {
        return state?.merge(action.payload);
    }
);

export const vcrLinkReducer = combineReducers({
    vcrLinkHash: messageHashReducer,
});
