import { RootEpic } from '../root-epic';
import { isActionOf } from 'typesafe-actions';
import { filter, switchMap, catchError } from 'rxjs/operators';
import { of } from 'rxjs';
import {
    ProgressDisplayStrategyRecord,
    StateProgressOnboardingRecord,
    OnboardingDisplayStrategyProps,
    StateProgressProps,
} from '../../store/learner-onboarding/learner-onboarding-records';

import {
    setCurrentDisplayStrategy,
    setProgressOnboarding,
    saveProgressStateDisplay,
} from '../../store/learner-onboarding/learner-onboarding-action';
import { Action } from '../../store/root-action';
import { localization } from '../../localization';

const selectCurrentDisplayStrategy: RootEpic = (
    action$,
    state$,
    { learnerOnboardingApi }
) => {
    return action$
        .pipe(filter(isActionOf(saveProgressStateDisplay.request)))
        .pipe(
            switchMap((x) => {
                const getDisplayStrategy =
                    state$.value.learnerOnboarding.displayStrategy;

                const learnerUUID = state$.value.user.identity?.learnerUUID;
                const getDisplayProgressState =
                    state$.value.learnerOnboarding.displayStateProgress;

                return learnerOnboardingApi
                    .saveLearnerStateProgress(
                        getDisplayStrategy as OnboardingDisplayStrategyProps,
                        getDisplayProgressState as StateProgressProps,
                        x.payload as number,
                        learnerUUID as string
                    )
                    .pipe(
                        switchMap((y) =>
                            of(
                                saveProgressStateDisplay.success(y),
                                setOnboardingProgressState(y),
                                setOnboardingCurrentDisplayStrategy(
                                    getDisplayStrategy as OnboardingDisplayStrategyProps,
                                    y as StateProgressProps
                                ),
                                Action.contentSavedActions.setNotification({
                                    show: true,
                                    error: false,
                                    maximum: false,
                                    text: localization.settings_notification_save_success,
                                    key: 104,
                                })
                            )
                        ),
                        catchError((e) =>
                            of(
                                Action.contentSavedActions.setNotification({
                                    show: true,
                                    error: true,
                                    maximum: false,
                                    text: localization.error,
                                    key: 204,
                                }),
                                saveProgressStateDisplay.failure(e)
                            )
                        )
                    );
            })
        );
};

export const setOnboardingProgressState = (
    progressStateDetails: StateProgressProps | null
) => {
    if (!progressStateDetails) {
        return setProgressOnboarding(null);
    }

    return setProgressOnboarding(
        new StateProgressOnboardingRecord(progressStateDetails)
    );
};

export const setOnboardingCurrentDisplayStrategy = (
    displayStrategy: OnboardingDisplayStrategyProps | null,
    displayProgressState?: StateProgressProps | null
) => {
    if (!displayStrategy) {
        return setCurrentDisplayStrategy(null);
    }

    const getDisplayRule = displayStrategy?.displayRule;
    const getDisplayRuleLength = getDisplayRule ? getDisplayRule.length : 0;
    let newData: any;
    if (getDisplayRuleLength > 0) {
        const getLastPageDisplay = Math.max.apply(
            Math,
            getDisplayRule?.map((o: any) => {
                return o.order;
            })
        );

        const onboardingStateDetailsLength = displayProgressState
            ? displayProgressState?.onboardingStateDetails.length
            : 0;

        const currentDisplay = getDisplayRule?.find((item: any) => {
            if (onboardingStateDetailsLength > getLastPageDisplay) {
                return item;
            } else {
                if (item.order === onboardingStateDetailsLength) {
                    return item;
                }
            }
            return undefined;
        });

        newData = { ...currentDisplay };
        if (newData && onboardingStateDetailsLength > getLastPageDisplay) {
            const lastOrderPage = getLastPageDisplay + 1;
            newData['display'] = 'Mandatory';
            newData['subComponent'] = 'completion';
            newData['order'] = lastOrderPage;
        }
    } else {
        newData = {
            display: 'Mandatory',
            subComponent: 'completion',
            order: 0,
        };
    }

    return setCurrentDisplayStrategy(
        new ProgressDisplayStrategyRecord(newData)
    );
};

export const learnerOnboardingEpic = [selectCurrentDisplayStrategy];
