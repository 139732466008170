/*
 *   Epic to load new test data
 */
import { RootEpic } from './root-epic';
import {
    catchError,
    filter,
    map,
    mergeMap,
    switchMap,
    takeUntil,
} from 'rxjs/operators';
import { isActionOf, RootAction } from 'typesafe-actions';
import { Action } from '../store/root-action';
import { interval, Observable, of } from 'rxjs';

const loadInProgressActivitiesEpic: RootEpic = (
    action$,
    state$,
    { activityReportApi }
) =>
    action$.pipe(
        filter(isActionOf(Action.resources.loadInProgressActivities.request)),
        switchMap(() => {
            const userUUID = state$.value.user.identity?.learnerUUID;
            const languageUUID =
                state$.value.learnerProfile.currentLanguage?.languageUUID;

            return activityReportApi
                .loadInProgressList(userUUID as string, languageUUID as string)
                .pipe(
                    map((x) => {
                        return Action.resources.loadInProgressActivities.success(
                            x
                        );
                    }),
                    catchError((e) =>
                        of(Action.resources.loadInProgressActivities.failure(e))
                    )
                );
        })
    );

const loadCompletedActivitiesEpic: RootEpic = (
    action$,
    state$,
    { activityReportApi }
) =>
    action$.pipe(
        filter(isActionOf(Action.resources.loadCompletedActivities.request)),
        switchMap((x) => {
            const userUUID = state$.value.user.identity?.learnerUUID;
            const languageUUID =
                state$.value.learnerProfile.currentLanguage?.languageUUID;

            return activityReportApi
                .loadCompletedList(userUUID as string, languageUUID as string)
                .pipe(
                    map((x) =>
                        Action.resources.loadCompletedActivities.success(x)
                    ),
                    catchError((e) =>
                        of(Action.resources.loadCompletedActivities.failure(e))
                    )
                );
        })
    );

const loadCoursesStatusesEpic: RootEpic = (
    action$,
    state$,
    { activityReportApi }
) =>
    action$.pipe(
        filter(isActionOf(Action.trainingPath.loadCoursesStatuses.request)),
        switchMap(() => {
            const userUUID = state$.value.user.identity?.learnerUUID;

            return activityReportApi
                .loadCoursesStatuses(userUUID as string)
                .pipe(
                    map((x) =>
                        Action.trainingPath.loadCoursesStatuses.success(x)
                    ),
                    catchError((e) =>
                        of(Action.trainingPath.loadCoursesStatuses.failure(e))
                    )
                );
        })
    );

export const pollDelay = 60 * 2 * 1000;

const isStopPollingRequest = (action$: Observable<RootAction>) =>
    action$.pipe(
        filter(isActionOf(Action.resources.stopPollingActivityStatuses.request))
    );

export const resetActivitiesStatusesEpic: RootEpic = (action$) =>
    action$.pipe(
        filter(
            isActionOf(Action.resources.startPollingActivityStatuses.request)
        ),
        switchMap(() => {
            return interval(pollDelay).pipe(
                mergeMap(() => {
                    return of(
                        Action.trainingPath.resetCoursesStatuses.success(),
                        Action.resources.resetCompletedActivities.success(),
                        Action.resources.resetInProgressActivities.success(),
                        Action.resources.startPollingActivityStatuses.success()
                    );
                }),
                takeUntil(isStopPollingRequest(action$))
            );
        }),
        catchError((e) => {
            throw e;
        })
    );

export const activityReportEpics = [
    loadInProgressActivitiesEpic,
    loadCompletedActivitiesEpic,
    resetActivitiesStatusesEpic,
    loadCoursesStatusesEpic,
];
