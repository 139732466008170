import React from 'react';
import './loading.css';
import { localization } from '../../localization';
import { LocalizationText } from '../../localization/localization-text/localization-text';
import { Spinner } from '@arcadia-projects/portal2-ui-library';

interface LoadingProps {
    text?: string;
    fullscreen?: boolean;
}

/**
 * Loading component
 * @param text to display
 */
export const Loading: React.FC<LoadingProps> = ({
    text,
    fullscreen = false,
}) => {
    return (
        <div className={'loading' + (fullscreen ? '-fullscreen' : '')}>
            <div className="loading__wrapper">
                <Spinner width={100} height={100} />
                <LocalizationText
                    text={text || localization.loading_label_Loading}
                />
            </div>
        </div>
    );
};
