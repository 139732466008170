import { RootEpic } from '../root-epic';
import { concat, forkJoin, of } from 'rxjs';
import { List } from 'immutable';
import {
    filter,
    switchMap,
    catchError,
    tap,
    ignoreElements,
    exhaustMap,
    withLatestFrom,
    concatMap,
    map,
    distinctUntilChanged,
} from 'rxjs/operators';
import { isActionOf } from 'typesafe-actions';
import { Action } from '../../store/root-action';
import { getStatusLoadingActions } from './helpers';
import { localization } from '../../localization';
import { PreferredLangauges } from '../../services/learner-preferrence';

const loadDownloads: RootEpic = (action$, state$, { offlineApi }) => {
    return action$
        .pipe(
            filter(isActionOf(Action.offline.loadResourceItemByCourse.request))
        )
        .pipe(
            switchMap(({ payload }) => {
                const { contentUuid } = payload;

                return offlineApi.loadContent(payload).pipe(
                    concatMap((response: any) => {
                        const listItem = response.valueSeq().toArray();
                        const l = List(
                            listItem.map((k: any) => {
                                return {
                                    courseUuid: contentUuid,
                                    resourceUuid: k.id,
                                };
                            })
                        );

                        if (response.size === 0) {
                            return of(
                                Action.offline.setNoSizeContent(),
                                Action.offline.loadResourceItemByCourse.failure(
                                    {
                                        error: new Error(
                                            'no downloadable contents'
                                        ),
                                        contentUuid,
                                    }
                                )
                            );
                        } else {
                            return of(
                                Action.offline.loadResourceItemByCourse.success(
                                    response
                                ),
                                Action.offline.loadResourceDetails.request({
                                    list: response,
                                    courseContentUuid: contentUuid,
                                }),
                                Action.offline.setResourceListByCourse(l)
                            );
                        }
                    }),
                    catchError((e) =>
                        of(
                            Action.offline.loadResourceItemByCourse.failure({
                                error: e,
                                contentUuid,
                            })
                        )
                    )
                );
            })
        );
};

const loadDownloadsResource: RootEpic = (action$, state$, { offlineApi }) =>
    action$.pipe(
        filter(isActionOf(Action.offline.loadResourceDetails.request)),
        switchMap(({ payload }) => {
            const { list, courseContentUuid } = payload;
            const addLanguageCode = {
                items: list,
                languageCode: state$.value.learnerProfile?.currentLanguage
                    ?.code as string,
            };

            return offlineApi.loadResourceDetails(addLanguageCode).pipe(
                concatMap((x: any) => {
                    return of(
                        Action.offline.loadResourceDetails.success(x),
                        Action.offline.loadResourceContent.request(x),
                        Action.offline.loadResourceQuiz.request(x)
                    );
                }),
                catchError((e) =>
                    of(
                        Action.offline.loadResourceDetails.failure({
                            error: e,
                            contentUuid: courseContentUuid,
                        })
                    )
                )
            );
        })
    );

export const loadResourceContent: RootEpic = (
    action$,
    state$,
    { offlineApi }
) =>
    action$.pipe(
        filter(isActionOf(Action.offline.loadResourceContent.request)),
        withLatestFrom(state$),
        switchMap(([{ payload }, state]) => {
            const inProgressResources = state.resources.inProgress.list;
            const completedResources = state.resources.completed.list;
            return offlineApi
                .loadResourceDetailsContent({
                    list: payload,
                    inProgressResources,
                    completedResources,
                })
                .pipe(
                    concatMap((loadedResource) => {
                        return of(
                            ...getStatusLoadingActions(
                                inProgressResources,
                                completedResources
                            ),
                            Action.offline.loadResourceContent.success(
                                loadedResource as any
                            )
                        );
                    }),
                    catchError((e) =>
                        of(Action.offline.loadResourceContent.failure(e))
                    )
                );
        })
    );

const loadDownloadsResourceQuiz: RootEpic = (action$, state$, { offlineApi }) =>
    action$.pipe(
        filter(isActionOf(Action.offline.loadResourceQuiz.request)),
        switchMap(({ payload }) => {
            const learnerId = state$.value.user?.identity?.learnerId as number;
            return offlineApi
                .loadResourceQuiz({ list: payload, learnerId })
                .pipe(
                    concatMap((x) => {
                        return of(Action.offline.loadResourceQuiz.success(x));
                    }),
                    catchError((e) =>
                        of(Action.offline.loadResourceQuiz.failure(e))
                    )
                );
        })
    );

const loadDownloadAssets: RootEpic = (action$, state$, { offlineApi }) =>
    action$.pipe(
        filter(isActionOf([Action.offline.loadResourceQuiz.success])),
        switchMap(({ payload }) => {
            const displayAssets =
                state$.value.resources?.offline?.displayedAssets;

            const arrayResourceState: any = [
                state$.value.resources?.offline?.displayedCourseList.courseList,
                state$.value.resources?.offline?.displayedResourceList,
                state$.value.resources?.offline?.displayedResource,
                state$.value.resources?.offline?.displayedResourceContent,
                state$.value.resources?.offline?.displayedResourceQuiz.quiz,
            ];

            const stringState: any = [].concat
                .apply(
                    [],
                    arrayResourceState.map((j: any) => j.valueSeq().toArray())
                )
                .filter((j: any) => {
                    return !(j instanceof Error);
                })
                .map((s) => {
                    return Object.fromEntries(s);
                });

            return offlineApi
                .loadAssets({ list: stringState, displayAssets })
                .pipe(
                    concatMap((x) => {
                        const activitiesDownloadSize =
                            state$.value.resources?.offline?.displayedCourseList
                                ?.downloadedResourceSize;

                        return of(
                            Action.offline.setDislayAssets(x),
                            Action.contentSavedActions.setNotification({
                                show: true,
                                error: false,
                                info: true,
                                text: localization.offline_downloaded_success.replace(
                                    '{n}',
                                    String(activitiesDownloadSize)
                                ),
                                key: 258,
                            })
                        );
                    }),
                    catchError((e) => {
                        return of();
                    })
                );
        })
    );

const postIndexDBDownloads: RootEpic = (
    action$,
    state$,
    { indexDBService }
) => {
    return action$
        .pipe(filter(isActionOf(Action.offline.setDislayAssets)))
        .pipe(
            tap((x) => {
                const leanerUUID = String(
                    state$.value.user.identity?.learnerUUID
                );
                const displayedCourseList =
                        state$.value.resources?.offline?.displayedCourseList
                            ?.courseList,
                    displayedResourcesListByCourse =
                        state$.value.resources?.offline?.displayedCourseList
                            ?.resourceList,
                    displayedResourceList =
                        state$.value.resources?.offline?.displayedResourceList,
                    displayResource =
                        state$.value.resources?.offline?.displayedResource,
                    displayResourceContent =
                        state$.value.resources?.offline
                            ?.displayedResourceContent,
                    displayResourceQuiz =
                        state$.value.resources?.offline?.displayedResourceQuiz
                            ?.quiz,
                    displayedAssets =
                        state$.value.resources?.offline?.displayedAssets;

                return of(
                    indexDBService.addDownloads({
                        learnerUUID: leanerUUID,
                        item: displayedCourseList,
                    }),
                    indexDBService.addCourses({
                        item: displayedCourseList,
                    }),
                    indexDBService.addResourcesByCourse({
                        item: displayedResourcesListByCourse,
                    }),
                    indexDBService.addResourcesList({
                        item: displayedResourceList,
                    }),
                    indexDBService.addResourcesDetails({
                        item: displayResource,
                        content: displayResourceContent,
                    }),
                    indexDBService.addResourcesQuiz({
                        learnerUUID: leanerUUID,
                        item: displayResourceQuiz,
                    }),
                    indexDBService.addAssets({
                        item: displayedAssets,
                    })
                );
            }),
            ignoreElements()
        );
};

const loadToStates: RootEpic = (action$, state$, _) => {
    return action$.pipe(filter(isActionOf(Action.offline.setItems))).pipe(
        exhaustMap(({ payload }) => {
            const {
                setSelectedCourseItem,
                setResourceItemByCourse,
                setResourceDetail,
                setResourceContent,
                setQuiz,
                preSetDislayAssets,
                setResourceListByCourse,
                preSetActivity,
            } = payload;

            return of(
                Action.offline.setSelectedCourseItem(setSelectedCourseItem),
                Action.offline.setResourceItemByCourse(setResourceItemByCourse),
                Action.offline.setResourceDetail(setResourceDetail),
                Action.offline.setResourceContent(setResourceContent),
                Action.offline.setQuiz(setQuiz),
                Action.offline.preSetDislayAssets(preSetDislayAssets),
                Action.offline.preSetActivity(preSetActivity),
                Action.offline.setResourceListByCourse(setResourceListByCourse)
            );
        })
    );
};

const postQuizStateToIndexDB: RootEpic = (_, state$, { indexDBService }) =>
    state$.pipe(
        map((x) => x.quizzes),
        filter((state) => {
            return !!(state && state.quiz);
        }),
        distinctUntilChanged(),
        tap((x) => {
            const learnerUUID = state$.value.user.identity
                ?.learnerUUID as string;
            const offlineDisplayedResource =
                state$.value.resources.offline.activeResourceDisplayed;
            if (x.quiz) {
                return of(
                    indexDBService.updateActivities({
                        learnerUUID: learnerUUID,
                        x,
                        offlineDisplayedResource,
                    })
                );
            }
            return of();
        }),
        ignoreElements()
    );

const saveOfflineSettingEpic: RootEpic = (
    action$,
    state$,
    { learnerPreferencesApi }
) =>
    action$.pipe(
        filter(isActionOf([Action.offline.postOfflineSetting.request])),
        switchMap(({ payload }) => {
            const displayLanguage = state$.value.user.currentUILanguage.id;
            const preferredLanguage =
                state$.value.user.currentPreferredLanguage.id;
            const params: PreferredLangauges = {
                displayLanguage: displayLanguage,
                offlineModeEnabled: payload?.offlineModeEnabled,
                preferedCommunicationLanguage: preferredLanguage,
                userId: payload?.userUUID,
            };
            return learnerPreferencesApi.setLearnerPreference(params).pipe(
                concatMap((x) => {
                    return of(Action.offline.postOfflineSetting.success(x));
                }),
                catchError((e) => {
                    return of();
                })
            );
        })
    );

const removeAllDownloaded: RootEpic = (action$, state$, { indexDBService }) =>
    action$.pipe(
        filter(isActionOf([Action.offline.postOfflineSetting.success])),
        filter(({ payload }) => {
            const { offlineModeEnabled } = payload;
            return !offlineModeEnabled;
        }),
        tap((x) => {
            const learnerUUID = state$.value.user.identity
                ?.learnerUUID as string;
            return of(indexDBService.removeAllDownloaded(learnerUUID));
        }),
        ignoreElements()
    );

const postActivityTrackingToDbEpic: RootEpic = (
    action$,
    state$,
    { indexDBService }
) =>
    action$.pipe(
        filter(isActionOf([Action.offline.setActivity])),
        tap(({ payload }) => {
            const learnerUUID = state$.value.user.identity
                ?.learnerUUID as string;

            return of(
                indexDBService.addActivityTracking({
                    learnerUUID,
                    resource: payload,
                })
            );
        }),
        ignoreElements()
    );

const postSynchronizeEpic: RootEpic = (action$, state$, { offlineApi }) =>
    action$.pipe(
        filter(isActionOf(Action.offline.postSynchronize.request)),
        switchMap(({ payload }) => {
            const { activity, quiz, activityWithoutQuiz } = payload;
            const learnerId = state$.value.user.identity?.learnerId as number;

            const learnerUUID = state$.value.user.identity
                ?.learnerUUID as string;

            const params = {
                activity: activity,
                quiz: quiz,
            };

            const paramsWithoutQuiz = {
                activity: activityWithoutQuiz,
                quiz: quiz,
            };

            const paramSyncQuiz = {
                quiz,
                learnerId,
                learnerUUID,
            } as any;

            const syncTracking$ = offlineApi.postSyncActivityTracking(
                params as any
            );
            const syncTrackingWithoutQuiz$ =
                offlineApi.postSyncActivityTracking(paramsWithoutQuiz as any);
            const syncQuiz$ = offlineApi.postSyncQuiz(paramSyncQuiz);

            const result$ = forkJoin([
                syncTracking$,
                syncQuiz$,
                syncTrackingWithoutQuiz$,
            ]).pipe(
                map(([tracking, quiz, trackingWithoutQuiz]) => {
                    return Action.offline.updateSyncDB({
                        quiz,
                        tracking,
                        trackingWithoutQuiz,
                    });
                })
            );

            return concat(result$);
        })
    );

const updateActivityEpic: RootEpic = (action$, state$, { indexDBService }) =>
    action$.pipe(
        filter(isActionOf([Action.offline.updateActivity])),
        tap(({ payload }) => {
            const values = Array.from(payload.values());
            return of(
                indexDBService.updateActivityTracking({
                    activity: values,
                })
            );
        }),
        ignoreElements()
    );

const loadNoContentDownloadableEpic: RootEpic = (
    action$,
    state$,
    { offlineApi }
) =>
    action$.pipe(
        filter(isActionOf([Action.offline.setNoSizeContent])),
        switchMap(({ payload }) => {
            return of(
                Action.contentSavedActions.setNotification({
                    show: true,
                    error: false,
                    info: true,
                    text: localization.offline_does_not_have_activities,
                    key: 261,
                })
            );
        })
    );
export const offlineEpics = [
    loadDownloads,
    loadDownloadsResource,
    loadDownloadsResourceQuiz,
    loadResourceContent,
    loadDownloadAssets,
    postIndexDBDownloads,
    postQuizStateToIndexDB,
    loadToStates,
    saveOfflineSettingEpic,
    removeAllDownloaded,
    postActivityTrackingToDbEpic,
    updateActivityEpic,
    postSynchronizeEpic,
    loadNoContentDownloadableEpic,
];
