import { createAsyncAction, createAction } from 'typesafe-actions';
import { List, Map } from 'immutable';
import {
    Availability,
    Teacher,
    CreditRecord,
    Foresen,
    MediaRecord,
    NewMediaProps,
    SchedulerSelectedTeachersProps,
    SchedulerLimitation,
    SchedulerLanguageProps,
} from './scheduler-data';
import { Moment } from 'moment';

/**
 * Initialize scheduler action, inits request for scheduler initialization
 */
export const initializeScheduler = createAsyncAction(
    '@@scheduler/init/request',
    '@@scheduler/init/success',
    '@@scheduler/init/failure'
)<
    {
        learnerID: string;
        selectedDate?: Moment;
    },
    {
        teachers: List<Teacher>;
        lessons: List<Availability>;
        credits: Map<string, CreditRecord>;
        medias: Map<string, MediaRecord>;
        learner: any;
        sat: boolean;
        isAvailabilitiesPreLoaded: boolean;
        currentCredit: any;
    },
    Error
>();

/**
 * Action fired after scheduler initialization
 */
export const initializeSchedulerEnded = createAction(
    '@@scheduler/init/ended'
)();

/**
 * Request for initializing week lessons
 */
export const loadInitWeekLessons = createAsyncAction(
    '@@scheduler/load-init-week-lessons/request',
    '@@scheduler/load-init-week-lessons/success',
    '@@scheduler/load-init-week-lessons/failure'
)<
    {
        learnerId: string;
        startDate: Moment;
    },
    {
        lessons: List<Availability>;
    },
    Error
>();

/**
 * Request for load availabilities
 */
export const loadAvailabilities = createAsyncAction(
    '@@scheduler/load-avails/request',
    '@@scheduler/load-avails/success',
    '@@scheduler/load-avails/failure'
)<
    {
        learnerID: string;
        selectAllAvailable: boolean;
        selectedTeachers: List<Teacher>;
        startDate: Moment;
    },
    {
        availabilities: List<Availability>;
        teachers: List<Teacher>;
        selectAllAvailable: boolean;
    },
    Error
>();

/**
 * Request for load more availabilities
 */
export const loadMoreAvailabilities = createAsyncAction(
    '@@scheduler/load-more-avails/request',
    '@@scheduler/load-more-avails/success',
    '@@scheduler/load-more-avails/failure'
)<
    {
        learnerID: string;
        selectAllAvailable: boolean;
        selectedTeachers: List<Teacher>;
        startDate: Moment;
    },
    {
        availabilities: List<Availability>;
        teachers: List<Teacher>;
    },
    Error
>();

/**
 * Action for scheduler current date selection
 */
export const selectDate = createAction('@@scheduler/select-date')<Moment>();

/**
 * Action for scheduler current month date selection
 */
export const selectMonthDate = createAction(
    '@@scheduler/select-month-date'
)<Moment>();

/**
 * Action for toggling SelectAll parameter in scheduler
 */
export const selectAllTeachers = createAction(
    '@@scheduler/all-teachers'
)<boolean>();

/**
 * Action fired after scheduler opening
 */
export const opened = createAction('@@scheduler/opened')();

/**
 * Action fired after scheduler settings opened
 */
export const settingsOpen = createAction('@@scheduler/settings-opend')();

/**
 * Book lesson request
 */
export const bookLesson = createAsyncAction(
    '@@scheduler/book-lesson/request',
    '@@scheduler/book-lesson/success',
    '@@scheduler/book-lesson/failure'
)<
    {
        lessonId: string;
        availability?: Availability;
        teacherId: number;
        media: MediaRecord;
    },
    {
        availability: Availability;
        credit: CreditRecord;
    },
    Error
>();

/**
 * Cancel lesson request
 */
export const cancelLesson = createAsyncAction(
    '@@scheduler/cancel-lesson/request',
    '@@scheduler/cancel-lesson/success',
    '@@scheduler/cancel-lesson/failure'
)<
    {
        lessonId: string;
    },
    any,
    Error
>();

/**
 * Lesson confirm cancelling request
 */
export const confirmedCancelLesson = createAsyncAction(
    '@@scheduler/confirmed-cancel-lesson/request',
    '@@scheduler/confirmed-cancel-lesson/success',
    '@@scheduler/confirmed-cancel-lesson/failure'
)<
    {
        lessonId: string;
    },
    any,
    Error
>();

/**
 * Abort cancellation action
 */
export const abortCancellation = createAction(
    '@@scheduler/abort-cancellation'
)();

/**
 * Init lessons request
 */
export const initLessons = createAsyncAction(
    '@@scheduler/init-lessons/request',
    '@@scheduler/init-lessons/success',
    '@@scheduler/init-lessons/failure'
)<
    {
        learnerId: string;
        startTime: string;
    },
    {
        lessons: List<Availability>;
        teachers: List<Teacher>;
    },
    Error
>();

/**
 * Init lessons request
 */
export const initLessonsByMonth = createAsyncAction(
    '@@scheduler/init-lessons-by-month/request',
    '@@scheduler/init-lessons-by-month/success',
    '@@scheduler/init-lessons-by-month/failure'
)<
    {
        learnerId: string;
        startTime: string;
    },
    {
        lessons: List<Availability>;
        teachers: List<Teacher>;
    },
    Error
>();

/**
 * Action for setting availability error message message
 */
export const setAvailabilityError = createAction(
    '@@scheduler/set-availability-error'
)<string | null>();

/**
 * Request for load foreseen
 */
export const loadForesen = createAsyncAction(
    '@@scheduler/load-foreseen/request',
    '@@scheduler/load-foreseen/success',
    '@@scheduler/load-foreseen/failure'
)<boolean | void, Foresen, Error>();

/**
 * Request for load foreseen
 */
export const loadGroupForesen = createAsyncAction(
    '@@scheduler/load-foreseen-group/request',
    '@@scheduler/load-foreseen-group/success',
    '@@scheduler/load-foreseen-group/failure'
)<boolean | void, Foresen, Error>();

/**
 * Request for load foreseen virtual-class
 */
export const loadForeseenVCR = createAsyncAction(
    '@@scheduler/load-foreesen-virtual-class/request',
    '@@scheduler/load-foreesen-virtual-class/success',
    '@@scheduler/load-foreesen-virtual-class/failure'
)<string, any, Error>();

export const loadForeseenInit = createAction(
    '@@scheduler/load-init-next-lesson'
)<boolean | void>();

/**
 * Action for setting scheduler current availability
 */
export const setSelectedAvailability = createAction(
    '@@scheduler/set-selected-availability'
)<Availability | null>();

/**
 * Action for setting scheduler current credit
 */
export const setCurrentCredit = createAction(
    '@@scheduler/set-credit'
)<CreditRecord | null>();

/**
 * Action for setting scheduler current duration
 */
export const setCurrentDuration = createAction('@@scheduler/set-duration')<
    string | null
>();

export const resetSchedulerTeacher = createAction(
    '@@scheduler/reset-teacher'
)<SchedulerSelectedTeachersProps>();

/**
 * Action for setting scheduler current media
 */
export const setCurrentMedia = createAction(
    '@@scheduler/set-media'
)<MediaRecord>();

/**
 * Action for toggle popup state
 */
export const togglePopup = createAction('@@scheduler/toggle-popup')<{
    name: 'calendar' | 'cancel';
    value: boolean;
}>();

/**
 * Action for setting scheduler current date
 */
export const selectSchedulerDate = createAction(
    '@@scheduler/select-scheduler-date'
)<Moment>();

/**
 * Action for setting scheduler selected teachers properties
 */
export const changeSelectedTeachers = createAction(
    '@@scheduler/change-selected-teachers'
)<SchedulerSelectedTeachersProps>();

/**
 * Action for setting scheduler current limitation
 */
export const setSchedulerLimitation = createAction(
    '@@scheduler/set-scheduler-limitation'
)<SchedulerLimitation>();

/**
 * Action for setting scheduler current limitation
 */
export const resetForeseenLesson = createAction(
    '@@scheduler/reset-foreseen-lesson'
)<boolean>();

/**
 * Action for scheduler coming from location
 */
export const setOriginLocation = createAction(
    '@@scheduler/set-origin-location'
)<string>();

/**
 * Init language request
 */
export const initLanguage = createAsyncAction(
    '@@scheduler/init-language/request',
    '@@scheduler/init-language/success',
    '@@scheduler/init-language/failure'
)<
    {
        learnerId: string;
    },
    SchedulerLanguageProps,
    Error
>();

/**
 * Create new media request
 */
export const addNewMedia = createAsyncAction(
    '@@learner-profile/add-new-media/request',
    '@@learner-profile/add-new-media/success',
    '@@learner-profile/add-new-media/failure'
)<
    NewMediaProps,
    {
        medias: Map<string, MediaRecord>;
        learner: any;
    },
    Error
>();

export const setIsDisabledVCR = createAction(
    '@@scheduler/foreseen-lesson/set-disabled-vcr'
)<boolean>();

export const setIsVCRStatus = createAction(
    '@@scheduler/foreseen-lesson/set-status-display'
)<string>();
