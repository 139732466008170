import { getCacheStorage } from './get-storage';

function processCacheKeyAsString(args: any[]) {
    return args.join(':');
}

type cacheConfig = {
    processCacheKey?: Function;
    globalPrefix?: string;
    lifetime?: number;
    type?: 'async' | 'sync';
};

export function Cache(config: cacheConfig = {}) {
    const {
        processCacheKey = processCacheKeyAsString,
        type = 'async',
        globalPrefix,
    } = config;

    const memo = getCacheStorage[type](globalPrefix);

    return function cacheHandler(
        _target: any,
        _propertyKey: string,
        descriptor: any
    ) {
        const original = descriptor.value;

        descriptor.value = function (...args: any[]) {
            const key = processCacheKey([args]);

            if (memo.hasItem(key)) {
                return memo.getItem(key);
            }

            const value = original.apply(this, args);
            memo.setItem(key, value);

            return value;
        };
    };
}
