import { of } from 'rxjs';
import { ajax } from 'rxjs/ajax';
import { catchError, map } from 'rxjs/operators';

export interface IUseSSOToRetrieveIdp {
    authnRequest: {
        authnRequestXml: string;
        encodedAuthnRequest: string;
        id: string;
        issueInstant: string;
    };
    idpUrl: string;
    signatureAlgorithm: string | null;
    signature: string | null;
    relayState: string | null;
}

export const UseSSOToRetrieveIdp = (url: string) => {
    return ajax.get(url).pipe(
        map((response) => {
            if (response.status === 200) {
                // Handle successful response
                return response.response as IUseSSOToRetrieveIdp;
            } else {
                // Handle non-200 status code (business rule)
                throw new Error('Invalid status code: ' + response.status);
            }
        }),
        catchError((error) => {
            // Handle network errors or errors thrown from the map operator
            console.error('Error:', error);
            // Handle the error and return a default value or rethrow the error if necessary
            return of(error);
        })
        // Extract response data
    );
};
