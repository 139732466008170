import { createAction } from 'typesafe-actions';

export const trackAICCActivity = createAction('@@track-aicc-activity-event')<{
    learnerUUID: string;
    learnerExternalId: string;
}>();

export const aiccActivityTrackingActions = {
    trackAICCActivity,
};
