import { Record } from 'immutable';

export interface ActiveClassroomProps {
    id: number;
    startDateTime: number;
    length: number;
    message: string;
    status: string;
}

const defaultActiveClassroomProps: ActiveClassroomProps = {
    id: 0,
    startDateTime: 0,
    length: 0,
    message: '',
    status: '',
};

export class ActiveClassroomData
    extends Record(defaultActiveClassroomProps)
    implements ActiveClassroomProps {}

export interface ActiveClassroomSecurityIdProps {
    teacherSecurityId: string;
    teacherUUID: string;
    learnerSecurityId: string;
    learnerUUID: string;
    meetingLink: string;
    eventStatus: string;
    lessonExternalId: number;
    lessonStatus: string;
    lessonStart: string;
    duration: number;
}

const defaultActiveClassroomSecurityProps: ActiveClassroomSecurityIdProps = {
    teacherSecurityId: '',
    teacherUUID: '',
    learnerSecurityId: '',
    learnerUUID: '',
    meetingLink: '',
    eventStatus: '',
    lessonExternalId: 0,
    lessonStatus: '',
    lessonStart: '',
    duration: 0,
};

export class ActiveClassroomSecurityData
    extends Record(defaultActiveClassroomSecurityProps)
    implements ActiveClassroomSecurityIdProps
{
    [x: string]: any;
}
