import { ContentActivityEventType } from './content-activity-event-type';

export class ContentActivityEvent {
    constructor(
        public readonly topicId: string,
        public readonly contentId: string,
        public readonly deviceId: string,
        public readonly userId: string,
        public readonly tabId: string,
        public timestamp: string,
        public readonly type: ContentActivityEventType,

        public readonly quizId?: string,
        public readonly score?: number,
        public readonly attributes?: any,
        public readonly curriculumId?: string,
        public readonly courseId?: string
    ) {}
}
