export const UseConvertStringToObjectMap = (url: string) => {
    // Parse the hash fragment to extract JSON response
    let responseJson: {
        SAMLResponse?: string;
        client?: string;
        RelayState?: string;
    } = {};

    const responseHash = url.split('?')[1];
    responseHash.split('&').forEach(function (pair: string) {
        const keyValue = pair.split('=');
        const dynamicKey = keyValue[0] as keyof typeof responseJson;
        responseJson[dynamicKey] = decodeURIComponent(keyValue[1]);
    });

    return responseJson;
};
