import { List, Map, Record } from 'immutable';

export interface SectionDetails {
    image: string | null;
    articleId: string;
    description: string;
    title: string;
    contentTotal: number;
    contentCompleted: number;
    progressPercentage: number;
    percentsToComplete: number;
    status: string | null;
    score: number;
    contentUUID: string;
    uuid: string;
    resourceType: string;
    createdDate?: string;
}

export enum CourseStatusEnum {
    InProgress = 'IN_PROGRESS',
    Completed = 'COMPLETED',
}

export enum CourseCompletionStatusEnum {
    Failed = 'FAILED',
    Passed = 'PASSED',
}

export interface CourseStatusDetails {
    curriculumId: string;
    legacy: { articleId: string; groupId: string };
    completedContents: number;
    totalContents: number;
    progressPercentage: number;
    percentsToComplete: number;
    progressStatus?: CourseStatusEnum;
    endTimeStamp: string;
    curriculumScoreAvg: number;
    curriculumCompletionStatus?: CourseCompletionStatusEnum;
}

const defaultTrainingPathValue: SectionDetails = {
    image: '',
    articleId: '',
    description: '',
    title: '',
    contentTotal: 0,
    contentCompleted: 0,
    progressPercentage: 0,
    percentsToComplete: 100,
    createdDate: undefined,
    status: null,
    score: 0,
    contentUUID: '',
    uuid: '',
    resourceType: 'Course',
};

export class TrainingPath
    extends Record(defaultTrainingPathValue)
    implements SectionDetails
{
    constructor(params: Partial<SectionDetails> = defaultTrainingPathValue) {
        // Call the Record constructor with the updated params
        super({
            ...params,
            // If this exists in params use it, otherwise use default
            createdDate: params?.createdDate || undefined,
            image: params?.image || null,
            status: params?.status || null,
        });
    }
}

export interface CourseSubCategoryProps {
    id: number;
    title: string;
    tags: Map<string, List<string>>;
    tagsId: Map<string, List<number>>;
    topic: List<CourseTopic>;
}

export interface CourseCategoryProps {
    id: number;
    title: string;
    subcategories: List<CourseSubCategoryProps>;
    topic: List<CourseTopic>;
}

export interface CourseTopic {
    topicUUID: string;
    code: string;
    name: string;
}

export class CourseCategory
    extends Record({
        id: 0,
        title: '',
        subcategories: List<CourseSubCategoryProps>(),
        topic: List<CourseTopic>(),
    })
    implements CourseCategoryProps {}

export class CourseSubCategory
    extends Record({
        id: 0,
        title: '',
        tags: Map<string, List<string>>(),
        tagsId: Map<string, List<number>>(),
        topic: List<CourseTopic>(),
    })
    implements CourseSubCategoryProps {}
