import { ApiHeaders } from '../api-headers';
import { catchError, map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { Axios } from 'axios-observable';
import { contentService, saveContent } from '../api-urls';
import {
    AclResponseInterface,
    SaveContentDTO,
    SaveContentProps,
    SavedContentPostResponse,
} from '../../store/save-content/save-content-record';
import moment from 'moment';

export class SaveContentApi {
    constructor(private readonly apiHeaders: ApiHeaders) {}

    private getPostHeaders() {
        return {
            ...this.apiHeaders.getHeaders(),
            'Content-Type': 'application/json',
        };
    }

    public loadSavedContent(userUUID: string): Observable<SaveContentDTO> {
        return Axios.get<SaveContentProps>(
            `${saveContent()}recommended-resource?dataSource=LearnerBookmark&userId=${userUUID}`,
            {
                headers: this.getPostHeaders(),
            }
        ).pipe(
            map((response) => new SaveContentDTO(response.data)),
            catchError((error) => {
                throw error?.message ? error.message : error;
            })
        );
    }

    saveContent(
        languageId: string,
        userUUID: string,
        contentId: string,
        resourceType: string
    ): Observable<SavedContentPostResponse[]> {
        return Axios.post<SavedContentPostResponse[]>(
            `${saveContent()}`,
            {
                userId: userUUID,
                assignerId: userUUID,
                topicId: languageId,
                contentIds: [contentId],
                resourceType: resourceType,
                dataSource: 'LearnerBookmark',
                dueDate:
                    moment()
                        .locale('en')
                        .add(1, 'M')
                        .format('YYYY-MM-DDTHH:mm:ss.SSS')
                        .toString() + 'Z',
            },
            {
                headers: this.getPostHeaders(),
            }
        ).pipe(
            map((response) => response.data),
            catchError((error) => {
                throw error?.response?.data?.message
                    ? error.response.data
                    : error;
            })
        );
    }

    unSaveContent(
        languageId: string,
        userUUID: string,
        contentId: string
    ): Observable<void> {
        return Axios.delete(`${saveContent()}`, {
            headers: this.getPostHeaders(),
            data: {
                userId: userUUID,
                topicId: languageId,
                contentIds: [contentId],
                dataSource: 'LearnerBookmark',
            },
        }).pipe(
            map((response) => response.data),
            catchError((error) => {
                throw error?.message ? error.message : error;
            })
        );
    }

    public loadSavedContentForDisplay(uuid: string): Observable<any> {
        return Axios.get<AclResponseInterface>(
            `${contentService()}acl/${uuid}`,
            {
                headers: this.getPostHeaders(),
            }
        ).pipe(
            map((response) => response.data),
            catchError((error) => {
                throw error?.message ? error.message : error;
            })
        );
    }
}
