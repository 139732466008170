import { List } from 'immutable';
import { StandaloneQuizDescription } from '../../store/resources/standalone-quiz/standalone-quiz';
import moment from 'moment';

// export interface StandaloneQuizDto {
//     groupId: number;
//     articleId: string;
//     categories: string[];
//     date: string;
//     index: number;
//     tags: string[];
//     name: string;
//     template: string;
//     contentUUId: string;
// }

export class StandaloneQuizParser {
    parseContent(dto: any) {
        return new StandaloneQuizDescription({
            contentMetadata: dto?.contentMetadata ?? null,
            resourceId: dto.articleId,
            categories: List(dto.categories),
            date: moment(dto.date),
            groupId: dto.groupId,
            index: dto.index,
            quizArticleId: dto.articleId,
            tags: List(dto.tags.sort()),
            title: dto.name,
            template: dto.template,
            contentUUId: dto.contentUUId,
        });
    }
}
