import { videoActions } from './resources/videos/videos-actions';
import { articleActions } from './resources/articles/articles-actions';
import { userActions } from './user/user-actions';
import { allHowToActions } from './resources/howtos/howto-actions';
import {
    changeResourceContentProgress,
    changeResourceFrom,
    clearAllResources,
    loadSelectedResource,
    loadSelectedUsingUUIDResource,
    openResource,
    openResourceUsingCuuid,
    setDisplayResource,
} from './resources/resources-actions';

import { offlineActions } from './resources/offline/offline-actions';

import {
    loadSelectedResourceContent,
    startPollingActivityStatuses,
    stopPollingActivityStatuses,
    setDisplayResourceContent,
} from './resources/resources-content-actions';

import {
    clearTranslateData,
    toggleTranslateSwitchAction,
    translateAction,
    translateOriginalText,
} from './resources/translate/translate-actions';
import { actions as quizActions } from './quiz/quiz-actions';
import vcr from './vcr/vcr-actions';
import { trackActivity } from './activity-tracking/activity-tracking-actions';
import { searchResource, setSearchKeyword } from './resources/search';
import * as trainingPathActions from './resources/training-path/training-path-actions';
import * as learnerProfile from './learner-profile/learner-profile-action';
import * as learnerOnboarding from './learner-onboarding/learner-onboarding-action';
import * as moduleRestriction from './module-restriction/module-restriction-action';
import * as scheduler from './scheduler/scheduler-actions';
import * as lessonsList from './scheduler/lessons-list-actions';
import * as adaptiveTest from './adaptive-test/adaptive-test-actions';
import * as displayStrategy from './display-strategy/display-strategy-actions';
import * as homework from './homework/homework-action';
import * as training from './training/training-actions';
import * as insights from './insights/insights-actions';
import * as proficiency from './proficiency/proficiency-actions';
import { gradingActions } from './grading/grading-actions';
import { setMuted } from './autoplay-helper';
import { trackerActions } from '../features/tracking/tracker-actions';
import { portalTrackerActions } from './portal-tracker/portal-tracker-actions';
import { trackContentActivity } from './content-activity-tracking/content-activity-actions';
import { trackAICCActivity } from './aicc-activity-tracking/aicc-activity-actions';
import * as lessonReports from './lesson-reports/lesson-reports-actions';
import { loadCredits } from './credits/credits-actions';
import { changeAppMobileState, changeAppWindowWidth } from './app/app-actions';
import {
    setCountryPrivacyPolicy,
    setPrivacyPolicy,
} from './privacy-policy/privacy-policy-actions';
import {
    loadRecommendedLevel,
    loadRecommendedResources,
    removeRecommendedResources,
    selectedRemoveRecommendedResource,
    selectedReassessmentCard,
} from './resources/recommended';
import { eWritingActions } from './e-writing/e-writing-actions';
import { contractActions } from './contracts/contract-actions';
import { alertActions } from './alert/alert-actions';
import { vcrFetchMessage } from './vcr/persist-message/persist-message-action';
import { activityFiltersActions } from './activity-filters/activity-filters-actions';
import { learnerChatActions } from '../features/contact-us/learner-chat-actions';
import {
    loadInProgressActivities,
    resetInProgressActivities,
} from './resources/in-progress/in-progress-actions';
import {
    loadCompletedActivities,
    resetCompletedActivities,
} from './resources/completed/completed-actions';
import { savedContentActions } from './save-content/save-content-actions';
import { inProgressTabActions } from './in-progress/in-progress-actions';
import { integrationActions } from './integration/integration-actions';
import { channelActions } from './resources/channel/channel-actions';

export const Action = {
    app: {
        changeAppWindowWidth,
        changeAppMobileState,
    },
    privacyPolicy: {
        displayPrivacyPolicy: setPrivacyPolicy,
        countryPreload: setCountryPrivacyPolicy,
    },
    fetchMessageLink: {
        vcrFetchMessage,
    },
    videos: videoActions,
    articles: articleActions,
    howtos: allHowToActions,
    channel: channelActions,
    offline: offlineActions,
    resources: {
        loadSelectedResource,
        loadSelectedUsingUUIDResource,
        searchResource,
        openResource,
        openResourceUsingCuuid,
        setSearchKeyword,
        clearAllResources,
        changeResourceContentProgress,
        changeResourceFrom,
        loadRecommendedResources,
        loadRecommendedLevel,
        removeRecommendedResources,
        selectedRemoveRecommendedResource,
        selectedReassessmentCard,
        loadSelectedResourceContent,
        translateAction,
        toggleTranslateSwitchAction,
        translateOriginalText,
        clearTranslateData,
        loadInProgressActivities,
        resetInProgressActivities,
        loadCompletedActivities,
        resetCompletedActivities,
        startPollingActivityStatuses,
        stopPollingActivityStatuses,
        setDisplayResource,
        setDisplayResourceContent,
    },
    quizzes: quizActions,
    user: userActions,
    activities: {
        trackActivity,
    },
    contentActivityTracking: {
        trackContentActivity,
    },
    aiccTracking: {
        trackAICCActivity,
    },
    trainingPath: trainingPathActions,
    learnerProfile: learnerProfile,
    learnerOnboarding: learnerOnboarding,
    moduleRestriction: moduleRestriction,
    vcr: vcr,
    scheduler: scheduler,
    lessonsList: lessonsList,
    autoplay: {
        setMuted,
    },
    tracker: trackerActions,
    portalTracker: portalTrackerActions,
    adaptiveTest,
    displayStrategy,
    lessonReports,
    homework,
    credits: {
        loadCredits: loadCredits,
    },
    training,
    eWriting: eWritingActions,
    contracts: contractActions,
    insights,
    proficiency,
    alert: alertActions,
    grading: gradingActions,
    activityFilters: activityFiltersActions,
    learnerChat: learnerChatActions,
    contentSavedActions: savedContentActions,
    inProgressTab: inProgressTabActions,
    integration: integrationActions,
};

export default Action;
