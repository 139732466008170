import { createAsyncAction } from 'typesafe-actions';
import { Map } from 'immutable';
import { CreditCreditAPIRecord } from './credits-data';

/*
 *   Action to load credits
 */
export const loadCredits = createAsyncAction(
    '@@credits/load-credits/request',
    '@@credits/load-credits/success',
    '@@credits/load-credits/failure'
)<
    { userUUID: string },
    { credits: Map<string, CreditCreditAPIRecord> },
    Error
>();
