import { CustomThemeProvider } from '@arcadia-projects/portal2-ui-library';
import EventEmitter from 'events';
import { createBrowserHistory, createHashHistory } from 'history';
import React from 'react';
import ReactDOM from 'react-dom';
import App from './components/root/App';
import { AppWrapper } from './components/root/app-wrapper';
import { environment } from './environments';
import './index.css';
import { logger } from './logging';
import configureLogger from './logging/configure-logger';
import { persistorRTK, storeRTK } from './rtk/store';
import * as serviceWorker from './serviceWorker';
import { SSOPid, SSOUtils } from './utils/SSO';
import { configureCordova, isCordova, setDeviceId } from './utils/cordova';
import { ScormUtils } from './utils/scorm';

const rootEl = document.getElementById('root');

ScormUtils.subscribeToGetSCORMSettings();
SSOUtils.getSSOParamsFromURL();

export const isLocalhost = window.location.hostname === 'localhost';
const basename = isLocalhost ? '/' : environment.DEPLOY_PATH;
const history = isCordova
    ? createHashHistory()
    : createBrowserHistory({ basename });

const startApp = (Component: React.FC) => {
    EventEmitter.defaultMaxListeners = 99;
    console.log(' START APP ');
    //TODO: make changes to configuration
    configureLogger();
    logger.info('Application started');

    render(Component);
};

const render = (Component: React.FC) => {
    ReactDOM.render(
        <AppWrapper store={storeRTK} persistor={persistorRTK} history={history}>
            <CustomThemeProvider>
                <Component />
            </CustomThemeProvider>
        </AppWrapper>,
        rootEl
    );
};

if (SSOUtils.isSABATrigger()) {
    SSOUtils.redirectToSSO(SSOPid.saba);
} else if (SSOUtils.isCSODTrigger()) {
    SSOUtils.redirectToSSO(SSOPid.csod);
} else {
    if (isCordova) {
        document.addEventListener(
            'deviceready',
            () => {
                startApp(App);
                configureCordova();
            },
            false
        );
    } else {
        setDeviceId();
        startApp(App);
    }
}

if ((module as any).hot) {
    (module as any).hot.accept('./components/root/App', () => {
        const NextApp = require('./components/root/App').default;
        render(NextApp);
    });
}

// Development logs for accessibility test from axe engine by Deque Labs
// if (process.env.NODE_ENV !== 'production') {
//     let axe = require('react-axe');
//     axe(React, ReactDOM, 5000, axeConfig);
// }

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
if (!isCordova) {
    serviceWorker.unregister();
}
