import { Record } from 'immutable';

export interface DisplayRuleObjectProps {
    component: string;
    display: string;
    order: number;
    subComponent: string;
}

export interface ModuleRestrictionDisplayProps {
    displayHandle: string;
    displayRule: DisplayRuleObjectProps[];
}

export const defaultModuleRestrictionDisplayProps: ModuleRestrictionDisplayProps =
    {
        displayHandle: 'module-restriction',
        displayRule: [],
    };

export class ModuleRestrictionDisplayRecord
    extends Record(defaultModuleRestrictionDisplayProps)
    implements ModuleRestrictionDisplayProps {}
