import { createReducer } from 'typesafe-actions';

import { combineReducers } from 'redux';

import {
    QuizAttemptDescription,
    QuizDescription,
    QuizDescriptionOffline,
} from '../../services/quizzes/quiz';

import { actions } from './quiz-actions';
import { List } from 'immutable';

import {
    setCurrentQuiz,
    setCurrentQuizAttempt,
} from '../resources/offline/offline-actions';

const {
    loadQuiz,
    gotNewQuizResultIdentifier,
    commitedSpeechRecognitionAnswer,
    saveSpeechRecognitionAnswer,
    commitedAnswer,
    reachedQuizEnd,
    retakeQuiz,
    reviewQuizAnswers,
    finishedQuestion,
    displayGenericError,
} = actions;

/**
 * Holds quiz description that doesn't change during quiz progress.
 */
const currentQuiz = createReducer<
    QuizDescription | QuizDescriptionOffline | null
>(null)
    .handleAction(loadQuiz.success, (state, action) => {
        return action.payload;
    })
    .handleAction(loadQuiz.request, () => null)
    .handleAction(setCurrentQuiz, (state, action) => {
        return action.payload;
    });

const quizId = createReducer('')
    .handleAction(loadQuiz.request, () => '')
    .handleAction(
        [loadQuiz.success, setCurrentQuiz],
        (state, { payload: { id } }) => (id ? String(id.qArticleId) : state)
    );

const findWordWithLastCharacter = (
    startIndex: number,
    word: string,
    ans: string
): { index: number; word: string } => {
    const formattedAns = ans.toLowerCase().slice(startIndex);
    const leftIndex = formattedAns.search(word);
    let rightIndex = leftIndex;
    while (
        rightIndex < formattedAns.length &&
        formattedAns[++rightIndex] !== ' '
    ) {}
    return {
        index: rightIndex + startIndex,
        word: formattedAns.slice(leftIndex, rightIndex),
    };
};

/**
 * QuizAttempt encapsulates one attempt to go through quiz,
 * e.g. review is still part of the attempt,
 * but retaking the same quiz results in a different attempt.
 */
const quizAttempt = createReducer(new QuizAttemptDescription())
    .handleAction([loadQuiz.success, setCurrentQuiz], (state, action) => {
        let all: Array<QuizAttemptDescription> = action.payload.attempts;
        let attempt = all.length > 0 ? all[0] : new QuizAttemptDescription();
        return attempt
            .set('questionsCount', action.payload.questions.length)
            .update('correctAnswers', (answers) => {
                return attempt.suppliedAnswers.reduce(
                    (acc, { ord, answer }) => {
                        // check if it's list of answers for SR
                        if (
                            List.isList(answer) &&
                            answer.size > 0 &&
                            (answer.get(0) as any).word
                        ) {
                            return acc.set(
                                ord,
                                // at least one incorrect(falsy)
                                !(
                                    answer.filter(
                                        ({ correct }: any) => !correct
                                    ).size > 0
                                )
                            );
                        } else return acc;
                    },
                    answers
                );
            });
    })
    .handleAction(gotNewQuizResultIdentifier, (state, { payload: { qri } }) =>
        state.set('qri', qri)
    )
    .handleAction(
        commitedAnswer,
        (state, { payload: { ord, answer, question } }) => {
            return state
                .update('suppliedAnswers', (answers) =>
                    answers.set(ord - 1, { ord, answer })
                )
                .update('correctAnswers', (a) =>
                    a.set(question.order, question.isValidAnswer(answer))
                );
        }
    )
    .handleAction(
        commitedSpeechRecognitionAnswer,
        (state, { payload: { ord, answer } }) =>
            state.update('suppliedAnswers', (answers) =>
                answers.set(ord - 1, { ord, answer })
            )
    )
    .handleAction(
        saveSpeechRecognitionAnswer,
        (state, { payload: { ord, result, question } }) => {
            if (result) {
                let answer: { word: string; correct: boolean }[] = [];
                let answerIsCorrect = result.status === 'OK';
                let incorrectExist = false;
                let lastMatchedIndex = 0;
                if (question.correctAnswer.kind === 'string') {
                    const fullAnswer = question.correctAnswer.answer;
                    answer = result?.source.map(({ word, status }) => {
                        if (!incorrectExist && status !== 'Matched') {
                            incorrectExist = true;
                        }

                        const { index: lastIndex, word: wordWithPunctuation } =
                            findWordWithLastCharacter(
                                lastMatchedIndex,
                                word,
                                fullAnswer
                            );

                        const isUppercase =
                            lastMatchedIndex === 0 &&
                            // text node
                            question.stemTokens[0].nodeType === 3 &&
                            // title contains uppercase
                            ((question.stemTokens[0].text.toLowerCase() !==
                                question.stemTokens[0].text &&
                                // title starts with our word
                                question.stemTokens[0].text
                                    .toLowerCase()
                                    .startsWith(word)) ||
                                // ans contains uppercase and it's the first word
                                (fullAnswer.toLowerCase() !== fullAnswer &&
                                    fullAnswer.toLowerCase().startsWith(word)));

                        lastMatchedIndex = lastIndex;
                        return {
                            word: !isUppercase
                                ? wordWithPunctuation
                                : `${wordWithPunctuation[0].toUpperCase()}${wordWithPunctuation.slice(
                                      1
                                  )}`,
                            correct: status === 'Matched',
                        };
                    });
                    if (!incorrectExist && !answerIsCorrect) {
                        answer = answer.map(({ word }) => {
                            return {
                                word,
                                correct: false,
                            };
                        });
                    }
                }

                return state
                    .update('suppliedAnswers', (answers) =>
                        answers.set(ord - 1, {
                            ord,
                            answer,
                        })
                    )
                    .update('correctAnswers', (a) =>
                        a.set(question.order, answerIsCorrect)
                    );
            } else return state;
        }
    )
    .handleAction(finishedQuestion, (state, _) =>
        state.update('currentQuestionNumber', (x) => x + 1)
    )
    .handleAction(reachedQuizEnd, (state, _) => {
        let newState = state.set('currentMode', 'results');

        if (state.currentMode === 'filling-in') {
            return newState.set('completed', true);
        }

        return newState;
    })
    .handleAction(retakeQuiz, (state, _) => {
        return new QuizAttemptDescription({
            questionsCount: state.questionsCount,
        });
    })
    .handleAction(reviewQuizAnswers, (state, _) =>
        state.set('currentQuestionNumber', 1).set('currentMode', 'review')
    )
    .handleAction(setCurrentQuizAttempt, (state, { payload }) => payload);

const error = createReducer<boolean | null>(null).handleAction(
    displayGenericError,
    (state, action) => action.payload
);

export const quizzesReducer = () =>
    combineReducers({
        quiz: currentQuiz,
        quizId,
        quizAttempt,
        error,
    });
