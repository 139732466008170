import { createAction, createAsyncAction } from 'typesafe-actions';
import {
    AssignmentDTO,
    SaveContentProps,
    SavedContentNotification,
    SavedContentPostResponse,
    SavedContentPostResponseKey,
} from './save-content-record';
import { ResourceContent } from '../resources/resource-content';

export const getSavedContentItems = createAsyncAction(
    '@@saved-content/load-saved-content/request',
    '@@saved-content/load-saved-content/success',
    '@@saved-content/load-saved-content/failure',
    '@@saved-content/load-saved-content/cancelled'
)<boolean, SaveContentProps | null, Error, null>();

export const loadSavedDisplayContent = createAsyncAction(
    '@@savedContent/load-displayed-content/request',
    '@@savedContent/load-displayed-content/success',
    '@@savedContent/load-displayed-content/failure'
)<AssignmentDTO | SavedContentPostResponseKey, ResourceContent | null, Error>();

export const saveContent = createAsyncAction(
    '@@saved-content/save/request',
    '@@saved-content/save/success',
    '@@saved-content/save/failure'
)<{ id: string; item: ResourceContent }, SavedContentPostResponse, Error>();

export const unSaveContent = createAsyncAction(
    '@@saved-content/unSave/request',
    '@@saved-content/unSave/success',
    '@@saved-content/unSave/failure'
)<{ id: string; item: ResourceContent }, void, Error>();

export const setSavedContent = createAction('@@saved-content/set/data')<
    AssignmentDTO[]
>();

export const setSavedContentToDisplay = createAction(
    '@@saved-content/set/to/display'
)<ResourceContent[]>();

export const setNotification = createAction(
    '@@saved-content/save/notification'
)<SavedContentNotification>();

export const clearLoadedSavedDisplayContent = createAction(
    '@@saved-content/load-display-content/clear'
)();

export const savedContentActions = {
    clearLoadedSavedDisplayContent,
    loadSavedDisplayContent,
    getSavedContentItems,
    saveContent,
    unSaveContent,
    setSavedContent,
    setNotification,
    setSavedContentToDisplay,
};
