import { useSelector } from 'react-redux';
import {
    ShowCCModalSelector,
    ShowGLModalSelector,
    ShowILModalSelector,
    ShowPerformanceModalSelector,
} from '../slice';
import { useGetSurveysTranslatedItems } from './useGetSurveysTranslatedItems';
import { ILearnerSurveysData } from '../types';

export const useGetLearnerSurveysData = (): ILearnerSurveysData => {
    const isILOpened = useSelector(ShowILModalSelector);
    const isCCOpened = useSelector(ShowCCModalSelector);
    const isGLOpened = useSelector(ShowGLModalSelector);
    const isPSOpened = useSelector(ShowPerformanceModalSelector);
    const isOpened = isPSOpened || isILOpened || isCCOpened || isGLOpened;
    const {
        IndividualLesson,
        ConversationClass,
        GroupLesson,
        PerformanceSurvey,
    } = useGetSurveysTranslatedItems();

    if (isPSOpened) {
        return {
            content: PerformanceSurvey,
            businessType: 'LearnerPortal',
            isOpened,
        };
    } else if (isILOpened) {
        return {
            content: IndividualLesson,
            businessType: 'IndividualLesson',
            isOpened,
        };
    } else if (isCCOpened) {
        return {
            content: ConversationClass,
            businessType: 'ConversationClass',
            isOpened,
        };
    } else if (isGLOpened) {
        return {
            content: GroupLesson,
            businessType: 'GroupLesson',
            isOpened,
        };
    } else {
        return {
            content: null,
            businessType: null,
            isOpened: false,
        };
    }
};
