import { ApiHeaders } from './api-headers';
import { from, lastValueFrom, Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { activityReport, user } from './api-urls';
import { Cache } from '../utils/cache';

import { ajax } from 'rxjs/ajax';
import moment from 'moment';

export interface IActivityLessonApi {
    loadLessonActivitiesList(
        learnerUUID: string,
        topicUUID?: string | null | undefined
    ): Observable<ActivityTrainingLessonDto[]>;

    loadLessonActivitiesListWithTrainers(
        learnerUUID: string,
        topicUUID?: string
    ): Observable<ActivityTrainingLessonDto[]>;
}

export enum ActivityTrainingLessonStatus {
    EmailReportSent = 'EmailReportSent',
    Foreseen = 'Foreseen',
    NoShowLearner = 'NoShowLearner',
    Completed = 'Completed',
}

export enum ActivityTrainingLessonReportStatus {
    Available = 'Available',
    Pending = 'Pending',
}

export interface ActivityTrainingLessonDto {
    dateTimeEnded: string;
    dateTimeStarted: string;
    dateTimeEndedUTC: string;
    duration: number;
    lessonId: number;
    mediaId: number;
    mediaType: string;
    portraitId: number;
    topicName: string;
    topicUUID: string;
    trainerUUID: string;
    trainer?: UserDto;
    status: ActivityTrainingLessonStatus;
    lessonReport: ContentLinkDto;
    lessonReportStatus: ActivityTrainingLessonReportStatus;
}

export function isActivityTrainingLesson(
    item: any
): item is ActivityTrainingLessonDto {
    return (item as ActivityTrainingLessonDto).mediaType !== undefined;
}

export interface ContentLinkDto {
    articleId: number;
    contentType: string;
    groupId: number;
    uuid: string;
}

export interface UserDto {
    active?: boolean;
    firstname: string;
    lastname: string;
    firstName: string;
    lastName: string;
    uuid: string;
    avatar: string;
    teacherUUID: string;
}

export class ActivityLessonApi implements IActivityLessonApi {
    apiHeaders: ApiHeaders;

    constructor(apiHeaders: ApiHeaders) {
        this.apiHeaders = apiHeaders;
    }

    loadLessonActivitiesList(
        learnerUUID: string,
        topicUUID?: string | null
    ): Observable<ActivityTrainingLessonDto[]> {
        let url = activityReport() + `lesson/${learnerUUID}`;
        if (topicUUID) url += `?topic=${topicUUID}`;

        return ajax.getJSON<any>(url, this.apiHeaders.getHeaders()).pipe(
            map((r) => {
                return r
                    .sort((i: any, j: any) => {
                        return -(
                            moment(i.dateTimeStarted).unix() -
                            moment(j.dateTimeStarted).unix()
                        );
                    })
                    .map((l: any) => {
                        return {
                            // using moment here for dates helps us to show
                            // correct date in training page since start
                            dateTimeEnded: moment(l.dateTimeEnded).format(
                                'YYYY-MM-DDTHH:mm:ss'
                            ),
                            dateTimeStarted: moment(l.dateTimeStarted).format(
                                'YYYY-MM-DDTHH:mm:ss'
                            ),
                            dateTimeEndedUTC: l.dateTimeEnded,
                            duration: l.duration,
                            lessonId: l.lessonId,
                            mediaId: l.mediaId,
                            mediaType: l.mediaType,
                            portraitId: l.portraitId,
                            topicName: l.topicName,
                            topicUUID: l.topicUUID,
                            trainerUUID: l.trainerUUID,
                            status: l.status as ActivityTrainingLessonStatus,
                            lessonReport: l.lessonReport,
                            lessonReportStatus: l.lessonReport
                                ? ActivityTrainingLessonReportStatus.Available
                                : ActivityTrainingLessonReportStatus.Pending,
                        } as ActivityTrainingLessonDto;
                    });
            })
        );
    }

    public loadLessonActivitiesListWithTrainers(
        learnerUUID: string,
        topicUUID?: string
    ): Observable<ActivityTrainingLessonDto[]> {
        return from(
            this.loadLessonActivitiesListFilled(learnerUUID, topicUUID)
        );
    }

    private async loadLessonActivitiesListFilled(
        learnerUUID: string,
        topicUUID?: string | null
    ): Promise<ActivityTrainingLessonDto[]> {
        let list = await lastValueFrom(
            this.loadLessonActivitiesList(learnerUUID, topicUUID)
        );
        if (list) {
            let trainerIds = list
                .map((lesson: ActivityTrainingLessonDto) => lesson?.trainerUUID)
                .filter((id) => id);

            let uniqueTrainerIds = trainerIds.filter(
                (v, i, a) => a.indexOf(v) === i
            );

            let trainers = await this.loadUsers(uniqueTrainerIds);

            let newItems = Array<ActivityTrainingLessonDto>();
            list.forEach((l) => {
                l.trainer = trainers.find(
                    (t) =>
                        t.teacherUUID === l.trainerUUID ||
                        t.uuid === l.trainerUUID
                );
                newItems.push(l);
            });
            return lastValueFrom(of(newItems));
        } else {
            return [];
        }
    }

    private async loadUsers(ids: string[]): Promise<UserDto[]> {
        if (ids.length > 0) {
            const requests = ids.map((t) => this.loadUser(t));
            return Promise.all(requests);
        }

        return lastValueFrom(of([]));
    }

    @Cache({ globalPrefix: 'loadProfile' })
    private loadUser(userId: string): Promise<UserDto> {
        return lastValueFrom(
            ajax
                .getJSON<any>(user() + userId, this.apiHeaders.getHeaders())
                .pipe(
                    map((r) => {
                        return r as unknown as UserDto;
                    })
                )
        );
    }
}
