import { createReducer } from 'typesafe-actions';
import { Action } from '../../root-action';
import {
    ActiveClassroomData,
    ActiveClassroomSecurityData,
} from './classroom-data';
import { combineReducers } from 'redux';
import { websocket } from './websocket/websocket-reducers';
import { board } from './board/board-reducers';
import { chat } from './chat/chat-reducers';
import { call } from './call/call-reducers';
import { clearClassroom } from '../vcr-actions';
import { VirtualClassDto } from '../list/classroom-list-data';
import { LoadingState } from '../../resources/resources';
import { clearConversation } from '../list/classroom-list-actions';

const activeClassroom = createReducer<ActiveClassroomData | null>(null)
    .handleAction(
        [
            Action.vcr.classroom.registerUserToClassroom.success,
            Action.vcr.classroom.checkActiveClassroom.success,
        ],
        (state, action) => action.payload
    )
    .handleAction(
        [
            Action.vcr.classroom.registerUserToClassroom.failure,
            Action.vcr.classroom.checkActiveClassroom.failure,
            Action.vcr.clearClassroom,
        ],
        () => null
    );

const personalClassroom = createReducer<ActiveClassroomData | null>(null)
    .handleAction(
        Action.vcr.classroom.getPersonalClassroom.success,
        (state, action) => action.payload
    )
    .handleAction(
        [Action.vcr.classroom.getPersonalClassroom.failure, clearClassroom],
        () => null
    );

const isLoadingActiveClassroom = createReducer(false)
    .handleAction(
        [Action.vcr.classroom.checkActiveClassroom.request],
        (state, { payload = true }) => Boolean(payload)
    )
    .handleAction(
        [Action.vcr.classroom.registerUserToClassroom.request],
        () => true
    )
    .handleAction(
        [
            Action.vcr.classroom.registerUserToClassroom.success,
            Action.vcr.classroom.registerUserToClassroom.failure,
            Action.vcr.classroom.checkActiveClassroom.success,
            Action.vcr.classroom.checkActiveClassroom.failure,
        ],
        () => false
    );

const isErrorActiveClassroom = createReducer(false)
    .handleAction(
        [
            Action.vcr.classroom.registerUserToClassroom.failure,
            Action.vcr.classroom.checkActiveClassroom.failure,
        ],
        () => true
    )
    .handleAction(
        [
            Action.vcr.classroom.registerUserToClassroom.success,
            Action.vcr.classroom.checkActiveClassroom.success,
        ],
        () => false
    );

const videoSrc = createReducer<string>('')
    .handleAction(
        Action.vcr.classroom.videoTrainer.setVideoSrc,
        (state, action) => action.payload
    )
    .handleAction(clearClassroom, () => '');

const isShowing = createReducer<boolean>(false)
    .handleAction(
        Action.vcr.classroom.videoTrainer.showTrainer,
        (state, action) => action.payload
    )
    .handleAction(clearClassroom, () => false);

const videoTrainer = combineReducers({
    videoSrc,
    isShowing,
});

const personalClassroomSecurityId =
    createReducer<ActiveClassroomSecurityData | null>(null)
        .handleAction(
            Action.vcr.classroom.getPersonalClassroomSecurity.success,
            (state, action) => action.payload
        )
        .handleAction(
            [
                Action.scheduler.resetForeseenLesson,
                Action.vcr.classroom.getPersonalClassroomSecurity.failure,
                clearClassroom,
            ],
            () => null
        );

const isNewAttendee = createReducer<boolean>(false).handleAction(
    Action.vcr.classroom.isNewAttendee,
    (state, action) => action.payload
);

const selectedRoom = createReducer<VirtualClassDto | null>(null)
    .handleAction(
        Action.vcr.classroom.setSelectedRoom,
        (state, action) => action.payload
    )
    .handleAction(clearClassroom, () => null);

const countViewableCam = createReducer<any>([])
    .handleAction(
        Action.vcr.classroom.videoTrainer.setViewableParticipant,
        (state, action) => action.payload
    )
    .handleAction(clearClassroom, () => '');

const loading = createReducer<string>('idle')
    .handleAction(
        Action.vcr.list.getVCRToken.request,
        (state, action) => 'pending'
    )
    .handleAction(
        Action.vcr.list.getVCRToken.success,
        (state, action) => 'succeeded'
    )
    .handleAction(
        Action.vcr.list.getVCRToken.failure,
        (state, action) => 'failed'
    )
    .handleAction(clearClassroom, () => 'idle');

const loadingKick = createReducer<LoadingState>('idle')
    .handleAction(
        Action.vcr.classroom.checkKickoutClassStatus.request,
        () => 'pending'
    )
    .handleAction(
        Action.vcr.classroom.checkKickoutClassStatus.success,
        () => 'succeeded'
    )
    .handleAction(
        Action.vcr.classroom.checkKickoutClassStatus.failure,
        () => 'failed'
    )
    .handleAction(clearConversation, () => 'idle');

const kickOutClass = createReducer<any>(null)
    .handleAction(
        Action.vcr.classroom.checkKickoutClassStatus.success,
        (state, action) => action.payload
    )
    .handleAction(clearClassroom, () => null);

const joinedParticipants = createReducer<any>(null)
    .handleAction(
        [
            Action.vcr.classroom.getJoinedParticipants.success,
            Action.vcr.classroom.updateJoinedParticipantsByClassId.success,
        ],
        (state, action) => action.payload
    )
    .handleAction(
        Action.vcr.classroom.setJoinedParticipants,
        (state, action) => action.payload
    );

const connectedInRoom = createReducer<boolean>(false)
    .handleAction(
        Action.vcr.classroom.setConnectedInRoom,
        (state, action) => action.payload
    )
    .handleAction(clearConversation, () => false);

export const classroom = combineReducers({
    activeClassroom,
    isNewAttendee,
    isLoadingActiveClassroom,
    isErrorActiveClassroom,
    personalClassroom,
    personalClassroomSecurityId,
    chat,
    websocket,
    board,
    videoTrainer,
    call,
    selectedRoom,
    countViewableCam,
    loading,
    loadingKick,
    kickOutClass,
    joinedParticipants,
    connectedInRoom,
});
