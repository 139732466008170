import { createAsyncAction } from 'typesafe-actions';
import { InProgressResource } from './in-progress-record';

export const loadInProgressActivities = createAsyncAction(
    '@@resources/in-progress/request',
    '@@resources/in-progress/success',
    '@@resources/in-progress/failure'
)<undefined, InProgressResource[] | null, Error>();

export const resetInProgressActivities = createAsyncAction(
    '@@resources/in-progress-reset/request',
    '@@resources/in-progress-reset/success',
    '@@resources/in-progress-reset/failure'
)<undefined, undefined, Error>();

export const inProgressActions = { loadInProgressActivities };
