import { createAsyncAction, createAction } from 'typesafe-actions';
import { List, Map } from 'immutable';
import {
    AvailabilityGroupLesson,
    Availability,
    Teacher,
    CreditRecord,
    Foresen,
    MediaRecord,
    SchedulerLanguageProps,
} from './scheduler-data';
import { Moment } from 'moment';

/**
 * Initialize lessons list action, inits request for scheduler initialization
 */
export const initializeLessonsList = createAsyncAction(
    '@@lessons-list/init/request',
    '@@lessons-list/init/success',
    '@@lessons-list/init/failure'
)<
    {
        learnerID: string;
        selectedDate?: Moment;
    },
    {
        teachers: List<Teacher>;
        lessons: List<Availability>;
        credits: Map<string, CreditRecord>;
        medias: Map<string, MediaRecord>;
        learner: any;
        sat: boolean;
    },
    Error
>();

/**
 * Action fired after lessons list initialization
 */
export const initializeLessonsListEnded = createAction(
    '@@lessons-list/init/ended'
)();

/**
 * Request for initializing week lessons
 */
export const loadInitWeekLessons = createAsyncAction(
    '@@lessons-list/load-init-week-lessons/request',
    '@@lessons-list/load-init-week-lessons/success',
    '@@lessons-list/load-init-week-lessons/failure'
)<
    {
        learnerId: string;
        startDate: Moment;
    },
    {
        lessons: List<Availability>;
    },
    Error
>();

/**
 * Request for load availabilities
 */
export const loadAvailabilities = createAsyncAction(
    '@@lessons-list/load-avails/request',
    '@@lessons-list/load-avails/success',
    '@@lessons-list/load-avails/failure'
)<
    {
        learnerID: string;
        selectAllAvailable: boolean;
        selectedTeachers: List<Teacher>;
        startDate: Moment;
    },
    {
        availabilities: List<Availability>;
        teachers: List<Teacher>;
        selectAllAvailable: boolean;
    },
    Error
>();

/**
 * Request for load more availabilities
 */
export const loadMoreAvailabilities = createAsyncAction(
    '@@lessons-list/load-more-avails/request',
    '@@lessons-list/load-more-avails/success',
    '@@lessons-list/load-more-avails/failure'
)<
    {
        learnerID: string;
        selectAllAvailable: boolean;
        selectedTeachers: List<Teacher>;
        startDate: Moment;
    },
    {
        availabilities: List<Availability>;
        teachers: List<Teacher>;
    },
    Error
>();

/**
 * Action for lessons list current date selection
 */
export const selectDate = createAction('@@lessons-list/select-date')<Moment>();

/**
 * Action for toggling SelectAll parameter in lessons list
 */
export const selectAllTeachers = createAction(
    '@@lessons-list/all-teachers'
)<boolean>();

/**
 * Action fired after lessons list opening
 */
export const opened = createAction('@@lessons-list/opened')();

/**
 * Action for scheduler current date selection
 */
export const selectMonthDate = createAction(
    '@@lessons-list/select-month-date'
)<Moment>();

/**
 * Action fired after lessons list setting opened
 */
export const settingsOpen = createAction('@@lessons-list/settings-opend')();

/**
 * Cancel lesson request
 */
export const cancelLesson = createAsyncAction(
    '@@lessons-list/cancel-lesson/request',
    '@@lessons-list/cancel-lesson/success',
    '@@lessons-list/cancel-lesson/failure'
)<
    {
        lessonId: string;
    },
    any,
    Error
>();

/**
 * Lesson confirm cancelling request
 */
export const confirmedCancelLesson = createAsyncAction(
    '@@lessons-list/confirmed-cancel-lesson/request',
    '@@lessons-list/confirmed-cancel-lesson/success',
    '@@lessons-list/confirmed-cancel-lesson/failure'
)<
    {
        lessonId: string;
    },
    any,
    Error
>();

/**
 * Abort cancellation action
 */
export const abortCancellation = createAction(
    '@@lessons-list/abort-cancellation'
)();

/**
 * Init lessons request
 */
export const initLessons = createAsyncAction(
    '@@lessons-list/init-lessons/request',
    '@@lessons-list/init-lessons/success',
    '@@lessons-list/init-lessons/failure'
)<
    {
        learnerId: string;
        startTime: string;
    },
    {
        lessons: List<Availability>;
        groupLessonAvail: List<AvailabilityGroupLesson>;
        teachers: List<Teacher>;
    },
    Error
>();

/**
 * Action for setting availability error message message
 */
export const setAvailabilityError = createAction(
    '@@lessons-list/set-availability-error'
)<string | null>();

/**
 * Request for load foreseen
 */
export const loadForesen = createAsyncAction(
    '@@lessons-list/load-foreseen/request',
    '@@lessons-list/load-foreseen/success',
    '@@lessons-list/load-foreseen/failure'
)<void, Foresen, Error>();

/**
 * Action for setting lessons list current availability
 */
export const setSelectedAvailability = createAction(
    '@@lessons-list/set-selected-availability'
)<Availability | null>();

/**
 * Action for setting lessons list current credit
 */
export const setCurrentCredit = createAction(
    '@@lessons-list/set-credit'
)<CreditRecord | null>();

/**
 * Action for setting lessons list current duration
 */
export const setCurrentDuration = createAction(
    '@@lessons-list/set-duration'
)<string>();

/**
 * Action for setting lessons list current media
 */
export const setCurrentMedia = createAction(
    '@@lessons-list/set-media'
)<MediaRecord>();

/**
 * Action for toggle popup state
 */
export const togglePopup = createAction('@@lessons-list/toggle-popup')<{
    name: 'calendar' | 'cancel';
    value: boolean;
}>();

/**
 * Action for setting lessons list current date
 */
export const selectLessonsListDate = createAction(
    '@@lessons-list/select-scheduler-date'
)<Moment>();

/**
 * Init language request
 */
export const initLanguage = createAsyncAction(
    '@@lessons-list/init-language/request',
    '@@lessons-list/init-language/success',
    '@@lessons-list/init-language/failure'
)<
    {
        learnerId: string;
    },
    SchedulerLanguageProps,
    Error
>();
