import {
    PerformanceSurveyElement,
    PerformanceSurveyStepQuestions,
} from '../types';

export const prepareAnswerForSubmit = (
    answers: PerformanceSurveyStepQuestions[]
) => {
    const defaultAnswer = (answer: PerformanceSurveyStepQuestions) => {
        if (answer.questionType === 'Open') return 0;
        return '';
    };
    return answers.reduce((acc: PerformanceSurveyElement, answer) => {
        const keyInputType =
            answer.questionType.toLocaleLowerCase() === 'score'
                ? 'score'
                : 'text';
        acc = {
            ...acc,
            [answer.option]: {
                type: answer.type,
                questionType: answer.questionType,
                [keyInputType]: answer?.score || defaultAnswer(answer),
            },
        };
        return acc;
    }, {});
};
