import { RootEpic } from '../root-epic';
import {
    catchError,
    concatMap,
    filter,
    map,
    switchMap,
    withLatestFrom,
} from 'rxjs/operators';
import { isActionOf } from 'typesafe-actions';
import { Action } from '../../store/root-action';
import { of } from 'rxjs';
import { getStatusLoadingActions, modifyResourceStatus } from './helpers';
import { ResourceContent } from '../../store/resources/resource-content';

const recommendedEpic: RootEpic = (action$, state$, { recommendedApi }) =>
    action$.pipe(
        filter(isActionOf(Action.resources.loadRecommendedResources.request)),
        withLatestFrom(state$),
        switchMap(([x, state]) =>
            recommendedApi
                .loadRecommendedItems(
                    state.learnerProfile.currentLanguage
                        ?.languageUUID as string,
                    state.user.identity?.learnerUUID as string
                )
                .pipe(
                    concatMap((loadedResources) => {
                        const inProgressResources =
                            state.resources.inProgress.list;
                        const completedResources =
                            state.resources.completed.list;
                        const actualizedResources = loadedResources.map(
                            modifyResourceStatus<ResourceContent>(
                                inProgressResources,
                                completedResources
                            )
                        );

                        return of(
                            ...getStatusLoadingActions(
                                inProgressResources,
                                completedResources
                            ),
                            Action.resources.loadRecommendedResources.success(
                                actualizedResources as ResourceContent[]
                            )
                        );
                    }),
                    catchError((y) =>
                        of(Action.resources.loadRecommendedResources.failure(y))
                    )
                )
        )
    );

const hideRecommendedEpic: RootEpic = (action$, state$, { recommendedApi }) =>
    action$.pipe(
        filter(isActionOf(Action.resources.removeRecommendedResources.request)),
        switchMap((x) => {
            return recommendedApi
                .removeRecommendedItem({
                    topicUUID: state$.value.learnerProfile?.currentLanguage
                        ?.languageUUID as string,
                    userUUID: state$.value.learnerProfile?.profile
                        ?.learnerUUID as string,
                    contentId:
                        state$.value.resources?.recommended?.selectedHideItem,
                    reason: x.payload.reason,
                })
                .pipe(
                    switchMap((y) => {
                        let newContentResource = [] as ResourceContent[];
                        state$.value.resources?.recommended?.items?.forEach(
                            (item) => {
                                if (
                                    item.id !==
                                    state$.value.resources?.recommended
                                        ?.selectedHideItem
                                ) {
                                    newContentResource.push(item);
                                }
                            }
                        );

                        return of(
                            Action.resources.removeRecommendedResources.success(
                                newContentResource
                            ),
                            Action.resources.selectedRemoveRecommendedResource(
                                ''
                            )
                        );
                    }),
                    catchError((y) =>
                        of(
                            Action.resources.removeRecommendedResources.failure(
                                y
                            )
                        )
                    )
                );
        })
    );

const recommendedLevelEpic: RootEpic = (action$, state$, { recommendedApi }) =>
    action$.pipe(
        filter(isActionOf(Action.resources.loadRecommendedLevel.request)),
        switchMap((x) =>
            recommendedApi
                .loadTestLevel(
                    state$.value.learnerProfile.currentLanguage
                        ?.languageUUID as string,
                    state$.value.user.identity?.learnerUUID as string
                )
                .pipe(
                    map((y) =>
                        Action.resources.loadRecommendedLevel.success(y)
                    ),
                    catchError((y) =>
                        of(Action.resources.loadRecommendedLevel.failure(y))
                    )
                )
        )
    );

export const recommendedEpics = [
    recommendedEpic,
    recommendedLevelEpic,
    hideRecommendedEpic,
];
