export enum SSOPid {
    csod = 'csod',
    sabatest = 'sabatest',
    sabaAsterisk = 'saba*',
    saba = 'saba',
    aicc = 'boehringer',
    ocn = 'sfcpart001073',
    lufthansatest = 'lufthansatest',
    lufthansagroup = 'lufthansagroup',
}

export const redirectLink = (pID: string) => {
    const attrs = localStorage.getItem('attrs');
    const path = `/samlsso/sp.jsp?pid=${pID}&attrs=
      ${
          attrs
              ? attrs
              : btoa(window.location.pathname + window.location.search)
      }`;

    return window.location.assign(path);
};

export class SSOUtils {
    static sso_type: string | null;
    static client: string | null;
    static client_id: string | null;
    static idle_timeout: string | null;
    static userGuid: string | null;
    static sessionToken: string | null;
    static callbackUrl: string | null;
    static fqdn: string | null;
    static registrationNumber: string | null;
    static subdomain: string | null;
    static localePref: string | null;
    static languagePref: string | null;
    static source: string | null;
    static aiccSID: string | null;
    static aiccURL: string | null;
    static ocnCourseId: string | null;
    static provision: string | null;

    static getSSOParamsFromURL(): void {
        const queryParams = new URLSearchParams(window.location.search);
        const fromSSOValidator = queryParams.get('RelayState');
        this.client = queryParams.get('client');

        if (fromSSOValidator) {
            console.log('has config relay state for new saml');
            this.setParameters(
                new URLSearchParams(fromSSOValidator.split('?')[1])
            );
        } else {
            console.log('has config old saml');
            this.setParameters(queryParams);
        }

        if (this.idle_timeout)
            localStorage.setItem('idleTimeout', this.idle_timeout);

        if (this.userGuid) localStorage.setItem('userGuid', this.userGuid);

        if (this.sessionToken)
            localStorage.setItem('sessionToken', this.sessionToken);

        if (this.callbackUrl)
            localStorage.setItem('callbackUrl', this.callbackUrl);

        if (this.fqdn) localStorage.setItem('fqdn', this.fqdn);

        if (this.registrationNumber)
            localStorage.setItem('registrationNumber', this.registrationNumber);

        if (this.subdomain) localStorage.setItem('subdomain', this.subdomain);

        if (this.languagePref)
            localStorage.setItem('languagePref', this.languagePref);

        if (this.localePref)
            localStorage.setItem('localePref', this.localePref);

        if (this.source) localStorage.setItem('source', this.source);

        if (this.client) localStorage.setItem('client', this.client);

        if (this.client_id) localStorage.setItem('client_id', this.client_id);

        if (this.sso_type) localStorage.setItem('sso_type', this.sso_type);

        if (this.aiccSID) localStorage.setItem('aiccSID', this.aiccSID);

        if (this.aiccURL) localStorage.setItem('aiccURL', this.aiccURL);

        if (this.ocnCourseId)
            localStorage.setItem('ocnCourseId', this.ocnCourseId);

        if (this.provision) localStorage.setItem('provision', this.provision);
    }

    static setParameters(urlParams: URLSearchParams) {
        this.sso_type = urlParams.get('sso_type');
        this.client_id = urlParams.get('client_id');
        this.idle_timeout = urlParams.get('idleTimeout');
        this.userGuid = urlParams.get('userGuid');
        this.sessionToken = urlParams.get('sessionToken');
        this.callbackUrl = urlParams.get('callbackUrl');
        this.fqdn = urlParams.get('fqdn');
        this.registrationNumber = urlParams.get('registrationNumber');
        this.subdomain = urlParams.get('subdomain');
        this.languagePref = urlParams.get('ltl');
        this.localePref = urlParams.get('lui');
        this.source = urlParams.get('source');
        this.aiccSID = urlParams.get('AICC_SID');
        this.aiccURL = urlParams.get('AICC_URL');
        this.ocnCourseId = urlParams.get('ocn_courseid');
        this.provision = urlParams.get('provision');
    }

    static isCSODTrigger(): boolean {
        const urlParams = new URLSearchParams(window.location.search);
        const sso_type =
            urlParams.get('sso_type') ||
            this.sso_type ||
            localStorage.getItem('sso_type');
        const client_id =
            urlParams.get('client_id') ||
            this.client_id ||
            localStorage.getItem('client_id');
        const client =
            urlParams.get('client') ||
            this.client ||
            localStorage.getItem('client');

        return Boolean(
            sso_type &&
                (client_id === SSOPid.csod || client === SSOPid.csod) &&
                !localStorage.getItem('Authorization')
        );
    }

    static isSABATrigger(): boolean {
        const urlParams = new URLSearchParams(window.location.search);
        const sso_type =
            urlParams.get('sso_type') ||
            this.sso_type ||
            localStorage.getItem('sso_type');
        const client =
            urlParams.get('client') ||
            this.client ||
            localStorage.getItem('client');
        const fqdn =
            urlParams.get('fqdn') || this.fqdn || localStorage.getItem('fqdn');

        return Boolean(
            sso_type &&
                client === SSOPid.csod &&
                fqdn &&
                !localStorage.getItem('Authorization')
        );
    }

    static isCSODTriggerPortal(): boolean {
        const urlParams = new URLSearchParams(window.location.search);
        const sso_type =
            urlParams.get('sso_type') ||
            this.sso_type ||
            localStorage.getItem('sso_type');
        const client =
            urlParams.get('client') ||
            this.client ||
            localStorage.getItem('client');
        const client_id =
            urlParams.get('client_id') ||
            this.client_id ||
            localStorage.getItem('client_id');

        return Boolean(
            sso_type && (client_id === SSOPid.csod || client === SSOPid.csod)
        );
    }

    static isSABATriggerPortal(): boolean {
        const urlParams = new URLSearchParams(window.location.search);
        const sso_type =
            urlParams.get('sso_type') ||
            this.sso_type ||
            localStorage.getItem('sso_type');
        const client =
            urlParams.get('client') ||
            this.client ||
            localStorage.getItem('client');
        const fqdn =
            urlParams.get('fqdn') || this.fqdn || localStorage.getItem('fqdn');

        return Boolean(
            sso_type &&
                (client === SSOPid.saba ||
                    client === SSOPid.sabatest ||
                    client === SSOPid.sabaAsterisk) &&
                fqdn
        );
    }

    static isSABATriggerHook(): boolean {
        const urlParams = new URLSearchParams(window.location.search);
        const source =
            urlParams.get('source') ||
            this.source ||
            localStorage.getItem('source');
        const sso_type =
            urlParams.get('sso_type') ||
            this.sso_type ||
            localStorage.getItem('sso_type');
        const client =
            urlParams.get('client') ||
            this.client ||
            localStorage.getItem('client');
        const fqdn =
            urlParams.get('fqdn') || this.fqdn || localStorage.getItem('fqdn');

        return Boolean(
            sso_type &&
                (source?.toLocaleLowerCase() === SSOPid.saba ||
                    source?.toLocaleLowerCase() === SSOPid.sabatest ||
                    source?.toLocaleLowerCase() === SSOPid.sabaAsterisk) &&
                client === SSOPid.csod &&
                fqdn
        );
    }

    static isCSODTriggerHook(): boolean {
        const urlParams = new URLSearchParams(window.location.search);
        const source =
            urlParams.get('source') ||
            this.source ||
            localStorage.getItem('source');
        const sso_type =
            urlParams.get('sso_type') ||
            this.sso_type ||
            localStorage.getItem('sso_type');
        const client =
            urlParams.get('client') ||
            this.client ||
            localStorage.getItem('client');

        return Boolean(
            sso_type &&
                client?.toLocaleLowerCase() === SSOPid.csod &&
                (source?.toLocaleLowerCase() === SSOPid.csod || client === null)
        );
    }

    static isAICCTriggerHook(): boolean {
        const urlParams = new URLSearchParams(window.location.search);
        const aiccSID =
            urlParams.get('AICC_SID') ||
            this.aiccSID ||
            localStorage.getItem('aiccSID');
        const aiccURL =
            urlParams.get('AICC_URL') ||
            this.aiccURL ||
            localStorage.getItem('aiccURL');

        return Boolean(aiccURL && aiccSID);
    }

    static isUseProvisioning(): boolean {
        const urlParams = new URLSearchParams(window.location.search);
        const provision =
            urlParams.get('provision') ||
            this.provision ||
            localStorage.getItem('provision');

        return Boolean(provision && provision === 'true');
    }

    static isOCNTriggerHook(): boolean {
        const urlParams = new URLSearchParams(window.location.search);
        const sso_type =
            urlParams.get('sso_type') ||
            this.sso_type ||
            localStorage.getItem('sso_type');

        const ocnCourseId =
            urlParams.get('ocn_courseid') ||
            this.ocnCourseId ||
            localStorage.getItem('ocnCourseId');

        return Boolean(sso_type && ocnCourseId);
    }

    static isOtherTrigger(): boolean {
        return Boolean(
            this.sso_type &&
                (this.client_id || this.client) &&
                !localStorage.getItem('Authorization')
        );
    }

    static redirectToSSO(pid?: SSOPid) {
        const pID = pid || this.client || this.client_id;

        if (pID) return redirectLink(pID);
    }
}
