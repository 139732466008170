import { RootEpic } from './root-epic';
import { catchError, filter, switchMap, withLatestFrom } from 'rxjs/operators';
import { isActionOf } from 'typesafe-actions';
import { of } from 'rxjs';
import {
    loadRestrictions,
    setIsRFY,
    setRestrictions,
} from '../store/module-restriction/module-restriction-action';

export const getRestrictionEpics: RootEpic = (
    action$,
    state$,
    { moduleRestrictionApi }
) => {
    return action$.pipe(
        filter(isActionOf(loadRestrictions.request)),
        withLatestFrom(state$),
        switchMap(() => {
            return moduleRestrictionApi
                .loadModuleRestrictionCall(
                    state$.value.user.identity?.learnerUUID as string
                )
                .pipe(
                    switchMap((x) => {
                        let rfy: any = null;
                        if (x?.displayRule) {
                            rfy = x.displayRule.filter(
                                (restriction) =>
                                    restriction.subComponent === 'rfy'
                            );
                            rfy = rfy.length === 1 ? rfy[0] : null;
                        }
                        return of(
                            loadRestrictions.success(x),
                            setRestrictions(x),
                            setIsRFY(rfy?.display !== 'No')
                        );
                    }),
                    catchError((e) => of(loadRestrictions.failure(e)))
                );
        })
    );
};

export const restrictionEpics = [getRestrictionEpics];
