import { combineReducers } from 'redux';
import { createReducer } from 'typesafe-actions';
import {
    loadCertificate,
    loadCurrent,
    loadHistory,
    loadList,
    loadProficiencyOverviewCertificate,
    setCurrentTopicOPL,
    setHistory,
    setLoadList,
} from './proficiency-actions';
import { ProficienciesDetailDTO, ProficienciesDTO } from './proficiency-dto';
import { Action } from '../root-action';

const historyReducer = createReducer<ProficienciesDetailDTO[] | null>(null)
    .handleAction(
        [loadHistory.success, setHistory],
        (state, action) => action.payload
    )
    .handleAction(
        [Action.user.logout.success, loadHistory.request, loadHistory.failure],
        () => {
            return null;
        }
    );

const listReducer = createReducer<ProficienciesDTO[] | any>(null)
    .handleAction(loadList.success, (state, action) => action.payload)
    .handleAction(setLoadList, (state, action) => action.payload)
    .handleAction(
        [
            Action.user.logout.success,
            loadHistory.request,
            loadCurrent.request,
            loadList.request,
            loadList.failure,
        ],
        () => null
    );

const currentTopicOPLReducer = createReducer<ProficienciesDTO[] | any>(null)
    .handleAction(setCurrentTopicOPL, (state, action) => action.payload)
    .handleAction(
        [
            loadCurrent.request,
            loadProficiencyOverviewCertificate.request,
            Action.user.logout.success,
        ],
        () => null
    );

const downloadStatusReducer = createReducer<string>('idle')
    .handleAction(
        [loadCertificate.request, loadProficiencyOverviewCertificate.request],
        () => 'downloading'
    )
    .handleAction(
        [loadCertificate.success, loadProficiencyOverviewCertificate.success],
        () => 'success'
    )
    .handleAction(
        [loadCertificate.failure, loadProficiencyOverviewCertificate.failure],
        () => 'failed'
    );

export const proficiencyReducer = combineReducers({
    history: historyReducer,
    list: listReducer,
    current: currentTopicOPLReducer,
    downloadStatus: downloadStatusReducer,
});
