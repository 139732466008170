import { RootEpic } from '../root-epic';
import { filter, tap, ignoreElements } from 'rxjs/operators';
import { isActionOf } from 'typesafe-actions';
import { Action } from '../../store/root-action';
import { logger } from '../../logging';

export const failuresLoggerEpic: RootEpic = (action$) =>
    action$.pipe(
        filter(
            isActionOf([
                Action.articles.loadArticles.failure,
                Action.howtos.loadGrammar.failure,
                Action.howtos.loadHowtos.failure,
                Action.howtos.loadVocabularies.failure,
                Action.quizzes.loadQuiz.failure,
                Action.videos.loadVideos.failure,
                Action.resources.loadSelectedResource.failure,
                Action.resources.loadSelectedUsingUUIDResource.failure,
            ])
        ),
        tap({
            next: (action) => {
                logger.error('Failed to load a resource', action.payload);
            },
        }),
        ignoreElements()
    );
