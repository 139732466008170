import { Record } from 'immutable';

export interface LearnerTopicProps {
    topicUUID: string;
}

export interface LearnerCreatorProps {
    creatorUUID: string;
    organisationalUUID: string;
    organisationalPriority: string;
    creatorType: string;
}

export interface learningObjectivesProps {
    objectiveUUID: string;
    learnerUUID: string;
    topic: LearnerTopicProps;
    creator: LearnerCreatorProps;
}

export interface demographicProps {
    industryUUID: string[] | null;
    jobFunctionUUID: string[] | null;
}

export interface LearnerProfileProps {
    learnerUUID: string;
    defaultObjectiveUUID: string;
    demographic: demographicProps;
    objectives: learningObjectivesProps[];
}

export const defaultLearnerProfileDataProps: LearnerProfileProps = {
    learnerUUID: '',
    defaultObjectiveUUID: '',
    demographic: {
        industryUUID: [],
        jobFunctionUUID: [],
    },
    objectives: [
        {
            objectiveUUID: '',
            learnerUUID: '',
            topic: { topicUUID: '' },
            creator: {
                creatorUUID: '',
                creatorType: 'Learner',
                organisationalPriority: '',
                organisationalUUID: '',
            },
        },
    ],
};

export class LearnerProfileRecord
    extends Record(defaultLearnerProfileDataProps)
    implements LearnerProfileProps {}

export interface LanguageProps {
    languageUUID: string;
    code: string;
    name: string;
    type: string;
}

const defaultLanguageProps = {
    languageUUID: '',
    code: '',
    name: '',
    type: '',
};

export class LanguageRecord
    extends Record(defaultLanguageProps)
    implements LanguageProps {}

export interface TranslationsProps {
    label: string;
    locale: string;
}

export interface TranslatedArray {
    translations: TranslationsProps[];
    name: string;
}

export interface JobFunctionProps extends TranslatedArray {
    jobfunctionUUID: string;
}

export interface JobFunctionListProps {
    jobFunctionList: JobFunctionProps[];
}

const defaultJobFunctionProps = {
    jobfunctionUUID: '',
    name: '',
    translations: [
        {
            label: '',
            locale: '',
        },
    ],
};

const defaultJobFunctionCurrentProps: JobFunctionListProps = {
    jobFunctionList: [],
};

export class JobFunctionListRecord
    extends Record(defaultJobFunctionProps)
    implements JobFunctionProps {}

export class JobFunctionRecord
    extends Record(defaultJobFunctionCurrentProps)
    implements JobFunctionListProps {}

const defaultIndustryProps = {
    industryUUID: '',
    name: '',
    translations: [
        {
            locale: '',
            label: '',
        },
    ],
};

const defaultIndustryCurrentProps: IndustryCurrentCProps = {
    industryList: [],
};

export interface IndustryProps extends TranslatedArray {
    industryUUID: string;
}

export interface IndustryCurrentCProps {
    industryList: IndustryProps[];
}

export class industryRecord
    extends Record(defaultIndustryProps)
    implements IndustryProps {}

export class IndustryRecord extends industryRecord {}

export class IndustryCurrentRecord
    extends Record(defaultIndustryCurrentProps)
    implements IndustryCurrentCProps {}
