import { ApiHeaders } from '../api-headers';
import { Observable, of } from 'rxjs';
import { Axios } from 'axios-observable';
import { contentLegacy, language } from '../api-urls';
import { map, catchError, concatMap } from 'rxjs/operators';
import { ResourceContentType } from '../../store/resources/resource-content-type';
import { ResourceContent } from '../../store/resources/resource-content';
import {
    GetIdsResponse,
    ResourceListDTO,
    ResourceListParser,
} from './resource-list-parser';
import { FilterOption } from '../../components/filters/filters';
import { convertToLegacyLevelName } from '../../utils/level-utils';

export class LegacyResourceContentApi {
    private readonly apiHeaders: ApiHeaders;
    private readonly resourceListParser = new ResourceListParser();

    constructor(apiHeaders: ApiHeaders) {
        this.apiHeaders = apiHeaders;
    }

    private getPostHeaders() {
        return {
            ...this.apiHeaders.getHeaders(),
            'Content-Type': 'application/json',
        };
    }

    loadArticlesList(
        startIndex: number,
        languageCode: string,
        levels?: FilterOption[],
        categories?: FilterOption[]
    ): Observable<ResourceContent[]> {
        return this.loadListForGroup(
            ResourceContentType.Article,
            startIndex,
            languageCode,
            levels,
            categories
        );
    }

    loadVideoList(
        startIndex: number,
        languageCode: string,
        levels?: FilterOption[],
        categories?: FilterOption[]
    ): Observable<ResourceContent[]> {
        return this.loadListForGroup(
            ResourceContentType.Video,
            startIndex,
            languageCode,
            levels,
            categories
        );
    }

    loadHowtosList(
        startIndex: number,
        languageCode: string,
        levels?: FilterOption[],
        categories?: FilterOption[]
    ): Observable<ResourceContent[]> {
        return this.loadListForGroup(
            ResourceContentType.Howto,
            startIndex,
            languageCode,
            levels,
            categories
        );
    }

    loadVocabulariesList(
        startIndex: number,
        languageCode: string,
        levels?: FilterOption[],
        categories?: FilterOption[]
    ): Observable<ResourceContent[]> {
        return this.loadListForGroup(
            ResourceContentType.Vocabulary,
            startIndex,
            languageCode,
            levels,
            categories
        );
    }

    loadGrammarList(
        startIndex: number,
        languageCode: string,
        levels?: FilterOption[],
        categories?: FilterOption[]
    ): Observable<ResourceContent[]> {
        return this.loadListForGroup(
            ResourceContentType.Grammar,
            startIndex,
            languageCode,
            levels,
            categories
        );
    }

    private loadListForGroup(
        contentType: string,
        startIndex: number,
        languageCode: string,
        levels?: FilterOption[],
        categories?: FilterOption[]
    ): Observable<ResourceContent[]> {
        if (
            (levels && levels.length > 0) ||
            (categories && categories.length > 0)
        ) {
            const filterLevels = levels?.map((level) => level.value);
            const legacyFilterLevels: string[] | null =
                convertToLegacyLevelName(filterLevels);
            const filterCategories = categories?.flatMap((category) =>
                category.id.split(',').map((id) => Number(id))
            );
            return Axios.post<ResourceListDTO[]>(
                `${language()}${languageCode}/api/v1.0/content/${contentType}/list/${startIndex}`,
                {
                    levels: legacyFilterLevels
                        ? legacyFilterLevels
                        : filterLevels,
                    tagsId: filterCategories,
                },
                { headers: this.getPostHeaders() }
            ).pipe(
                concatMap((response) => {
                    if (response && response.data && response.data.length > 0) {
                        const getUUID = this.loadContentUUIDForListGroup(
                            response.data.map(
                                (item) => item.articleId as number
                            )
                        );
                        return getUUID.pipe(
                            map((getUUIDAnswer) =>
                                response.data.map((y) => {
                                    const uuid = getUUIDAnswer.data?.find(
                                        (record) =>
                                            record.articleId === y.articleId
                                    );
                                    if (uuid) {
                                        y.contentUUID = uuid.uuid;
                                    }
                                    return this.resourceListParser.parseListItem(
                                        y
                                    );
                                })
                            ),
                            catchError((e) => {
                                return of(
                                    response.data.map((y) =>
                                        this.resourceListParser.parseListItem(y)
                                    )
                                );
                            })
                        );
                    } else {
                        throw new Error(
                            `Can't load ${contentType} resources list`
                        );
                    }
                }),
                catchError((e) => {
                    throw new Error(`Can't load ${contentType} resources list`);
                })
            );
        } else {
            return Axios.get<ResourceListDTO[]>(
                `${language()}${languageCode}/api/v1.0/content/${contentType}/list/${startIndex}`,
                { headers: this.getPostHeaders() }
            ).pipe(
                concatMap((response) => {
                    if (response && response.data && response.data.length > 0) {
                        const getUUID = this.loadContentUUIDForListGroup(
                            response.data.map(
                                (item) => item.articleId as number
                            )
                        );
                        return getUUID.pipe(
                            map((getUUIDAnswer) =>
                                response.data.map((y) => {
                                    const uuid = getUUIDAnswer.data?.find(
                                        (record) =>
                                            record.articleId === y.articleId
                                    );
                                    if (uuid) {
                                        y.contentUUID = uuid.uuid;
                                    }
                                    return this.resourceListParser.parseListItem(
                                        y
                                    );
                                })
                            ),
                            catchError((e) => {
                                return of(
                                    response.data.map((y) =>
                                        this.resourceListParser.parseListItem(y)
                                    )
                                );
                            })
                        );
                    } else {
                        throw new Error(
                            `Can't load ${contentType} resources list`
                        );
                    }
                }),
                catchError((e) => {
                    throw new Error(`Can't load ${contentType} resources list`);
                })
            );
        }
    }

    loadContentUUIDForListGroup(ids: number[]) {
        return Axios.post<GetIdsResponse[]>(`${contentLegacy()}`, ids, {
            headers: this.getPostHeaders(),
        });
    }
}
