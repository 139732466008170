import { createAction } from 'typesafe-actions';
import { Alert } from './alert-data';

/*
 *   Action to set alert message
 */
export const setAlert = createAction('@@alert/set-alert')<Alert>();

export const clearAlert = createAction('@@alert/clear-alert')();

export const alertActions = {
    setAlert,
    clearAlert,
};
