export class AICCSSOValidatorEvent {
    constructor(
        public readonly learnerUUID: string,
        public readonly learnerExternalId: string,
        public readonly sessionId: string,
        public readonly clientUrl: string,
        public readonly providerId: string
    ) {}
}

export class UserCustomFieldEvent {
    constructor(public readonly learnerUUID: string) {}
}
