import { PerformanceSurveyStepQuestions } from '../types';
import { collectAnswers } from './collectAnswers';
import { checkScore } from './checkScore';

export const checkStepRequirements = (
    questions: PerformanceSurveyStepQuestions[] | null,
    answers: PerformanceSurveyStepQuestions[]
): boolean => {
    if (questions && answers) {
        const required = collectAnswers(questions);
        const check = required.filter((question) => {
            const getAnswered = answers.find(
                (answer) => answer.option === question.option
            );
            if (getAnswered) {
                return (
                    checkScore(getAnswered.score) &&
                    getAnswered.questionType === 'Score'
                );
            } else {
                return false;
            }
        });
        return (
            required.length > 0 &&
            check.length > 0 &&
            required.length === check.length
        );
    } else {
        return false;
    }
};
