import { createReducer, ActionType } from 'typesafe-actions';
import { combineReducers } from 'redux';
import { eWritingActions } from './e-writing-actions';
import { Action } from '../root-action';
import { EWritingLimitation, EwritingListItem } from './data/ewriting-models';
import { List } from 'immutable';

export type EWritingAction = ActionType<typeof eWritingActions>;

export interface EWritingState {
    isPostEWritingLoading: boolean;
    limitation: EWritingLimitation;
    eWritingsList: List<EwritingListItem> | null;
    isEWritingListLoading: boolean;
    eWritingLessonReportId: string;
    preselectedEwritingLessonId: string;
}

const initialState: EWritingState = {
    isPostEWritingLoading: false,
    limitation: new EWritingLimitation(),
    eWritingsList: null,
    isEWritingListLoading: false,
    eWritingLessonReportId: '',
    preselectedEwritingLessonId: '',
};

const isPostEWritingLoading = createReducer(initialState.isPostEWritingLoading)
    .handleAction(eWritingActions.sendEWriting.request, () => true)
    .handleAction(
        [
            eWritingActions.sendEWriting.success,
            eWritingActions.sendEWriting.failure,
            Action.user.logout.success,
        ],
        () => initialState.isPostEWritingLoading
    );

const limitation = createReducer(initialState.limitation)
    .handleAction(
        eWritingActions.setEWritingLimitation,
        (_, { payload }) => payload
    )
    .handleAction(Action.user.logout.success, (state, action) => state.clear());

const eWritingsList = createReducer(initialState.eWritingsList)
    .handleAction(
        eWritingActions.loadEWritingsList.success,
        (_, { payload }) => payload
    )
    .handleAction(Action.user.logout.success, () => initialState.eWritingsList);

const isEWritingListLoading = createReducer(initialState.isEWritingListLoading)
    .handleAction(eWritingActions.loadEWritingsList.request, () => true)
    .handleAction(
        [
            eWritingActions.loadEWritingsList.success,
            eWritingActions.loadEWritingsList.failure,
            Action.user.logout.success,
        ],
        () => initialState.isEWritingListLoading
    );

const eWritingLessonReportId = createReducer(
    initialState.eWritingLessonReportId
)
    .handleAction(eWritingActions.sendEWriting.success, (_, { payload }) => {
        return payload.id;
    })
    .handleAction(
        [
            Action.user.logout.success,
            Action.lessonReports.lessonReportPageItemSelected,
            Action.lessonReports.lessonReportListItemSelected,
            Action.lessonReports.ewritingListItemSelected,
        ],
        () => initialState.eWritingLessonReportId
    );

const preselectedEwritingLessonId = createReducer(
    initialState.preselectedEwritingLessonId
)
    .handleAction(
        eWritingActions.setPreselectedEWritingLessonId,
        (_, { payload }) => {
            return payload;
        }
    )
    .handleAction(
        [
            Action.user.logout.success,
            Action.lessonReports.lessonReportPageItemSelected,
            Action.lessonReports.lessonReportListItemSelected,
            Action.lessonReports.ewritingListItemSelected,
        ],
        () => initialState.preselectedEwritingLessonId
    );

export const eWritingReducer = combineReducers<EWritingState, EWritingAction>({
    isPostEWritingLoading,
    limitation,
    eWritingsList,
    isEWritingListLoading,
    eWritingLessonReportId,
    preselectedEwritingLessonId,
});
