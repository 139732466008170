import { VideoDescription } from '../../store/resources/videos/video-description';
import moment from 'moment';
import { List } from 'immutable';
import { TagEntryProps } from '../../store/resources/resources-content-record';

interface ISnapshots {
    snap_title: string;
    value: string;
    snap_body: string;
    snap_image: string;
}

export class VideosParser {
    parseContent(dto: any) {
        return new VideoDescription({
            ...dto,
            contentMetadata: dto?.contentMetadata ?? null,
            resourceId: dto.articleId,
            date: moment(Number(dto.date)),
            title: dto.name,
            categories: List(dto.categories),
            tags: List(dto.tags),
            urls: List(dto.urls),
            contentUUId: dto.contentUUId,
            quizUUID: dto.quizUUID,
        });
    }

    parseFromContentService(dto: any) {
        const {
            title,
            description,
            legacy,
            content,
            id: contentUUid,
            learn,
        } = dto;
        const {
            quiz_article_id: quizArticleId,
            quiz_article_uuid: quizUUID,
            source_publish_date,
            snapshots,
            video_url,
            Mobile_Video_URL_h264_sub,
        } = content;
        const { articleId, groupId, contentType, tagEntries } = legacy;
        const snapShotsMap = new Map();

        if (snapshots) {
            snapshots.forEach((element: ISnapshots, index: number) => {
                const { snap_title, value, snap_body, snap_image } = element;
                snapShotsMap.set(value, {
                    content: snap_body,
                    imageUrl: snap_image,
                    title: snap_title,
                    index: index,
                });
            });
        }
        let categories: string[] = [];
        if (tagEntries) {
            categories = tagEntries
                .filter((y: TagEntryProps) => y.isCategory)
                .map((x: TagEntryProps) => x.name);
        }

        return new VideoDescription({
            ...dto,
            categories: List(categories),
            contentMetadata: dto?.contentMetadata ?? null,
            contentUUId: contentUUid,
            date: dto?.administration.displayDate,
            description: description,
            groupId: groupId,
            learn: learn,
            quizArticleId: quizArticleId,
            quizUUID: quizUUID,
            resourceId: articleId,
            source: `${source_publish_date}`,
            template: 'old',
            title: title,
            topicUUID: dto?.contentMetadata?.topic ?? null,
            type: contentType,
            urls: List([video_url, Mobile_Video_URL_h264_sub]),
        });
    }
}
