import { createReducer } from 'typesafe-actions';
import { combineReducers } from 'redux';
import { Discussion, Message } from './model';
import { learnerChatActions } from './learner-chat-actions';
import moment from 'moment';
import { OOOMessage } from '../../localization/localization-api';

export type LoadingState = 'idle' | 'pending' | 'succeeded' | 'failed';
export interface LearnerChatState {
    discussions: Discussion[];
    discussionsLoadingState: LoadingState;
    activeDiscussion: Discussion | null;
    createDiscussionLoadingState: LoadingState;
    messages: Message[];
    discussionStarted: Date | null;
    OOOMessage: OOOMessage;
    discussionLanguage: string;
    checkLcTeamLoading: boolean;
    messageSending: boolean;
}

const initialState: LearnerChatState = {
    discussions: [],
    discussionsLoadingState: 'idle' as LoadingState,
    activeDiscussion: null,
    createDiscussionLoadingState: 'idle' as LoadingState,
    messages: [],
    discussionStarted: null,
    discussionLanguage: 'en',
    checkLcTeamLoading: false,
    messageSending: false,
    OOOMessage: {
        heading: 'Thank you for contacting us.',
        content:
            'All our learning consultants are busy right now. If you have an urgent matter, please contact us via email.',
    },
};

export const sortByTimestamp = (discA: any, discB: any) =>
    new Date(discB.creationTimestamp).getTime() <
    new Date(discA.creationTimestamp).getTime()
        ? 1
        : new Date(discB.creationTimestamp).getTime() ===
          new Date(discA.creationTimestamp).getTime()
        ? 0
        : -1;

export const getDaysDifference = (date: string | number | Date, endDate: any) =>
    endDate.diff(moment(date), 'days') + 1;

const messageSending = createReducer(initialState.messageSending)
    .handleAction(learnerChatActions.sendMessage.request, () => true)
    .handleAction(
        [
            learnerChatActions.updateMessages.success,
            learnerChatActions.updateMessages.failure,
        ],
        () => false
    );

const checkLcTeamLoading = createReducer(initialState.checkLcTeamLoading)
    .handleAction(learnerChatActions.checkLcTeam.request, () => true)
    .handleAction(
        [
            learnerChatActions.checkLcTeam.success,
            learnerChatActions.checkLcTeam.failure,
        ],
        () => false
    );

const discussionLanguage = createReducer(initialState.discussionLanguage)
    .handleAction([learnerChatActions.checkLcTeam.failure], () => {
        localStorage.removeItem('activeDiscussionId');
        return 'en';
    })
    .handleAction(
        learnerChatActions.checkLcTeam.success,
        (_, { payload }) => payload
    )
    // .handleAction(learnerChatActions.setActiveDiscussion, (_, { payload }) => {
    //     return payload?.interfaceLanguage ? payload.interfaceLanguage : 'en';
    // })
    .handleAction(
        learnerChatActions.setDiscussionLanguage,
        (_, { payload }) => {
            return payload;
        }
    );

const messages = createReducer(initialState.messages)
    .handleAction(
        [
            learnerChatActions.loadDiscussions.success,
            learnerChatActions.updateDiscussions.success,
        ],
        (state, { payload }: { payload: Discussion[] }) => {
            const messages = payload?.reduce(
                (acc, { messages, interfaceLanguage }) => {
                    const modifiedMessages = messages?.map((msg) => ({
                        ...msg,
                        lang: interfaceLanguage,
                    }));
                    return modifiedMessages
                        ? [...acc, ...modifiedMessages]
                        : acc;
                },
                [] as Message[]
            );

            let localMessages: any[] = state.filter(
                ({ messageId }) => messageId === 'local'
            );

            const localTimeStamps = new Set(
                localMessages.map(({ creationTimestamp }) => creationTimestamp)
            );

            localMessages = Array.from(localTimeStamps)
                .map((time) =>
                    localMessages.find(
                        ({ creationTimestamp }) => creationTimestamp === time
                    )
                )
                .filter((val) => !!val);

            return messages
                .filter(({ messageId }) => messageId !== 'local')
                .concat(localMessages)
                .sort(sortByTimestamp);
        }
    )
    .handleAction(
        learnerChatActions.sendMessage.success,
        (state, { payload }) =>
            [...state, payload.newMessage].sort(sortByTimestamp)
    )
    .handleAction(
        learnerChatActions.sendOfficeHoursMessage.success,
        (state, { payload }) =>
            [...state, payload.newMessage].sort(sortByTimestamp)
    )
    .handleAction(
        learnerChatActions.updateMessages.success,
        (
            state,
            {
                payload,
            }: { payload: { messages: Message[]; preferredLanguage: string } }
        ) => {
            let updatedMessages = [...state];

            for (let i = 0; i < payload.messages.length; i++) {
                const index = updatedMessages.findIndex(
                    ({ messageId }) =>
                        messageId === payload.messages[i].messageId
                );
                const modifiedMsg = {
                    ...payload.messages[i],
                    lang: payload.preferredLanguage,
                };

                if (index !== -1) {
                    updatedMessages[index] = modifiedMsg;
                } else {
                    updatedMessages = [...updatedMessages, modifiedMsg];
                }
            }
            return updatedMessages.sort(sortByTimestamp);
        }
    );
const discussions = createReducer(initialState.discussions)
    .handleAction(
        [
            learnerChatActions.loadDiscussions.success,
            learnerChatActions.updateDiscussions.success,
        ],
        (_, { payload }) => payload
    )
    .handleAction(
        learnerChatActions.createDiscussion.success,
        (state, { payload }) => {
            const discussions = state;
            return discussions.map((discussion) => {
                if (discussion.discussionId === 'empty') {
                    return {
                        ...payload.discussion,
                        messages: null,
                    };
                } else return discussion;
            });
        }
    )
    .handleAction(
        learnerChatActions.updateMessages.success,
        (state, { payload }) => {
            const discussionsList = state;
            const activeDiscussion = discussionsList.find(
                (discussion) => discussion.discussionId === payload.discussionId
            );
            if (!!activeDiscussion) {
                return discussionsList.map((discussion) => {
                    if (
                        discussion.discussionId ===
                        activeDiscussion.discussionId
                    ) {
                        return {
                            ...activeDiscussion,
                            messages: payload.messages,
                        };
                    } else {
                        return discussion;
                    }
                });
            } else {
                return discussionsList;
            }
        }
    )
    .handleAction(
        learnerChatActions.readMessage.success,
        (state, { payload }) => {
            const discussions = state;
            return discussions.map((discussion) => {
                if (discussion.discussionId === payload.discussionId) {
                    return {
                        ...discussion,
                        lastSeenMessageId: payload.messageId,
                    };
                } else return discussion;
            });
        }
    )
    .handleAction(
        learnerChatActions.sendMessage.success,
        (state, { payload }) => {
            const discussionsList = state;
            const activeDiscussion = discussionsList.find(
                (discussion) => discussion.discussionId === payload.discussionId
            );
            if (!!activeDiscussion) {
                return discussionsList.map((discussion) => {
                    if (
                        discussion.discussionId ===
                        activeDiscussion.discussionId
                    ) {
                        if (!!activeDiscussion.messages) {
                            return {
                                ...activeDiscussion,
                                previousLastSeenMessageId:
                                    payload.newMessage.messageId,
                                lastSeenMessageId: payload.newMessage.messageId,
                                messages: [
                                    ...activeDiscussion.messages,
                                    payload.newMessage,
                                ],
                            };
                        } else {
                            return {
                                ...activeDiscussion,
                                previousLastSeenMessageId:
                                    payload.newMessage.messageId,
                                lastSeenMessageId: payload.newMessage.messageId,
                                messages: [payload.newMessage],
                            };
                        }
                    } else {
                        return discussion;
                    }
                });
            } else {
                return discussionsList;
            }
        }
    );

const discussionsLoadingState = createReducer(
    initialState.discussionsLoadingState
)
    .handleAction(
        learnerChatActions.loadDiscussions.request,
        () => 'pending' as LoadingState
    )
    .handleAction(
        learnerChatActions.loadDiscussions.success,
        () => 'succeeded' as LoadingState
    )
    .handleAction(
        learnerChatActions.loadDiscussions.failure,
        () => 'failed' as LoadingState
    );

const activeDiscussion = createReducer(initialState.activeDiscussion)
    .handleAction(learnerChatActions.setActiveDiscussion, (_, { payload }) => {
        return payload;
    })
    .handleAction(
        learnerChatActions.sendMessage.success,
        (state, { payload }) => {
            const discussion = state;
            const messages = discussion?.messages;
            if (!!discussion) {
                if (!!messages) {
                    return {
                        ...discussion,
                        messages: [...messages, payload.newMessage].sort(
                            sortByTimestamp
                        ),
                    };
                } else {
                    return {
                        ...discussion,
                        messages: [payload.newMessage],
                    };
                }
            } else {
                return discussion;
            }
        }
    )
    .handleAction(
        learnerChatActions.readMessage.success,
        (state, { payload }) => {
            const discussion = state;
            if (
                !!discussion &&
                discussion.discussionId === payload.discussionId
            ) {
                return {
                    ...discussion,
                    lastSeenMessageId: payload.messageId,
                };
            } else return discussion;
        }
    )
    .handleAction(
        learnerChatActions.updateMessages.success,
        (state, { payload }) => {
            const discussion = state;
            if (!!discussion) {
                return {
                    ...discussion,
                    messages: payload.messages,
                };
            } else {
                return discussion;
            }
        }
    );

const createDiscussionLoadingState = createReducer(
    initialState.createDiscussionLoadingState
)
    .handleAction(
        learnerChatActions.createDiscussion.request,
        () => 'pending' as LoadingState
    )
    .handleAction(
        learnerChatActions.createDiscussion.success,
        () => 'succeeded' as LoadingState
    )
    .handleAction(
        learnerChatActions.createDiscussion.failure,
        () => 'failed' as LoadingState
    );

const OOOMessageReducer = createReducer(initialState.OOOMessage).handleAction(
    learnerChatActions.loadOOOMessage.success,
    (_state, { payload }) => {
        return payload;
    }
);

const discussionStarted = createReducer(initialState.discussionStarted)
    .handleAction(learnerChatActions.createDiscussion.request, () => null)
    .handleAction(
        learnerChatActions.setChatSessionStarted,
        (_, { payload }) => payload
    );

export const learnerChatReducer = combineReducers({
    discussions,
    discussionsLoadingState,
    activeDiscussion,
    createDiscussionLoadingState,
    messages,
    discussionStarted,
    OOOMessage: OOOMessageReducer,
    discussionLanguage,
    checkLcTeamLoading,
    messageSending,
});
