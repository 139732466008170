import { RootEpic } from '../../epics/root-epic';
import {
    catchError,
    concatMap,
    distinctUntilChanged,
    filter,
    map,
    switchMap,
    withLatestFrom,
} from 'rxjs/operators';
import { isActionOf } from 'typesafe-actions';
import { Action } from '../../store/root-action';
import { EMPTY, of } from 'rxjs';
import { Message } from './model';
import { checkLcTeamOfficeHours, messageDateParser } from './lib';
import moment, { Moment } from 'moment';
import { differenceWith } from 'lodash';

const extractUserDetails = (state$: any) => {
    const learnerId = String(state$.value.user.identity?.learnerUUID);
    const learnerName = `${state$.value.user.identity?.firstname} ${state$.value.user.identity?.lastname}`;
    const learnerImage = String(state$.value.user.identity?.image);
    return { learnerId, learnerName, learnerImage };
};

const compareMessages = (
    firstArray: Message[],
    secondArray: Message[]
): Message[] => {
    return differenceWith(secondArray, firstArray, (a: Message, b: Message) => {
        return a.messageId === b.messageId;
    });
};

const fromTrainer = ({ isFromCurrentUser }: Message) => !isFromCurrentUser;

const getOOOMessageTemplate = (
    future: Moment,
    lang: string,
    heading: string,
    content: string
) => ({
    messageId: 'local',
    userName: 'goFLUENT',
    userImage: 'gofluent-user',
    date: messageDateParser(future.format()),
    text: `${heading} ${content}`,
    isFromCurrentUser: false,
    creationTimestamp: future.toISOString(true),
    lang,
});

const checkLcTeamEpic: RootEpic = (action$, state$, { learnerChatApi }) =>
    action$.pipe(
        filter(isActionOf(Action.learnerChat.checkLcTeam.request)),
        switchMap((action) => {
            return learnerChatApi.checkLcTeam(action.payload).pipe(
                map((x) => {
                    return Action.learnerChat.checkLcTeam.success(x);
                }),
                catchError((e) => of(Action.learnerChat.checkLcTeam.failure(e)))
            );
        })
    );

const loadDiscussionsEpic: RootEpic = (action$, state$, { learnerChatApi }) =>
    action$.pipe(
        filter(isActionOf(Action.learnerChat.loadDiscussions.request)),
        switchMap(() => {
            const { learnerId, learnerName, learnerImage } =
                extractUserDetails(state$);
            return learnerChatApi
                .loadDiscussions(learnerId, learnerName, learnerImage)
                .pipe(
                    map((x) => {
                        return Action.learnerChat.loadDiscussions.success(x);
                    }),
                    catchError((e) =>
                        of(Action.learnerChat.loadDiscussions.failure(e))
                    )
                );
        })
    );

const loadOOOMessageEpic: RootEpic = (
    action$,
    state$,
    { localizationService }
) =>
    action$.pipe(
        filter(isActionOf(Action.learnerChat.setActiveDiscussion)),
        distinctUntilChanged(),
        switchMap((action) => {
            if (action.payload) {
                return localizationService
                    .getOOOMessage(action.payload.interfaceLanguage)
                    .pipe(
                        map((x) =>
                            Action.learnerChat.loadOOOMessage.success(x)
                        ),
                        catchError((e) =>
                            of(
                                Action.learnerChat.loadOOOMessage.failure(
                                    new Error('Error while loading by language')
                                )
                            )
                        )
                    );
            } else {
                return of(
                    Action.learnerChat.loadOOOMessage.failure(
                        new Error('set null discussion')
                    )
                );
            }
        })
    );

const updateDiscussionsEpic: RootEpic = (action$, state$, { learnerChatApi }) =>
    action$.pipe(
        filter(isActionOf(Action.learnerChat.updateDiscussions.request)),
        switchMap(() => {
            const { learnerId, learnerName, learnerImage } =
                extractUserDetails(state$);
            return learnerChatApi
                .loadDiscussions(learnerId, learnerName, learnerImage)
                .pipe(
                    map((x) => Action.learnerChat.updateDiscussions.success(x)),
                    catchError((e) =>
                        of(Action.learnerChat.updateDiscussions.failure(e))
                    )
                );
        })
    );

const setActiveDiscussionEpic: RootEpic = (action$, state$, _) =>
    action$.pipe(
        filter(isActionOf(Action.learnerChat.loadDiscussions.success)),
        map((action) => {
            const discussions = action.payload;
            return Action.learnerChat.setActiveDiscussion(discussions[0]);
        })
    );

const createDiscussionEpic: RootEpic = (action$, state$, { learnerChatApi }) =>
    action$.pipe(
        filter(isActionOf(Action.learnerChat.createDiscussion.request)),
        switchMap((action) => {
            const learnerId = String(state$.value.user.identity?.learnerUUID);
            const preferredLanguage =
                state$.value.learnerChat.discussionLanguage;
            return learnerChatApi
                .createDiscussion(
                    learnerId,
                    preferredLanguage,
                    action.payload.message
                )
                .pipe(
                    map((x) =>
                        Action.learnerChat.createDiscussion.success({
                            discussion: x,
                            message: action.payload.message,
                        })
                    ),
                    catchError((e) =>
                        of(Action.learnerChat.createDiscussion.failure(e))
                    )
                );
        })
    );

const updateMessagesEpic: RootEpic = (action$, state$, { learnerChatApi }) =>
    action$.pipe(
        filter(isActionOf(Action.learnerChat.updateMessages.request)),
        switchMap((action) => {
            const { learnerId, learnerName, learnerImage } =
                extractUserDetails(state$);
            const preferredLanguage =
                state$.value.learnerChat.discussionLanguage;

            return learnerChatApi
                .loadMessages(
                    learnerId,
                    learnerName,
                    learnerImage,
                    preferredLanguage,
                    action.payload.discussionId
                )
                .pipe(
                    map((x) => {
                        const messages: Message[] =
                            state$.value.learnerChat?.messages;
                        return Action.learnerChat.updateMessages.success({
                            discussionId: action.payload.discussionId,
                            preferredLanguage,
                            messages: x,
                            newMessagesFound:
                                compareMessages(messages, x).filter(fromTrainer)
                                    .length !== 0,
                        });
                    }),
                    catchError((e) =>
                        of(Action.learnerChat.updateMessages.failure(e))
                    )
                );
        })
    );

const updateMessagesFinishEpic: RootEpic = (action$, state$, _se) =>
    action$.pipe(
        filter(isActionOf(Action.learnerChat.updateMessages.success)),
        withLatestFrom(state$),
        concatMap(([action, state]) => {
            if (
                !state.learnerChat?.discussionStarted &&
                action.payload?.newMessagesFound
            ) {
                return of(Action.learnerChat.setChatSessionStarted(new Date()));
            }
            return EMPTY;
        }),
        catchError((e) => {
            console.error(e);
            return of(Action.learnerChat.updateMessages.failure(e));
        })
    );

const readMessageEpic: RootEpic = (action$, state$, { learnerChatApi }) =>
    action$.pipe(
        filter(isActionOf(Action.learnerChat.readMessage.request)),
        switchMap((action) => {
            const learnerId = String(state$.value.user.identity?.learnerUUID);
            const preferredLanguage =
                state$.value.learnerChat.discussionLanguage;

            return learnerChatApi
                .readMessage(
                    learnerId,
                    preferredLanguage,
                    action.payload.discussionId,
                    action.payload.messageId
                )
                .pipe(
                    map(() =>
                        Action.learnerChat.readMessage.success({
                            discussionId: action.payload.discussionId,
                            messageId: action.payload.messageId,
                        })
                    ),
                    catchError((e) =>
                        of(Action.learnerChat.readMessage.failure(e))
                    )
                );
        })
    );

const sendMessageEpic: RootEpic = (action$, state$, { learnerChatApi }) =>
    action$.pipe(
        filter(isActionOf(Action.learnerChat.sendMessage.request)),
        switchMap((action) => {
            const { learnerId, learnerName, learnerImage } =
                extractUserDetails(state$);
            const preferredLanguage =
                state$.value.learnerChat.discussionLanguage;
            return learnerChatApi
                .createMessage(
                    learnerId,
                    preferredLanguage,
                    action.payload.discussionId,
                    action.payload.content
                )
                .pipe(
                    switchMap((x) => {
                        return of(
                            Action.learnerChat.updateMessages.request({
                                discussionId: action.payload.discussionId,
                                preferredLanguage,
                            })
                        ).pipe(
                            map(() => {
                                const newMessage = {
                                    messageId: x.messageId,
                                    userName: learnerName,
                                    userImage: learnerImage,
                                    date: messageDateParser(
                                        x.creationTimestamp
                                    ),
                                    text: x.content,
                                    isFromCurrentUser: true,
                                    lang: preferredLanguage,
                                    creationTimestamp: x.creationTimestamp,
                                } as Message;
                                return Action.learnerChat.sendMessage.success({
                                    discussionId: action.payload.discussionId,
                                    newMessage,
                                });
                            })
                        );
                    }),
                    catchError((e) =>
                        of(Action.learnerChat.sendMessage.failure(e))
                    )
                );
        })
    );

const sendOfficeHoursMessageActiveDiscussionEpic: RootEpic = (
    action$,
    state$,
    _
) =>
    action$.pipe(
        filter(isActionOf(Action.learnerChat.sendMessage.success)),
        withLatestFrom(state$),
        map(([action, state]) => {
            const discussionId = String(action.payload.discussionId);
            const message = action.payload.newMessage;
            const activeDiscussion = state.learnerChat?.activeDiscussion;
            const discussionAlreadyStarted =
                state.learnerChat?.discussionStarted;
            const OOOMessage = state.learnerChat?.OOOMessage;

            if (
                !checkLcTeamOfficeHours(activeDiscussion) &&
                !discussionAlreadyStarted &&
                message.lang
            ) {
                let future = moment(message.creationTimestamp).add(
                    1,
                    'seconds'
                );

                return Action.learnerChat.sendOfficeHoursMessage.success({
                    discussionId,
                    newMessage: getOOOMessageTemplate(
                        future,
                        message.lang,
                        OOOMessage.heading,
                        OOOMessage.content
                    ),
                });
            }

            return Action.learnerChat.sendOfficeHoursMessage.failure(
                new Error('office hours')
            );
        })
    );

const loadFirstDiscussionMessage: RootEpic = (action$, state$, _) =>
    action$.pipe(
        filter(isActionOf(Action.learnerChat.createDiscussion.success)),
        map((action) => {
            const discussionId = String(action.payload.discussion.discussionId);
            const preferredLanguage =
                state$.value.learnerChat.discussionLanguage;
            return Action.learnerChat.updateMessages.request({
                discussionId,
                preferredLanguage,
            });
        })
    );

const setFirstActiveDiscussionEpic: RootEpic = (action$, state$, _) =>
    action$.pipe(
        filter(isActionOf(Action.learnerChat.createDiscussion.success)),
        map((action) => {
            return Action.learnerChat.setActiveDiscussion(
                action.payload.discussion
            );
        })
    );

const sendOfficeHoursMessageAfterDiscussionCreationEpic: RootEpic = (
    action$,
    state$,
    { localizationApi }
) =>
    action$.pipe(
        filter(isActionOf(Action.learnerChat.createDiscussion.success)),
        withLatestFrom(state$),
        switchMap(([action, state]) => {
            const discussionId = String(action.payload.discussion.discussionId);
            const activeDiscussion = action.payload.discussion!;

            if (
                !state.learnerChat?.discussionStarted &&
                !checkLcTeamOfficeHours(activeDiscussion)
            ) {
                return localizationApi
                    .loadOOOMessage(activeDiscussion.interfaceLanguage)
                    .pipe(
                        map((OOOMessage) => {
                            let future = moment(new Date().getTime()).add(
                                2,
                                'seconds'
                            );
                            return Action.learnerChat.sendOfficeHoursMessage.success(
                                {
                                    discussionId,
                                    newMessage: getOOOMessageTemplate(
                                        future,
                                        activeDiscussion?.interfaceLanguage,
                                        OOOMessage.heading,
                                        OOOMessage.content
                                    ),
                                }
                            );
                        }),
                        catchError((e) =>
                            of(
                                Action.learnerChat.sendOfficeHoursMessage.failure(
                                    new Error('Error while loading OOO message')
                                )
                            )
                        )
                    );
            } else {
                return of(
                    Action.learnerChat.sendOfficeHoursMessage.failure(
                        new Error('office hours')
                    )
                );
            }
        }),
        catchError((e) => {
            console.error(e);
            return of(
                Action.learnerChat.sendOfficeHoursMessage.failure(
                    new Error('Error while sending office hours message')
                )
            );
        })
    );

export const learnerChatEpics = [
    loadFirstDiscussionMessage,
    loadDiscussionsEpic,
    updateDiscussionsEpic,
    setActiveDiscussionEpic,
    createDiscussionEpic,
    updateMessagesEpic,
    updateMessagesFinishEpic,
    readMessageEpic,
    sendMessageEpic,
    setFirstActiveDiscussionEpic,
    sendOfficeHoursMessageActiveDiscussionEpic,
    sendOfficeHoursMessageAfterDiscussionCreationEpic,
    loadOOOMessageEpic,
    checkLcTeamEpic,
];
