import { createReducer } from 'typesafe-actions';
import { combineReducers } from 'redux';
import { Action } from '../root-action';

const isPrivacyPolicy = createReducer(false).handleAction(
    Action.privacyPolicy.displayPrivacyPolicy,
    (state, { payload }) => {
        return payload;
    }
);

const isCountryPreload = createReducer<string | null>(null).handleAction(
    Action.privacyPolicy.countryPreload,
    (state, { payload }) => {
        return payload;
    }
);

export const privacyPolicyReducer = () =>
    combineReducers({
        isPrivacyPolicy,
        isCountryPreload,
    });
