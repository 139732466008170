import { RootState } from 'typesafe-actions';
import { createSelector } from 'reselect';
import camelcaseKeys from 'camelcase-keys';
import { Colors } from '../../customization/colors';

export const displayStrategySelector = (state: RootState) =>
    state.displayStrategy.navigation;

export const displayStrategyDisplaySelector = (state: RootState) =>
    state.displayStrategy?.customDisplay;

export const customizedColorsSelector = createSelector(
    displayStrategyDisplaySelector,
    (strategy) => {
        return strategy &&
            Array.isArray(strategy.displayRule) &&
            strategy.displayRule.length &&
            strategy.displayRule[0].stylePropertyData
            ? (camelcaseKeys(
                  strategy.displayRule[0].stylePropertyData
              ) as Colors)
            : null;
    }
);

export const displayStrategyMenuSelector = createSelector(
    displayStrategySelector,
    (strategy) => {
        if (!strategy?.displayRule) return null;
        const found = strategy.displayRule.find(
            (item) => item.subComponent === 'menu-site'
        );
        return found ? found : null;
    }
);

export const hideMenuSelector = createSelector(
    displayStrategyMenuSelector,
    (menu) => menu?.display === 'No'
);

export const displayStrategyAddMediaSelector = createSelector(
    displayStrategySelector,
    (strategy) => {
        if (!strategy?.displayRule) return null;
        const found = strategy.displayRule.find(
            (item) => item.subComponent === 'addmedia-button'
        );
        return found ? found : null;
    }
);

export const hideAddMediaSelector = createSelector(
    displayStrategyAddMediaSelector,
    (nav) => nav?.display === 'No'
);

export const displayStrategyNavigationSelector = createSelector(
    displayStrategySelector,
    (strategy) => {
        if (!strategy?.displayRule) return null;
        const found = strategy.displayRule.find(
            (item) => item.subComponent === 'container-content'
        );
        return found ? found : null;
    }
);

export const hideNavigationSelector = createSelector(
    displayStrategyNavigationSelector,
    (nav) => nav?.display === 'No'
);

export const displayLogoutNavigatorSelector = createSelector(
    displayStrategySelector,
    (strategy) => {
        if (!strategy?.displayRule) return null;
        const found = strategy.displayRule.find(
            (item) => item.subComponent === 'logout-button'
        );
        return found ? found : null;
    }
);

export const displayLogoutButton = createSelector(
    displayLogoutNavigatorSelector,
    (nav) => nav?.display !== 'No'
);

export const displayLogoComponentSelector = createSelector(
    displayStrategySelector,
    (strategy) => {
        if (!strategy?.displayRule) return null;
        const found = strategy.displayRule.find(
            (item) => item.subComponent === 'logo'
        );
        return found ? found : null;
    }
);

export const displayGroupLessonsSelector = createSelector(
    displayStrategySelector,
    (strategy) => {
        if (!strategy?.displayRule) return null;
        const found = strategy.displayRule.find(
            (item) => item.subComponent === 'externalAgendaLink'
        );
        return found ? found : null;
    }
);

export const displayCLComponentSelector = createSelector(
    displayStrategySelector,
    (strategy) => {
        if (!strategy?.displayRule) return null;
        const found = strategy.displayRule.find(
            (item) => item.subComponent === 'continue-learning'
        );
        return found ? found : null;
    }
);

export const displayCLSelector = createSelector(
    displayCLComponentSelector,
    (nav) => {
        return Boolean(nav && nav.display !== 'No');
    }
);
