import { combineReducers } from 'redux';
import { createReducer } from 'typesafe-actions';
import { loadCompletedActivities } from './insights-actions';
import { InsightReportDTO } from './insight-report';

export interface InsightsState {
    content: InsightReportDTO[] | null;
}

const initialInsightsState: InsightsState = {
    content: null,
};

const contentReducer = createReducer(initialInsightsState.content)
    .handleAction(
        loadCompletedActivities.success,
        (state, action) => action.payload
    )
    .handleAction(
        [loadCompletedActivities.request, loadCompletedActivities.failure],
        () => null
    );

export const insightsReducer = combineReducers({
    content: contentReducer,
});
