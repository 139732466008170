import { createAction, createAsyncAction } from 'typesafe-actions';
import {
    LanguageProps,
    LanguageRecord,
    LearnerProfileProps,
    JobFunctionProps,
    IndustryProps,
    IndustryCurrentRecord,
    JobFunctionRecord,
    LearnerProfileRecord,
} from './learner-profile-records';

export const loadLearnerProfile = createAsyncAction(
    '@@learner-profile/load/request',
    '@@learner-profile/load/success',
    '@@learner-profile/load/failure'
)<void, LearnerProfileProps | null, Error>();

export const saveLanguageToProfile = createAsyncAction(
    '@@learner-profile/save-language/request',
    '@@learner-profile/save-language/success',
    '@@learner-profile/save-language/failure'
)<{ languageUUID: string }, LearnerProfileProps, Error>();

export const updateLearnerProfile = createAsyncAction(
    '@@learner-profile/save/request',
    '@@learner-profile/save/success',
    '@@learner-profile/save/failure'
)<string, LearnerProfileProps, Error>();

export const saveIndustryProfile = createAsyncAction(
    '@@learner-profile/save-industry/request',
    '@@learner-profile/save--industry/success',
    '@@learner-profile/save--industry/failure'
)<{ industryUUID: string[] }, LearnerProfileProps, Error>();

export const updateIndustryProfile = createAsyncAction(
    '@@learner-profile/update-industry/request',
    '@@learner-profile/update-industry/success',
    '@@learner-profile/update-industry/failure'
)<string[], LearnerProfileProps, Error>();

export const saveJobFunctionProfile = createAsyncAction(
    '@@learner-profile/save-jobfunction/request',
    '@@learner-profile/save-jobfunction/success',
    '@@learner-profile/save-jobfunction/failure'
)<{ jobFunctions: string[] }, LearnerProfileProps, Error>();

export const updateJobFunctionProfile = createAsyncAction(
    '@@learner-profile/update-jobfunction/request',
    '@@learner-profile/update-jobfunction/success',
    '@@learner-profile/update-jobfunction/failure'
)<string[], LearnerProfileProps, Error>();

export const loadLanguageList = createAsyncAction(
    '@@learner-profile/language-list/request',
    '@@learner-profile/language-list/success',
    '@@learner-profile/language-list/failure'
)<void, LanguageProps[], Error>();

export const loadJobFunctionList = createAsyncAction(
    '@@learner-profile/jobfunction-list/request',
    '@@learner-profile/jobfunction-list/success',
    '@@learner-profile/jobfunction-list/failure'
)<void, JobFunctionProps[], Error>();

export const loadLearnerProfileAndLanguage = createAction(
    '@@learner-profile/load-all'
)();
export const setLearnerLanguage = createAction(
    '@@learner-profile/language'
)<LanguageRecord | null>();

export const loadIndustryList = createAsyncAction(
    '@@learner-profile/industry-list/request',
    '@@learner-profile/industry-list/success',
    '@@learner-profile/industry-list/failure'
)<void, IndustryProps[], Error>();

export const loadLearnerProfileAndIndustry = createAction(
    '@@learner-profile/load-all'
)();
export const setLearnerIndustry = createAction(
    '@@learner-profile/industry'
)<IndustryCurrentRecord | null>();

export const setLearnerJobFunction = createAction(
    '@@learner-profile/jobfunction'
)<JobFunctionRecord | null>();

export const setLearnerProfile = createAction(
    '@@learner-profile/set-learner-profile'
)<LearnerProfileRecord | null>();
