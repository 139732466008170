import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { Axios } from 'axios-observable';
import { ApiHeaders } from '../api-headers';
import { apiv1 } from '../api-urls';
import { TranslateResourceData } from '../../store/resources/translate/translate-record';

export interface TranslateProps {
    activityUUID: string;
    topic: string;
    user: string;
    text: Array<string>;
    toLanguage: string;
}

export class TranslateApi {
    constructor(private readonly apiHeaders: ApiHeaders) {}

    private getPostHeaders() {
        return {
            ...this.apiHeaders.getHeaders(),
            'Content-Type': 'application/json',
        };
    }

    public loadTranslate(
        translateRequest: TranslateProps
    ): Observable<TranslateResourceData> {
        return Axios.post(apiv1() + 'translate/', translateRequest, {
            headers: this.getPostHeaders(),
        }).pipe(
            map((x) => {
                return new TranslateResourceData({
                    ...x.data,
                });
            })
        );
    }
}
