import { Record } from 'immutable';
import moment, { Moment } from 'moment';
import { LessonReportType } from './lesson-report-type';
import { ResourceContentType } from '../../resources/resource-content-type';

interface LessonReportTeacherProps {
    firstName: string;
    lastName: string;
    teacherUUID: string;
    gender: string;
    photo: string;
}

const defaultLessonReportTeacher: LessonReportTeacherProps = {
    firstName: '',
    lastName: '',
    teacherUUID: '',
    gender: '',
    photo: '',
};

export class LessonReportTeacher
    extends Record(defaultLessonReportTeacher)
    implements LessonReportTeacherProps {}

interface ContentAssetProps {
    articleId: number;
    groupId: number;
    displayName: string;
    type: ResourceContentType;
    comment: string;
}

const defaultContentAssetProps: ContentAssetProps = {
    articleId: 0,
    groupId: 0,
    displayName: '',
    type: ResourceContentType.Article,
    comment: '',
};

export class ContentAsset
    extends Record(defaultContentAssetProps)
    implements ContentAssetProps {}

interface LessonReportLearnerProps {
    name: string;
    learnerUUID: string;
    gender: string;
    photo: string;
}

const defaultLessonReportLearner: LessonReportLearnerProps = {
    name: '',
    learnerUUID: '',
    gender: '',
    photo: '',
};

export class LessonReportLearner
    extends Record(defaultLessonReportLearner)
    implements LessonReportLearnerProps {}

interface LessonReportActivityItemProps {
    comment: string;
    skills: string;
    value: string;
}

const defaultLessonReportActivityItem: LessonReportActivityItemProps = {
    comment: '',
    skills: '',
    value: '',
};

export class LessonReportActivityItem
    extends Record(defaultLessonReportActivityItem)
    implements LessonReportActivityItemProps {}

export interface LessonReportProps {
    startDateTime: Moment;
    endDateTime: Moment;
    teacher: LessonReportTeacher;
    duration: number;
    lessonID: string;
    type: LessonReportType;
    corrections: string;
    greetings: string;
    greetingsHtml: string;
    words: string;
    todayExternalResources: Array<string>;
    homeworkExternalResources: Array<LessonReportActivityItem>;
    homework: Array<LessonReportActivityItem>;
    homeworkAssets: Array<ContentAsset>;
    today: Array<LessonReportActivityItem>;
    todayAssets: Array<ContentAsset>;
    tagNames: Array<string>;
    lessonActivitiesAssets: Array<ContentAsset>;
    modifiedVocabularyContent: string;
    modifiedCorrectionsContent: string;
    modifiedCorrectionsContentMobile: string[];
}

const defaultLessonReportProps: LessonReportProps = {
    startDateTime: moment().startOf('day'),
    endDateTime: moment().startOf('day'),
    teacher: new LessonReportTeacher(),
    duration: 0,
    lessonID: '',
    type: LessonReportType.Classroom,
    corrections: '',
    greetings: '',
    greetingsHtml: '',
    words: '',
    todayExternalResources: Array<string>(),
    homeworkExternalResources: Array<LessonReportActivityItem>(),
    today: Array<LessonReportActivityItem>(),
    homework: Array<LessonReportActivityItem>(),
    tagNames: Array<string>(),
    homeworkAssets: Array<ContentAsset>(),
    todayAssets: Array<ContentAsset>(),
    lessonActivitiesAssets: Array<ContentAsset>(),
    modifiedVocabularyContent: '',
    modifiedCorrectionsContent: '',
    modifiedCorrectionsContentMobile: [],
};

export class LessonReport
    extends Record(defaultLessonReportProps)
    implements LessonReportProps {}

interface LessonReportListItemProps {
    startDateTime: Moment;
    endDateTime: Moment;
    teacher: LessonReportTeacher;
    learner: LessonReportLearner;
    lessonReportUUID: string;
    type: LessonReportType;
    lessonId: number;
    derive?: string;
}

const defaultLessonReportListItemProps: LessonReportListItemProps = {
    startDateTime: moment().startOf('day'),
    endDateTime: moment().startOf('day'),
    teacher: new LessonReportTeacher(),
    learner: new LessonReportLearner(),
    type: LessonReportType.Classroom,
    lessonReportUUID: '',
    lessonId: 0,
    derive: 'legacy',
};

export class LessonReportListItem
    extends Record(defaultLessonReportListItemProps)
    implements LessonReportListItemProps {}
