import { ActionType, createReducer } from 'typesafe-actions';
import { combineReducers } from 'redux';
import { List } from 'immutable';
import { ResourceState, LoadingState } from '../resources';
import { articleActions } from './articles-actions';
import { ResourceContent } from '../resource-content';
import { modifyResourceStatus } from '../../../epics/resources/helpers';
import { loadInProgressActivities } from '../in-progress/in-progress-actions';
import { loadCompletedActivities } from '../completed/completed-actions';

export type ArticleAction = ActionType<typeof articleActions>;

const descriptions = createReducer(List<ResourceContent>())
    .handleAction(articleActions.loadArticles.success, (state, action) => {
        return state.merge(List(action.payload));
    })
    .handleAction(articleActions.clearAllResources, (state) => state.clear())
    .handleAction(loadInProgressActivities.success, (state, action) => {
        const statuses = action.payload;
        return state.map(modifyResourceStatus<ResourceContent>(statuses, null));
    })
    .handleAction(loadCompletedActivities.success, (state, action) => {
        const statuses = action.payload;
        return state.map(modifyResourceStatus<ResourceContent>(null, statuses));
    });

const filtered = createReducer(List<ResourceContent>())
    .handleAction(
        articleActions.loadFilteredArticles.success,
        (state, action) => {
            return state.merge(List(action.payload));
        }
    )
    .handleAction(articleActions.resetArticles, (_, action) => action.payload)
    .handleAction(
        [articleActions.clearArticles, articleActions.clearAllResources],
        (state) => state.clear()
    );

const loadingState = createReducer('idle' as LoadingState)
    .handleAction(
        [
            articleActions.loadArticles.request,
            articleActions.loadFilteredArticles.request,
        ],
        () => 'pending' as LoadingState
    )
    .handleAction(
        [
            articleActions.loadArticles.success,
            articleActions.loadFilteredArticles.success,
        ],
        () => 'succeeded' as LoadingState
    )
    .handleAction(
        [
            articleActions.loadArticles.failure,
            articleActions.loadFilteredArticles.failure,
        ],
        () => 'failed' as LoadingState
    );

export const articlesReducer = () =>
    combineReducers<ResourceState, ArticleAction>({
        descriptions,
        filtered,
        loadingState,
    });
