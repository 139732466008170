import { RootEpic } from '../root-epic';
import { filter, tap, map, ignoreElements } from 'rxjs/operators';
import { Action } from '../../store/root-action';
import { isActionOf } from 'typesafe-actions';
import { logger } from '../../logging';

const logAllActivitiesEpic: RootEpic = (action$) =>
    action$.pipe(
        filter(isActionOf(Action.activities.trackActivity)),
        map((x) => x.payload),
        tap({ next: (p) => logger.trace('Activity registered', p.name) }),
        ignoreElements()
    );

export const activitiesTrackingEpics = [logAllActivitiesEpic];
