import { createAsyncAction, createCustomAction } from 'typesafe-actions';
import { List, Set } from 'immutable';
import { QuizDescription } from '../../services/quizzes/quiz';
import Question from '../../services/quizzes/question';

/** Request to load quiz for particular resource */
const loadQuiz = createAsyncAction(
    '@@quizzes/request',
    '@@quizzes/success',
    '@@quizzes/failure'
)<
    { articleId: string; qArticleId: number; groupId: number },
    QuizDescription,
    Error
>();

/** Click on the "Next" button */
const finishedQuestion = createCustomAction('@@quizzes/show-next-question');

/** Possible options for answer that is to be commited */
export type CommitableAnswer =
    | Set<number>
    | List<string>
    | number
    | string
    | number[] // TODO(mt): handle positions explicitly
    | List<number>
    | FormData
    | null; // Speech recognition question

/** Save answer to question and validate it for correctness */
const commitedAnswer = createCustomAction(
    '@@quizzes/commit-answer',
    (answer: CommitableAnswer, ord: number, question: Question) => ({
        payload: { answer, ord, question },
    })
);

/** Recognise speech and validate it for correctness */
const commitedSpeechRecognitionAnswer = createCustomAction(
    '@@quizzes/commit-sr-answer',
    (answer: FormData, ord: number, question: Question) => ({
        payload: { answer, ord, question },
    })
);

/** Save result of recognising*/
const saveSpeechRecognitionAnswer = createCustomAction(
    '@@quizzes/save-sr-answer',
    (result: SpeechToTextResult | null, ord: number, question: Question) => ({
        payload: { result, ord, question },
    })
);

/** Triggered after the last question's "Next" button was clicked */
const reachedQuizEnd = createCustomAction('@@quizzes/show-quiz-results');

/** Initiate new QuizAttempt, starting from the first question */
const retakeQuiz = createCustomAction('@@quizzes/retake-quiz');

/** Switch to "Review" mode, triggered on "Review" button */
const reviewQuizAnswers = createCustomAction('@@quizzes/switch-to-review');

/** Triggered on receiving new attempt identifier */
const gotNewQuizResultIdentifier = createCustomAction(
    '@@quizzes/got-qri',
    (qri: number) => ({ payload: { qri } })
);

/** Triggered after commiting all questions in the quiz */
const finishedQuizAttempt = createCustomAction(
    '@@quizzes/finished-quiz-attempt'
);

/** Triggered after some error */
const displayGenericError = createCustomAction(
    '@@quizzes/generic-error',
    (show: boolean) => ({
        payload: show,
    })
);

export const actions = {
    loadQuiz,
    finishedQuestion,
    saveSpeechRecognitionAnswer,
    commitedSpeechRecognitionAnswer,
    commitedAnswer,
    reachedQuizEnd,
    retakeQuiz,
    reviewQuizAnswers,
    gotNewQuizResultIdentifier,
    finishedQuizAttempt,
    displayGenericError,
};
