import { RootEpic } from './root-epic';
import { of } from 'rxjs';
import {
    catchError,
    concatMap,
    filter,
    map,
    switchMap,
    withLatestFrom,
} from 'rxjs/operators';
import { Action } from '../store/root-action';
import { isActionOf } from 'typesafe-actions';
import {
    getActionWithStatus,
    getStatusLoadingActions,
    modifyCourseStatus,
    modifyResourceStatus,
} from './resources/helpers';

import { TrainingPath } from '../store/resources/training-path/training-path-record';
import { ResourceContent } from '../store/resources/resource-content';
import { AssignmentDTOInterface } from '../services/training-path-api';

export const loadTrainingPathEpic: RootEpic = (
    action$,
    state$,
    { trainingPathApi }
) =>
    action$.pipe(
        filter(isActionOf(Action.trainingPath.loadTrainingPath.request)),
        withLatestFrom(state$),
        switchMap(([_, state]) => {
            // P2-2043 - Fix for Japanese language code. API expects 'jp' instead of 'ja'
            const languageCode =
                state.learnerProfile.currentLanguage?.code === 'ja'
                    ? 'jp'
                    : (state.learnerProfile.currentLanguage?.code as string);
            return trainingPathApi
                .loadTrainingPath(state.user.identity, languageCode)
                .pipe(
                    concatMap((loadedCourses) => {
                        const { statusLoadingActions, actualizedCourses } =
                            getActionWithStatus(loadedCourses, state);
                        return of(
                            ...statusLoadingActions,
                            Action.trainingPath.loadTrainingPath.success(
                                actualizedCourses
                            )
                        );
                    }),
                    // map((x) => Action.trainingPath.loadTrainingPath.success(x)),
                    catchError((e) =>
                        of(Action.trainingPath.loadTrainingPath.failure(e))
                    )
                );
        })
    );

export const loadAdditionalTrainingPathEpic: RootEpic = (
    action$,
    state$,
    { trainingPathApi }
) =>
    action$.pipe(
        filter(isActionOf(Action.trainingPath.loadTrainingPath.request)),
        withLatestFrom(state$),
        switchMap(([_, state]) => {
            const languageUUID =
                state.learnerProfile.currentLanguage?.languageUUID || null;
            return trainingPathApi
                .loadAdditionalTrainingPath(state.user.identity, languageUUID)
                .pipe(
                    concatMap((DTOs: AssignmentDTOInterface[]) => {
                        const loadedCourses = DTOs.map((dto) => {
                            return new TrainingPath({
                                ...dto,
                                articleId:
                                    dto?.legacy?.articleId?.toString() || '',
                                // contentTotal: number,
                                contentUUID: dto.uuid,
                                uuid: dto.uuid,
                                description: dto.description,
                                image: dto.thumbnail,
                                title: dto.title,
                            });
                        });
                        const { statusLoadingActions, actualizedCourses } =
                            getActionWithStatus(loadedCourses, state);
                        const EnableToLunchTPTotal = DTOs.length > 0;
                        let loadActions = [
                            ...statusLoadingActions,
                            Action.trainingPath.loadAdditionalTrainingPath.success(
                                actualizedCourses
                            ),
                        ];
                        if (EnableToLunchTPTotal) {
                            loadActions.push(
                                Action.trainingPath.loadAdditionalTrainingPathContentTotal.request(
                                    DTOs
                                )
                            );
                        }

                        return of(...loadActions);
                    }),
                    catchError((e) =>
                        of(
                            Action.trainingPath.loadAdditionalTrainingPath.failure(
                                e
                            )
                        )
                    )
                );
        })
    );

export const loadAdditionalTrainingPathContentTotalEpic: RootEpic = (
    action$,
    state$,
    { trainingPathApi }
) =>
    action$.pipe(
        filter(
            isActionOf(
                Action.trainingPath.loadAdditionalTrainingPathContentTotal
                    .request
            )
        ),
        withLatestFrom(state$),
        switchMap(([x, state]) => {
            return trainingPathApi
                .loadAdditionalTrainingPathContentTotal(x.payload)
                .pipe(
                    concatMap((loadedCourses) => {
                        const { statusLoadingActions, actualizedCourses } =
                            getActionWithStatus(loadedCourses, state);

                        return of(
                            ...statusLoadingActions,
                            Action.trainingPath.loadAdditionalTrainingPathContentTotal.success(
                                actualizedCourses
                            )
                        );
                    }),
                    // map((x) => Action.trainingPath.loadTrainingPath.success(x)),
                    catchError((e) =>
                        of(
                            Action.trainingPath.loadAdditionalTrainingPathContentTotal.failure(
                                e
                            )
                        )
                    )
                );
        })
    );

export const activitiesTrainingPathEpic: RootEpic = (
    action$,
    state$,
    { trainingPathApi }
) =>
    action$
        .pipe(
            filter(
                isActionOf(
                    Action.trainingPath.loadActivitiesTrainingPath.request
                )
            )
        )
        .pipe(
            withLatestFrom(state$),
            switchMap(([x, state]) => {
                return trainingPathApi
                    .loadTrainingList(
                        state.trainingPath.activitiesTP.count(),
                        x.payload.activityId
                    )
                    .pipe(
                        concatMap((loadedResources) => {
                            const inProgressResources =
                                state.resources.inProgress.list;
                            const completedResources =
                                state.resources.completed.list;
                            const actualizedResources = loadedResources.map(
                                modifyResourceStatus<ResourceContent>(
                                    inProgressResources,
                                    completedResources
                                )
                            );
                            return of(
                                ...getStatusLoadingActions(
                                    inProgressResources,
                                    completedResources
                                ),
                                Action.trainingPath.loadActivitiesTrainingPath.success(
                                    actualizedResources
                                )
                            );
                        }),
                        catchError((e) => {
                            console.error(e);
                            return of(
                                Action.trainingPath.loadActivitiesTrainingPath.failure(
                                    e
                                )
                            );
                        })
                    );
            })
        );

export const loadCourseCategoriesEpic: RootEpic = (
    action$,
    state$,
    { trainingPathApi }
) =>
    action$.pipe(
        filter(isActionOf(Action.trainingPath.loadCourseCategories.request)),
        withLatestFrom(state$),
        switchMap(([x, state]) => {
            //call selector language
            return trainingPathApi
                .loadCourseCategories(
                    state.learnerProfile.currentLanguage?.code as string,
                    state.user.currentUILanguage.id
                )
                .pipe(
                    map((x) =>
                        Action.trainingPath.loadCourseCategories.success(x)
                    ),
                    catchError((e) =>
                        of(Action.trainingPath.loadCourseCategories.failure(e))
                    )
                );
        })
    );

export const loadCoursesEpic: RootEpic = (
    action$,
    state$,
    { trainingPathApi }
) =>
    action$.pipe(
        filter(isActionOf(Action.trainingPath.loadCourses.request)),
        withLatestFrom(state$),
        switchMap(([x, state]) => {
            return trainingPathApi.loadCourses(x.payload.courseTags).pipe(
                concatMap((loadedCourses) => {
                    const inProgressResources = state.resources.inProgress.list;
                    const completedResources = state.resources.completed.list;
                    const coursesStatuses = state.trainingPath.coursesStatuses;
                    const actualizedCourses = loadedCourses.map(
                        modifyCourseStatus(coursesStatuses)
                    ) as TrainingPath[];
                    return of(
                        ...getStatusLoadingActions(
                            inProgressResources,
                            completedResources,
                            coursesStatuses
                        ),
                        Action.trainingPath.loadCourses.success(
                            actualizedCourses
                        )
                    );
                }),
                catchError((e) =>
                    of(Action.trainingPath.loadCourses.failure(e))
                )
            );
        })
    );

export const loadAllCoursesEpic: RootEpic = (
    action$,
    state$,
    { trainingPathApi }
) =>
    action$.pipe(
        filter(isActionOf(Action.trainingPath.loadAllCourses.request)),
        withLatestFrom(state$),
        switchMap(([x, state]) => {
            return trainingPathApi
                .loadCourses(x.payload.courseTags as any)
                .pipe(
                    map((loadedCourses) =>
                        Action.trainingPath.loadAllCourses.success(
                            loadedCourses
                        )
                    ),
                    catchError((e) =>
                        of(Action.trainingPath.loadAllCourses.failure(e))
                    )
                );
        })
    );

export const loadCoursesLegacyById: RootEpic = (
    action$,
    state$,
    { trainingPathApi }
) =>
    action$.pipe(
        filter(isActionOf(Action.trainingPath.loadLegacyCourseId.request)),
        switchMap((x) => {
            return trainingPathApi
                .loadCoursesLegacyId(x.payload.trainingPathId)
                .pipe(
                    map((x) =>
                        Action.trainingPath.loadLegacyCourseId.success(x)
                    ),
                    catchError((e) =>
                        of(Action.trainingPath.loadLegacyCourseId.failure(e))
                    )
                );
        })
    );

export const loadCurrentlyViewedCourseEpic: RootEpic = (
    action$,
    state$,
    { trainingPathApi }
) =>
    action$.pipe(
        filter(
            isActionOf(Action.trainingPath.loadCurrentlyViewedCourse.request)
        ),
        concatMap((x) => {
            return trainingPathApi
                .loadNowViewableCourseData(x.payload.activityId)
                .pipe(
                    map((x) => {
                        return Action.trainingPath.loadCurrentlyViewedCourse.success(
                            x
                        );
                    }),
                    catchError((e) =>
                        of(
                            Action.trainingPath.loadCurrentlyViewedCourse.failure(
                                e
                            )
                        )
                    )
                );
        })
    );

export const trainingPathEpics = [
    loadTrainingPathEpic,
    loadAdditionalTrainingPathEpic,
    loadAdditionalTrainingPathContentTotalEpic,
    activitiesTrainingPathEpic,
    loadCourseCategoriesEpic,
    loadCoursesEpic,
    loadAllCoursesEpic,
    loadCoursesLegacyById,
    loadCurrentlyViewedCourseEpic,
];
