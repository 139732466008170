import { createReducer } from 'typesafe-actions';
import { combineReducers } from 'redux';
import { Action } from '../../../root-action';
import { BoardFigureData, BoardTabData, BoardTabProps } from './board-data';
import { Map } from 'immutable';
import { clearClassroom } from '../../vcr-actions';

const updateTabState = (
    state: Map<number, BoardTabData>,
    updateData: BoardTabProps,
    updateKeys: (
        | 'imgSrc'
        | 'videoSrc'
        | 'videoPosition'
        | 'isVideoPlaying'
        | 'id'
    )[]
) => {
    const { id } = updateData;

    const currentItem = state.get(id);

    if (!currentItem) return state;

    let updateItem = updateKeys.reduce(
        (result, key) => result.set(key, updateData[key]),
        currentItem
    );

    return state.set(id, updateItem);
};

const isBoardVisible = createReducer(false)
    .handleAction(
        Action.vcr.classroom.board.showBoard,
        (state, action) => action.payload
    )
    .handleAction(clearClassroom, () => false);

const tabs = createReducer(Map<number, BoardTabData>())
    .handleAction(Action.vcr.classroom.board.addTab, (state, action) =>
        state.set(action.payload.id, new BoardTabData(action.payload))
    )
    .handleAction(Action.vcr.classroom.board.closeTab, (state, action) =>
        state.filter((item) => item.id !== action.payload)
    )
    .handleAction(Action.vcr.classroom.board.addTabContent, (state, action) => {
        return updateTabState(state, action.payload, ['imgSrc']);
    })
    .handleAction(Action.vcr.classroom.board.playVideoTab, (state, action) => {
        return updateTabState(state, action.payload, [
            'isVideoPlaying',
            'videoPosition',
        ]);
    })
    .handleAction(Action.vcr.classroom.board.stopVideoTab, (state, action) => {
        return updateTabState(state, action.payload, ['isVideoPlaying']);
    })
    .handleAction(clearClassroom, (state) => state.clear());

const activeTab = createReducer(0)
    .handleAction(
        Action.vcr.classroom.board.setActiveTab,
        (state, action) => action.payload
    )
    .handleAction(clearClassroom, () => 0);

const figures = createReducer(Map<number, Map<string, BoardFigureData>>())
    .handleAction(Action.vcr.classroom.board.addFigure, (state, action) => {
        let figuresListOfTab = state.get(action.payload.tabId);
        const figureObject = {
            [action.payload.id]: new BoardFigureData(action.payload),
        };

        if (!figuresListOfTab) {
            return state.set(action.payload.tabId, Map(figureObject));
        }

        if (action.payload.type === 'pointer') {
            figuresListOfTab = figuresListOfTab.filter(
                (item) => item.type !== 'pointer'
            );
        }

        return state.set(
            action.payload.tabId,
            figuresListOfTab.merge(figureObject)
        );
    })
    .handleAction(Action.vcr.classroom.board.clearFigures, (state, action) =>
        state.set(action.payload, Map<string, BoardFigureData>())
    )
    .handleAction(Action.vcr.classroom.board.removeFigure, (state, action) => {
        const figuresListOfTab = state.get(action.payload.tabId);

        if (!figuresListOfTab) {
            return state;
        }

        return state.set(
            action.payload.tabId,
            figuresListOfTab.filter((item) => item.id !== action.payload.id)
        );
    })
    .handleAction(clearClassroom, (state) => state.clear());

const isVideoTabPlaying = createReducer(false)
    .handleAction(Action.vcr.classroom.board.playVideoTab, () => true)
    .handleAction(
        [Action.vcr.classroom.board.stopVideoTab, clearClassroom],
        () => false
    );

export const board = combineReducers({
    isBoardVisible,
    isVideoTabPlaying,
    tabs,
    activeTab,
    figures,
});
