import { RootEpic } from '../root-epic';
import { of } from 'rxjs';
import {
    catchError,
    concatMap,
    filter,
    map,
    mergeMap,
    switchMap,
    withLatestFrom,
} from 'rxjs/operators';
import { isActionOf } from 'typesafe-actions';
import { Action } from '../../store/root-action';
import { UserCompletedHowToSection } from '../../store/resources/howtos/user-completed-block';
import moment from 'moment';
import { LegacyResourceContentApi } from '../../services/resources/legacy-resource-content-api';
import { getStatusLoadingActions, modifyResourceStatus } from './helpers';
import { ResourceContent } from '../../store/resources/resource-content';
import { FilterOption } from '../../components/filters/filters';

type StoreProperty = 'grammar' | 'howtos' | 'vocabularies';

export const epicParams = [
    {
        action: Action.howtos.loadGrammar,
        storeProperty: 'grammar' as StoreProperty,
        loadData: (
            startIndex: number,
            languageCode: string,
            x: LegacyResourceContentApi,
            levels?: FilterOption[],
            categories?: FilterOption[]
        ) => x.loadGrammarList(startIndex, languageCode, levels, categories),
    },
    {
        action: Action.howtos.loadHowtos,
        storeProperty: 'howtos' as StoreProperty,
        loadData: (
            startIndex: number,
            languageCode: string,
            x: LegacyResourceContentApi,
            levels?: FilterOption[],
            categories?: FilterOption[]
        ) => x.loadHowtosList(startIndex, languageCode, levels, categories),
    },
    {
        action: Action.howtos.loadVocabularies,
        storeProperty: 'vocabularies' as StoreProperty,
        loadData: (
            startIndex: number,
            languageCode: string,
            x: LegacyResourceContentApi,
            levels?: FilterOption[],
            categories?: FilterOption[]
        ) =>
            x.loadVocabulariesList(
                startIndex,
                languageCode,
                levels,
                categories
            ),
    },
];

export const epicFilteredParams = [
    {
        action: Action.howtos.loadFilteredGrammar,
        storeProperty: 'grammar' as StoreProperty,
        loadData: (
            startIndex: number,
            languageCode: string,
            x: LegacyResourceContentApi,
            levels?: FilterOption[],
            categories?: FilterOption[]
        ) => x.loadGrammarList(startIndex, languageCode, levels, categories),
    },
    {
        action: Action.howtos.loadFilteredHowtos,
        storeProperty: 'howtos' as StoreProperty,
        loadData: (
            startIndex: number,
            languageCode: string,
            x: LegacyResourceContentApi,
            levels?: FilterOption[],
            categories?: FilterOption[]
        ) => x.loadHowtosList(startIndex, languageCode, levels, categories),
    },
    {
        action: Action.howtos.loadFilteredVocabularies,
        storeProperty: 'vocabularies' as StoreProperty,
        loadData: (
            startIndex: number,
            languageCode: string,
            x: LegacyResourceContentApi,
            levels?: FilterOption[],
            categories?: FilterOption[]
        ) =>
            x.loadVocabulariesList(
                startIndex,
                languageCode,
                levels,
                categories
            ),
    },
];

export const loadingEpics = epicParams.map<RootEpic>(
    ({ action, loadData, storeProperty }) =>
        (action$, state$, { legacyResourceContentApi }) => {
            return action$.pipe(
                filter(isActionOf(action.request)),
                withLatestFrom(state$),
                switchMap(([y, state]) => {
                    return loadData(
                        state.resources[storeProperty].descriptions.count(),
                        state.learnerProfile.currentLanguage?.code as string,
                        legacyResourceContentApi,
                        y?.payload?.levels,
                        y?.payload?.categories
                    ).pipe(
                        concatMap((loadedResources) => {
                            const inProgressResources =
                                state.resources.inProgress.list;
                            const completedResources =
                                state.resources.completed.list;
                            const actualizedResources = loadedResources.map(
                                modifyResourceStatus<ResourceContent>(
                                    inProgressResources,
                                    completedResources
                                )
                            );
                            return of(
                                ...getStatusLoadingActions(
                                    inProgressResources,
                                    completedResources
                                ),
                                action.success(actualizedResources)
                            );
                        }),
                        catchError((e) => of(action.failure(e)))
                    );
                })
            );
        }
);

export const loadingFilteredEpics = epicFilteredParams.map<RootEpic>(
    (x) =>
        (action$, state$, { legacyResourceContentApi }) => {
            return action$.pipe(
                filter(isActionOf(x.action.request)),
                withLatestFrom(state$),
                switchMap(([y, state]) => {
                    return x
                        .loadData(
                            state.resources[x.storeProperty].filtered.count(),
                            state.learnerProfile.currentLanguage
                                ?.code as string,
                            legacyResourceContentApi,
                            y?.payload?.levels,
                            y?.payload?.categories
                        )
                        .pipe(
                            concatMap((loadedResources) => {
                                const inProgressResources =
                                    state.resources.inProgress.list;
                                const completedResources =
                                    state.resources.completed.list;
                                const actualizedResources = loadedResources.map(
                                    modifyResourceStatus<ResourceContent>(
                                        inProgressResources,
                                        completedResources
                                    )
                                );
                                return of(
                                    ...getStatusLoadingActions(
                                        inProgressResources,
                                        completedResources
                                    ),
                                    x.action.success(actualizedResources)
                                );
                            }),
                            catchError((e) => of(x.action.failure(e)))
                        );
                })
            );
        }
);

export const openResourceEpic: RootEpic = (action$, state$, _) => {
    return action$.pipe(
        filter(isActionOf(Action.resources.openResource)),
        switchMap((x) => {
            return of(Action.howtos.openHowto(x.payload));
        })
    );
};

export const openResourceUsingCuuidEpic: RootEpic = (action$, state$, _) => {
    return action$.pipe(
        filter(isActionOf(Action.resources.openResourceUsingCuuid)),
        switchMap((x) => {
            return of(Action.howtos.openHowtoUsingCuuid(x.payload));
        })
    );
};

export const openHowtoEpic: RootEpic = (action$, state$, _) => {
    return action$.pipe(
        filter(isActionOf(Action.howtos.openHowto)),
        withLatestFrom(state$),
        mergeMap(([x, state]) => {
            let learnerId = String(state.user.identity?.learnerExternalId);

            return of(
                Action.resources.loadSelectedResource.request(x.payload),
                Action.howtos.loadCompletedBlock.request({
                    ...x.payload,
                    learnerId,
                })
            );
        })
    );
};

export const openHowtoUsingCuuidEpic: RootEpic = (action$, state$, _) => {
    return action$.pipe(
        filter(isActionOf(Action.howtos.openHowtoUsingCuuid)),
        switchMap((x) => {
            return of(
                Action.resources.loadSelectedUsingUUIDResource.request(
                    x.payload
                )
            );
        })
    );
};

export const loadSelectedHowtoUsingCUUIDEpic: RootEpic = (
    action$,
    state$,
    { howtosApi }
) =>
    action$.pipe(
        filter(
            isActionOf(Action.resources.loadSelectedUsingUUIDResource.request)
        ),
        switchMap((x) => {
            return howtosApi.loadContentUsingUUID(x.payload).pipe(
                map((r) => {
                    return Action.resources.loadSelectedUsingUUIDResource.success(
                        r
                    );
                }),
                catchError((e) =>
                    of(
                        Action.resources.loadSelectedUsingUUIDResource.failure(
                            e
                        )
                    )
                )
            );
        })
    );

export const loadSelectedHowtoEpic: RootEpic = (
    action$,
    state$,
    { howtosApi, resourceContentApi }
) =>
    action$.pipe(
        filter(isActionOf(Action.resources.loadSelectedResource.request)),
        withLatestFrom(state$),
        switchMap(([x, state]) => {
            const { contentType, resourceId, groupId } = x.payload;

            if (contentType === 'workshop') {
                return resourceContentApi
                    .load({
                        groupId,
                        articleId: resourceId,
                    })
                    .pipe(
                        map((r) => {
                            return Action.resources.loadSelectedResource.success(
                                r
                            );
                        }),
                        catchError((e) =>
                            of(Action.resources.loadSelectedResource.failure(e))
                        )
                    );
            } else {
                return howtosApi
                    .loadContent({
                        contentType,
                        resourceId,
                        groupId,
                        languageCode: state.learnerProfile.currentLanguage
                            ?.code as string,
                    })
                    .pipe(
                        map((r) => {
                            return Action.resources.loadSelectedResource.success(
                                r
                            );
                        }),
                        catchError((e) =>
                            of(Action.resources.loadSelectedResource.failure(e))
                        )
                    );
            }
        })
    );

export const loadCompletedBlockEpic: RootEpic = (
    action$,
    state$,
    { howtosApi }
) =>
    action$.pipe(
        filter(isActionOf(Action.howtos.loadCompletedBlock.request)),
        switchMap((x) => {
            return howtosApi
                .loadCompletedBlock(
                    {
                        resourceId: x.payload.resourceId,
                        groupId: x.payload.groupId,
                    },
                    {
                        learnerId: x.payload.learnerId,
                    }
                )
                .pipe(
                    map((block) =>
                        Action.howtos.loadCompletedBlock.success({
                            resource: { resourceId: x.payload.resourceId },
                            block: block,
                        })
                    ),
                    catchError((e) =>
                        of(Action.howtos.loadCompletedBlock.failure(e))
                    )
                );
        })
    );

export const saveCompletedSectionEpic: RootEpic = (action$, state$, _) =>
    action$.pipe(
        filter(isActionOf(Action.howtos.saveCompletedSection)),
        withLatestFrom(state$),
        switchMap(([x, state]) => {
            if (x.payload.section.blockId) {
                return of(
                    Action.howtos.updateCompletedBlock.request({
                        resource: { resourceId: x.payload.resource.resourceId },
                        section: x.payload.section,
                    })
                );
            }

            let learnerId = String(state.user.identity?.learnerExternalId);

            return of(
                Action.howtos.saveCompletedBlock.request({
                    resource: x.payload.resource,
                    user: { learnerId: learnerId },
                    section: x.payload.section,
                })
            );
        })
    );

export const saveCompletedBlockEpic: RootEpic = (
    action$,
    state$,
    { howtosApi }
) =>
    action$.pipe(
        filter(isActionOf(Action.howtos.saveCompletedBlock.request)),
        switchMap((x) => {
            return howtosApi
                .saveCompletedBlock(
                    x.payload.resource,
                    { learnerId: x.payload.user.learnerId },
                    x.payload.section
                )
                .pipe(
                    map((block) =>
                        Action.howtos.saveCompletedBlock.success({
                            resource: {
                                resourceId: x.payload.resource.resourceId,
                            },
                            block: block,
                        })
                    ),
                    catchError((e) =>
                        of(Action.howtos.saveCompletedBlock.failure(e))
                    )
                );
        })
    );

export const updateCompletedBlockEpic: RootEpic = (
    action$,
    state$,
    { howtosApi }
) =>
    action$.pipe(
        filter(isActionOf(Action.howtos.updateCompletedBlock.request)),
        switchMap((x) => {
            return howtosApi.updateCompletedBlock(x.payload.section).pipe(
                map((instanceId) =>
                    Action.howtos.updateCompletedBlock.success({
                        resource: { resourceId: x.payload.resource.resourceId },
                        section: new UserCompletedHowToSection({
                            instanceId: instanceId,
                            dateCompleted: moment
                                .utc(Date.now())
                                .format('YYYY-MM-DD HH:mm:ss'),
                        }),
                    })
                ),
                catchError((e) =>
                    of(Action.howtos.updateCompletedBlock.failure(e))
                )
            );
        })
    );

export const howtosEpics = [
    ...loadingEpics,
    ...loadingFilteredEpics,
    openResourceEpic,
    openResourceUsingCuuidEpic,
    openHowtoEpic,
    openHowtoUsingCuuidEpic,
    loadSelectedHowtoEpic,
    loadSelectedHowtoUsingCUUIDEpic,
    loadCompletedBlockEpic,
    saveCompletedSectionEpic,
    saveCompletedBlockEpic,
    updateCompletedBlockEpic,
];
