import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { NewPasswordForm as NewPasswordFormUI } from '@arcadia-projects/portal2-ui-library';
import { localization } from '../../localization';
import { useAppDispatch } from '../root/app-dispatch';
import { Action } from '../../store/root-action';
import {
    isLoggingIn,
    newPasswordError,
    userPasswordPolicySelector,
} from '../../store/user/user-selectors';

export const NewPasswordForm = () => {
    const dispatch = useAppDispatch();

    const isSigningIn = useSelector(isLoggingIn);
    const newPasswordErrorText = useSelector(newPasswordError);
    const pattern = useSelector(userPasswordPolicySelector);

    const onNewPasswordSubmit = useCallback(
        (newPassword) =>
            dispatch(Action.user.setNewPassword.request({ newPassword })),
        [dispatch]
    );

    return (
        <NewPasswordFormUI
            passwordPlaceholder={localization.login_placeholder_NewPassword}
            confirmedPasswordPlaceholder={
                localization.login_placeholder_ConfirmNewPassword
            }
            submitText={localization.login_button_SignIn}
            validation={{
                match: localization.login_message_PasswordNotMatch,
                strong: localization.login_message_PasswordNotStrong,
                pattern,
            }}
            onSubmit={onNewPasswordSubmit}
            errorText={newPasswordErrorText ?? ''}
            loadingText={isSigningIn ? 'Signing in...' : ''}
        />
    );
};
