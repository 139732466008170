import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { localization } from './localization';
import { useAppDispatch } from './components/root/app-dispatch';
import { Action } from './store/root-action';
import {
    hasOfflineContractSelector,
    notifConnectionSelector,
} from './store/user/user-selectors';

interface ConenctivityProps {}

export const Connectivity: React.FC<ConenctivityProps> = () => {
    const dispatch = useAppDispatch();
    const hasOfflineContract = useSelector(hasOfflineContractSelector);
    const notifConnection = useSelector(notifConnectionSelector);

    useEffect(() => {
        dispatch(Action.offline.setConnectivity(navigator.onLine));
    }, [dispatch]);

    useEffect(() => {
        const connectionState = navigator.onLine;

        if (hasOfflineContract && connectionState !== notifConnection) {
            setTimeout(() => {
                if (connectionState) {
                    dispatch(
                        Action.contentSavedActions.setNotification({
                            show: true,
                            error: false,
                            info: true,
                            text: localization.offline_back_online,
                            key: 261,
                        })
                    );
                } else {
                    dispatch(
                        Action.contentSavedActions.setNotification({
                            show: true,
                            error: false,
                            info: true,
                            text: localization.offline_you_are_offline,
                            key: 260,
                        })
                    );
                }
            }, 3000);
        }
    }, [dispatch, hasOfflineContract, notifConnection]);

    return <React.Fragment />;
};
