import { createLoadResourcesAction } from '../resources-actions';
import { createAction } from 'typesafe-actions';
import { ResourceContent } from '../resource-content';
import { ResourceContentType } from '../resource-content-type';
import { clearAllResources } from '../resources-actions';
import { List } from 'immutable';

export const loadVideos = createLoadResourcesAction<ResourceContent>(
    ResourceContentType.Video
);

export const loadFilteredVideos = createLoadResourcesAction<ResourceContent>(
    `${ResourceContentType.Video}-filtered`
);

export const resetVideos =
    createAction('@@videos/reset')<List<ResourceContent>>();

export const clearVideos = createAction('@@videos/clear')();

export const videoActions = {
    loadVideos,
    loadFilteredVideos,
    resetVideos,
    clearVideos,
    clearAllResources,
};
