import { createReducer, ActionType } from 'typesafe-actions';
import { combineReducers } from 'redux';
import { UserContract } from './contract-data';
import { contractActions } from './contract-actions';
import { Action } from '../root-action';
import { List } from 'immutable';

export type ContractAction = ActionType<typeof contractActions>;

export interface ContractState {
    hasContracts: boolean;
    userContracts: List<UserContract>;
}

const initialState: ContractState = {
    hasContracts: false,
    userContracts: List<UserContract>(),
};

const hasContracts = createReducer(initialState.hasContracts)
    .handleAction(contractActions.loadUserContracts.success, () => true)
    .handleAction(
        [
            contractActions.loadUserContracts.request,
            contractActions.loadUserContracts.failure,
        ],
        () => false
    )
    .handleAction(
        Action.user.logout.success,
        (state) => initialState.hasContracts
    );

const userContracts = createReducer(initialState.userContracts)
    .handleAction(
        contractActions.loadUserContracts.success,
        (state, action) => action.payload
    )
    .handleAction(
        Action.user.logout.success,
        (state) => initialState.userContracts
    );

export const contractReducer = combineReducers<ContractState, ContractAction>({
    hasContracts,
    userContracts,
});
