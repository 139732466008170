import { LoginLayout } from '@arcadia-projects/portal2-ui-library';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { Action } from '../../store/root-action';
import { shouldResetPassword } from '../../store/user/user-selectors';
import { useAppDispatch } from '../root/app-dispatch';
import defaultBackgroundImage from './default-background-image.jpg';
import { ForgotPasswordForm } from './forgot-password-form';
import goFLUENTVideoBg from './goFLUENT-video-bg.mp4';
import logo from './gofluent.svg';
import { LoginForm } from './login-form';
import { NewPasswordForm } from './new-password-form';

export const LoginPage: React.FC = () => {
    const location = useLocation();
    const dispatch = useAppDispatch();

    const showForgotPasswordForm =
        location.pathname.includes('forgot-password');

    const showNewPasswordForm = useSelector(shouldResetPassword);

    useEffect(() => {
        dispatch(Action.user.resetLogoutProcess());
    }, [dispatch]);

    return (
        <LoginLayout
            logo={logo}
            poster={defaultBackgroundImage}
            video={goFLUENTVideoBg}
        >
            {showNewPasswordForm ? (
                <NewPasswordForm />
            ) : showForgotPasswordForm ? (
                <ForgotPasswordForm />
            ) : (
                <LoginForm />
            )}
        </LoginLayout>
    );
};
