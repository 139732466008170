import { TitleSlideContent } from '../../store/resources/howtos/title-slide-content';
import { List } from 'immutable';
import { Section } from '../../store/resources/howtos/section';
import {
    DialogueLine,
    GenericSlideContent,
    GenericSlideSet,
} from '../../store/resources/howtos/generic-slide-content';
import { SummarySlideContent } from '../../store/resources/howtos/summary-slide-content';
import { HowtoContent } from '../../store/resources/howtos/howto-content';
import { HowtoDescription } from '../../store/resources/howtos/howto-description';
import { parseDtoToResourceType } from './resource-type-dto';
import { LegacyIdentifierProps } from '../../store/resources/resources-content-record';

interface BlockSet {
    audio: string;
    content: string;
    image: string;
    video: string;
}

interface IBlockSets extends BlockSet {
    videoSubbed: string;
}

interface IBlockDialogueLine {
    thumbnail: string;
    audioTime: number;
    text: string;
}

// As prefix - CS: Content Service;
interface CSIBlockDialogueLine {
    thumbnail: string;
    audio_time: number;
    text: string;
}

interface CSIBlockSets extends BlockSet {
    video_subbed: string;
}

// overriding the object to use ANY to support the number or string;
interface ILegacyIdentifierProps extends LegacyIdentifierProps {
    articleId: any;
}

interface CSIContent {
    template: string;
    contentMetadata?: {
        topic: string;
        grading: string[];
        objectives: string[];
        organizationIds: number[];
        teachingPoint: string[];
        tagConcepts: string[];
        proficiency: {
            avgProficiency: number;
            minProficiency: number;
            maxProficiency: number;
        };
        shape: {
            studyTimeMinutes: number;
            searchable: boolean;
        };
        targetType: string;
        targetFunction: string;
        businessType: string;
        groupingList: string[];
        overridden: boolean;
    };
    legacy: ILegacyIdentifierProps;
    content: { [key: string]: any };
    id: string;
}

interface ISection {
    instanceID: string;
}

interface LegacyContent extends CSIContent {
    sectionList?: ISection[];
}

export class HowtoParser {
    private parseInnerContent(response: LegacyContent) {
        const content = response.content;
        const learnObjectives = content?.learnObjective?.repeatObjectives?.map(
            (o: any) => o.objective
        );
        const contentUUId = response?.content?.contentUUId;
        const quizUUID = response?.content?.quizUUID;

        const quizArticleId: string = content?.quizArticleId;
        const resourceType = content?.resourceType;

        const titleSlide = new TitleSlideContent({
            objectives: learnObjectives ? List(learnObjectives) : undefined,
            studyTime: content?.studyTime,
            levels: content?.levels,
            bgImage: content?.bgImage,
            resourceType: parseDtoToResourceType(resourceType),
        });

        const titleSection = new Section({
            instanceId:
                response.sectionList && response.sectionList[0]?.instanceID,
            title: content?.titlePage,
            slides: List([titleSlide]),
        });

        // we tend to use ANY type in this case; basically the object values in this is dynamic.
        const contentBlock = (content?.block as any[]) || [];

        const blocks =
            contentBlock?.map<Section>((x) => {
                // we tend to use ANY type in this case; basically the object values in this is dynamic.
                const slides = (x.slide as any[]).map((y) => {
                    const sets = (y.sets as IBlockSets[]).map(
                        (s) =>
                            new GenericSlideSet({
                                audio: s.audio,
                                content: s.content,
                                image: s.image,
                                video: s.video,
                                videoSubbed: s.videoSubbed,
                            })
                    );
                    const dialogueLines = (
                        y.dialogueLine as IBlockDialogueLine[]
                    ).map(
                        (d) =>
                            new DialogueLine({
                                thumbnail: d.thumbnail,
                                audioTime: d.audioTime,
                                content: d.text,
                            })
                    );
                    return new GenericSlideContent({
                        audioMain: y.audioMain,
                        mainImageUrl: y.mainImageUrl,
                        template: y.slideTemplate,
                        alignment: y.alignment,
                        mainVideoUrl: y.mainVideoUrl,
                        mainVideoUrlSubbed: y.mainVideoUrlSubbed,
                        intro: y.intro,
                        size: y.size,
                        sets: List(sets),
                        dialogueLine: List(dialogueLines),
                    });
                });
                return new Section({
                    instanceId: x.instanceId,
                    title: x.blockTitle,
                    slides: List(slides),
                });
            }) || [];

        const summarySlide = new SummarySlideContent({
            body: content?.summary,
        });

        const summarySection = new Section({
            //putting any type here to support any kind of response in this, since the started implementation either can accept any type;
            instanceId:
                response.sectionList &&
                response.sectionList.length &&
                (response.sectionList[response.sectionList.length - 1]
                    ?.instanceID as any),
            title: 'Summary',
            slides: List([summarySlide]),
        });

        const sections = [titleSection, ...blocks, summarySection];

        const howtoContent = new HowtoContent({
            instructionsPost: content?.instructionsPost,
            trainerNotes: content?.trainerNotes,
            bgImage: content?.bgImage,
            titleSection: titleSection,
            summarySection: summarySection,
            sections: List(sections),
            mediumImage: content?.mediumImage,
            medium_image: content?.mediumImage || content?.medium_image,
        });

        return {
            quizArticleId,
            howtoContent,
            resourceType,
            contentUUId,
            quizUUID,
        };
    }

    private parseInnerFromContentService(response: CSIContent) {
        const content = response.content;
        const learnObjectives = content?.title_page?.learn_objectives.objective;
        const quizUUID = response?.content?.quiz_article_uuid;

        const quizArticleId: string = content?.quiz_article_id;

        const resourceType = response?.legacy?.contentType;

        const titleSlide = new TitleSlideContent({
            objectives: learnObjectives ? List(learnObjectives) : undefined,
            studyTime: content?.title_page?.study_time,
            levels: content?.levels,
            bgImage: content?.title_page?.background_image,
            resourceType: parseDtoToResourceType(resourceType),
        });

        const titleSection = new Section({
            instanceId: '0',
            title: content?.title_page?.value,
            slides: List([titleSlide]),
        });

        const contentBlock = (content?.block as any[]) || [];

        const blocks =
            contentBlock?.map<Section>((x) => {
                const slides = (x.block_title.slide as any[]).map((y) => {
                    const sets = (y.sets as CSIBlockSets[]).map((s) => {
                        return new GenericSlideSet({
                            audio: s.audio,
                            content: s.content,
                            image: s.image,
                            video: s.video,
                            videoSubbed: s.video_subbed,
                        });
                    });
                    const dialogueLines = (
                        y.dialogue_line as CSIBlockDialogueLine[]
                    ).map((d) => {
                        return new DialogueLine({
                            thumbnail: d.thumbnail,
                            audioTime: d.audio_time,
                            content: d.text,
                        });
                    });

                    return new GenericSlideContent({
                        audioMain: y.audio_main,
                        mainImageUrl: y.main_image_url,
                        template: y.slide_template,
                        alignment: y.alignment,
                        mainVideoUrl: y.main_video_url,
                        mainVideoUrlSubbed: y.main_video_url_subbed,
                        intro: y.intro,
                        size: y.size,
                        sets: List(sets),
                        dialogueLine: List(dialogueLines),
                    });
                });
                return new Section({
                    instanceId: x.value.toString(),
                    title: x.block_title.value,
                    slides: List(slides),
                });
            }) || [];

        const summarySlide = new SummarySlideContent({
            body: content?.summary,
        });

        const summarySection = new Section({
            instanceId: (blocks.length + 1).toString(),
            title: 'Summary',
            slides: List([summarySlide]),
        });

        const sections = [titleSection, ...blocks, summarySection];

        const howtoContent = new HowtoContent({
            instructionsPost: content?.instructionsPost,
            trainerNotes: content?.trainerNotes,
            bgImage: content?.bgImage,
            titleSection: titleSection,
            summarySection: summarySection,
            sections: List(sections),
            mediumImage: content?.mediumImage,
            medium_image: content?.mediumImage || content?.medium_image,
        });

        return {
            quizArticleId,
            howtoContent,
            resourceType,
            contentUUId: response.id,
            quizUUID,
        };
    }

    parseContent(response: CSIContent, resource: any) {
        const parsedResponse = this.parseInnerContent(response);
        const usedTemplate = response?.template;

        return new HowtoDescription({
            contentMetadata: response?.contentMetadata ?? null,
            resourceId: resource.resourceId,
            groupId: resource.groupId,
            title: parsedResponse.howtoContent.titleSection?.title,
            tags: List(response?.content?.levels),
            quizArticleId: parsedResponse.quizArticleId,
            content: parsedResponse.howtoContent,
            type: parseDtoToResourceType(parsedResponse.resourceType),
            contentUUId: parsedResponse.contentUUId,
            quizUUID: parsedResponse.quizUUID,
            template: usedTemplate ? usedTemplate : 'nrt',
            topicUUID: response?.contentMetadata?.topic,
        });
    }

    parseFromContentService(response: CSIContent) {
        const parsedResponse = this.parseInnerFromContentService(response);
        const usedTemplate = response?.template;

        return new HowtoDescription({
            contentMetadata: response?.contentMetadata ?? null,
            resourceId: response?.legacy.articleId,
            groupId: response?.legacy.groupId,
            title: parsedResponse.howtoContent.titleSection?.title,
            tags: List(response?.content?.levels),
            quizArticleId: parsedResponse.quizArticleId,
            content: parsedResponse.howtoContent,
            type: parseDtoToResourceType(parsedResponse.resourceType),
            contentUUId: parsedResponse.contentUUId,
            quizUUID: parsedResponse.quizUUID,
            template: usedTemplate ? usedTemplate : 'nrt',
            topicUUID: response?.contentMetadata?.topic,
        });
    }
}
