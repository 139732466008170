import { Observable } from 'rxjs';
import { ajax, AjaxResponse } from 'rxjs/ajax';
import { map } from 'rxjs/operators';
import { apiv100, user } from './api-urls';
import {
    AICCSSOValidatorEvent,
    UserCustomFieldEvent,
} from './integrations/integrations-event';
import {
    IntegrationActivityTrackingSettings,
    IntegProvisioningActivityTrackingSettings,
    ProvisioningDto,
    UserCustomFields,
    ProcessStatusEnum,
} from './integrations/integrations-settings';

export interface IIntegrationApi {
    SSOLearnerValidate(
        event: AICCSSOValidatorEvent
    ): Observable<IntegrationActivityTrackingSettings>;

    SSOProvisioning(
        event: AICCSSOValidatorEvent
    ): Observable<IntegProvisioningActivityTrackingSettings>;

    UserCustomField(event: UserCustomFieldEvent): Observable<UserCustomFields>;
}

export class IntegrationRequireApi implements IIntegrationApi {
    SSOLearnerValidate(
        event: AICCSSOValidatorEvent
    ): Observable<IntegrationActivityTrackingSettings> {
        const {
            clientUrl,
            sessionId,
            learnerUUID,
            learnerExternalId,
            providerId,
        } = event;

        return ajax
            .getJSON<any>(
                `${apiv100()}sso-validator/${learnerUUID}/learner-validate?clientUrl=${clientUrl}&providerId=${providerId}&sessionId=${sessionId}&userExternalId=${learnerExternalId}`,

                {
                    'Content-Type': 'application/json',
                }
            )
            .pipe(
                map((response) => {
                    if (response) {
                        return {
                            status: response.status,
                        };
                    } else {
                        return {
                            status: ProcessStatusEnum.AccessDenied,
                        };
                    }
                })
            );
    }

    SSOProvisioning(
        event: AICCSSOValidatorEvent
    ): Observable<IntegProvisioningActivityTrackingSettings> {
        const payload = {
            userExternalId: event.learnerExternalId,
            providerId: event.providerId,
            sessionId: event.sessionId,
            clientUrl: event.clientUrl,
            clientId: event.providerId,
        };

        return ajax
            .post<any>(
                `${apiv100()}sso-validator/${event.learnerUUID}/provisioning`,
                payload,
                {
                    'Content-Type': 'application/json',
                }
            )
            .pipe(
                map((data: AjaxResponse<ProvisioningDto>) => {
                    const { processSteps } = data.response;
                    const stepsParamStatus = processSteps.find(
                        (step) => step.processId === 'aicc-get-param'
                    );
                    if (data.response && stepsParamStatus) {
                        return {
                            AICCParamSteps: stepsParamStatus,
                        };
                    } else {
                        return {
                            AICCParamSteps: {
                                processId: '',
                                processStatus:
                                    'AccessDenied' as ProcessStatusEnum,
                            },
                        };
                    }
                })
            );
    }

    UserCustomField(event: UserCustomFieldEvent): Observable<UserCustomFields> {
        const { learnerUUID } = event;

        return ajax
            .getJSON<any>(`${user()}/${learnerUUID}/customfield`, {
                'Content-Type': 'application/json',
            })
            .pipe(
                map((response) => {
                    if (response && response.length > 0) {
                        const getCustom = response.find(
                            (x: any) => x.column === 'Cust_Report_field1'
                        );
                        if (getCustom) {
                            return {
                                userGUID: getCustom.value,
                            };
                        } else {
                            return {
                                userGUID: null,
                            };
                        }
                    } else {
                        return {
                            userGUID: null,
                        };
                    }
                })
            );
    }
}
