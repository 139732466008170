import { Record } from 'immutable';
import moment, { Moment } from 'moment';
import { EwritingState } from './ewriting-type';
import { TimeLimitRule } from '../../contracts/time-limit-rule';

interface EwritingTeacherProps {
    firstName: string;
    lastName: string;
    teacherUUID: string;
    photo: string;
}

const defaultEwritingTeacher: EwritingTeacherProps = {
    firstName: '',
    lastName: '',
    teacherUUID: '',
    photo: '',
};

export class EwritingTeacher
    extends Record(defaultEwritingTeacher)
    implements EwritingTeacherProps {}

interface EwritingLearnerProps {
    name: string;
    learnerUUID: string;
    gender: string;
    photo: string;
}

const defaultEwritingLearner: EwritingLearnerProps = {
    name: '',
    learnerUUID: '',
    gender: '',
    photo: '',
};

export class EwritingLearner
    extends Record(defaultEwritingLearner)
    implements EwritingLearnerProps {}

export interface EwritingProps {
    assignDateTime: Moment;
    completeDateTime: Moment;
    submitDateTime: Moment;
    learner: EwritingLearner;
    trainer: EwritingTeacher;
    id: string;
    homeworkActivity: string;
    learnerTextHtml: string;
    state: EwritingState;
    subject: string;
    subjectActivity: string;
    topic: string;
    trainerNoteHtml: string;
    trainerTextHtml: string;
}

const defaultEwritingProps: EwritingProps = {
    assignDateTime: moment().startOf('day'),
    completeDateTime: moment().startOf('day'),
    submitDateTime: moment().startOf('day'),
    trainer: new EwritingTeacher(),
    learner: new EwritingLearner(),
    id: '',
    homeworkActivity: '',
    learnerTextHtml: '',
    state: EwritingState.AssignedDraft,
    subject: '',
    subjectActivity: '',
    topic: '',
    trainerNoteHtml: '',
    trainerTextHtml: '',
};

export class Ewriting
    extends Record(defaultEwritingProps)
    implements EwritingProps {}

interface EwritingListItemProps {
    assignDateTime: Moment;
    completeDateTime: Moment;
    submitDateTime: Moment;
    teacher: EwritingTeacher;
    learner: EwritingLearner;
    ewritingUUID: string;
    state: EwritingState;
    homeworkActivity: string;
    learnerTextHtml: string;
    trainerNoteHtml: string;
    trainerTextHtml: string;
    subject: string;
    topic: string;
    languageName: string;
    aiText: string;
}

const defaultEwritingListItemProps: EwritingListItemProps = {
    assignDateTime: moment().startOf('day'),
    completeDateTime: moment().startOf('day'),
    submitDateTime: moment().startOf('day'),
    teacher: new EwritingTeacher(),
    learner: new EwritingLearner(),
    state: EwritingState.AssignedDraft,
    ewritingUUID: '',
    homeworkActivity: '',
    learnerTextHtml: '',
    trainerNoteHtml: '',
    trainerTextHtml: '',
    subject: '',
    topic: '',
    languageName: '',
    aiText: '',
};

export class EwritingListItem
    extends Record(defaultEwritingListItemProps)
    implements EwritingListItemProps {}

interface EWritingLimitationProps {
    postLimitTimeRule: TimeLimitRule | undefined;
    postLimitValue: number | undefined;
}

const defaultEwritingLimitationProps: EWritingLimitationProps = {
    postLimitTimeRule: TimeLimitRule.None,
    postLimitValue: 0,
};

export class EWritingLimitation
    extends Record(defaultEwritingLimitationProps)
    implements EWritingLimitationProps {}
