import { RootEpic } from '../root-epic';
import { catchError, filter, map, switchMap } from 'rxjs/operators';
import { isActionOf } from 'typesafe-actions';
import { Action } from '../../store/root-action';
import { of } from 'rxjs';

export const addFileLinkEpic: RootEpic = (action$, state$, { vcrFetchAPI }) =>
    action$.pipe(
        filter(isActionOf(Action.fetchMessageLink.vcrFetchMessage.request)),
        switchMap((x) => {
            return vcrFetchAPI
                .loadChatMessage(
                    x.payload.label as string,
                    x.payload.hash as string,
                    x.payload.classId as string,
                    x.payload.chatId as number
                )
                .pipe(
                    map((x) => {
                        return Action.fetchMessageLink.vcrFetchMessage.success(
                            x
                        );
                    }),
                    catchError((e) =>
                        of(Action.fetchMessageLink.vcrFetchMessage.failure(e))
                    )
                );
        })
    );
export const FetchMessageEpics = [addFileLinkEpic];
