import { emptySplitApi } from '../../rtk/empty-split-api';
import { routes } from '../../rtk/routes';
import {
    GetPageOptionsRequest,
    GetRequiredSurveyStateResponse,
    PerformanceSurveySaveDto,
    SurveyOptionsDto,
    SurveyOptionsRequest,
    SurveyOptionsResponse,
    SurveySkipRequest,
    SurveySubmitRequest,
    SurveyTrainer,
} from './types';

export const surveysApi = emptySplitApi.injectEndpoints({
    endpoints: (builder) => ({
        getRequiredSurveys: builder.query<
            GetRequiredSurveyStateResponse,
            string
        >({
            query: routes.api.survey.surveyState,
        }),
        getSurveysOptions: builder.query<
            SurveyOptionsResponse,
            SurveyOptionsRequest
        >({
            query: (payload: SurveyOptionsRequest) => {
                const { surveyType, businessType, userUuid } = payload;
                return routes.api.survey.options(
                    surveyType,
                    businessType,
                    userUuid
                );
            },
        }),
        saveSurvey: builder.mutation<
            void,
            SurveySubmitRequest | PerformanceSurveySaveDto
        >({
            query: (
                payload: SurveySubmitRequest | PerformanceSurveySaveDto
            ) => ({
                url: routes.api.survey.save(payload.surveyType),
                method: 'POST',
                body: payload,
            }),
        }),
        skipSurvey: builder.mutation<void, SurveySkipRequest>({
            query: (payload: SurveySkipRequest) => ({
                url: routes.api.survey.skip(
                    payload.businessType,
                    payload.userUuid
                ),
                method: 'PUT',
            }),
        }),
        getTrainer: builder.query<SurveyTrainer, string>({
            query: routes.api.survey.getTrainer,
        }),
        getPageOptions: builder.query<
            SurveyOptionsDto[],
            GetPageOptionsRequest
        >({
            query: (payload: GetPageOptionsRequest) => {
                const { surveyType, businessType } = payload;
                return routes.api.survey.pageOptions(surveyType, businessType);
            },
        }),
    }),
});

export const {
    useGetRequiredSurveysQuery,
    useGetSurveysOptionsQuery,
    useSaveSurveyMutation,
    useSkipSurveyMutation,
    useGetTrainerQuery,
    useGetPageOptionsQuery,
} = surveysApi;
