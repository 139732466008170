import { Record, List } from 'immutable';
import { SlideContent } from './section';

interface DialogueLineProps {
    thumbnail: string | null;
    audioTime: number;
    content: string;
}

export class DialogueLine extends Record({
    thumbnail: null,
    audioTime: 0,
    content: '',
} as DialogueLineProps) {}

interface GenericSlideSetProps {
    image: string | null;
    audio: string | null;
    video: string | null;
    videoSubbed: string | null;
    content: string;
}

export class GenericSlideSet extends Record({
    audio: null,
    image: null,
    video: null,
    videoSubbed: null,
    content: '',
} as GenericSlideSetProps) {}

interface GenericSlideContentProps extends SlideContent {
    audioMain: string | null;
    alignment: string | null;
    size: string | null;
    mainImageUrl: string | null;
    mainVideoUrl: string | null;
    intro: string | null;
    mainVideoUrlSubbed: string | null;
    body: string | null;
    dialogueLine: List<DialogueLine>;
    sets: List<GenericSlideSet>;
}

export class GenericSlideContent extends Record({
    template: '',
    alignment: null,
    size: null,
    audioMain: null,
    dialogueLine: List(),
    mainImageUrl: null,
    mainVideoUrl: null,
    mainVideoUrlSubbed: null,
    body: null,
    intro: null,
    sets: List(),
} as GenericSlideContentProps) {}

export function isGenericSlide(
    slide: SlideContent
): slide is GenericSlideContent {
    return slide instanceof GenericSlideContent;
}
