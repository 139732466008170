import { Record } from 'immutable';

export type TestStatus = 'New' | 'Incomplete' | 'Complete' | '';

export interface TestScoreProps {
    pedagogicName: string;
    pedagogicScore: number;
}

// Common data for test
export interface TestProps {
    // test id
    id: string;
    // created date
    createdDate: string;
    // completion date
    completionDate: string;
    // test status
    status: TestStatus;
    // number of questions
    questionPoolSize: number;
    // test results
    evaluationResult: {
        // test duration
        duration: number;
        // language level
        level: number;
        // scores on specific pedagogic
        pedagogicScores: TestScoreProps[];
        goCefrLevel: string;
        // language certificate
        cefrLevel: string;
    };
}

const defaultTestProps: TestProps = {
    id: '',
    status: '',
    questionPoolSize: 32,
    createdDate: '2023-04-12T08:09:12.876',
    completionDate: '2023-04-12T12:49:25.075',
    evaluationResult: {
        duration: 0,
        level: 0,
        pedagogicScores: [],
        goCefrLevel: 'A1.1',
        cefrLevel: 'Pre-A1',
    },
};

export class TestRecord extends Record(defaultTestProps) implements TestProps {}

// Question template
// to - Text Only
// wv - With Video
// wa - With Audio
// wc - With Article
// wp - With Picture
export type TestQuestionTemplate = 'to' | 'wv' | 'wa' | 'wc' | 'wp';

// Options type
// oT - Text repeatable
// oTM - Text (Multiple)
// oP - Picture
// oPM - Picture (Multiple)
export type TestQuestionOptionsType = 'oT' | 'oTM' | 'oP' | 'oPM';

// Question type
// mc - Multi Choice
// fg - Fill-in-the-Gap
export type TestQuestionType = 'mc' | 'fg';

// Question data for test
export interface TestQuestionProps {
    // question id
    id: string;
    // question instruction
    instruction: string;
    // question content
    question: string;
    // question type
    questionType: TestQuestionType;
    // additional content article
    article?: string;
    // additional media content
    media: string;
    // additional media content for video
    mediaMobile: string;
    // template for additional content
    template: TestQuestionTemplate;
    // options type
    optionsType: TestQuestionOptionsType;
    // options
    options: {
        value: string;
        text: string;
        media?: string;
    }[];
    // current question number
    questionNumber: number;
    // start time of timer
    timer: number | null;
}

const defaultTestQuestionProps: TestQuestionProps = {
    id: '',
    instruction: '',
    question: '',
    questionType: 'mc',
    article: '',
    media: '',
    mediaMobile: '',
    template: 'to',
    optionsType: 'oT',
    options: [
        {
            value: '',
            text: '',
            media: '',
        },
    ],
    questionNumber: 1,
    timer: 30,
};

export class TestQuestionRecord
    extends Record(defaultTestQuestionProps)
    implements TestQuestionProps {}

// Answer data for test
export type TestAnswerSelection = string[];
