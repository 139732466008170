import { SlideContent } from './section';
import { Record } from 'immutable';

const summarySlideTemplate = 'summary-slide';

interface SummarySlideProps extends SlideContent {
    template: typeof summarySlideTemplate;
    body: string;
}

const summarySlidePropsDefaults: SummarySlideProps = {
    template: summarySlideTemplate,
    body: '',
};

export class SummarySlideContent extends Record(summarySlidePropsDefaults) {}

export function isSummarySlide(
    slide: SlideContent
): slide is SummarySlideContent {
    return slide.template === summarySlideTemplate;
}
