import { createAction, createAsyncAction } from 'typesafe-actions';
import { ChatMessage, ChatUser } from './chat-data';

const addMessage = createAction('@@classroom/chat/add-message')<ChatMessage>();
const clearMessages = createAction('@@classroom/chat/clear-messages')();

const loadUsers = createAsyncAction(
    '@@classroom/chat/get-users/request',
    '@@classroom/chat/get-users/success',
    '@@classroom/chat/get-users/failure'
)<number, ChatUser[], Error>();

const loadUser = createAsyncAction(
    '@@classroom/chat/get-user/request',
    '@@classroom/chat/get-user/success',
    '@@classroom/chat/get-user/failure'
)<number, ChatUser, Error>();

const addUser = createAction('@@classroom/chat/add-user')<ChatUser>();

const chatActions = {
    addMessage,
    clearMessages,
    loadUsers,
    loadUser,
    addUser,
};
export default chatActions;
