import { isEmpty } from 'lodash';
import { GradingGoCEFR } from '../store/grading/grading-dto';
import { ProficiencyProps } from '../store/resources/resources-content-record';

export const leveling: { [key: string]: string } = {
    '1': 'A1',
    '2': 'A2',
    '3': 'B1',
    '4': 'B2',
    '5': 'C1',
    '6': 'C2',
    A1: 'A1',
    A2: 'A2',
    B1: 'B1',
    B2: 'B2',
    C1: 'C1',
    C2: 'C2',
};

export const legacyLeveling: { [key: string]: string } = {
    A1: 'level 1',
    A2: 'level 2',
    B1: 'level 3',
    B2: 'level 4',
    C1: 'level 5',
    C2: 'level 6',
    'no level': 'no level',
};

const convertLevelTags = (levelTags: string[] | number[]) => {
    let levelTagsConverter: string[] = [];

    levelTags.forEach((item: string | number) => {
        let lItem = item.toString().toLowerCase().replace(/\s/g, '');
        if (lItem.search('level') > -1) {
            lItem = lItem.replace('level', '');
        }
        if (leveling[lItem.toUpperCase()]) {
            levelTagsConverter.push(leveling[lItem.toUpperCase()]);
        }
    });

    levelTagsConverter = levelTagsConverter
        .sort((a, b) => {
            if (a > b) {
                return 1;
            }
            if (b > a) {
                return -1;
            }
            return 0;
        })
        .filter((n) => n);

    return levelTagsConverter;
};

export const levelLegacyToNewLeveling = (levelTags: any[]) => {
    if (!levelTags || (levelTags && isEmpty(levelTags))) return null;
    const levelTagsConverter = convertLevelTags(levelTags);
    const levelTagsLast = levelTagsConverter.length - 1;

    if (isEmpty(levelTagsConverter)) return null;
    if (levelTagsConverter[0] === levelTagsConverter[levelTagsLast]) {
        return `${levelTagsConverter[0]}`;
    }
    return `${levelTagsConverter[0]}-${levelTagsConverter[levelTagsLast]}`;
};

export const newLeveling = (
    goCEFR: GradingGoCEFR[],
    proficiencyData?: ProficiencyProps | null
) => {
    if (!proficiencyData) return null;
    const levels = goCEFR
        .sort((a, b) => a.level - b.level)
        .filter(
            (levels) =>
                !levels.intermediateLevel &&
                (levels.minProficiency === proficiencyData?.minProficiency ||
                    levels.maxProficiency === proficiencyData?.maxProficiency)
        );

    return levels.length === 1
        ? `${levels[0].name}`
        : levels.length === 2 && levels[0].name === levels[1].name
        ? `${levels[0].name}`
        : levels.length === 2
        ? `${levels[0].name}-${levels[1].name}`
        : levels.length > 2 && levels[0].name === levels[levels.length - 1].name
        ? `${levels[0].name}`
        : levels.length > 2
        ? `${levels[0].name}-${levels[levels.length - 1].name}`
        : null;
};

export const convertToLegacyLevelName = (
    filterLevels?: (string | string[])[]
): string[] | null => {
    if (!filterLevels || (filterLevels && isEmpty(filterLevels))) return null;
    let legacy: string[] = [];
    filterLevels.forEach((item) => {
        // @ts-ignore
        if (item && !Array.isArray(item) && legacyLeveling[item]) {
            // @ts-ignore
            legacy.push(legacyLeveling[item]);
            legacy.push(item);
        }
        if (item && Array.isArray(item) && !isEmpty(item)) {
            item.forEach((subItem) => {
                // @ts-ignore
                if (legacyLeveling[subItem]) {
                    // @ts-ignore
                    legacy.push(legacyLeveling[subItem]);
                    legacy.push(subItem);
                }
            });
        }
    });
    return legacy;
};

export const convertProficiencyToArray = (
    goCEFR: GradingGoCEFR[],
    proficiency: ProficiencyProps | null
) => {
    if (!proficiency) return [];
    return goCEFR
        .sort((a, b) => a.level - b.level)
        .filter(
            (levels) =>
                !levels.intermediateLevel &&
                (levels.minProficiency === proficiency?.minProficiency ||
                    levels.maxProficiency === proficiency?.maxProficiency)
        )
        .map((item) => item.name);
};

export const convertLegacyTagEntriesToProficiencyArray = (tagEntries: any) => {
    if (!tagEntries || (tagEntries && isEmpty(tagEntries))) return null;
    if (tagEntries[0].name) {
        tagEntries = tagEntries.map((tag: any) => tag.name);
    }

    const levelTagsConverter = convertLevelTags(tagEntries);
    const levelTagsLast = levelTagsConverter.length - 1;

    if (isEmpty(levelTagsConverter)) return [];
    if (levelTagsConverter[0] === levelTagsConverter[levelTagsLast]) {
        return [levelTagsConverter[0]];
    }
    return levelTagsConverter;
};
