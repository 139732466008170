import { Observable, of, empty } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { Axios } from 'axios-observable';
import {
    QuizAttemptDescription,
    QuizDescription,
    QuizIdentifier,
} from './quizzes/quiz';
import { ApiHeaders } from './api-headers';
import { logger } from '../logging';
import { isImmutable } from 'immutable';
import { mobileRest } from './api-urls';
import { environment } from '../environments';

export class QuizzesApi {
    apiHeaders: ApiHeaders;

    constructor(apiHeaders: ApiHeaders) {
        this.apiHeaders = apiHeaders;
    }

    /**
     * Load immutable quiz info from the server
     * @param quizIdentifier quiz identifier with additional info on learner
     */
    loadQuizDescription(
        quizIdentifier: QuizIdentifier
    ): Observable<QuizDescription> {
        let id: QuizIdentifier = quizIdentifier;
        return Axios.post(mobileRest() + 'ws/quiz', quizIdentifier, {
            headers: this.apiHeaders.getHeaders({
                'Content-Type': 'application/json',
            }),
        })
            .pipe(map((response) => response.data))
            .pipe(
                map((x) => {
                    return new QuizDescription({ quizDto: x, quizId: id });
                }),
                catchError((x) => {
                    return of(x);
                })
            );
    }

    parseProgress({
        quizIdentifier,
        attempt,
    }: {
        quizIdentifier: QuizIdentifier;
        attempt: QuizAttemptDescription;
    }) {
        let progress = {
            articleId: quizIdentifier.articleId,
            groupId: quizIdentifier.groupId,
            interactions: attempt.suppliedAnswers.map((ans) => {
                let answer = ans.answer;

                if (isImmutable(answer)) {
                    answer = answer.toJS();
                }
                return {
                    t: '00:00:00',
                    q: ans.ord,
                    s: ans.ord,
                    a: ans.answer,
                };
            }),
            learnerId: quizIdentifier.learnerId,
            qArticleId: quizIdentifier.qArticleId,
            qResultId: attempt.qri,
            rawScore: attempt.correctAnswers.filter((v) => v).size,
            sequence: attempt.suppliedAnswers.map((ans) => ans.ord),
            totalItems: attempt.questionsCount,
            trackable: 'true',
        };

        return progress;
    }
    /**
     * Save current attempt to server and get Quiz Result Identifier.
     * @param quizIdentifier quiz identifier with additional info on learner
     * @param attempt attempt to be saved
     */
    saveQuizProgress(
        quizIdentifier: QuizIdentifier,
        attempt: QuizAttemptDescription
    ) {
        const progress = this.parseProgress({ quizIdentifier, attempt });
        return Axios.post(mobileRest() + 'ws/quiz/save', progress, {
            headers: this.apiHeaders.getHeaders({
                'Content-Type': 'application/json',
            }),
        }).pipe(
            map((x) => x.data.qri),
            catchError((x) => {
                logger.error(x);
                return of(0);
            })
        );
    }

    /**
     * Notify outer LMS on finishing the quiz.
     */
    callProgressApi() {
        const csodToken = localStorage.getItem('csodAuthorization');
        const userGuid = localStorage.getItem('userGuid'); // ToDo: send to activity tracking on open -> attributes 'UserGUID'
        const courseId = localStorage.getItem('courseId'); // ToDo: send to activity tracking on open -> attributes 'ArticleId' , already have
        const sessionToken = localStorage.getItem('sessionToken'); // ToDo: send to activity tracking on open -> attributes 'SessionToken'
        const subDomain = localStorage.getItem('subdomain'); // ToDo: send to activity tracking on open -> attributes 'SubDomain'
        const callbackUrl = localStorage.getItem('callbackURL'); // ToDo: send to activity tracking on open -> attributes 'CallBackUrl'

        if (
            csodToken &&
            userGuid &&
            courseId &&
            sessionToken &&
            subDomain &&
            callbackUrl
        ) {
            let data = {
                callBackUrl: decodeURIComponent(callbackUrl),
                progresses: [
                    {
                        userGuid: userGuid,
                        courseId: courseId,
                        status: 'Completed',
                    },
                ],
                sessionToken: sessionToken,
                subdomain: subDomain,
            };

            // console.log('call progress api');
            const url = environment.PROGRESSAPI_HOST + '/api/progress';
            return Axios.post(url, data, {
                headers: this.apiHeaders.getHeaders({
                    'Content-Type': 'application/json',
                    Authorization: csodToken,
                }),
            }).pipe(map((response) => response.data));
        }
        return empty();
    }
}
