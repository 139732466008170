import { RootState } from 'typesafe-actions';
import { createSelector } from 'reselect';

/** Basic selector for all quiz-related activities */
export const quizzesSelector = (state: RootState) => state.quizzes;

export const currentQuizId = (state: RootState) => state.quizzes.quizId;

export const currentQuizAttempt = (state: RootState) =>
    state.quizzes.quizAttempt;

export const quizAttemptHistory = (state: RootState) =>
    state.quizzes.quiz?.attempts;

export const currentQuizSelector = createSelector(
    [quizzesSelector],
    (quizzes) => quizzes.quiz
);

export const errorSelector = createSelector(
    [quizzesSelector],
    (quizzes) => quizzes.error
);

export const currentQuestion = createSelector(
    [currentQuizSelector, currentQuizAttempt],
    (quiz, attempt) => {
        if (quiz) {
            return quiz.questions[attempt.currentQuestionNumber - 1];
        } else {
            return null;
        }
    }
);

export const hasNextQuestion = createSelector(
    [currentQuizSelector, currentQuizAttempt],
    (quiz, attempt) => {
        // we number question in natural order: 1 is the first question
        // quiz.questions is an array, so its numbering starts with 0.
        return quiz && quiz.questions.length >= attempt.currentQuestionNumber;
    }
);

export const isFillingIn = createSelector(
    [currentQuizAttempt],
    (attempt) => attempt.currentMode === 'filling-in'
);

export const isCurrentQuestionCorrect = createSelector(
    currentQuizAttempt,
    (attempt) => {
        let index = attempt.currentQuestionNumber;
        let isCorrect = attempt.correctAnswers.get(index, undefined);
        return isCorrect;
    }
);
