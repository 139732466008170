import log from 'loglevel';
import prefix from 'loglevel-plugin-prefix';
import { logger } from './index';

export default function configureLogger() {
    log.enableAll();
    prefix.reg(log);
    prefix.apply(log);
    logger.configure();
}
