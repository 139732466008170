import moment from 'moment/moment';

interface IUpdateDatesInSurveysLoc {
    subTitle?: string;
    startDateTime?: string;
}

export const updateDatesInSurveysLoc = ({
    subTitle,
    startDateTime,
}: IUpdateDatesInSurveysLoc) => {
    if (!subTitle || !startDateTime) return null;

    const dayOfWeek = moment(startDateTime).format('dddd');
    const shortDate = moment(startDateTime).format('DD MMM');

    return subTitle
        .replace('{lesson_date & month}', shortDate)
        .replace('{ConversationClass_lesson_date & month}', shortDate)
        .replace('{individual_lesson_date & month}', shortDate)
        .replace('{lesson_day}', dayOfWeek)
        .replace('{ConversationClass_lesson_day}', dayOfWeek)
        .replace('{individual_lesson_day}', dayOfWeek);
};
