import moment from 'moment';
import { EMPTY, of } from 'rxjs';
import {
    distinctUntilChanged,
    filter,
    map,
    mergeMap,
    switchMap,
    withLatestFrom,
} from 'rxjs/operators';
import { Action } from '../store/root-action';
import { RootEpic } from './root-epic';

const onAppWindowWidthChange: RootEpic = (_, state$) =>
    state$.pipe(
        map((x) => x.app.currentAppWidth),
        distinctUntilChanged(),
        switchMap((x) => {
            if (x < 960) {
                //ToDo: move this service
                return of(Action.app.changeAppMobileState(true));
            } else {
                return of(Action.app.changeAppMobileState(false));
            }
        })
    );

const allActionsEpic: RootEpic = (action$, state$) => {
    return action$.pipe(
        filter((action) => action.type.includes('/request')), // Filter actions containing '/request' in their type
        withLatestFrom(state$), // Combine the action stream with the latest state values
        mergeMap(([action, state]) => {
            // Handle the action here
            // Access the latest state using the 'state' variable
            const tokenRenewRequest = action.type.includes('/token-renew');
            const userIdentity = state.user.identity;
            const restartToken = state.user.restartToken;
            const stateExpiredToken = state.user.isExpiredToken;
            if (userIdentity?.jwt && !restartToken && !stateExpiredToken) {
                const renewDateToken = userIdentity.jwt.renewDateTime;
                const expiryInMinutes = userIdentity.jwt.expiryInMinutes;
                const expiryDate = userIdentity.jwt.expiryDate;

                if (expiryInMinutes > 0) {
                    const currentTime = moment();
                    const isExpiredSession =
                        currentTime.isSameOrAfter(expiryDate);

                    const expectedRenewToken = moment(renewDateToken);
                    const differentInTime = expectedRenewToken.diff(
                        currentTime,
                        'seconds'
                    );

                    if (isExpiredSession) {
                        return of(Action.user.setIsExpiredToken(true));
                    } else {
                        if (differentInTime <= 0 && !tokenRenewRequest) {
                            return of(Action.user.setRenewToken(true));
                        }
                    }
                }
            }

            return EMPTY;
        })
    );
};

export const appEpics = [onAppWindowWidthChange, allActionsEpic];
