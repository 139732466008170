import { SsoLayoutFailure } from '@arcadia-projects/portal2-ui-library';
import { Box } from '@mui/material';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { localization } from '../../../localization';

export const SSOErrorLoginPage: React.FC = () => {
    const history = useHistory();

    return (
        <Box
            sx={{
                '& > div > div ~ div': {
                    margin: `24px`,
                },
            }}
        >
            <SsoLayoutFailure
                title={localization.sso_failed_title}
                text={localization.sso_failed_text}
                textBtn={localization.sso_failed_button}
                onClickBtn={() => history.push('/login')}
            />
        </Box>
    );
};
