import React, { useState, useEffect, useCallback } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import * as dd from 'dingtalk-jsapi';
import axios, { AxiosResponse } from 'axios';
import { Button } from '@arcadia-projects/portal2-ui-library';
import { useAppDispatch } from '../../components/root/app-dispatch';
import Action from '../../store/root-action';
import { JWTDto } from '../../services/authentication-api';
import { useSelector } from 'react-redux';
import { isLoginDingTalkSelector } from '../../store/user/user-selectors';
import styles from './styles.module.css';

const PRODUCT = 'language-academy';

const DingAuthPage = () => {
    const search = useLocation().search;
    const corpId = new URLSearchParams(search).get('corpId');
    const clientId = new URLSearchParams(search).get('client');
    const history = useHistory();
    const dispatch = useAppDispatch();
    const isLoginDingTalk = useSelector(isLoginDingTalkSelector);
    const [state, setState] = useState(0);
    const [stateInfo, setSateInfo] = useState('Please wait...');

    const fetchUserInfo = useCallback(
        (code: string) => {
            axios
                .post<void, AxiosResponse<JWTDto>>(
                    `/api/v1.0.0/sso-validator/ding/${clientId}/${PRODUCT}/${code}`
                )
                .then((response) => {
                    console.log('response', response.data);
                    setSateInfo('User created, opening goFLUENT...');
                    dispatch(Action.user.loginDingTalk.request(response.data));
                })
                .catch((error) => {
                    setSateInfo(`User create error: ${error.message}`);
                    setState(1);
                });
        },
        [dispatch, clientId]
    );

    const onClick = useCallback(
        (corpId: string) => {
            setState(0);
            setSateInfo('Initializing...');
            try {
                dd.ready(function () {
                    setSateInfo('Request code...');
                    dd.runtime.permission
                        .requestAuthCode({
                            corpId: corpId,
                        })
                        .then((result) => {
                            setSateInfo('Creating goFLUENT account...');
                            fetchUserInfo(result.code);
                        })
                        .catch((reason) => {
                            setSateInfo('Request code is fail: ' + reason);
                            setState(1);
                        });
                });
            } catch (e: any) {
                setState(1);
                console.log('REAL REASON', e);
                setSateInfo('Request failed');
            }
        },
        [fetchUserInfo]
    );

    useEffect(() => {
        if (isLoginDingTalk && isLoginDingTalk?.jwt?.token?.length > 0) {
            dispatch(Action.user.setDingTalkUser(isLoginDingTalk));
            history.push(`/dashboard`);
        } else if (corpId) {
            onClick(corpId);
        }
    }, [corpId, history, onClick, dispatch, isLoginDingTalk]);

    return (
        <div className={styles.dingPage}>
            <header className={styles.header}>
                {corpId ? (
                    <React.Fragment>
                        <div>{stateInfo}</div>
                        {state === 1 && (
                            <Button
                                variant={'contained'}
                                className={styles.dingButton}
                                onClick={() => onClick(corpId)}
                            >
                                Try again
                            </Button>
                        )}
                    </React.Fragment>
                ) : (
                    <div>
                        Something went wrong on DingTalk app side. Please try
                        again later.
                    </div>
                )}
            </header>
        </div>
    );
};

export default DingAuthPage;
