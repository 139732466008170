import { v4 as uuidv4, v5 as uuidv5, validate as isUuid } from 'uuid';
import { THEMES } from './constants';
import { environment } from '../environments';
import moment from 'moment';

export const windowWithPossiblyCordova: any = window;

export const isCordova =
    typeof windowWithPossiblyCordova.cordova !== 'undefined' ||
    window.location.href.startsWith('file://');

export const changeStatusBarTheme = (theme: THEMES) => {
    windowWithPossiblyCordova.StatusBar.backgroundColorByHexString(theme);
    if (theme === THEMES.DARK) {
        windowWithPossiblyCordova.StatusBar.styleLightContent();
    } else {
        windowWithPossiblyCordova.StatusBar.styleDarkContent();
    }
};

export const initializeStatusBar = (theme: THEMES) => {
    if (checkPlatform('Android')) {
        windowWithPossiblyCordova.AndroidFullScreen.setSystemUiVisibility(
            windowWithPossiblyCordova.AndroidFullScreen
                .SYSTEM_UI_FLAG_IMMERSIVE |
                windowWithPossiblyCordova.AndroidFullScreen
                    .SYSTEM_UI_FLAG_HIDE_NAVIGATION
        );
    }

    changeStatusBarTheme(theme);
};

export const openFullScreenMode = () => {
    windowWithPossiblyCordova.StatusBar.hide();
    if (checkPlatform('Android')) {
        windowWithPossiblyCordova.AndroidFullScreen.immersiveMode();
    }
};

export const closeFullScreenMode = () => {
    windowWithPossiblyCordova.StatusBar.show();
    initializeStatusBar(THEMES.LIGHT);
};

export const iPhoneLockOrientation = () => {
    if (windowWithPossiblyCordova.device.model.indexOf('iPhone') !== -1) {
        windowWithPossiblyCordova.screen.orientation.lock('portrait');
    }
};

export const configureCordova = () => {
    (navigator as any).splashscreen.hide();

    initializeStatusBar(THEMES.SEMI_LIGHT);

    if (checkPlatform('iOS')) {
        iPhoneLockOrientation();
        windowWithPossiblyCordova.StatusBar.overlaysWebView(false);
    }

    if (checkPlatform('Android')) {
        if (windowWithPossiblyCordova.isTablet === false) {
            windowWithPossiblyCordova.screen.orientation.lock('portrait');
        }
    }

    if (isCordova) {
        if (environment.PRIVACY_POLICY_LANGUAGE === 'en') {
            if (checkPlatform('Android')) {
                windowWithPossiblyCordova.device.initializeDevice();
            }

            setDeviceId();
        }

        localStorage.setItem('simCountry', environment.PRIVACY_POLICY_LANGUAGE);
    }
};

export const checkPlatform = (name: string) => {
    return (
        windowWithPossiblyCordova.device &&
        windowWithPossiblyCordova.device.platform === name
    );
};

export const setDeviceId = () => {
    if (localStorage.getItem('deviceId')) {
        return null;
    }

    if (
        windowWithPossiblyCordova.device &&
        windowWithPossiblyCordova.device.uuid &&
        isUuid(windowWithPossiblyCordova.device.uuid)
    ) {
        localStorage.setItem('deviceId', windowWithPossiblyCordova.device.uuid);
    } else if (
        windowWithPossiblyCordova.device &&
        windowWithPossiblyCordova.device.uuid &&
        !isUuid(windowWithPossiblyCordova.device.uuid)
    ) {
        localStorage.setItem(
            'deviceId',
            uuidv5(
                windowWithPossiblyCordova.device.uuid,
                '01646638-c2b0-11eb-89d2-0492260bf93a'
            )
        );
    } else {
        localStorage.setItem('deviceId', uuidv4());
    }
};

export const windowOpen = ({
    link,
    settings = 'location=yes',
}: {
    link: string;
    settings?: string;
}) => {
    return windowWithPossiblyCordova.cordova.InAppBrowser.open(
        link,
        '_blank',
        settings
    );
};

export const checkIosPermissionsToMediaDevices = () => {
    // request microphone
    windowWithPossiblyCordova.cordova.plugins.diagnostic.requestMicrophoneAuthorization(
        function (status: any) {
            if (
                status ===
                windowWithPossiblyCordova.cordova.plugins.diagnostic
                    .permissionStatus.GRANTED
            ) {
                console.log('Microphone use is authorized');
            } else {
                windowWithPossiblyCordova.cordova.plugins.diagnostic.switchToSettings(
                    (success: any) => {
                        console.log('switchToSettings success', success);
                    },
                    (errors: any) => {
                        console.log('switchToSettings errors', errors);
                    }
                );
            }
        },
        function (error: any) {
            console.error(error);
        }
    );
    // request camera
    windowWithPossiblyCordova.cordova.plugins.diagnostic.requestCameraAuthorization(
        {
            successCallback: function (status: any) {
                console.log(
                    'Authorization request for camera use was ' +
                        (status ===
                        windowWithPossiblyCordova.cordova.plugins.diagnostic
                            .permissionStatus.GRANTED
                            ? 'granted'
                            : 'denied')
                );
                if (
                    status !==
                    windowWithPossiblyCordova.cordova.plugins.diagnostic
                        .permissionStatus.GRANTED
                ) {
                    windowWithPossiblyCordova.cordova.plugins.diagnostic.switchToSettings(
                        (success: any) => {
                            console.log('switchToSettings success', success);
                        },
                        (errors: any) => {
                            console.log('switchToSettings errors', errors);
                        }
                    );
                }
            },
            errorCallback: function (error: any) {
                console.error(error);
            },
            externalStorage: false,
        }
    );
};

export const checkMicrophone = () => {
    const permissions = windowWithPossiblyCordova.cordova?.plugins.permissions;
    permissions.checkPermission(permissions.RECORD_AUDIO, (status: any) => {
        if (!status.hasPermission) {
            permissions.requestPermissions(
                [permissions.RECORD_AUDIO],
                (status: any) => console.log('request permission', status),
                () => console.log('You dont have permission')
            );
        }
    });
};

export const checkCamera = () => {
    const permissions = windowWithPossiblyCordova.cordova?.plugins.permissions;
    permissions.checkPermission(permissions.CAMERA, (status: any) => {
        if (!status.hasPermission) {
            permissions.requestPermissions(
                [permissions.CAMERA],
                (status: any) => console.log('request permission', status),
                () => console.log('You dont have permission')
            );
        }
    });
};

export const exitApplication = () => {
    if (isCordova) {
        if (navigator) {
            if ((navigator as any).app) {
                (navigator as any).app.exitApp();
            } else if ((navigator as any).device) {
                (navigator as any).device.exitApp();
            } else {
                windowWithPossiblyCordova.close();
            }
        }
    }
};

export const mobileCheck = () => {
    return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent
    );
};

export const saveAndOpenFileCordova = (
    fileName: string,
    extension: string,
    fileContent: Blob
) => {
    // Typescript? What's that?
    const windowWithPossiblyCordova: any = window;
    // isn't @ts-ignore better than any?

    const writeFile = (fileEntry: any, dataObj: any) => {
        // Create a FileWriter object for our FileEntry
        fileEntry.createWriter(function (fileWriter: any) {
            fileWriter.onwriteend = function () {
                windowWithPossiblyCordova.cordova.plugins.fileOpener2.open(
                    fileEntry.toURL(), // You can also use a Cordova-style file uri: cdvfile://localhost/persistent/Downloads/starwars.pdf
                    'application/pdf',
                    {
                        error: function (e: any) {
                            console.log(
                                'Error status: ' +
                                    e.status +
                                    ' - Error message: ' +
                                    e.message
                            );
                        },
                        success: function () {
                            console.log('file opened successfully');
                        },
                    }
                );
            };

            fileWriter.onerror = function (error: any) {
                console.log('Failed file write: ' + error);
            };
            fileWriter.write(dataObj);
        });
    };

    const onErrorLoadFs = (error: any) => {
        console.log(error);
    };

    const onErrorCreateFile = (error: any) => {
        console.log(error);
    };

    const saveBlob2File = (fileName: any, blob: any) => {
        let storageLocation = '';
        switch (windowWithPossiblyCordova.device.platform) {
            case 'Android':
                storageLocation =
                    windowWithPossiblyCordova.cordova.file
                        .externalRootDirectory + 'Download';
                break;

            case 'iOS':
                storageLocation =
                    windowWithPossiblyCordova.cordova.file.dataDirectory;
                break;
        }

        windowWithPossiblyCordova.resolveLocalFileSystemURL(
            storageLocation,
            function (dirEntry: any) {
                createFile(dirEntry, fileName, blob);
            },
            onErrorLoadFs
        );
    };

    const createFile = (dirEntry: any, fileName: any, blob: any) => {
        // Creates a new file
        dirEntry.getFile(
            fileName,
            { create: true, exclusive: true },
            function (fileEntry: any) {
                writeFile(fileEntry, blob);
            },
            onErrorCreateFile
        );
    };

    switch (windowWithPossiblyCordova.device.platform) {
        case 'Android':
            let permissions =
                windowWithPossiblyCordova.cordova.plugins.permissions;
            permissions.checkPermission(
                permissions.WRITE_EXTERNAL_STORAGE,
                (status: any) => {
                    const { hasPermission } = status;
                    if (hasPermission) {
                        saveBlob2File(
                            `${fileName}-${moment().unix()}.${extension}`,
                            fileContent
                        );
                    } else {
                        permissions.requestPermission(
                            permissions.WRITE_EXTERNAL_STORAGE,
                            (data: any) => {
                                saveBlob2File(
                                    `${fileName}-${moment().unix()}.${extension}`,
                                    fileContent
                                );
                            },
                            null
                        );
                    }
                },
                null
            );
            break;

        case 'iOS':
            saveBlob2File(
                `${fileName}-${moment().unix()}.${extension}`,
                fileContent
            );
            break;
    }
};
