import { createAction, createAsyncAction } from 'typesafe-actions';
import { InProgressResource } from '../resources/in-progress/in-progress-record';
import { CoursesDTO, PlacementTestDTO } from './in-progress-record';
import { CompletedResource } from '../resources/completed/completed-record';
// import { EwritingListItem } from '../e-writing/data/ewriting-models';

export const loadInProgressTabItems = createAction(
    '@@in-progress-tab/load/all'
)<void>();

export const loadActivityList = createAsyncAction(
    '@@in-progress-tab/load-activity-list/request',
    '@@in-progress-tab/load-activity-list/success',
    '@@in-progress-tab/load-activity-list/failure',
    '@@in-progress-tab/load-activity-list/cancel'
)<void, InProgressResource[] | null, Error, void>();

export const loadCourseList = createAsyncAction(
    '@@in-progress-tab/load-course-list/request',
    '@@in-progress-tab/load-course-list/success',
    '@@in-progress-tab/load-course-list/failure',
    '@@in-progress-tab/load-course-list/cancel'
)<void, CoursesDTO[] | null, Error, void>();

export const loadTestsList = createAsyncAction(
    '@@in-progress-tab/load-tests-list/request',
    '@@in-progress-tab/load-tests-list/success',
    '@@in-progress-tab/load-tests-list/failure',
    '@@in-progress-tab/load-tests-list/cancel'
)<void, PlacementTestDTO[] | null, Error, void>();

export const loadInProgressCompletedActivities = createAsyncAction(
    '@@in-progress-tab/load-completed-activities-list/request',
    '@@in-progress-tab/load-completed-activities-list/success',
    '@@in-progress-tab/load-completed-activities-list/failure',
    '@@in-progress-tab/load-completed-activities-list/cancel'
)<void, CompletedResource[] | null, Error, void>();

// export const loadEwritingsList = createAsyncAction(
//     '@@in-progress-tab/load-ewritings-list/request',
//     '@@in-progress-tab/load-ewritings-list/success',
//     '@@in-progress-tab/load-ewritings-list/failure',
//     '@@in-progress-tab/load-ewritings-list/cancel',
// )<void, EwritingListItem[] | null, Error, void>()

export const inProgressTabActions = {
    loadInProgressTabItems,
    loadActivityList,
    loadCourseList,
    loadTestsList,
    // loadEwritingsList,
    loadInProgressCompletedActivities,
};
