import React, { useCallback, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { ForgotPasswordForm as ForgotPasswordFormUI } from '@arcadia-projects/portal2-ui-library';
import { localization } from '../../localization';
import { useAppDispatch } from '../root/app-dispatch';
import { Action } from '../../store/root-action';
import {
    resetPasswordError,
    resetPasswordSuccess,
} from '../../store/user/user-selectors';

export const ForgotPasswordForm = () => {
    const history = useHistory();
    const dispatch = useAppDispatch();

    const resetPasswordErrorText = useSelector(resetPasswordError);
    const resetPasswordComplete = useSelector(resetPasswordSuccess);

    const [submitted, setSubmitted] = useState(false);

    const onForgotPasswordSubmit = useCallback(
        (email) => {
            setSubmitted(true);
            dispatch(Action.user.resetPassword.request({ email }));
        },
        [dispatch]
    );

    return (
        <ForgotPasswordFormUI
            title="Forgot Password"
            instruction={localization.login_label_RequestPassword}
            placeholder={localization.login_placeholder_EnterEmail}
            submitText={localization.login_button_ResetPassword}
            onSubmit={onForgotPasswordSubmit}
            signin={{
                text: 'Sign in',
                onSignIn: () => history.push('/login'),
            }}
            errorText={submitted ? resetPasswordErrorText ?? '' : ''}
            onSuccess={
                resetPasswordComplete ? () => history.push('/login') : undefined
            }
        />
    );
};
