import { ApiHeaders } from './api-headers';
import { Axios } from 'axios-observable';
import { from, lastValueFrom, Observable, of } from 'rxjs';
import {
    LessonReportLearner,
    LessonReportListItem,
    LessonReportProps,
    ContentAsset,
    LessonReportTeacher,
    LessonReportActivityItem,
} from '../store/lesson-reports/data/lesson-report-models';
import { LessonReportType } from '../store/lesson-reports/data/lesson-report-type';
import { ajax, AjaxResponse } from 'rxjs/ajax';
import { catchError, map } from 'rxjs/operators';
import moment, { Moment } from 'moment';
import {
    assets,
    legacyTags,
    lessonReports,
    lessonReportsContent,
    tags,
    activityReport,
    user,
    feedbackService,
    contentService,
} from './api-urls';
import { ResourceContentType } from '../store/resources/resource-content-type';
import { Cache } from '../utils/cache/cache';

export interface ILessonReportsApi {
    loadLessonReportsList(
        learnerUUID: string,
        topicUUID?: string
    ): Observable<LessonReportListItem[]>;

    loadLessonReportFromActivity(
        learnerUUID: string
    ): Observable<LessonReportListItem[]>;

    loadLessonReport(lessonId: string): Observable<LessonReportProps>;

    lessonFromFeedbackReport({
        endDateTime,
        startDateTime,
        trainerID,
        lessonID,
    }: {
        endDateTime: Moment;
        startDateTime: Moment;
        trainerID: string;
        lessonID: string;
    }): Observable<LessonReportProps>;
}

export class LessonReportsApi implements ILessonReportsApi {
    constructor(private readonly apiHeaders: ApiHeaders) {}

    loadLessonReport(lessonId: string): Observable<LessonReportProps> {
        return from(this.loadLessonReportFull(lessonId));
    }

    lessonFromFeedbackReport({
        endDateTime,
        startDateTime,
        trainerID,
        lessonID,
    }: {
        endDateTime: Moment;
        startDateTime: Moment;
        trainerID: string;
        lessonID: string;
    }): Observable<LessonReportProps> {
        return from(
            this.loadLessonReportFull(lessonID, {
                endDateTime,
                startDateTime,
                trainerID,
            })
        );
    }

    loadLessonReportFromActivity(learnerId: string) {
        return from(this.lessonReportFromActivity(learnerId));
    }

    private getLessonsCompleted(userUUID: string) {
        return Axios.get<any>(
            activityReport() + `/lesson/${userUUID}?lessonStatus=Completed`,
            {
                headers: this.apiHeaders.getHeaders({}, true),
            }
        ).pipe(
            map((response) => {
                const reportList = response.data.filter((x: any) => {
                    return !x.lessonReport;
                });
                return reportList;
            }),
            catchError((error) => {
                throw error.message;
            })
        );
    }

    private async loadLearnerInformation(consultsIds: string[]): Promise<any> {
        if (consultsIds.length > 0) {
            const learnerConsults = consultsIds.map((id) =>
                this.getLearnerConsult(id)
            );
            return Promise.all(learnerConsults);
        }
        return [];
    }

    @Cache({ globalPrefix: 'loadUserProfile' })
    private getLearnerConsult(userId: string): Promise<any> {
        return lastValueFrom(
            ajax
                .getJSON<any>(user() + userId, this.apiHeaders.getHeaders())
                .pipe(
                    map((r) => {
                        return {
                            firstName: r.firstname,
                            lastName: r.lastname,
                            userUUID: r.uuid,
                            image: r.avatar,
                        } as any;
                    })
                )
        );
    }

    private async lessonReportFromActivity(
        userUUID: string
    ): Promise<LessonReportListItem[]> {
        let activityReportLessons = await lastValueFrom(
            this.getLessonsCompleted(userUUID)
        );

        let learnerConsultsIds: string[] = [userUUID];
        activityReportLessons.forEach((message: any) => {
            if (learnerConsultsIds.indexOf(message.trainerUUID) < 0) {
                learnerConsultsIds.push(message.trainerUUID);
            }
        });

        let learnerConsults = await this.loadLearnerInformation(
            learnerConsultsIds
        );

        const activityReportLessonsList = activityReportLessons.map(
            (message: any) => {
                const trainerDetail = learnerConsults.find((x: any) => {
                    return x.userUUID === message.trainerUUID;
                });

                const learnerDetail = learnerConsults.find((x: any) => {
                    return x.userUUID === userUUID;
                });

                return new LessonReportListItem({
                    startDateTime: moment(message.dateTimeStarted),
                    endDateTime: moment(message.dateTimeEnded),
                    teacher: new LessonReportTeacher({
                        teacherUUID: trainerDetail.userUUID,
                        firstName: trainerDetail.firstName,
                        lastName: trainerDetail.lastName,
                        photo: trainerDetail.image,
                    }),
                    learner: new LessonReportLearner({
                        learnerUUID: learnerDetail.userUUID,
                        photo: learnerDetail.image,
                        name: `${learnerDetail.firstname}`,
                    }),
                    type: LessonReportType.LiveLesson,
                    lessonId: message.lessonId,
                    derive: 'new',
                });
            }
        );

        return lastValueFrom(of(activityReportLessonsList));
    }

    loadLessonReportsList(
        learnerUUID: string,
        topicUUID?: string
    ): Observable<LessonReportListItem[]> {
        let url = lessonReports() + `${learnerUUID}`;

        if (topicUUID) {
            url += `?topic=${topicUUID}`;
        }

        return ajax.getJSON<any>(url, this.apiHeaders.getHeaders()).pipe(
            map((r) => {
                return r
                    .sort((i: any, j: any) => {
                        return -(
                            moment(i.lessonInfo.start).unix() -
                            moment(j.lessonInfo.start).unix()
                        );
                    })
                    .map((l: any) => {
                        return new LessonReportListItem({
                            startDateTime: moment(l.lessonInfo.start),
                            endDateTime: moment(l.lessonInfo.end),
                            lessonReportUUID: l.uuid,
                            teacher: new LessonReportTeacher({
                                teacherUUID: l.assigner.uuid,
                                firstName: l.assigner.firstname,
                                lastName: l.assigner.lastname,
                                photo: l.assigner.avatar,
                            }),
                            learner: new LessonReportLearner({
                                learnerUUID: l.assignee.uuid,
                                photo: l.assignee.avatar,
                                name: `${l.assignee.firstname}`,
                            }),
                            type: LessonReportType.LiveLesson,
                            lessonId: l.lessonId,
                        });
                    });
            })
        );
    }

    private loadTagsArray(tags: string[]): Observable<string[]> {
        if (tags.length > 0) {
            const tagRequests = tags.map((t) => this.loadTagName(t));
            return from(Promise.all(tagRequests));
        } else {
            return of([]);
        }
    }

    private loadLegacyTagsArray(tags: number[]): Observable<string[]> {
        if (tags.length > 0) {
            return ajax
                .post(
                    legacyTags(),
                    tags,
                    this.apiHeaders.getHeaders({
                        'Content-Type': 'application/json;charset=UTF-8',
                    })
                )
                .pipe(
                    map((r: any) => {
                        return r.response.map((t: any) => t.name as string);
                    }),
                    catchError(() => {
                        return [];
                    })
                );
        } else {
            return of([]);
        }
    }

    private loadLessonReportContent(lessonId: string): Promise<{
        report: LessonReportProps;
        tagIds: string[];
        legacyTagIds: number[];
    }> {
        return lastValueFrom(
            ajax
                .getJSON<any>(
                    lessonReportsContent() + `${lessonId}`,
                    this.apiHeaders.getHeaders()
                )
                .pipe(
                    map((r) => {
                        const todayArray = r.content.TodayID.map((t: any) => {
                            return new LessonReportActivityItem({
                                comment: t.Comment,
                                skills: t.Skills,
                                value: t.value ? t.value : t,
                            });
                        });
                        const homeworkArray = r.content.HomeworkID.map(
                            (t: any) => {
                                return new LessonReportActivityItem({
                                    comment: t.Comment,
                                    skills: t.Skills,
                                    value: t.value,
                                });
                            }
                        );
                        const todayExternalResources =
                            r.content.TodayExternalResource.map((t: any) => {
                                return t.value
                                    ? (t.value as string)
                                    : (t as string);
                            });

                        const homeworkExternalResources =
                            r.content.HomeworkExternalResource.map((t: any) => {
                                return new LessonReportActivityItem({
                                    comment: t.Comment,
                                    skills: t.Skills,
                                    value: t.value,
                                });
                            });

                        const tagIds = r.metadata.tags.map((t: string) => t);
                        const legacyTagIds = r.legacy.tags.map(
                            (t: string) => t
                        );
                        return {
                            report: {
                                tagNames: Array<string>(),
                                lessonID: r.id,
                                words: r.content.Words,
                                greetings: r.content.Greetings,
                                duration: parseInt(r.content.LessonDuration),
                                corrections: r.content.Corrections,
                                startDateTime: moment(
                                    r.content.LessonStartDateTime
                                ),
                                endDateTime: moment(
                                    r.content.LessonStartDateTime
                                ).add(
                                    parseInt(r.content.LessonDuration),
                                    'minutes'
                                ),
                                todayExternalResources: todayExternalResources,
                                homeworkExternalResources:
                                    homeworkExternalResources,
                                today: todayArray,
                                homework:
                                    homeworkArray as LessonReportActivityItem[],
                            } as LessonReportProps,
                            tagIds: tagIds,
                            legacyTagIds: legacyTagIds,
                        };
                    })
                )
        );
    }

    private loadLessonReportFeedback(
        lessonId: string,
        trainerId: string
    ): Promise<any> {
        return lastValueFrom(
            ajax
                .getJSON<any>(
                    feedbackService() +
                        `feedback/businessId/${lessonId}?reporterUuid=${trainerId}`,
                    this.apiHeaders.getHeaders()
                )
                .pipe(
                    map((r) => {
                        return r.length > 0 ? r[0] : [];
                    })
                )
        );
    }

    private loadTagName(tagId: string): Promise<string> {
        return ajax
            .getJSON<any>(tags() + `${tagId}`, this.apiHeaders.getHeaders())
            .pipe(
                map((r) => {
                    return r.name;
                }),
                catchError((err) => {
                    console.error('Error loading tags', err);
                    return '';
                })
            )
            .toPromise();
    }

    private loadAssets(
        assetList: LessonReportActivityItem[]
    ): Promise<ContentAsset[]> {
        if (assetList.length > 0) {
            return ajax
                .post(
                    assets(),
                    assetList.map((a) => a.value),
                    this.apiHeaders.getHeaders({
                        'Content-Type': 'application/json;charset=UTF-8',
                    })
                )
                .pipe(
                    map((r: AjaxResponse<any>) => {
                        return r?.response?.map((asset: any) => {
                            // eslint-disable-next-line
                            let assetWithComment = assetList.find(
                                (a) =>
                                    a.value === String(asset.legacy?.tagAssetId)
                            );

                            return new ContentAsset({
                                articleId: asset.legacy.articleId,
                                groupId: asset.legacy.groupId,
                                displayName: asset.title,
                                type: asset.legacy
                                    .contentType as ResourceContentType,
                                comment: assetWithComment?.comment,
                            });
                        });
                    }),
                    catchError((err) => {
                        console.error('Error loading assets', err);
                        return [];
                    })
                )
                .toPromise();
        } else {
            return lastValueFrom(of([]));
        }
    }

    private async loadContentInformations(
        contentUUIDs: string[]
    ): Promise<any> {
        if (contentUUIDs.length > 0) {
            const learnerConsults = contentUUIDs.map((id) =>
                this.getContentInformation(id)
            );
            return Promise.all(learnerConsults);
        }
        return [];
    }

    @Cache({ globalPrefix: 'loadContentInformation' })
    private getContentInformation(uuid: string): Promise<any> {
        return lastValueFrom(
            ajax
                .getJSON<any>(
                    contentService() + `content/${uuid}`,
                    this.apiHeaders.getHeaders()
                )
                .pipe(
                    map((r) => {
                        return new ContentAsset({
                            articleId: r.legacy.articleId,
                            comment: '',
                            displayName: r.title,
                            groupId: r.legacy.groupId,
                            type: r.legacy.contentType,
                        }) as any;
                    })
                )
        );
    }

    private async loadLessonReportFull(
        lessonId: string,
        lessonInfo?: any
    ): Promise<LessonReportProps> {
        if (!lessonInfo) {
            let content = await this.loadLessonReportContent(lessonId);

            const tagsRequest = this.loadTagsArray(content.tagIds).toPromise();
            const legacyTagsRequest = this.loadLegacyTagsArray(
                content.legacyTagIds
            ).toPromise();

            let requests = [tagsRequest, legacyTagsRequest] as any[];

            let homeworkAssetsIds = content.report.homework.filter((asset) =>
                Number(asset.value)
            );
            requests.push(this.loadAssets(homeworkAssetsIds));

            let todayAssetIds = content.report.today.filter((asset) =>
                Number(asset.value)
            );
            requests.push(this.loadAssets(todayAssetIds));

            let [tagNames, legacyTags, homeworkAssets, todayAssets] =
                await Promise.all(requests);

            let uniqueTagNames = tagNames.concat(legacyTags);
            uniqueTagNames = uniqueTagNames.filter(
                (item: string, pos: number) =>
                    uniqueTagNames.indexOf(item) === pos
            );

            return {
                tagNames: uniqueTagNames,
                lessonID: content.report.lessonID,
                words: content.report.words,
                greetings: content.report.greetings,
                duration: content.report.duration,
                corrections: content.report.corrections,
                startDateTime: content.report.startDateTime,
                endDateTime: content.report.endDateTime,
                todayExternalResources: content.report.todayExternalResources,
                homeworkExternalResources:
                    content.report.homeworkExternalResources,
                today: content.report.today,
                todayAssets: todayAssets,
                homework: content.report.homework,
                homeworkAssets: homeworkAssets,
            } as LessonReportProps;
        } else {
            const { trainerID, startDateTime, endDateTime } = lessonInfo;

            const content = await this.loadLessonReportFeedback(
                lessonId,
                trainerID
            );

            const assignments = content.details.assignments;

            const homeworkAssets = await this.loadContentInformations(
                assignments
            );

            return {
                tagNames: [] as String[],
                lessonID: content.businessId,
                words: '',
                greetings: content.feedback,
                duration: 0,
                corrections: '',
                startDateTime: startDateTime,
                endDateTime: endDateTime,
                todayExternalResources: [] as String[],
                homeworkExternalResources: [] as LessonReportActivityItem[],
                today: [] as LessonReportActivityItem[],
                todayAssets: [] as ContentAsset[],
                homework: [] as LessonReportActivityItem[],
                homeworkAssets: homeworkAssets,
            } as LessonReportProps;
        }
    }
}
