import { Record } from 'immutable';

export interface BoardTabProps {
    id: number;
    imgSrc: string;
    videoSrc: string;
    videoPosition: string;
    isVideoPlaying: boolean;
}

const defaultBoardTabProps: BoardTabProps = {
    id: 0,
    imgSrc: '',
    videoSrc: '',
    videoPosition: '',
    isVideoPlaying: false,
};

export class BoardTabData
    extends Record(defaultBoardTabProps)
    implements BoardTabProps {}

export interface BoardFigureProps {
    id: number;
    tabId: number;
    left: number;
    top: number;
    width: number;
    height: number;
    color: number;
    text?: string;
    type:
        | 'pointer'
        | 'line'
        | 'rectangle'
        | 'highlighter-rectangle'
        | 'circle'
        | 'text';
}

const defaultBoardFigureProps: BoardFigureProps = {
    id: 0,
    tabId: 0,
    left: 0,
    top: 0,
    width: 0,
    height: 0,
    color: 0,
    text: undefined,
    type: 'pointer',
};

export class BoardFigureData
    extends Record(defaultBoardFigureProps)
    implements BoardFigureProps {}
