import { createAction, createAsyncAction } from 'typesafe-actions';
import {
    ActiveClassroomData,
    ActiveClassroomSecurityData,
} from './classroom-data';
import { VirtualClassDto } from '../list/classroom-list-data';
import chat from './chat/chat-actions';
import call from './call/call-actions';
import board from './board/board-actions';

const registerUserToClassroom = createAsyncAction(
    '@@classroom/register/request',
    '@@classroom/register/success',
    '@@classroom/register/failure'
)<
    {
        topicLanguage: string;
    },
    ActiveClassroomData,
    Error
>();

const checkActiveClassroom = createAsyncAction(
    '@@classroom/check/request',
    '@@classroom/check/success',
    '@@classroom/check/failure'
)<void, ActiveClassroomData, Error>();

const getPersonalClassroom = createAsyncAction(
    '@@classroom/get-personal/request',
    '@@classroom/get-personal/success',
    '@@classroom/get-personal/failure'
)<string, ActiveClassroomData, Error>();

const getPersonalClassroomSecurity = createAsyncAction(
    '@@classroom/get-personal-security/request',
    '@@classroom/get-personal-security/success',
    '@@classroom/get-personal-security/failure'
)<
    {
        learnerUUID: string;
        learnerExternalID: number;
    },
    ActiveClassroomSecurityData,
    Error
>();

const checkKickoutClassStatus = createAsyncAction(
    '@@classroom/check-kickout-class-status/request',
    '@@classroom/check-kickout-class-status/success',
    '@@classroom/check-kickout-class-status/failure'
)<
    {
        virtualClassId: string;
        userId: string;
    },
    any,
    Error
>();

export const getJoinedParticipantsByClassId = createAsyncAction(
    '@@classroom/get-joined-participants-by-id/request',
    '@@classroom/get-joined-participants-by-id/success',
    '@@classroom/get-joined-participants-by-id/failure'
)<
    {
        virtualClassId: string;
    },
    any,
    Error
>();

export const updateJoinedParticipantsByClassId = createAsyncAction(
    '@@classroom/update-joined-participants-by-id/request',
    '@@classroom/update-joined-participants-by-id/success',
    '@@classroom/update-joined-participants-by-id/failure'
)<
    {
        virtualClassId: string;
    },
    any,
    Error
>();

const showTrainer = createAction('@@classroom/trainer/show')<boolean>();
const setVideoSrc = createAction('@@classroom/video-trainer')<string>();
const SetViewableParticipant = createAction(
    '@@vcr/set-viewable-participant'
)<any>();

const isNewAttendee = createAction(
    '@@classroom/learner/attendees-status'
)<boolean>();

const setSelectedRoom = createAction(
    '@@classroom/selected-room'
)<VirtualClassDto | null>();

const setJoinedParticipants = createAction(
    '@@classroom/set-joined-participants-by-id'
)<any>();

const setConnectedInRoom = createAction(
    '@@classroom/set-connected-in-room'
)<boolean>();

const classroomAction = {
    registerUserToClassroom,
    isNewAttendee,
    setSelectedRoom,
    checkActiveClassroom,
    getPersonalClassroom,
    getPersonalClassroomSecurity,
    checkKickoutClassStatus,
    videoTrainer: {
        showTrainer,
        setVideoSrc,
        setViewableParticipant: SetViewableParticipant,
    },
    chat: chat,
    call: call,
    board: board,
    getJoinedParticipants: getJoinedParticipantsByClassId,
    updateJoinedParticipantsByClassId,
    setJoinedParticipants,
    setConnectedInRoom,
};

export default classroomAction;
