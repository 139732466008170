import { ArticleDescription } from '../../store/resources/articles/article-description';
import { List, Map } from 'immutable';
import { WordDescription } from '../../store/resources/articles/word-description';
import { TagEntryProps } from '../../store/resources/resources-content-record';

interface DynamicContent {
    'dynamic-content': string;
}

type ContentValueType = DynamicContent | DynamicContent[];

export interface ArticleDto {
    groupId: number;
    articleId: string;
    categories: string[];
    quizArticleId: string;
    date: string;
    index: number;
    tags: string[];
    name: string;
    content: { [key: string]: ContentValueType };
    description: string;
    entryId: null | string;
    vocabularyId: null | string;
    howtoId: null | string;
    entryName: null | string;
    contentUUId: string;
    quizUUID: string;
    template: string;
}

export class ArticlesParser {
    parseContent(dto: any) {
        return new ArticleDescription({
            contentMetadata: dto?.contentMetadata ?? null,
            resourceId: dto.articleId,
            categories: List(dto.categories),
            date: this.parseSingleDynamicContent(
                dto.content,
                'source_publish_date'
            ),
            groupId: dto.groupId,
            index: dto.index,
            quizArticleId: dto.quizArticleId,
            tags: List(dto?.tags?.sort() || []),
            thumbnail: this.parseSingleDynamicContent(
                dto.content,
                'medium_image'
            ),
            learn: this.parseSingleDynamicContent(dto.content, 'body') || '',
            textToSpeachUrl: this.parseSingleDynamicContent(
                dto.content,
                'text_to_speech_url'
            ),
            title: dto.name,
            description: dto.description,
            source: this.parseSingleDynamicContent(dto.content, 'source'),
            wordDescriptions: Map(
                this.parseWordDescriptions(dto.content as any, 'snapshots')
            ),
            contentUUId: dto.contentUUId,
            quizUUID: dto.quizUUID,
            template: dto.template,
        });
    }

    parseFromContent(dto: any) {
        const { title, description, legacy, content, id: contentUUid } = dto;
        const {
            source,
            quiz_article_id: quizArticleId,
            quiz_article_uuid: quizUUID,
            source_publish_date,
            text_to_speech,
            snapshots,
            body,
        } = content;
        const { articleId, groupId, mediumImage, tagEntries } = legacy;

        let categories: string[] = [];
        if (tagEntries) {
            categories = tagEntries
                .filter((y: TagEntryProps) => y.isCategory)
                .map((x: TagEntryProps) => x.name);
        }

        return new ArticleDescription({
            contentMetadata: dto?.contentMetadata ?? null,
            resourceId: articleId,
            categories: List(categories),
            date: source_publish_date,
            groupId: groupId,
            quizArticleId: quizArticleId,
            tags: List(dto?.tags?.sort() || []),
            thumbnail: mediumImage,
            learn: body,
            textToSpeachUrl: text_to_speech,
            title: title,
            description: description,
            source: source,
            wordDescriptions: Map(
                this.parseWordDescriptionsContentService(snapshots)
            ),
            contentUUId: contentUUid,
            quizUUID: quizUUID,
            template: 'old',
        });
    }

    parseFromContentService(dto: any) {
        const { title, description, legacy, content, id: contentUUid } = dto;
        const {
            source,
            quiz_article_id: quizArticleId,
            quiz_article_uuid: quizUUID,
            source_publish_date,
            text_to_speech,
            snapshots,
            body,
        } = content;
        const { articleId, groupId, mediumImage, tagEntries } = legacy;

        let categories: string[] = [];
        if (tagEntries) {
            categories = tagEntries
                .filter((y: TagEntryProps) => y.isCategory)
                .map((x: TagEntryProps) => x.name);
        }

        return new ArticleDescription({
            contentMetadata: dto?.contentMetadata ?? null,
            resourceId: articleId,
            categories: List(categories),
            date: source_publish_date,
            groupId: groupId,
            quizArticleId: quizArticleId,
            tags: List(dto?.tags?.sort() || []),
            thumbnail: mediumImage,
            learn: body,
            textToSpeachUrl: text_to_speech,
            title: title,
            description: description,
            source: source,
            wordDescriptions: Map(
                this.parseWordDescriptionsContentService(snapshots)
            ),
            contentUUId: contentUUid,
            quizUUID: quizUUID,
            template: 'old',
        });
    }

    private parseSingleDynamicContent(
        content: { [key: string]: ContentValueType },
        key: string
    ) {
        if (content) {
            const value = content[key] as any;
            if (value && !(value instanceof Array)) {
                return value['dynamic-content'];
            }
        }
        return null;
    }

    private parseWordDescriptions(
        content: { [key: string]: ContentValueType },
        key: string
    ) {
        const bufferObject: [string, WordDescription][] = [];
        if (content) {
            const snapshots: { [key: string]: ContentValueType }[] = content[
                key
            ] as any;
            if (snapshots) {
                snapshots.forEach((item, index) => {
                    bufferObject.push([
                        item['dynamic-content'] as any,
                        new WordDescription({
                            title: this.parseSingleDynamicContent(
                                item,
                                'snap_title'
                            ),
                            content: this.parseSingleDynamicContent(
                                item,
                                'snap_body'
                            ),
                            imageUrl: this.parseSingleDynamicContent(
                                item,
                                'snap_image'
                            ),
                            index: index,
                        }),
                    ]);
                });
            }
        }

        return bufferObject;
    }

    private parseWordDescriptionsContentService(snapshots: {
        [key: string]: any;
    }) {
        const bufferObject: [string, WordDescription][] = snapshots.map(
            (element: any, index: number) => {
                const { snap_title, value, snap_body, snap_image } = element;
                return [
                    value,
                    new WordDescription({
                        content: snap_body,
                        imageUrl: snap_image,
                        title: snap_title,
                        index: index,
                    }),
                ];
            }
        );

        return bufferObject;
    }
}
