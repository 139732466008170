import { createAsyncAction } from 'typesafe-actions';
import { List } from 'immutable';
import { UserContract } from './contract-data';

/*
 *   Action to load user contracts
 */
export const loadUserContracts = createAsyncAction(
    '@@contracts/load/request',
    '@@contracts/load/success',
    '@@contracts/load/failure'
)<{ userUUID: string }, List<UserContract>, Error>();

export const contractActions = {
    loadUserContracts,
};
