import { ApiHeaders } from './api-headers';
import { Observable } from 'rxjs';
import { Axios } from 'axios-observable';
import { grading, gradingGoCEFR } from './api-urls';
import { catchError, map } from 'rxjs/operators';
import { GradingGoCEFR } from '../store/grading/grading-dto';

export interface IGradingApi {
    loadGrading(id: string): Observable<any>;
}

export class GradingApi implements IGradingApi {
    apiHeaders: ApiHeaders;

    constructor(apiHeaders: ApiHeaders) {
        this.apiHeaders = apiHeaders;
    }

    loadGradingGoCEFR(): Observable<GradingGoCEFR[]> {
        return Axios.get<GradingGoCEFR[]>(gradingGoCEFR(), {
            headers: this.apiHeaders.getHeaders({
                'Content-Type': 'application/json',
            }),
        }).pipe(
            map((response) => response.data),
            catchError((error) => {
                throw error?.message ? error.message : error;
            })
        );
    }

    loadGrading(id: string): Observable<any> {
        return Axios.get(grading() + id, {
            headers: this.apiHeaders.getHeaders({
                'Content-Type': 'application/json',
            }),
        })
            .pipe(map((response) => response.data))
            .pipe(map((x) => x));
    }

    loadAllGrading(): Observable<any> {
        return Axios.get(grading(), {
            headers: this.apiHeaders.getHeaders({
                'Content-Type': 'application/json',
            }),
        })
            .pipe(map((response) => response.data))
            .pipe(map((x) => x));
    }
}
