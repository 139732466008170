import { Observable, of } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { ApiHeaders } from '../api-headers';
import { Configuration } from '../../configuration';
import { contentService, language, mobileRest } from '../api-urls';
import {
    UserCompletedHowToBlock,
    UserCompletedHowToSection,
} from '../../store/resources/howtos/user-completed-block';
import { Axios } from 'axios-observable';
import { ArticlesParser } from './articles-parser';
import { VideosParser } from './videos-parser';
import { HowtoParser } from './howto-parser';
import { ResourceTypeDto } from './resource-type-dto';
import { StandaloneQuizParser } from './standalone-quiz-parser';

export interface HowtoDto {
    articleId: number; //yes, its a number here
    groupId: number;
    date: string;
    medium_image?: string;
    index: number;
    categories: string[];
    type: ResourceTypeDto;
    title: string;
    name: string;
    tags: string[];
    contentUUId: string;
    quizUUID: string;
    content?: {
        medium_image?: string;
    };
}

export interface UserCompletedBlockDto {
    id: string;
    block: UserCompletedSectionDto[];
}

export interface UserCompletedSectionDto {
    instanceId: string;
    dateCompleted: string;
}

export class HowtoApi {
    private readonly apiHeaders: ApiHeaders;
    private readonly configuration: Configuration;
    private readonly articlesParser = new ArticlesParser();
    private readonly videosParser = new VideosParser();
    private readonly howtoParser = new HowtoParser();
    private readonly standaloneQuizParser = new StandaloneQuizParser();

    constructor(configuration: Configuration, apiHeaders: ApiHeaders) {
        this.apiHeaders = apiHeaders;
        this.configuration = configuration;
    }

    loadContent(resource: {
        contentType: string;
        resourceId: number;
        groupId: number;
        languageCode: string;
        contentUUId?: string;
        fromDownload?: boolean;
    }) {
        const payload = { noLog: '' };
        const {
            contentType,
            resourceId,
            languageCode,
            fromDownload = false,
        } = resource;

        return Axios.post(
            `${language()}${languageCode}/api/v1.0/content/${contentType}/${resourceId}`,
            payload,
            { headers: this.getPostHeaders() }
        ).pipe(
            map((x) => {
                const response = x.data as any;
                if (!fromDownload) {
                    if (!response) {
                        throw new Error('Content is undefined');
                    }
                }

                if (response.template) {
                    if (response.template === 'old') {
                        return this.structureOldTemplate(
                            contentType,
                            response,
                            resource
                        );
                    } else {
                        return this.howtoParser.parseContent(
                            response,
                            resource
                        );
                    }
                } else {
                    return this.structureOldTemplate(
                        contentType,
                        response,
                        resource
                    );
                }
            }),
            catchError((x) => {
                throw new Error(x);
            })
        );
    }

    loadContentUsingUUID(contentUuid: string) {
        return Axios.get(contentService() + `content/${contentUuid}`, {
            headers: this.getPostHeaders(),
        }).pipe(
            map((x) => {
                let response = x.data as any;
                const contentType = response.legacy.contentType;
                const structureId = response.legacy.structureId;
                const oldTemplateList = ['STR_VIDEO', 'STR_ARTICLE'];
                const templateType = oldTemplateList.includes(structureId)
                    ? 'old'
                    : 'nrt';

                response = { ...response, template: templateType };

                if (response.template === 'old') {
                    return this.structureOTFromContentService(
                        contentType,
                        response
                    );
                } else {
                    return this.howtoParser.parseFromContentService(response);
                }
            }),
            catchError((x) => {
                return of(x);
            })
        );
    }

    //Old template
    private structureOTFromContentService(moduleType: string, response: any) {
        if (moduleType === 'article') {
            return this.articlesParser.parseFromContentService(response);
        } else if (moduleType === 'video') {
            return this.videosParser.parseFromContentService(response);
        }
    }

    private structureOldTemplate(
        moduleType: string,
        response: any,
        resource: any
    ) {
        if (moduleType === 'article') {
            return this.articlesParser.parseContent(response);
        } else if (moduleType === 'video') {
            return this.videosParser.parseContent(response);
        } else if (moduleType === 'standalone-quiz') {
            return this.standaloneQuizParser.parseContent(response);
        } else {
            return this.howtoParser.parseContent(response, resource);
        }
    }

    public loadCompletedBlock(
        resource: {
            resourceId: number;
            groupId: number;
        },
        user: {
            learnerId: string;
        }
    ): Observable<UserCompletedHowToBlock> {
        const payload = { noLog: '' };

        return Axios.post(
            `${mobileRest()}ws/v1/usercompletedsections/get/${user.learnerId}/${
                resource.resourceId
            }/${resource.groupId}`,
            payload,
            {
                headers: this.getPostHeaders(),
            }
        ).pipe(
            map((x) => {
                const blockDto: UserCompletedBlockDto = x.data;

                if (!blockDto.block) {
                    throw new Error("Can't load completed how to block");
                }

                return this.deserializeCompletedBlock(blockDto);
            }),
            catchError(() => {
                throw new Error("Can't load completed how to block");
            })
        );
    }

    public saveCompletedBlock(
        resource: {
            resourceId: number;
            groupId: number | null;
        },
        user: {
            learnerId: string;
        },
        section: {
            instanceId: string;
        }
    ): Observable<UserCompletedHowToBlock> {
        const payload = { noLog: '' };

        return Axios.post(
            `${mobileRest()}ws/v1/usercompletedsections/${user.learnerId}/${
                resource.resourceId
            }/${resource.groupId}/${section.instanceId}`,
            payload,
            {
                headers: this.getPostHeaders(),
            }
        ).pipe(
            map((x) => {
                const blockDto: UserCompletedBlockDto = x.data;

                if (!blockDto.block) {
                    throw new Error("Can't save completed how to block");
                }

                return this.deserializeCompletedBlock(blockDto);
            }),
            catchError(() => {
                throw new Error("Can't save completed how to block");
            })
        );
    }

    public updateCompletedBlock(section: {
        blockId: string | null;
        instanceId: string;
    }): Observable<string> {
        const payload = { noLog: '' };

        return Axios.put(
            `${mobileRest()}ws/v1/usercompletedsections/${section.blockId}/${
                section.instanceId
            }`,
            payload,
            { headers: this.getPostHeaders() }
        ).pipe(
            map((x) => {
                const instanceId: string = x.data.instanceId;

                if (!instanceId) {
                    throw new Error("Can't save completed how to block");
                }

                return instanceId;
            }),
            catchError(() => {
                throw new Error("Can't save completed how to block");
            })
        );
    }

    private deserializeCompletedBlock(
        dto: UserCompletedBlockDto
    ): UserCompletedHowToBlock {
        return new UserCompletedHowToBlock({
            id: dto.id,
            sections: dto.block?.map(
                (section) =>
                    new UserCompletedHowToSection({
                        instanceId: section.instanceId,
                        dateCompleted: section.dateCompleted,
                    })
            ),
        });
    }

    private getPostHeaders() {
        return {
            ...this.apiHeaders.getHeaders(),
            'Content-Type': 'application/json',
        };
    }
}
