import React, { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useAppDispatch } from '../root/app-dispatch';
import { Action } from '../../store/root-action';
import { setDeviceId, checkPlatform, isCordova } from '../../utils/cordova';

import privacyMic from './privacy-mic.png';
import { PrivacyPopup } from './privacy-popup';
import './privacy-policy.css';

export const PrivacyPage: React.FC = () => {
    const dispatch = useAppDispatch();
    const [modalActive, setModalActive] = useState(false);

    const [state, setState] = useState({
        preload: true,
        privacy_header_title: '',
        privacy_permission_guide: '',
        privacy_permission_mic_description: '',
        privacy_permission_camera_description: '',
        privacy_permission_device_description: '',
        privacy_agreement_guide_description: '',
        privacy_policy_text: '',
        privacy_disagree: '',
        privacy_agree: '',
    });

    const history = useHistory();
    const [agreementGuide, setAgreementGuide] = useState({
        preload: true,
        agreementGuideDetails: '',
    });

    const clickAgreePolicy = useCallback(() => {
        if (isCordova) {
            if (checkPlatform('Android')) {
                const windowWithPossiblyCordova: any = window;
                windowWithPossiblyCordova.device.initializeDevice();
            }
        }

        setDeviceId();
        dispatch(Action.privacyPolicy.displayPrivacyPolicy(true));
    }, [dispatch]);

    const clickDisagreePolicy = () => setModalActive(!modalActive);

    const simCountry = localStorage.getItem('simCountry') || `en`;

    useEffect(() => {
        if (state.preload === true && simCountry) {
            if (
                simCountry.toLowerCase() === `cn` ||
                simCountry.toLowerCase() === `chn`
            ) {
                setState({
                    preload: false,
                    privacy_header_title: '隐私政策',
                    privacy_permission_guide:
                        '重要提示：请阅读下面的隐私政策。<br/> 我们将收集以下设备权限和信息：',
                    privacy_permission_mic_description: '用于语音通话的麦克风',
                    privacy_permission_camera_description:
                        '用于视频通话的摄像头',
                    privacy_permission_device_description: '设备ID和MAC地址',
                    privacy_agreement_guide_description:
                        '根据国家法律法规，请您详细阅读 <div id="privacy-detail" ontouchstart="">[privacy_policy_text]</div>，点击同意表示您了解相关内容并同意隐私政策。',
                    privacy_policy_text: '《隐私政策》',
                    privacy_disagree: '不同意',
                    privacy_agree: '同意',
                });
            } else {
                setState({
                    preload: false,
                    privacy_header_title: 'Privacy Policy',
                    privacy_permission_guide:
                        'Important: Please read the privacy policy below. <br/> The following device permissions will be obtained and the following information will be collected:',
                    privacy_permission_mic_description:
                        'Microphone for voice calls',
                    privacy_permission_camera_description:
                        'Camera for video calls',
                    privacy_permission_device_description:
                        'Device ID and MAC address',
                    privacy_agreement_guide_description:
                        'By clicking "I agree", you agree and understand the terms and conditions in our <div id="privacy-detail">[privacy_policy_text]</div>',
                    privacy_policy_text: 'Privacy Policy.',
                    privacy_disagree: 'I disagree',
                    privacy_agree: 'I agree',
                });
            }
        }
    }, [state, setState, simCountry]);

    const handleClick = useCallback(() => {
        history.push('/service-privacy-policy');
    }, [history]);

    useEffect(() => {
        const privacyDetailLink = document.getElementById('privacy-detail');
        privacyDetailLink?.addEventListener('click', handleClick);

        if (state.preload === false && agreementGuide.preload === true) {
            const { privacy_agreement_guide_description, privacy_policy_text } =
                state;

            const replacePrivacyPolicyText =
                privacy_agreement_guide_description.replace(
                    '[privacy_policy_text]',
                    privacy_policy_text
                );

            setAgreementGuide({
                preload: false,
                agreementGuideDetails: replacePrivacyPolicyText,
            });
        }
    }, [agreementGuide, setAgreementGuide, state, simCountry, handleClick]);

    return (
        <>
            {state.preload === false && agreementGuide.preload === false && (
                <div className="wrapperMain">
                    <div className="privacyMainWrapper">
                        <div className="privacyTitle">
                            {state?.privacy_header_title}
                        </div>
                        <div className="privacyMicWrapper">
                            <div
                                className="permissionGuide"
                                dangerouslySetInnerHTML={{
                                    __html: state?.privacy_permission_guide,
                                }}
                            />
                            <div className="permissionGuide-wrapper">
                                <div className="permissionMicImage-wrapper">
                                    <div className="permissionMicImage">
                                        <img
                                            src={privacyMic}
                                            alt="privacy mic alt"
                                        />
                                    </div>
                                </div>
                                <div className="permissionGuideMic-wrapper">
                                    <div className="permissionGuideMic">
                                        <div className="permissionMicDescription">
                                            {
                                                state.privacy_permission_mic_description
                                            }
                                        </div>
                                    </div>

                                    <div className="permissionGuideMic">
                                        <div className="permissionMicDescription">
                                            {
                                                state.privacy_permission_camera_description
                                            }
                                        </div>
                                    </div>

                                    <div className="permissionGuideMic">
                                        <div className="permissionMicDescription">
                                            {
                                                state.privacy_permission_device_description
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="agreementGuide">
                            <div
                                dangerouslySetInnerHTML={{
                                    __html: agreementGuide.agreementGuideDetails,
                                }}
                                className="agreementGuideDescription"
                            />
                        </div>
                    </div>
                    <div className="privacyAction">
                        <div className="privacyActionWrapper">
                            <button
                                className="agreeBtn"
                                onClick={clickAgreePolicy}
                            >
                                {state.privacy_agree}
                            </button>
                            <button
                                className="disagreeBtn"
                                onClick={clickDisagreePolicy}
                            >
                                {state.privacy_disagree}
                            </button>
                        </div>
                    </div>
                </div>
            )}
            {modalActive && (
                <PrivacyPopup
                    isActive={modalActive}
                    handleToggle={clickDisagreePolicy}
                />
            )}
        </>
    );
};
