export interface Colors {
    mainColor: string;
    secondaryColor: string;
    accentColor: string;
    navigationColor: string;
    sideMenuColor: string;
}

export const defaultColors: Colors = {
    mainColor: '#055B96',
    secondaryColor: '#F2F9FD',
    accentColor: '#055B96',
    navigationColor: '#FFFFFF',
    sideMenuColor: '#FFFFFF',
};
