import { Record } from 'immutable';

export interface ContentAdministrationProps {
    approved: string;
    copyright: string;
    createdDate: string;
    displayDate: string;
    license: string;
    modifiedDate: string;
    version: number;
}

export interface TagEntryProps {
    id: number;
    isCategory: boolean;
    name: string;
}

export interface LegacyIdentifierProps {
    articleId: number;
    contentType: string;
    groupId: number;
    groupName: string;
    id: number;
    resourcePrimKey: number;
    structureId: string;
    tagAssetId: number;
    tagEntries: TagEntryProps[];
    tags: number[];
}

export interface ProficiencyProps {
    avgProficiency: number;
    minProficiency: number;
    maxProficiency: number;
}

export interface ContentMetadataProps {
    topic: string;
    grading: string[];
    objectives: any;
    organizationIds: number[];
    teachingPoint: any[];
    tagConcepts: string[];
    proficiency: ProficiencyProps | null;
    shape: {
        studyTimeMinutes: null | number | string;
        searchable: boolean;
    };
    targetType: string;
    targetFunction: string;
    businessType: string;
    groupingList: string[];
}

export interface ContentOldMetadataProps {
    accessRights: string[];
    gradings: string[];
    groups: string[];
    organizations: number[];
    tags: string[];
    teachingContent: string[];
    topics: string[];
}

export interface ResourceContentProps {
    administration: ContentAdministrationProps;
    description: string;
    id: string;
    legacy: LegacyIdentifierProps;
    metadata: ContentOldMetadataProps;
    contentMetadata: ContentMetadataProps | null;
    title: string;
    content: any;
    courseUuid?: string;
}

export const defaultResourceContentRecord: ResourceContentProps = {
    administration: {
        approved: '',
        copyright: '',
        createdDate: '',
        displayDate: '',
        license: '',
        modifiedDate: '',
        version: 0,
    },
    description: '',
    id: '',
    legacy: {
        articleId: 0,
        contentType: '',
        groupId: 0,
        groupName: '',
        id: 0,
        resourcePrimKey: 0,
        structureId: '',
        tagAssetId: 0,
        tagEntries: [],
        tags: [],
    },
    metadata: {
        accessRights: [],
        gradings: [],
        groups: [],
        organizations: [],
        tags: [],
        teachingContent: [],
        topics: [],
    },
    contentMetadata: null,
    title: '',
    content: null,
};

export class ResourceContentRecord
    extends Record(defaultResourceContentRecord)
    implements ResourceContentProps {}
