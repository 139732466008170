import { ActionType, createReducer } from 'typesafe-actions';
import { Action } from '../../root-action';
import { clearAllResources } from '../resources-actions';
import { inProgressActions } from './in-progress-actions';
import { combineReducers } from 'redux';
import { InProgressResource } from './in-progress-record';

export type InProgressAction = ActionType<typeof inProgressActions>;

export type InProgressState = Readonly<{
    list: InProgressResource[] | null;
    isLoading: boolean;
}>;

export const initialState: InProgressState = {
    list: null,
    isLoading: false,
};

export const inProgressActivities = createReducer(initialState.list)
    .handleAction(
        Action.resources.loadInProgressActivities.success,
        (state, action) => action.payload
    )
    .handleAction(
        [Action.resources.resetInProgressActivities.success, clearAllResources],
        () => null
    );

export const isLoadingInProgressActivities = createReducer(
    initialState.isLoading
)
    .handleAction(Action.resources.loadInProgressActivities.request, () => true)
    .handleAction(
        [
            Action.resources.loadInProgressActivities.success,
            Action.resources.loadInProgressActivities.failure,
        ],
        () => false
    );

export const inProgressReducer = () =>
    combineReducers<InProgressState, InProgressAction>({
        list: inProgressActivities,
        isLoading: isLoadingInProgressActivities,
    });
